export const languages = [ 
    "BENGALI",
    "ENGLISH",
    "HINDI",
    "GUJARATI",
    "KANNADA",
    "MARATHI",
    "MAITHILI",
    "ORIYA",
    "NEPALI",
    "PUNJABI"
]
  export const bloodGroups = ["A +ve","A -ve","B +ve","B -ve","AB +ve","AB -ve","O +ve","O -ve","--Select--"];

 export const occupations=[ 'EMPLOYEED_FULL_TIME',
      'EMPLOYEED_PART_TIME',
      'SELF_EMPLOYED',
      'UNEMPLOYED',
      'HOMEMAKER',
      'RETIRED',
      'STUDENT']

  export const educations=[
      "NO_EDUCATION",
      "UPTO_5th_STD",
      "UPTO_10th_STD",
      "UPTO_12th_STD",
      "DIPLOMA",
      "GRADUATION",
      "POST_GRADUATION",
      "DOCTORATE",
      "POST_DOCTORATE"]
  
  export const Gender = [
    { id: 'g1', value: "MALE", col: 1 },
    { id: 'g2', value: "FEMALE", col: 2 },
    
  ];
  export const countries=['India']

  export const facilitators = [
    "No Facilitator",
    "HG GOPAL CHANDRA DAS(Gyan)",
"BH. VINAY", 
"BH. RAVISHANKAR 2013",
"BH. NISHANT",
"BH. NIRAJ", 
"BH. SAGAR", 
"BH. AJAY 2014",
"BH. PURUSHOTTAM", 
"BH. RANJIT" ,
"BH. RAVISHANKAR 2015",
"BH. BISWAJEET ROWANI", 
"BH. NIKHILESH", 
"BH. BHANU PRATAP SINGH" ,
"BH. SONU VISHWAKARMA" ,
"BH. SAURAV KUMAR(2019)",
"BH. GAURAV KUMAR(2019)",
"BH. SHREEKANT SINGH" ,
"BH. TRIDEEP BISWAS", 
"BH. SATYAM SINGH" ,
"HG. JYOTIRUPA SUDEVI DASI(Jyoti Anand Mj)",
"BH. PRIYA RAJ",
"BH. RUCHI",
"BH. SHIWANI SINGH",
"HG MANJUGAMINI ROHINI DD(Manju Mj)",
"HG NANDPATNI DAYAVATI DD(Nisha Mj)",
"HG KIRTIDA PAVINI DD(Kiran Mj)",

  ];

  export   const mothersTongue = [
    "Hindi",
    "Bhojpuri",
    "Bengali",
    "Oriya",
    "Gujrati",
    "Marwari",
    "Punjabi",
    "Tamil",
    "Telgu",
    "Kannad",
    "Malyalam",
    "Nepali",
    "Assamese",
    "English",
    "Chinese",
    "Japanese",
    "German",
    "French",
  ];

  export const marritalStatus=["UNMARRIED", "MARRIED" , "BRAHMACHARI", "DIVORCED"];
  
  export const introductionMedium=["Temple", "House Program" , "Dham Yatra", "Home(introduced by own Child/relative/Parents)","Book Distribution",
               "YouTube Lectures","Bhagwad Geeta Study","Rath Yatra","VOICE","Educational Institution","others"];