export const requiredDataAllFields={
    
    fname: "",
    mname: "",
    lname: "",
    initiatedName: "",
    gender: "",
    dateOfBirth: "",
    maritalStatus: "",
    bloodGroup: "",
    language: "Hindi",
    profileImgUrl: "",
    primaryPhone: '',
    whatsAppPhone: '',
    email: '',
    currentAddress: {line1:'',line2:'',
    country:'India',
    state:'State',
    city:'City',
    district:"District",
    pinCode:'100001'},
    permanentAddress: {line1:'',line2:'',
    country:'India',
    state:'State',
    city:'City',
    district:"District",
    pinCode:'100001'},
    facilitator: "",
    spiritualMaster: "",
    chantingRounds: "",
    yearChantingSince: "",
    yearChanting16Rounds: "",
    introducedBy: "",
    preferredServices: "",
    servicesRendered: "",
    education: "NO_EDUCATION",
    degreeSpecification:"",
    occupation: "STUDENT",
    presentDesignation: "",
    skills: "",
    currentCompany: "",
    occupationLocation: "",
    birthCity: "",
    birthState: "",
    motherTongue: "Hindi",
    fathersName: "",
    mothersName: "",
    dateOfMarriage:"",
    spouseName:"",
    children:"",
    connectedTo:"",
    modified: false,
    priviledge:"",
    validations:{
    isValidFname: false,
    isValidGender:false,
    isValidDateOfBirth:false,
    isValidSpiritualMaster:false,
    isValidInitiatedName:false,
    isValidMaritalStatus:false,
    isValidProfileImgUrl:false,
    isSubmitDisabled:true,
    isValidPrimaryNo :false,
    isValidEmail:false,
    isValidCurrentAddress:false,
    isValidPermanentAddress:false,
    isValidCenterConnectedTo:false,
    isValidYearChantingSince:false,
    isValidYearChanting16Rounds:false,
    isValidChantingRounds:false,
    isValidCounselor:false,
    isValidIntroducedBy:false,
    isValidFathersName:false,
    isValidMothersName:false,
    isValidSpouseName:false,
    isValidDateOfMarriage:false,
    isValidMothersTongue:true,
    isValidEducation:true,
    isValidEducationSpecification:false,
    isValidOccupation:true,
    isValidOccupationLocation:false,
    isValidDesignation:false,
    isValidCurrentCompany:false,
    isSameAddress:false,
    isShowPreviewOn:false,
    imageSource:""
  },
    admin:"",
    logout:"",
    menus:[],
    profile:{}
  }

//language: send an array



//priviledge
// SUPER_ADMIN,
//     ADMIN,  
//     GUARDIAN, 
//     VOLUNTEER,
//     USER
