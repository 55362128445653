export const addressData=[
    {"pincode":"504273","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504293","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504307","Taluk":"Boath","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504001","Taluk":"Adilabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504251","Taluk":"Bellampalle","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504251","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504309","Taluk":"Adilabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504201","Taluk":"Chennur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504110","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504202","Taluk":"Khanapur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504296","Taluk":"Sirpur (t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504231","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504109","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504299","Taluk":"Kagaznagar","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504002","Taluk":"Adilabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504204","Taluk":"Chennur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504312","Taluk":"Adilabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504313","Taluk":"Utnoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504101","Taluk":"Mudhole","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504299","Taluk":"Sirpur(t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504205","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504203","Taluk":"NA","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504295","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504106","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504306","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504299","Taluk":"Sirpur (t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504296","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504304","Taluk":"Boath","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504103","Taluk":"Mudhole","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504308","Taluk":"Adilabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504311","Taluk":"Utnoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504272","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504323","Taluk":"Neradigonda","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504215","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504103","Taluk":"Kubeer","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504346","Taluk":"Utnoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504201","Taluk":"Chennoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504310","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504294","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504202","Taluk":"Kaddam (Peddur)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504302","Taluk":"Mancherial","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504206","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504102","Taluk":"Mudhole","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504218","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504207","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504296","Taluk":"Kagaznagar","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504209","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504104","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504292","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504297","Taluk":"Asifabad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504219","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504312","Taluk":"Tamsi","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504208","Taluk":"Mancherial","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504308","Taluk":"Boath","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504216","Taluk":"Chennur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504214","Taluk":"Chennur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504105","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504203","Taluk":"Khanapur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504323","Taluk":"Nirmal","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504311","Taluk":"Sirpur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504323","Taluk":"Boath","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504109","Taluk":"Mudhole","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504301","Taluk":"Mandamarri","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504309","Taluk":"Jainad","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504220","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504103","Taluk":"Bhainsa","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504313","Taluk":"Sirpur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504209","Taluk":"Mancherial","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504208","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504231","Taluk":"Mandamarri","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504104","Taluk":"Lokeswaram","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504307","Taluk":"Bazarhathnoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504207","Taluk":"Mancherial","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504292","Taluk":"Sirpur (t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504273","Taluk":"Sirpur (t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504107","Taluk":"Mudhole","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504296","Taluk":"Bejjur","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504302","Taluk":"Luxettipet","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504303","Taluk":"Mancherial","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504293","Taluk":"Kerameri","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504311","Taluk":"Narnoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504272","Taluk":"Sirpur (t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504297","Taluk":"Tiryani","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504308","Taluk":"Utnoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504002","Taluk":"Bela","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504313","Taluk":"Jainoor","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504296","Taluk":"Sirpur(t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"504295","Taluk":"Sirpur (t)","Districtname":"Adilabad","statename":"TELANGANA"},
    {"pincode":"506144","Taluk":"Jangaon","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506343","Taluk":"Mulug","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506145","Taluk":"Narmitta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506169","Taluk":"Bhupalapalli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506355","Taluk":"Cherial","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506366","Taluk":"Mogullapalli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506172","Taluk":"Mangapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506342","Taluk":"Atmakur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506224","Taluk":"Narmetta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506223","Taluk":"Cherial","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506221","Taluk":"Buchannapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506371","Taluk":"Hasanparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506352","Taluk":"Mulug","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506367","Taluk":"Narmetta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506303","Taluk":"Devaruppula","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506223","Taluk":"Maddur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506244","Taluk":"Raghunathapalli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506224","Taluk":"Buchannapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506244","Taluk":"Raghunathpalli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506003","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506344","Taluk":"Tadvai","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506345","Taluk":"Regonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506367","Taluk":"Maddur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506003","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506015","Taluk":"Hasanparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506347","Taluk":"Tadvai","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506349","Taluk":"Mulug","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506252","Taluk":"Palakurthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506172","Taluk":"Bachannapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506345","Taluk":"Ghanpur (mulug)","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506344","Taluk":"Govindaraopet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506165","Taluk":"Eturunagaram","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506301","Taluk":"Ghanpur Station","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506170","Taluk":"Chityal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506356","Taluk":"Chityal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506303","Taluk":"Lingalaghanpur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506168","Taluk":"Ghanpur (Mulug)","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506319","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506391","Taluk":"Parkal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506145","Taluk":"Ghanpur Station","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506221","Taluk":"China Raman Cherla","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506319","Taluk":"Regonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506144","Taluk":"Ghanpur Stn","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506223","Taluk":"Chiryal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506175","Taluk":"Jangaon","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506011","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506348","Taluk":"Regonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506370","Taluk":"Hasanparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506302","Taluk":"Devaruppula","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506144","Taluk":"Dharmasagar","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506302","Taluk":"Devaruppala","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506345","Taluk":"Ghanpur(m)","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506142","Taluk":"Dharmasagar","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506349","Taluk":"Shayampet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506356","Taluk":"Mogullapalli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506301","Taluk":"Ghanpur Stn","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506004","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506143","Taluk":"Zafargadh","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506319","Taluk":"Shayampet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506143","Taluk":"Ghanpur Station","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506169","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506170","Taluk":"Regonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506168","Taluk":"Bhupalapalli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506201","Taluk":"Lingal Ghanpur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506168","Taluk":"Venkatapur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506165","Taluk":"Eturnagaram","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506145","Taluk":"Ghanpur (Station)","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506001","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506175","Taluk":"Narmitta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506252","Taluk":"Ghanpur Stn","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506244","Taluk":"Palakurthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506151","Taluk":"Dharmasagar","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506167","Taluk":"Jangaon","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506201","Taluk":"Lingala Ghanpur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506201","Taluk":"Lingalaghanpur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506319","Taluk":"Parkal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506347","Taluk":"Govindaraopet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506003","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506343","Taluk":"Venkatapur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506303","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506303","Taluk":"Devaruppala","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506303","Taluk":"Lingala Ghanpur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506170","Taluk":"Chelpur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506143","Taluk":"Zafargaddah","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506252","Taluk":"Raghunathpalli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506224","Taluk":"Maddur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506302","Taluk":"Kodakandla","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506168","Taluk":"Ghanpur(mulug)","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506345","Taluk":"Venkatapur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506342","Taluk":"Parkal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506223","Taluk":"Buchannapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506344","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506142","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506003","Taluk":"Dharmasagar","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506145","Taluk":"Ghanpur Stn","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506244","Taluk":"Narmetta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506349","Taluk":"Duggondi","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506366","Taluk":"Mogullapally","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506224","Taluk":"Cherial","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506391","Taluk":"So","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506009","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506349","Taluk":"Nallabelli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506175","Taluk":"Narmetta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506224","Taluk":"Narmitta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506175","Taluk":"Lingala Ghanpur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506143","Taluk":"Ghanpur Stn","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506349","Taluk":"Bhupalpalle","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506244","Taluk":"Raghunathplli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506303","Taluk":"Devaruppal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506221","Taluk":"Jangaon","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506165","Taluk":"Venkatapur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506164","Taluk":"Parkal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506201","Taluk":"Jangaon","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506145","Taluk":"Dharmasagar","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506345","Taluk":"Ghanpur (Mulug)","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506244","Taluk":"Raghunathpalle","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506345","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506344","Taluk":"Mogullapalle","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506366","Taluk":"Parkal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506349","Taluk":"Nallbelli","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506302","Taluk":"Palakurthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506367","Taluk":"Narmitta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506143","Taluk":"Zafargadah","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506370","Taluk":"Dharmasagar","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506221","Taluk":"Narmetta","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506352","Taluk":"Venkatapur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506366","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506003","Taluk":"Zaffargaddh","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506370","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506371","Taluk":"Hasanaparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"505445","Taluk":"Gangadhara","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505305","Taluk":"Yellareddipet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505532","Taluk":"Gollapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505330","Taluk":"Koratla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505531","Taluk":"Pegadapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505466","Taluk":"Husnabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505527","Taluk":"Thimmapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505460","Taluk":"Raikal","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505526","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505306","Taluk":"Kathalapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505450","Taluk":"Ibrahimpatnam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505402","Taluk":"Ellanthakunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505524","Taluk":"Boenpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505469","Taluk":"Manakondur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505302","Taluk":"Vemulawada","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505455","Taluk":"Jagtial","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505001","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505452","Taluk":"Pegadapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505415","Taluk":"Choppadandi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505529","Taluk":"Sarangapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505401","Taluk":"Karimnagar","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505325","Taluk":"Metpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505405","Taluk":"Sirsilla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505403","Taluk":"Vemulawada","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505452","Taluk":"Mallial","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505303","Taluk":"Kishandaspet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505404","Taluk":"Mustabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505403","Taluk":"Chandurthi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505405","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505473","Taluk":"Koheda","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505532","Taluk":"Pegadapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505403","Taluk":"Konaraopet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505454","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505528","Taluk":"Bejjanki","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505453","Taluk":"Medpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505462","Taluk":"Kathalapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505001","Taluk":"Karimnagar","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505301","Taluk":"Sirsilla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505454","Taluk":"Dharmapuri","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505415","Taluk":"Karimnagar","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505473","Taluk":"Bejjanki","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505402","Taluk":"Sirsilla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505501","Taluk":"Kodimial","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505467","Taluk":"Chigurumamidi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505453","Taluk":"Koratla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505331","Taluk":"Mallapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505405","Taluk":"Ellanthakunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505528","Taluk":"Ellanthakunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505304","Taluk":"Gambhiraopet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505306","Taluk":"Medpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505451","Taluk":"Ramadugu","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505425","Taluk":"Dharmapuri","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505301","Taluk":"Konaraopet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505462","Taluk":"Koratla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505526","Taluk":"Velgatur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505524","Taluk":"Vemulawada","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505305","Taluk":"Gambhiraopet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505530","Taluk":"Ellanthakunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505301","Taluk":"Sircilla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505455","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505530","Taluk":"Bejjanki","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505531","Taluk":"Ramadugu","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505467","Taluk":"Husnabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505325","Taluk":"Ibrahimpatnam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505531","Taluk":"Choppadandi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505454","Taluk":"Jagtial","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505529","Taluk":"Gollapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505481","Taluk":"Chigurumamidi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505327","Taluk":"Jagtial","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505460","Taluk":"Koratla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505307","Taluk":"Chandurthi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505467","Taluk":"Koheda","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505401","Taluk":"Ellanthakunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505453","Taluk":"Medapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505303","Taluk":"Yellareddypet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505303","Taluk":"Mustabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505326","Taluk":"Koratla","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505481","Taluk":"Thimmapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505451","Taluk":"Karimnagar","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505529","Taluk":"Jagtial","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505527","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505529","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505401","Taluk":"Boenpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505469","Taluk":"Thimmapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505307","Taluk":"Nizamabad Division","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505462","Taluk":"Medpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505403","Taluk":"Chendurthy","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505325","Taluk":"Metpalle","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505481","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505524","Taluk":"Boenpali","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505530","Taluk":"Thimmapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505325","Taluk":"Koratal","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505415","Taluk":"Jullapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505401","Taluk":"Ellanthkunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505305","Taluk":"Yellareddypet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505528","Taluk":"Koheda","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505467","Taluk":"Hunsabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505307","Taluk":"Chendurthi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505402","Taluk":"Vemulawada","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505531","Taluk":"Gangadhara","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505454","Taluk":"Sarangapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505526","Taluk":"Dharmaram","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505302","Taluk":"Boenpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505302","Taluk":"Konaraopet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505402","Taluk":"Boenpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505530","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505331","Taluk":"Metpalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505301","Taluk":"Yellareddipet","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505415","Taluk":"Pegadapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"509210","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509382","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509002","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509352","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509152","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509132","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509133","Taluk":"NA","Districtname":"NULL","statename":"NULL"},
    {"pincode":"509130","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509340","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509336","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509371","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509407","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509335","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509208","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509131","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509338","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509409","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509204","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509339","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509411","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509353","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509205","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509127","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509128","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509129","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509334","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509126","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509135","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509311","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509350","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509339","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509351","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509125","Taluk":"Gadwal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509337","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509380","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509219","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509001","Taluk":"Mahbubnagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509002","Taluk":"Mahbubnagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509153","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509338","Taluk":"Kodangal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509219","Taluk":"Devarkadra","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509133","Taluk":"Gadwal","Districtname":"NULL","statename":"NULL"},
    {"pincode":"509001","Taluk":"Mahabubnagar H.o","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509335","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509210","Taluk":"Narayanpet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509371","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509133","Taluk":"Dharur","Districtname":"NULL","statename":"NULL"},
    {"pincode":"509126","Taluk":"Mahabub Nagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"502278","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502108","Taluk":"Dubbak","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502115","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502311","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502280","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502281","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502310","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502247","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502103","Taluk":"Siddipet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502109","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502278","Taluk":"Gujvail","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502267","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502277","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502114","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502302","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502113","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502375","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502117","Taluk":"Medak","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502334","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502279","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502102","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502117","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502381","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502255","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502276","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502301","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502108","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502107","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502130","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502248","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502335","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502113","Taluk":"Medak","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502281","Taluk":"Gujvail","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502336","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502312","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502372","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502331","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502331","Taluk":"Medak","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502130","Taluk":"Medak","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502125","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502303","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502109","Taluk":"Medak","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502110","Taluk":"Medak","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502303","Taluk":"Papannapet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502101","Taluk":"NA","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"508277","Taluk":"Nalgodna","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508114","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508254","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508101","Taluk":"Alair","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508377","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508277","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508285","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508101","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508117","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508255","Taluk":"Chandur","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508245","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508002","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508112","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508111","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508243","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508105","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508126","Taluk":"Bibinagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508117","Taluk":"Bhongir","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508116","Taluk":"Bhongiri","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508126","Taluk":"Bhongir","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508244","Taluk":"Munugode","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508113","Taluk":"Nalgodna","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508258","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508285","Taluk":"Cc","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508126","Taluk":"Nalgolnda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508001","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508202","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508256","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508286","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508286","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508253","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508004","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508244","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508257","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508252","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508243","Taluk":"Pedda Adiserla Palle","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508250","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508248","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508286","Taluk":"Vv","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508115","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508115","Taluk":"Yadagarigutta","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508248","Taluk":"Devarakonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508113","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508105","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508255","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508257","Taluk":"Pedda Adiserla Palle","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508115","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508373","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508253","Taluk":"Narayanapur","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508112","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508244","Taluk":"Nalognda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508126","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508377","Taluk":"Anumula","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508117","Taluk":"Cc","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508202","Taluk":"Anumula","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508286","Taluk":"Cc","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508284","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508244","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508252","Taluk":"Choutuppal","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508245","Taluk":"Marriguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508285","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508266","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508112","Taluk":"Cc","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508277","Taluk":"Mothkur","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508112","Taluk":"Bb","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508285","Taluk":"Nalgoda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508113","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508002","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508112","Taluk":"Cxc","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508252","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508256","Taluk":"Peddavoora","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508284","Taluk":"Pochampalle","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508117","Taluk":"Nalgnda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508105","Taluk":"Bhongir","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508277","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508113","Taluk":"Ramannapeta So","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508113","Taluk":"Cc","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508285","Taluk":"Bhongir","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508115","Taluk":"Bhongir","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508112","Taluk":"Valigonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508286","Taluk":"Bhongir","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508117","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508115","Taluk":"Yadagirigutta","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"503245","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503302","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503122","Taluk":"Yellareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503145","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503112","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503246","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503311","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503223","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503235","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503123","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503180","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503003","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503188","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503186","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503213","Taluk":"NA","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503223","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503301","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503144","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503310","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503164","Taluk":"NA","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503102","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503174","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503110","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503224","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503246","Taluk":"NA","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503307","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503212","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503230","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503217","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503187","Taluk":"Banswada","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503321","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503225","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503101","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503305","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503101","Taluk":"Bhiknoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503307","Taluk":"Bheemgal","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503125","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503306","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503185","Taluk":"Bodhan","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503218","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503180","Taluk":"NA","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503175","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503165","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503164","Taluk":"Dichpalle","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503206","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503108","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503308","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503165","Taluk":"Dharpalle","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503213","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503120","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503175","Taluk":"Dichpalle","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503207","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503309","Taluk":"Madnoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503219","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503202","Taluk":"Yedpalle","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503001","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503114","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503164","Taluk":"Armoor","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503112","Taluk":"Machareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503002","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503201","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503202","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503111","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503111","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503124","Taluk":"Kamareddy","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503201","Taluk":"Varni","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503187","Taluk":"Lingampet","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503188","Taluk":"Varni","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503108","Taluk":"Lingampet","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503322","Taluk":"Nizamabad","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503003","Taluk":"NA","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503187","Taluk":"Bichkunda","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"503223","Taluk":"Armur","Districtname":"Nizamabad","statename":"TELANGANA"},
    {"pincode":"505215","Taluk":"Ramagundam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505208","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505184","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505212","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505472","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505102","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505474","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505504","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505480","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505470","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505503","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505514","Taluk":"Ramagundam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505174","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505187","Taluk":"Peddapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505101","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505129","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505497","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505475","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505472","Taluk":"Saidapur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505416","Taluk":"Dharmaram","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505186","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505498","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505187","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505514","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505212","Taluk":"Kamanpur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505505","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505416","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505174","Taluk":"Peddapalle","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505122","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505476","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505502","Taluk":"Veenavanka","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505471","Taluk":"Bheemadevarpalle","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505184","Taluk":"Manthani","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505525","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505490","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505188","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505498","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505210","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505497","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505153","Taluk":"Srirampur","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505186","Taluk":"Sultanabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505185","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505502","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505209","Taluk":"Ramagundam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505152","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505211","Taluk":"Ramagundam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505468","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505122","Taluk":"Jammikunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505209","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505153","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505102","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505470","Taluk":"Shankarapatnam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505471","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505129","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505503","Taluk":"Manthani","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505153","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505490","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505162","Taluk":"NA","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505416","Taluk":"Choppadandi","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505129","Taluk":"Jammikunta","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505504","Taluk":"Manthani","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505416","Taluk":"Peddapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505471","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505185","Taluk":"Sultanabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505208","Taluk":"Ramagundam","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505172","Taluk":"Peddapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505172","Taluk":"Peddapalle","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505152","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505174","Taluk":"Peddapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505472","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505474","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505503","Taluk":"Kataram","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505502","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505525","Taluk":"Julapalle","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505214","Taluk":"Peddapalli","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"505476","Taluk":"Huzurabad","Districtname":"Karim Nagar","statename":"TELANGANA"},
    {"pincode":"502032","Taluk":"Ramachandrapuram","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502286","Taluk":"Narayankhed","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502325","Taluk":"R.c.puram","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502314","Taluk":"Narsapur","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502270","Taluk":"Jogipet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502318","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502269","Taluk":"Alladurg","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502273","Taluk":"Jogipet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502345","Taluk":"Sadasivpet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502306","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502273","Taluk":"Andole","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502313","Taluk":"Narsapur","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502287","Taluk":"Narayankhed","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502291","Taluk":"Sadasivpet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502371","Taluk":"Narayankhed","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502318","Taluk":"Zahirabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502293","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502305","Taluk":"Patancheru","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502305","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502210","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502269","Taluk":"Jogipet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502296","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502290","Taluk":"Narayankhed","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502228","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502256","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502221","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502271","Taluk":"Jogipet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502285","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502319","Taluk":"Patancheru","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502321","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502307","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502251","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502300","Taluk":"Patancheru","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502294","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502295","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502246","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502257","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502324","Taluk":"Patancheru","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502307","Taluk":"Patancheru","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502329","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502249","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502316","Taluk":"Narsapur","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502228","Taluk":"Zahirabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502306","Taluk":"Sadasivpet","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502205","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502001","Taluk":"Sangareddy","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502271","Taluk":"Shankarampet (A)","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"502220","Taluk":"Zaheerabad","Districtname":"Medak","statename":"TELANGANA"},
    {"pincode":"508208","Taluk":"Miryalguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508210","Taluk":"Nakrekal","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508374","Taluk":"Miryalguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508221","Taluk":"Thungathurthy","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508205","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508233","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508208","Taluk":"Miryalgudda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508204","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508206","Taluk":"NA","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508213","Taluk":"Suryapet","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508206","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508278","Taluk":"Miryalguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508280","Taluk":"Thungathurthi","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508212","Taluk":"Suryapet","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508224","Taluk":"Suryapet","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508206","Taluk":"Kodad","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508376","Taluk":"Suryapet","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508223","Taluk":"Thungathurthi","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508211","Taluk":"Nakrekal","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508208","Taluk":"Miryalaguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508218","Taluk":"Miryalguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508355","Taluk":"Miryalguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508218","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508213","Taluk":"Suryap[et","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508247","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508246","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508279","Taluk":"Thungathurthy","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508201","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508238","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508278","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508223","Taluk":"Tirumalgiri","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508234","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508224","Taluk":"Suryapet Ho","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508217","Taluk":"Miryalguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508374","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508207","Taluk":"Miryalguda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508211","Taluk":"Nalgonda","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508221","Taluk":"Thungathurthi","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508279","Taluk":"Thungathurthi","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"508355","Taluk":"Huzurnagar","Districtname":"Nalgonda","statename":"TELANGANA"},
    {"pincode":"509375","Taluk":"Achampet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509207","Taluk":"Kondurgu","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509209","Taluk":"Tadoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509360","Taluk":"Veldanda","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509110","Taluk":"Kothakota","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509321","Taluk":"Amangal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509412","Taluk":"Thelkapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509110","Taluk":"Cckunta","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509408","Taluk":"Keshampet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509381","Taluk":"Kothakota","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509401","Taluk":"Lingala","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509325","Taluk":"Maheswaram","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509104","Taluk":"Veepangandla","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509201","Taluk":"Amrabad","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509401","Taluk":"Balmoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509321","Taluk":"Madgul","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509202","Taluk":"Farooqnagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509357","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509406","Taluk":"Thimmajipet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509327","Taluk":"Madgul","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509401","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509302","Taluk":"Thimmajipet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509104","Taluk":"Pebbair","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509105","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509202","Taluk":"Balanagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509215","Taluk":"Tadoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509203","Taluk":"Bijnapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509105","Taluk":"Pangal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509206","Taluk":"Gopalpet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509120","Taluk":"Pangal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509110","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509120","Taluk":"Wanaparthy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509412","Taluk":"Peddakothapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509209","Taluk":"Nagarkurnool","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509324","Taluk":"Vangoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509216","Taluk":"Farooqnagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509321","Taluk":"Talakondapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509325","Taluk":"Kothur","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509321","Taluk":"Amnagal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509206","Taluk":"Wanaparthy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509410","Taluk":"Farooqnqgar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509408","Taluk":"Balanagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509105","Taluk":"Kollapur","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509202","Taluk":"NA","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509410","Taluk":"Farooqnagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509349","Taluk":"Vangoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509401","Taluk":"Lingal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509302","Taluk":"Jadcherla","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509358","Taluk":"Amangal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509385","Taluk":"Thelkapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509385","Taluk":"Nagarkurnool","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509106","Taluk":"Wanapathy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509103","Taluk":"Wanaparthy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509412","Taluk":"Nagarkurnool","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509320","Taluk":"Talakondapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509375","Taluk":"Balmoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509324","Taluk":"Veldanda","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509105","Taluk":"Veepangandla","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509235","Taluk":"Nagarkurnool","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509215","Taluk":"Bijnapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509324","Taluk":"Kalwakurthy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509209","Taluk":"Thadoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509217","Taluk":"Shahabad","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509228","Taluk":"Kothur","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509320","Taluk":"Midjil","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509102","Taluk":"Kodair","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509376","Taluk":"Uppanunthala","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509385","Taluk":"Balmoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509206","Taluk":"Kodair","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509381","Taluk":"Wanaparthy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509360","Taluk":"Madgul","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509102","Taluk":"Peddakothapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509376","Taluk":"Uppununthala","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509120","Taluk":"Pebbair","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509381","Taluk":"Peddamandadi","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509358","Taluk":"Amnagal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509102","Taluk":"Kollapur","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509320","Taluk":"Kalwakurthy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509385","Taluk":"Uppunuthala","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509406","Taluk":"Bhootpur","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509217","Taluk":"Kothur","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509410","Taluk":"Shahabad","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509357","Taluk":"Jadcherla","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509104","Taluk":"Kothakota","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509358","Taluk":"Kandukur","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509324","Taluk":"Kalwakurhy","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509235","Taluk":"Gopalpet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509408","Taluk":"Talakondapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509376","Taluk":"Achampet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509325","Taluk":"Shamshabad","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509206","Taluk":"Pedda Mandadi","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509216","Taluk":"Keshampet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509408","Taluk":"Jadcherla","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509360","Taluk":"Amangal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509376","Taluk":"Balmoor","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509202","Taluk":"Keshampet","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509215","Taluk":"Palem","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509320","Taluk":"Veldanda","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509206","Taluk":"Pangal","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509217","Taluk":"Farooqnagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509216","Taluk":"Shadnagar","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509410","Taluk":"Kondurgu","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509326","Taluk":"Amrabad","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509301","Taluk":"Stn. Jadcherla","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509325","Taluk":"Shamshabad","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"509385","Taluk":"Uppanunthala","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509235","Taluk":"Peddakothapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509401","Taluk":"Peddakothapally","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"509406","Taluk":"Boothpur","Districtname":"Mahabub Nagar","statename":"TELANGANA"},
    {"pincode":"506310","Taluk":"Wardhannapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506315","Taluk":"Maripeda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506324","Taluk":"Narasimulapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506007","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506102","Taluk":"Mahabubabad","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506112","Taluk":"Kesamudram","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506102","Taluk":"Mah'bad","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506122","Taluk":"Nekkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506381","Taluk":"Dornakal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506163","Taluk":"Turrur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506365","Taluk":"Parvathagiri","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506369","Taluk":"Nekkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506005","Taluk":"Sangam","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506132","Taluk":"Narsampet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506317","Taluk":"Turrur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506134","Taluk":"Gudur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506105","Taluk":"Kuravi","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506313","Taluk":"Wardhannapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506002","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506324","Taluk":"Narsimulapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506135","Taluk":"Kothaguda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506318","Taluk":"Kodakandal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506369","Taluk":"Parwathagiri","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506104","Taluk":"Neerada","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506332","Taluk":"Chennaraopet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506222","Taluk":"Palakurthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506329","Taluk":"Sangem","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506318","Taluk":"Kodakandla","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506365","Taluk":"Parwathagiri","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Hanamkonda Mandal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506316","Taluk":"Zaffargadh","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506324","Taluk":"Narsimhulapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506330","Taluk":"Geesugonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506331","Taluk":"Duggondi","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506329","Taluk":"Samgem","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506329","Taluk":"Sangam","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506008","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506310","Taluk":"Sangam","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506330","Taluk":"Geegugonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506365","Taluk":"Palakurthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Geesungonda Mandal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506331","Taluk":"Chennaraopet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506163","Taluk":"Turrrur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506007","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506331","Taluk":"Narsampet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506318","Taluk":"Kadakandla","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506314","Taluk":"Raiparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506222","Taluk":"Kodakondla","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Atmakur Mandall","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506313","Taluk":"Raiparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Atmakur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506013","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506310","Taluk":"Sangem","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506368","Taluk":"Nallikaddur","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506101","Taluk":"Mahabubabad","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506166","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506166","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506332","Taluk":"Chennraopet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Geesugonda Mandal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506331","Taluk":"Sangem","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506222","Taluk":"Kodakandla","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506330","Taluk":"Sangem","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506317","Taluk":"Narsimhulapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506005","Taluk":"Warangal","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506368","Taluk":"Nallikaddr","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506369","Taluk":"Redlawada","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506313","Taluk":"Zaffargadh","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506330","Taluk":"Samgem","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506329","Taluk":"Ggesugonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Hasnparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506005","Taluk":"Wardhannapet","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506314","Taluk":"Raiparhty","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506005","Taluk":"Hanamkonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506316","Taluk":"Zaffargadgh","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506002","Taluk":"NA","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506329","Taluk":"Geesugonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506006","Taluk":"Hasanparthy","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506331","Taluk":"Geesugonda","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"506318","Taluk":"Kodakondla","Districtname":"Warangal","statename":"TELANGANA"},
    {"pincode":"500004","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500045","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500091","Taluk":"Rajendra Nagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500008","Taluk":"Rajendranagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500075","Taluk":"Moinabad","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500034","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500018","Taluk":"Balanagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500075","Taluk":"NA","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500075","Taluk":"Rajendranagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500022","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500046","Taluk":"Serilingampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500081","Taluk":"Shaikpet","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500008","Taluk":"Golconda","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500033","Taluk":"Shaikpet","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500018","Taluk":"Ameerpet","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500096","Taluk":"Shaikpet","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500032","Taluk":"Seri Lingampally","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500029","Taluk":"Himayathnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500001","Taluk":"Nampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500075","Taluk":"Rajendra Nagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500028","Taluk":"Asifnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500091","Taluk":"Golconda","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500082","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500031","Taluk":"Golconda","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500075","Taluk":"Shankarpally","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500006","Taluk":"Asifnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500084","Taluk":"Serilingampally","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500084","Taluk":"Serilingampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500063","Taluk":"Himayathnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500019","Taluk":"Serilingampally","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500089","Taluk":"NA","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500032","Taluk":"Seri Lingampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500075","Taluk":"Rajendranagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500004","Taluk":"Himayathnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500095","Taluk":"Nampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500041","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500018","Taluk":"Balanagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500038","Taluk":"Ameerpet","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500082","Taluk":"Nampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500073","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500038","Taluk":"Khairatabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500004","Taluk":"Nampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500057","Taluk":"Asifnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500001","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500030","Taluk":"Rajendranagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501510","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501506","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501509","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500044","Taluk":"Musheerabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500013","Taluk":"Amberpet","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501512","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501508","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500048","Taluk":"Rajendranagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500058","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500064","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500005","Taluk":"Saroornagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501505","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500027","Taluk":"Himayathnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500012","Taluk":"Nampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500092","Taluk":"Ghatkesar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500060","Taluk":"Saroornagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501506","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501218","Taluk":"Shamshabad","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501359","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500005","Taluk":"Bandlaguda","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500024","Taluk":"Charminar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500086","Taluk":"Rajendranagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500053","Taluk":"Charminar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500065","Taluk":"Charminar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500052","Taluk":"Rajendranagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500097","Taluk":"saroornagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501218","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500068","Taluk":"Hayathnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501505","Taluk":"Hayathnagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500066","Taluk":"Charminar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500051","Taluk":"Uppal","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500035","Taluk":"Saroornagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500064","Taluk":"Bahadurpura","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500002","Taluk":"Charminar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500048","Taluk":"Rajendranagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500076","Taluk":"Uppal","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501506","Taluk":"Ibrahimpatnam","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500079","Taluk":"Saroornagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500058","Taluk":"Bandlaguda","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500077","Taluk":"Rajendranagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500030","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501511","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501510","Taluk":"Maheswaram","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501505","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500074","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500036","Taluk":"Amberpet","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500076","Taluk":"Uppal","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500005","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500068","Taluk":"Hayathnagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500098","Taluk":"Ghatkesar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500097","Taluk":"Saroornagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500027","Taluk":"Nampally","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500036","Taluk":"Saidabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500060","Taluk":"Saroornagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500039","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500069","Taluk":"Bandlaguda","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501510","Taluk":"Hayathnagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500030","Taluk":"Rajendranagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501512","Taluk":"Hayathnagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500074","Taluk":"Saroornagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500024","Taluk":"Saidabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500059","Taluk":"Saidabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500059","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501511","Taluk":"Hayathnagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500070","Taluk":"Hayathnagar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500035","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501510","Taluk":"Ibrahimpatnam","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500039","Taluk":"Uppal","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500052","Taluk":"Rajendranagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500079","Taluk":"Saroornagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500070","Taluk":"Hayathnagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500023","Taluk":"Charminar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501142","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500007","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501144","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501502","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500043","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500101","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501102","Taluk":"Vikarabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501141","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501503","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500010","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501504","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501202","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500047","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501102","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500101","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501301","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500015","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500040","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501111","Taluk":"Nawabpet","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500020","Taluk":"Musheerabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501401","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500090","Taluk":"Qutubullapur","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500037","Taluk":"Rangareddy","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501106","Taluk":"R","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501106","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501143","Taluk":"Hyd","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500003","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500016","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501144","Taluk":"Rr.","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501203","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500078","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500078","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500011","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500043","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501158","Taluk":"Tandur","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501111","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500050","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501501","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501143","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500037","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501401","Taluk":"Medchal","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501401","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500078","Taluk":"Shamirpet","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501121","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500100","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500062","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501301","Taluk":"Ghatkesar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500055","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500080","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501121","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501301","Taluk":"Hyd","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501102","Taluk":"Vikarabad","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501141","Taluk":"Tandur","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500014","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500042","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500025","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500054","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500055","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500087","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500085","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501158","Taluk":"Rangareddy","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500088","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500100","Taluk":"Quthbullapur Mandal","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500088","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501202","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500010","Taluk":"Malkagiri Manda","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500072","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500017","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500047","Taluk":"Rangareddy","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500009","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500049","Taluk":"Serilingampally","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500049","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500020","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500083","Taluk":"Rangareddy","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500026","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500056","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500090","Taluk":"Qutubullapur","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500009","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500090","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"501102","Taluk":"NA","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501501","Taluk":"Shabad","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500094","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500003","Taluk":"Hyderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500101","Taluk":"Shamirpet Mandal","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500061","Taluk":"Secunderabad","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500067","Taluk":"NA","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500078","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500015","Taluk":"Rangareddy","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500078","Taluk":"Shameerpet","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"500088","Taluk":"Ghatkesar","Districtname":"K.V.Rangareddy","statename":"TELANGANA"},
    {"pincode":"501101","Taluk":"Hyd","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500093","Taluk":"Tirumalagiri","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"500072","Taluk":"Balanagar","Districtname":"Hyderabad","statename":"TELANGANA"},
    {"pincode":"515004","Taluk":"Anantapur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515731","Taluk":"Singanamala","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515002","Taluk":"Anantapur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515766","Taluk":"Kundurpi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515415","Taluk":"Tadipatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515822","Taluk":"Uravakonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515445","Taluk":"Tadpatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515001","Taluk":"Anantapur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515741","Taluk":"Beluguppa","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515775","Taluk":"Pamidi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515455","Taluk":"Peddavadugur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515445","Taluk":"Ammalladinne","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515465","Taluk":"Yellanur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515751","Taluk":"Atmakur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515867","Taluk":"Rayadurg","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515763","Taluk":"Settur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515767","Taluk":"Shetturu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515775","Taluk":"Guntakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515722","Taluk":"Raptadu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515863","Taluk":"Gummagatta","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515425","Taluk":"Narpala","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515402","Taluk":"Gooty","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515761","Taluk":"Kalyandurg","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515867","Taluk":"Gummagatta","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515787","Taluk":"Kalyandurg","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515413","Taluk":"Tadpatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515767","Taluk":"Brahma Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515870","Taluk":"Bommanahal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515763","Taluk":"Brahmasamudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515871","Taluk":"Bommanahal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515411","Taluk":"Tadipatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515767","Taluk":"NA","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515408","Taluk":"Peddapappur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515401","Taluk":"Gooty","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515871","Taluk":"Kanekal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515812","Taluk":"Uravakonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515701","Taluk":"Bukkrayasamudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515701","Taluk":"Singanamala","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515812","Taluk":"Vajrakarur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515865","Taluk":"Rayadurg","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515414","Taluk":"Putlur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515455","Taluk":"Yadiki","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515842","Taluk":"Vajrakarur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515812","Taluk":"Vidapanakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515767","Taluk":"Brhama Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515425","Taluk":"Bukkaraya Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515766","Taluk":"Settur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515445","Taluk":"Peddapappur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515761","Taluk":"Kambadur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515405","Taluk":"Pedda Vadagur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515408","Taluk":"Yadiki","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515611","Taluk":"Peddavadugur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515721","Taluk":"Anantapur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515711","Taluk":"Kuderu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515415","Taluk":"Tadpatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515701","Taluk":"B K Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515765","Taluk":"Kalyandurg","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515414","Taluk":"Tadipatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515842","Taluk":"Vidapanakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515803","Taluk":"Guntakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515405","Taluk":"Pamidi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515832","Taluk":"Vajrakarur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515005","Taluk":"Anantapur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515722","Taluk":"Kanaganapalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515761","Taluk":"Beluguppa","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515731","Taluk":"Garladinne","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515871","Taluk":"D.Hirehal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515865","Taluk":"Gummagatta","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515722","Taluk":"Rapthadu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515812","Taluk":"Bommanahal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515763","Taluk":"Kalyandurg","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515863","Taluk":"Brahmasamudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515801","Taluk":"Guntakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515801","Taluk":"Anantapur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515741","Taluk":"Kanekal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515765","Taluk":"Kambadur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515870","Taluk":"Vipanakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515872","Taluk":"Herial","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515611","Taluk":"Garladinne","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515711","Taluk":"Kudair","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515731","Taluk":"Garladinna","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515701","Taluk":"Bukkarayasamudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515722","Taluk":"NA","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515411","Taluk":"Peddapappuru","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515865","Taluk":"Herial","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515611","Taluk":"Singanamala","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515774","Taluk":"Garladinna","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515865","Taluk":"D.Hirehal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515767","Taluk":"Settur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515721","Taluk":"Kandukur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515763","Taluk":"Brahma Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515870","Taluk":"Vidapanakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515787","Taluk":"Kambadur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515774","Taluk":"Garladinne","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515731","Taluk":"Bukkaraya Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515405","Taluk":"Peddavadugur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515766","Taluk":"Shetturu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515405","Taluk":"Gooty","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515767","Taluk":"Brahmasamudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515751","Taluk":"Kalayandurg","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515445","Taluk":"Tadipatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515842","Taluk":"Guntakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515871","Taluk":"Herial","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515763","Taluk":"Beluguppa","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515435","Taluk":"Singanamala","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515751","Taluk":"Kudair","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515865","Taluk":"Kanekal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515411","Taluk":"Tadpatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515411","Taluk":"Putluru","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515767","Taluk":"Shettur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515003","Taluk":"Anantapur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515765","Taluk":"Kundurpi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515763","Taluk":"Brhama Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515414","Taluk":"Tadpatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515701","Taluk":"Bukkaraya Samudram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515465","Taluk":"Muddanur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"515741","Taluk":"Uravakonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515465","Taluk":"Tadpatri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515425","Taluk":"Putlur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"517425","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517403","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517280","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517124","Taluk":"Puthalpattu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517415","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517421","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517326","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517390","Taluk":"Mulakalacheruvu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517432","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517247","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517167","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517401","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517129","Taluk":"Arugonda","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517370","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517424","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517421","Taluk":"Gangadhara Nellore","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517351","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517127","Taluk":"Murakambattu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517418","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517416","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517426","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517422","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517132","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517370","Taluk":"Madanpalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517123","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517423","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517408","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517257","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517419","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517417","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517002","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517004","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517126","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517319","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517001","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517127","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517257","Taluk":"Madanpalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517257","Taluk":"Chowdepalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517424","Taluk":"Venkatagirikota","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517599","Taluk":"Vengalrajukuppam","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517131","Taluk":"Kanipakam","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517426","Taluk":"Kuppam","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517125","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517130","Taluk":"Iral","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517352","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517416","Taluk":"Bangarupalem","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517129","Taluk":"Thavanampalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517129","Taluk":"Bangarupalem","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517126","Taluk":"Penumuru","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517123","Taluk":"NA","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517429","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517130","Taluk":"Irala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517130","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517128","Taluk":"Yadamarri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517414","Taluk":"Peddapanjani","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517390","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517391","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517131","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517422","Taluk":"Yadamarri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517432","Taluk":"Palamaner","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517350","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517325","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517167","Taluk":"Srirangarajapuram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517128","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517417","Taluk":"Ramasamudram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517425","Taluk":"Kuppam","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517247","Taluk":"Madanpalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517416","Taluk":"NA","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517423","Taluk":"Santhipuram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517132","Taluk":"Gudipala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517419","Taluk":"Palasamudram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517370","Taluk":"B.Kothakota","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517401","Taluk":"Ramakuppam","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517247","Taluk":"Punganur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517124","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517125","Taluk":"Gangadhara Nellore","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517414","Taluk":"Madanapalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517599","Taluk":"Palasamudram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517124","Taluk":"Puthalapattu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517418","Taluk":"Chowdepalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517599","Taluk":"Chittoor","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"516270","Taluk":"Rayachoty","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516269","Taluk":"Rayachoti","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516150","Taluk":"Nandalur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516215","Taluk":"Sambepalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516259","Taluk":"Chakrayapet","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516150","Taluk":"Rajampet","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516193","Taluk":"Porumamilla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516501","Taluk":"Atlur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516003","Taluk":"Cuddapah","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516001","Taluk":"Cuddapah","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516107","Taluk":"Pullampeta","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516257","Taluk":"Lakkireddipalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516105","Taluk":"Kodur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516228","Taluk":"Badvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516107","Taluk":"Badvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516228","Taluk":"B.Kodur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516104","Taluk":"Chitvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516203","Taluk":"Khajipet","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516247","Taluk":"Vontimitta","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516267","Taluk":"Galiveedu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516227","Taluk":"Badvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516502","Taluk":"Badvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516105","Taluk":"Obulavaripalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516270","Taluk":"Ramapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516101","Taluk":"Kodur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516503","Taluk":"Brahmamgarimattam","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516162","Taluk":"Chennur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516128","Taluk":"Penagalur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516129","Taluk":"T Sundupalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516233","Taluk":"Gopavaram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516130","Taluk":"T Sundupalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516110","Taluk":"Obulavaripalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516214","Taluk":"Chinnamandem","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516108","Taluk":"Obulavaripalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516126","Taluk":"Rajampet","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516217","Taluk":"Kalasapadu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516002","Taluk":"Cuddapah","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516501","Taluk":"Porumamilla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516004","Taluk":"Chinthakommadinne","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516003","Taluk":"Chinthakommadinne","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516152","Taluk":"Vontimitta","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516504","Taluk":"Ramapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516163","Taluk":"Chennur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516004","Taluk":"Cuddapah","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516002","Taluk":"Sidhout","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516269","Taluk":"Chinnamandem","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516502","Taluk":"Brahmamgarimattam","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516504","Taluk":"Lakkireddipalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516268","Taluk":"Veeraballe","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516213","Taluk":"Vontimitta","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516237","Taluk":"Sidhout","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516270","Taluk":"Rayachoti","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516505","Taluk":"Porumamilla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516151","Taluk":"Nandalur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516127","Taluk":"Penagalur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516128","Taluk":"Chitvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516213","Taluk":"Sidhout","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516193","Taluk":"B.Kodur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516110","Taluk":"Chitvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516126","Taluk":"Penagalur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516247","Taluk":"Sidhout","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516110","Taluk":"Peddarachapalli","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516108","Taluk":"Penagalur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516129","Taluk":"Rayachoti","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516115","Taluk":"Rajampet","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516193","Taluk":"Kalasapadu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516502","Taluk":"B.Kodur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516217","Taluk":"Sri Avadhutha Kasinayana","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516501","Taluk":"Badvel","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"515621","Taluk":"Kambadur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515124","Taluk":"Penukonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515311","Taluk":"Agali","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515281","Taluk":"Amarapuram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515154","Taluk":"Bukkapatnam","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515556","Taluk":"Amadagur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515144","Taluk":"Puttaparthi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515301","Taluk":"Madakasira","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515651","Taluk":"Hindupur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515661","Taluk":"Bathalapalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515631","Taluk":"Tadimarri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515672","Taluk":"Dharmavaram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515501","Taluk":"Bathalapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515134","Taluk":"Puttaparthi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515571","Taluk":"Tanakallu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515110","Taluk":"Kothacheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515651","Taluk":"Chennekothapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515305","Taluk":"Amarapuram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515261","Taluk":"Parigi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515211","Taluk":"Hindupur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515641","Taluk":"Kanaganapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515621","Taluk":"Kanaganapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515341","Taluk":"Chilamathur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515212","Taluk":"Hindupur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515331","Taluk":"Lepakshi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515202","Taluk":"Hindupur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515271","Taluk":"Gudibanda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515591","Taluk":"Kadiri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515531","Taluk":"Puttaparthi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515124","Taluk":"Somandepalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515144","Taluk":"Bukkapatnam","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515123","Taluk":"Roddam","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515511","Taluk":"Mudigubba","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515241","Taluk":"Gorantla","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515303","Taluk":"Madakasira","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515241","Taluk":"Somandepalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515601","Taluk":"Chilamathur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515122","Taluk":"Somandepalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515122","Taluk":"Somandepalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515331","Taluk":"Hindupur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515110","Taluk":"Penukonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515101","Taluk":"C.k.palli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515124","Taluk":"Roddam","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515501","Taluk":"Nallamada","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515541","Taluk":"Kadiri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515551","Taluk":"Nallacheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515672","Taluk":"Bathalapalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515531","Taluk":"O.d. Cheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515641","Taluk":"Kanaganapalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515291","Taluk":"Rolla","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515331","Taluk":"Chilamathur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515521","Taluk":"N.p. Kunta","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515671","Taluk":"Dharmavaram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515571","Taluk":"Tanakal","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515321","Taluk":"Rolla","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515531","Taluk":"Nallamada","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515164","Taluk":"Penukonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515581","Taluk":"Talupula","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515531","Taluk":"Obuladevaracheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515159","Taluk":"Dharmavaram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515501","Taluk":"Mudigubba","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515521","Taluk":"Gandlapenta","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515231","Taluk":"Gorantla","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515144","Taluk":"C.k.. Palli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515291","Taluk":"Madakasira","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515101","Taluk":"Ramagiri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515621","Taluk":"Ramagiri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515631","Taluk":"Bathalapalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515661","Taluk":"Bathalapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515212","Taluk":"Parigi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515581","Taluk":"N.p. Kunta","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515561","Taluk":"Obuladevaracheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515286","Taluk":"Gudibanda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515159","Taluk":"Mudigubba","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515305","Taluk":"Madakasira","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515286","Taluk":"Amarapuram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515201","Taluk":"Hindupur","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515305","Taluk":"Gudibanda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515591","Taluk":"O.d. Cheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515133","Taluk":"Puttaparthi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515561","Taluk":"Amadaguru","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515202","Taluk":"Bathalapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515501","Taluk":"Kadiri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515144","Taluk":"Kothacheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515651","Taluk":"C.k. Palli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515202","Taluk":"Lepakshi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515541","Taluk":"Mudigubba","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515621","Taluk":"Kanaganapalli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515133","Taluk":"Kothacheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515110","Taluk":"Puttaparthi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515159","Taluk":"Bukkapatnam","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515621","Taluk":"Kambaduru","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515541","Taluk":"Talupula","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515271","Taluk":"Rolla","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515154","Taluk":"Mudigubba","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515641","Taluk":"Raptadu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515101","Taluk":"C.k. Palli","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515672","Taluk":"Ramagiri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515661","Taluk":"Tadimarri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515561","Taluk":"Obuladevacheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515672","Taluk":"Bathalapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515241","Taluk":"Somandepalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515571","Taluk":"N.p. Kunta","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515231","Taluk":"Puttaparthi","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515621","Taluk":"Ramgiri","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515631","Taluk":"Dharmavaram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515651","Taluk":"Dharmavaram","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515591","Taluk":"Nallamada","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515134","Taluk":"Penukonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515134","Taluk":"Puttaparthy","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515291","Taluk":"Gudibanda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515561","Taluk":"O.d. Cheruvu","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515123","Taluk":"Penukonda","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515144","Taluk":"Puttaparthy","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"515101","Taluk":"Chennekothapalle","Districtname":"Ananthapur","statename":"ANDHRA PRADESH"},
    {"pincode":"518467","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518533","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518302","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518301","Taluk":"Adoni","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518225","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518405","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518465","Taluk":"Devanakonda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518463","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518220","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518395","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518216","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518390","Taluk":"Tuggali","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518313","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518005","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518347","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518347","Taluk":"Pattikonda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518422","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518350","Taluk":"Gonegandla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518222","Taluk":"Dhone","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518007","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518344","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518006","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518598","Taluk":"Bethamcherla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518533","Taluk":"Pamulapadu","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518350","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518323","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518401","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518396","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518348","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518218","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518462","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518599","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518004","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518411","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518508","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518412","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518222","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518432","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518216","Taluk":"Veldurthi","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518468","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518220","Taluk":"Peapally","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518533","Taluk":"Velgode","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518206","Taluk":"Bethamcherla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518002","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518380","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518206","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518380","Taluk":"Pattikonda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518221","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518390","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518464","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518345","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518442","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518308","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518346","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518333","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518360","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518465","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518221","Taluk":"Peapally","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518452","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518323","Taluk":"Adoni","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518323","Taluk":"Pattikonda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518442","Taluk":"Pamulapadu","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518422","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518463","Taluk":"Gonegandla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518010","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518220","Taluk":"Devanakonda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518360","Taluk":"Yemmiganur","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518508","Taluk":"Gadivemula","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518466","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518598","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518323","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518385","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518462","Taluk":"C.Belagal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518003","Taluk":"Kallur","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518220","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518349","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518003","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518217","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518343","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518206","Taluk":"Panyam","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518001","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518464","Taluk":"Kodumur","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518222","Taluk":"Krishnagiri","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518432","Taluk":"Nandikotkur","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518411","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518217","Taluk":"Veldurthi","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518467","Taluk":"NA","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518422","Taluk":"Jupadu Bungalow","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518217","Taluk":"Bethamcherla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518390","Taluk":"Maddikera (East)","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518385","Taluk":"Maddikera (East)","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518468","Taluk":"Tuggali","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518599","Taluk":"Bethamcherla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518343","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518411","Taluk":"Pagidyala","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518313","Taluk":"Mantralayam","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518412","Taluk":"Pagidyala","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518395","Taluk":"Adoni","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518468","Taluk":"Bethamcherla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518467","Taluk":"Krishnagiri","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518102","Taluk":"Srisailam","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518221","Taluk":"Tuggali","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518101","Taluk":"Kurnool","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518462","Taluk":"Nandavaram","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518468","Taluk":"Dhone","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518349","Taluk":"Tuggali","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518344","Taluk":"Adoni","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518360","Taluk":"Adoni","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518523","Taluk":"Nandyal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518123","Taluk":"Banaganapalli","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518543","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523372","Taluk":"Giddalur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518145","Taluk":"Sanjamala","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523328","Taluk":"Pullalacheruvu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523372","Taluk":"Cumbum","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523356","Taluk":"Racherla","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518196","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518112","Taluk":"Banaganapalle","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518165","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523373","Taluk":"Giddalur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518134","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523357","Taluk":"Giddalur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523330","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518135","Taluk":"Dornipadu","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523315","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518123","Taluk":"Kolimigundla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523326","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523368","Taluk":"Giddalur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523368","Taluk":"Racherla","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518176","Taluk":"Banaganapalle","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518124","Taluk":"Banaganapalle","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523305","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523335","Taluk":"Cumbum","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523335","Taluk":"Ardhaveedu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523370","Taluk":"Cumbum","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518502","Taluk":"Nandyal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523331","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523336","Taluk":"Cumbum","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523320","Taluk":"Peda  Araveedu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518573","Taluk":"Gospadu","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523346","Taluk":"Cumbum","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523333","Taluk":"Cumbum","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518510","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518583","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518122","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523334","Taluk":"Cumbum","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523332","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523327","Taluk":"Yerragondapalem","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518155","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518512","Taluk":"Nandyal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523332","Taluk":"Tarlupadu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523327","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523367","Taluk":"Giddalur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518553","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518135","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518594","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523320","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518511","Taluk":"Nandyal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523331","Taluk":"Dornala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523329","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523356","Taluk":"Giddalur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523334","Taluk":"Bestawaripeta","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518502","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523305","Taluk":"Donakonda","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518135","Taluk":"Koilakuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523328","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523326","Taluk":"Tripuranthakam","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518513","Taluk":"Nandyal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523330","Taluk":"Tripuranthakam","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518166","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523373","Taluk":"Komarolu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518674","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518573","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518583","Taluk":"Mahanandi","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518593","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523369","Taluk":"Giddalur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518186","Taluk":"Banaganapalle","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518123","Taluk":"Banaganapalle","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523346","Taluk":"Bestawaripeta","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518563","Taluk":"Allagadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518145","Taluk":"Koilkuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518593","Taluk":"Nandyal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523371","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518134","Taluk":"Koilakuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518513","Taluk":"Bandi Atmakur","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518573","Taluk":"Allgadda","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523336","Taluk":"Tripuranthakam","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523316","Taluk":"Markapur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523326","Taluk":"Markpur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518501","Taluk":"Nandyal","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523369","Taluk":"Komarolu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523371","Taluk":"Tarlupadu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518145","Taluk":"Koilakuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518122","Taluk":"Koilakuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518166","Taluk":"Koilakuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"523315","Taluk":"Markapur Ho","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"518166","Taluk":"Kolimigundla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518122","Taluk":"Owk","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518166","Taluk":"Sanjamala","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518593","Taluk":"Nandyal .","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518165","Taluk":"Koilakuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518563","Taluk":"Sirvel","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518112","Taluk":"Panyam","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518166","Taluk":"Banaganapalli","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518123","Taluk":"Banaganpallie","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518155","Taluk":"Koilakuntla","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518510","Taluk":"Sirvel","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"518573","Taluk":"Sirvel","Districtname":"Kurnool","statename":"ANDHRA PRADESH"},
    {"pincode":"516339","Taluk":"Chapad","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516421","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516454","Taluk":"Simhadripuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516396","Taluk":"Lingala","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516350","Taluk":"Vaimpalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516339","Taluk":"Payasampalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516356","Taluk":"Mydekur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516391","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516293","Taluk":"Vallur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516434","Taluk":"Jammalamdugu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516172","Taluk":"S.Mydukur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516355","Taluk":"Mydekur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516321","Taluk":"Verrapanayanipalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516484","Taluk":"Balapanur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516390","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516172","Taluk":"Mydekur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516362","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516289","Taluk":"Kamalapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516359","Taluk":"Rajupalam","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516350","Taluk":"Vempalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516411","Taluk":"Jammalamadugu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516484","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516175","Taluk":"Duvvur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516474","Taluk":"Tallaproddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516312","Taluk":"Yerraguntla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516432","Taluk":"Jammalamadugu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516401","Taluk":"Tondur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516433","Taluk":"NA","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516349","Taluk":"Vemula","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516431","Taluk":"NA","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516484","Taluk":"Simhadripuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516380","Taluk":"Muddanur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516321","Taluk":"Veerapunayunipalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516339","Taluk":"Veerapunayunipalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516356","Taluk":"Chapad","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516218","Taluk":"Nandimandalam","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516361","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516474","Taluk":"Kondapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516464","Taluk":"Lavanur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516310","Taluk":"Kamalapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516433","Taluk":"Jammalmadugu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516312","Taluk":"NA","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516411","Taluk":"Jammalamdugu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516175","Taluk":"NA","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516310","Taluk":"Yerraguntla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516444","Taluk":"Kondapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516360","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516434","Taluk":"Jammalamadugu","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516216","Taluk":"Pendlimarri","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516218","Taluk":"Pendlimarri","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516431","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516175","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516173","Taluk":"S.Mydukur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516359","Taluk":"Rajupalem","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516421","Taluk":"Thondur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516173","Taluk":"Mydekur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516349","Taluk":"NA","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516321","Taluk":"Veerapunayanipalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516330","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516339","Taluk":"Yearraguntla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516310","Taluk":"NA","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516355","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516401","Taluk":"Thondur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516432","Taluk":"Mylavaram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516350","Taluk":"Vaimapalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516339","Taluk":"Yerraguntla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516309","Taluk":"Yerraguntla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516444","Taluk":"Muddanur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516359","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516311","Taluk":"Yerraguntla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516464","Taluk":"Muddanur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516396","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516464","Taluk":"Kondapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516172","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516439","Taluk":"Mylavaram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516356","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516218","Taluk":"Cuddapah","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516433","Taluk":"Mylavaram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516173","Taluk":"Proddatur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516464","Taluk":"Yellanoor","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516339","Taluk":"Kamalapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516216","Taluk":"Cuddapah","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516411","Taluk":"Peddamudium","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516454","Taluk":"Pulivedla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516350","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516474","Taluk":"Muddanur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516329","Taluk":"Vaimpalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516401","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516329","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516293","Taluk":"Cuddapah","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516355","Taluk":"Kallur","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516329","Taluk":"Vempalle","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516321","Taluk":"Kamalapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516349","Taluk":"Pulivendla","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"516309","Taluk":"Kamalapuram","Districtname":"Cuddapah","statename":"ANDHRA PRADESH"},
    {"pincode":"517507","Taluk":"Tirupati (Rural)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517643","Taluk":"K.v.b.puram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517592","Taluk":"Nagari","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517589","Taluk":"Pichatur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517591","Taluk":"Nindra","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517587","Taluk":"Pissatur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517214","Taluk":"Piler","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517640","Taluk":"Srikalahasti","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517536","Taluk":"Akkurthi","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517645","Taluk":"Srikalahasti","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517571","Taluk":"Vadamalapeta","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517582","Taluk":"Karvetinagar","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517588","Taluk":"Satyavedu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517291","Taluk":"Gurramkonda","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517641","Taluk":"Thondamanadu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517619","Taluk":"Yerpedu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517551","Taluk":"Srikalahasti","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517236","Taluk":"Vayalpad","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517581","Taluk":"Puttur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517642","Taluk":"Buchinaidu Kandriga","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517520","Taluk":"Renigunta","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517102","Taluk":"Chandragiri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517277","Taluk":"Valmikipuram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517504","Taluk":"Tirupati (Urban)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517561","Taluk":"Ramachandrapuram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517641","Taluk":"Yerpedu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517213","Taluk":"Kambhamvaripalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517589","Taluk":"Pissatur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517194","Taluk":"Chinnagottigallu Mandal","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517501","Taluk":"Tirupati (Urban)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517642","Taluk":"Srikalahasti","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517194","Taluk":"Bhakarapet","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517569","Taluk":"Vedurukuppam","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517551","Taluk":"Vadamalpet","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517237","Taluk":"Kalikiri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517590","Taluk":"Nagari","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517505","Taluk":"Tirupati Rural","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517192","Taluk":"Rompicherla","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517101","Taluk":"Chandragiri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517526","Taluk":"Yerpedu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517583","Taluk":"Puttur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517305","Taluk":"Gurramkonda","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517113","Taluk":"Pulicherla","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517193","Taluk":"Chinnagottigallu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517541","Taluk":"Varadaiahpalem","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517193","Taluk":"Chinnagottigallu Mandal","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517234","Taluk":"Kalikiri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517277","Taluk":"Vayalpad","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517194","Taluk":"Chinnagottigallu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517112","Taluk":"Pakala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517520","Taluk":"Tirupati (Rural)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517152","Taluk":"Damalacheruvu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517503","Taluk":"Tirupati (Rural)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517172","Taluk":"Pulicherla","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517236","Taluk":"Kalakada","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517620","Taluk":"Yerpedu","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517235","Taluk":"Kalikiri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517644","Taluk":"Srikalahasti","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517213","Taluk":"G.k.palle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517620","Taluk":"Srikalahasti","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517152","Taluk":"Pakala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517297","Taluk":"Gurramkonda","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517586","Taluk":"Vijayapuram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517299","Taluk":"Valmikipuram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517571","Taluk":"Puttur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517586","Taluk":"Puttur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517645","Taluk":"Buchinaidu Kandriga","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517172","Taluk":"Pakala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517581","Taluk":"Narayanavanam","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517214","Taluk":"Pileru","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517587","Taluk":"Nindra","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517194","Taluk":"Yerravaripalem","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517587","Taluk":"Pichatur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517584","Taluk":"Puttur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517213","Taluk":"Pileru","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517235","Taluk":"Vayalpad","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517507","Taluk":"Tirupati Rural","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517237","Taluk":"Kalakada","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517213","Taluk":"Kalikiri","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517299","Taluk":"Vayalpad","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517551","Taluk":"Vadamalapeta","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517569","Taluk":"Venkata Krishna Puram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517503","Taluk":"Tirupati Rural","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517505","Taluk":"Pakala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517214","Taluk":"Kambhamvaripalle","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517586","Taluk":"Pannur","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517507","Taluk":"Tirupati (Urban)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517502","Taluk":"Tirupati (Urban)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517506","Taluk":"Renigunta","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517643","Taluk":"K.V.B.Puram","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517291","Taluk":"Vayalpad","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517641","Taluk":"Srikalahasti","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517502","Taluk":"Tirupati Rural","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517503","Taluk":"Tirupati (Urban)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517113","Taluk":"Pakala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517582","Taluk":"Pakala","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"517502","Taluk":"Tirupati (Rural)","Districtname":"Chittoor","statename":"ANDHRA PRADESH"},
    {"pincode":"534267","Taluk":"Poduru (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534266","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534260","Taluk":"Palakol (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534235","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534320","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534280","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534235","Taluk":"Akividu (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534126","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534240","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534208","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534202","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534330","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534250","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534230","Taluk":"Attili (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534217","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534199","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534123","Taluk":"Atchanta (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534134","Taluk":"Attili (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534243","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534207","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534202","Taluk":"Bhimavaram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534201","Taluk":"Bhimavaram (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534201","Taluk":"Bhimavaram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534206","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534124","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534216","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534204","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534281","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534269","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534268","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534275","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534227","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534123","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534239","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534239","Taluk":"Bhimavaram (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534237","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534266","Taluk":"Ellamanchili (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534328","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534268","Taluk":"Ellamanchili (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534225","Taluk":"Iragavaram(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534244","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534211","Taluk":"Tanuku","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534326","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534217","Taluk":"Iragavaram (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534122","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534265","Taluk":"Palakol (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534247","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534236","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534237","Taluk":"Kalla (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534230","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534222","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534338","Taluk":"Poduru(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534330","Taluk":"Peravali (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534269","Taluk":"Atchanta (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534210","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534240","Taluk":"Viravasaram(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534206","Taluk":"Kalla (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534209","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534260","Taluk":"Palacole","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534250","Taluk":"Viravasaram(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534238","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534134","Taluk":"Manchili (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534218","Taluk":"Tanuku (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534122","Taluk":"Penumantra (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534265","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534134","Taluk":"Minavilluru(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534327","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534209","Taluk":"Palakoderu (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534281","Taluk":"Mogalturu(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534227","Taluk":"U.varam(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534134","Taluk":"Moyyeru(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534324","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534275","Taluk":"Narasapuram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534275","Taluk":"Narsapur (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534210","Taluk":"Palakoderu (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534260","Taluk":"Palakol(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534225","Taluk":"Penumantra (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534338","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534320","Taluk":"Penugonda (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534267","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534124","Taluk":"Penumantra (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534328","Taluk":"Peravali (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534218","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534327","Taluk":"Poduru (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534238","Taluk":"Penumantra (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534247","Taluk":"Viravasaram (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534208","Taluk":"Bhimavaram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534204","Taluk":"Kalla (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534134","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534326","Taluk":"Penugonda (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534280","Taluk":"Narsapur (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534245","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534243","Taluk":"Palakoderu (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534211","Taluk":"Tanuku (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534207","Taluk":"Bhimavaram(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534199","Taluk":"Undi (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534324","Taluk":"Penugonda (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534222","Taluk":"Velpur (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534215","Taluk":"Tanuku (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534245","Taluk":"Viravasaram (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534244","Taluk":"Palakoderu(mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534216","Taluk":"Vundrajavaram (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534236","Taluk":"Kalla (mdl)","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534416","Taluk":"Kaikaram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534456","Taluk":"Jeelugumilli","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534449","Taluk":"Kamavarapukota","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534427","Taluk":"Gundugolanu","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534455","Taluk":"J.r.gudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534448","Taluk":"Buttayagudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534401","Taluk":"Pulla","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534449","Taluk":"K.kota","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534003","Taluk":"S.v.peta","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534426","Taluk":"Dwarakatirumala","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534002","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534312","Taluk":"Koyyalagudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534460","Taluk":"Chintalapudi","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534475","Taluk":"Vijaayrai","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534001","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534425","Taluk":"Bhimadole","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534007","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534462","Taluk":"Dharmajigudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534451","Taluk":"Lakkavaram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534004","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534406","Taluk":"Chebrole","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534432","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534426","Taluk":"D.tirumala","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534442","Taluk":"Kovvali","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534461","Taluk":"Pragadavaram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534006","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534005","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534467","Taluk":"T.narasapuram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534450","Taluk":"Gopannapalem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534437","Taluk":"Pedapadu","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534450","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534455","Taluk":"Jangareddigudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534437","Taluk":"Padapadu","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534004","Taluk":"Chataparru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534427","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534455","Taluk":"J.r.gudem Bo","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534475","Taluk":"Vijayarai","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534447","Taluk":"Jangareddigudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534447","Taluk":"J.r.gudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534452","Taluk":"Tadikalapudi","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534311","Taluk":"Kannapuram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534003","Taluk":"S.v. Peta","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534442","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534432","Taluk":"Denduluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534003","Taluk":"Eluru","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"521333","Taluk":"Kaikalur","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521390","Taluk":"Pamarru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521213","Taluk":"Musunuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521340","Taluk":"Kaikalur","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521343","Taluk":"Mudinepelli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521324","Taluk":"Bantumilli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521312","Taluk":"Vunguturu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521327","Taluk":"Nandivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521330","Taluk":"Gudlavalleru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521215","Taluk":"Reddigudem","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521202","Taluk":"Nuzvid","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521261","Taluk":"Vuyyuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521106","Taluk":"Nandivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521106","Taluk":"Bapulapadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521345","Taluk":"Mandavalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521207","Taluk":"Musunuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521105","Taluk":"Bapulapadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521301","Taluk":"Gudivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521263","Taluk":"Pedaparupudi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521111","Taluk":"Bapulapadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521260","Taluk":"Vunguturu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521343","Taluk":"Mudinepalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521215","Taluk":"Vissannapet","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521214","Taluk":"Chatrai","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521333","Taluk":"Mandavalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521324","Taluk":"Kruthivennu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521329","Taluk":"Mudinepalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521325","Taluk":"Mudinepalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521148","Taluk":"Pedaparupudi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521329","Taluk":"Bantumilli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521322","Taluk":"Gudivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521213","Taluk":"Chatrai","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521323","Taluk":"Gudivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521111","Taluk":"Nuzvid","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521213","Taluk":"Nuzvid","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521321","Taluk":"Nandivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521201","Taluk":"Nuzvid","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521322","Taluk":"Pamarru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521326","Taluk":"Nandivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521311","Taluk":"Vunguturu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521326","Taluk":"Mandavalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521325","Taluk":"Gudivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521214","Taluk":"Vissannapet","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521344","Taluk":"Kalidindi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521333","Taluk":"Kalidindi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521325","Taluk":"Mandavalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521260","Taluk":"Kankipadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521213","Taluk":"Vissannapet","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521333","Taluk":"Kalidinid","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521343","Taluk":"Kalidindi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521111","Taluk":"Billanapalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521324","Taluk":"Krithivennu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521327","Taluk":"Gudivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521321","Taluk":"Pedaparupudi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521261","Taluk":"Vuyyur","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521207","Taluk":"Nuzvid","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521105","Taluk":"Nuzvid","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521323","Taluk":"Mudinepalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521345","Taluk":"Bantumilli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521202","Taluk":"Nuz Id","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521323","Taluk":"NA","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521330","Taluk":"Gudivada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521343","Taluk":"Kaikalur","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521260","Taluk":"Kakulapadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521329","Taluk":"Mudinepalle","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521325","Taluk":"Gudlavalleru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"524342","Taluk":"Kaluvoya","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524129","Taluk":"Pellakur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524405","Taluk":"Manubolu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524121","Taluk":"Tada","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524131","Taluk":"Gudur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524345","Taluk":"Podalakur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524132","Taluk":"Venkatagiri","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524404","Taluk":"Balayapalli","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524407","Taluk":"Sydapuram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524123","Taluk":"Sullurpeta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524321","Taluk":"Survepalli","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524126","Taluk":"Naidupeta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524127","Taluk":"Naidupeta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524402","Taluk":"Vojili","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524127","Taluk":"Chittamuru","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524421","Taluk":"Naidupeta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524410","Taluk":"Gudur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524415","Taluk":"Vakadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524412","Taluk":"Chillakur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524309","Taluk":"Virur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524126","Taluk":"Naidupet","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524414","Taluk":"Rapur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524343","Taluk":"Kaluvoya","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524403","Taluk":"Chittamur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524134","Taluk":"Dakkili","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524341","Taluk":"Chejerla","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524320","Taluk":"Venkatachalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524406","Taluk":"Gudur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524408","Taluk":"Rapur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524412","Taluk":"Gudur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524127","Taluk":"Chittamur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524121","Taluk":"Sullurpeta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524409","Taluk":"Sydapuram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524123","Taluk":"Doravarisatram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524323","Taluk":"Venkatachalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524309","Taluk":"Podalakur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524413","Taluk":"Vakadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524101","Taluk":"Gudur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524401","Taluk":"Tada","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524320","Taluk":"Venkatachala Satram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524403","Taluk":"Doravarisatram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524411","Taluk":"Kota","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524409","Taluk":"Gudur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524126","Taluk":"Doravarisatram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524124","Taluk":"Sullurpeta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524121","Taluk":"Doravarisatram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"522004","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522237","Taluk":"Tulluru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522015","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522007","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522020","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522236","Taluk":"Tadikonda","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522017","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522235","Taluk":"Pedanandipadu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522005","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522112","Taluk":"Kakumanu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522503","Taluk":"Mangalagiri","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522302","Taluk":"Mangalagiri Ho","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522018","Taluk":"Ponnekallu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522502","Taluk":"Mangalagiri Ho","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522002","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522001","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522508","Taluk":"Mangalagiri Ho","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522305","Taluk":"Pedapalem","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522303","Taluk":"Nutakki","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522003","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522006","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522019","Taluk":"Prathipadu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522020","Taluk":"Amaravathi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522016","Taluk":"Lemalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522509","Taluk":"Pedakakani","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522034","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522302","Taluk":"Pedavadlapudi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522502","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522112","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522508","Taluk":"Namburu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522302","Taluk":"K.c.works","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522501","Taluk":"Tadepalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522016","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522237","Taluk":"Mangalagiri Ho","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522510","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522508","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522303","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522509","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522235","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522009","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522302","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522009","Taluk":"Medikonduru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522018","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522019","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522502","Taluk":"Mangalagiri","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522501","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522236","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522237","Taluk":"Guntur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"507316","Taluk":"P.r.gudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507121","Taluk":"Kunavaram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507160","Taluk":"Nkpally","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507204","Taluk":"Bonnakal","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507124","Taluk":"Kothagudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507203","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507318","Taluk":"Vvpalem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507133","Taluk":"Charla","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507114","Taluk":"Burgampahad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507203","Taluk":"Madhira","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507116","Taluk":"Manuguru","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507305","Taluk":"Tanikella","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507158","Taluk":"Gokinepalli","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507301","Taluk":"Aswaraopet","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507117","Taluk":"Manugurq","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507208","Taluk":"Chintakani","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507124","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507306","Taluk":"Dammapeta","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507167","Taluk":"Tallada","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507169","Taluk":"Ch.madharam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507164","Taluk":"Vemsur","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507163","Taluk":"T. Palem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507163","Taluk":"T.palem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507168","Taluk":"Enkoor","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507137","Taluk":"Dummagudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507304","Taluk":"Siripuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507301","Taluk":"Aswaraopeta","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507116","Taluk":"Manugur","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507202","Taluk":"Bnpahad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507123","Taluk":"Yellandu","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507111","Taluk":"Bhadrachalam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507161","Taluk":"Subled","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507211","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507002","Taluk":"Khammam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507118","Taluk":"Kothagudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507202","Taluk":"B.n.pahad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507209","Taluk":"Kallur","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507303","Taluk":"Sathupalli","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507302","Taluk":"Penuballi","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507117","Taluk":"Manuguru","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507211","Taluk":"Bayyaram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507157","Taluk":"Palair","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507101","Taluk":"Kothagudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507204","Taluk":"Bommakal","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507210","Taluk":"Garla","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507182","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507001","Taluk":"Khammam (Urban)","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507122","Taluk":"Karepalli","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507136","Taluk":"Venkatapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507118","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507129","Taluk":"Chintur","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507170","Taluk":"Tallampahad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507129","Taluk":"Nellipaka","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507182","Taluk":"Komminepalli","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507128","Taluk":"Bhadrachalam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507135","Taluk":"Vr Puram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507113","Taluk":"Mothugudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507204","Taluk":"Bonakal","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507166","Taluk":"Julurupadu","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507129","Taluk":"Bhadrachalam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507129","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507002","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507003","Taluk":"Khammam (Urban)","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507114","Taluk":"Burghampahad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507183","Taluk":"Pindipole","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507115","Taluk":"Paloncha","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507003","Taluk":"Khammam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507130","Taluk":"Donkarayi","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"507201","Taluk":"Yerrupalem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507003","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507134","Taluk":"Gdpeta","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507165","Taluk":"Wyra","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507120","Taluk":"Sujathanagar","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507159","Taluk":"Kusumanchi","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507140","Taluk":"Snpuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507138","Taluk":"Gouthampur","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507116","Taluk":"Aswapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507002","Taluk":"Khammam (Urban)","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507135","Taluk":"Vrpuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507166","Taluk":"Julurpahad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507123","Taluk":"Kothagudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507170","Taluk":"Kusumanchi","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507001","Taluk":"Khammam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507124","Taluk":"Yellandu Colls","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507114","Taluk":"Burgamapahad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507140","Taluk":"S.n.puram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507169","Taluk":"Cheruvumadhram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507137","Taluk":"Bhadrachalam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507301","Taluk":"Awaraopet","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507160","Taluk":"Nkpalli","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507125","Taluk":"Manuguru","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507117","Taluk":"Manugur","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507166","Taluk":"Julurupad","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507115","Taluk":"Palwancha","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507316","Taluk":"P R Gudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507103","Taluk":"Vkhani","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507136","Taluk":"Vennkatapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507183","Taluk":"Pindipolu","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507123","Taluk":"Yelladu","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507135","Taluk":"V.r.puram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507119","Taluk":"Rudrampur","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507140","Taluk":"Satyanarayanapuram","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507128","Taluk":"Sarapaka","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507154","Taluk":"Palwancha","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507303","Taluk":"Sathupalle","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507120","Taluk":"Sujathanagarq","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507169","Taluk":"Cheruvumadharam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507133","Taluk":"Charal","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507103","Taluk":"Kothagudem","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507124","Taluk":"Yellandu","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"507201","Taluk":"Khammam","Districtname":"Khammam","statename":"TELANGANA"},
    {"pincode":"521366","Taluk":"Pedana","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521149","Taluk":"Guduru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521369","Taluk":"Bantumilli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521122","Taluk":"Avanigadda","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521121","Taluk":"Avanigadda","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521138","Taluk":"Movva","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521001","Taluk":"Machilipatnam","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521136","Taluk":"Movva","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521120","Taluk":"Nagayalanka","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521131","Taluk":"Challapalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521126","Taluk":"Challapalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521002","Taluk":"Bandar","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521132","Taluk":"Ghantasala","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521003","Taluk":"Bandar","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521003","Taluk":"Machilipatnam","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521125","Taluk":"Mopidevi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521150","Taluk":"Movva","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521356","Taluk":"Gudlavalleru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521157","Taluk":"Pamarru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521131","Taluk":"Ghantasala","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521133","Taluk":"Ghantasala","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521328","Taluk":"Koduru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521332","Taluk":"Gudlavallru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521002","Taluk":"Machilipatnam","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521156","Taluk":"Guduru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521135","Taluk":"Movva","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521332","Taluk":"Gudlavalleru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521150","Taluk":"Guduru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521331","Taluk":"Gudlavalleru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521331","Taluk":"Gudlavallru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521130","Taluk":"Mopidevi","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521001","Taluk":"Bandar","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521150","Taluk":"Ghantasala","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521162","Taluk":"Guduru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521156","Taluk":"Movva","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521158","Taluk":"Pamarru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521158","Taluk":"Movva","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521369","Taluk":"Bantumalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521328","Taluk":"Avanigadda","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521132","Taluk":"Challapalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521002","Taluk":"Machillipatnam","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"522402","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522612","Taluk":"Durgi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522647","Taluk":"Vinukonda","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522659","Taluk":"Nuzendla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522617","Taluk":"Rompicherla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522415","Taluk":"Gurazalla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522529","Taluk":"Phirangipuram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522410","Taluk":"Krosuru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522658","Taluk":"Ipuru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522603","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522616","Taluk":"Chilakaluripet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522611","Taluk":"Chilakaluripet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522601","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522409","Taluk":"Atchampet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522436","Taluk":"Pedakurapadu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522412","Taluk":"Rajupalem","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522663","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522402","Taluk":"Pedakurapadu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522411","Taluk":"Bellamkonda","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522549","Taluk":"Nadendla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522412","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522613","Taluk":"Veldurthy","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522408","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522626","Taluk":"Chilakaluripet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522437","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522661","Taluk":"Ipuru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522615","Taluk":"Nekarikallu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522435","Taluk":"Machavaram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522659","Taluk":"Vinukonda","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522616","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522437","Taluk":"Piduguralla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522649","Taluk":"Vinukonda","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522614","Taluk":"Karempudi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522234","Taluk":"Nadendla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522414","Taluk":"Dachepalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522408","Taluk":"Muppalla(sap)","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522663","Taluk":"Ipuru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522233","Taluk":"Edlapadu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522549","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522411","Taluk":"NA","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522619","Taluk":"Nadendla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522403","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522426","Taluk":"Macherla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522421","Taluk":"Rentachintala","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522657","Taluk":"Vinukonda","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522413","Taluk":"Piduguralla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522233","Taluk":"Edlapdu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522660","Taluk":"Nuzendla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522412","Taluk":"Bollapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522646","Taluk":"Savalyapuram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522438","Taluk":"Medikonduru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522647","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522529","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522613","Taluk":"Macherla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522435","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522408","Taluk":"Sttenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522415","Taluk":"Gurzalla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522401","Taluk":"Medikonduru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522408","Taluk":"Muppalla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522661","Taluk":"Vinukonda","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522410","Taluk":"Sattenapalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522603","Taluk":"Narassaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522649","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522402","Taluk":"Pedakurpadu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522413","Taluk":"Pidugurlalla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522649","Taluk":"NA","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522426","Taluk":"Narasaraopet","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522439","Taluk":"Macherla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522408","Taluk":"Muppalla(sa)","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"524002","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524142","Taluk":"Bitragunta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524318","Taluk":"Vidavalur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524312","Taluk":"Marripadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524203","Taluk":"Kavali","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524152","Taluk":"Damavaram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524315","Taluk":"Allur Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524303","Taluk":"Revur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524302","Taluk":"Anantasagaram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524344","Taluk":"Muthukur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524225","Taluk":"Siddanakonduru","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524004","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524203","Taluk":"Anemadugu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524306","Taluk":"Duvvur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524223","Taluk":"Jaladanki","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524304","Taluk":"A.s.peta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524304","Taluk":"Anumasamudrampeta Mandal","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524322","Taluk":"Atmakur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524222","Taluk":"Duthalur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524307","Taluk":"Karatampadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524236","Taluk":"Gandipalem","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524310","Taluk":"Seetharamapuram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524226","Taluk":"Udayagiri","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524315","Taluk":"Allur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524228","Taluk":"Vinjamoor","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524003","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524230","Taluk":"Nandipadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524240","Taluk":"Dagadarthi","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524319","Taluk":"Kodavaluru","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524346","Taluk":"Muthukur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524305","Taluk":"Buchireddypalem","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524305","Taluk":"Buchireddipalem Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524223","Taluk":"Kavali","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524221","Taluk":"Chakalakonda","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524137","Taluk":"Kovur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524224","Taluk":"Kaligiri","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524227","Taluk":"Varikuntapadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524239","Taluk":"Kondapuram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524308","Taluk":"Sangam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524239","Taluk":"Kavali","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524201","Taluk":"Kavali","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524316","Taluk":"Kodavaluru","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524316","Taluk":"Kodavalur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524306","Taluk":"Buchireddypalem Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524311","Taluk":"Tp Gudur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524317","Taluk":"Gandavaram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524317","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524313","Taluk":"Mypadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524234","Taluk":"Gumparlapadu","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524314","Taluk":"Indukurpeta","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524346","Taluk":"Brahmadevam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524307","Taluk":"Atmakur(nl)","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524302","Taluk":"Ananatasagaram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524316","Taluk":"Kodavaluru Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524308","Taluk":"NA","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524137","Taluk":"Kovur Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524301","Taluk":"S.s.project","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524366","Taluk":"Nr Palem","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524344","Taluk":"Muthukur Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524313","Taluk":"Indukurpeta Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524319","Taluk":"Kodavalur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524001","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524322","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524366","Taluk":"Northrajupalem Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524301","Taluk":"Ss Project","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524005","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524303","Taluk":"Atmakur(nl)","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524308","Taluk":"Atmakur(nl)","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524310","Taluk":"Seetaramapuram","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524301","Taluk":"Atmakur(nl)","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524311","Taluk":"Nellore","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524318","Taluk":"Kodavalur Mandalam","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"524228","Taluk":"Vinjamur","Districtname":"Nellore","statename":"ANDHRA PRADESH"},
    {"pincode":"523171","Taluk":"NA","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523001","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523201","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523169","Taluk":"Parchur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523240","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523274","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523304","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523212","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523286","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523182","Taluk":"Kotha Patnam","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523112","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523180","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523116","Taluk":"Voletivaripalem","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523281","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523270","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523101","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523301","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523115","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523211","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523253","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523214","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523292","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523304","Taluk":"Kurichedu","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523170","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523271","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523116","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523110","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523225","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523113","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523245","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523261","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523223","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523254","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523226","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523246","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523252","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523167","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523104","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523114","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523265","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523155","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523183","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523169","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523108","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523157","Taluk":"Chiral","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523247","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523214","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523263","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523109","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523262","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523303","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523291","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523260","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523135","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523181","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523279","Taluk":"NA","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523227","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523111","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523117","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523157","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523279","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523190","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523182","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523187","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523186","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523264","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523171","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523241","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523280","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523224","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523166","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523169","Taluk":"Chiral","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523228","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523214","Taluk":"Prakasam","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523165","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523185","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523253","Taluk":"Prakasam","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523302","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523105","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523272","Taluk":"NA","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523230","Taluk":"Kanigiri","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523168","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523213","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523002","Taluk":"Ongole","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523156","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523184","Taluk":"Chirala","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523273","Taluk":"Kandukur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523201","Taluk":"Addanki","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523187","Taluk":"Vetapalem","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523274","Taluk":"Tangutur","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523279","Taluk":"Prakasam","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"523240","Taluk":"Podili","Districtname":"Prakasam","statename":"ANDHRA PRADESH"},
    {"pincode":"534197","Taluk":"Ganapavaramandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534198","Taluk":"Ganapavaram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534331","Taluk":"Peravali Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534196","Taluk":"Pentapadu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534146","Taluk":"Pentapadu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534111","Taluk":"Nallajerla Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534341","Taluk":"Tallapudi Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534101","Taluk":"Tadepalligudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534186","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534145","Taluk":"Tadepalligudem Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534197","Taluk":"Ganapavarammandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534340","Taluk":"Kovvuru Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534111","Taluk":"Nallajerla Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534302","Taluk":"Nidadavole Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534112","Taluk":"Nallajarlamandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534156","Taluk":"Pentapadu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534411","Taluk":"Vunguturu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534340","Taluk":"Tallapudi Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534313","Taluk":"Devarapalli Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Polavarammandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534301","Taluk":"Nidadavole","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534341","Taluk":"Gopalapuram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534198","Taluk":"Ganapavaram Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534196","Taluk":"Tadepalligudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Polavaram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534301","Taluk":"Nidadavolemandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534196","Taluk":"Pentapadu Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534342","Taluk":"Chagallu Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534342","Taluk":"Chagally Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534176","Taluk":"Nallajarla Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534316","Taluk":"Gopalapuram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534305","Taluk":"Chagallu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534316","Taluk":"Deverapalli Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534195","Taluk":"Nidamarrumandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534145","Taluk":"Tadepalligudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534166","Taluk":"Pentapadumandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534313","Taluk":"Deverapalli","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534342","Taluk":"Kovvur","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534318","Taluk":"Gopalapuram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534112","Taluk":"Nallajarla Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534313","Taluk":"Deverapalli Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534156","Taluk":"Tanuku Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534198","Taluk":"Ganapavarammandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534186","Taluk":"Palakoderu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534316","Taluk":"Gopalapuram Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534313","Taluk":"Devarapalli Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534350","Taluk":"Kovvur","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534111","Taluk":"Nallajerla","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534318","Taluk":"Buttayagudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534341","Taluk":"Polavaram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534318","Taluk":"Gopalapuram Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534342","Taluk":"Kovvur Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Buttayagudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534102","Taluk":"Tadepalligudem Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534186","Taluk":"Ganapavaram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534102","Taluk":"Tadepalligudem","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534329","Taluk":"Peravali Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534331","Taluk":"Peravali","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534165","Taluk":"Vunguturu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534329","Taluk":"Undrajavaram Mandalam.","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534342","Taluk":"Kovvuru Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534196","Taluk":"Ganapavaramm Andalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534305","Taluk":"Wgdt","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534186","Taluk":"Ganapavaram Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534196","Taluk":"Ganapavaram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Devipatnammandalam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Polavarammandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534305","Taluk":"Nidadavole Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534146","Taluk":"Tadepalligudem Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534318","Taluk":"Polavaram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534305","Taluk":"Devarapalli Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Devipatnam Mandalam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534342","Taluk":"Chagallu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534165","Taluk":"Pentapadu Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534146","Taluk":"Tanuku Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534329","Taluk":"Nidadavole Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534112","Taluk":"Nallajerla Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534195","Taluk":"Nidamarry Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534156","Taluk":"Tanuku Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534166","Taluk":"Pentapadu","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534197","Taluk":"Ganapavaram Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Polavaram","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534176","Taluk":"Nallajarla Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534318","Taluk":"Polavaram Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534146","Taluk":"Pentapadu Mandall","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534112","Taluk":"Nallajerlamandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534156","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534318","Taluk":"Buttayagudem Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534302","Taluk":"Nidadavole","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534305","Taluk":"Nidadavole Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534341","Taluk":"Tallapudi Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534315","Taluk":"Devipatnam Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534302","Taluk":"Kovvuru Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534197","Taluk":"Ganapavarammandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534340","Taluk":"Tallapudi Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534329","Taluk":"Undrajavaram Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534102","Taluk":"Tadepalligudem Mandaolam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534302","Taluk":"NA","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534186","Taluk":"Undi Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534411","Taluk":"Vunguturu","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534165","Taluk":"Pentapadu Mandal","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"534316","Taluk":"Devarapalli Mandalam","Districtname":"West Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"522102","Taluk":"Bapatla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522262","Taluk":"Kuchinapudi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522312","Taluk":"Turumella","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522256","Taluk":"Bhattiprolu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522259","Taluk":"Cherukupalli","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522314","Taluk":"Nizampatnam","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522124","Taluk":"Ponnuru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522325","Taluk":"Amartaluru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522257","Taluk":"Bhattiprole","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522213","Taluk":"Chebrole","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522301","Taluk":"Anantavaram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522211","Taluk":"Angalakudur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522310","Taluk":"Appikatla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522316","Taluk":"Patsalatadiparru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522309","Taluk":"Cherukupalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522306","Taluk":"Tenali","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522261","Taluk":"Vemuru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522101","Taluk":"Bapatla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522268","Taluk":"Nagaram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522265","Taluk":"Repalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522311","Taluk":"Bapatla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522341","Taluk":"Inturu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522324","Taluk":"Kolluru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522315","Taluk":"Machavaram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522212","Taluk":"Bapatla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522111","Taluk":"Karlapalem","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522311","Taluk":"Chandole","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522212","Taluk":"Chebrole","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522113","Taluk":"Cheruvu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522330","Taluk":"Duggirala","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522201","Taluk":"NA","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522313","Taluk":"Amartaluru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522124","Taluk":"Ponnur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522304","Taluk":"Kollipara","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522264","Taluk":"Chodayapalem","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522258","Taluk":"Dhulipudi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522308","Taluk":"Emani","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522201","Taluk":"Tenali","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522264","Taluk":"Repalle","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522310","Taluk":"Bapatla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522307","Taluk":"Tenali","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522341","Taluk":"Intur","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522259","Taluk":"Kanagala","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522202","Taluk":"Tenali","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522317","Taluk":"Stuvartpuram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522329","Taluk":"Bapatla","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522258","Taluk":"Nagaram","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522307","Taluk":"Kolakaluru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522313","Taluk":"Kuchipudi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522318","Taluk":"Tsunduru","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522113","Taluk":"Cheruuvu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522306","Taluk":"Nandivelugu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522202","Taluk":"NA","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522213","Taluk":"Sangamjagarlamudi","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522308","Taluk":"Duggirala","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"522257","Taluk":"Vellaturu","Districtname":"Guntur","statename":"ANDHRA PRADESH"},
    {"pincode":"520003","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521226","Taluk":"A.Konduru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520012","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520008","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521212","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521250","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521245","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521101","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521181","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521109","Taluk":"Telaprolu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520012","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521165","Taluk":"Vuyyuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521403","Taluk":"Gampalagudem","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521190","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521175","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520002","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521286","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521229","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521211","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520007","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520015","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521102","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521178","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520011","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521163","Taluk":"Thotlavalluru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521182","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521151","Taluk":"Kankipadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521163","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521185","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521230","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520010","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521227","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521226","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521185","Taluk":"Nandigama","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521180","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521456","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521230","Taluk":"Mylavaram","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521245","Taluk":"Akunuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521401","Taluk":"Penugolanu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521183","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521235","Taluk":"Tiruvuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520001","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521139","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521170","Taluk":"Veerullapadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521402","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521170","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521108","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521403","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521165","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521101","Taluk":"Gannavaram","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521225","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521256","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521151","Taluk":"Vuyyuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521104","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521182","Taluk":"Chandarlapadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520004","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521241","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521247","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521246","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521228","Taluk":"Kondapalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521457","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521164","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521227","Taluk":"A.Konduru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521164","Taluk":"Katuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521151","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520007","Taluk":"Vijayawada (Rural)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521182","Taluk":"Chandaralpadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521228","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521153","Taluk":"Vuyyuru","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521228","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521227","Taluk":"NA","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521110","Taluk":"Veeravalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521211","Taluk":"Agiripalle","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521403","Taluk":"Gamplaguedem","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520013","Taluk":"Vijayawada (Urban)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521211","Taluk":"Vja","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521175","Taluk":"Jaggayyapeta","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521457","Taluk":"Jaggaiahpet","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521107","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521104","Taluk":"Vijayawada","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521134","Taluk":"Poranki","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521402","Taluk":"Vatsavai","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521401","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521182","Taluk":"Chadarlapadu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521137","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521108","Taluk":"Vijayawada (Rural)","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521190","Taluk":"Penuganchiprolu","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521134","Taluk":"Penamaluru Mandal","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521109","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521211","Taluk":"Aukiripalli","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521235","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521110","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"520001","Taluk":"Krishna","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521403","Taluk":"Gamplagudem","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"521456","Taluk":"Ibrahimpatnam","Districtname":"Krishna","statename":"ANDHRA PRADESH"},
    {"pincode":"533577","Taluk":"Amalapuram.","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533252","Taluk":"Sakhinetipalli Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533247","Taluk":"Mamidikuduru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533216","Taluk":"Mummidivaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533211","Taluk":"Ainavilli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533217","Taluk":"Allavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533201","Taluk":"Amalapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533214","Taluk":"Ambajipeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533222","Taluk":"Uppalaguptan Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533236","Taluk":"Atreyapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533252","Taluk":"NA","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533247","Taluk":"Razole","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533252","Taluk":"Razole Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533248","Taluk":"Razole","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533235","Taluk":"Atreyapuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533229","Taluk":"Kothapeta Taluk","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533212","Taluk":"Katrenikona","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533221","Taluk":"Amalapuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533210","Taluk":"Allavaram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533222","Taluk":"Amalapuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533222","Taluk":"Uppalaguptam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533228","Taluk":"Kothapeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533235","Taluk":"Atreyapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533215","Taluk":"Allavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533213","Taluk":"Uppalaguptam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533222","Taluk":"Katrenikona Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533250","Taluk":"Sakhinetipalli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533244","Taluk":"Razole","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533240","Taluk":"D.gannavaram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533217","Taluk":"Allavaram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533223","Taluk":"Kothapeta Taluk","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533244","Taluk":"Malikipuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533274","Taluk":"P.gannavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533221","Taluk":"Amalapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533274","Taluk":"Kothapeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533248","Taluk":"Mamidikuduru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533251","Taluk":"Sakhinetipalli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533274","Taluk":"Ravulapalem Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533218","Taluk":"Amalapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533253","Taluk":"Malikipuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533222","Taluk":"Mummidivaram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533220","Taluk":"I.polavaram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533254","Taluk":"Malikipuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533239","Taluk":"Ambajipeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533238","Taluk":"Ravulapalem","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533249","Taluk":"Razole","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533577","Taluk":"Amalapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533577","Taluk":"Ainavilli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533213","Taluk":"Amalapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533241","Taluk":"Ambajipeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533212","Taluk":"Katrenikona Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533220","Taluk":"I.polavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533218","Taluk":"Allavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533210","Taluk":"Allavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533216","Taluk":"Mummidivaram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533240","Taluk":"P.gannavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533254","Taluk":"Malikipuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533236","Taluk":"Ravulapalem","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533237","Taluk":"Atreypauram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533228","Taluk":"Ainavilli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533253","Taluk":"Malikipuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533249","Taluk":"Mamidikuduru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533250","Taluk":"Sakhinetipalli Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533241","Taluk":"Ambajipeta Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533211","Taluk":"Ainavilli Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533274","Taluk":"Ravulapalem","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533214","Taluk":"P.gannavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533214","Taluk":"P.gannavaram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533222","Taluk":"Uppalaguptam Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533247","Taluk":"Mamidikuduru Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533249","Taluk":"P.gannavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533241","Taluk":"Ainavilli Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533228","Taluk":"Ambajipeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533222","Taluk":"Katrenikona","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533218","Taluk":"Amalapuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533248","Taluk":"Razole Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533239","Taluk":"Ambajipeta Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533238","Taluk":"Ravulapalem Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533242","Taluk":"Razole","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533236","Taluk":"Atreyapuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533251","Taluk":"Sakhinetipalli Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533213","Taluk":"Amalapuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533250","Taluk":"Malikipuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533244","Taluk":"Razole Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533249","Taluk":"Razole Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533221","Taluk":"Ambajipeta Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533237","Taluk":"Atreyapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533241","Taluk":"Amabajipeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533228","Taluk":"Kothapeta Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533577","Taluk":"Ainavilli Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533237","Taluk":"Atreyapuram Mandal","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"531022","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531027","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531115","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531077","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531035","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531040","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531030","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531002","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531117","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531055","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531001","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531118","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531111","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531084","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531028","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531151","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531149","Taluk":"Araku","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531151","Taluk":"Araku Valley","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531036","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531034","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531031","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531011","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531029","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531085","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531149","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531021","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531113","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531082","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531023","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531081","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531087","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531026","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531020","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531025","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531083","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531024","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531032","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531105","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531027","Taluk":"Paderu","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531133","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531030","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531061","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531114","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531126","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531082","Taluk":"Yellamanchili","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531075","Taluk":"Chodavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531127","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531149","Taluk":"Araku Valley","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531055","Taluk":"Atchutapuram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531019","Taluk":"Paravada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531033","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531019","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531060","Taluk":"Anakapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531111","Taluk":"Chintapalle","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531081","Taluk":"Yellamanchili","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531116","Taluk":"Narsipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531020","Taluk":"Paravada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531084","Taluk":"Golugonda","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531126","Taluk":"Yellamanchili","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531083","Taluk":"Yellamanchili","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531127","Taluk":"Yellamanchili","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531115","Taluk":"Nathavaram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531036","Taluk":"Cheedikada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531055","Taluk":"Yellamanchili","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531030","Taluk":"Ananthagiri","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"533408","Taluk":"Thondangi","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533449","Taluk":"Gollaprolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533401","Taluk":"Tuni","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533428","Taluk":"Addatigala","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533407","Taluk":"Kotananduru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533448","Taluk":"Kothapalli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533436","Taluk":"Rajavommangi","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533406","Taluk":"Sankhavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533437","Taluk":"Peddapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533005","Taluk":"Kakinada (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533016","Taluk":"Karapa","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533468","Taluk":"Kakinada","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533005","Taluk":"Kakinada","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533006","Taluk":"Kakinada","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533431","Taluk":"Kirlampudi","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533429","Taluk":"Yeleswaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533003","Taluk":"Kakinada (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533428","Taluk":"Addateegala","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533445","Taluk":"Gollaprolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533433","Taluk":"Pithapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533483","Taluk":"Y .ramavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533450","Taluk":"Peddapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533444","Taluk":"Gollaprolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533432","Taluk":"Prathipadu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533446","Taluk":"Sankhavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533344","Taluk":"Pedapudi","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533450","Taluk":"Pithapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533461","Taluk":"Kakinada","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533002","Taluk":"Kakinada (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533461","Taluk":"Tallarevu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533483","Taluk":"NA","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533430","Taluk":"Prathipadu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533291","Taluk":"Rajavommangi","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533464","Taluk":"Pondicherry","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533463","Taluk":"Tallarevu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533004","Taluk":"Kakinada (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533468","Taluk":"Kajuluru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533001","Taluk":"Kakinada (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533435","Taluk":"Jaggampeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533434","Taluk":"Samalkot","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533006","Taluk":"Kakinada Rural","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533464","Taluk":"Tallarevu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533006","Taluk":"Pedapudi","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533001","Taluk":"Kakinada","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533462","Taluk":"Karapa","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533464","Taluk":"Thallarevu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533447","Taluk":"Kothapalli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533016","Taluk":"Kakinada","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533260","Taluk":"Karapa","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533450","Taluk":"NA","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533463","Taluk":"Thallarevu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533291","Taluk":"Rangampeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533440","Taluk":"Samalkota","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533440","Taluk":"Kakinada","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533428","Taluk":"Addathigala","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533016","Taluk":"Kakinada (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533450","Taluk":"pithapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533483","Taluk":"Y Ramavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533464","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"533260","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533447","Taluk":"Addathigala","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533428","Taluk":"Kothapalli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"535547","Taluk":"Makkuva","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532440","Taluk":"R.amadalavalasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535502","Taluk":"NA","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535546","Taluk":"Balijipeta","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535124","Taluk":"Badangi","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535573","Taluk":"Bobbili","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535126","Taluk":"Therlam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535523","Taluk":"Gummalakshmipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535557","Taluk":"Balijipeta","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535525","Taluk":"Jiyyammavalasa","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535593","Taluk":"Salur","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535521","Taluk":"Komarada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535546","Taluk":"Seethanagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532440","Taluk":"Palakonda","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535579","Taluk":"Ramabhadrapuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535101","Taluk":"Garividi","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532462","Taluk":"Palakonda","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535578","Taluk":"Badangi","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535502","Taluk":"Parvatipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535527","Taluk":"Parvatipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535591","Taluk":"Salur","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535559","Taluk":"Balijipeta","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535125","Taluk":"Sigadam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535102","Taluk":"Merakamudidam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535128","Taluk":"Merakamudidam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532462","Taluk":"Veeraghattam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535559","Taluk":"Bobbili","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535558","Taluk":"Bobbili","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532148","Taluk":"Sigadam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535594","Taluk":"Jiyyammavalasa","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535128","Taluk":"Garividi","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535524","Taluk":"Kurupam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535463","Taluk":"Garugubilli","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535579","Taluk":"Dattirajeru","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532460","Taluk":"Veeraghattam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535463","Taluk":"Parvatipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535526","Taluk":"Jiyyammavalasa","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535128","Taluk":"Chipurupalle","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535527","Taluk":"Makkuva","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535128","Taluk":"Sigadam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535125","Taluk":"Chipurupalle","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535101","Taluk":"Gurla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535534","Taluk":"Kurupam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532460","Taluk":"Seethampeta","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535559","Taluk":"Seethanagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535592","Taluk":"Pachipenta","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535124","Taluk":"Therlam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535102","Taluk":"Meraka Mudidam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535128","Taluk":"Rajam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532443","Taluk":"Seethampeta","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535557","Taluk":"Vangara","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535525","Taluk":"Parvatipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535591","Taluk":"Pachipenta","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535101","Taluk":"Merakamudidam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535501","Taluk":"Parvathipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535521","Taluk":"NA","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535594","Taluk":"Jiyyammavalsa","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535573","Taluk":"Makkuva","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535568","Taluk":"Bobbili","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535522","Taluk":"Parvatipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532440","Taluk":"R. Amadalavalasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535502","Taluk":"Parvathipuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532461","Taluk":"Vangara","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535546","Taluk":"Makkuva","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535525","Taluk":"Garugubilli","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535547","Taluk":"Salur","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535573","Taluk":"Seethanagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535124","Taluk":"Merakamudidam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535568","Taluk":"Therlam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532148","Taluk":"Regidi Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535578","Taluk":"Dattirajeru","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535579","Taluk":"Bobbili","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535568","Taluk":"Badangi","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535593","Taluk":"Makkuva","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532462","Taluk":"Vangara","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535578","Taluk":"Dattirajeru Bo","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532148","Taluk":"Ganguvada Sigadam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"535527","Taluk":"Seethanagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535557","Taluk":"NA","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"533262","Taluk":"Pamarru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533296","Taluk":"NA","Districtname":"NULL","statename":"NULL"},
    {"pincode":"533288","Taluk":"Rampachodavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533233","Taluk":"Alamuru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533101","Taluk":"Rajahmundry (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533305","Taluk":"Pamarru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533284","Taluk":"Gangavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533342","Taluk":"Anaparthy","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533307","Taluk":"Kapileswarapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533106","Taluk":"Rajahmundry (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533256","Taluk":"Bikkavolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533340","Taluk":"Mandapeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533104","Taluk":"Rajahmundry (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533289","Taluk":"Gokavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533284","Taluk":"Rampachodavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533126","Taluk":"Alamuru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533343","Taluk":"Bikkavolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533263","Taluk":"Pamarru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533263","Taluk":"Pamaru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533285","Taluk":"Gokavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533290","Taluk":"Korukonda","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533124","Taluk":"NA","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533126","Taluk":"Kadiyam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533292","Taluk":"Korukonda","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533296","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533295","Taluk":"Maredumilli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533261","Taluk":"Rayavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533286","Taluk":"Gokavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533287","Taluk":"Sithanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533339","Taluk":"Devipatnam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533232","Taluk":"Alamuru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533256","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533234","Taluk":"Alamuru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533288","Taluk":"Maredumilli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533103","Taluk":"Rajahmundry (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533286","Taluk":"Devipatnam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533125","Taluk":"NA","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533262","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533263","Taluk":"Kajuluru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533234","Taluk":"Kadiyam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533341","Taluk":"Mandapeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533294","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533285","Taluk":"Rampachodavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533294","Taluk":"Rangampeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533102","Taluk":"Korukonda","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533289","Taluk":"Korukonda","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533297","Taluk":"Gandepalli","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533105","Taluk":"Rajahmundry (Urban)","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533107","Taluk":"Rajahmundry Rural","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533294","Taluk":"Ragampeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533124","Taluk":"Rajahmundry Rural","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533262","Taluk":"Kajuluru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533285","Taluk":"Gangavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533297","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533262","Taluk":"Bikkavolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533342","Taluk":"Bikkavolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533309","Taluk":"Kapileswarapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533293","Taluk":"Sithanagram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533105","Taluk":"NA","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533102","Taluk":"Rajahmundry Rural","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533346","Taluk":"Bikkavolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533124","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533345","Taluk":"Bikkavolu","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533306","Taluk":"Pamarru","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533256","Taluk":"Rayavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533264","Taluk":"Anaparthy","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533346","Taluk":"Anaparthy","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533308","Taluk":"Mandapeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533293","Taluk":"Sithanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533342","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533292","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533308","Taluk":"Kapileswarapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533102","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533288","Taluk":"Devipatnam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533261","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533341","Taluk":"Rajanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533340","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533342","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533107","Taluk":"Rajaahmundry Rural","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533261","Taluk":"Anaparthy","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533101","Taluk":"Rajahmundry","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533255","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533101","Taluk":"Rajahmundry Rural","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533346","Taluk":"Rayavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533234","Taluk":"Mandapeta","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533293","Taluk":"Rajahmundry Rural","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533305","Taluk":"Kapileswarapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533345","Taluk":"Rayavaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533346","Taluk":"Ramachandrapuram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533125","Taluk":"Kadiyam","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"533105","Taluk":"Sithanagaram","Districtname":"East Godavari","statename":"ANDHRA PRADESH"},
    {"pincode":"532403","Taluk":"Laveru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Rajam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532203","Taluk":"Tekkali","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532403","Taluk":"Etcherla","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532186","Taluk":"Amadalavalasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532219","Taluk":"Vajrapukotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532459","Taluk":"Kotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532190","Taluk":"Sarubujjili Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532186","Taluk":"Srikakulam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532425","Taluk":"Jalumuru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532445","Taluk":"Burja Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532403","Taluk":"Etcherla Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532426","Taluk":"Saravakota Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532185","Taluk":"Amadalavalasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532218","Taluk":"Vajrapukotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532429","Taluk":"Polaki Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532122","Taluk":"Regidi Amadalavalasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532123","Taluk":"Regidi Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532404","Taluk":"Gara Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532168","Taluk":"Sigadam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532222","Taluk":"Palasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532122","Taluk":"Vangara Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532401","Taluk":"Srikakulam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532409","Taluk":"Ranasthalam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532264","Taluk":"Sompeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532457","Taluk":"Kotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532455","Taluk":"Bhamini","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532406","Taluk":"Gara Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532264","Taluk":"Mandasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532263","Taluk":"Sompeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532456","Taluk":"Bhamini","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532322","Taluk":"Kaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532290","Taluk":"Kaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532484","Taluk":"Amadalavalasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532430","Taluk":"Polaki Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532220","Taluk":"Palasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532427","Taluk":"Saravakota Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532459","Taluk":"Hiramandalam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532405","Taluk":"Srikakulam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532456","Taluk":"Bhamini Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532312","Taluk":"Ichchapuram Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Santhakaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532408","Taluk":"Etcherla","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532292","Taluk":"Kaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532214","Taluk":"Pathapatnam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532218","Taluk":"Nandigam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532195","Taluk":"Santabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532284","Taluk":"Sompeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532427","Taluk":"Saravakota Mdl","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532212","Taluk":"Tekkali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532284","Taluk":"Kanchili Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532405","Taluk":"Gara","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532168","Taluk":"Ponduru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532445","Taluk":"Burja","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532312","Taluk":"Ichchapuram","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532406","Taluk":"Gara","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532203","Taluk":"Tekkali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532432","Taluk":"Jalumuru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532216","Taluk":"Meliaputti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532242","Taluk":"Mandasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532214","Taluk":"Saravakota Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532455","Taluk":"Seethampeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532186","Taluk":"Srikakulam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532458","Taluk":"Sarubujjili Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532168","Taluk":"Santhakaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532186","Taluk":"Amadalavalasa Manal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532425","Taluk":"Narasannapeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532402","Taluk":"Ponduru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532212","Taluk":"Santabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532195","Taluk":"Kotabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532312","Taluk":"Kaviti","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532421","Taluk":"Narasannapeta","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532211","Taluk":"Vajrapukotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532214","Taluk":"Pathapatnam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532203","Taluk":"Nandigam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532212","Taluk":"Nandigam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532222","Taluk":"Vajrapukothuru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532291","Taluk":"Kanchili Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532484","Taluk":"Amadalavalasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532410","Taluk":"Etcherla","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532410","Taluk":"Etcherla Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Rajam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532122","Taluk":"Regidi Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532243","Taluk":"Palasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532455","Taluk":"Bhamini Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532407","Taluk":"Cheepurupalle","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532292","Taluk":"NA","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532221","Taluk":"Meliaputti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532123","Taluk":"Santhakaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532484","Taluk":"Srikakulam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532005","Taluk":"Srikakulam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532407","Taluk":"Laveru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532455","Taluk":"Kotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532243","Taluk":"Mandasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532430","Taluk":"Kotabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532459","Taluk":"Hiramandalam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532421","Taluk":"Polaki Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532401","Taluk":"Srikakulam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532292","Taluk":"Sompeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532407","Taluk":"Ranastalam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532430","Taluk":"Santhabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532445","Taluk":"Santhakaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532407","Taluk":"Ranasthalam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532290","Taluk":"Kanchili Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532458","Taluk":"Seethampeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Therlam Mandal","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532214","Taluk":"Hiramandalam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532195","Taluk":"Kotabommali","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532421","Taluk":"Narasannapeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532186","Taluk":"Amadalavalasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532221","Taluk":"Meliaputti","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532428","Taluk":"Jalumuru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532312","Taluk":"Kaviti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532222","Taluk":"Palasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532220","Taluk":"Nandigam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532221","Taluk":"Palasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532242","Taluk":"Mandasa","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532428","Taluk":"Hiramandalam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532427","Taluk":"Jalumuru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532203","Taluk":"Saravakota Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532459","Taluk":"Sarubujjili Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532243","Taluk":"Mandasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Regidi Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532455","Taluk":"Bhamani Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532405","Taluk":"Gara Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532211","Taluk":"Santhabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532203","Taluk":"Kotabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532005","Taluk":"Etcherla Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532455","Taluk":"Kotturu","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532457","Taluk":"Kotturu","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532458","Taluk":"Kotturu","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532213","Taluk":"Pathapatnam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532211","Taluk":"Santabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532222","Taluk":"Vajrapukotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532215","Taluk":"Meliaputti Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Merakamudidam Mandal","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532484","Taluk":"Ponduru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532312","Taluk":"Sompeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532005","Taluk":"Etcherla","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532428","Taluk":"Jalumuru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532403","Taluk":"Laveru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Sigadam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532211","Taluk":"Santhabommali","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532243","Taluk":"Palasa Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532186","Taluk":"Burja Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532190","Taluk":"Sarubujjili","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532168","Taluk":"Laveru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532213","Taluk":"Pathapatnam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532195","Taluk":"Kotabommalimandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532474","Taluk":"Kotabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532168","Taluk":"Ponduru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532218","Taluk":"Vajrapukothuru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532409","Taluk":"Ranastalam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532474","Taluk":"Jalumuru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532432","Taluk":"Kotabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532402","Taluk":"Etcherla Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532195","Taluk":"Santhabommali Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532127","Taluk":"Merakamudidam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532458","Taluk":"Lakshminarsupeta","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532001","Taluk":"Srikakulam","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532404","Taluk":"Gara","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532122","Taluk":"Therlam Mandal","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"532195","Taluk":"Jalumuru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532292","Taluk":"Kanchili Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532243","Taluk":"Sompeta Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532168","Taluk":"G.sigadam Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532201","Taluk":"Tekkali","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532474","Taluk":"Kotabommali","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532474","Taluk":"Jalumuru Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532401","Taluk":"Gara Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532220","Taluk":"Vajrapukotturu Mandal","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"532427","Taluk":"Jalumuru","Districtname":"Srikakulam","statename":"ANDHRA PRADESH"},
    {"pincode":"530003","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530053","Taluk":"Gajuwaka","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530016","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531162","Taluk":"Visakhapatnam.","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530052","Taluk":"NA","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531219","Taluk":"Viskhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531162","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530040","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530012","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530012","Taluk":"Pedagantyada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530044","Taluk":"Pedagantyada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531163","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531163","Taluk":"Bheemunipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531173","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530003","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531219","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530047","Taluk":"Pendurthi","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531162","Taluk":"Bheemunipatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530002","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530020","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530043","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530031","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530029","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530046","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530001","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530026","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530005","Taluk":"Pedagantyada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530048","Taluk":"Visakhapatnam (Rural)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530045","Taluk":"Visakhapatnam (Rural)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530004","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530027","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530022","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530007","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530008","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530022","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530031","Taluk":"Pedagantyada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530012","Taluk":"Pendurthi","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530008","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531163","Taluk":"Viskhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530002","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530048","Taluk":"NA","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530017","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530011","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530048","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530018","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530009","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530007","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530009","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530044","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530027","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530005","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530014","Taluk":"Pedagantyada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530014","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530016","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530013","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530046","Taluk":"Pedagantyada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530053","Taluk":"Visakhapatnam (Rural)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"531162","Taluk":"Visakhapatnam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"530047","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530041","Taluk":"Visakhapatnam (Rural)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530045","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530024","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530049","Taluk":"Visakhapatnam (Rural)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530028","Taluk":"Visakhapatnam (Rural)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530051","Taluk":"Pendurthi","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530052","Taluk":"Anandapuram","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530032","Taluk":"Pedagantyada","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530009","Taluk":"Visakhapatnam Rural","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530001","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530035","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530043","Taluk":"Visakhapatnam (Rural)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530032","Taluk":"Visakhapatnam","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530004","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"530015","Taluk":"Visakhapatnam (Urban)","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"535281","Taluk":"Vepada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535218","Taluk":"Nellimarla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535005","Taluk":"Apspqrs","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"535216","Taluk":"Denkada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535240","Taluk":"Alamanda Rs","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535240","Taluk":"Alamanda","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535204","Taluk":"Pusapatirega","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535250","Taluk":"S Kota","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535216","Taluk":"Bhogapuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535003","Taluk":"Bondapalli","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535280","Taluk":"Gurla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535145","Taluk":"Ananthagiri","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"535273","Taluk":"Bondapalli","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535250","Taluk":"Jami","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535005","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535161","Taluk":"Vepada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535003","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535214","Taluk":"Jami","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535260","Taluk":"Gajapathinagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535005","Taluk":"B.tallavalasa","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535183","Taluk":"Kothavalasa","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535250","Taluk":"Jami","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"535240","Taluk":"Alamanada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535145","Taluk":"Anathagiri","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"535216","Taluk":"NA","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535270","Taluk":"Mentada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535145","Taluk":"Vepada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535160","Taluk":"Bonangi","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535260","Taluk":"Bondapalli","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535215","Taluk":"Mentada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535280","Taluk":"Nellimarla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535581","Taluk":"Challapeta","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535580","Taluk":"Gantyada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535217","Taluk":"Gurla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535217","Taluk":"Nellimarla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535002","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535161","Taluk":"L Kota","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535580","Taluk":"Datti Rajeru","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535006","Taluk":"Denkada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535004","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535148","Taluk":"Srungavarapukota","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535270","Taluk":"Gajapathinagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535183","Taluk":"L Kota","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535215","Taluk":"Gantyada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535220","Taluk":"Gurla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535273","Taluk":"Bondapalli","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"535240","Taluk":"NA","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535221","Taluk":"Gantyada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535148","Taluk":"S Kota","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535581","Taluk":"Gajapathinagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535006","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535005","Taluk":"Denkada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535183","Taluk":"Vepada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535240","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535580","Taluk":"Pachipenta","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535213","Taluk":"Konada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535003","Taluk":"Nellimarla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535213","Taluk":"Pusapatirega","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535214","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535220","Taluk":"Kotagandredu","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535145","Taluk":"S Kota","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535160","Taluk":"Gantyada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535273","Taluk":"Mentada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535280","Taluk":"Moida","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535280","Taluk":"Bondapalli","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535221","Taluk":"Pedamajjipalem","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535580","Taluk":"Pedamanapuram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535183","Taluk":"Kothavalasa","Districtname":"Visakhapatnam","statename":"ANDHRA PRADESH"},
    {"pincode":"535273","Taluk":"Gantyada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535218","Taluk":"Gurla","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535260","Taluk":"gajapathinagram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535218","Taluk":"Ramathirtam","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535160","Taluk":"S.kota.","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535213","Taluk":"Denkada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535215","Taluk":"Bondapalli","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535148","Taluk":"NA","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535003","Taluk":"Denkada","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535580","Taluk":"Dattirajeru","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"535001","Taluk":"Vizianagaram","Districtname":"Vizianagaram","statename":"ANDHRA PRADESH"},
    {"pincode":"787032","Taluk":"Lakhimpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"785676","Taluk":"Khowang Ghat","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787054","Taluk":"Islamgaon","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786613","Taluk":"Bamunbari","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787052","Taluk":"Lakhimpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787059","Taluk":"Silapathar","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"787110","Taluk":"Sissiborgaon","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"786101","Taluk":"Dikom","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786692","Taluk":"Duliajan","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787031","Taluk":"Lakhimpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787057","Taluk":"Dhemaji","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"784165","Taluk":"Dhalpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786621","Taluk":"Namrup","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786002","Taluk":"Dibrugarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"784161","Taluk":"Bihupuria","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"784161","Taluk":"Bihpuria","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787055","Taluk":"Dhakuakhana","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786003","Taluk":"Dibrugarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"785676","Taluk":"Khiowang Ghat","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786007","Taluk":"Barabruah","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786184","Taluk":"Chabua","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786610","Taluk":"Naharkatia","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786007","Taluk":"Barbaruah","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786610","Taluk":"Dibrugarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"784163","Taluk":"Pathalipahar","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786611","Taluk":"Rajgarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786004","Taluk":"Dibrugarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787053","Taluk":"Ghilamara","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787058","Taluk":"Machkhowa","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"786191","Taluk":"Naholia","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786103","Taluk":"Tengakhat","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787033","Taluk":"Bhogpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786612","Taluk":"Tingkhong","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787032","Taluk":"Boginadi","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786010","Taluk":"Lahoal","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787023","Taluk":"Lakhimpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"784164","Taluk":"Dikrong","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787026","Taluk":"Bordoloni","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"785675","Taluk":"Khowang","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787051","Taluk":"Lakhimpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786102","Taluk":"Chabua","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786611","Taluk":"Tengakhat","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787061","Taluk":"Simen Chapori","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"787059","Taluk":"Silapthar","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"787026","Taluk":"Dhemaji","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"784165","Taluk":"Dhlapur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"784160","Taluk":"Laluk","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786001","Taluk":"Dibrugarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"784164","Taluk":"Bihupuria","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786103","Taluk":"Tingkhiong","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786602","Taluk":"Duliajan","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"785676","Taluk":"Khowang  Ghat","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787060","Taluk":"Jonai","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"787035","Taluk":"Gogamukh","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787034","Taluk":"Gogamukh","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786012","Taluk":"Mohanbari","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786005","Taluk":"Dibrugarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"784161","Taluk":"Badati","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786614","Taluk":"Jeypore","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786614","Taluk":"Naharkatia","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787034","Taluk":"Gogamukh","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"787056","Taluk":"Pathalipam","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787053","Taluk":"Ghilamara`","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787031","Taluk":"North Lakhimpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"785676","Taluk":"Khiowang","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"785676","Taluk":"Khowang","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786008","Taluk":"Dibrugarh","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786610","Taluk":"Nahariatia","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"785676","Taluk":"Klhowang","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787053","Taluk":"Ghilamara","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"786006","Taluk":"Barbarua","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786621","Taluk":"Naholia","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787035","Taluk":"Gerukamukh","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"787034","Taluk":"Moinapara","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787054","Taluk":"Isalmgaon","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786622","Taluk":"Namrup","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787023","Taluk":"Naoboicha","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786191","Taluk":"Naharkatia","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786612","Taluk":"Tinghkhong","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"787001","Taluk":"North Lakhimpur","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"787110","Taluk":"Sissiborgaron","Districtname":"Dhemaji","statename":"ASSAM"},
    {"pincode":"787052","Taluk":"Panigaon","Districtname":"Lakhimpur","statename":"ASSAM"},
    {"pincode":"786623","Taluk":"Parbatpur","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786010","Taluk":"Lahoal;","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"782412","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782002","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782123","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782103","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782124","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782120","Taluk":"Ambagan","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782445","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782136","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782410","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782122","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782461","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782410","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782003","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782138","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782481","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782448","Taluk":"Donka","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782425","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782435","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782450","Taluk":"Hamren","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782139","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782446","Taluk":"Lanka","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782105","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782442","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782120","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782103","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782462","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782441","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782140","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782482","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782144","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782470","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782102","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782121","Taluk":"Bhuragaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782127","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782141","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782428","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782103","Taluk":"Raha","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782142","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782425","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782125","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782439","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782411","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782447","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782137","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782120","Taluk":"NA","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782143","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782121","Taluk":"Bhuragaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782104","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782101","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782490","Taluk":"Bokajan","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782480","Taluk":"Bokajan","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782485","Taluk":"Donkamokam","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782480","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782440","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782135","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782106","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782440","Taluk":"Doboka","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782426","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782126","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782481","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782460","Taluk":"Nagaon","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782123","Taluk":"Dhing","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782460","Taluk":"Diphu","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782485","Taluk":"Hamren","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782128","Taluk":"NA","Districtname":"NULL","statename":"NULL"},
    {"pincode":"782460","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782128","Taluk":"Nagaon","Districtname":"NULL","statename":"NULL"},
    {"pincode":"782486","Taluk":"Hamren","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782440","Taluk":"Nagon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782485","Taluk":"Donka","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782410","Taluk":"Jagiroad","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782429","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782427","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782413","Taluk":"Jagiroad","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782426","Taluk":"Kampur","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782135","Taluk":"NA","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782427","Taluk":"Kampur","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782136","Taluk":"Diphu","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782448","Taluk":"Hamren","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782137","Taluk":"Kaliabor","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782446","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782447","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782447","Taluk":"Hojai","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782446","Taluk":"Donka","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782435","Taluk":"Donka","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782001","Taluk":"Morigaon","Districtname":"Marigaon","statename":"ASSAM"},
    {"pincode":"782001","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782139","Taluk":"Nagaon","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"782442","Taluk":"Hojai","Districtname":"Karbi Anglong","statename":"ASSAM"},
    {"pincode":"782410","Taluk":"Jagiroad","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"785702","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785663","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785614","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785690","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785664","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785697","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785108","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785622","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785681","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785680","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785682","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785013","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785625","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785696","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785106","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785611","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785681","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785101","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785633","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785107","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785009","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785674","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785667","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785104","Taluk":"Jorhat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785612","Taluk":"Bkt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785635","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785671","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785685","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785683","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785692","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785004","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785104","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785602","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785693","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785626","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785684","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785603","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785618","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785630","Taluk":"Ttb","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785661","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785618","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785615","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785112","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785702","Taluk":"Glgt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785640","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785701","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785110","Taluk":"Majuli","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785691","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785682","Taluk":"Sivasagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785001","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785691","Taluk":"Sibsagar","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785611","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785680","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785687","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785602","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785632","Taluk":"Ttb","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785670","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785697","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785612","Taluk":"Bokakhat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785613","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785014","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785619","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785689","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785680","Taluk":"Sivasagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785006","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785693","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785631","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785610","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785612","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785703","Taluk":"Glt","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785105","Taluk":"Majuli","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785673","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785705","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785688","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785672","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785616","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785666","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785661","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785010","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785705","Taluk":"NA","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785601","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785102","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785008","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785102","Taluk":"Majuli","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785107","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785662","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785662","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785669","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785614","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785680","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785015","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785700","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785622","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785686","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785671","Taluk":"Svs","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785663","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785602","Taluk":"NA","Districtname":"Nagaon","statename":"ASSAM"},
    {"pincode":"785634","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785111","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785703","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785610","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785636","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785104","Taluk":"Majuli","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785664","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785696","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785601","Taluk":"Glt","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785621","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785631","Taluk":"Ttb","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785701","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785007","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785609","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785666","Taluk":"Svs","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785683","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785665","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785619","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785666","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785106","Taluk":"Majuli","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785625","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785699","Taluk":"Glt","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785609","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785701","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785667","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785688","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785698","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785613","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785626","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785670","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785704","Taluk":"Jorhat","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785633","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785626","Taluk":"Glt.","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785684","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785601","Taluk":"Sarupathar","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785685","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785703","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785674","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785671","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785699","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785615","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785603","Taluk":"Golaghat","Districtname":"Golaghat","statename":"ASSAM"},
    {"pincode":"785697","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785006","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785632","Taluk":"Titabor","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785672","Taluk":"Sivasagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785665","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785614","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785687","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785692","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785673","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785698","Taluk":"Svs","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785602","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785686","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785640","Taluk":"Sivasagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785690","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785689","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785616","Taluk":"NA","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785682","Taluk":"Sibsagar","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"785616","Taluk":"NA","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785630","Taluk":"Titabor","Districtname":"Jorhat","statename":"ASSAM"},
    {"pincode":"785692","Taluk":"Svs.","Districtname":"Sibsagar","statename":"ASSAM"},
    {"pincode":"786171","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786174","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786187","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786181","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786157","Taluk":"Chapakhowa","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786160","Taluk":"Doom Dooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786154","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786170","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786189","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786601","Taluk":"Teghakhat","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786183","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786150","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786160","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786183","Taluk":"Chabua","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786182","Taluk":"Margfherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786601","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786188","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786171","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786152","Taluk":"Doom Dooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786152","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786156","Taluk":"Doom Dooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786151","Taluk":"Doom Dooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786126","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786146","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786179","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786157","Taluk":"Sadiya","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786156","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786151","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786192","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786189","Taluk":"Chabua","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786125","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786160","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786154","Taluk":"Doom Dooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786182","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786190","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786173","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786145","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786152","Taluk":"Margherita","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786183","Taluk":"Chabua","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786147","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786158","Taluk":"Chapakhowa","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786171","Taluk":"Tipling","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786170","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786189","Taluk":"Tinsukia","Districtname":"Dibrugarh","statename":"ASSAM"},
    {"pincode":"786153","Taluk":"Doomdooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786155","Taluk":"Chapakhowa","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786154","Taluk":"Chapakhowa","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786190","Taluk":"Tinsukia","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786150","Taluk":"Doom Dooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"786159","Taluk":"Doom Dooma","Districtname":"Tinsukia","statename":"ASSAM"},
    {"pincode":"788701","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788722","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788161","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788101","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788150","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788164","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788030","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788712","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788004","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788119","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788806","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788156","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788734","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788009","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788025","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788723","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788931","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788011","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788115","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788006","Taluk":"Karimganj","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788803","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788113","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788720","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788820","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788026","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788726","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788801","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788110","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788107","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788162","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788728","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788155","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788710","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788818","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788152","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788736","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788117","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788713","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788815","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788121","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788781","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788102","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788725","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788015","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788733","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788727","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788817","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788123","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788120","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788010","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788099","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788114","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788724","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788126","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788005","Taluk":"Silchar","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788166","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788163","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788116","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788737","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788002","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788007","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788003","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788819","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788112","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788735","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788111","Taluk":"Dalu","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788830","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788112","Taluk":"Cleaver House","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788001","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788832","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788013","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788165","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788106","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788031","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788113","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788802","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788804","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788711","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788151","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788831","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788805","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788127","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788005","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788160","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788709","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788816","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788104","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788098","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788006","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788168","Taluk":"NA","Districtname":"Hailakandi","statename":"ASSAM"},
    {"pincode":"788719","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788108","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788109","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788103","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788108","Taluk":"NA","Districtname":"North Cachar Hills","statename":"ASSAM"},
    {"pincode":"788120","Taluk":"NA","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788014","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788118","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788710","Taluk":"Karimanj","Districtname":"Karimganj","statename":"ASSAM"},
    {"pincode":"788111","Taluk":"NA","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"788003","Taluk":"Silchar","Districtname":"Cachar","statename":"ASSAM"},
    {"pincode":"784524","Taluk":"Hatigarh","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784112","Taluk":"Alisinga","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784178","Taluk":"Kalabari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784510","Taluk":"Harisinga","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784101","Taluk":"Balipara","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784514","Taluk":"Lalpul Bazar","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784170","Taluk":"Halem","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784148","Taluk":"Duni","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784167","Taluk":"Borgong","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784527","Taluk":"Atterikhat","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784103","Taluk":"Chariduar","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784529","Taluk":"Mangaldoi","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784502","Taluk":"Bindukuri","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784522","Taluk":"Khoirabari","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784145","Taluk":"Sipajhar","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784182","Taluk":"Bakola","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784172","Taluk":"Barangabari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784175","Taluk":"Sootea","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784146","Taluk":"Dhula","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784174","Taluk":"Pabhoi","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784027","Taluk":"Kaliabhomora","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784171","Taluk":"Tetenbari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784182","Taluk":"Naharbari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784117","Taluk":"Sirajuli","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784154","Taluk":"Ketekibari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784509","Taluk":"Udalguri","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784153","Taluk":"Bandarmari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784153","Taluk":"Bihaguri","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784144","Taluk":"Pathorighat","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784525","Taluk":"Kalaigaon","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784190","Taluk":"Bhakatpara","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784149","Taluk":"Thelamara","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784147","Taluk":"Deomornoi","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784506","Taluk":"Missamari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784507","Taluk":"Mazbat","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784111","Taluk":"Darrang Panbari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784179","Taluk":"Bedeti","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784166","Taluk":"Behali","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784523","Taluk":"Panerihat","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784150","Taluk":"Besseria","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784513","Taluk":"Bhairabkunda","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784508","Taluk":"Rowta","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784528","Taluk":"Purani Tangla","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784177","Taluk":"Biswanathghat","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784105","Taluk":"Ghoramari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784113","Taluk":"Kopati","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784114","Taluk":"Orang","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784184","Taluk":"Gingia","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784504","Taluk":"Borjuli","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784117","Taluk":"Borsola","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784176","Taluk":"Charilai","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784116","Taluk":"Dalgaon","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784176","Taluk":"NA","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784110","Taluk":"Dhekiajuli","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784150","Taluk":"Besesria","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784501","Taluk":"Dekargaon","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784526","Taluk":"Dimakuchi","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784169","Taluk":"Howajan","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784505","Taluk":"Rangapara","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784115","Taluk":"Kharupetiaghat","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784180","Taluk":"Jamugurihat","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784168","Taluk":"Gohpur","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784104","Taluk":"Shalonibari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784001","Taluk":"Hazarapar","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784001","Taluk":"Kacharigaon","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784189","Taluk":"Karsontala","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784154","Taluk":"Tezpur","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784026","Taluk":"Khalihamari","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784153","Taluk":"Khelmati","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784176","Taluk":"Chariali","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784102","Taluk":"Lokra","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784001","Taluk":"Tezpur","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784125","Taluk":"Mangaldoi","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784521","Taluk":"Tangla","Districtname":"Darrang","statename":"ASSAM"},
    {"pincode":"784173","Taluk":"Mijikajan","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784189","Taluk":"Nagshankar","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784028","Taluk":"Napaam","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784025","Taluk":"Panchmile","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784182","Taluk":"Rangachakua","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"784010","Taluk":"Tezpur","Districtname":"Sonitpur","statename":"ASSAM"},
    {"pincode":"783388","Taluk":"Bongaigaon","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783384","Taluk":"Abhayapuri","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783392","Taluk":"Manikpur","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783346","Taluk":"Dotma","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783120","Taluk":"Balijana","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783335","Taluk":"NA","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783330","Taluk":"South Salmara","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783323","Taluk":"Dhubri","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783339","Taluk":"Debitala","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783391","Taluk":"Boitamari","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783384","Taluk":"Srijangram","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783123","Taluk":"Kushdhowa","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783129","Taluk":"Lakhipur","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783123","Taluk":"Khusdhowa","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783393","Taluk":"Borobazar","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783337","Taluk":"Bilasipara","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783124","Taluk":"Dudnai","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783393","Taluk":"Bijni","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783390","Taluk":"Bijni","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783360","Taluk":"Gossaigaon","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783331","Taluk":"Rupsi","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783130","Taluk":"Rangjuli","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783384","Taluk":"B.s.gram","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783390","Taluk":"Manikpur","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783345","Taluk":"Dotma","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783349","Taluk":"Bagribari","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783125","Taluk":"Matia","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783350","Taluk":"Kachugaon","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783121","Taluk":"Goalpara","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783101","Taluk":"Goalpara","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783334","Taluk":"Golakganj","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783388","Taluk":"B.s.gram","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783347","Taluk":"Dotma","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783348","Taluk":"Bilasipara","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783384","Taluk":"B.s. Gram","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783332","Taluk":"Hatidhura","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783382","Taluk":"Baitamari","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783330","Taluk":"Salmara South","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783348","Taluk":"Raniganj","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783131","Taluk":"Mankachar","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783380","Taluk":"Bongaigaon","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783391","Taluk":"Manikpur","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783337","Taluk":"Bilasipara","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783372","Taluk":"Kokrajhar","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783127","Taluk":"Salmara South","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783394","Taluk":"Bengtal","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783383","Taluk":"Baitamari","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783336","Taluk":"Gossaigaon","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783337","Taluk":"Kokrajhar","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783324","Taluk":"Dhubri","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783335","Taluk":"Agomoni","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783372","Taluk":"Dotoma","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783370","Taluk":"Kokrajhar","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783389","Taluk":"Boitamari","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783349","Taluk":"Mahamaya","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783339","Taluk":"Debitola","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783393","Taluk":"Boro Bazar","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783335","Taluk":"Agamoni","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783388","Taluk":"B.s. Gram","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783373","Taluk":"Sidli Chirang","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783371","Taluk":"Chapar Salkocha","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783354","Taluk":"Dotma","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783376","Taluk":"Kokrajhar","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783126","Taluk":"Krishnai","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783122","Taluk":"Dudnai","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783134","Taluk":"Dudnai","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783334","Taluk":"Golokganj","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783375","Taluk":"Bengtal","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783385","Taluk":"Bongaigaon","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783135","Taluk":"Fekamari","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783132","Taluk":"Lakhipur","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783301","Taluk":"Dhubri","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783334","Taluk":"Golakganj S.o.","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783333","Taluk":"Hatidhura","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783380","Taluk":"Sidli (Part-II)","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783101","Taluk":"Matia","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783337","Taluk":"Bagribari","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783120","Taluk":"Lakhipur","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783333","Taluk":"Gossaigaon","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783325","Taluk":"Dhubri","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783361","Taluk":"Gossaigaon","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783128","Taluk":"South Salmara","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783350","Taluk":"Gossaigaon","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783325","Taluk":"NA","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783382","Taluk":"Boitamari","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783134","Taluk":"Dudhnoi","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783390","Taluk":"Minikpur","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783369","Taluk":"Kokrajhar","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783336","Taluk":"Bhowraguri","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783337","Taluk":"Bhowraguri","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783389","Taluk":"Baitamari","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783371","Taluk":"Salkocha","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783393","Taluk":"Bongaigaon","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783128","Taluk":"Fekamari","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783123","Taluk":"Rangjuli","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783384","Taluk":"B.s.gram.","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783345","Taluk":"Bilasipara","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783388","Taluk":"Dhubri","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783124","Taluk":"Dudhnai","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783134","Taluk":"Dudhnoi","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783384","Taluk":"Bongaigaon","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783381","Taluk":"Bongaigaon","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783122","Taluk":"Dudhnoi","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783394","Taluk":"Bengtol","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783360","Taluk":"Bijni","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783127","Taluk":"Kokrajhar","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783135","Taluk":"Mankachar","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783133","Taluk":"Matia","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"783345","Taluk":"Raniganj","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783375","Taluk":"Bengtol","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783383","Taluk":"Baitamari","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783381","Taluk":"Bijni","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783331","Taluk":"Rupshi","Districtname":"Dhubri","statename":"ASSAM"},
    {"pincode":"783361","Taluk":"Kokrajhar","Districtname":"Kokrajhar","statename":"ASSAM"},
    {"pincode":"783394","Taluk":"Bijni","Districtname":"Bongaigaon","statename":"ASSAM"},
    {"pincode":"783126","Taluk":"Balijana","Districtname":"Goalpara","statename":"ASSAM"},
    {"pincode":"781128","Taluk":"Rani Block ( Palasbari Circle)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781137","Taluk":"Dakhin Sarubanswar Mauza","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781102","Taluk":"Hajo Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781137","Taluk":"Pubchamaria ( Goroimari Block)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781127","Taluk":"Mandia","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781025","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781023","Taluk":"Sonapur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781026","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781031","Taluk":"Gmc ( North Gh Circle )","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781014","Taluk":"Palashbari Circle","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781027","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781134","Taluk":"Dakhin Sarubanswar (rampur)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781003","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781006","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781017","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781127","Taluk":"Chamaria","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781366","Taluk":"Goreswar Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781381","Taluk":"Bezera Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781380","Taluk":"Kamalpur","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781124","Taluk":"Chhaygaonpantan","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781103","Taluk":"Hajo Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781141","Taluk":"Chhaygaon Pantan","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781021","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781364","Taluk":"Goreswar Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781354","Taluk":"Rangia Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781134","Taluk":"Dakhin Sarubanswar ( Rampur)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781101","Taluk":"Bezera Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781030","Taluk":"Bezera Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781382","Taluk":"Kamalpur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781104","Taluk":"Hajo Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781135","Taluk":"Boko","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781121","Taluk":"Bezera Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781364","Taluk":"NA","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781380","Taluk":"Kamalpur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781136","Taluk":"Chamaria","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781029","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781122","Taluk":"Rampur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781028","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781132","Taluk":"Rampur","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781123","Taluk":"Boko","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781009","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781017","Taluk":"Kamalpur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781136","Taluk":"Goroimari","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781137","Taluk":"Goroimari Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781365","Taluk":"Rangia Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781018","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781123","Taluk":"Bongaon ( Boko)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781123","Taluk":"Bongaon","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781015","Taluk":"Kamalpur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781103","Taluk":"Soalkuchi Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781120","Taluk":"Jharobari Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"782402","Taluk":"Sonapur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781150","Taluk":"Chandrapur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781380","Taluk":"Rangia Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781366","Taluk":"Greswar Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781124","Taluk":"Chhaygaon Pantan","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781031","Taluk":"Bezera Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781133","Taluk":"Kamalpur Block Taluk of Kamrup","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781376","Taluk":"Rangia Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781011","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781012","Taluk":"Palasbari Circle","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781136","Taluk":"Boko","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781101","Taluk":"Kamalpur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781014","Taluk":"Jalukbari Lac","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781125","Taluk":"Jharobari Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"782403","Taluk":"Sonapur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"782401","Taluk":"Sonapur Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781382","Taluk":"Hajo Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781005","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781001","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781122","Taluk":"Dakhin Sarubansar ( C. Block)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781035","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781016","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781015","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781014","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781129","Taluk":"Luki ( Boko Block)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781038","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781137","Taluk":"P. Chamaria ( Goroimari Block)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781036","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781039","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781032","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781013","Taluk":"Jalukbari Lac","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781141","Taluk":"Bongaon","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781122","Taluk":"Chaini ( Jharobari)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781019","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781010","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781022","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781004","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781382","Taluk":"Rangia Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781007","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781120","Taluk":"Jharobari Block ( Chainibardua","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781129","Taluk":"Luki (boko Block)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781120","Taluk":"Jharobari Block(chaini Barduar","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781127","Taluk":"Chamaria Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781020","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781034","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781128","Taluk":"Rani Block ( Palashbari Circle","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781008","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781012","Taluk":"Palashbari Circle","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781012","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781137","Taluk":"Dakhinsarubanswar ( R. Block)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781037","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781171","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781120","Taluk":"Jharobariblock(chainibarduar)","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781122","Taluk":"Rampur","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781131","Taluk":"Rani Block","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781040","Taluk":"NA","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781125","Taluk":"Rani","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781024","Taluk":"Gmc","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781126","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"Adalbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781373","Taluk":"Ahopa","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781329","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781369","Taluk":"Allia","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781306","Taluk":"Chamata","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781367","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781316","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781325","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781317","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781343","Taluk":"Dhanbil","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781307","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781318","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781327","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781344","Taluk":"Angardhowa","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Angarkata","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781347","Taluk":"Kamarkuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Chowk Bazar","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781339","Taluk":"Arikuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781355","Taluk":"Niz Namati","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781377","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781348","Taluk":"Marowa","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781344","Taluk":"Baganpara","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781308","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781328","Taluk":"Baghmarabazar","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781330","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781311","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781310","Taluk":"Jagra","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781368","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781344","Taluk":"Bagulamari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781302","Taluk":"Baharihat","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781325","Taluk":"Pathsala","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781340","Taluk":"Bakuajari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781339","Taluk":"Bala","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781313","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781319","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781378","Taluk":"Bali","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781341","Taluk":"Balikaria","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781126","Taluk":"Balikuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Balilecha","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781350","Taluk":"NA","Districtname":"Kamrup","statename":"ASSAM"},
    {"pincode":"781349","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781310","Taluk":"Balowa","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781325","Taluk":"Bajali","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781312","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781326","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781303","Taluk":"Banagram","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781303","Taluk":"Banagrasm","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"Banbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781340","Taluk":"Banekuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781375","Taluk":"Bangaon","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781314","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781369","Taluk":"Barajol","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781346","Taluk":"Barama","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781355","Taluk":"Baramchari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Chowkbazar","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781351","Taluk":"Barbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781373","Taluk":"Doomni","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781315","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781350","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781302","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781351","Taluk":"Baridatara","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Barimakha","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"Barimukh","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781337","Taluk":"Milanpur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781304","Taluk":"Belsor","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781301","Taluk":"Barpeta","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781315","Taluk":"Barpetaroad","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781378","Taluk":"Barpit","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781346","Taluk":"Barsimlaguri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781138","Taluk":"Bartola","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Bennabari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Bhadra","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781371","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781126","Taluk":"Mukalmua","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781309","Taluk":"Bhella","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781309","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781309","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781305","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781378","Taluk":"Bhojkuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781328","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781352","Taluk":"Bhowanipur","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781335","Taluk":"Nalbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781321","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781338","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781341","Taluk":"Burinagar","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781343","Taluk":"Chaibari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781126","Taluk":"Chanda","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Chandkuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781352","Taluk":"NA","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781350","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Chechapani","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781305","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781369","Taluk":"Chilling","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Nalbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781329","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Dalbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Dangarmakha","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Darangamala","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Darangapara","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Debachara","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"Dihira","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781304","Taluk":"Dirua","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781367","Taluk":"Bajali","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781304","Taluk":"Gandhia","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781344","Taluk":"Gerua","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781355","Taluk":"Geruapara","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781369","Taluk":"Ghograpar","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781370","Taluk":"Gobradal","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781375","Taluk":"Golibandha","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781353","Taluk":"Nalbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Gopalthan","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781369","Taluk":"Guakuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781378","Taluk":"Haribhanga","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781337","Taluk":"Hatinamati","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Haziragaon","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781375","Taluk":"Helana","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781138","Taluk":"Hidilatari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781316","Taluk":"Howly","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781327","Taluk":"Jalahghat","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781378","Taluk":"Jalkhana","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Janijog","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781126","Taluk":"Jaysagar","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781370","Taluk":"Kaithalkuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781304","Taluk":"Kakaya","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781351","Taluk":"Kalag","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781319","Taluk":"Kalgachia","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Kalipur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781346","Taluk":"Kaljar","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781138","Taluk":"Kaplabari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781355","Taluk":"Karemura","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781339","Taluk":"Karia","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"Mussalpur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Kauli","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781341","Taluk":"Nalbari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781138","Taluk":"Kekankuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781341","Taluk":"Kendukuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781370","Taluk":"Kharsitha","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781346","Taluk":"Kharua","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781369","Taluk":"Khatikuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781344","Taluk":"Khagrabari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781330","Taluk":"Kokilabari","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Kumarikata","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781350","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781138","Taluk":"Larkuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781126","Taluk":"Lohasrkata","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781126","Taluk":"Lowtola","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781346","Taluk":"Madhapur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781374","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781308","Taluk":"Mandia","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781321","Taluk":"Mayanbari","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781355","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781337","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"Motipur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Mugkuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781344","Taluk":"Muktapur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781303","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781311","Taluk":"Nagaon","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781368","Taluk":"Nagrijuli","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781369","Taluk":"Nankarbhaira","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781138","Taluk":"Narua","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"Nikashi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781329","Taluk":"Nityananda","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Nizbahjani","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781349","Taluk":"Nizdhamdhama","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Paharpur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Pakribari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781372","Taluk":"NA","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781303","Taluk":"Panigaon","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781326","Taluk":"Patacharkuchi","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781360","Taluk":"Patkijuli","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Pub Baramchari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781375","Taluk":"Bangaon.","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781312","Taluk":"Rampur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781353","Taluk":"Sandheli","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781350","Taluk":"Sanekuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781314","Taluk":"Santinagar","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781307","Taluk":"Sarthebari","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781318","Taluk":"Sarupeta","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781312","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781355","Taluk":"Sathisamuka","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Saukuchi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781313","Taluk":"Simlaguri","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781338","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781337","Taluk":"Sondha","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781317","Taluk":"Sorbhog","Districtname":"Barpeta","statename":"ASSAM"},
    {"pincode":"781317","Taluk":"Barkhetri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781340","Taluk":"Suradi","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781367","Taluk":"Tambulpur","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781334","Taluk":"Terechia","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781377","Taluk":"Thamna","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781333","Taluk":"Thanguri","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781371","Taluk":"Tihu","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"781339","Taluk":"Ulabari","Districtname":"Nalbari","statename":"ASSAM"},
    {"pincode":"851128","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851216","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851111","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"848204","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851131","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"848202","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"848203","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851118","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851112","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851133","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851214","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851205","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851203","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851132","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851204","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"848201","Taluk":"Khagaria","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851113","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851116","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851111","Taluk":"Teghra","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851129","Taluk":"Begusarai H.o","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851213","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851129","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"848201","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851217","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851214","Taluk":"Mansi","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851211","Taluk":"Balia","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851212","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851211","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851133","Taluk":"NA","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851114","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851112","Taluk":"Barauni","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851115","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851101","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"852161","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851120","Taluk":"Teghra","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851201","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851135","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851134","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851127","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851215","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851202","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851130","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"852161","Taluk":"Beldaur","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851117","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851126","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"848204","Taluk":"Khagaria","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851218","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851210","Taluk":"Begusarai","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851120","Taluk":"Begusarai H.o","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851215","Taluk":"Khagaria","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"851201","Taluk":"NA","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"851217","Taluk":"Khagaria","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"847121","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847304","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847115","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847427","Taluk":"Biraul","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847201","Taluk":"Beniipur]","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847203","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847303","Taluk":"Darbhanga","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847405","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847233","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847101","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847103","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847106","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847105","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847121","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847104","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847203","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847201","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846007","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846005","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846002","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847337","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847422","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847201","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847105","Taluk":"Darbhnaga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847107","Taluk":"Muzaffarpur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"847428","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847303","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847301","Taluk":"Darbhanga","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"847423","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847103","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846003","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847306","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847122","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847123","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847203","Taluk":"Biraul","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847204","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847105","Taluk":"Darbhanga","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"847307","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846004","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847122","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847307","Taluk":"Pupri","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"847301","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846004","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847301","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"846009","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846003","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846003","Taluk":"Hanumannagar","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847302","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847407","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846001","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846008","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847204","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847107","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"847302","Taluk":"Pupri","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847429","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847107","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846001","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847307","Taluk":"Darbhanga","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"846009","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847307","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847103","Taluk":"Benipur]","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847302","Taluk":"Pupri","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"847233","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847122","Taluk":"Darbhanga","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847105","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"847122","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846005","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846002","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847301","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847123","Taluk":"Singhwara","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"846006","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"845301","Taluk":"Raxaul","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845413","Taluk":"Chakia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845417","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845433","Taluk":"Raxaul","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845401","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845428","Taluk":"Pakari Dayal","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845303","Taluk":"Dhaka","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845411","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845429","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845415","Taluk":"Sikrahana","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845435","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845315","Taluk":"Dhaka","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845437","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845418","Taluk":"Dhaka","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845456","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845436","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845415","Taluk":"Sikarahana","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845434","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845424","Taluk":"Chakia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845413","Taluk":"Kalyanpur","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845457","Taluk":"Dhaka","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845413","Taluk":"P.dayal","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845412","Taluk":"Chakia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845422","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845304","Taluk":"Dhaka","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845426","Taluk":"Chakia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845412","Taluk":"Chakia(Pipra)","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845418","Taluk":"NA","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845302","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845420","Taluk":"P.dayal","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845416","Taluk":"Motihari","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845302","Taluk":"Chauradano","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845305","Taluk":"Raxaul","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845423","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845427","Taluk":"Dhaka","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845425","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845315","Taluk":"Chiraia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845432","Taluk":"Kalyanpur","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845406","Taluk":"Chakia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845430","Taluk":"P.dayal","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845414","Taluk":"P.dayal","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845414","Taluk":"Pakri Dayal","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845419","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845302","Taluk":"Bankatwa","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845458","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845415","Taluk":"Chiraia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845416","Taluk":"Chakia(Pipra)","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845417","Taluk":"Sangrampur","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845440","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845431","Taluk":"Raxaul","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845303","Taluk":"Ghorasahan","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845419","Taluk":"Paharpur","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845420","Taluk":"Madhuban","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845422","Taluk":"Harsidhi","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845423","Taluk":"Kotwa","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845440","Taluk":"Raxaul","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845413","Taluk":"NA","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845305","Taluk":"Areraj","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845424","Taluk":"Kesaria","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845304","Taluk":"Ghorasahan","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845302","Taluk":"Adapur","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845302","Taluk":"Raxaul","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845426","Taluk":"Mehsi","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845415","Taluk":"NA","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845304","Taluk":"Sikarahana","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845428","Taluk":"Pakri Dayal","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845302","Taluk":"Chaurdano","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845457","Taluk":"Patahi","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845401","Taluk":"Motihari H.o.","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845430","Taluk":"Phenhara","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845429","Taluk":"Piprakothi","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845432","Taluk":"Ramgarhwa","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845431","Taluk":"Ramgarhwa","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845406","Taluk":"Tetaria","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845432","Taluk":"Tetaria","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845433","Taluk":"Ramgarhwa","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845434","Taluk":"Sangrampur","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845435","Taluk":"Banjaria","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845432","Taluk":"Klyanpur","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845417","Taluk":"NA","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845456","Taluk":"Sugauli","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845436","Taluk":"Tetaria","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"845437","Taluk":"Turkaulia","Districtname":"East Champaran","statename":"BIHAR"},
    {"pincode":"847230","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847402","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847234","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847401","Taluk":"Andhratharhi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847109","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847222","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847108","Taluk":"Phulparas","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847308","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847215","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847224","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847409","Taluk":"Phulparas","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847226","Taluk":"Jaynagar","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847239","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847108","Taluk":"Laukahi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847223","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847408","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847452","Taluk":"Nirmali","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"847401","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847227","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847102","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847411","Taluk":"Andhratharhi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847228","Taluk":"Jaynagar","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847421","Taluk":"Phulparas","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847225","Taluk":"Jaynagar","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847212","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847305","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847227","Taluk":"Phulparas","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847236","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847403","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847226","Taluk":"Jainagar","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847224","Taluk":"Babubarhi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847452","Taluk":"Laukahi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847404","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847402","Taluk":"Ghoghardiha","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847227","Taluk":"Ladania","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847230","Taluk":"Harlakhi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847410","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847239","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847235","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847231","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847212","Taluk":"Sadar Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847227","Taluk":"Laukaha","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847240","Taluk":"Jaynagar","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847235","Taluk":"Andhratharhi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847451","Taluk":"Nirmali","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"847409","Taluk":"Phulaparas","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847452","Taluk":"Phulparas","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847229","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847232","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847408","Taluk":"Nirmali","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"847408","Taluk":"Madhepur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847235","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847229","Taluk":"Kaluahi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847238","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847213","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847234","Taluk":"Pandaul","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847232","Taluk":"Ladania","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847404","Taluk":"Andhratharhi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847225","Taluk":"Basopatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847451","Taluk":"Supaul","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"847102","Taluk":"Jale","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847228","Taluk":"Khajauli","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847235","Taluk":"Khajauli","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847227","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847452","Taluk":"Basantpur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"847421","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847108","Taluk":"Laukaha","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847231","Taluk":"Pandaul","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847424","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847214","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847211","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847211","Taluk":"Sadar Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847109","Taluk":"Phulparas","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847232","Taluk":"Madhubnai","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847239","Taluk":"Pandaul","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847452","Taluk":"Marauna","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"847109","Taluk":"Jhanjhapur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847239","Taluk":"Benipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847229","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847424","Taluk":"Pandaul","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847411","Taluk":"Jhanjharpur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847224","Taluk":"Rajnagar","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847424","Taluk":"Madhubani","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847452","Taluk":"Chhatapur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"847403","Taluk":"Madhepur","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847424","Taluk":"Darbhanga","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"847231","Taluk":"Benipatti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847215","Taluk":"Rajnagar","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847232","Taluk":"Laukahi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847240","Taluk":"Harlakhi","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"847308","Taluk":"Beniptti","Districtname":"Madhubani","statename":"BIHAR"},
    {"pincode":"843113","Taluk":"Bandra","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842002","Taluk":"Mushahari","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844112","Taluk":"Kurhani","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"843118","Taluk":"Bochaha","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842002","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843125","Taluk":"Sahebganj","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843111","Taluk":"Motipur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843104","Taluk":"Sakra","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843123","Taluk":"Motipur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843119","Taluk":"Kurhani","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842001","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843103","Taluk":"Bochaha","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843129","Taluk":"Bochaha","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844120","Taluk":"Kurhani","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843360","Taluk":"Katra","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843128","Taluk":"Minapur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843312","Taluk":"Aurai","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843109","Taluk":"Kanti","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843105","Taluk":"Sakara","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843121","Taluk":"Muraul","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843117","Taluk":"Minapur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842004","Taluk":"Bochaha","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843120","Taluk":"Paroo","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843127","Taluk":"Motipur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844112","Taluk":"Kurhani","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843112","Taluk":"Paroo","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843101","Taluk":"Saraiya","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843321","Taluk":"Katra","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843115","Taluk":"Bandra","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843102","Taluk":"Sakra","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844127","Taluk":"Kurhani","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843105","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843122","Taluk":"Paroo","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843104","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843119","Taluk":"Motipur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844112","Taluk":"Chehra Kalan","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"842003","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843104","Taluk":"Mushahari","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843119","Taluk":"Maniari","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843126","Taluk":"Minapur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844120","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843108","Taluk":"Kanti","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843107","Taluk":"Paroo","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843105","Taluk":"Sakra","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843106","Taluk":"Kurhani","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843124","Taluk":"Motipur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843106","Taluk":"Paroo","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843119","Taluk":"Sakra","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842001","Taluk":"Muzaffarpur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843130","Taluk":"Kanti","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843143","Taluk":"Marwan","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843102","Taluk":"Sakara","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843146","Taluk":"Mushahari","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843102","Taluk":"Sakra","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844120","Taluk":"Kurhani","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"842005","Taluk":"Mushahari","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842003","Taluk":"Mushahari","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843125","Taluk":"Motipur","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842003","Taluk":"Mushahri","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844112","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"842001","Taluk":"Mushahari","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843127","Taluk":"NA","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"843109","Taluk":"Kurhani","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"854330","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854336","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854327","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854317","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854204","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854102","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"855107","Taluk":"Kishanganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854205","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854112","Taluk":"NA","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854332","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854318","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854316","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854315","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"855115","Taluk":"Kishanganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854311","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854312","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"855114","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854202","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"855102","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854101","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"855113","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"855108","Taluk":"Kishanganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854113","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854303","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854106","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"855105","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854325","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854334","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"855101","Taluk":"Kishanganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854329","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854116","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854201","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854333","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854312","Taluk":"Araria Rs","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854115","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854114","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854335","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854203","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854326","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854316","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854317","Taluk":"Katihar","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854304","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854337","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854107","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854316","Taluk":"Purnea","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854104","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854102","Taluk":"Purnea","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854301","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854108","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"855116","Taluk":"Kishanganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854315","Taluk":"Baisi","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854304","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854306","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854325","Taluk":"Purnea","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854102","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"855117","Taluk":"Kishanganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854331","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854325","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854103","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854205","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"855101","Taluk":"Kishaganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"855101","Taluk":"Kishagnaj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854117","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"855115","Taluk":"Kishangaj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854334","Taluk":"Arraia","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854105","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854335","Taluk":"Araria","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"855107","Taluk":"Kishanagnj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"855106","Taluk":"Kishanganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854102","Taluk":"Bamankhi","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854328","Taluk":"Araria","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854109","Taluk":"Katihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"854303","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854305","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854334","Taluk":"Araira","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854113","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854333","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854330","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854335","Taluk":"NA","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854336","Taluk":"Araraia","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"854335","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"854302","Taluk":"Purnea","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"855107","Taluk":"Kishangnj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"854312","Taluk":"Araraia","Districtname":"Araria","statename":"BIHAR"},
    {"pincode":"855115","Taluk":"Kishanaganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"855102","Taluk":"Kaihar","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"855107","Taluk":"Kishanganhj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"855101","Taluk":"Kishanaganj","Districtname":"Kishanganj","statename":"BIHAR"},
    {"pincode":"852213","Taluk":"Chausa","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852138","Taluk":"Kishanpur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852109","Taluk":"Raghopur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852216","Taluk":"Mahishi","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852221","Taluk":"Sour Bazar","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852116","Taluk":"Puraini","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852126","Taluk":"Salkhua","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852202","Taluk":"Kahara","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852122","Taluk":"Murliganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852218","Taluk":"Pipra Bazar","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852108","Taluk":"Gamaharia","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852217","Taluk":"S.b.raj","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852115","Taluk":"Udakishanganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852105","Taluk":"NA","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852124","Taluk":"NA","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852216","Taluk":"NA","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852121","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852217","Taluk":"Sonbersa Raj","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852116","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852214","Taluk":"Tribeniganj","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852121","Taluk":"Madhepura","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852112","Taluk":"Kumarkhan","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852202","Taluk":"Kahra","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852139","Taluk":"Triveniganj","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852131","Taluk":"Supaul","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852123","Taluk":"NA","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852215","Taluk":"Raghopur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852219","Taluk":"Alamnagar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852130","Taluk":"NA","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852123","Taluk":"Nauhatta","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"854339","Taluk":"Saharsa","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852127","Taluk":"NA","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852217","Taluk":"Sonbarsa","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852139","Taluk":"Tribeniganj","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"854339","Taluk":"Birpur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852106","Taluk":"Simri Bakhtiarpur","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"854340","Taluk":"NA","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852212","Taluk":"Kahra","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852128","Taluk":"Singheshwar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852130","Taluk":"Supaul","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852110","Taluk":"Supaul","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852217","Taluk":"Sbraj","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852123","Taluk":"NA","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852212","Taluk":"Kahara","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852132","Taluk":"Singheshwar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852127","Taluk":"Simri Bakhtiarpur","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852219","Taluk":"Sanagar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852215","Taluk":"Ragahopur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852138","Taluk":"Supaul","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852101","Taluk":"Bihariganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852114","Taluk":"Madhepura","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852112","Taluk":"Kumarkhand","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852113","Taluk":"Madhepura","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852108","Taluk":"Pipra","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852105","Taluk":"Saraigarh","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852127","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852106","Taluk":"Simri Bakhtiapur","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852125","Taluk":"Chhatapur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852218","Taluk":"Pipra Bazar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"854338","Taluk":"Birpur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"854339","Taluk":"Chatapur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852131","Taluk":"NA","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"854340","Taluk":"Basantpur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852107","Taluk":"Patarghat","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852115","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852219","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852212","Taluk":"Saharsa","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852214","Taluk":"Chattapur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852125","Taluk":"Pratapganj","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852132","Taluk":"Shankarpur","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852137","Taluk":"Chhatapur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852221","Taluk":"Saur Bazar","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852139","Taluk":"Triveniganj\\","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852129","Taluk":"Sonbersa Raj","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852213","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852101","Taluk":"Udakishanganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852111","Taluk":"Raghopur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852108","Taluk":"Gamahria","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852105","Taluk":"Saraigarh Bhaptiyahi","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852218","Taluk":"Supaul","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852124","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852220","Taluk":"Kishanganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852202","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852125","Taluk":"Raghopur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852137","Taluk":"Chhata Pur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852115","Taluk":"Gwalapara","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852112","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852126","Taluk":"Banma","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852214","Taluk":"Triveniganj","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852217","Taluk":"Patarghat","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852220","Taluk":"Udakishanganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852202","Taluk":"NA","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852107","Taluk":"Pataghat","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852129","Taluk":"Sonbarsa","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852106","Taluk":"NA","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852108","Taluk":"Gamharia","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852129","Taluk":"Sbraj","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852220","Taluk":"Saharsa","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852101","Taluk":"Uda Kishanganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852113","Taluk":"Madhepura Mdg","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852101","Taluk":"Udakisanganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852124","Taluk":"Satar Kataiya","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852216","Taluk":"NA","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"852219","Taluk":"S.a.nagar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852116","Taluk":"Chousa","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852124","Taluk":"Sattar","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852128","Taluk":"Singheshwar\\","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852116","Taluk":"Chousa","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852218","Taluk":"Piprabazar","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852108","Taluk":"Pipra Bazar","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852202","Taluk":"Sour Bazar \\","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852128","Taluk":"Singheswar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852201","Taluk":"Kahra","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852201","Taluk":"Kahara","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"854338","Taluk":"Basantpur","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852113","Taluk":"NA","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852202","Taluk":"Sour Bazar","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852219","Taluk":"Shah Alam Nagar","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"852121","Taluk":"Sour Bazar","Districtname":"Saharsa","statename":"BIHAR"},
    {"pincode":"852125","Taluk":"NA","Districtname":"Supaul","statename":"BIHAR"},
    {"pincode":"852220","Taluk":"Udakishunganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"848130","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848205","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848505","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848209","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848132","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848127","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848114","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848502","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848236","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848210","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848101","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848114","Taluk":"NA","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848101","Taluk":"NA","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848506","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848504","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848209","Taluk":"Samastipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"848160","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848501","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848117","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848125","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848114","Taluk":"Bibhutpur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848207","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848213","Taluk":"Samastipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"848206","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848102","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848503","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848302","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848236","Taluk":"Bibhutpur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848134","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848208","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848211","Taluk":"Bibhutpur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848211","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848216","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848129","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848113","Taluk":"Pusa","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848115","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848211","Taluk":"NA","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848122","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848301","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848114","Taluk":"Dalsinghsarai","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848121","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848133","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848205","Taluk":"Hasanpur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848506","Taluk":"Samastipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"848213","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848113","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848131","Taluk":"Samastipur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848208","Taluk":"Hasanpur","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848205","Taluk":"Samastipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"848502","Taluk":"Mohiuddinagar","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848125","Taluk":"NA","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848504","Taluk":"NA","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848210","Taluk":"Rosera","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848113","Taluk":"NA","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"848207","Taluk":"Samastipur","Districtname":"Darbhanga","statename":"BIHAR"},
    {"pincode":"848131","Taluk":"Mohiuddinagar","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"841207","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841414","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841411","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841421","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841208","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841401","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841219","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841213","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841402","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841221","Taluk":"Dariapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841424","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841417","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841224","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841442","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841204","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841412","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841419","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841419","Taluk":"Marhaura","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841219","Taluk":"Parsa","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841215","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841223","Taluk":"Siswan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841101","Taluk":"Sonepur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841221","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841401","Taluk":"Amnour","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841205","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841403","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841422","Taluk":"Bhagwanpur Hat","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841206","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841212","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841412","Taluk":"Jalalpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841201","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841101","Taluk":"Dighwara","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841205","Taluk":"Manjhi","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841202","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841424","Taluk":"Taraiya","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841410","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841301","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841424","Taluk":"Marhaura","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841311","Taluk":"Garkha","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841101","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841460","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841214","Taluk":"Lahladpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841401","Taluk":"Marhaura","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841415","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841212","Taluk":"Manjhi","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841208","Taluk":"Ekma","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841225","Taluk":"Dighwara","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841204","Taluk":"Ekma","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841411","Taluk":"Ishupur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841213","Taluk":"Jalalpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841209","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841211","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841442","Taluk":"Marhaura","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841401","Taluk":"Taraiya","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841225","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841224","Taluk":"Lahladpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841201","Taluk":"Manjhi","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841214","Taluk":"Revelganj","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841209","Taluk":"Manjhi","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841410","Taluk":"Panapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841216","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841217","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841417","Taluk":"Mashrakh","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841313","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841305","Taluk":"Revelganj","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841316","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841222","Taluk":"Dariapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841313","Taluk":"Manjhi","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841421","Taluk":"Jalalpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841312","Taluk":"Revelganj","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841311","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841443","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841207","Taluk":"Dighwara","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841422","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841101","Taluk":"Dariapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841220","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841221","Taluk":"Dighwara","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841222","Taluk":"Parsa","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841214","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841414","Taluk":"Nagra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841222","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841312","Taluk":"Barhara","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"841422","Taluk":"Baniapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841218","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841460","Taluk":"Maker","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841301","Taluk":"Revelganj","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841418","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841219","Taluk":"Dariapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841223","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841442","Taluk":"Nagra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841403","Taluk":"Lahladpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841403","Taluk":"Baniapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841223","Taluk":"Manjhi","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841214","Taluk":"Manjhi","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841402","Taluk":"Parsa","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841220","Taluk":"Ekma","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841443","Taluk":"Baniapur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841410","Taluk":"Ishupur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841206","Taluk":"Lahladpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841302","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841202","Taluk":"Garkha","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841305","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841205","Taluk":"Jalalpur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841417","Taluk":"Bhagwanpur Hat","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841460","Taluk":"Parsa","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841421","Taluk":"Ishupur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841312","Taluk":"Chapra","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841411","Taluk":"Marhaura","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841424","Taluk":"Ishupur","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"841211","Taluk":"Garkha","Districtname":"Saran","statename":"BIHAR"},
    {"pincode":"843334","Taluk":"Purnahiya","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843351","Taluk":"Runnisaidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843302","Taluk":"Dumra","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843334","Taluk":"Piprahi","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843324","Taluk":"Parihar","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843302","Taluk":"Riga","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843330","Taluk":"Sonbersa","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843313","Taluk":"NA","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843311","Taluk":"Runnisaidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843314","Taluk":"Bajpatti","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843323","Taluk":"R. Saidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843320","Taluk":"Pupri","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843333","Taluk":"Nanpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843317","Taluk":"Sonbarsa","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843325","Taluk":"Parsauni","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843319","Taluk":"Choraut","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843311","Taluk":"Runisaidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843327","Taluk":"Riga","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843332","Taluk":"M. Ganj","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843331","Taluk":"Sursand","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843313","Taluk":"Bairgania","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843326","Taluk":"Nanpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843328","Taluk":"R.saidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843302","Taluk":"Bathnaha","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843315","Taluk":"Majorganj","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843315","Taluk":"M. Ganj","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843311","Taluk":"R.saidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843316","Taluk":"Belsand","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843328","Taluk":"Runnisaidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843318","Taluk":"Bokhra","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843329","Taluk":"Sheohar","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843317","Taluk":"Sonbersa","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843318","Taluk":"Bokhara","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843334","Taluk":"Piprahi","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843325","Taluk":"Parsauni","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843322","Taluk":"Sonbarsa","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843317","Taluk":"NA","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843334","Taluk":"Sheohar","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843314","Taluk":"NA","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843319","Taluk":"NA","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843316","Taluk":"Belsand","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843322","Taluk":"Sonbersa","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843332","Taluk":"Majorganj","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843332","Taluk":"NA","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843323","Taluk":"Runnisaidpur","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843316","Taluk":"NA","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843334","Taluk":"Piprarhi","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843313","Taluk":"NA","Districtname":"Sheohar","statename":"BIHAR"},
    {"pincode":"843320","Taluk":"Pupari","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843327","Taluk":"NA","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843333","Taluk":"NA","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"843301","Taluk":"Dumra","Districtname":"Sitamarhi","statename":"BIHAR"},
    {"pincode":"841405","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841504","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841436","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841439","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841426","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841239","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841286","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841423","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841234","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841226","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841428","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841231","Taluk":"Andar","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841203","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841239","Taluk":"Mairwa","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841236","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841287","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841438","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841508","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841243","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841506","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841241","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841404","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841406","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841441","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841409","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841286","Taluk":"Hasanpura","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841244","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841237","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841238","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841503","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841435","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841501","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841245","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841227","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841408","Taluk":"Bhagwanpur Hat","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841232","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841408","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841425","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841235","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841437","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841413","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841210","Taluk":"Siswan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841427","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841441","Taluk":"NA","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841507","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841434","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841231","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841407","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841240","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841245","Taluk":"Ziradei","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841416","Taluk":"Lakri Nabiganj","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841242","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841420","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841233","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841440","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841416","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841509","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841210","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841404","Taluk":"Daraundha","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841505","Taluk":"Gopalganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841243","Taluk":"Siwn","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841502","Taluk":"Siwan","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841507","Taluk":"Bhagwanpur Hat","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841436","Taluk":"Hathua","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841446","Taluk":"NA","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841437","Taluk":"Gopalgan J","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841238","Taluk":"Maharajganj","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841502","Taluk":"Andar","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841440","Taluk":"Gopal;ganj","Districtname":"Gopalganj","statename":"BIHAR"},
    {"pincode":"841435","Taluk":"Guthani","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"841227","Taluk":"Pachrukhi","Districtname":"Siwan","statename":"BIHAR"},
    {"pincode":"845459","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845438","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845455","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845450","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845101","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845101","Taluk":"Bagaha-1","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845103","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845452","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845307","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845106","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845454","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845449","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845105","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845104","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845451","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845101","Taluk":"NA","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845453","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845306","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845107","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845454","Taluk":"NA","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845102","Taluk":"Bettiah","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"845438","Taluk":"Bettiah H.o.","Districtname":"West Champaran","statename":"BIHAR"},
    {"pincode":"824207","Taluk":"Konch","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824221","Taluk":"Imamganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824143","Taluk":"Daudnagar","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824103","Taluk":"Madanpur","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824203","Taluk":"Goh","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824113","Taluk":"Daudnagar","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824220","Taluk":"Barachatti","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824219","Taluk":"Amas","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824111","Taluk":"Amba","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824124","Taluk":"Obra","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824116","Taluk":"Rafiganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824217","Taluk":"Bankey Bazar","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824303","Taluk":"Nabinagar","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824116","Taluk":"Rafiganj","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824123","Taluk":"Kutumba","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824115","Taluk":"Kaler","Districtname":"Arwal","statename":"BIHAR"},
    {"pincode":"824120","Taluk":"Haspura","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824202","Taluk":"Deo","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824125","Taluk":"Rafiganj","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824101","Taluk":"Aurangabad","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824102","Taluk":"Aurangabad","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824205","Taluk":"Gurua","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824217","Taluk":"Bankey Bajar","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824210","Taluk":"Raniganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824301","Taluk":"Sherghati","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824302","Taluk":"Nabinagar","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824201","Taluk":"Brachatti","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824122","Taluk":"Rafiganj","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824211","Taluk":"Sherghati","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824114","Taluk":"Goh","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824112","Taluk":"Barun","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824127","Taluk":"Kaler","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824208","Taluk":"Madanpur","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824113","Taluk":"Daunagar","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824201","Taluk":"Barachatti","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824201","Taluk":"Barahcatti","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824129","Taluk":"Goh","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824211","Taluk":"Shergahti","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824121","Taluk":"Jamhor","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824115","Taluk":"Haspura","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824124","Taluk":"Barun","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824220","Taluk":"Dobhi","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824206","Taluk":"Imamgnaj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824201","Taluk":"Barahchatti","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824122","Taluk":"Rafigang","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824121","Taluk":"Aurangabad","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824219","Taluk":"Madanpur","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824302","Taluk":"Nabi Nagar","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824211","Taluk":"Shergahati","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824216","Taluk":"Deo","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824206","Taluk":"Imamganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824207","Taluk":"Ko0nch","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824301","Taluk":"Nabinagar","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824219","Taluk":"Gurua","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824207","Taluk":"Konch","Districtname":"Aurangabad(BH)","statename":"BIHAR"},
    {"pincode":"824127","Taluk":"Kaler","Districtname":"Arwal","statename":"BIHAR"},
    {"pincode":"824113","Taluk":"Daudnagar","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Naugachia","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813101","Taluk":"Amarpur","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"812001","Taluk":"Jagdishpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813213","Taluk":"Sultanganj","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813210","Taluk":"Goradih","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813110","Taluk":"Barahat","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813222","Taluk":"Kahalgoan","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"812006","Taluk":"Sultanganj","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"812005","Taluk":"Jagdishpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813210","Taluk":"Sabour","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813201","Taluk":"Tarapur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"853201","Taluk":"Bihpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813101","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813108","Taluk":"Sahkund","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813209","Taluk":"Pirpainti","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813204","Taluk":"Kahalgoan","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"812004","Taluk":"Nathnagar","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Chausa","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"813201","Taluk":"Asarganj","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"813103","Taluk":"Barahat","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"853202","Taluk":"Kharik","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853201","Taluk":"Bhipur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813105","Taluk":"Jagdishpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813104","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813207","Taluk":"Fullidumar","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813221","Taluk":"Shambhuganj","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813106","Taluk":"Katoria","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813209","Taluk":"Pripainti","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853203","Taluk":"Narayanpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813103","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813102","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813205","Taluk":"Kahalgoan","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813107","Taluk":"Rajoun","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"814131","Taluk":"Chandan","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813202","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813211","Taluk":"Shambhuganj","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"812003","Taluk":"Jagdishpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813103","Taluk":"Sangrampur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"813221","Taluk":"Sangrampur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"813204","Taluk":"Bihariganj","Districtname":"Madhepura","statename":"BIHAR"},
    {"pincode":"813207","Taluk":"Tarapur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"812002","Taluk":"Jagdishpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813221","Taluk":"Tarapur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"853203","Taluk":"Parbatta","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"813109","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"812006","Taluk":"Nathnagar","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813203","Taluk":"Kahalgoan","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853202","Taluk":"Kharikbazar","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813221","Taluk":"Katoria","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813106","Taluk":"Chanan","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813212","Taluk":"Sangrampur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"853205","Taluk":"Ismailpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813207","Taluk":"Phulidumar","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"814131","Taluk":"Katoria","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Rupauli","Districtname":"Purnia","statename":"BIHAR"},
    {"pincode":"813109","Taluk":"Rajoun","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813104","Taluk":"Bausi","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813222","Taluk":"Colgong","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"814131","Taluk":"Chanan","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813109","Taluk":"Barahat","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Rangra Chowk","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813104","Taluk":"Bounsi","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813205","Taluk":"Dharia","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Naugachhia","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813201","Taluk":"Shambhuganj","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813204","Taluk":"Kahalgaon","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"812004","Taluk":"NA","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813213","Taluk":"Narayanpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813221","Taluk":"Tarapur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813205","Taluk":"Kahalgaon","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813101","Taluk":"Bausi","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813113","Taluk":"Jagdishpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813105","Taluk":"Rajaun","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813109","Taluk":"Dhuraiya","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"814131","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813206","Taluk":"Pripainti","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853205","Taluk":"Gopalpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813206","Taluk":"Pirpainti","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813102","Taluk":"Bausi","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813102","Taluk":"Katoria","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813211","Taluk":"Shambhuganj","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"813203","Taluk":"Kahalgaon","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Kursela","Districtname":"Katihar","statename":"BIHAR"},
    {"pincode":"812005","Taluk":"Nathnagar","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813106","Taluk":"Amarpur","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813207","Taluk":"Fulldumar","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"853202","Taluk":"Kharik Bazar","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813105","Taluk":"Goradih","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813107","Taluk":"Rajaun","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813106","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813108","Taluk":"Shahkund","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"812006","Taluk":"Colgong","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813212","Taluk":"Kharagpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"813222","Taluk":"Kahalgaon","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813204","Taluk":"Sonhaula","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813108","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813109","Taluk":"Rajaun","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"853203","Taluk":"Pirpainti","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813205","Taluk":"Sonhaula","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813105","Taluk":"Dhuraiya","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813102","Taluk":"Amarpur","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813202","Taluk":"Belhar","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"812004","Taluk":"Nathnager","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Tarapur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"813209","Taluk":"Colgong","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813213","Taluk":"NA","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813201","Taluk":"Bausi","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813207","Taluk":"Belhar","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813207","Taluk":"Shambhuganj","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"853204","Taluk":"Gopalpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813110","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813205","Taluk":"Dhuraiya","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813107","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813110","Taluk":"Tarapur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"813213","Taluk":"sultanganj","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813214","Taluk":"Colgong","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813110","Taluk":"Dhuraiya","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813108","Taluk":"Bhagalpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813101","Taluk":"Amarpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813212","Taluk":"Sangrampur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813205","Taluk":"Sanhoula","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813212","Taluk":"Belhar","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"813209","Taluk":"NA","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813211","Taluk":"Banka","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"812007","Taluk":"Jagdishpur","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"853205","Taluk":"Rangra Chowk","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"813204","Taluk":"Colgong","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"802202","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802201","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802156","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802116","Taluk":"Buxar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802155","Taluk":"Jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802352","Taluk":"Bhojpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802114","Taluk":"Chausa","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802161","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802154","Taluk":"Jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802126","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802302","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802222","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802111","Taluk":"Duraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802119","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802301","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802135","Taluk":"Simri","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802210","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802129","Taluk":"Nawanagar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802123","Taluk":"Itarhi","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802203","Taluk":"Garhani","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802163","Taluk":"Koilwar","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802158","Taluk":"Jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802207","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802164","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802134","Taluk":"Barhampur","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802203","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802166","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802311","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802130","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802351","Taluk":"Shahpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802312","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802183","Taluk":"Jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802206","Taluk":"Udwant Nagar","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802128","Taluk":"Rajpur","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802166","Taluk":"Shahpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802207","Taluk":"Piro","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802209","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802165","Taluk":"Jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802133","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802120","Taluk":"Simri","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802113","Taluk":"Rajpur","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802112","Taluk":"Barhampur","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802208","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802152","Taluk":"Behea","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802206","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802154","Taluk":"NA","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802154","Taluk":"Behea","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802201","Taluk":"NA","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802209","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802205","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802129","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802152","Taluk":"Jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802117","Taluk":"Rajpur","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802314","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802165","Taluk":"Shahpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802103","Taluk":"Buxar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802112","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802101","Taluk":"Buxar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802102","Taluk":"Buxar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802125","Taluk":"Kesath","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802223","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802159","Taluk":"Jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802115","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802157","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802164","Taluk":"Sandesh","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802163","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802313","Taluk":"Rajpur","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802122","Taluk":"Rajpur","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802204","Taluk":"Piro","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802160","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802111","Taluk":"Chakki","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802118","Taluk":"Simri","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802120","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802136","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802131","Taluk":"Simri","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802131","Taluk":"Nawanagar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802203","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802127","Taluk":"Itarhi","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802313","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802128","Taluk":"Buxar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802352","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802161","Taluk":"Koilwar","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802204","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802159","Taluk":"Piru","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802134","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802160","Taluk":"Koilwar","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802162","Taluk":"Behea","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802205","Taluk":"Tarari","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802222","Taluk":"Tarari","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802201","Taluk":"Agiaon","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802316","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802158","Taluk":"Jagdishpur  jagdishpur","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802311","Taluk":"Barhara","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802127","Taluk":"Dumraon","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802352","Taluk":"Koilwar","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802162","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802351","Taluk":"Arrah","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"802135","Taluk":"Buxar","Districtname":"Buxar","statename":"BIHAR"},
    {"pincode":"802316","Taluk":"Barhara","Districtname":"Bhojpur","statename":"BIHAR"},
    {"pincode":"823001","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804403","Taluk":"Belaganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804426","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824233","Taluk":"Khijersarai","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804421","Taluk":"Kurtha","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804425","Taluk":"Ratnifaridpur","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824232","Taluk":"Mohanpur","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804401","Taluk":"Arwal","Districtname":"Arwal","statename":"BIHAR"},
    {"pincode":"804405","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"823003","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804425","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824231","Taluk":"Bodhgaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804419","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804419","Taluk":"Karpi","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804423","Taluk":"Ratnifaridpur","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824236","Taluk":"Tekari","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"823005","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804432","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"823311","Taluk":"Atri","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"805135","Taluk":"Atri","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804417","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"823004","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824232","Taluk":"Fatehpur","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804402","Taluk":"Arwal","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824237","Taluk":"Gurua","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804423","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"823002","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804426","Taluk":"Karpi","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804428","Taluk":"Karpi","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804422","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804404","Taluk":"Town Block Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"823003","Taluk":"Manpur","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824233","Taluk":"Belaganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824235","Taluk":"Tikari","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824118","Taluk":"G.mills","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804419","Taluk":"Karpi","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804407","Taluk":"Hulasganj","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804418","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804429","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824237","Taluk":"Bodh Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824235","Taluk":"Tekari","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824118","Taluk":"Guraru","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804404","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804427","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824232","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824233","Taluk":"Khizirsarai","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824237","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824236","Taluk":"Tikari","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824236","Taluk":"Belaganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824232","Taluk":"Fatehpur Block","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804402","Taluk":"Arwal","Districtname":"Arwal","statename":"BIHAR"},
    {"pincode":"804424","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804406","Taluk":"Ghoshi","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804406","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804420","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804423","Taluk":"Kurtha","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804428","Taluk":"Arwal","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804428","Taluk":"Kaler","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804401","Taluk":"Arwal","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804408","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"805236","Taluk":"Atri","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804430","Taluk":"Arwal","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804422","Taluk":"Makhdumpur","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824209","Taluk":"Paraiya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804419","Taluk":"Arwal","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824235","Taluk":"Konch","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824118","Taluk":"Konch","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"823002","Taluk":"Town Block Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804419","Taluk":"Karpi","Districtname":"Arwal","statename":"BIHAR"},
    {"pincode":"804426","Taluk":"Karpi","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804424","Taluk":"Belaganj","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804406","Taluk":"Ghoshi","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804427","Taluk":"Kurtha","Districtname":"Arwal","statename":"BIHAR"},
    {"pincode":"804421","Taluk":"Arwal","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804434","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824234","Taluk":"Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804435","Taluk":"Belaganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804421","Taluk":"Banshi Surajpur","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824235","Taluk":"Belaganj","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804419","Taluk":"Banshi Surajpura","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824118","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804426","Taluk":"Paliganj","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804419","Taluk":"Banshisurajpura","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804423","Taluk":"Karpi","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824233","Taluk":"Atri","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804429","Taluk":"Ratnifaridpur","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"823004","Taluk":"Bodhgaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824237","Taluk":"Guruwa","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804428","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804421","Taluk":"Sakurabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804424","Taluk":"Tekari","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824234","Taluk":"Bodh Gaya","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804402","Taluk":"Kaler","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"804421","Taluk":"Jehanabad","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804420","Taluk":"Kako","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804403","Taluk":"Makhdumpur","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"824235","Taluk":"Konch","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"824235","Taluk":"Kurtha","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"804418","Taluk":"Kako","Districtname":"Jehanabad","statename":"BIHAR"},
    {"pincode":"811105","Taluk":"Sheikhpura","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811301","Taluk":"Islamnagar","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811112","Taluk":"Surajgarha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811105","Taluk":"Ariyari","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811314","Taluk":"Sono","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811211","Taluk":"Haweli Kharagpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811304","Taluk":"Chewara","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811106","Taluk":"Samho","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"811301","Taluk":"Aliganj","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811106","Taluk":"Surajgarha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811212","Taluk":"Dharhara","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811317","Taluk":"Jamui","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Asthawan","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811103","Taluk":"Shekhosarai","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811313","Taluk":"Jamui","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811312","Taluk":"Laxmipur","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811309","Taluk":"Surajgarha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Sheopursarai","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Ramgarh Chowk","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811315","Taluk":"Sikandra","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811302","Taluk":"Barhiya","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811202","Taluk":"Munger","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811107","Taluk":"Ramgarh Chowk","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Barbigha","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Halsi","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811202","Taluk":"Dharhara","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811304","Taluk":"Halsi","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Lakhisarai","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811303","Taluk":"Chakai","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811310","Taluk":"Bandhu Bagicha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Haweli Kharagpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811202","Taluk":"Jamalpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811305","Taluk":"Khaira","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811313","Taluk":"Barahat","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"H Kharagpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811211","Taluk":"Bariarpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811317","Taluk":"Khaira","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811212","Taluk":"Dahrhara","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811308","Taluk":"Sono","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811201","Taluk":"Munger","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Shekhopur","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811315","Taluk":"Chewara","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811107","Taluk":"Ghatkeshambha","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811107","Taluk":"Ghatkusumbha","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811317","Taluk":"Khiara","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Tetiya Bambar","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811310","Taluk":"Bannu Bagicha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811316","Taluk":"Chakai","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811201","Taluk":"Sheikhpura","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811201","Taluk":"Lakhisarai","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811308","Taluk":"Jhajha","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811103","Taluk":"Asthawan","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811305","Taluk":"Khira","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811314","Taluk":"Sonoi","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811103","Taluk":"Shekhpur Sarai","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811104","Taluk":"Srmera","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811304","Taluk":"Sheikhpur","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811304","Taluk":"Ariyari","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811305","Taluk":"Jamui","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Sarmera","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811315","Taluk":"Halsi","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811314","Taluk":"Khaira","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811107","Taluk":"Ghat Koshamba","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811105","Taluk":"Aiary","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811102","Taluk":"Sarmera","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811102","Taluk":"Ghat Kushambha","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811305","Taluk":"Gidhaur","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811304","Taluk":"Sheikhpura","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811211","Taluk":"Sultanganj","Districtname":"Bhagalpur","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Tetia Bambar","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811305","Taluk":"Barhat","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811104","Taluk":"Sarmera","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811214","Taluk":"Jamalpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811105","Taluk":"Hathiyawan","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811301","Taluk":"Islam Nagar","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Bind","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811302","Taluk":"Mokamah","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"811201","Taluk":"Jamalpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811307","Taluk":"Jamui","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811211","Taluk":"Jamalpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811103","Taluk":"Sheikhpura","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811107","Taluk":"Lakhisarai","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811107","Taluk":"Sheikhpura","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811309","Taluk":"Lakhisarai","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811104","Taluk":"Ghoswari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"811102","Taluk":"Sheikhpura","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811313","Taluk":"Barhat","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811305","Taluk":"Jhajha","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Tarapur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811316","Taluk":"NA","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811316","Taluk":"Jhajha","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Shekhopur Sarai","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811310","Taluk":"Lakhisarai","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811303","Taluk":"Chakai So","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811105","Taluk":"Barbigha","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Tetiabambar","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811105","Taluk":"Mehus","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811211","Taluk":"Munger","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811310","Taluk":"Chanan (bannu Bagicha)","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811313","Taluk":"Laxmipur","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811310","Taluk":"Chanan(bannu Bagicha)","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811305","Taluk":"Khiara","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Jamui","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811310","Taluk":"Surajgarha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811202","Taluk":"Khagaria","Districtname":"Khagaria","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Ashtawan","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Hawelikhragpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Ramgarh Chowkq","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811211","Taluk":"Sadar Munger","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811103","Taluk":"Shekhopur Sarai","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811317","Taluk":"Khaira Bo","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Shekhopursarai","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Bannubagicha (chanan)","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811103","Taluk":"Shekhopura Sarai","Districtname":"Sheikhpura","statename":"BIHAR"},
    {"pincode":"811308","Taluk":"Chandan","Districtname":"Banka","statename":"BIHAR"},
    {"pincode":"811302","Taluk":"Piparia","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811107","Taluk":"Halsi","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Sangrampur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Pipariya","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Ramgarh","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Pipaia","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Hawelikharagpur","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Surajgarha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811101","Taluk":"Asthama","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811102","Taluk":"Sermera","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811106","Taluk":"Shamho","Districtname":"Begusarai","statename":"BIHAR"},
    {"pincode":"811310","Taluk":"NA","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811106","Taluk":"Surajgaraha","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"811308","Taluk":"Jhajah","Districtname":"Jamui","statename":"BIHAR"},
    {"pincode":"811213","Taluk":"Tetia","Districtname":"Munger","statename":"BIHAR"},
    {"pincode":"811103","Taluk":"Ashthwan","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"811311","Taluk":"Piparia","Districtname":"Lakhisarai","statename":"BIHAR"},
    {"pincode":"801303","Taluk":"NA","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803107","Taluk":"Asthawan","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803213","Taluk":"Barh","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803214","Taluk":"Barh","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803114","Taluk":"Nursarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803120","Taluk":"Nursarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801305","Taluk":"Nagarnausa","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801302","Taluk":"Hilsa","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803114","Taluk":"Parwalpur","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803101","Taluk":"Bihar","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803119","Taluk":"Rahui","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801307","Taluk":"Chandi","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803213","Taluk":"Belchi","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803113","Taluk":"Nursarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803114","Taluk":"Ben","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801304","Taluk":"Daniyawan","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801303","Taluk":"Islampur","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803118","Taluk":"Sohsarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803120","Taluk":"Tharthari","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803107","Taluk":"Asthama","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803211","Taluk":"Athmalgola","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801301","Taluk":"Ekangersarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803303","Taluk":"Mokama","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803110","Taluk":"Chandi","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803108","Taluk":"Chandi","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801304","Taluk":"Karaipersurai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803110","Taluk":"Harnaut","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803306","Taluk":"Ghoshwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803212","Taluk":"Bakhtiarpur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803110","Taluk":"Belchi","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803117","Taluk":"Islampur","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803117","Taluk":"Ben","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803118","Taluk":"Nursarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803111","Taluk":"Silao","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803116","Taluk":"Rajgir","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803307","Taluk":"Pandarak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803216","Taluk":"Biharsharif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803107","Taluk":"Belchi Sharif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803110","Taluk":"Rahui","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801301","Taluk":"Parwalpur","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803307","Taluk":"Pandaak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803108","Taluk":"Nagarnausa","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803118","Taluk":"Rahui","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801307","Taluk":"Tharthari","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803113","Taluk":"Chandi","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803117","Taluk":"Rajgir","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803101","Taluk":"Biharsharif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803107","Taluk":"Bind","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803212","Taluk":"Athmalgola","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801302","Taluk":"NA","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803302","Taluk":"Mokama","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803115","Taluk":"Giriak","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803216","Taluk":"Nursarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803111","Taluk":"Biharsarif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803302","Taluk":"Ghoshwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803216","Taluk":"NA","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803117","Taluk":"Silao","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803212","Taluk":"Bakhtiarpurj","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803108","Taluk":"Biharsharif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801301","Taluk":"NA","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803301","Taluk":"Mokama","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803107","Taluk":"Rahui","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801303","Taluk":"Ishlampur","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803221","Taluk":"Pandarak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801306","Taluk":"Ekangersarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803211","Taluk":"Asthawan","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803116","Taluk":"Bathani","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801301","Taluk":"Ekangarsarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803213","Taluk":"Pandarak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803116","Taluk":"Islampur","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803111","Taluk":"Noorsaran","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803111","Taluk":"Ben","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803111","Taluk":"Noorsarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803110","Taluk":"Bakhtiarpur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803213","Taluk":"Bah","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801303","Taluk":"Hulashganj","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801306","Taluk":"NA","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803216","Taluk":"Bihar","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803115","Taluk":"Silao","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803113","Taluk":"Nusarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803115","Taluk":"Biharsarif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803215","Taluk":"Barh","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803121","Taluk":"Rajgir","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803107","Taluk":"Biharsarif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803211","Taluk":"Barh","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803216","Taluk":"Bihar Sharif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803111","Taluk":"Nursarai","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803306","Taluk":"Ghoswari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801305","Taluk":"Patna","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803118","Taluk":"Biharsharif","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803213","Taluk":"Bind","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"801306","Taluk":"Telhara","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"803109","Taluk":"Nawada","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805123","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805131","Taluk":"NA","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"805125","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805122","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805108","Taluk":"Kashi Chak","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805109","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805124","Taluk":"Pakribarawan","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805129","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805132","Taluk":"Warisaliganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805111","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805126","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805128","Taluk":"NA","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"805105","Taluk":"NA","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"805132","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805103","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805108","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805121","Taluk":"Gobindpur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805125","Taluk":"Rajauli","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805101","Taluk":"Pakribarawan","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805130","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805104","Taluk":"Nawada","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805121","Taluk":"Akbarpur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805107","Taluk":"Roh","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805102","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805124","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805121","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805124","Taluk":"Nawada","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805127","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805128","Taluk":"Akbarpur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805106","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805129","Taluk":"NA","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"805112","Taluk":"Pakribarawan","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805104","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805129","Taluk":"Hisua","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805132","Taluk":"Pakribarawan","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805112","Taluk":"Nawada","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805132","Taluk":"Nawada","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805132","Taluk":"Pakribrawana","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805112","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805122","Taluk":"Narhat","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"803109","Taluk":"Giriak","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"805111","Taluk":"Nawada","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"803109","Taluk":"Warisaliganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805102","Taluk":"Akbarpur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805130","Taluk":"NA","Districtname":"Nalanda","statename":"BIHAR"},
    {"pincode":"805106","Taluk":"Kawakol","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805130","Taluk":"Warisaliganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805126","Taluk":"Akbarpur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805108","Taluk":"Warisaliganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805122","Taluk":"Meskaur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805107","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805112","Taluk":"Akbarpur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805104","Taluk":"Roh","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805102","Taluk":"Hisua","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805110","Taluk":"Nawadha","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805133","Taluk":"Nardiganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805111","Taluk":"Hisua","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805126","Taluk":"Sirdala","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805104","Taluk":"Warisaliganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805127","Taluk":"Sirdala","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805109","Taluk":"NA","Districtname":"Gaya","statename":"BIHAR"},
    {"pincode":"805141","Taluk":"NA","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805123","Taluk":"Nardiganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805132","Taluk":"Roh","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805127","Taluk":"Warisaliganj","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805126","Taluk":"Gobindpur","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"805129","Taluk":"Pakribarawan","Districtname":"Nawada","statename":"BIHAR"},
    {"pincode":"801103","Taluk":"Bihta","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801109","Taluk":"Naubatpur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801110","Taluk":"Paliganj","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801108","Taluk":"Maner","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801507","Taluk":"Patna","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801102","Taluk":"Dulhin Bazar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801109","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804453","Taluk":"Masaurhi","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801104","Taluk":"Paliganj","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800012","Taluk":"Dinapur-Cum-Khagaul","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801505","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803203","Taluk":"Fatwa","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803202","Taluk":"NA","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801109","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801103","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801103","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801113","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800002","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800025","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800020","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801503","Taluk":"Khagual","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800001","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800022","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800026","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801501","Taluk":"Dinapur-Cum-Khagaul","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800017","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804454","Taluk":"Masaurhi","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800014","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804451","Taluk":"Masaurhi","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803202","Taluk":"Patna City","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800007","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801111","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801113","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800004","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803201","Taluk":"Patna City","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804451","Taluk":"Punpun","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801112","Taluk":"Paliganj","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804452","Taluk":"Masaurhi","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803201","Taluk":"Fatwah","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800018","Taluk":"Dinapur-Cum-Khagaul","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801503","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800009","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801102","Taluk":"Paliganj","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803202","Taluk":"Khusrupur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801112","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800019","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801503","Taluk":"Dinapur-Cum-Khagaul","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804452","Taluk":"NA","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800008","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804453","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"803202","Taluk":"Dhanarua","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801103","Taluk":"NA","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801104","Taluk":"Bikram","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800007","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801506","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801505","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800011","Taluk":"Dinapur-Cum-Khagaul","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801503","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801108","Taluk":"NA","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800004","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804451","Taluk":"Patna City","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801503","Taluk":"NA","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800003","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801111","Taluk":"Bihta","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800024","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801105","Taluk":"Dinapur-Cum-Khagaul","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801105","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800023","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800016","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800006","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801108","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800009","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800012","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800027","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804453","Taluk":"Punpun","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800002","Taluk":"Patna Sadar","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800029","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800013","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800028","Taluk":"NA","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800015","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800005","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"804452","Taluk":"Danapur","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800016","Taluk":"Sampatchak","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"801503","Taluk":"Dinapur-cum-Khagaul","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800010","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"800021","Taluk":"Phulwari","Districtname":"Patna","statename":"BIHAR"},
    {"pincode":"802226","Taluk":"Bikramganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821312","Taluk":"Dehri On Sone","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821102","Taluk":"Adhaura","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"802132","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821101","Taluk":"Bhabua","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821108","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"802213","Taluk":"Bikramganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821109","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821301","Taluk":"Akorhi","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821301","Taluk":"Akorhi Gola","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821113","Taluk":"Sasaram","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821106","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821104","Taluk":"Chenari","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821309","Taluk":"Dehri On Sone","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821310","Taluk":"Dehri On Sone","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821302","Taluk":"Rohtas","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802214","Taluk":"Karakat","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802213","Taluk":"Dinara","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821107","Taluk":"Sasaram","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802218","Taluk":"Bikramganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802211","Taluk":"Dawath","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821309","Taluk":"Nasriganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802215","Taluk":"Sasaram","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821106","Taluk":"Chainpur","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"802216","Taluk":"Dawath","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821311","Taluk":"Dehri On Sone","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821303","Taluk":"Rohtas","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821301","Taluk":"Akorhi Bazar","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821111","Taluk":"Sasaram","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821306","Taluk":"Dehri","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821103","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821305","Taluk":"Dehri","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821110","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821109","Taluk":"Bhabua","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821107","Taluk":"NA","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802215","Taluk":"Nokha","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821108","Taluk":"Kudra","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821304","Taluk":"Rohtas","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802217","Taluk":"Sasaram","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802220","Taluk":"Bikramganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821102","Taluk":"Bhagwanpur","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"802215","Taluk":"Bikramganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821105","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"802212","Taluk":"Bikramganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802218","Taluk":"Dinara","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802219","Taluk":"Rajpur","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802219","Taluk":"Dehri On Sone","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821103","Taluk":"Chainpur","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821312","Taluk":"Tilouthu","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802132","Taluk":"Nuaon","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821110","Taluk":"Chainpur","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821112","Taluk":"Kochas","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821112","Taluk":"Sasaram","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802226","Taluk":"Dawath","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821113","Taluk":"Chenari","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821307","Taluk":"Dehri","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821107","Taluk":"Kargahar","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821105","Taluk":"NA","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821104","Taluk":"Sheosagar","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821111","Taluk":"Sheosagar","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821105","Taluk":"Durgawati","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821115","Taluk":"Sasaram","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802215","Taluk":"Garh Nokha","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821106","Taluk":"Chand","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821310","Taluk":"Nasriganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802221","Taluk":"Bikramganj","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821308","Taluk":"Tilouthu","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802220","Taluk":"Karakat","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821102","Taluk":"NA","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821110","Taluk":"Nuaon","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821305","Taluk":"Dehri On Sone","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802213","Taluk":"Sanjhauli","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821102","Taluk":"Chand","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821108","Taluk":"Koura","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821101","Taluk":"NA","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821108","Taluk":"Bhabua","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821308","Taluk":"Rohtas","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821302","Taluk":"Tilouthu","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802132","Taluk":"Ramgarh","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"802219","Taluk":"Dehri","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821110","Taluk":"Ramgarh","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821311","Taluk":"Rohtas","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821104","Taluk":"Rampur","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821109","Taluk":"Kudra","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"802220","Taluk":"Nokha","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821310","Taluk":"Akorhi Gola","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821308","Taluk":"Dehri","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802213","Taluk":"Mohania","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821306","Taluk":"Dehri On Sone","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802221","Taluk":"Surajpura","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"802218","Taluk":"Chainpur","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"821108","Taluk":"Chenari","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821310","Taluk":"Nokha","Districtname":"Rohtas","statename":"BIHAR"},
    {"pincode":"821102","Taluk":"Bhabuaa","Districtname":"Kaimur (Bhabua)","statename":"BIHAR"},
    {"pincode":"843114","Taluk":"Patepur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844123","Taluk":"Vaishali","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844126","Taluk":"Jhandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"843110","Taluk":"Patepur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844125","Taluk":"Bhagwanpur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844506","Taluk":"Mahnar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844113","Taluk":"Vaishali","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844111","Taluk":"Paedhi Belsar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844103","Taluk":"Hajipur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844505","Taluk":"Jandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844114","Taluk":"Bhagwanpur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Sahdei Buzurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844111","Taluk":"Saraiya","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844502","Taluk":"Hajipur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844124","Taluk":"Rajapakar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844509","Taluk":"Sahdai Buzurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844502","Taluk":"Bidupur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844503","Taluk":"Bidupur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Rajapakar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844122","Taluk":"Chehrakala","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Desari","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844501","Taluk":"Mahnar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844118","Taluk":"Goraul","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844125","Taluk":"Rajapakar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844111","Taluk":"P. Belsar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844122","Taluk":"Mahuwa","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844102","Taluk":"Bidupur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844505","Taluk":"Jhandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844111","Taluk":"P.belsar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844116","Taluk":"Rajapakar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844125","Taluk":"NA","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844508","Taluk":"Raghopur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844116","Taluk":"Goraul","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844115","Taluk":"Jhandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844126","Taluk":"Mahuwa","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844509","Taluk":"Sahdei Buzurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844118","Taluk":"Paterhi Belsar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844128","Taluk":"Vaishali","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844115","Taluk":"Bidupur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844505","Taluk":"Sahdei Buzurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844122","Taluk":"Mahua","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Jhandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844111","Taluk":"Vaishali","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844117","Taluk":"Lalganj","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844102","Taluk":"Hajipur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844118","Taluk":"Kurhani","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844115","Taluk":"Rajapakar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844119","Taluk":"Goroul","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844118","Taluk":"Goroul","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844507","Taluk":"Mahnar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844117","Taluk":"Lalgang","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844101","Taluk":"Hajipur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844111","Taluk":"Belsar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844506","Taluk":"Patori","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"844121","Taluk":"Lalganj","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844123","Taluk":"Paterhi Belsar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844122","Taluk":"Goroul","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844119","Taluk":"Lalganj","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844507","Taluk":"Jhandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Sahdei Bujurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Mahua","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844122","Taluk":"Jhandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844505","Taluk":"Jhanda","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844118","Taluk":"Bhagwanpur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844126","Taluk":"Patepur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844125","Taluk":"Lalganj","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Bidupur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844506","Taluk":"Sahdei  Bujurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844504","Taluk":"Jandaha","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844124","Taluk":"Hajipur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844505","Taluk":"Mahnar","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844506","Taluk":"Sahdei Bujurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844118","Taluk":"Kurhanni","Districtname":"Muzaffarpur","statename":"BIHAR"},
    {"pincode":"844114","Taluk":"Bhaghwanpur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844507","Taluk":"Sahdei Buzurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844123","Taluk":"Bhagwanpur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844507","Taluk":"S Buzurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844102","Taluk":"Raghopur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844123","Taluk":"Lalganj","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844505","Taluk":"S Buzurg","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844126","Taluk":"Mahua","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844505","Taluk":"NA","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844123","Taluk":"Lalgang","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"844501","Taluk":"Patori","Districtname":"Samastipur","statename":"BIHAR"},
    {"pincode":"844125","Taluk":"Hajipur","Districtname":"Vaishali","statename":"BIHAR"},
    {"pincode":"494335","Taluk":"Kanker","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494001","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494331","Taluk":"Keshkal","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494228","Taluk":"Keshkal","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494230","Taluk":"Keshkal","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494333","Taluk":"Kanker","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494665","Taluk":"Antagarh","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494337","Taluk":"NA","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494556","Taluk":"Dantewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494122","Taluk":"Konta","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494635","Taluk":"Bhanupratappur","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494221","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494669","Taluk":"Bhaupratappur","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494446","Taluk":"Bhopalpatnam","Districtname":"Bijapur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"494334","Taluk":"Kanker","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494447","Taluk":"Bhopalpatnam","Districtname":"Bijapur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"494226","Taluk":"Kondagaon","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494553","Taluk":"Dantewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494010","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494347","Taluk":"Keshkal","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494224","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494552","Taluk":"Dantewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494442","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494223","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494222","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494115","Taluk":"Konta","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494771","Taluk":"Pakhanjore","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494450","Taluk":"Bijapur","Districtname":"Bijapur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"494111","Taluk":"Konta","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494441","Taluk":"Danteawada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494670","Taluk":"Kanker","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494661","Taluk":"Narayanpur","Districtname":"Narayanpur","statename":"CHATTISGARH"},
    {"pincode":"494449","Taluk":"Dantaewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494449","Taluk":"Dantewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494114","Taluk":"Konta","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494777","Taluk":"Pakhanjore","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494776","Taluk":"Pakhanjore","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494441","Taluk":"Dantewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494450","Taluk":"Dantewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494450","Taluk":"Bhairamgarh","Districtname":"Bijapur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"494229","Taluk":"Keshkal","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494551","Taluk":"Dantewada","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494669","Taluk":"Bhanupratappur","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494337","Taluk":"Charama","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494444","Taluk":"Bijapur","Districtname":"Bijapur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"494335","Taluk":"NA","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494111","Taluk":"Jagdalpur","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494448","Taluk":"Bhopalpatnam","Districtname":"Bijapur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"494336","Taluk":"Kanker","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494669","Taluk":"Bhanupratappur4","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"494552","Taluk":"Dantewada","Districtname":"Bijapur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"494111","Taluk":"Konta","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494001","Taluk":"NA","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494229","Taluk":"Kondagaon","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494556","Taluk":"Dantewda","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494448","Taluk":"Bhopalpatnam","Districtname":"Dantewada","statename":"CHATTISGARH"},
    {"pincode":"494228","Taluk":"Kondagaon","Districtname":"Bastar","statename":"CHATTISGARH"},
    {"pincode":"494335","Taluk":"Narharpur","Districtname":"Kanker","statename":"CHATTISGARH"},
    {"pincode":"495113","Taluk":"Kargi Road","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495695","Taluk":"Malkharoda","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495660","Taluk":"NA","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495684","Taluk":"Balconagar","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495690","Taluk":"Jaijaipur","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495552","Taluk":"NA","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495668","Taluk":"Janjgir","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495115","Taluk":"Lormi","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495692","Taluk":"Chandrapur","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495119","Taluk":"Pendra","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495689","Taluk":"Sakti","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495691","Taluk":"Malkharoda","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495330","Taluk":"Takhatpur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495663","Taluk":"Gopalnagar","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495224","Taluk":"Bilha","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495117","Taluk":"Pendra Road","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495550","Taluk":"Jairamnagar","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495552","Taluk":"Dabhara","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495559","Taluk":"Baloda","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495335","Taluk":"Patharia","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495692","Taluk":"NA","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495118","Taluk":"Marwahi","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495006","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495003","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495449","Taluk":"Pali","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495684","Taluk":"Janjgir","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495455","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495660","Taluk":"Champa","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495695","Taluk":"Adbhar","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495448","Taluk":"Machadoli","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495445","Taluk":"Katghora","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495334","Taluk":"Mungeli","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495447","Taluk":"Katghora","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495687","Taluk":"Sakti","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495552","Taluk":"Akaltara","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495554","Taluk":"Pamgarh","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495674","Taluk":"Bhaisma","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495671","Taluk":"Champa","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495691","Taluk":"Malkharoda","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495687","Taluk":"Baradwar","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495116","Taluk":"Kargiroad","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495454","Taluk":"Kusmunda","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495674","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495112","Taluk":"Ganiyari","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495551","Taluk":"Masturi","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495557","Taluk":"Seorinarayan","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495556","Taluk":"Kharod","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495001","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495223","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495004","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495446","Taluk":"Hardibazar","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495009","Taluk":"Koni","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495661","Taluk":"Champa","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495683","Taluk":"Rajgamar","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495220","Taluk":"Bilha","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495692","Taluk":"Dabhra","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495442","Taluk":"Ratanpur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495116","Taluk":"Belgahana","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495688","Taluk":"Dabhra","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495450","Taluk":"Jamnipali","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495117","Taluk":"Pendraroad","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495686","Taluk":"Baradwar","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495682","Taluk":"Manikpur","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495452","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495112","Taluk":"Takhatpur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495684","Taluk":"Machadoli","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495446","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495661","Taluk":"Birra","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495663","Taluk":"Masturi","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495448","Taluk":"Katghora","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495668","Taluk":"Janjgir","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495677","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495220","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495222","Taluk":"Hirri Mines","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495222","Taluk":"Bilha","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495690","Taluk":"Janjgir","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495550","Taluk":"Masturi","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495450","Taluk":"Katghora","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495660","Taluk":"Bamhni Bazar","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495556","Taluk":"Pamgarh","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495553","Taluk":"Nariyara","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495009","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495454","Taluk":"Katghora","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495682","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495334","Taluk":"NA","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495118","Taluk":"Pendra Road","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495447","Taluk":"Bankimongra","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495220","Taluk":"Chakarbhata Camp","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495668","Taluk":"NA","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495553","Taluk":"Janjgir","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495449","Taluk":"Katghora","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495555","Taluk":"Bilaspur","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495335","Taluk":"Mungeli","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495119","Taluk":"Pendra Road","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495552","Taluk":"Kharod","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495450","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495660","Taluk":"Patharia","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495683","Taluk":"Korba","Districtname":"Korba","statename":"CHATTISGARH"},
    {"pincode":"495118","Taluk":"Marwahi","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495686","Taluk":"Chamba","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495555","Taluk":"Seepat","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"495559","Taluk":"NA","Districtname":"Janjgir-champa","statename":"CHATTISGARH"},
    {"pincode":"495116","Taluk":"Belgahna","Districtname":"Bilaspur(CGH)","statename":"CHATTISGARH"},
    {"pincode":"490001","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491223","Taluk":"Gunderdehi","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491771","Taluk":"Dondi Lohara","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491226","Taluk":"Balod","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"490036","Taluk":"Dhamdha","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491221","Taluk":"Gunderdehi","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491666","Taluk":"Mohla","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491228","Taluk":"Dondi Awari","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491668","Taluk":"Ambagarh Chowki","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491665","Taluk":"Ambagarh Chowk","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491332","Taluk":"Berla","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491337","Taluk":"Nawagarh","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491445","Taluk":"Dongargarh","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491001","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491665","Taluk":"Ambagarh Chowki","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491225","Taluk":"Gunderdehi","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491441","Taluk":"Dongargaon","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491222","Taluk":"Gunderdehi","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491558","Taluk":"Dongargaon","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491661","Taluk":"Dongargaon","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491888","Taluk":"Khairagarh","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"490021","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491229","Taluk":"Manpur","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491335","Taluk":"Bemetara","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491340","Taluk":"Nandghat","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491559","Taluk":"Pandaria","Districtname":"Kawardha","statename":"CHATTISGARH"},
    {"pincode":"491441","Taluk":"Rajnandgaon","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491557","Taluk":"Chhuria","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491995","Taluk":"Kawardha","Districtname":"Kawardha","statename":"CHATTISGARH"},
    {"pincode":"491881","Taluk":"Khairagarh","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491227","Taluk":"Gurur","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491336","Taluk":"Kawardha","Districtname":"Kawardha","statename":"CHATTISGARH"},
    {"pincode":"491993","Taluk":"Saja","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491331","Taluk":"Dhamdha","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491111","Taluk":"Patan","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491228","Taluk":"Balod","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491444","Taluk":"Rajnandgaon","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"490025","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"490009","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491001","Taluk":"NA","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491888","Taluk":"Dondiluhara","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491885","Taluk":"Chhuikhadan","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491558","Taluk":"Chhuria","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491557","Taluk":"NA","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491881","Taluk":"Khairagarh Raj","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491337","Taluk":"Nawgarh","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"490006","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491888","Taluk":"Gandai Pandaria","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"490024","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491559","Taluk":"Pandariya","Districtname":"Kawardha","statename":"CHATTISGARH"},
    {"pincode":"491107","Taluk":"Patan","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491771","Taluk":"Dondiluhara","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491338","Taluk":"Saja","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"490026","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491668","Taluk":"Ambagarh","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491441","Taluk":"Khairagarh","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"490042","Taluk":"Patan","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491559","Taluk":"Kawardha","Districtname":"Kawardha","statename":"CHATTISGARH"},
    {"pincode":"490011","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"490023","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491557","Taluk":"Dongargarh","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491228","Taluk":"Dondi","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491445","Taluk":"Rajnandgaon","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"491335","Taluk":"Dhamdha","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"490020","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491111","Taluk":"Patah","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491888","Taluk":"Saja","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491335","Taluk":"Berla","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"490022","Taluk":"Durg","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491226","Taluk":"Sanjaribalod","Districtname":"Durg","statename":"CHATTISGARH"},
    {"pincode":"491888","Taluk":"Chhuikhadan","Districtname":"Rajnandgaon","statename":"CHATTISGARH"},
    {"pincode":"497001","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497001","Taluk":"Ambikapur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496107","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496551","Taluk":"Sarangarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496661","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496245","Taluk":"Kunkuri","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497220","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497116","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496331","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496330","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496100","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497101","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496224","Taluk":"Bagicha","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496338","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496450","Taluk":"Sarangarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496440","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496554","Taluk":"Sarangarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497331","Taluk":"NA","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496220","Taluk":"Jashpurnagar","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496440","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497001","Taluk":"Rajpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496115","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497778","Taluk":"NA","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496111","Taluk":"Gharghoda","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496220","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496336","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497231","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497335","Taluk":"NA","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496118","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496116","Taluk":"Udaipur (Dharamjaigarh)","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497333","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497225","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497119","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496223","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497114","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496334","Taluk":"Kunkuri","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496665","Taluk":"Udaipur (Dharamjaigarh)","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496001","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496665","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497339","Taluk":"NA","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496554","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496225","Taluk":"NA","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497335","Taluk":"Baikunthapur","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496665","Taluk":"Kharsia","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497449","Taluk":"NA","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"497223","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497111","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497226","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496113","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496445","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496242","Taluk":"Kunkuri","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497235","Taluk":"Surajpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496450","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497224","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497101","Taluk":"Surguja","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496661","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497225","Taluk":"Pratappur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496001","Taluk":"NA","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497447","Taluk":"Manendragarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496336","Taluk":"Jashpurnagar","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497235","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497778","Taluk":"Janakpur","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496223","Taluk":"Bagicha","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496111","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497220","Taluk":"Surguja","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496445","Taluk":"Sarangarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496445","Taluk":"Sarnagarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496100","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497101","Taluk":"Sitapur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497449","Taluk":"Manendragarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496115","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497339","Taluk":"Baikunthapur","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"497117","Taluk":"Lakhanpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497231","Taluk":"Surajpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497118","Taluk":"Surguja","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497229","Taluk":"Surajpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496665","Taluk":"Dharmajaigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497111","Taluk":"Sitapur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497333","Taluk":"Surajpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497223","Taluk":"Surguja","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496116","Taluk":"Dharmajaigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496107","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496118","Taluk":"Pathalgaon","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497331","Taluk":"Baikunthapur","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"497224","Taluk":"Kusmi","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496242","Taluk":"Kunkuri","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496113","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496330","Taluk":"Jashpurnagar","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497118","Taluk":"Pathalgaon","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496331","Taluk":"Jashpurnagar","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497226","Taluk":"Surajpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497559","Taluk":"Janakpur","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496338","Taluk":"Jashpurnagar","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497442","Taluk":"Manendragarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496225","Taluk":"Kunkuri","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497555","Taluk":"Manendragarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496005","Taluk":"Raigarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497225","Taluk":"Pratppur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497114","Taluk":"Surguja","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497451","Taluk":"Chirimiri","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496227","Taluk":"Kunkuri","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497116","Taluk":"Lakhanpur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497446","Taluk":"Manendragarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496551","Taluk":"Saramgarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496331","Taluk":"Jashpur","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496118","Taluk":"Pathalgoan","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497229","Taluk":"NA","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496661","Taluk":"Kharsia","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497559","Taluk":"Baikunthapur","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496225","Taluk":"Jashpurnagar","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"497553","Taluk":"Chirimiri","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"497119","Taluk":"Ambikapur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496450","Taluk":"Sarnagarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496220","Taluk":"Pathalgaon","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496113","Taluk":"Raigrh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497114","Taluk":"Ambikapur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497223","Taluk":"Pratappur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496109","Taluk":"Gharghoda","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497118","Taluk":"Ambikapur","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"496223","Taluk":"Jashpur","Districtname":"Jashpur","statename":"CHATTISGARH"},
    {"pincode":"496107","Taluk":"Raiggarh","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"496554","Taluk":"Saria","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497231","Taluk":"Surguja","Districtname":"Surguja","statename":"CHATTISGARH"},
    {"pincode":"497557","Taluk":"Chirimiri","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"497448","Taluk":"Manendragarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"497449","Taluk":"Manendrgarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"496108","Taluk":"Tamnar","Districtname":"Raigarh","statename":"CHATTISGARH"},
    {"pincode":"497773","Taluk":"Manendragarh","Districtname":"Koriya","statename":"CHATTISGARH"},
    {"pincode":"493661","Taluk":"Abhanpur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493445","Taluk":"Mahasamund","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493773","Taluk":"Dhamtari","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493663","Taluk":"Kurud","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493225","Taluk":"Kharora","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493526","Taluk":"Lawan","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493992","Taluk":"Phingeshwar","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493111","Taluk":"Manddar Cement Factory","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493335","Taluk":"Kasdol","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493778","Taluk":"Nagri","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493558","Taluk":"Saraipali","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493228","Taluk":"Pallari","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493890","Taluk":"Deobhog","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493559","Taluk":"Sarsiwa","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493554","Taluk":"Bsna","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493770","Taluk":"Kurud","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493441","Taluk":"Arang","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493555","Taluk":"Jagdishpur","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493331","Taluk":"Rawan","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492109","Taluk":"Panduka","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493449","Taluk":"Mahasamund","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493778","Taluk":"NA","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493116","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493449","Taluk":"NA","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493332","Taluk":"Baloda Bazar","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493101","Taluk":"Simga","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493554","Taluk":"Basna","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493551","Taluk":"Pithora","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493344","Taluk":"Katgi","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493663","Taluk":"Kurud","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493551","Taluk":"Kasdol","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493885","Taluk":"Rajim","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493889","Taluk":"Gariaband","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493554","Taluk":"Mahasamund","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493551","Taluk":"Pithora","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493662","Taluk":"Kurud","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493449","Taluk":"Bagbahra","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"492112","Taluk":"Mahasamund","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493113","Taluk":"Hathband","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493770","Taluk":"Dhamtari","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493225","Taluk":"Arang","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493118","Taluk":"Bhatapara","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493195","Taluk":"Hirmi","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493776","Taluk":"Rudri","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"493222","Taluk":"Bilaigarh","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493229","Taluk":"Watgan","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493558","Taluk":"Basna","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village Bhotha","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493558","Taluk":"NA","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493338","Taluk":"Bilaigarh","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493221","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493992","Taluk":"Phigeshwar","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492001","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493114","Taluk":"Neora","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492101","Taluk":"Mandi R Hasod","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492112","Taluk":"Sankra","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493116","Taluk":"Baikunth","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492112","Taluk":"Sankra","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493996","Taluk":"Chhura","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493770","Taluk":"NA","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"492015","Taluk":"Mana Camp","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village  Deori","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"492112","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493221","Taluk":"Birgaon","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493222","Taluk":"Bhatgaon","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493888","Taluk":"Gariaband","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493111","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493196","Taluk":"Grasim Vihar Rawan","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493889","Taluk":"Gariand","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492101","Taluk":"Mandir Hasod","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493891","Taluk":"Gariaband","Districtname":"Gariaband","statename":"CHATTISGARH"},
    {"pincode":"493196","Taluk":"Simga","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492009","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493113","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village Hathibahra","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493195","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493555","Taluk":"Pithora","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village of Kaskera","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493344","Taluk":"Kasdol","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492008","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village Khatti","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village Komakhan of  Mahasa","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493887","Taluk":"Gariaband","Districtname":"Gariaband","statename":"CHATTISGARH"},
    {"pincode":"493225","Taluk":"Tilda","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492012","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493221","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492101","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493890","Taluk":"Debhog","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493332","Taluk":"Baloda  Bazar","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493526","Taluk":"Baloda Bazar","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493889","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493888","Taluk":"Bindranawagarh","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493888","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493331","Taluk":"Baloda Bazar","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492015","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492002","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492013","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492109","Taluk":"Bindranawagarh","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493890","Taluk":"Deobhag","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village of Narra","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493113","Taluk":"Simga","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493881","Taluk":"Rajim","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493114","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492004","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492109","Taluk":"Chhura","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492112","Taluk":"NA","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493551","Taluk":"Mahasamund","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493551","Taluk":"NA","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493885","Taluk":"Phingeshwar","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492010","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492001","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493776","Taluk":"Dhamtari","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"492014","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493448","Taluk":"GP Village Salhebhata","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493441","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493778","Taluk":"Nagir","Districtname":"Dhamtari","statename":"CHATTISGARH"},
    {"pincode":"492112","Taluk":"Basna","Districtname":"Mahasamund","statename":"CHATTISGARH"},
    {"pincode":"493559","Taluk":"Bhatgaon","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493992","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492099","Taluk":"Dharsiwa","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492099","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492003","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492005","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"492008","Taluk":"NA","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"493229","Taluk":"Raipur","Districtname":"Raipur","statename":"CHATTISGARH"},
    {"pincode":"110092","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110051","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110032","Taluk":"NA","Districtname":"North East Delhi","statename":"DELHI"},
    {"pincode":"110090","Taluk":"East Delhi","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110032","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110053","Taluk":"NA","Districtname":"North East Delhi","statename":"DELHI"},
    {"pincode":"110053","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110091","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110094","Taluk":"NA","Districtname":"North East Delhi","statename":"DELHI"},
    {"pincode":"110095","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110031","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110053","Taluk":"East Delhi","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110096","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110094","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110093","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110090","Taluk":"NA","Districtname":"East Delhi","statename":"DELHI"},
    {"pincode":"110093","Taluk":"NA","Districtname":"North East Delhi","statename":"DELHI"},
    {"pincode":"110094","Taluk":"Delhi North East","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110090","Taluk":"NA","Districtname":"North East Delhi","statename":"DELHI"},
    {"pincode":"110006","Taluk":"Delhi","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110033","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110036","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110034","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110054","Taluk":"North","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110052","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110039","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110085","Taluk":"NA","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110042","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110040","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110006","Taluk":"NA","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110086","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110084","Taluk":"Delhi","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110007","Taluk":"Delhi","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110081","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110054","Taluk":"Delhi","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110009","Taluk":"NA","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110009","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110035","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110088","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110082","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110007","Taluk":"NA","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110083","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110083","Taluk":"NA","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110036","Taluk":"NA","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110085","Taluk":"North West Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110056","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110086","Taluk":"NA","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110085","Taluk":"Delhi","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110085","Taluk":"NA","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110089","Taluk":"NA","Districtname":"North West Delhi","statename":"DELHI"},
    {"pincode":"110084","Taluk":"North","Districtname":"North Delhi","statename":"DELHI"},
    {"pincode":"110002","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110055","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110005","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110001","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110008","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110003","Taluk":"Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110003","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110006","Taluk":"Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110012","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110011","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110060","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110004","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110069","Taluk":"New Delhi","Districtname":"Central Delhi","statename":"DELHI"},
    {"pincode":"110001","Taluk":"New Delhi","Districtname":"New Delhi","statename":"DELHI"},
    {"pincode":"110025","Taluk":"NA","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110062","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110019","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110076","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110003","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110024","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110049","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110044","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110020","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110013","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110017","Taluk":"NA","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110065","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110020","Taluk":"New Delhi","Districtname":"New Delhi","statename":"DELHI"},
    {"pincode":"110048","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110014","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110044","Taluk":"NA","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110025","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110017","Taluk":"New Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110080","Taluk":"South Delhi","Districtname":"South Delhi","statename":"DELHI"},
    {"pincode":"110010","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110038","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110021","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110029","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110047","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110061","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110074","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110030","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110016","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110037","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110068","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110067","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110097","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110023","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110070","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110029","Taluk":"New Delhi","Districtname":"New Delhi","statename":"DELHI"},
    {"pincode":"110066","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110022","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110074","Taluk":"South West Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110023","Taluk":"Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110057","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110075","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110018","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110026","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110071","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110077","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110041","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110043","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110072","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110059","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110058","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110045","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110071","Taluk":"South West Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110015","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110073","Taluk":"NA","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110075","Taluk":"Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110078","Taluk":"NA","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110073","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110064","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110027","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110063","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110087","Taluk":"New Delhi","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110028","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110078","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110064","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110046","Taluk":"New Delhi","Districtname":"South West Delhi","statename":"DELHI"},
    {"pincode":"110077","Taluk":"NA","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"110026","Taluk":"NA","Districtname":"West Delhi","statename":"DELHI"},
    {"pincode":"380006","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380058","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380026","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380007","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380016","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380024","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380009","Taluk":"City Ahmedabad","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382427","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380015","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382430","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382210","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382425","Taluk":"Dasroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380022","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380028","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382433","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382330","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380054","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380004","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382433","Taluk":"Dehgam","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382424","Taluk":"Gandhinagar","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382433","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380019","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380001","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380009","Taluk":"City Taluka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380008","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382435","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382210","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380027","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380061","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380050","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382425","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380021","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380009","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382405","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382435","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382443","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382427","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380051","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380055","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380005","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382430","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382210","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382430","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382350","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382210","Taluk":"Sanand","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382345","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382340","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382405","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382330","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380052","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380005","Taluk":"Ahmedabad","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382425","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380002","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380013","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382330","Taluk":"Ahmedabad","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380014","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382350","Taluk":"Ahmedabad","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382415","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380023","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382480","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380018","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382475","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380058","Taluk":"Sanand","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380063","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382424","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382418","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382440","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"385555","Taluk":"Kankrej","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385535","Taluk":"Disa","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385566","Taluk":"Vav","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385545","Taluk":"Dhanera","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385110","Taluk":"Danta","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385010","Taluk":"Palanpur","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385575","Taluk":"Vav","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385565","Taluk":"Tharad","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385410","Taluk":"Palanpur","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385520","Taluk":"Vadgam","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385320","Taluk":"Bhabhar","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385530","Taluk":"Disa","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385135","Taluk":"Amirgadh","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385210","Taluk":"Vadgam","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385570","Taluk":"Vav","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385505","Taluk":"Danta","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385560","Taluk":"Kankrej","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385001","Taluk":"Palanpur","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385120","Taluk":"Danta","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385510","Taluk":"Palanpur","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385505","Taluk":"Dantiwada","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385421","Taluk":"Vadgam","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385310","Taluk":"Dhanera","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385330","Taluk":"Deodar","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385566","Taluk":"Tharad","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385550","Taluk":"Kankrej","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385410","Taluk":"Vadgam","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385130","Taluk":"Shri Amirgadh","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385515","Taluk":"Palanpur","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385001","Taluk":"Amirgadh","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385135","Taluk":"Shriamirgadh","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385540","Taluk":"Disa","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385535","Taluk":"Deesa","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385310","Taluk":"Tharad","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385135","Taluk":"Shri Amirgadh","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385001","Taluk":"Vadgam","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385120","Taluk":"NA","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385550","Taluk":"Kankraj","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385520","Taluk":"Palanpur","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385210","Taluk":"Vadagam","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385506","Taluk":"Dantiwada","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385520","Taluk":"NA","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"385001","Taluk":"NA","Districtname":"Banaskantha","statename":"GUJARAT"},
    {"pincode":"382016","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382021","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382024","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382028","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382030","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382006","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382007","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382120","Taluk":"Detroj-rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382421","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382640","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382220","Taluk":"Bavla","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382460","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382810","Taluk":"Mansa","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382250","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382042","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382245","Taluk":"Ranpur","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382255","Taluk":"Ranpur","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382721","Taluk":"Kalol","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382225","Taluk":"Dholka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382463","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382845","Taluk":"Mansa","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382735","Taluk":"Kalol","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382305","Taluk":"Dehgam","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382721","Taluk":"Mansa","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382115","Taluk":"Sanand","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382855","Taluk":"Mansa","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382230","Taluk":"Dholka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382150","Taluk":"Viramgam","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382140","Taluk":"Detroj-rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382260","Taluk":"Dholka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382230","Taluk":"Bavla","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382308","Taluk":"Dehgam","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382170","Taluk":"Sanand","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382240","Taluk":"Bavla","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382145","Taluk":"Detroj-rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382145","Taluk":"Detrioj-rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382450","Taluk":"Barwala","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382355","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382455","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380060","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382140","Taluk":"Viramgam","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382428","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382422","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382045","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382465","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382255","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382481","Taluk":"Gandhinagar","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382321","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382170","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382213","Taluk":"Sanand","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382450","Taluk":"Ranpur","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382110","Taluk":"Sanand","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382120","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382321","Taluk":"Dehgam","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382140","Taluk":"Detroj- Rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382729","Taluk":"Kalol","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382250","Taluk":"Barwala","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382145","Taluk":"Detroj Rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382120","Taluk":"Mandal","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382110","Taluk":"Sannad","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382130","Taluk":"Mandal","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382220","Taluk":"Sanand","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382120","Taluk":"Detroj Rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382740","Taluk":"Kalol","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382315","Taluk":"Dehgam","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382240","Taluk":"Dholka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382470","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382320","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382245","Taluk":"Dhandhuka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382120","Taluk":"Detroj- Rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382010","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382265","Taluk":"Dholka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382449","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382355","Taluk":"Gandhi Nagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382481","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380060","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382445","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382455","Taluk":"Barwala","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382470","Taluk":"Dascroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382423","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382449","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382725","Taluk":"Kalol","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382145","Taluk":"Detroj-Rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382260","Taluk":"Bavla","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382115","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382421","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382835","Taluk":"Mansa","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382465","Taluk":"Dholka","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382130","Taluk":"Patdi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382110","Taluk":"Viramgam","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382481","Taluk":"Ahmadabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382140","Taluk":"Detroj-Rampura","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382610","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382315","Taluk":"Dehgam","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382115","Taluk":"Kalol","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382620","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382255","Taluk":"Barwala","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382630","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382463","Taluk":"Barwala","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380059","Taluk":"Daskroi","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"380060","Taluk":"Ahmedabad","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382041","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382470","Taluk":"Ahmedabad City","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382150","Taluk":"Mandal","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382140","Taluk":"Mandal","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"382650","Taluk":"Gandhinagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382010","Taluk":"Gandhi Nagar","Districtname":"Gandhi Nagar","statename":"GUJARAT"},
    {"pincode":"382728","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382715","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382870","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382165","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384170","Taluk":"Unjha","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382710","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384205","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384210","Taluk":"Becharaji","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382732","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384435","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384335","Taluk":"Kheralu","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384330","Taluk":"Satlasana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384410","Taluk":"Becharaji","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384355","Taluk":"Vadnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384325","Taluk":"Kheralu","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384355","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384120","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384001","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384315","Taluk":"Visnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382705","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384330","Taluk":"Satalasana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384421","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384130","Taluk":"Unjha","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384120","Taluk":"Visnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384215","Taluk":"Unjha","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382730","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382865","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384305","Taluk":"Visnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382830","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382705","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384410","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384001","Taluk":"Visnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384360","Taluk":"Satlasana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382860","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384212","Taluk":"Becharaji","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384012","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382815","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382820","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382825","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384170","Taluk":"Vadnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384140","Taluk":"Unjha","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384335","Taluk":"Kadi","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384430","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384325","Taluk":"Vadnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384310","Taluk":"Visnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382850","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382840","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384130","Taluk":"Kheralu","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382860","Taluk":"Satlasana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384002","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384003","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384355","Taluk":"Vadnabar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384130","Taluk":"Becharaji","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382865","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382870","Taluk":"Visnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384340","Taluk":"Vijapur","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384335","Taluk":"Vadnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384340","Taluk":"Satlasana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384345","Taluk":"Vadnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"382728","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384320","Taluk":"Visnagar","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384335","Taluk":"Mahesana","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"384160","Taluk":"Unjha","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"385360","Taluk":"Santalpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384285","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384245","Taluk":"Sami","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384255","Taluk":"Harij","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384265","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"385360","Taluk":"Radhanpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"385340","Taluk":"Radhanpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384240","Taluk":"Sami","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384110","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384272","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384151","Taluk":"Sidhpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384246","Taluk":"Sami","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384240","Taluk":"Harij","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384275","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384220","Taluk":"Chanasma","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384255","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384241","Taluk":"Sami","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"385350","Taluk":"Santalpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384225","Taluk":"Chanasma","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384221","Taluk":"Chanasma","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384246","Taluk":"Santalpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384265","Taluk":"Sidhpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384230","Taluk":"Chanasma","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384290","Taluk":"Sidhpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384265","Taluk":"Harij","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384275","Taluk":"Chanasma","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"385340","Taluk":"Santalpur","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"385340","Taluk":"Sami","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"385360","Taluk":"Sami","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384260","Taluk":"Patan","Districtname":"Mahesana","statename":"GUJARAT"},
    {"pincode":"385360","Taluk":"Santalpurp","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384229","Taluk":"Chanasma","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384260","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384220","Taluk":"Patan","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384245","Taluk":"Harij","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"384230","Taluk":"Harij","Districtname":"Patan","statename":"GUJARAT"},
    {"pincode":"383460","Taluk":"Vijaynagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383240","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383250","Taluk":"Modasa","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383276","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383001","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383270","Taluk":"Khedbrahma","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383422","Taluk":"Khedbrahma","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383260","Taluk":"Dhansura","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383325","Taluk":"Bayad","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383246","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383345","Taluk":"NA","Districtname":"Ahmedabad","statename":"GUJARAT"},
    {"pincode":"383307","Taluk":"Dhansura","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383210","Taluk":"Prantij","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383315","Taluk":"Modasa","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383330","Taluk":"Bayad","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383310","Taluk":"Dhansura","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383215","Taluk":"Prantij","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383305","Taluk":"Talod","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383345","Taluk":"Malpur","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383410","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383251","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383110","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383225","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383235","Taluk":"Vadali","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383335","Taluk":"Bayad","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383434","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383255","Taluk":"Khedbrahma","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383462","Taluk":"Vijaynagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383205","Taluk":"Prantij","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383240","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383350","Taluk":"Meghraj","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383335","Taluk":"Dhansura","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383430","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383316","Taluk":"Modasa","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383245","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383430","Taluk":"Vadali","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383335","Taluk":"Malpur","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383440","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383230","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383421","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383340","Taluk":"Bayad","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383215","Taluk":"Talod","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383006","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383246","Taluk":"Vijaynagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383260","Taluk":"Bayad","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383440","Taluk":"Vadali","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383317","Taluk":"Modasa","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383120","Taluk":"Prantij","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383275","Taluk":"Khedbrahma","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383320","Taluk":"Modasa","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383220","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383355","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383030","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383345","Taluk":"Modasa","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383010","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383235","Taluk":"Khedbrahma","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383434","Taluk":"Vadali","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383255","Taluk":"Vadali","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383120","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383010","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383251","Taluk":"Meghraj","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383450","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383440","Taluk":"Vijaynagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383010","Taluk":"Himatnagar.","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383450","Taluk":"Idar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383276","Taluk":"Modasa","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383250","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383315","Taluk":"Malpur","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383210","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383460","Taluk":"Vijaynagar.","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383225","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383307","Taluk":"Talod","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383276","Taluk":"Bhiloda","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383316","Taluk":"Dhansura","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383330","Taluk":"Dhansura","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383110","Taluk":"Himatnagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"383030","Taluk":"Himatanagar","Districtname":"Sabarkantha","statename":"GUJARAT"},
    {"pincode":"364522","Taluk":"Savar Kundla","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364522","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365430","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365450","Taluk":"NA","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365535","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365630","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365220","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365601","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365660","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364521","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365635","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365450","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365620","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365480","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365610","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365540","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365421","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365560","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365440","Taluk":"Bagasara","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365456","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365460","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365545","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365435","Taluk":"Lathi","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365565","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365650","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"362730","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365645","Taluk":"Dhari","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364530","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365220","Taluk":"Lathi","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364525","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365640","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365630","Taluk":"Dhari","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365410","Taluk":"Babra","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365555","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364515","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365480","Taluk":"Jetpur","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"365435","Taluk":"NA","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365550","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365550","Taluk":"Khambha","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365440","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365410","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365640","Taluk":"Dhari","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365555","Taluk":"Rajula","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365540","Taluk":"Rajula","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365455","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365480","Taluk":"Kunkavav Vadia","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364521","Taluk":"Savarkundla","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365645","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365650","Taluk":"Khambha","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365421","Taluk":"Babra","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365541","Taluk":"Amreli","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365565","Taluk":"Liliya","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365450","Taluk":"Kunkvav-vadia","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365430","Taluk":"Lathi","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364515","Taluk":"Savar Kundla","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365535","Taluk":"Liliya","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365460","Taluk":"Kunkvav-vadia","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365635","Taluk":"Khambha","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365545","Taluk":"Jafrabad","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365560","Taluk":"Rajula","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365560","Taluk":"NA","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365660","Taluk":"Dhari","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364515","Taluk":"Savarkundla","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365440","Taluk":"Visavadar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362730","Taluk":"Jafrabad","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365541","Taluk":"Rajula","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364530","Taluk":"Savarkundla","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"365480","Taluk":"Kunkvav-vadia","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"365456","Taluk":"Bagsra","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364525","Taluk":"Savarkundla","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364530","Taluk":"Savarkundla","Districtname":"Amreli","statename":"GUJARAT"},
    {"pincode":"364002","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364765","Taluk":"Gadhada","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364260","Taluk":"Sihor","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364320","Taluk":"Umrala","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364081","Taluk":"Talaja","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364210","Taluk":"Sihor","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364310","Taluk":"Vallabhipur","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364270","Taluk":"Palitana","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364740","Taluk":"Gadhada Sn","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364760","Taluk":"NA","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364510","Taluk":"NA","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364145","Taluk":"Talaja","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364710","Taluk":"Botad","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364280","Taluk":"Mahuva","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364150","Taluk":"Talaja","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364290","Taluk":"Mahuva","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364313","Taluk":"Vallbhipur","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364720","Taluk":"Botad","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364240","Taluk":"Sihor","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364295","Taluk":"Mahuva","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364505","Taluk":"Gariyadhar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364050","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364120","Taluk":"Gogha","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364005","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364004","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364001","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364006","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364003","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364060","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364110","Taluk":"Gogha","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364230","Taluk":"Sihor","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364750","Taluk":"Gadhada Sn","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364130","Taluk":"Talaja","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364320","Taluk":"Gadhada Sn","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364510","Taluk":"Jesar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364330","Taluk":"Umrala","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364140","Taluk":"Talaja","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364265","Taluk":"Palitana","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364760","Taluk":"Gadhada","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364130","Taluk":"Mahuva","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364730","Taluk":"Gadhada","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364130","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364275","Taluk":"Gariyadhar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364110","Taluk":"NA","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364730","Taluk":"Gadhada Sn","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364250","Taluk":"NA","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364070","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364730","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364740","Taluk":"Gariyadhar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364120","Taluk":"Talaja","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364135","Taluk":"Talaja","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364250","Taluk":"Sihor","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364150","Taluk":"Gogha","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364313","Taluk":"Vallabhipur","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364050","Taluk":"Gogha","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364760","Taluk":"Botad","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364230","Taluk":"Palitana","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364230","Taluk":"Umrala","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364750","Taluk":"Gadhada","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364265","Taluk":"Gariyadhar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364505","Taluk":"NA","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364710","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364260","Taluk":"Bhavnagar","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364310","Taluk":"Vallabhiopur","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364270","Taluk":"NA","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"364313","Taluk":"Vallbhiopur","Districtname":"Bhavnagar","statename":"GUJARAT"},
    {"pincode":"360055","Taluk":"Jasdan","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360360","Taluk":"Jetpur","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"364485","Taluk":"Jetpur","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360311","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360490","Taluk":"Upleta","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360070","Taluk":"Kotda","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360030","Taluk":"Kotda Sangani","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360450","Taluk":"Upleta","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360040","Taluk":"Jasdan","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360480","Taluk":"Jamjodhpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360050","Taluk":"Jasdan","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360330","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360452","Taluk":"Jamkandorna","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360421","Taluk":"Dhoraji","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360410","Taluk":"Dhoraji","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360405","Taluk":"Jamkandorna","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360030","Taluk":"Kotda","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"364490","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360370","Taluk":"Jetpur","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360035","Taluk":"Lodhika","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360430","Taluk":"Dhoraji","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360410","Taluk":"Upleta","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360070","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"364465","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"364485","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360460","Taluk":"Upleta","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"364470","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"364490","Taluk":"Jasdan","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360410","Taluk":"Jamkandorna","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360440","Taluk":"Upleta","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360370","Taluk":"Dhoraji","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360320","Taluk":"Gondal","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360311","Taluk":"NA","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360480","Taluk":"Upleta","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360380","Taluk":"Jetpur","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360440","Taluk":"Jamkandorna","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360470","Taluk":"Upleta","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360490","Taluk":"Kutiyana","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360070","Taluk":"Kotda Sangani","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360360","Taluk":"Dhoraji","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360440","Taluk":"Dhoraji","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360450","Taluk":"Jamkandorna","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360375","Taluk":"Jetpur","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360055","Taluk":"Sayla","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"360490","Taluk":"Jamjodhpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360330","Taluk":"Kotda","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360490","Taluk":"Manavadar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"361003","Taluk":"Jodiya","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361110","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"363655","Taluk":"Jodia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360515","Taluk":"Bhanvad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361305","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361006","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361162","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361280","Taluk":"Lalpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361345","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361170","Taluk":"Lalpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361160","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361325","Taluk":"Jamkalyanpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361305","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361250","Taluk":"Jodia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361230","Taluk":"Jodia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361240","Taluk":"Jodiya","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360530","Taluk":"Jamjodhpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360540","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361013","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361315","Taluk":"Kalyanpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361120","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361335","Taluk":"Okhamandal","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361002","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361009","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361310","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361305","Taluk":"Kahbhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361011","Taluk":"Jodia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361330","Taluk":"Okhamandal","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361306","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360510","Taluk":"Bhanvad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361010","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361220","Taluk":"Dhrol","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361345","Taluk":"Okhamandal","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360520","Taluk":"Jamjodhpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360510","Taluk":"Bhanvad","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"361320","Taluk":"Jamkalyanpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361012","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361130","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361210","Taluk":"Dhrol","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361005","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361140","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361335","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361335","Taluk":"Jamjodhpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361001","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361007","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361240","Taluk":"Jodia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360530","Taluk":"Jamjodhpur","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"361320","Taluk":"Kalyanpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360531","Taluk":"Jamjodhpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361305","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361013","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"360540","Taluk":"Okhamandal","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361006","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361220","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361008","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361007","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361162","Taluk":"Khambhalia","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361350","Taluk":"Okhamandal","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361140","Taluk":"Jamjodhpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361325","Taluk":"Kalyanpur","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361142","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361230","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361310","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361141","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361347","Taluk":"Okhamandal","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361004","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"361150","Taluk":"Jamnagar","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"362720","Taluk":"Kodinar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362255","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362255","Taluk":"Patan-Veraval","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362222","Taluk":"Keshod","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362229","Taluk":"Keshod","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362268","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362150","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362245","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362222","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362220","Taluk":"Keshod","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362140","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362260","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362565","Taluk":"Una","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362560","Taluk":"Una","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362715","Taluk":"Kodinar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362263","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362215","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362510","Taluk":"Una","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362250","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362725","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362263","Taluk":"Junagadh","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362610","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362030","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362140","Taluk":"Talala","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362110","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362120","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362020","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362020","Taluk":"Bhesan","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362310","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362570","Taluk":"Diu","Districtname":"Diu","statename":"DAMAN & DIU"},
    {"pincode":"362130","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362710","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362720","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362135","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362315","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362250","Taluk":"Malia","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362266","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362266","Taluk":"Patan-Veraval","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362275","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362002","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362205","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362215","Taluk":"Vanthali(s)","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362520","Taluk":"Diu","Districtname":"Diu","statename":"DAMAN & DIU"},
    {"pincode":"362220","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362037","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362530","Taluk":"Una","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362037","Taluk":"Junagadh","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362227","Taluk":"Keshod","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362550","Taluk":"Una","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362540","Taluk":"Diu U.T.","Districtname":"Diu","statename":"DAMAN & DIU"},
    {"pincode":"362011","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362269","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362015","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362001","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362004","Taluk":"Junagadh","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362001","Taluk":"Junagadh","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362510","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362725","Taluk":"Kodinar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362275","Taluk":"Sutrapada","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362215","Taluk":"Keshod","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362011","Taluk":"Junagadh","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362245","Taluk":"Malia","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362260","Taluk":"Mendarda","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362227","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362565","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362550","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362560","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362268","Taluk":"Patan-Veraval","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362530","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362135","Taluk":"Talala","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362245","Taluk":"Malia Hatina","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362276","Taluk":"Sutrapada","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362150","Taluk":"Talala","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362015","Taluk":"Junagadh","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362265","Taluk":"Patan-Veraval","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362269","Taluk":"Patan-Veraval","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362570","Taluk":"NA","Districtname":"Diu","statename":"DAMAN & DIU"},
    {"pincode":"370421","Taluk":"Mundra","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370155","Taluk":"Rahpar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370135","Taluk":"Bhachau","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370205","Taluk":"Gandhidham","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370655","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370605","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370511","Taluk":"Kachchh","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370150","Taluk":"Bhachau","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370140","Taluk":"Bhachau","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370610","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370165","Taluk":"Rahpar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370610","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370675","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370110","Taluk":"Anjar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370665","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370645","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370485","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370105","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370602","Taluk":"Lakhpat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370475","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370145","Taluk":"Rahpar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370650","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370427","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370650","Taluk":"Bhachau","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370620","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370020","Taluk":"Bhachau","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370660","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370511","Taluk":"Lakhpat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370415","Taluk":"Mundra","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370455","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370405","Taluk":"Mundra","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370465","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370625","Taluk":"Lakhpat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370410","Taluk":"Mundra","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370001","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370130","Taluk":"Anjar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370625","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370030","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370240","Taluk":"Anjar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370160","Taluk":"Rahpar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370510","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370030","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370450","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370015","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370020","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370155","Taluk":"Rapar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370001","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370435","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370020","Taluk":"Anjar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370430","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370485","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370445","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370630","Taluk":"Kachch","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370435","Taluk":"Mundra","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370165","Taluk":"Rapar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370040","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370627","Taluk":"Lakhpat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370165","Taluk":"Bhachau","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370630","Taluk":"Lakhpat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370490","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370445","Taluk":"Mandavi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370240","Taluk":"Gandhidham","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370627","Taluk":"Lakhapat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370201","Taluk":"Gandhidham","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370601","Taluk":"Lakhpat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370640","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370670","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370230","Taluk":"Gandhidham","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370210","Taluk":"Gandhidham","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370425","Taluk":"Mundra","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370450","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370620","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370145","Taluk":"Bhachau","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370460","Taluk":"Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370205","Taluk":"Anjar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370615","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370605","Taluk":"Lakhapat","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370030","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370115","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370105","Taluk":"Anjar","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370630","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370435","Taluk":"K. Mandvi","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370511","Taluk":"NA","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370203","Taluk":"Gandhidham","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370445","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370445","Taluk":"Nakhatrana","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370511","Taluk":"Abdasa","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"370130","Taluk":"Bhuj","Districtname":"Kachchh","statename":"GUJARAT"},
    {"pincode":"360545","Taluk":"NA","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360590","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362240","Taluk":"Mangrol","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"360570","Taluk":"Kutiyana","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360560","Taluk":"NA","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362625","Taluk":"Manavadar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362620","Taluk":"Kutiyana","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360570","Taluk":"Ranavav","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362620","Taluk":"Mangrol","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"360579","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362230","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362625","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362620","Taluk":"NA","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"360576","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362620","Taluk":"Manavadar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362640","Taluk":"Manavadar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"360550","Taluk":"Ranavav","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360579","Taluk":"NA","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360545","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362630","Taluk":"Manavadar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362225","Taluk":"Mangrol","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362650","Taluk":"Kutiyana","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360578","Taluk":"NA","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362650","Taluk":"Manavadar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"360560","Taluk":"Ranavav","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360590","Taluk":"Bhanvad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"362230","Taluk":"Porbandar","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362620","Taluk":"Manavadar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362650","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362226","Taluk":"Mangrol","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"360590","Taluk":"Porbaandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360575","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360577","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360570","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"360550","Taluk":"Porbandar","Districtname":"Porbandar","statename":"GUJARAT"},
    {"pincode":"362230","Taluk":"Mangrol","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362235","Taluk":"Mangrol","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"362630","Taluk":"Vanthli","Districtname":"Junagadh","statename":"GUJARAT"},
    {"pincode":"360110","Taluk":"Paddhari","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363621","Taluk":"Wankaner","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360003","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363641","Taluk":"Morbi","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360060","Taluk":"Jasdan","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363650","Taluk":"Tankara","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360025","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363642","Taluk":"Morbi","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360025","Taluk":"Jasdan","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363660","Taluk":"Dahisara","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363660","Taluk":"Maliya","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363660","Taluk":"Malia(m)","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360021","Taluk":"NA","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360022","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360021","Taluk":"Lodhika","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360020","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363630","Taluk":"Morvi","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363641","Taluk":"Tankara","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363642","Taluk":"Morvi","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360110","Taluk":"Dhrol","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363670","Taluk":"Maliya Miyana","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360025","Taluk":"Kotda Saangani","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360060","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360005","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363630","Taluk":"Maliya Miyana","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360002","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360022","Taluk":"NA","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360023","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363641","Taluk":"Taankaraa","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360006","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360110","Taluk":"Kalavad","Districtname":"Jamnagar","statename":"GUJARAT"},
    {"pincode":"363670","Taluk":"Maliya","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360004","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363641","Taluk":"Morvi","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360024","Taluk":"Lodhika","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363660","Taluk":"Morvi","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360007","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360001","Taluk":"Rajkot","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360110","Taluk":"Lodhika","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360025","Taluk":"Sardhar","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360024","Taluk":"Kotda Sangani","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363670","Taluk":"Morbi","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"363630","Taluk":"Maliya  Miyana","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"360024","Taluk":"Kotda Sanghani","Districtname":"Rajkot","statename":"GUJARAT"},
    {"pincode":"382775","Taluk":"Lakhtar","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382780","Taluk":"Dasada","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363330","Taluk":"Halvad","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382745","Taluk":"Dasda","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363520","Taluk":"Chotila","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363425","Taluk":"Limbdi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363110","Taluk":"Lakhtar","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363421","Taluk":"Limbdi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382745","Taluk":"Dasada","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363040","Taluk":"Wadhwancity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363030","Taluk":"Wadhwancity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382765","Taluk":"Dasdaa","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363310","Taluk":"Dhrangadhra","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363510","Taluk":"Muli","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363320","Taluk":"Dhrangadhra","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363410","Taluk":"Limbdi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363423","Taluk":"Limbdi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363415","Taluk":"Limbdi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382750","Taluk":"Dasada","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363115","Taluk":"Lakhtar","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382765","Taluk":"Dasada","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363530","Taluk":"Chotila","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363425","Taluk":"Limbdiq","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363440","Taluk":"Sayla","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382755","Taluk":"Dasada","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363351","Taluk":"Halvad","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363310","Taluk":"Dhrangsadhra","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363430","Taluk":"Sayla","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363040","Taluk":"Wadhwan","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363435","Taluk":"Wadhwancity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363427","Taluk":"Limbdi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363115","Taluk":"Lkhtar","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363020","Taluk":"Wadhwan","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"382760","Taluk":"Dasada","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363020","Taluk":"Wadhwancity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363410","Taluk":"Limndi","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363415","Taluk":"NA","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363310","Taluk":"Dshrangadhra","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363421","Taluk":"Dhandhuka","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363520","Taluk":"NA","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363410","Taluk":"NA","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363001","Taluk":"Wadhwanicity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363002","Taluk":"Wadhwancity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363001","Taluk":"Wadhwancity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"363035","Taluk":"Wadhwancity","Districtname":"Surendra Nagar","statename":"GUJARAT"},
    {"pincode":"388305","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388230","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388130","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388210","Taluk":"Umreth","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388310","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388360","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388225","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388543","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388265","Taluk":"Balasinor","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388307","Taluk":"Anklav","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388250","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388001","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388110","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388510","Taluk":"Anklav","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388205","Taluk":"Umreth","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388210","Taluk":"NA","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388315","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388520","Taluk":"Anklav","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388265","Taluk":"Birpur","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388220","Taluk":"Umreth","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388320","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388530","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388205","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388255","Taluk":"Balasinor","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388260","Taluk":"Birpur","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388220","Taluk":"Birpur","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388520","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388540","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388225","Taluk":"Kheda","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388260","Taluk":"Virpur","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388560","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388365","Taluk":"Umreth","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388130","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388345","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388370","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388325","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388545","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388255","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388350","Taluk":"Anklav","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388330","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388560","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388365","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388530","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388215","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388365","Taluk":"Unreth","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388235","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388335","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388245","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388235","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388340","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388130","Taluk":"Perlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388350","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388355","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388210","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388220","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388255","Taluk":"Birpur","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388306","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388130","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388215","Taluk":"Umreth","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388205","Taluk":"Unreth","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388120","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388220","Taluk":"Thsra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388121","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388239","Taluk":"Thasra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388235","Taluk":"Balasinor","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"394150","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394620","Taluk":"Bardoli","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394651","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394710","Taluk":"Ahwa","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394355","Taluk":"Bardoli","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394246","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394365","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394350","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394163","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394641","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394352","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394633","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394250","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394380","Taluk":"Nizar","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"396510","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394630","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394635","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394325","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394170","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394651","Taluk":"Vyara","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394320","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394375","Taluk":"Uchchhal","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394305","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394630","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394690","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394340","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394317","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394655","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394335","Taluk":"Bardoli","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394310","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394635","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394715","Taluk":"Ahwa","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394601","Taluk":"Bardoli","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394730","Taluk":"Dang","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394240","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394630","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394340","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394360","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394650","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394246","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394680","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394710","Taluk":"Dangs","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394640","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394370","Taluk":"Nizar","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394690","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394248","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394690","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394140","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394670","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394630","Taluk":"Valod","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394660","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394710","Taluk":"Dang","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394350","Taluk":"Bardoli","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394730","Taluk":"The Dangs","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394650","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394380","Taluk":"Nijhar","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394160","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394330","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394620","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394248","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394180","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394635","Taluk":"Vyara","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394633","Taluk":"Vyara","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394710","Taluk":"The Dangs","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394245","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394155","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394360","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394651","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394720","Taluk":"Dang","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394370","Taluk":"Nijhar","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394310","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394315","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394326","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394716","Taluk":"Dang","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394327","Taluk":"Palsana","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394635","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394360","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394335","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394185","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394350","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394340","Taluk":"Bardoli","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394655","Taluk":"Vyara","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394340","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394641","Taluk":"Vyara","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394190","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394180","Taluk":"Makrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394715","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394380","Taluk":"Nijhar","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394325","Taluk":"Kamrej","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394715","Taluk":"The Dangs","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394716","Taluk":"Songadh","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394670","Taluk":"Uchchhal","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394305","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394345","Taluk":"Bardoli","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394651","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394320","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394620","Taluk":"Mahuva","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394715","Taluk":"Ahwa","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394716","Taluk":"Dang","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394365","Taluk":"Fort Songadh","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394720","Taluk":"Ahwa","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394716","Taluk":"Ahwa","Districtname":"The Dangs","statename":"GUJARAT"},
    {"pincode":"394375","Taluk":"Valod","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394375","Taluk":"Uchchhal","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394680","Taluk":"Songadh","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394641","Taluk":"Valod","Districtname":"Tapi","statename":"GUJARAT"},
    {"pincode":"394155","Taluk":"Mandvi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394730","Taluk":"Ahwa","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"394730","Taluk":"Dang","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"392110","Taluk":"NA","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392240","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392110","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392130","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Dediapada","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"394115","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392150","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393025","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393041","Taluk":"Dediapada","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393030","Taluk":"Hansot","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393110","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393050","Taluk":"Sagbara","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392012","Taluk":"Hansot","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393140","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393010","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392030","Taluk":"Bharuch","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392150","Taluk":"Jambusar","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"393002","Taluk":"Ankleshwar","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393001","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393001","Taluk":"Ankleshwar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392025","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Valia","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392012","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"394810","Taluk":"Hansot","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393120","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393151","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Valia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393017","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Valia","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"392001","Taluk":"Bahruch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392001","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"394810","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393120","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393025","Taluk":"Sagbara","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393040","Taluk":"Dediapada","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393145","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392012","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392155","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393105","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393125","Taluk":"Valia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393135","Taluk":"Valia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392210","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392012","Taluk":"Bharuch","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393155","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393050","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"392035","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392140","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393041","Taluk":"NA","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392150","Taluk":"Jambusar","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392210","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393041","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393001","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392230","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392020","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392020","Taluk":"Dediapada","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393040","Taluk":"Dediapada","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392170","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392040","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393020","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393041","Taluk":"Dediapada","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"391810","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392165","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393155","Taluk":"Nandod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393115","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392220","Taluk":"Bharuch","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392011","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393115","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393150","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392135","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"391810","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392020","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392130","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392220","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392170","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392015","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Hansot","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393135","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392025","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393151","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392210","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392150","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392160","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392180","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392025","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393151","Taluk":"Nadod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392020","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393025","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393050","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392035","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393025","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393105","Taluk":"Rajpipala","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392020","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392140","Taluk":"Vagra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393150","Taluk":"Nadod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392130","Taluk":"Jhagadia","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392165","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393155","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392130","Taluk":"Vagra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392110","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393130","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392030","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393150","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392160","Taluk":"Bharuch","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393125","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393110","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393040","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392140","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392040","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392015","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393020","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392165","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393105","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393010","Taluk":"Ankleshwar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"394810","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392230","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392025","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393040","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"394116","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"394115","Taluk":"Ankleshwar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"394115","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393151","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"391810","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392140","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393125","Taluk":"Valia","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"393041","Taluk":"NA","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393050","Taluk":"Sagbara","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393020","Taluk":"Ankleshwar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392012","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392180","Taluk":"Jambsuar","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393010","Taluk":"Ankleshwar","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393120","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392140","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392210","Taluk":"Bharuch","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"393025","Taluk":"Sagbara","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393030","Taluk":"Anklesvar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392015","Taluk":"Bharuch","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392170","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393145","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392150","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"392215","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392155","Taluk":"Vagra","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393125","Taluk":"Amod","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"393001","Taluk":"Nandod","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"393120","Taluk":"Bharuch","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"392012","Taluk":"Jambusar","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"387335","Taluk":"Kathlal","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387650","Taluk":"Kapadwanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387610","Taluk":"Kathlal","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387355","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387430","Taluk":"Mahemdavad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387305","Taluk":"Mahudha","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387510","Taluk":"Matar","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387115","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387620","Taluk":"Kapadwanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387620","Taluk":"Kapadvanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388570","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388160","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387130","Taluk":"Mehmedabad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387630","Taluk":"Kathlal","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387620","Taluk":"NA","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387365","Taluk":"Kapadwanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387570","Taluk":"Matar","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388450","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387320","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387530","Taluk":"Matar","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387001","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387610","Taluk":"Kapadwanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388550","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387635","Taluk":"Kathlal","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387240","Taluk":"Sojitra","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388180","Taluk":"Matar","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388580","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387345","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388410","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388580","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387130","Taluk":"Mahemdabad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387650","Taluk":"Kapadvanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387380","Taluk":"Sojitra","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387240","Taluk":"Matar","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387430","Taluk":"Kathlal","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387370","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388430","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388640","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387120","Taluk":"Kheda","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387002","Taluk":"Naidiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387350","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388140","Taluk":"Borsad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388140","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387310","Taluk":"Anand","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388620","Taluk":"Tarapur","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388180","Taluk":"Tarapur","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387315","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387560","Taluk":"Kheda","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388421","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387640","Taluk":"Kathlal","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387635","Taluk":"NA","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387640","Taluk":"Kapadwanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388620","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387330","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387210","Taluk":"Sojitra","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387335","Taluk":"Mahudha","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387630","Taluk":"Kapadwanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387710","Taluk":"Petlad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387210","Taluk":"Matar","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387380","Taluk":"Petlad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388430","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387540","Taluk":"Kheda","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387550","Taluk":"Kheda","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388610","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388625","Taluk":"Tarapur","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387110","Taluk":"Mahemdavad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388440","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388625","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388480","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387130","Taluk":"Mahemdavad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387570","Taluk":"Kheda","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387360","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387330","Taluk":"Mahudha","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388460","Taluk":"Sojitra","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387335","Taluk":"Mahemdavad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387110","Taluk":"Mehmedabad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388590","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388170","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387365","Taluk":"Kathlal","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387120","Taluk":"Mahemdavad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387325","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388630","Taluk":"Khambhat","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387710","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388550","Taluk":"Borsad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387530","Taluk":"Tarapur","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387520","Taluk":"Matar","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387411","Taluk":"Kheda","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387430","Taluk":"Mahudha","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387380","Taluk":"Matar","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387340","Taluk":"Mahudha","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387220","Taluk":"Sojitra","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388150","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387002","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387630","Taluk":"Mahemdabad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388180","Taluk":"Tarapura=","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388170","Taluk":"Tarapur","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387345","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388465","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387230","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387610","Taluk":"Kapadvanj","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387710","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387120","Taluk":"Mahemdabad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387375","Taluk":"Petlad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387335","Taluk":"Mehmedabad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388150","Taluk":"Sojitra","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388480","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"388180","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387430","Taluk":"Mehmedabad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388470","Taluk":"Petlad","Districtname":"Anand","statename":"GUJARAT"},
    {"pincode":"387375","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"388440","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387115","Taluk":"Umreth","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387380","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"387430","Taluk":"Nadiad","Districtname":"Kheda","statename":"GUJARAT"},
    {"pincode":"396403","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396406","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396040","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396445","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396060","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396360","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396409","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396521","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396310","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396580","Taluk":"Vansda","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396540","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396469","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396409","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396325","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396475","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396415","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396433","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396439","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396590","Taluk":"Vansda","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396427","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396436","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396110","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396321","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396445","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396560","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396412","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396472","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396570","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396530","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396436","Taluk":"Jalalporre","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396460","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396466","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396472","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396380","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396321","Taluk":"Vansda","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396430","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396450","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396350","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396418","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396421","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396424","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396370","Taluk":"Gandevi","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396440","Taluk":"Jalalpore","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396060","Taluk":"Vansda","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396430","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396463","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396475","Taluk":"Navsari","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396325","Taluk":"Chikhli","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"389130","Taluk":"Devgadbaria","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389152","Taluk":"Garbada","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389341","Taluk":"Kalol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389190","Taluk":"Fatepura","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389140","Taluk":"Limkheda","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389175","Taluk":"Limkheda","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"388270","Taluk":"Lunawada","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389115","Taluk":"Morva Hadaf","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389382","Taluk":"Dhanpur","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389154","Taluk":"Dahod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389310","Taluk":"Kalol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389180","Taluk":"Jahlod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389380","Taluk":"Devgadh Baria","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389001","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389240","Taluk":"Kadana","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389175","Taluk":"Jhalod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389210","Taluk":"Shehera","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389110","Taluk":"Santrampur","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389380","Taluk":"Devgadh Baria","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389350","Taluk":"Halol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389232","Taluk":"Khanpur","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389260","Taluk":"Santrampur","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389001","Taluk":"Shehera","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389170","Taluk":"Jhalod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389120","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389365","Taluk":"Ghoghmba","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389230","Taluk":"Lunawada","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389140","Taluk":"Dohad","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389370","Taluk":"Holol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389172","Taluk":"Santrampur","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389152","Taluk":"Dahod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389180","Taluk":"Lunawada","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389320","Taluk":"Kalol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389340","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389360","Taluk":"Halol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389160","Taluk":"Dahod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389160","Taluk":"Dohad","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389155","Taluk":"Garbada","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389172","Taluk":"Fatepura","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389341","Taluk":"Ghoghmba","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389180","Taluk":"Jhalod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389380","Taluk":"Ghoghamba","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389330","Taluk":"Kalol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389140","Taluk":"Dhanpur","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389180","Taluk":"Dahod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389360","Taluk":"Ghoghamba","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389130","Taluk":"Limkheda","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389152","Taluk":"Limkheda","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389110","Taluk":"Santrampur","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389380","Taluk":"Dhanpur","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389130","Taluk":"Devgadh Baria","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389250","Taluk":"Kadana","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389151","Taluk":"Dohad","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389151","Taluk":"Dahod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389154","Taluk":"Dohad","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389170","Taluk":"Fatepura","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389120","Taluk":"Shehera","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"388713","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389220","Taluk":"Shehera","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389146","Taluk":"Limkheda","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389390","Taluk":"Jambughoda","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389340","Taluk":"Kalol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389002","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389380","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389190","Taluk":"Santrampur","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389220","Taluk":"Lunawada","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"388710","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389265","Taluk":"Santrampur","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389180","Taluk":"Limkheda","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389152","Taluk":"Dhanpur","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389235","Taluk":"Lunawada","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389365","Taluk":"Ghoghamba","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389370","Taluk":"Halol","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389154","Taluk":"Panchamahals","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389210","Taluk":"Lunawada","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389265","Taluk":"Kadana","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389235","Taluk":"NA","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389265","Taluk":"Santrampura","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389120","Taluk":"Morwa (Hadaf)","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389110","Taluk":"Morwa (Hadaf)","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389120","Taluk":"Morva Hadaf","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389190","Taluk":"Santrampur","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389232","Taluk":"Godhra","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389160","Taluk":"Jhalod","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"389172","Taluk":"Santrampur","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389341","Taluk":"Ghoghamba","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"389180","Taluk":"Likheda","Districtname":"Dahod","statename":"GUJARAT"},
    {"pincode":"388270","Taluk":"NA","Districtname":"Panch Mahals","statename":"GUJARAT"},
    {"pincode":"388710","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"395008","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395007","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395009","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394516","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395003","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395017","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394430","Taluk":"Vankal","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395005","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394107","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395009","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394110","Taluk":"Olpad","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394410","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394405","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394540","Taluk":"Olpad","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395001","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394130","Taluk":"Sayan","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394440","Taluk":"Zankhvav","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394210","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395003","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394530","Taluk":"Olpad","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394125","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395007","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394510","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394517","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394210","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395023","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394421","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394550","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394445","Taluk":"Umarpada","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395010","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394230","Taluk":"Sachin","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394210","Taluk":"Udhna","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394440","Taluk":"Umarpada","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395004","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394110","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394550","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395012","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394440","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394270","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394421","Taluk":"Olpad","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395002","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394235","Taluk":"Lajpore","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394230","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394405","Taluk":"Velachha","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395004","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394445","Taluk":"Zankhvav","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394120","Taluk":"Olpad","Districtname":"Bharuch","statename":"GUJARAT"},
    {"pincode":"394111","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394107","Taluk":"Chorayasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394520","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394120","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394120","Taluk":"Olpad","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395006","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394515","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395010","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394235","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394517","Taluk":"NA","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394101","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395003","Taluk":"Umarpada","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394270","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395001","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394518","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394510","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394221","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394130","Taluk":"Mangrol","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395012","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395007","Taluk":"Nizar","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395011","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394230","Taluk":"Surat","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394520","Taluk":"Variav","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395013","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394421","Taluk":"Umarpada","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394210","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"394105","Taluk":"Chorasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395006","Taluk":"Surat City","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"395007","Taluk":"Choryasi","Districtname":"Surat","statename":"GUJARAT"},
    {"pincode":"391250","Taluk":"Sinor","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391121","Taluk":"Tilakwada","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390019","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391145","Taluk":"Sankheda","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391150","Taluk":"Naswadi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391135","Taluk":"Sankheda","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391121","Taluk":"Tilakwada","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"390022","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391175","Taluk":"Kawant","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391165","Taluk":"Chhota Udepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391115","Taluk":"Sinor","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391120","Taluk":"Tilakwada","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"391110","Taluk":"Sinor","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391107","Taluk":"Dabhoi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391160","Taluk":"Pavijetpur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391168","Taluk":"Ch.udepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391170","Taluk":"Kawant","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391140","Taluk":"Pavijetpur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391250","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391125","Taluk":"Sankheda","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390001","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391105","Taluk":"Sinor","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391168","Taluk":"Kawant","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391152","Taluk":"Naswadi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391130","Taluk":"Sankheda","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391110","Taluk":"Dabhoi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391135","Taluk":"Pavijetpur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391165","Taluk":"Chhota-udepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391135","Taluk":"Bodeli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391105","Taluk":"Dabhoi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391165","Taluk":"Chhotaudepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390009","Taluk":"NA","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390004","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390022","Taluk":"NA","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390014","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391168","Taluk":"Chhotaudepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391120","Taluk":"NA","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"390006","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391152","Taluk":"Nasvadi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391150","Taluk":"Sankheda","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391155","Taluk":"Pavijetpur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391175","Taluk":"Chhotaudepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390018","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391170","Taluk":"Chhotaudepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391140","Taluk":"Sankheda","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390010","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390013","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390011","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390009","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391160","Taluk":"Pavijetpura","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391107","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391121","Taluk":"Naswadi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391110","Taluk":"Dabhoi","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"390025","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391155","Taluk":"NA","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391156","Taluk":"Chhotaudepur","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391120","Taluk":"Dabhoi","Districtname":"Narmada","statename":"GUJARAT"},
    {"pincode":"390017","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391421","Taluk":"Padra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391510","Taluk":"Vaghodia","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391740","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390020","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390007","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391760","Taluk":"Vaghodia","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391445","Taluk":"Padra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391101","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391761","Taluk":"Dabhoi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391761","Taluk":"Sankheda","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391240","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391330","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391775","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391761","Taluk":"Vaghodia","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391745","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391244","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390012","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391310","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391243","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391780","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391410","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391510","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391520","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391440","Taluk":"Padra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390003","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391530","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390024","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391210","Taluk":"Dabhoi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391430","Taluk":"Padra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391450","Taluk":"Padra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391774","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391346","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391210","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391340","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390008","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391760","Taluk":"Vaghoida","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391750","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390002","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391240","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391770","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390016","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391243","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391320","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391220","Taluk":"Dabhoi","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"392310","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391220","Taluk":"Karjan","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391350","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391220","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391345","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391745","Taluk":"Savli","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391240","Taluk":"Padra","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390023","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"390021","Taluk":"Vadodara","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"391761","Taluk":"Vaghodida","Districtname":"Vadodara","statename":"GUJARAT"},
    {"pincode":"396105","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396065","Taluk":"Kaprada","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396051","Taluk":"Dharampur","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396145","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396191","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396230","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396126","Taluk":"Kaprada","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396125","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396230","Taluk":"Dadra & Nagar Haveli","Districtname":"Dadra & Nagar Haveli","statename":"DADRA & NAGAR HAVELI"},
    {"pincode":"396155","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396055","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396235","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396050","Taluk":"Dharampur","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396007","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396045","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396235","Taluk":"Dadra & Nagar Haveli","Districtname":"Dadra & Nagar Haveli","statename":"DADRA & NAGAR HAVELI"},
    {"pincode":"396020","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396185","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396051","Taluk":"Vansda","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396030","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396385","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396165","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396210","Taluk":"Daman","Districtname":"Daman","statename":"DAMAN & DIU"},
    {"pincode":"396195","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396240","Taluk":"Dadra & Nagar Haveli","Districtname":"Dadra & Nagar Haveli","statename":"DADRA & NAGAR HAVELI"},
    {"pincode":"396126","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396215","Taluk":"Daman","Districtname":"Daman","statename":"DAMAN & DIU"},
    {"pincode":"396193","Taluk":"Dadra & Nagar Haveli","Districtname":"Dadra & Nagar Haveli","statename":"DADRA & NAGAR HAVELI"},
    {"pincode":"396170","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396126","Taluk":"Dharampur","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396001","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396375","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396130","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396220","Taluk":"Daman","Districtname":"Daman","statename":"DAMAN & DIU"},
    {"pincode":"396193","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396375","Taluk":"Dungri","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396045","Taluk":"NA","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396170","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396030","Taluk":"Hanuman Bhagda","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396235","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396065","Taluk":"Dharampur","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396105","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396140","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396155","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396193","Taluk":"Pardi","Districtname":"Dadra & Nagar Haveli","statename":"DADRA & NAGAR HAVELI"},
    {"pincode":"396230","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396051","Taluk":"Bansda","Districtname":"Navsari","statename":"GUJARAT"},
    {"pincode":"396120","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396115","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396007","Taluk":"Dharampur","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396193","Taluk":"Dadra & Nagar Haveli","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396120","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396130","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396240","Taluk":"Dharampur","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396135","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396191","Taluk":"Kaprada","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396140","Taluk":"Umargarm","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396150","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396150","Taluk":"Umargam","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396130","Taluk":"NA","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396135","Taluk":"NA","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396180","Taluk":"Pardi","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396171","Taluk":"Umbergaon","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396002","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"396035","Taluk":"Valsad","Districtname":"Valsad","statename":"GUJARAT"},
    {"pincode":"133001","Taluk":"NA","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134003","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133205","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133205","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134203","Taluk":"Panchkula","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135133","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133202","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135101","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135002","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133206","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133301","Taluk":"Kalka","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133005","Taluk":"NA","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135021","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134007","Taluk":"NA","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135103","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135003","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134007","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134202","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133004","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134203","Taluk":"Naraingarh","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133201","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134118","Taluk":"Ambala City Ho","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"135102","Taluk":"Jagadhri","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134102","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134203","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133103","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134118","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134201","Taluk":"Naraingarh","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135001","Taluk":"Jagadhri","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134205","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"135106","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133101","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134118","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133206","Taluk":"Chhachhrauli","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133005","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135101","Taluk":"Chhachhrauli","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134104","Taluk":"Kalka","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134107","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"135102","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135103","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133103","Taluk":"Jagadhri","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133102","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133001","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135003","Taluk":"Chhachhrauli","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133201","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134109","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134201","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133203","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133202","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133102","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134108","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134202","Taluk":"Panchkula","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134204","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133203","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135001","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134101","Taluk":"Kalka","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134204","Taluk":"Panchkula","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133204","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134103","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133006","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134113","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"135003","Taluk":"Jagadhri","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135003","Taluk":"NA","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135002","Taluk":"Jagadhri","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134118","Taluk":"Ambala","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134005","Taluk":"Ambala city","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134201","Taluk":"Ambala City","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135004","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135106","Taluk":"Ambala","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133302","Taluk":"Kalka","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133104","Taluk":"Ambala","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"133101","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135003","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135021","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135103","Taluk":"Chhachhrauli","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134114","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133207","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135004","Taluk":"Jagadhri","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133104","Taluk":"Barara","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134007","Taluk":"Ambala City","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134203","Taluk":"Ambala City","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"134112","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134109","Taluk":"Ambala City","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134102","Taluk":"Kalka","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"135133","Taluk":"Jagadhri","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134118","Taluk":"Ambala City","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"133204","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133204","Taluk":"Chhachhrauli","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"133206","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"134117","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134116","Taluk":"Panchkula","Districtname":"Panchkula","statename":"HARYANA"},
    {"pincode":"134202","Taluk":"Ambala City","Districtname":"Ambala","statename":"HARYANA"},
    {"pincode":"135004","Taluk":"Yamuna Nagar","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"135001","Taluk":"NA","Districtname":"Yamuna Nagar","statename":"HARYANA"},
    {"pincode":"127307","Taluk":"Achina","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127308","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127030","Taluk":"Biwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127026","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127025","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127310","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127031","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127308","Taluk":"Badhara","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127310","Taluk":"Charkhi Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127111","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127032","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127021","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127201","Taluk":"Loharu","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127032","Taluk":"Bawani Khera","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127045","Taluk":"Siwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127025","Taluk":"Baund","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127028","Taluk":"Siwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127307","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127041","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127030","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127043","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127035","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127046","Taluk":"Siwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127026","Taluk":"Charkhi Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127029","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127022","Taluk":"Dadari","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127027","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127040","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127306","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127306","Taluk":"Charkhi Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127022","Taluk":"Ch-dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127309","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127022","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127309","Taluk":"Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127035","Taluk":"Bawani Khera","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127114","Taluk":"Bhiwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127309","Taluk":"Charkhi Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127042","Taluk":"Charkhi Dadri","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127041","Taluk":"Mundhal","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127111","Taluk":"Biwani","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127040","Taluk":"Tosham","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"127032","Taluk":"Bawanikhera","Districtname":"Bhiwani","statename":"HARYANA"},
    {"pincode":"121102","Taluk":"Palwal","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121106","Taluk":"Hodal","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121101","Taluk":"Ballabgarh","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121003","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121004","Taluk":"Ballabgarh","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121105","Taluk":"Palwal","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121105","Taluk":"Hodal","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121107","Taluk":"Hodal","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121002","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121103","Taluk":"Hathin","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121007","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121001","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121005","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121008","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121006","Taluk":"Ballabgarh","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121010","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121013","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121012","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"121009","Taluk":"Faridabad","Districtname":"Faridabad","statename":"HARYANA"},
    {"pincode":"122104","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Ahrod","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122005","Taluk":"Air Force","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Akberpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122107","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Akoda","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Arjun Nagar","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Bawal","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123035","Taluk":"Rewari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Azamabad Mukhota","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"B B Ashram","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Mandi Ateli","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Narnaul","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122508","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123412","Taluk":"Rewari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122101","Taluk":"Badshahpur","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Narnaul","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122105","Taluk":"Tauru","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Baghot","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Baghthala","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123028","Taluk":"Bairawas","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Rewari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123103","Taluk":"Rewari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Banipur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Barda","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Barkoda","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122006","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Basai","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Basai Road","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123101","Taluk":"Basbatori","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Basduda","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Bashirpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122503","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Bawana","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Bawania","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Bawwa","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Bayal","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Behali","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Beri","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Bewal","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122108","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Bhakli","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122505","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Bharaf","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Bhatsana","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122103","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Bhojawas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122102","Taluk":"Bhondsi","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122413","Taluk":"Bhorakalan","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123101","Taluk":"Bhotwas Ahir","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Mahendergarh","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Bhaungerka","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Bhushan Kalan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Bikaner","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122413","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Bisowa","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Bithwana","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Boria Kamalpur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Buddin","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Budhpur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Budhwa","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Buroli","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Bhrthala","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"C R Mgrh","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122017","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122002","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122502","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Chillro","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Chita Dungra","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Chitrauli","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Dahina","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Dakhora","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Dalanwas","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122102","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123028","Taluk":"Daroli Ahir","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Daroli","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Dehlawas Gulabpur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Dewas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Dhadhot","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Dhana","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Dhanaunda","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Dhanota","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123412","Taluk":"Dharan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Dharson","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Dharuhera","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123103","Taluk":"Dhawana","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Dholera","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Dhor Kalan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Digrota","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122008","Taluk":"Dlf Ph-ii","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122010","Taluk":"Dlf Ph-iii","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122002","Taluk":"Dlf Qe","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Dochana","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Dongra Ahir","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Narnaul","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Dulana","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Dulat Jat","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122105","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Dulot Ahir","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122016","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Dunderwas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122506","Taluk":"Farrukh Nagar","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122101","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122104","Taluk":"Ferozepur Jhirka","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122009","Taluk":"Galleria Dlf-iv","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Ganiar","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Garhi","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Garhi Bolni","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122505","Taluk":"Garhi Harsaru","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Rewari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Gokalgarh","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Gokalpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Golwa","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Gothra","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Gothera","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Gugodh","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Gujar Majra","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Gujarwas","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123035","Taluk":"Guraora","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Sadar Bazar","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Gurgaon Kty.","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Sector-17","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122003","Taluk":"Sector -45","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122011","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122018","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Gurha","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123301","Taluk":"Guriani","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122003","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122504","Taluk":"Haillymandi","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Hamidpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Hudina","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122052","Taluk":"Manesar","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122414","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123110","Taluk":"Dharuhera","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122016","Taluk":"Industrial Complex Dundahera","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122007","Taluk":"Industrial Estate","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Jaisinghpur Khera","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123301","Taluk":"Jakhala","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Jarwa","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122504","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Jatusana Rs","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Jhabuwa","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Jhagroli","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Jhal","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Jharoda","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Jhoiri","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Jhook","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Juddi","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Kakrola","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Kamania","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Kanina Mandi","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Kanina","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Gurgaon","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123035","Taluk":"Kannaura","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Kanti","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Kanti Kheri","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Kanwali","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Kanwi","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123035","Taluk":"Karawara","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Karnawas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Karnawas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Karoli","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Karota","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122051","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123103","Taluk":"Khaleta","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122502","Taluk":"Khalilpur","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Khandora","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122004","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Gurgaon","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Kharkhara","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Khatod","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122506","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Kheri","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122103","Taluk":"Sohna","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123103","Taluk":"Khol","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123101","Taluk":"Khori","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Khudana","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Kohrar","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Kolana","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Koriawas","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Kosli R S","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Kosli","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123028","Taluk":"Kothal Khurd","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Kotia","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Krishan Nagar","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Kultazpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Kund","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Kurawata","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Lawan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Lilodh","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Lisan","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Lookhi","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Lula Ahir","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Madhana Kalan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Madhogarh","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Maheshwari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Majra Gurdas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Majra Sheoraj  majra Sheoraj","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123101","Taluk":"Mamaria Ahir","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Mandi","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Mandola","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123103","Taluk":"Mandola","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Masani","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Mastapur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123103","Taluk":"Mayan","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Mini Sectt.","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Mohanpur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Mohindergarh","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Motla Kalan","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Mundi","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Musepur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122108","Taluk":"Nagina","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Nahar","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Naichana","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Nand Rampurbas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Nangal","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Nangal Dargu","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Nangal Jamalpur","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Nagal Katha","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Nangal Pathani","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123028","Taluk":"Nangal Sirohi","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Nangal Teju","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Nangalmala","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Nangli Godha","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122414","Taluk":"Nanukalan","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Civil Line","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122004","Taluk":"Narsinghpur","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Nautana","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Nawan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Naigawan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Neerpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123303","Taluk":"Nehrugarh","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"New Anaz Mandi Rewari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"New Colony","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Niamatpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123028","Taluk":"Nihalawas","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Nimbi","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Nimoth","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123001","Taluk":"Nizampur R.s.","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122051","Taluk":"Nsg Camp Manesar","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122107","Taluk":"Nuh","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122015","Taluk":"Palam Road","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"122017","Taluk":"Palam Vihar","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123035","Taluk":"Palhawas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123102","Taluk":"Pali","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Pali","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123029","Taluk":"Palri Panihara","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122503","Taluk":"Pataudi","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Pathera","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Patherwa","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123101","Taluk":"Rewari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Pota","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Pranpura","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122508","Taluk":"Punhana","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123101","Taluk":"Punsika","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122006","Taluk":"Railway Road","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123412","Taluk":"Rajgarh","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123106","Taluk":"Raliawas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Rambas","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Rampuri","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Rasoolpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Rattan Kalan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123301","Taluk":"Rattanthal","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Rohrai","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Sadat Nagar","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Saharanwas","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Sangwari","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Sareli","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122015","Taluk":"NA","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Satnali","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Sehlong","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123028","Taluk":"Sehma","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Shivaji Nagar","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Shayam Nagar","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Shyampura","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Siana","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Siha","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123027","Taluk":"Sihore","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Sirohi Behali","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Sohna Adda","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123401","Taluk":"Sulkha","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123034","Taluk":"Sundrah","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Surethi","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123024","Taluk":"Sureti Kalan","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123501","Taluk":"Suthana","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123021","Taluk":"Tajpur","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123412","Taluk":"Tankri","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123023","Taluk":"Thanwas","Districtname":"Mahendragarh","statename":"HARYANA"},
    {"pincode":"123101","Taluk":"Tint","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"123302","Taluk":"Tumna","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"122001","Taluk":"Urban Estate","Districtname":"Gurgaon","statename":"HARYANA"},
    {"pincode":"123411","Taluk":"Zainabad","Districtname":"Rewari","statename":"HARYANA"},
    {"pincode":"125033","Taluk":"Hansi","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125104","Taluk":"Mandi Dabwali","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125047","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125051","Taluk":"Rattia","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125050","Taluk":"Fatehabad","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125055","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125106","Taluk":"Tohana","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125078","Taluk":"Baragudha","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125102","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125004","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125101","Taluk":"NA","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125052","Taluk":"Hisar","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125001","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125007","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125011","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125042","Taluk":"Hansi","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125121","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125048","Taluk":"Fatehabad","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125005","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125054","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125075","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125060","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125111","Taluk":"Fatehabad","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125112","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125053","Taluk":"Fatehabad","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125103","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125078","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125044","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125039","Taluk":"Hansi","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125038","Taluk":"Hansi","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125053","Taluk":"Hisar","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125111","Taluk":"Tohana","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125113","Taluk":"Hisar","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125113","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125077","Taluk":"Odhan","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125110","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125201","Taluk":"Kalanwali","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125120","Taluk":"Tohana","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125101","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125052","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125104","Taluk":"Dabwali","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125006","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125049","Taluk":"Hansi","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125121","Taluk":"NA","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125058","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125076","Taluk":"Rania","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125120","Taluk":"Tohana","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125133","Taluk":"Tohana","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125056","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125201","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125037","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125039","Taluk":"NA","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125047","Taluk":"NA","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125051","Taluk":"Fatehabad","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125104","Taluk":"M.dabwali","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125051","Taluk":"Rattia","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125033","Taluk":"Hisar","Districtname":"Hisar","statename":"HARYANA"},
    {"pincode":"125077","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125201","Taluk":"Kaanwali","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125102","Taluk":"Hisar","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125076","Taluk":"Sirsa","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"125051","Taluk":"Tohana","Districtname":"Fatehabad","statename":"HARYANA"},
    {"pincode":"125201","Taluk":"Kalan Wali","Districtname":"Sirsa","statename":"HARYANA"},
    {"pincode":"132024","Taluk":"Nissing","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132113","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132107","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"126102","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132157","Taluk":"Nigdhu","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126111","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"126116","Taluk":"Narwana","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132040","Taluk":"Assandh","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126112","Taluk":"Safidon","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132103","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132039","Taluk":"Assandh","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132117","Taluk":"Nilokheri","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126113","Taluk":"Safidon","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132105","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132122","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132104","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132140","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"126152","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"126125","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132037","Taluk":"Karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132024","Taluk":"Karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126110","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132036","Taluk":"Jundla","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132023","Taluk":"S.s. Kunjpura","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132037","Taluk":"Madhuban","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126115","Taluk":"Narwana","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"126114","Taluk":"Safidon","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132114","Taluk":"Gharunda","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132101","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132116","Taluk":"Nilokheri","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132102","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132054","Taluk":"Indri","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132041","Taluk":"Indri","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132106","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"126101","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132001","Taluk":"Karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126116","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132108","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132145","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132041","Taluk":"Karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132023","Taluk":"Ss Kunjpura","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132046","Taluk":"Assandh","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132115","Taluk":"Panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132036","Taluk":"Indri","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132116","Taluk":"Karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132114","Taluk":"Garunda","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132114","Taluk":"Guarunda","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126113","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"126115","Taluk":"Narwnaa","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132022","Taluk":"Kunjpura","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132037","Taluk":"Nilokheri","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126101","Taluk":"Jind  jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132113","Taluk":"Panipat  panipat","Districtname":"Panipat","statename":"HARYANA"},
    {"pincode":"132157","Taluk":"Nilokheri","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126112","Taluk":"Jind","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132041","Taluk":"Indri]","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132114","Taluk":"Gharudna","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"126113","Taluk":"Narwana","Districtname":"Jind","statename":"HARYANA"},
    {"pincode":"132116","Taluk":"NA","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132037","Taluk":"Karnal'karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132023","Taluk":"Karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"132157","Taluk":"Karnal","Districtname":"Karnal","statename":"HARYANA"},
    {"pincode":"136035","Taluk":"Guhla","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136021","Taluk":"Pundri","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136130","Taluk":"Shahbad","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136136","Taluk":"Shahbad","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136038","Taluk":"Thanesar","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136034","Taluk":"Guhla","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136156","Taluk":"Babain","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136117","Taluk":"Kalayat","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136119","Taluk":"Thanesar","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136043","Taluk":"Pundri","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136132","Taluk":"Ladwa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136128","Taluk":"Pehowa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136027","Taluk":"Kaithal","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136156","Taluk":"Thanesar","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136026","Taluk":"Pundri","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136030","Taluk":"Pehowa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136044","Taluk":"Rajaund","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136131","Taluk":"Thanesar","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136118","Taluk":"Thanesar","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136135","Taluk":"Shahbad\\","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136129","Taluk":"Pehowa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136020","Taluk":"Pundri","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136135","Taluk":"Shahbad","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136042","Taluk":"Pundri","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136119","Taluk":"Thanesa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136130","Taluk":"Pehowa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136033","Taluk":"Kaithal","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136117","Taluk":"Kaithal","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136027","Taluk":"Kaithal  kaithal","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136118","Taluk":"Tthanesar","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136135","Taluk":"shahabad","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136044","Taluk":"Kaithal","Districtname":"Kaithal","statename":"HARYANA"},
    {"pincode":"136128","Taluk":"Pehwoa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136135","Taluk":"Shahabad","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"136136","Taluk":"Pehowa","Districtname":"Kurukshetra","statename":"HARYANA"},
    {"pincode":"124106","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124108","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124111","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124146","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124411","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124103","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124406","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124505","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124021","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124501","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124105","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124201","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124507","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124508","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124022","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124142","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124514","Taluk":"Mehem","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124104","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124010","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124401","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124107","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124022","Taluk":"Mehem","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124303","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124112","Taluk":"Mehem","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124109","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124102","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124001","Taluk":"Rohak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124504","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124412","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124514","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124001","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124202","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124501","Taluk":"NA","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124514","Taluk":"NA","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124404","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124104","Taluk":"NA","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124142","Taluk":"NA","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124146","Taluk":"Jhanswakalan","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124106","Taluk":"Jharli","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124103","Taluk":"Jondhi","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124113","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124021","Taluk":"Rothak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124109","Taluk":"Jhajar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124111","Taluk":"Mehem","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124103","Taluk":"Jahjjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124103","Taluk":"Jhajajr","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124146","Taluk":"Jhamri","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124105","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124508","Taluk":"Bahaudrgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124506","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124404","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124513","Taluk":"Mehem","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124106","Taluk":"NA","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124141","Taluk":"Rohtak","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124112","Taluk":"NA","Districtname":"Rohtak","statename":"HARYANA"},
    {"pincode":"124109","Taluk":"Jhajjr","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124501","Taluk":"Bahadurgarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124508","Taluk":"Bahadurarh","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124506","Taluk":"Jhajjar","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124109","Taluk":"NA","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"124103","Taluk":"Jhajjr","Districtname":"Jhajjar","statename":"HARYANA"},
    {"pincode":"131101","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131301","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131103","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131409","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131027","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131023","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131001","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131403","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131021","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131102","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131101","Taluk":"Ganaur","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131304","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131304","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131021","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131402","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131001","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131039","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131022","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131302","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131403","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131024","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131301","Taluk":"Gohana","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131306","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131039","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131408","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131305","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131028","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131027","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131028","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131029","Taluk":"NA","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131302","Taluk":"Gohana","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131305","Taluk":"Gohana","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131402","Taluk":"Kharkhoda","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"131029","Taluk":"Sonipat","Districtname":"Sonipat","statename":"HARYANA"},
    {"pincode":"176316","Taluk":"Churah","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176312","Taluk":"Salooni","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176313","Taluk":"Bhatiyat","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176314","Taluk":"Chamba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176324","Taluk":"Bharmour","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176312","Taluk":"Slooni","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176303","Taluk":"Bhatiyat","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176325","Taluk":"Bhallai","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176309","Taluk":"Holi","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176311","Taluk":"Chamba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176301","Taluk":"Bhatiyat","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176321","Taluk":"Salooni","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176302","Taluk":"Bhatiyat","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176306","Taluk":"Bhatiyat","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176318","Taluk":"Chamba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176321","Taluk":"Churah","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176308","Taluk":"Bhalei","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176320","Taluk":"Salooni","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176315","Taluk":"Bharmour","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176207","Taluk":"Sihunta","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176323","Taluk":"Pangi","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176310","Taluk":"Chamba(T)","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176319","Taluk":"Chamba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176305","Taluk":"Dalhousie(T)","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176304","Taluk":"Dalhousie(T)","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176304","Taluk":"Bhatiyat","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176306","Taluk":"Chamba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176309","Taluk":"Bharmour","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176324","Taluk":"Chamba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176301","Taluk":"Chamba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176318","Taluk":"Chamba(T)","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176311","Taluk":"Chmaba","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176325","Taluk":"Dal Housia","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176311","Taluk":"Bharmour","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176317","Taluk":"Salooni","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176324","Taluk":"Bahrmour","Districtname":"Chamba","statename":"HIMACHAL PRADESH"},
    {"pincode":"176036","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177034","Taluk":"Rakkar","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176031","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176027","Taluk":"Jawali(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176501","Taluk":"Quasba Kotla","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176054","Taluk":"Baroh(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176031","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176209","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177103","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176208","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176047","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176037","Taluk":"Baroh","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176029","Taluk":"Baroh","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176028","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177114","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176029","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177104","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176047","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176051","Taluk":"Jawali(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176502","Taluk":"Jaswan(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176027","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176055","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176054","Taluk":"Baroh","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176025","Taluk":"Fatehpur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176022","Taluk":"Nurpur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177117","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176208","Taluk":"Shahpur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176214","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177112","Taluk":"Jaswan(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177103","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177105","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177111","Taluk":"Quasba Kotla","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176023","Taluk":"Jawali","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176029","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176021","Taluk":"Jawali(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176036","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177043","Taluk":"Rakkar(S.T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176026","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176028","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176033","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176001","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177113","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177043","Taluk":"Rakkar","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176023","Taluk":"Harchakian(S.T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176022","Taluk":"Jawali","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176056","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177106","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176026","Taluk":"Harchakian(S.T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176051","Taluk":"Jawali","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176027","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176029","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176038","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177101","Taluk":"Dehra Gopipur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176056","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176038","Taluk":"Baroh","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176021","Taluk":"Jawali","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176029","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176030","Taluk":"Khundian(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176053","Taluk":"Fatehpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176022","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176209","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177108","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176029","Taluk":"Baroh(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176029","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176066","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176502","Taluk":"Qasba Kotla","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176023","Taluk":"Jawali(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176030","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176501","Taluk":"Jaswan(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177114","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176001","Taluk":"Kangara","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177106","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176001","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177111","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176051","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177106","Taluk":"Qusba Kotla","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176022","Taluk":"Fatehpur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177113","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176501","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176038","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177111","Taluk":"Jaswan(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176031","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176027","Taluk":"Jawali","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177114","Taluk":"NA","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176054","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176025","Taluk":"Fatehpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176030","Taluk":"Khundian","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177106","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177107","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176031","Taluk":"Khundian","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176037","Taluk":"Baroh(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176022","Taluk":"Jawali(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177108","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176032","Taluk":"Khuandian","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177108","Taluk":"Rakkar(S.T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176026","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176209","Taluk":"Shahpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177108","Taluk":"Rakkar","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176033","Taluk":"Jawali(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177112","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177034","Taluk":"Dera Gopipur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177111","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176208","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176051","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176214","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176037","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177112","Taluk":"Qusba Kotla","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176032","Taluk":"Khundian","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176053","Taluk":"Fatehpur(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176037","Taluk":"Kangra(T)","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176055","Taluk":"Khundian","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177105","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176058","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176082","Taluk":"Jaiinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176103","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176217","Taluk":"Sahpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176102","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176059","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176094","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176083","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176204","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176062","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176202","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176107","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176081","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176201","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176225","Taluk":"Jawali","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176125","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176205","Taluk":"Kotla","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176086","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176073","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176098","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176082","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176403","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176064","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176084","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176061","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176097","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176101","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176200","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176219","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176206","Taluk":"Shahpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176401","Taluk":"Indora","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176210","Taluk":"Shahpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176076","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176088","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176402","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176087","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176071","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176077","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176092","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176088","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176217","Taluk":"Shahpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176128","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176203","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176052","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176216","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176403","Taluk":"Indora So","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176060","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176057","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176128","Taluk":"Baijanath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176215","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176096","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176075","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176065","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176204","Taluk":"Indora","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176085","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176217","Taluk":"NA","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176211","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176115","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176091","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176210","Taluk":"Harcahkian","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176095","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176213","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176098","Taluk":"Jaisisnghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176402","Taluk":"Indora","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176052","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176218","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176086","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176093","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176205","Taluk":"Shapur/jawali","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176225","Taluk":"Nurpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176096","Taluk":"Jasinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176089","Taluk":"Jaisinghpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176063","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176403","Taluk":"Indora","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176103","Taluk":"Palampur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176052","Taluk":"Kangra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176058","Taluk":"Indora","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176225","Taluk":"Shahpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176217","Taluk":"Dharamsala","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176096","Taluk":"Jainshgpur","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"176041","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177501","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174021","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177020","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177024","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174024","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177601","Taluk":"Bhoranj(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174029","Taluk":"Jhanduta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176048","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177040","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177601","Taluk":"Bhuranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177029","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177021","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177026","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177118","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174309","Taluk":"Barsar","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177028","Taluk":"Sp Tira","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177048","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174031","Taluk":"Jhandutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176040","Taluk":"Bijhari","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174003","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174305","Taluk":"Barsar","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174029","Taluk":"Jhamdutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174001","Taluk":"Bilaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177022","Taluk":"Sp Tira","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174304","Taluk":"Barsar","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177044","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176049","Taluk":"Bijhari","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174029","Taluk":"Jhandutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177023","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177001","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174013","Taluk":"Bilaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174027","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176042","Taluk":"Ghumarwin","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177301","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174002","Taluk":"Bilaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174312","Taluk":"Barsar","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177042","Taluk":"Nadaun(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174012","Taluk":"Bilaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177041","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174015","Taluk":"Naina Devi","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177033","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176041","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174029","Taluk":"Jhandutha","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174201","Taluk":"Naina Devi","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176108","Taluk":"Tira Sujanpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174035","Taluk":"Jhandutha","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177022","Taluk":"Tira Sujanpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174004","Taluk":"Bilaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174028","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177028","Taluk":"S P Tira","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177038","Taluk":"Nadaun(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176045","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176041","Taluk":"Barsar","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177024","Taluk":"Bhoranj(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174026","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177119","Taluk":"Barsar(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174001","Taluk":"Bilaspur Sadar(T)","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176110","Taluk":"Hamirpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177048","Taluk":"Nadaun(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177119","Taluk":"Hamirpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177007","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176039","Taluk":"Bijhari","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176111","Taluk":"Sp Tira","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177033","Taluk":"Rakkar","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"174505","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176110","Taluk":"Sp Tira","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174021","Taluk":"Ghuamrwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174023","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174032","Taluk":"Bilaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174034","Taluk":"Jhandutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174030","Taluk":"Jhandutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174017","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177401","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176045","Taluk":"Bhuranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177025","Taluk":"Bhorang","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177025","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177001","Taluk":"Hamirpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177042","Taluk":"Hamirpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174405","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174034","Taluk":"Jhandutha","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177042","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177041","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177119","Taluk":"Mehre","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177005","Taluk":"Hamirpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174013","Taluk":"Bilaspur Sadar","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174030","Taluk":"Jhansdutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174011","Taluk":"Naina Devi","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174310","Taluk":"Naina Devi","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176109","Taluk":"Sujanpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174312","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174017","Taluk":"Jhandutha","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174031","Taluk":"Jhanduta(T)","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176043","Taluk":"Ghumarwin","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174033","Taluk":"Bilaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177028","Taluk":"Sujanpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174035","Taluk":"Jhandutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176110","Taluk":"Nadaun(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177027","Taluk":"Hamirpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174311","Taluk":"Bijhari","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176044","Taluk":"Ghumarwin","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176108","Taluk":"Sujanpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177006","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176109","Taluk":"Sp Tira","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177038","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174304","Taluk":"Barsar(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177020","Taluk":"Hamirpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176043","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174002","Taluk":"Naina Devi","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176039","Taluk":"Mehre","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176042","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174029","Taluk":"Jhandutt","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174017","Taluk":"Jhandutta","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176044","Taluk":"Bhoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174036","Taluk":"NA","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176111","Taluk":"Sujanpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177045","Taluk":"Nadaun","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174005","Taluk":"Blaspur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174001","Taluk":"Bilaspur Sadar","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174032","Taluk":"Bilapur","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176110","Taluk":"Sujanpur Tira","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174310","Taluk":"Ghumarwin","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"174030","Taluk":"Jhandutha","Districtname":"Bilaspur (HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177025","Taluk":"Bhaoranj","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"176110","Taluk":"Tira Sujanpur(T)","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"177022","Taluk":"Sujanpur","Districtname":"Hamirpur(HP)","statename":"HIMACHAL PRADESH"},
    {"pincode":"175032","Taluk":"Jogindernagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175101","Taluk":"Kullu","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175001","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175009","Taluk":"Karsog","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175123","Taluk":"Banjar(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175104","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175121","Taluk":"Mandi","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175138","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175034","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175028","Taluk":"Chachiot","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175035","Taluk":"Thunag(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175027","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175035","Taluk":"Thunag","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175103","Taluk":"Manali(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175011","Taluk":"Karsog","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175033","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175123","Taluk":"Banjar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175134","Taluk":"Sainj(S.T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175125","Taluk":"Kullu","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175038","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175012","Taluk":"Joginder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175015","Taluk":"Jogindarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175016","Taluk":"Lad Bharol(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175106","Taluk":"Thunag","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175052","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175008","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175121","Taluk":"Aut(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175124","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175128","Taluk":"Naggar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175029","Taluk":"Chachiot","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175129","Taluk":"Naggar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175013","Taluk":"Baijnath","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"175024","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175013","Taluk":"Padhar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175040","Taluk":"Dharmpur(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175030","Taluk":"Sundarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175105","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175021","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175036","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175103","Taluk":"Naggar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175052","Taluk":"Kotli(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175042","Taluk":"Sarkaghat(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175029","Taluk":"Chachyot(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175005","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175017","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175009","Taluk":"Nihri(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175006","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175049","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175134","Taluk":"Banjar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175102","Taluk":"Kullu","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175004","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175021","Taluk":"Sadar Mandi","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175015","Taluk":"Jogindernagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175003","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175101","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175002","Taluk":"Sundarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175125","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175102","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175047","Taluk":"Thunag(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175001","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175050","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175105","Taluk":"Kullu","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175131","Taluk":"Naggar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175027","Taluk":"Sundernagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175031","Taluk":"Nihri(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175028","Taluk":"Chachyot(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175106","Taluk":"Banjar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175018","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175047","Taluk":"Thunag","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175048","Taluk":"Thunag","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175131","Taluk":"Manali(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175142","Taluk":"Udaipur","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"176090","Taluk":"Sandhol(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175037","Taluk":"Sarkaghat(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175031","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175027","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175010","Taluk":"Karsog","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175025","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175030","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175106","Taluk":"Bali Chowki(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175040","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175009","Taluk":"Karsog(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175027","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175001","Taluk":"Padhar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175014","Taluk":"Jogindernagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175141","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175123","Taluk":"Bali Chowki(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175006","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175132","Taluk":"Lahaul","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"175023","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175026","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175140","Taluk":"Lahaul","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"175007","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175011","Taluk":"Karsog(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175007","Taluk":"Sarkaghat(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175136","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175039","Taluk":"Chachiot","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175143","Taluk":"Naggar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175139","Taluk":"Lahaul","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"175033","Taluk":"Baldwara(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175042","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175048","Taluk":"Thunag(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175015","Taluk":"Padhar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175125","Taluk":"Bali Chowki(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175133","Taluk":"Lahaul","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"175015","Taluk":"Joginder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175002","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175023","Taluk":"Baldwara(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175050","Taluk":"Sandhol(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175019","Taluk":"Sunder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175018","Taluk":"Sundernagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175101","Taluk":"Manali(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175136","Taluk":"Naggar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175016","Taluk":"Joginder Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175012","Taluk":"Jogindernagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175046","Taluk":"Karsog","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175003","Taluk":"Kotli(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175143","Taluk":"Manali(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175126","Taluk":"Banjar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175024","Taluk":"Sarkaghat(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"176090","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175121","Taluk":"Sadar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175122","Taluk":"Sainj(S.T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175124","Taluk":"Aut(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175046","Taluk":"Karsog(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175036","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175032","Taluk":"Lad Bharol(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175031","Taluk":"Sundarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175130","Taluk":"Naggar","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175008","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175001","Taluk":"Jogindernagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175129","Taluk":"Manali(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175106","Taluk":"Banjar(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175023","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175049","Taluk":"Sarkaghat(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175032","Taluk":"Jogindarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175046","Taluk":"Nihri(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175019","Taluk":"Sundarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175128","Taluk":"Manali(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175038","Taluk":"Nihri(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175130","Taluk":"Kullu(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"175134","Taluk":"Banjar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175132","Taluk":"Lahul     (T)","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"175011","Taluk":"Thunag(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175023","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175124","Taluk":"Chachyot(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175005","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175051","Taluk":"Sarkaghat","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175141","Taluk":"Aut(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175124","Taluk":"Mandi(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175012","Taluk":"Jogindr Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175140","Taluk":"Lahul     (T)","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"175121","Taluk":"Bali Chowki(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175017","Taluk":"Sundarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175018","Taluk":"Sundarnagar(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175001","Taluk":"Kotli(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175026","Taluk":"Sarkaghat(T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175034","Taluk":"Baldwara(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175004","Taluk":"Baldwara(S.T)","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"175018","Taluk":"Sundr Nagar","Districtname":"Mandi","statename":"HIMACHAL PRADESH"},
    {"pincode":"172116","Taluk":"Poo(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172026","Taluk":"Anni","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172002","Taluk":"Nirmand","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172109","Taluk":"Moorang","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172022","Taluk":"Rampur Bushahr","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172001","Taluk":"Nankhari","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172027","Taluk":"Kumarsain","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172107","Taluk":"Kalpa(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172115","Taluk":"Nichar(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172031","Taluk":"Kumharsain(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172106","Taluk":"Sangla(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172002","Taluk":"Nermand(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172118","Taluk":"Nichar(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172022","Taluk":"Rampur(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172024","Taluk":"Kumharsain(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172030","Taluk":"Kumarsain","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172034","Taluk":"Rampur(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172105","Taluk":"Sangla(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172104","Taluk":"Nichar(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172111","Taluk":"Poo(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172109","Taluk":"Morang(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172101","Taluk":"Nichar(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172024","Taluk":"Kumarsain","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172032","Taluk":"Ani(S.T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172025","Taluk":"Ani(S.T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172021","Taluk":"Nankhari","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172001","Taluk":"Rampur Bushahr","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172028","Taluk":"Nankhari(S.T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172114","Taluk":"Spiti","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"172026","Taluk":"Ani(S.T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172113","Taluk":"Spiti","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"172102","Taluk":"Rampur(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172108","Taluk":"Kalpa(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172033","Taluk":"Nermand(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172032","Taluk":"Anni","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172101","Taluk":"Rampur Bushahr","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172201","Taluk":"Rampur Bushahr","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172023","Taluk":"Nirmand","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172110","Taluk":"Morang(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172117","Taluk":"Spiti","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"172112","Taluk":"Hangrang","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172114","Taluk":"Spiti       (T)","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"172023","Taluk":"Nermand(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172101","Taluk":"Rampur(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172032","Taluk":"Nermand(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172029","Taluk":"Kumharsain(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172201","Taluk":"Rampur(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172106","Taluk":"Sangla","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172110","Taluk":"Poo(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172001","Taluk":"Rampur(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172105","Taluk":"Nichar(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172102","Taluk":"Rampur Bushahr","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172026","Taluk":"Nermand(T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172029","Taluk":"Kumarsain","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172201","Taluk":"Nirmand","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172113","Taluk":"Spiti       (T)","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"172001","Taluk":"Ani(S.T)","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172117","Taluk":"Spiti       (T)","Districtname":"Lahul & Spiti","statename":"HIMACHAL PRADESH"},
    {"pincode":"172111","Taluk":"Hangrang","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172115","Taluk":"Nichar","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172103","Taluk":"Nichar(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172033","Taluk":"Nirmand","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172111","Taluk":"Pooh","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172001","Taluk":"Nirmand","Districtname":"Kullu","statename":"HIMACHAL PRADESH"},
    {"pincode":"172104","Taluk":"Nichar","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172116","Taluk":"Pooh","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172116","Taluk":"Morang(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"172030","Taluk":"Kumharsain(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172027","Taluk":"Kumharsain(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172001","Taluk":"Nankhari(S.T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172001","Taluk":"Nankahri","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"172030","Taluk":"Nichar(T)","Districtname":"Kinnaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"171003","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171004","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171219","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171206","Taluk":"Jubbal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171223","Taluk":"Rohru","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171207","Taluk":"Rohru","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171011","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171202","Taluk":"Kotkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171225","Taluk":"Kothkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171220","Taluk":"Theog","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171226","Taluk":"Theog","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171007","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171211","Taluk":"Chopal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171201","Taluk":"Theog","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171018","Taluk":"Suni","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171013","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171213","Taluk":"Kumarsain","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171217","Taluk":"Chopal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171212","Taluk":"Theog","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171210","Taluk":"Chopal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171003","Taluk":"Shimla Rural(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171226","Taluk":"Rajgarh","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"171216","Taluk":"Jubbal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171003","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171005","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171001","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171301","Taluk":"Suni","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171005","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171103","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171211","Taluk":"Chopl","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171103","Taluk":"Suni","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171209","Taluk":"Theog","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171220","Taluk":"Kothkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171012","Taluk":"Shimla R","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171208","Taluk":"Chirgaon","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171205","Taluk":"Jubbal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171204","Taluk":"Kotkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171214","Taluk":"Rohru","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171007","Taluk":"Suni","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171012","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171203","Taluk":"Rohru","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171019","Taluk":"Suni","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171221","Taluk":"Rohru","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171002","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171213","Taluk":"Rampur","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171214","Taluk":"Chirgaon","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171218","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171008","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171225","Taluk":"Kotkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171220","Taluk":"Kotkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171006","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171213","Taluk":"Theog","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171009","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171222","Taluk":"Theog","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171221","Taluk":"Dodra Kawar","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171225","Taluk":"Rohru","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171204","Taluk":"Kothkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171212","Taluk":"Kumarsain","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171202","Taluk":"Kothkhai","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171012","Taluk":"Shimlar","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171215","Taluk":"Jubbal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171210","Taluk":"Choupal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171009","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171207","Taluk":"Shimla","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171006","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171224","Taluk":"Rohru","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171301","Taluk":"Sunni","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171010","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171203","Taluk":"Jubbal","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"171011","Taluk":"Shimla Urban(T)","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"173023","Taluk":"Sangrah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173234","Taluk":"Kandaghat","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173208","Taluk":"Kandaghat","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173206","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173205","Taluk":"Nalagarh","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173218","Taluk":"Ramshahar","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173233","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173032","Taluk":"Sangarh","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173024","Taluk":"Pachhad","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173001","Taluk":"Pachhad","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"174102","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173208","Taluk":"Arki","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173029","Taluk":"Shillai","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173221","Taluk":"Arki","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173027","Taluk":"Shillai","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173025","Taluk":"Paonta","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173001","Taluk":"Nahan","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173211","Taluk":"Solan","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"174103","Taluk":"Kasauli","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173101","Taluk":"Pachhad","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"174101","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173213","Taluk":"Solan","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173027","Taluk":"Shilai","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"171102","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173207","Taluk":"Kandaghat","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173032","Taluk":"Sangrah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173235","Taluk":"Arki","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173022","Taluk":"Paonta","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173022","Taluk":"Sangrah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173205","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173032","Taluk":"Sangah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173229","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173101","Taluk":"Rajgarh","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173104","Taluk":"Sangrah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173030","Taluk":"Nahan","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173207","Taluk":"Solan","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173217","Taluk":"Kandaghat","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173001","Taluk":"Sangrah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173032","Taluk":"Sangarha","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173029","Taluk":"Paonta","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173101","Taluk":"Rajgarah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173001","Taluk":"Nahan(T)","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173210","Taluk":"Solan","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173212","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173217","Taluk":"Junga","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173104","Taluk":"Sangarah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173209","Taluk":"Kasauli","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173207","Taluk":"Arki","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173031","Taluk":"Poanta Sahib","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173223","Taluk":"Rajghar","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173217","Taluk":"Junga","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"173201","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173001","Taluk":"Paonta","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173029","Taluk":"Sangrah","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173223","Taluk":"Pajhota","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173225","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173215","Taluk":"Kandaghat","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173027","Taluk":"Shilla","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173223","Taluk":"NA","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173204","Taluk":"Kasauli","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173214","Taluk":"Kasauli(T)","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173204","Taluk":"Kasauli(T)","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173204","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173027","Taluk":"Paonta","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173223","Taluk":"Pachhad","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173021","Taluk":"Paonta","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173229","Taluk":"NA","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173026","Taluk":"Nahan","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173218","Taluk":"Arki","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173222","Taluk":"Kandaghat","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173208","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173220","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"174102","Taluk":"Ramsheher","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173230","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173104","Taluk":"Nohra","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173223","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173217","Taluk":"Kandaghat","Districtname":"Shimla","statename":"HIMACHAL PRADESH"},
    {"pincode":"173023","Taluk":"Sangarh","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173028","Taluk":"Paonta","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173212","Taluk":"Solan(T)","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173214","Taluk":"Solan","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173202","Taluk":"NA","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173220","Taluk":"Kasauli","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173104","Taluk":"Sangarh","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173029","Taluk":"NA","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"173212","Taluk":"Solan","Districtname":"Solan","statename":"HIMACHAL PRADESH"},
    {"pincode":"173022","Taluk":"NA","Districtname":"Sirmaur","statename":"HIMACHAL PRADESH"},
    {"pincode":"174301","Taluk":"Una","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177203","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174314","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177204","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177205","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177203","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174307","Taluk":"Bangana(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177109","Taluk":"Bharwain","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174306","Taluk":"Una","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177209","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177201","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174302","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174303","Taluk":"Una","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174321","Taluk":"Bangana(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177220","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174307","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174303","Taluk":"Una(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174301","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"176601","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174317","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174321","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177213","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177109","Taluk":"Bharwain(S.T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177110","Taluk":"Bharwain(S.T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174315","Taluk":"Una(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174316","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177039","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174308","Taluk":"Bangana(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177210","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177204","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174308","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174306","Taluk":"Una(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177110","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174314","Taluk":"Bangana(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177031","Taluk":"Bangana(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177211","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177212","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174302","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174316","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174319","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177110","Taluk":"Bharwain","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177213","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"176601","Taluk":"Haroli(S.T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177208","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177207","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177208","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177109","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174315","Taluk":"Una","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177201","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177207","Taluk":"Una(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177219","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174503","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177206","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177210","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177206","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177202","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177208","Taluk":"Una","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177212","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177039","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177208","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177211","Taluk":"Amb(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177031","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177209","Taluk":"Una(T)","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"177110","Taluk":"Dehra","Districtname":"Kangra","statename":"HIMACHAL PRADESH"},
    {"pincode":"177219","Taluk":"Amb","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174507","Taluk":"Haroli","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"174320","Taluk":"Bangana","Districtname":"Una","statename":"HIMACHAL PRADESH"},
    {"pincode":"193503","Taluk":"GUREZ","Districtname":"Bandipur","statename":"JAMMU & KASHMIR"},
    {"pincode":"193502","Taluk":"Bandipora","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193224","Taluk":"Kupwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193501","Taluk":"Sonawari","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193121","Taluk":"Pattan","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193504","Taluk":"Sumbal","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Sopore","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193108","Taluk":"Pattan","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193503","Taluk":"Gurez","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193301","Taluk":"Sopore","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193123","Taluk":"Uri","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193101","Taluk":"Baramulla","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193302","Taluk":"Handwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193222","Taluk":"Kupwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193222","Taluk":"Handwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Sopore","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193122","Taluk":"Uri","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193301","Taluk":"Baramulla","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193201","Taluk":"Sopore","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193202","Taluk":"Sopore","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193225","Taluk":"Tagdhar","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193223","Taluk":"Sogam","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193221","Taluk":"Handwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Baramulla","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193103","Taluk":"Baramulla","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193225","Taluk":"Tangdahr","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193224","Taluk":"Handwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193302","Taluk":"Handwara","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193225","Taluk":"Tangdhar","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193223","Taluk":"Kupwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193201","Taluk":"Sorore","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193101","Taluk":"Bandipora","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193225","Taluk":"Kupwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"193202","Taluk":"Uri","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193221","Taluk":"Hadwara","Districtname":"Kupwara","statename":"JAMMU & KASHMIR"},
    {"pincode":"181121","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181141","Taluk":"Samba","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181131","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181102","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184151","Taluk":"Kathua","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"180017","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180002","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181201","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184144","Taluk":"Hiranagar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184205","Taluk":"Billawar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184148","Taluk":"Hiranagar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184204","Taluk":"Billawar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"181102","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184204","Taluk":"Billwar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"180017","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180006","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181202","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180001","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181201","Taluk":"Akhnoor","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181132","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184206","Taluk":"Bani","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184202","Taluk":"Basoli","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184201","Taluk":"Bashohli","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"181123","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181122","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181204","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181133","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184121","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184143","Taluk":"Kathua","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184203","Taluk":"Billawar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184152","Taluk":"Kathua","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184201","Taluk":"Basoli","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"181206","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181152","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181122","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181224","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181101","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181132","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181124","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181141","Taluk":"Bishna","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181203","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184120","Taluk":"Samba","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181102","Taluk":"Ranbirsinghpora","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184206","Taluk":"Bashohli","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184104","Taluk":"Kathua","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"180015","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184145","Taluk":"Hiranagar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"180011","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"185154","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181111","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184205","Taluk":"NA","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"185154","Taluk":"Akhnoor","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184202","Taluk":"Bashohli","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184102","Taluk":"NA","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"180004","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180010","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184142","Taluk":"Hiranagar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"180016","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184141","Taluk":"Hiranagar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184102","Taluk":"Kathua","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184120","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181207","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181143","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181203","Taluk":"Akhnoor","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180003","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181221","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184120","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180001","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180007","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184121","Taluk":"Jammu","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"180005","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184101","Taluk":"Kathua","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"181141","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181145","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181101","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181131","Taluk":"Bishna","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184104","Taluk":"NA","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184151","Taluk":"Hiranagar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184121","Taluk":"Poonch","Districtname":"Poonch","statename":"JAMMU & KASHMIR"},
    {"pincode":"184121","Taluk":"Hiranagar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"180012","Taluk":"jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181101","Taluk":"Ranbirsinghpora","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181205","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184205","Taluk":"Billwar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"184121","Taluk":"Samba","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180012","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"181121","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"184203","Taluk":"Billwar","Districtname":"Kathua","statename":"JAMMU & KASHMIR"},
    {"pincode":"181143","Taluk":"Samba","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180013","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180019","Taluk":"NA","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180009","Taluk":"jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180020","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"180018","Taluk":"Jammu","Districtname":"Jammu","statename":"JAMMU & KASHMIR"},
    {"pincode":"194103","Taluk":"Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194106","Taluk":"Leh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194105","Taluk":"Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194301","Taluk":"Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194105","Taluk":"Leh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194101","Taluk":"Leh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194102","Taluk":"Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194109","Taluk":"Kargil Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194201","Taluk":"Leh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194109","Taluk":"Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194105","Taluk":"Kargilr","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194104","Taluk":"Leh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194401","Taluk":"Nobra","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194102","Taluk":"Drass","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194106","Taluk":"L:eh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194401","Taluk":"Leh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194302","Taluk":"Zanskar","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194106","Taluk":"Khalsi","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194301","Taluk":"Kargil Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194101","Taluk":"NA","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194101","Taluk":"Ladakh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194109","Taluk":"Mulbek","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194404","Taluk":"Leh","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194403","Taluk":"kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194301","Taluk":"Kargl","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194301","Taluk":"Sankoo","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"194402","Taluk":"Len","Districtname":"Leh","statename":"JAMMU & KASHMIR"},
    {"pincode":"194109","Taluk":"Kargil; Kargil","Districtname":"Kargil","statename":"JAMMU & KASHMIR"},
    {"pincode":"185151","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185101","Taluk":"Poonch","Districtname":"Poonch","statename":"JAMMU & KASHMIR"},
    {"pincode":"185102","Taluk":"Poonch","Districtname":"Poonch","statename":"JAMMU & KASHMIR"},
    {"pincode":"185211","Taluk":"Rajouri","Districtname":"Poonch","statename":"JAMMU & KASHMIR"},
    {"pincode":"185212","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185234","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185132","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185121","Taluk":"Rajouri","Districtname":"Poonch","statename":"JAMMU & KASHMIR"},
    {"pincode":"185233","Taluk":"Jammu","Districtname":"Reasi","statename":"JAMMU & KASHMIR"},
    {"pincode":"185152","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185131","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185153","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185156","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185155","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185201","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185233","Taluk":"Jammu","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185135","Taluk":"Jammu","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185202","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185135","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185121","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185233","Taluk":"Rajouri","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"185151","Taluk":"NA","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"190015","Taluk":"A K Pora","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191112","Taluk":"Abhama","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190003","Taluk":"Abi Karlpora","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192201","Taluk":"Achabal","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192305","Taluk":"Achan","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192305","Taluk":"NA","Districtname":"Shopian","statename":"JAMMU & KASHMIR"},
    {"pincode":"192302","Taluk":"NA","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192202","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Aharbal","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192233","Taluk":"Damhal Hanji Pora","Districtname":"Kulgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Ahmedbad","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191202","Taluk":"Kangan","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192231","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192201","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Anantnag","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192122","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192101","Taluk":"Anantnag Ho","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190011","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Arath","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"NA","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Aripal","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Aripanthan","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"192124","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192122","Taluk":"Awantipora","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Awenura","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193402","Taluk":"NA","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"190020","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Badran","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"190001","Taluk":"Badyar Balla","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"190019","Taluk":"Bagh Mehtab","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Baigund","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"191101","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Bandgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"NA","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192301","Taluk":"NA","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Bargam","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192122","Taluk":"Barsoo","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190017","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Batagund","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"190009","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Bathnoor","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192303","Taluk":"Batpora","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"190004","Taluk":"Batrara","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Tangmarg","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"193411","Taluk":"Beerwah","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192401","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Behibagh","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190018","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Bonagand","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Bouli","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"191121","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192101","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192221","Taluk":"Qazigund","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Buchoo","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Budgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Bugam","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190009","Taluk":"Central Market","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Chadoora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192305","Taluk":"Pulwama","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192230","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192122","Taluk":"Pulwama","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Tangmarg","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"191112","Taluk":"Char-sharief","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Chattargam","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Chimar","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190001","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"D.h Pora","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"D.k Marg","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Dadsar","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192202","Taluk":"Anantnag","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191112","Taluk":"Darwan Nawgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Demidulla","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192210","Taluk":"Dialgam","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192211","Taluk":"Dooru","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190002","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192211","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Frisal","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191201","Taluk":"Ganderbal","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Garend","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Gopal Pora","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Gopalpora","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193403","Taluk":"Gulmarg","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"191202","Taluk":"Gund","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Pattan","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Halsidar","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190021","Taluk":"NA","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191131","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191112","Taluk":"Hardudalwan","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Haripora","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192305","Taluk":"Shopian","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192303","Taluk":"NA","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192121","Taluk":"Pulwama","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Hutmarah","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190014","Taluk":"Hyderpora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Ichgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190008","Taluk":"Jawahar Nagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Jawalapora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Kachachakote","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Kachwari","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Kahleel","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192304","Taluk":"Pulwama","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Beerwah","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Kaprin","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190010","Taluk":"Karan Nagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"190006","Taluk":"Kashmir University","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"190003","Taluk":"Kathi Darwaza","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Katrasoo","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191112","Taluk":"Keller Mastpora","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"191112","Taluk":"Kanidagan","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190015","Taluk":"Kanihama","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192101","Taluk":"Khanabal","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190003","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Khigogipora","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191103","Taluk":"Khrew","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Khull","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192202","Taluk":"Kokernag","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Kragsoo","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190019","Taluk":"Badgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Krem Shora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191202","Taluk":"Kulan","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192231","Taluk":"Kulgam","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192221","Taluk":"Kund Bo","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"193404","Taluk":"Tangmarg","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"190023","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"190001","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Lalgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192126","Taluk":"NA","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192202","Taluk":"Larnoo","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191101","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Later Chadoora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Lower Mundah","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191103","Taluk":"Pampore","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Lurgam","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"M/gufan","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Magam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Mandoora","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192221","Taluk":"Manjmoh","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Manzgam","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Martand","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Midroo","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Nagam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Nambal","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Nandi Marg","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190003","Taluk":"Nandpora","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Nanil","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192202","Taluk":"Kukarnag","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"193401","Taluk":"Beerwah","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Nasnoor","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190015","Taluk":"Natipora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"193303","Taluk":"Nehama","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190009","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192221","Taluk":"Nissu","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Noonmai","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190011","Taluk":"Noushera","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Nowgam","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190015","Taluk":"Nowgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190015","Taluk":"NA","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192211","Taluk":"NA","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Ompora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192121","Taluk":"Chadura","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191112","Taluk":"Pakherpora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192121","Taluk":"Pampore","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191101","Taluk":"Pantha Chowk","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192221","Taluk":"Panzeth","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190017","Taluk":"Parimpora","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Pastana","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192126","Taluk":"Pahalgam","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Linglish","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192124","Taluk":"Bijbehara","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Qanmar","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"R H Hall","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190003","Taluk":"Rainawari","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Raithan","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190008","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192306","Taluk":"Pulwama","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192125","Taluk":"Ranbirpora","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Rangar","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191132","Taluk":"NA","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192121","Taluk":"Pulwama","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Reban Gund","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191202","Taluk":"S Bala","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"190002","Taluk":"S R Gunj","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Sadinare","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190002","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Sagam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Saimoh","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"190005","Taluk":"Sanat Nagar","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190001","Taluk":"Sathu","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Satura","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192129","Taluk":"Ananthnag","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Shogapora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Sholipora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"192303","Taluk":"Shopin","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"190007","Taluk":"Budgam","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"190025","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Saibugh","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191203","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Suraryan","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"193402","Taluk":"Tangmarg","Districtname":"Baramulla","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Tarigam","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192124","Taluk":"NA","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192123","Taluk":"Tral","Districtname":"Pulwama","statename":"JAMMU & KASHMIR"},
    {"pincode":"192212","Taluk":"Verinaag","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191202","Taluk":"Wangat","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192221","Taluk":"Wangund","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Wanpora","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191113","Taluk":"Watakala","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191111","Taluk":"Watrihaal","Districtname":"Budgam","statename":"JAMMU & KASHMIR"},
    {"pincode":"191102","Taluk":"Wuyan","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"192232","Taluk":"Yaripora","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"192221","Taluk":"Y.k Pora","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"190012","Taluk":"Zainakote","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"190024","Taluk":"Srinagar","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"190006","Taluk":"NA","Districtname":"Srinagar","statename":"JAMMU & KASHMIR"},
    {"pincode":"191103","Taluk":"Pulwama","Districtname":"Ananthnag","statename":"JAMMU & KASHMIR"},
    {"pincode":"182301","Taluk":"Reasi","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182205","Taluk":"Kishtwar","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182145","Taluk":"Ramban","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"185203","Taluk":"Reasi","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182122","Taluk":"Ramnagar","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182315","Taluk":"Mahore","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182313","Taluk":"Mahore","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182143","Taluk":"Ramban","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182204","Taluk":"Kishtwar","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182311","Taluk":"Reasi","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182143","Taluk":"Doda","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182141","Taluk":"Chenani","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182126","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182202","Taluk":"Doda","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182315","Taluk":"Reasi","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182101","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182146","Taluk":"Ramban","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182203","Taluk":"Thathri","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182161","Taluk":"Chenani","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182124","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"185203","Taluk":"Reasi","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"182121","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182128","Taluk":"Ramnagar","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182315","Taluk":"Gool Gulab Garh","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182145","Taluk":"Banihal","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182127","Taluk":"Ramnagar","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182222","Taluk":"Bhaderwah","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182201","Taluk":"Doda","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182221","Taluk":"Bhaderwah","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182148","Taluk":"Ramban","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182126","Taluk":"Ramnagar","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182144","Taluk":"Doda","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182313","Taluk":"Gool Gulab Garh","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182146","Taluk":"Banihal","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182141","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182203","Taluk":"Bhalessa (Gandoh)","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182144","Taluk":"Gool Gulab Garh","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182125","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"185203","Taluk":"Sunderbani","Districtname":"Rajauri","statename":"JAMMU & KASHMIR"},
    {"pincode":"182124","Taluk":"Doda","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182147","Taluk":"Doda","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182312","Taluk":"Reasi","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182311","Taluk":"Reasi","Districtname":"Reasi","statename":"JAMMU & KASHMIR"},
    {"pincode":"182206","Taluk":"Kishtwar","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182121","Taluk":"Reasi","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182204","Taluk":"Gool Gulab Garh","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182313","Taluk":"Reasi","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182320","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182142","Taluk":"Chenani","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182101","Taluk":"NA","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182144","Taluk":"Ramban","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182301","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182201","Taluk":"Bhaderwah","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182201","Taluk":"Thathri","Districtname":"Doda","statename":"JAMMU & KASHMIR"},
    {"pincode":"182122","Taluk":"Chenani","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182104","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182128","Taluk":"Udhampur","Districtname":"Udhampur","statename":"JAMMU & KASHMIR"},
    {"pincode":"182320","Taluk":"Reasi","Districtname":"Reasi","statename":"JAMMU & KASHMIR"},
    {"pincode":"828124","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827013","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828201","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828134","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828302","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828111","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828113","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"829301","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827001","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828130","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827011","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827010","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827302","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828110","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828306","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828109","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827009","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827014","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828116","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828101","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828205","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828106","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"826005","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828204","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828121","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828301","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827302","Taluk":"Bokaro","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828308","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828103","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828401","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828303","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828302","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828133","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828104","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827001","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828402","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828117","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828202","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828134","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828121","Taluk":"Chas","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827013","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828135","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"826001","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"826004","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828107","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828108","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827010","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827302","Taluk":"Kasmar","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"826003","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"829301","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828112","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828125","Taluk":"Bokaro","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828309","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828303","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828112","Taluk":"Bokaro","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828129","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828307","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827302","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828114","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828307","Taluk":"Bokaro","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828125","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828115","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828131","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828203","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827010","Taluk":"NA","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828207","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828304","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827012","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828305","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828120","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828307","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828206","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828132","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828119","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828123","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828122","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828127","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"827003","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827004","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827009","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"827006","Taluk":"Chas","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828105","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828125","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828126","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"828128","Taluk":"Dhanbad","Districtname":"Dhanbad","statename":"JHARKHAND"},
    {"pincode":"815312","Taluk":"Bengabad","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815311","Taluk":"NA","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825412","Taluk":"Dhanwar","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829104","Taluk":"NA","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825102","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825412","Taluk":"Djanwar","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829144","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829121","Taluk":"Peterbar","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825322","Taluk":"Bagodar","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825108","Taluk":"NA","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825322","Taluk":"NA","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815302","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825320","Taluk":"Bagodar","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815314","Taluk":"Deori","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815316","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815318","Taluk":"Jamua","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825324","Taluk":"Birni","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815301","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829112","Taluk":"Gomia","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815312","Taluk":"NA","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825167","Taluk":"Dumri","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825412","Taluk":"Ddhanwan","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829127","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825106","Taluk":"Dumri","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815313","Taluk":"Ganwan","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815312","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815311","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829104","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815317","Taluk":"Tisri","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829132","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829144","Taluk":"Nawadih","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825108","Taluk":"Pirtand","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829107","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829114","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829123","Taluk":"Tenughat","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828403","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825102","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815315","Taluk":"Jamua","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"828404","Taluk":"NA","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815318","Taluk":"Jamuagiridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825106","Taluk":"NA","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815316","Taluk":"Jmua","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815313","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829111","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829107","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829132","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829111","Taluk":"Gomia","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829128","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829112","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825167","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825412","Taluk":"Dhanwatr","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815318","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829113","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829114","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815315","Taluk":"Beniadih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829116","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815314","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829113","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825322","Taluk":"NA","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"828403","Taluk":"Bermo","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815313","Taluk":"NA","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815315","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825324","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829121","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829144","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825108","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825412","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829123","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825329","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829127","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825320","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829128","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"829149","Taluk":"Bokaro","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"815312","Taluk":"Bengabd","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"815317","Taluk":"Giridih","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"829132","Taluk":"NA","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825311","Taluk":"Barkagaon","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825408","Taluk":"Itkhori","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"829101","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825418","Taluk":"Kodarma","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825401","Taluk":"Chatra","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825302","Taluk":"Sadar","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825103","Taluk":"Simaria","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825321","Taluk":"Tandwa","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825407","Taluk":"Kodarma","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825319","Taluk":"Katakamsandi","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829143","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825313","Taluk":"Sadar","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825312","Taluk":"Bishungarh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829110","Taluk":"Gola","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825314","Taluk":"Mandu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825132","Taluk":"NA","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825301","Taluk":"Hazaribagh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829134","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825405","Taluk":"Barhi","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825402","Taluk":"Ichak","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829103","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829102","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825323","Taluk":"Barkatha","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825323","Taluk":"Barkatha","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825101","Taluk":"Gola","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825406","Taluk":"Chauparan","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825409","Taluk":"Kodarma","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825330","Taluk":"Gola","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829105","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829117","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829106","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829135","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825404","Taluk":"Pratappur","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825403","Taluk":"Hunterganj","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825317","Taluk":"Hazaribagh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825109","Taluk":"Jainagar","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825404","Taluk":"Patratu","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825303","Taluk":"Churchu","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825409","Taluk":"Chandwara","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825336","Taluk":"Churchu","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825315","Taluk":"Konardam","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"825101","Taluk":"Chitarpur","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825318","Taluk":"Markacho","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825406","Taluk":"Chouparan","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825302","Taluk":"Hazaribagh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825411","Taluk":"Padma","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825319","Taluk":"Katkamsandi","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829109","Taluk":"NA","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825313","Taluk":"Hazaribagh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825410","Taluk":"Kodarma","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825401","Taluk":"Hunterganj","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"829108","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829109","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825408","Taluk":"Gidhaur","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825413","Taluk":"Kodarma","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"829119","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825316","Taluk":"Mandu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829101","Taluk":"Ramgarh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829150","Taluk":"Gola","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825410","Taluk":"NA","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825317","Taluk":"Sdar","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825303","Taluk":"Ichak","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829118","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825318","Taluk":"Markach","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825404","Taluk":"NA","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825403","Taluk":"Jori","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825302","Taluk":"Katkamsandi","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825323","Taluk":"Barkhatha","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825409","Taluk":"Karma","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825401","Taluk":"Karma","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"825325","Taluk":"Mandu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825325","Taluk":"Churchu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825421","Taluk":"Koderma","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825315","Taluk":"Bishnugarh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825303","Taluk":"Hazaribag","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825316","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825406","Taluk":"NA","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825401","Taluk":"Chauparan","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829134","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825302","Taluk":"Churchu","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825303","Taluk":"Sadar","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829119","Taluk":"Patratu","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829130","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829122","Taluk":"Ramgarh Cantt","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829122","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825319","Taluk":"Hazaribagh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"829129","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829125","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829133","Taluk":"Ramgarh","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829126","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829133","Taluk":"Patratu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829131","Taluk":"NA","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825326","Taluk":"Churchu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"825418","Taluk":"NA","Districtname":"Giridh","statename":"JHARKHAND"},
    {"pincode":"825313","Taluk":"Bishnugarh","Districtname":"Hazaribag","statename":"JHARKHAND"},
    {"pincode":"825413","Taluk":"Chandwara","Districtname":"Koderma","statename":"JHARKHAND"},
    {"pincode":"825330","Taluk":"Mandu","Districtname":"Ramgarh","statename":"JHARKHAND"},
    {"pincode":"829203","Taluk":"Chandwa","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822114","Taluk":"Garhwa","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822121","Taluk":"Nagar Utari","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822116","Taluk":"Japla","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822122","Taluk":"Panki","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822112","Taluk":"Garhwa","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822119","Taluk":"Mahuwadand","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822118","Taluk":"Lesliganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822134","Taluk":"Garhwa","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822113","Taluk":"Chhatarpur","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829201","Taluk":"Bachra","Districtname":"Chatra","statename":"JHARKHAND"},
    {"pincode":"822123","Taluk":"Patan","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822115","Taluk":"Japla","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822125","Taluk":"Ranka Raj","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822126","Taluk":"Satbarwa","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822133","Taluk":"Garhwa","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"829206","Taluk":"Latehar","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"829202","Taluk":"Balumath","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822133","Taluk":"Meral","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"829201","Taluk":"Bachra","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822124","Taluk":"Bisrampur","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822118","Taluk":"Leshliganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829204","Taluk":"Latehar","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822112","Taluk":"Balumath","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822111","Taluk":"Barwadih","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822120","Taluk":"Japla","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822101","Taluk":"Daltonganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822128","Taluk":"Meral","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822124","Taluk":"Bishrampur","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822129","Taluk":"Bhawanathpur","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822132","Taluk":"Daltoganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829205","Taluk":"Bundu","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"822110","Taluk":"Chainpur","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822119","Taluk":"Mahuwdand","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"829202","Taluk":"Malumath","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822102","Taluk":"Daltonganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829203","Taluk":"Balumath","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"829210","Taluk":"Dakra","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"822101","Taluk":"NA","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829207","Taluk":"Latehar","Districtname":"Latehar","statename":"JHARKHAND"},
    {"pincode":"822132","Taluk":"Bisrampur","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"829205","Taluk":"Mander","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"822134","Taluk":"Meral","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"822131","Taluk":"Harihar Ganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829209","Taluk":"Mander","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"822117","Taluk":"Japla","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822110","Taluk":"Chinpur","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822118","Taluk":"Lesliganj","Districtname":"Garhwa","statename":"JHARKHAND"},
    {"pincode":"829208","Taluk":"Mander","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"822113","Taluk":"Chhatapru","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822113","Taluk":"Chhatarpru","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829208","Taluk":"Burmu","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"822126","Taluk":"Daltonganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822110","Taluk":"Patan","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822110","Taluk":"Rehala","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829209","Taluk":"Bundu","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"822113","Taluk":"Chhtarpur","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822131","Taluk":"Hariharganj","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822120","Taluk":"Lapla","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"829202","Taluk":"Balumath","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822126","Taluk":"Patan","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"822128","Taluk":"Meral","Districtname":"Palamau","statename":"JHARKHAND"},
    {"pincode":"835204","Taluk":"Bundu","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"834008","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835231","Taluk":"Gumla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"834002","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835211","Taluk":"Kolibira","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835102","Taluk":"Silli","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835325","Taluk":"Bhandra","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835203","Taluk":"Gumla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835227","Taluk":"Torpa","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835232","Taluk":"Nwagarh","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835225","Taluk":"Tamar","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835103","Taluk":"Angara","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835210","Taluk":"Khunti","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835233","Taluk":"Toto","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835208","Taluk":"Gumla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835324","Taluk":"Sisai","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835302","Taluk":"Lohardaga","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835220","Taluk":"Gumla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835324","Taluk":"Gumla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835212","Taluk":"Simdega","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835223","Taluk":"Simdega","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"834004","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835226","Taluk":"Thethaitangar","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835301","Taluk":"Itki","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835216","Taluk":"Murhu","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835229","Taluk":"Basia","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835205","Taluk":"Mandar","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835216","Taluk":"Bandgaon","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"835201","Taluk":"Bano","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"834010","Taluk":"Namkum","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835219","Taluk":"Ormanjhi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835211","Taluk":"Kolibira","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"834009","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835211","Taluk":"Kolibera","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835303","Taluk":"Ratu","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835102","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835211","Taluk":"Kolebira","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835206","Taluk":"Chainpur","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835202","Taluk":"NA","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835230","Taluk":"Noadih","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835302","Taluk":"Bhandra","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835203","Taluk":"Bharno","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835228","Taluk":"Simdega","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835222","Taluk":"Ratu","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835234","Taluk":"Karra","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835220","Taluk":"Palkot","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835209","Taluk":"Karra","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835214","Taluk":"Mandar","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835232","Taluk":"Nawagarh","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835231","Taluk":"Bishunpur","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835226","Taluk":"Bolba","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"834006","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"834006","Taluk":"Kanke","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835217","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835212","Taluk":"Kurdeg","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835213","Taluk":"Kuru","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835214","Taluk":"Chanho","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835101","Taluk":"Silli","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"834001","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835221","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835235","Taluk":"Simdega","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835202","Taluk":"Bero","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835230","Taluk":"Nodih","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835212","Taluk":"Kurdeg","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835207","Taluk":"Gumla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"834003","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"834005","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835219","Taluk":"Ormanhji","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835302","Taluk":"Lohardags","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835301","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835226","Taluk":"Simdega","Districtname":"Simdega","statename":"JHARKHAND"},
    {"pincode":"835213","Taluk":"NA","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835218","Taluk":"Lohardaga","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835227","Taluk":"Kamdara","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835232","Taluk":"Gomla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835325","Taluk":"Bhandra","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835235","Taluk":"Simdega","Districtname":"Khunti","statename":"JHARKHAND"},
    {"pincode":"834011","Taluk":"NA","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"834009","Taluk":"Ranchi","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835234","Taluk":"Lapung","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835229","Taluk":"Kamdara","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835215","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835325","Taluk":"Itki","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"834010","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835233","Taluk":"NA","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835232","Taluk":"Gumla","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835303","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835227","Taluk":"Rania","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835225","Taluk":"NA","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835302","Taluk":"Ohardaga","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835219","Taluk":"Ormanchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835219","Taluk":"Ranchi","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"835302","Taluk":"Lohardaga","Districtname":"Khunti","statename":"JHARKHAND"},
    {"pincode":"835302","Taluk":"NA","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"835214","Taluk":"Mandar","Districtname":"Gumla","statename":"JHARKHAND"},
    {"pincode":"835325","Taluk":"Bhandara","Districtname":"Ranchi","statename":"JHARKHAND"},
    {"pincode":"834004","Taluk":"Ranchi","Districtname":"Lohardaga","statename":"JHARKHAND"},
    {"pincode":"814143","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814160","Taluk":"Meharma","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"815355","Taluk":"Nala","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814153","Taluk":"Poraiyahat","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Borio","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814146","Taluk":"Jama","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814102","Taluk":"NA","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814145","Taluk":"Ramgarh","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815359","Taluk":"NA","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814141","Taluk":"Jarmundi","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815352","Taluk":"Narayanpur","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814103","Taluk":"Kathikund","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814148","Taluk":"Raniswar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814110","Taluk":"Jama","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814133","Taluk":"Godda","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814158","Taluk":"Jama","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814111","Taluk":"Amrapara","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814154","Taluk":"Mahagama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816120","Taluk":"Borio","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"816104","Taluk":"Hiranpur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"816118","Taluk":"Raneswar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815351","Taluk":"Jamtara","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814119","Taluk":"Dumka","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816106","Taluk":"Maheshpur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814146","Taluk":"Palojori","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Rajmahal","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814155","Taluk":"Pathargama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814145","Taluk":"Sariyahat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814112","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816110","Taluk":"Pathna","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"816102","Taluk":"Barhait","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814166","Taluk":"Kundahit","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815359","Taluk":"Kundahit","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"815351","Taluk":"Palojori","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814152","Taluk":"Debipur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814118","Taluk":"Jama","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815353","Taluk":"Madhupur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814144","Taluk":"Dumka","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"813208","Taluk":"Meharma","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814146","Taluk":"Palojori","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814143","Taluk":"Sarwan","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814133","Taluk":"Poriyahat","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Taljhari","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"816109","Taluk":"Sahibganj","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814111","Taluk":"Mahesapur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"816117","Taluk":"Pakuria","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814149","Taluk":"Sarath","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"815353","Taluk":"Sarath","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814147","Taluk":"Pathergama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814102","Taluk":"Ramgarah","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814101","Taluk":"Dumka","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814110","Taluk":"Dumka","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814165","Taluk":"Mahgama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816109","Taluk":"Borio","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814142","Taluk":"NA","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816118","Taluk":"Shikaripara","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814156","Taluk":"Sunder Pahari","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814144","Taluk":"Raneshwar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816103","Taluk":"Shikaripara","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816116","Taluk":"Taljhari","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Pathna","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814146","Taluk":"Masalia","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816102","Taluk":"Littipara","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"815357","Taluk":"Sarath","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814165","Taluk":"Boarijore","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"815357","Taluk":"Karon","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814118","Taluk":"Jarmundi","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814120","Taluk":"Jarmundi","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Barharwa","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814151","Taluk":"Sariyahat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816102","Taluk":"NA","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814111","Taluk":"Maheshpur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814147","Taluk":"Gouraijore","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814102","Taluk":"Ramgarh","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816107","Taluk":"Pakaur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814152","Taluk":"Devipur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814158","Taluk":"Dumka","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814113","Taluk":"Mohanpur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816104","Taluk":"Litipara","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"815351","Taluk":"Nala","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"816105","Taluk":"Barharwa","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"816110","Taluk":"Barharwa","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814155","Taluk":"Mahagama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"813208","Taluk":"Boarijor","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814144","Taluk":"Raneswar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"813208","Taluk":"Borio","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814156","Taluk":"Sunadar Pahari","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"815354","Taluk":"NA","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Udhua","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814151","Taluk":"Saraiyahat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815351","Taluk":"NA","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"816107","Taluk":"Pakur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814151","Taluk":"Sarayahat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814156","Taluk":"Godda","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"815351","Taluk":"Narayanpur","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"815351","Taluk":"Sarath","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814149","Taluk":"Palojori","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816108","Taluk":"Udhua","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814157","Taluk":"Mohanpur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814142","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816107","Taluk":"Hiranpur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814152","Taluk":"Rohni","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814153","Taluk":"Godda","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814153","Taluk":"Sunderpahari","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816106","Taluk":"Maheshpru","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814152","Taluk":"NA","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"815355","Taluk":"Poraiyahat","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814113","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814114","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"815352","Taluk":"Bnarayanpur","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"816118","Taluk":"Sikaripara","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816102","Taluk":"Sunderpahari","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816118","Taluk":"NA","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814103","Taluk":"Gopikandar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814166","Taluk":"Kundahit","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814149","Taluk":"Sarwan","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814120","Taluk":"Jarmundi","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814148","Taluk":"Raneswar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814151","Taluk":"Sarayaha","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814165","Taluk":"Meharma","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814119","Taluk":"Sikaripara","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814133","Taluk":"Pathargama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816103","Taluk":"Pakuria","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814156","Taluk":"Sundar Pahari","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814152","Taluk":"Dibipur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814144","Taluk":"Masalia","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814120","Taluk":"Mohanpur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814110","Taluk":"Masalia","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814166","Taluk":"Masalia","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816120","Taluk":"Barhait","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814115","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816102","Taluk":"Borio","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814166","Taluk":"Kundahir","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814145","Taluk":"Saraiyahat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816108","Taluk":"Udhwa","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814122","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816116","Taluk":"Rajmahal","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814103","Taluk":"Kathikiund","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814102","Taluk":"Poriyahat","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816108","Taluk":"Rajmahal","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814145","Taluk":"Saraiyhat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816116","Taluk":"Rahmahal","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"815353","Taluk":"Karon","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816129","Taluk":"Taljhari","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814158","Taluk":"Rmagarh","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816117","Taluk":"Gopikander","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815352","Taluk":"Jamtara","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"816117","Taluk":"Gopikandar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814154","Taluk":"Mahagama.","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816108","Taluk":"Sahibganj","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814119","Taluk":"Masaliya","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815354","Taluk":"Jamtara","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814145","Taluk":"Jarmundi","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816105","Taluk":"Pakur","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814143","Taluk":"Doghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814147","Taluk":"Boarijore","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814147","Taluk":"Mahagama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814150","Taluk":"Sarwan","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814133","Taluk":"Pahargama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814154","Taluk":"Mahagam","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814151","Taluk":"Poriyahat","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816109","Taluk":"Taljhari","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814133","Taluk":"NA","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814155","Taluk":"Pathergama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814154","Taluk":"Mahgama","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814119","Taluk":"Masalia","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814155","Taluk":"NA","Districtname":"Bokaro","statename":"JHARKHAND"},
    {"pincode":"813208","Taluk":"Mandro","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"815353","Taluk":"Koron","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Uedhwa","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814114","Taluk":"Debipur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"815352","Taluk":"Narayapur","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814133","Taluk":"NA","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"814150","Taluk":"Sarwan","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816108","Taluk":"NA","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"815351","Taluk":"Sarth","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"816104","Taluk":"Amrapara","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814156","Taluk":"Sundarpahari","Districtname":"Godda","statename":"JHARKHAND"},
    {"pincode":"816103","Taluk":"Shikripara","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816107","Taluk":"NA","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815353","Taluk":"Satath","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814148","Taluk":"NA","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814153","Taluk":"Poraiyahat","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814152","Taluk":"Debuipur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814148","Taluk":"Raneshwar","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814120","Taluk":"Jarmudi","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814167","Taluk":"Masalia","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815357","Taluk":"Koron","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814152","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"816115","Taluk":"Sahibganj","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814116","Taluk":"Deoghar","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"814158","Taluk":"Ramgarh","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"816101","Taluk":"Uedhua","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"813208","Taluk":"Boarijore","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"814102","Taluk":"Poraiyahat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"814166","Taluk":"Kundhahit","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"816102","Taluk":"Barhit","Districtname":"Sahibganj","statename":"JHARKHAND"},
    {"pincode":"816104","Taluk":"NA","Districtname":"Pakur","statename":"JHARKHAND"},
    {"pincode":"814143","Taluk":"Mohanpur","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"815354","Taluk":"Nala","Districtname":"Jamtara","statename":"JHARKHAND"},
    {"pincode":"814145","Taluk":"Poriyahat","Districtname":"Dumka","statename":"JHARKHAND"},
    {"pincode":"815353","Taluk":"NA","Districtname":"Deoghar","statename":"JHARKHAND"},
    {"pincode":"832401","Taluk":"NA","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832109","Taluk":"A.i .area","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831013","Taluk":"NA","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831009","Taluk":"Agrico","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833101","Taluk":"Amda","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832301","Taluk":"East Singhbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832102","Taluk":"Ghatshila","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833214","Taluk":"Chaibasa","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832101","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832302","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833216","Taluk":"Seraikella","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832102","Taluk":"Ghatsila","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833105","Taluk":"Chakradharpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832303","Taluk":"East Singhbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833202","Taluk":"Chaibasa","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832110","Taluk":"Azadnagar","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831002","Taluk":"Dhalbhum","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832104","Taluk":"Dumaria","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833204","Taluk":"Chaibasa","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831002","Taluk":"Dhalbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832101","Taluk":"Baharagora","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833104","Taluk":"Chakradharpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833102","Taluk":"Chakradharpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832301","Taluk":"Dhalbhum,  Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832401","Taluk":"East Singhbhum","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832401","Taluk":"Seraikella Kharsawan","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832404","Taluk":"Seraikella Kharsawan","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832105","Taluk":"Dhalbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832104","Taluk":"Ghatshila","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832104","Taluk":"Ghatsila","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831002","Taluk":"Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832112","Taluk":"Dhalbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833204","Taluk":"Chaibasa","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833221","Taluk":"Chaibasa","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833221","Taluk":"Barajamda","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833215","Taluk":"Chaibasa","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832304","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832301","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832108","Taluk":"Seraikella Kharsawan","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833103","Taluk":"Goilkera","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833201","Taluk":"Chaibasa","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831017","Taluk":"Baridih Colony","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832304","Taluk":"Ghatsila","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831012","Taluk":"Mango","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831002","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833105","Taluk":"Seraikela","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831012","Taluk":"Dhalbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832105","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831019","Taluk":"NA","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831001","Taluk":"Bistupur Bazar","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831001","Taluk":"Bistupur Gate","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832112","Taluk":"NA","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831007","Taluk":"Burma Mines","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833220","Taluk":"Seraikera","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833102","Taluk":"Chakdradharpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833102","Taluk":"Chakradharpur","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832301","Taluk":"Chakulia","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832401","Taluk":"Chandil","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832101","Taluk":"East Singhbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833216","Taluk":"NA","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832112","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831004","Taluk":"Dhalbhum Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833106","Taluk":"Chiria","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833106","Taluk":"Manoharpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832403","Taluk":"Chandil","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832404","Taluk":"Chowka","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831001","Taluk":"Mango","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831001","Taluk":"Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833212","Taluk":"Danguaposi","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832302","Taluk":"Dhalbhumgarh","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833220","Taluk":"Seraikela","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832104","Taluk":"NA","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833101","Taluk":"West Singhbhum","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832108","Taluk":"Gamharia","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832303","Taluk":"Ghatsila","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831004","Taluk":"Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832107","Taluk":"Tatanagar","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831015","Taluk":"Gobindpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832302","Taluk":"East Singhbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831003","Taluk":"Golmuri","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833101","Taluk":"West Singhbhum","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833213","Taluk":"Gua","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833214","Taluk":"Hatgamaria","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832402","Taluk":"Gamharia","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831004","Taluk":"Indranagar","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832102","Taluk":"Jadugoda","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833203","Taluk":"Jagannathpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833214","Taluk":"Jagannathpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833105","Taluk":"Chakradharpur","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833215","Taluk":"Jhinkpani","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833217","Taluk":"Chaibasa","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833102","Taluk":"West Singhbhum","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833218","Taluk":"Noamundi","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831006","Taluk":"Jugsalai","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831005","Taluk":"Kadma","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831004","Taluk":"Sakchi","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832402","Taluk":"Kandra","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831012","Taluk":"Dhalbhum, Jamshedpur","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832107","Taluk":"Dhalbhum","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833216","Taluk":"Kharsawangarh","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833204","Taluk":"NA","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833222","Taluk":"Kiriburu","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833203","Taluk":"Jagganathpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833216","Taluk":"Seraikera","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833105","Taluk":"Chakrdharpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831004","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831018","Taluk":"Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832304","Taluk":"Mahulia","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833104","Taluk":"Manoharpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833223","Taluk":"Megahatuburu","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832104","Taluk":"Mosabani","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832103","Taluk":"Moubhandar","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831007","Taluk":"Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832111","Taluk":"NA","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833217","Taluk":"Noamundi","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831020","Taluk":"Mango Notified Area","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832105","Taluk":"Patamda","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833219","Taluk":"Seraikella","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831012","Taluk":"Dhalbhum, Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832112","Taluk":"Seraikella Kharsawan","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831014","Taluk":"Jamshedpur","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831016","Taluk":"Rahargora","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833104","Taluk":"Jagannathpur","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831002","Taluk":"Rajnagar","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832106","Taluk":"R.c.project","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832106","Taluk":"NA","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"831002","Taluk":"NA","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831001","Taluk":"Sakchi","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833202","Taluk":"Chaibasa","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833220","Taluk":"Sini","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831011","Taluk":"East Singhbhum","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"833105","Taluk":"Sonua","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832107","Taluk":"Sundarnagar","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832404","Taluk":"Seraikella Kharsawanq","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"832401","Taluk":"Seraikella Kharsawanq","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"831010","Taluk":"Jamshedpur","Districtname":"East Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832403","Taluk":"Tiruldih","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"833104","Taluk":"NA","Districtname":"West Singhbhum","statename":"JHARKHAND"},
    {"pincode":"832404","Taluk":"Seraikalla Kharsawan","Districtname":"Seraikela-kharsawan","statename":"JHARKHAND"},
    {"pincode":"560063","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560007","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560007","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560092","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560024","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560045","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560064","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560047","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560043","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560001","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560300","Taluk":"NA","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560025","Taluk":"NA","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560103","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560046","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560049","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560049","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560093","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560001","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560025","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560067","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560036","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560077","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560037","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560071","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560016","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560066","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560005","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560065","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560087","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560008","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560051","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560048","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560038","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560094","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560006","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560046","Taluk":"NA","Districtname":"NULL","statename":"NULL"},
    {"pincode":"560075","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560067","Taluk":"Banglore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560067","Taluk":"Hosakote","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560084","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560033","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560087","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560017","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560032","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560080","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560042","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560001","Taluk":"NA","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560047","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560066","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560064","Taluk":"NA","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560030","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560034","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560108","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560050","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560070","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560085","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560002","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560076","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560083","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560004","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560068","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560056","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560082","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560099","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560035","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560018","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560081","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560053","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560061","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560060","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560026","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560029","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560062","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560100","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560019","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560105","Taluk":"Anekal","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560102","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560076","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560099","Taluk":"Bangalore South","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560078","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560041","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560011","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560082","Taluk":"Bangalore South","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560083","Taluk":"Bangalore South","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560060","Taluk":"Bangalore South","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560062","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560095","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560074","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560039","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560083","Taluk":"Anekal","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560098","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560059","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560027","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560068","Taluk":"Bangalore South","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560029","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560109","Taluk":"Bangalore South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560004","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560110","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560107","Taluk":"Bangalore North","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560073","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560002","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560009","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560091","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560079","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560089","Taluk":"Bangalore North","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560040","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560090","Taluk":"Bangalore North","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560021","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560104","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560091","Taluk":"Bangalore north","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560088","Taluk":"Bangalore North","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560010","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560014","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560013","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560015","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560091","Taluk":"Bangalore North","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"560112","Taluk":"Bangalore","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560058","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560023","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560086","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560003","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560055","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560054","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560072","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560096","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560057","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560012","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560020","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560090","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560097","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"560022","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562108","Taluk":"Channapatna","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562117","Taluk":"Kanakapura","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"561101","Taluk":"Magadi","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562120","Taluk":"Magadi","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"561201","Taluk":"Magadi","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562159","Taluk":"Ramanagara","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562138","Taluk":"Channapatna","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562119","Taluk":"Kanakapura","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562110","Taluk":"Devanahalli","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562162","Taluk":"Nelamangala","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562106","Taluk":"Anekal","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562159","Taluk":"Ramanagaram","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"561203","Taluk":"Doddaballapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562163","Taluk":"Doddaballapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562123","Taluk":"Nelamangala","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562111","Taluk":"Nelamangala","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"561204","Taluk":"Dodballapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562107","Taluk":"Anekal","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562126","Taluk":"Kanakapura","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562112","Taluk":"Kanakapura","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"561201","Taluk":"Magadi","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562164","Taluk":"Devanahalli","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562149","Taluk":"Banglorenorth","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562131","Taluk":"Magadi","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562127","Taluk":"Magadi","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562121","Taluk":"Kanakapura","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562149","Taluk":"Bgnorth","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562159","Taluk":"Ramangara","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562122","Taluk":"Hoskote","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562129","Taluk":"Hoskote","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562157","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562109","Taluk":"Ramanagara","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562164","Taluk":"Devanahalli","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562109","Taluk":"Ramangara","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562160","Taluk":"Channapatna","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562120","Taluk":"Magadi","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562135","Taluk":"Devanahalli","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562157","Taluk":"Bg North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562130","Taluk":"Bgsouth","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562130","Taluk":"Bg South","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562162","Taluk":"Bangalore North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"561203","Taluk":"Dod Ballapur","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"561204","Taluk":"Doddaballapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562132","Taluk":"Nelamangala","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562114","Taluk":"Hoskote","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562163","Taluk":"Dodballapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562125","Taluk":"Anekal","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"561205","Taluk":"Dodballapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562161","Taluk":"Channapatna","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562128","Taluk":"Ramanagara","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"561203","Taluk":"Dodballapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562128","Taluk":"Ramangaram","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"561205","Taluk":"Dodbalapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562110","Taluk":"Devanhalli","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562114","Taluk":"Hosakote","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562130","Taluk":"Nla & Bgsouth","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562109","Taluk":"Ramanagar","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562149","Taluk":"Bg North","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562164","Taluk":"Devanhalli","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562130","Taluk":"Bg South","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562159","Taluk":"NA","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562123","Taluk":"Nelamagala","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562119","Taluk":"Kanakapura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562163","Taluk":"Doddabllapur","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562126","Taluk":"Kanakapaura","Districtname":"Ramanagar","statename":"KARNATAKA"},
    {"pincode":"562130","Taluk":"Nelamangala","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"562130","Taluk":"Bangaloresouth","Districtname":"Bangalore","statename":"KARNATAKA"},
    {"pincode":"562163","Taluk":"Doddaballpura","Districtname":"Bangalore Rural","statename":"KARNATAKA"},
    {"pincode":"587111","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587201","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587330","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587206","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587124","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587119","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587116","Taluk":"Bilgi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587301","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587155","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587121","Taluk":"Bilgi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587118","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587125","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587112","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587311","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587117","Taluk":"Bilgi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587101","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587103","Taluk":"NA","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587103","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587104","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587204","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587313","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587113","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587114","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587115","Taluk":"Hungund Taluk","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587115","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587202","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587207","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587115","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587121","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587122","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587120","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587204","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587312","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587312","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587102","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587315","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587154","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587202","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587203","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587121","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587205","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587301","Taluk":"Jqamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587314","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587119","Taluk":"Jamkandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587122","Taluk":"Badami","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587207","Taluk":"Hungund","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587301","Taluk":"Bilgi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587316","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587125","Taluk":"Mudhol","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"587117","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"587120","Taluk":"Bagalkot","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"591110","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591143","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591129","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590020","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590010","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591112","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591108","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591345","Taluk":"Khanapur","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591102","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590006","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591109","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591126","Taluk":"Parasgad","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591117","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590016","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591126","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591115","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591127","Taluk":"Ramdurg","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591131","Taluk":"Khanapur","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591131","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591111","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591102","Taluk":"Sampgaon","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590018","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591115","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591103","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591130","Taluk":"Ramdurg","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591302","Taluk":"Khanapur","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591124","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591123","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591128","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591120","Taluk":"Londa","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590001","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590019","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590003","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590005","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590017","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590009","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590016","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591104","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591125","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591156","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591106","Taluk":"Khanapur","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591112","Taluk":"Khanapur","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591102","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591114","Taluk":"Ramdurg","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591123","Taluk":"Ramdurg","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591109","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591119","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591153","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591147","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590014","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591301","Taluk":"Khanapur","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591121","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591109","Taluk":"Sampgaon","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591116","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591120","Taluk":"Khanapur","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591126","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590011","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591118","Taluk":"Bailhongal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591346","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591113","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590015","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591345","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591115","Taluk":"Sampgaon","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591301","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591153","Taluk":"Sampgaon","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590008","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591111","Taluk":"Sampgaon","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591106","Taluk":"Sampgaon","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591126","Taluk":"Ramdurg","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591121","Taluk":"Saundatti","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591120","Taluk":"Belgaum","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591116","Taluk":"Ramdurg","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591102","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591103","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590020","Taluk":"BELGAUM","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591301","Taluk":"Londa","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591115","Taluk":"Bailhogal","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590014","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591173","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"590005","Taluk":"NA","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"583131","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583114","Taluk":"Siruguppa","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583128","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583121","Taluk":"Siruguppa","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583220","Taluk":"Huvinahadagali","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583127","Taluk":"Harapanahalli","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583137","Taluk":"Harapanahalli","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583104","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583218","Taluk":"Kudligi","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583135","Taluk":"Kudligi","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583211","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583134","Taluk":"Kudligi","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583125","Taluk":"Harapanahalli","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583201","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583212","Taluk":"Hb Halli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583115","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583130","Taluk":"Kudligi","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583213","Taluk":"Harapanahalli","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583122","Taluk":"Siruguppa","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583103","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583116","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583131","Taluk":"Harapanahalli","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583102","Taluk":"NA","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583124","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583224","Taluk":"Hagaribommanahalli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583214","Taluk":"Hb Halli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583126","Taluk":"Kudligi","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583224","Taluk":"Hb Halli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583117","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583216","Taluk":"Huvinahadagali","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583101","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583102","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583105","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583152","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583132","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583120","Taluk":"Siruguppa","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583119","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583217","Taluk":"Huvinahadagali","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583223","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583111","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583113","Taluk":"Bellary","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583129","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583222","Taluk":"Hospet Ho","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583118","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583222","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583219","Taluk":"Huvinahadagali","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583112","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583212","Taluk":"Hagaribommanahalli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583214","Taluk":"Huvinahadagali","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583222","Taluk":"H B Halli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583215","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583239","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583136","Taluk":"Kudligi","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583203","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583219","Taluk":"Hadagalli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583220","Taluk":"Huvinagadagali","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583135","Taluk":"Kudlgi","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583276","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583221","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583219","Taluk":"Huvinhadagali","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583123","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583220","Taluk":"Hagaribommanahalli","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583129","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583125","Taluk":"Haralpanahalli","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583137","Taluk":"Harapanahallli","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"583225","Taluk":"Hospet","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"583275","Taluk":"Sandur","Districtname":"Bellary","statename":"KARNATAKA"},
    {"pincode":"585444","Taluk":"Bhalki","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585328","Taluk":"Bhalki","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585419","Taluk":"Basavakalyan","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585402","Taluk":"Bidar","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585414","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585416","Taluk":"Bhalki","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585403","Taluk":"Bidar","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585326","Taluk":"Aurad (b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585226","Taluk":"Bidar","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585436","Taluk":"Aurad (b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585412","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585327","Taluk":"Basavakalyan","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585443","Taluk":"Aurad (b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585436","Taluk":"Aurad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585227","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585227","Taluk":"Homnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585411","Taluk":"Bhalki","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585401","Taluk":"Bidar","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585326","Taluk":"Aurad(b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585415","Taluk":"Bhalki","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585414","Taluk":"Bhalki","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585418","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585417","Taluk":"Aurad (b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585421","Taluk":"Aurad (b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585437","Taluk":"Basavakalyan","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585412","Taluk":"Homnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585415","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585413","Taluk":"Bhalki","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585330","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585353","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585416","Taluk":"Basavakalyan","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585415","Taluk":"Basavakalyan","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585436","Taluk":"Aurad(b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585329","Taluk":"Humnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585417","Taluk":"Auarad (b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585417","Taluk":"Aurad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585330","Taluk":"Homnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585331","Taluk":"Basavakalyan","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585326","Taluk":"Basavakalyan","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585421","Taluk":"Aurad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585326","Taluk":"Auad (b)","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"585329","Taluk":"Homnabad","Districtname":"Bidar","statename":"KARNATAKA"},
    {"pincode":"586213","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586111","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586117","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586123","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586112","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586211","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586104","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586118","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586201","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586201","Taluk":"Basavana Bagevadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586202","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586129","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586216","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586204","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586127","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586203","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586207","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586217","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586113","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586210","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586128","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586214","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586114","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586125","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586213","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586214","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586116","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586215","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586119","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586203","Taluk":"Basavana Bagevadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586212","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586207","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586103","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586120","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586125","Taluk":"Jamakhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"586108","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586101","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586109","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586102","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586114","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586124","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586206","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586115","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586208","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586208","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586205","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586117","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586113","Taluk":"Jamkhandi","Districtname":"Bagalkot","statename":"KARNATAKA"},
    {"pincode":"586130","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586117","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586215","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586210","Taluk":"Basavana Bagevadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586216","Taluk":"Basavana Bagevadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586210","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586115","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586122","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586124","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586122","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586208","Taluk":"Basavana Bagevadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586209","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586213","Taluk":"B Bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586203","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586121","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586203","Taluk":"Muddebihal","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586216","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586116","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586122","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586120","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586121","Taluk":"Indi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586127","Taluk":"Sindgi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586108","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586112","Taluk":"Bijapur","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586203","Taluk":"b..bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586128","Taluk":"Singi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586130","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"586102","Taluk":"B.bagewadi","Districtname":"Bijapur(KAR)","statename":"KARNATAKA"},
    {"pincode":"591304","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591248","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591215","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591248","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591303","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591212","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591211","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591317","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591220","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591212","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591213","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591241","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591232","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591237","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591230","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591226","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591265","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591263","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591214","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591222","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591222","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591217","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591216","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591201","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591247","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591287","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591238","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591316","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591219","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591311","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591229","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591235","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591242","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591240","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591242","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591223","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591244","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591303","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591234","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591228","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591230","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591311","Taluk":"Raybag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591232","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591234","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591213","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591239","Taluk":"Chikodi","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591317","Taluk":"Raybag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591304","Taluk":"Raibag","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591240","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591315","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591315","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591242","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591304","Taluk":"Athni","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591265","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591316","Taluk":"Athani","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"580028","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581207","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"582208","Taluk":"Navalgund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581103","Taluk":"NA","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581201","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580025","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580024","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"582201","Taluk":"Navalgund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580007","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581113","Taluk":"Kundgol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581196","Taluk":"Kalghatgi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581209","Taluk":"Navalagund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581204","Taluk":"Kalghatgi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580114","Taluk":"Kalghatgi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581195","Taluk":"Kundgol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581103","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581207","Taluk":"Kundgol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581204","Taluk":"Kalghatagi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580023","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581201","Taluk":"Navalgund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580118","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580001","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580011","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580003","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580008","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580006","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580002","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580009","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580005","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580004","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580114","Taluk":"Kalgahtagi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581105","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580030","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581107","Taluk":"Kundgol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580112","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581209","Taluk":"Navalgund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581117","Taluk":"Kundgol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580029","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580020","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580031","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580021","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580026","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580032","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580118","Taluk":"Kalghatgi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581209","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580028","Taluk":"Kundgol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581206","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581196","Taluk":"Kalgahtgi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580114","Taluk":"Dharwad","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581195","Taluk":"Hubli","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581195","Taluk":"Navalgund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580112","Taluk":"Navalgund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581204","Taluk":"Kalaghatgi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580023","Taluk":"Navalgund","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581204","Taluk":"Kalgahtgi","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"580023","Taluk":"Kundgol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"581117","Taluk":"Kungol","Districtname":"Dharwad","statename":"KARNATAKA"},
    {"pincode":"582111","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582116","Taluk":"Shirahatti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583277","Taluk":"Kushtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582103","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583234","Taluk":"Koppal","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583235","Taluk":"Gangavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583226","Taluk":"Koppal","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583227","Taluk":"Gangavathi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582205","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582209","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583230","Taluk":"Koppal","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582203","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582103","Taluk":"Mudargi","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582118","Taluk":"Mundargi","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583238","Taluk":"Koppal","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582102","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582116","Taluk":"Shirhatti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583232","Taluk":"Yelburga","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583278","Taluk":"Kushtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583236","Taluk":"Yelburga","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583281","Taluk":"Kushtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582207","Taluk":"Nargund","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582112","Taluk":"Shirahatti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582113","Taluk":"Mundargi","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583282","Taluk":"Gangavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583235","Taluk":"Ganagavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582204","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582112","Taluk":"Shiratti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582202","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583229","Taluk":"Gamgavatjhi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583237","Taluk":"Yelburga","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583280","Taluk":"Kushtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583229","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583229","Taluk":"Gamgavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583228","Taluk":"Koppal","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582120","Taluk":"Shirhatti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583229","Taluk":"Gangavathi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583283","Taluk":"Gangavathi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583283","Taluk":"Gamgavathi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582114","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582117","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582102","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583268","Taluk":"Gangavahi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582103","Taluk":"Mundargi","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582101","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582114","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583227","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583227","Taluk":"Gangavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583227","Taluk":"Gangawati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583277","Taluk":"Kushtagui","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583282","Taluk":"Gamgavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583282","Taluk":"Gangavathi","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583238","Taluk":"Kioppal","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582119","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582115","Taluk":"Mundargi","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583226","Taluk":"Koppal","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583268","Taluk":"Gangavathi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582115","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583277","Taluk":"Kustagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583268","Taluk":"Gangavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583279","Taluk":"Kushtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583229","Taluk":"Gangavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582211","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583232","Taluk":"Yelbulrga","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582111","Taluk":"Ron","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583283","Taluk":"Gangavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583278","Taluk":"Kukshtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583283","Taluk":"Kushtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583238","Taluk":"Koppal","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583230","Taluk":"Gangavthi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582206","Taluk":"Nargund","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583231","Taluk":"Koppal","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582111","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583277","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582116","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582117","Taluk":"Shirahatti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583230","Taluk":"Yelburga","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583279","Taluk":"Kusthgati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583233","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583283","Taluk":"Gamgavati","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582119","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582207","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583279","Taluk":"Kukshtagi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583268","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583236","Taluk":"Yelbura","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582112","Taluk":"Shirhatti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582209","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583236","Taluk":"NA","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583279","Taluk":"Yelburga","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583227","Taluk":"Gangvathi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583236","Taluk":"Yelbuarga","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582210","Taluk":"Shirhatti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582120","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"582120","Taluk":"Shiratti","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583282","Taluk":"Gangavathi","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"582120","Taluk":"Gadag","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"583237","Taluk":"Yelbulrga","Districtname":"Koppal","statename":"KARNATAKA"},
    {"pincode":"583235","Taluk":"NA","Districtname":"Gadag","statename":"KARNATAKA"},
    {"pincode":"591101","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591225","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591236","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591313","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591218","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591309","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591310","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591221","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591306","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591122","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591305","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591344","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591243","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591233","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591254","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591312","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591224","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591107","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591307","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591308","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591227","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591340","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591231","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591122","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591314","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591136","Taluk":"Gokak","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"591246","Taluk":"Hukeri","Districtname":"Belgaum","statename":"KARNATAKA"},
    {"pincode":"585223","Taluk":"Shahpur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585222","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585301","Taluk":"Afjalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585216","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585223","Taluk":"Shahapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585315","Taluk":"Yadgiri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585314","Taluk":"Aland","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585290","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585321","Taluk":"Yadgir","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585211","Taluk":"Chittapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585202","Taluk":"Yadgiri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585302","Taluk":"Aland","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585325","Taluk":"Jewargi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585316","Taluk":"Aland","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585303","Taluk":"Jewargi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585212","Taluk":"Afjalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585321","Taluk":"Yadgiri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585306","Taluk":"Chincholi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585305","Taluk":"Chincholi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585310","Taluk":"Jewargi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585322","Taluk":"Chittapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585236","Taluk":"Afjalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585104","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585316","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585202","Taluk":"Yadgri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585221","Taluk":"Yadgiri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585309","Taluk":"Jewargi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585319","Taluk":"Shahapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585265","Taluk":"Afzalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585237","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585308","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585317","Taluk":"Chittapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585225","Taluk":"Chittapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585355","Taluk":"Shahapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585265","Taluk":"Afjalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585228","Taluk":"Chitapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585287","Taluk":"Shahpur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585311","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585102","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585304","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585304","Taluk":"Shahapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585312","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585213","Taluk":"Afjalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585214","Taluk":"Yadgiri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585309","Taluk":"Shahapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585367","Taluk":"Alund","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585313","Taluk":"Chincholi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585318","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585307","Taluk":"Chincholi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585265","Taluk":"Asfjalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585320","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585321","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585265","Taluk":"Afjlpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585211","Taluk":"Chitapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585311","Taluk":"Aland","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585220","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585228","Taluk":"Chittapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585287","Taluk":"Shahapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585313","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585324","Taluk":"Chincholi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585323","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585103","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585105","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585101","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585106","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585291","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585212","Taluk":"Afzalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585309","Taluk":"Shahpur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585312","Taluk":"Chittapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585215","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585322","Taluk":"Chincholi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585265","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585217","Taluk":"Afzalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585212","Taluk":"Jewargio","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585212","Taluk":"Jewargi","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585218","Taluk":"Chittapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585217","Taluk":"Afjalpur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585104","Taluk":"Aland","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585221","Taluk":"Yadgri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585218","Taluk":"Chitapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585319","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585210","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585236","Taluk":"NA","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585292","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585317","Taluk":"Sedam","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585214","Taluk":"Yadgir","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585216","Taluk":"Shorpaur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585322","Taluk":"Gulbarga","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585219","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585236","Taluk":"Aland","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585213","Taluk":"Aland","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585102","Taluk":"NA","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585323","Taluk":"Shahapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585229","Taluk":"Chitapur","Districtname":"Gulbarga","statename":"KARNATAKA"},
    {"pincode":"585224","Taluk":"Shorapur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585355","Taluk":"Shahpur","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585201","Taluk":"Yadgir","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585201","Taluk":"Yadgiri","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"585202","Taluk":"Yadgir","Districtname":"Yadgir","statename":"KARNATAKA"},
    {"pincode":"581101","Taluk":"Hangal","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581128","Taluk":"Haveri","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581115","Taluk":"Ranebennur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581102","Taluk":"Hangal","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581110","Taluk":"Haveri","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581109","Taluk":"Hirekerur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581106","Taluk":"Byadgi","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581210","Taluk":"Hirekerur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581203","Taluk":"Hangal","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581208","Taluk":"Ranebennur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581104","Taluk":"Hangal","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581126","Taluk":"Savanur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581120","Taluk":"Byadgi","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581202","Taluk":"Shiggaon","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581205","Taluk":"Shiggaon","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581108","Taluk":"Haveri","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581211","Taluk":"Ranebennur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581128","Taluk":"NA","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581111","Taluk":"Hirekerur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581145","Taluk":"Ranebennur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581118","Taluk":"Savanur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581193","Taluk":"Shiggaon","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581119","Taluk":"Hirekerur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581116","Taluk":"Hirekerur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581148","Taluk":"Haveri","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581205","Taluk":"NA","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581213","Taluk":"Haveri","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581110","Taluk":"Byadgi","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581118","Taluk":"Gadag","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581112","Taluk":"Haveri","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581197","Taluk":"Shiggaon","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581123","Taluk":"Ranebennur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581109","Taluk":"Byadgi","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581208","Taluk":"Ranebennur`","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581115","Taluk":"NA","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581119","Taluk":"Hirekeruru","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581198","Taluk":"NA","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581202","Taluk":"Haveri","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581115","Taluk":"Ranibennur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581126","Taluk":"Shiggaon","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581212","Taluk":"Shiggoan","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581111","Taluk":"Hirekrur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581120","Taluk":"Hangal","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581119","Taluk":"Ranebennur","Districtname":"Haveri","statename":"KARNATAKA"},
    {"pincode":"581119","Taluk":"Harihar","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"581344","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581319","Taluk":"Ankola","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581348","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581319","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581351","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581314","Taluk":"Ankola","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581314","Taluk":"NA","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581354","Taluk":"Bhatkal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581343","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581324","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581350","Taluk":"Bhatkal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581360","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581440","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581342","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581334","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581308","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581317","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581316","Taluk":"Ankola","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581302","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581306","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581423","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581353","Taluk":"Ankola","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581321","Taluk":"Ankola","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581320","Taluk":"Bhatkal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581357","Taluk":"Ankola","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581421","Taluk":"Bhatkal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581333","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581307","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581328","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581323","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581361","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581400","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581352","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581327","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581345","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581384","Taluk":"NA","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581384","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581326","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581328","Taluk":"NA","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581362","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581330","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581332","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581338","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581335","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581396","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581339","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581352","Taluk":"NA","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581341","Taluk":"Honavar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581301","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581305","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581303","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581323","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581361","Taluk":"Kumta","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581304","Taluk":"Karwar","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581333","Taluk":"Bhatkal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"584128","Taluk":"Sindhanur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584123","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584129","Taluk":"Deodurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584140","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584125","Taluk":"Lingasugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584120","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584122","Taluk":"Lingasugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584120","Taluk":"Lingasugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584111","Taluk":"Deodurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584203","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584113","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584104","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584136","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584102","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584138","Taluk":"Sindhanur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584129","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584122","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584116","Taluk":"Deodurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584113","Taluk":"Deodurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584167","Taluk":"Sindhanur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584143","Taluk":"Sindhnur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584128","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584126","Taluk":"Deodurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584134","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584115","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584127","Taluk":"Lingasugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584170","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584139","Taluk":"Lingasugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584135","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584115","Taluk":"Lingasugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584136","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584136","Taluk":"Deodurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584143","Taluk":"Sindhanur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584124","Taluk":"Sindhanur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584132","Taluk":"Sindhanur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584202","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584118","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584143","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584115","Taluk":"Lingsugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584111","Taluk":"Devadurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584202","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584133","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584125","Taluk":"Lingsugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584122","Taluk":"Lingsugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584103","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584124","Taluk":"Lingasugur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584101","Taluk":"Raichur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584128","Taluk":"Sindhnur","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584138","Taluk":"Manvi","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"584115","Taluk":"Deodurga","Districtname":"Raichur","statename":"KARNATAKA"},
    {"pincode":"581340","Taluk":"Siddapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581355","Taluk":"Siddapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581340","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581453","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581325","Taluk":"Haliyal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581322","Taluk":"Siddapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581363","Taluk":"Haliyal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581315","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581329","Taluk":"Haliyal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581358","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581349","Taluk":"Mundgod","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581359","Taluk":"Yellapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581453","Taluk":"Joida","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581187","Taluk":"Joida","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581356","Taluk":"Haliyal","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581337","Taluk":"Yellapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581402","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581129","Taluk":"Joida","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581365","Taluk":"Joida","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581336","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581331","Taluk":"Siddapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581359","Taluk":"Yellpaur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581318","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581403","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581121","Taluk":"Supa","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581346","Taluk":"Mundgod","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581359","Taluk":"Yellpur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581401","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581186","Taluk":"Joida","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581347","Taluk":"Yellapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581365","Taluk":"Supa","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581450","Taluk":"Siddapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581412","Taluk":"Yellapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581186","Taluk":"Supa","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581347","Taluk":"Yellpur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581355","Taluk":"Sidddapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581187","Taluk":"Supa","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581411","Taluk":"Mundgod","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581450","Taluk":"Sirsi","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581450","Taluk":"Sidddapur","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"581453","Taluk":"Supa","Districtname":"Uttara Kannada","statename":"KARNATAKA"},
    {"pincode":"577126","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577126","Taluk":"Sringeri","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577139","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577547","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577123","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577548","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577111","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577101","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577228","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577182","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577138","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577547","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577550","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577131","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577134","Taluk":"Narasimharajapura","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577179","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577112","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577116","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577122","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577113","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577112","Taluk":"Narasimharajapura","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577135","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577144","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577114","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577132","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577120","Taluk":"Sringeri","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577146","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577127","Taluk":"Narasimharajapura","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577136","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577120","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577130","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577145","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577117","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577549","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577140","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577102","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577180","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577160","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577139","Taluk":"Srineri","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577175","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577118","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577139","Taluk":"Sringeri","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577124","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577160","Taluk":"Narasimharajapura","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577133","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577121","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577132","Taluk":"Mudigee","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577121","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577168","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577181","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577160","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577228","Taluk":"Tarikee","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577179","Taluk":"Mudigere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577129","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577125","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577550","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577127","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577116","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577142","Taluk":"Koppa","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577128","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577168","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577146","Taluk":"Kadur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577137","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577549","Taluk":"Chikmagalur","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577137","Taluk":"Tarikere","Districtname":"Chickmagalur","statename":"KARNATAKA"},
    {"pincode":"577536","Taluk":"Challakere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577546","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577518","Taluk":"NA","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577526","Taluk":"Holalkere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577511","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577558","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577541","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577597","Taluk":"Hosadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577512","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577598","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577601","Taluk":"Harihar","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577545","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577557","Taluk":"NA","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577534","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577528","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577556","Taluk":"Davanangere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577512","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577555","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577554","Taluk":"Hosadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577527","Taluk":"Hoadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577515","Taluk":"Hosadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577513","Taluk":"Jagalur","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577514","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577527","Taluk":"Hosadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577003","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577589","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577529","Taluk":"Molakalmuru","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577522","Taluk":"NA","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577525","Taluk":"Davangere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577517","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577516","Taluk":"Harihara","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577005","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577001","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577002","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577513","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577599","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577522","Taluk":"Challakere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577597","Taluk":"Hasadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577002","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577601","Taluk":"Harihara","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577519","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577520","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577521","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577553","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577518","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577543","Taluk":"Challakere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577533","Taluk":"Hosadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577532","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577535","Taluk":"Molakalmuru","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577004","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577553","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577538","Taluk":"Challakere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577523","Taluk":"Holalkere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577502","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577523","Taluk":"NA","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577501","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577501","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577537","Taluk":"Challakere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577540","Taluk":"Molakalmuru","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577524","Taluk":"Chitradurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577001","Taluk":"Davangere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577006","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577596","Taluk":"Hiriyur","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577566","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577531","Taluk":"Holalkere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577557","Taluk":"Holalkere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577539","Taluk":"Holalkere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577542","Taluk":"Hosadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577530","Taluk":"Harihara","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577557","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577525","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577556","Taluk":"NA","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577529","Taluk":"Challakere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577566","Taluk":"Harihra","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577528","Taluk":"Jagalur","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577516","Taluk":"Harihra","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577542","Taluk":"Hoadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577527","Taluk":"Holalkere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577515","Taluk":"Hodadurga","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"577534","Taluk":"Davanagere","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577601","Taluk":"Hariahra","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577518","Taluk":"Holalkere","Districtname":"Chitradurga","statename":"KARNATAKA"},
    {"pincode":"573214","Taluk":"Alur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573102","Taluk":"Arkalgud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573116","Taluk":"Channarayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573121","Taluk":"Belur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573103","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573128","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573133","Taluk":"Arkalgud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573131","Taluk":"Channarayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573225","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573213","Taluk":"Alur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573103","Taluk":"Arskiere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573212","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573150","Taluk":"Arkalud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573216","Taluk":"Belur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573210","Taluk":"Holenarasipura","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573134","Taluk":"Sakleshpur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573120","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573215","Taluk":"Belur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573122","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573202","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573101","Taluk":"Belur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573125","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573112","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573118","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573123","Taluk":"Sakleshpur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573214","Taluk":"Sakleshpur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573162","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573211","Taluk":"Holenarasipur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573164","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573111","Taluk":"Channarayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573124","Taluk":"Channarayaptna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573111","Taluk":"Channarayaptna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573225","Taluk":"NA","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573115","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573130","Taluk":"Arkalgud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573115","Taluk":"Belur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573225","Taluk":"Channarayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573135","Taluk":"Channarayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573136","Taluk":"Arkalgud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573113","Taluk":"Arkalgud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573135","Taluk":"Channaryapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573124","Taluk":"Channarayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573214","Taluk":"NA","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573115","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573129","Taluk":"Alur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573220","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573134","Taluk":"Saklehspur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573127","Taluk":"Sakleshpur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573217","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573218","Taluk":"Alur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573137","Taluk":"Sakleshpura","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573201","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573135","Taluk":"Channrayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573165","Taluk":"Skaleshpur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573141","Taluk":"Channarayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573210","Taluk":"Holenarasipur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573211","Taluk":"Holeanarasipur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573137","Taluk":"Sakleshpur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573142","Taluk":"Arkalgud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573117","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573119","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573116","Taluk":"Channrayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573165","Taluk":"Sakleshpur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573219","Taluk":"Hassan","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573210","Taluk":"Holenarsipur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573211","Taluk":"Holeanrasipur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573144","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573126","Taluk":"Arsikere","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573211","Taluk":"Holenarsipur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573165","Taluk":"Saklehspur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573137","Taluk":"Saklehspur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573117","Taluk":"Arsiekre","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573111","Taluk":"Channarayapatana","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573136","Taluk":"Arakalagud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573130","Taluk":"Arakalagud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573128","Taluk":"Alur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573150","Taluk":"Arkalgud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573117","Taluk":"Arsikre","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573127","Taluk":"Belur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573150","Taluk":"Arkalagud","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573124","Taluk":"Channrayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573131","Taluk":"Channrayapatna","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"573127","Taluk":"Saklehspur","Districtname":"Hassan","statename":"KARNATAKA"},
    {"pincode":"571236","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571253","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571251","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571235","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571211","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571237","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571218","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571212","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571201","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571213","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571215","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571217","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571249","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571234","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571236","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571252","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571219","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571214","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571216","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571231","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571254","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571247","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571218","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571248","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571212","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571213","Taluk":"NA","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571236","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571235","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571237","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571234","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571251","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571232","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571234","Taluk":"Somarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571248","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571252","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571250","Taluk":"Virajpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571232","Taluk":"Somvarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"571231","Taluk":"Somwarpet","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"562105","Taluk":"Sidlghatta","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563161","Taluk":"Srinivaspur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562103","Taluk":"Chik Ballapur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563138","Taluk":"Srinivaspur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562101","Taluk":"Chickballapur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"561213","Taluk":"Gauribidanure","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563160","Taluk":"Malur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561213","Taluk":"Gauribidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563132","Taluk":"Mulbagal","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563128","Taluk":"Chintamani","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563113","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562105","Taluk":"Sidlaghatta","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563131","Taluk":"Mulbagal","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563125","Taluk":"Chintamani","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563114","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563133","Taluk":"Kolar","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563130","Taluk":"Malur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563102","Taluk":"Kolar","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562104","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563127","Taluk":"Mulbagal","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561207","Taluk":"Bagepalli","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561211","Taluk":"Gauribidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563129","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563139","Taluk":"Malur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563134","Taluk":"Srinivaspur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563114","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562105","Taluk":"Sidlaghatta","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563129","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563123","Taluk":"Chintamani","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561209","Taluk":"Gudibanda","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563115","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563116","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562102","Taluk":"Sidlaghatta","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563124","Taluk":"Bagepalli","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561211","Taluk":"Gauribidanur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563159","Taluk":"Chintamani","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561208","Taluk":"Gauribidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563126","Taluk":"Srinivasapura","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563103","Taluk":"Kolar","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563117","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561208","Taluk":"Gauribidanur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"562102","Taluk":"Sidlaghatta","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563124","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561207","Taluk":"Bagepalli","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"562104","Taluk":"Chik Ballapur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563125","Taluk":"Chintamani","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"561209","Taluk":"Gauribidanur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563118","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563126","Taluk":"Kolar","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563162","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563138","Taluk":"Srinivasapura","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561206","Taluk":"Gauribidanur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563120","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562103","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563120","Taluk":"Bngarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563159","Taluk":"Chintamani","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563136","Taluk":"Mulbagal","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561213","Taluk":"Gauribidanur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563122","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562101","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563125","Taluk":"Sidlaghatta","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563116","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563121","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561211","Taluk":"Gaurobidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563146","Taluk":"Chintamani","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561209","Taluk":"Gudibanda","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563126","Taluk":"Srinivsapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561228","Taluk":"NA","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563137","Taluk":"Malur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561210","Taluk":"Gauribidanur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"561210","Taluk":"Gauribidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563126","Taluk":"Srinivaspur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561210","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563114","Taluk":"Bangarpet Tq","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563135","Taluk":"Srinivasapura","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561228","Taluk":"Gauribidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561212","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563146","Taluk":"Chintamanij","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563135","Taluk":"Srinivaspur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562101","Taluk":"Chik Ballapur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563113","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563123","Taluk":"Chintamani","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563101","Taluk":"Kolar","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561211","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563119","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"562102","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563132","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561228","Taluk":"Guribidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561206","Taluk":"Gauribidanur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561212","Taluk":"Bagepalli","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563120","Taluk":"Bangarapet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563124","Taluk":"Bagepalli","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563122","Taluk":"Bangarpet","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563121","Taluk":"Bagepalli","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563146","Taluk":"Mulbagal","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561213","Taluk":"Gauribidnur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561209","Taluk":"Chik Ballapur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"562104","Taluk":"Sidlaghatta","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"563138","Taluk":"Chintamani","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"561228","Taluk":"Gauribidanur","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"561213","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563130","Taluk":"Kolar","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561228","Taluk":"Chickballapur","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"561208","Taluk":"NA","Districtname":"Kolar","statename":"KARNATAKA"},
    {"pincode":"563146","Taluk":"Chintamani","Districtname":"Chikkaballapur","statename":"KARNATAKA"},
    {"pincode":"571425","Taluk":"Maddur","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571404","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571475","Taluk":"Malavalli","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571436","Taluk":"K.r.pete","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571426","Taluk":"K.r.pete","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571605","Taluk":"K.r.pete","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571807","Taluk":"Srirangapatna","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571448","Taluk":"Nagamangala","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571434","Taluk":"Pandavapura","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571433","Taluk":"Maddur","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571424","Taluk":"Malavalli","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571423","Taluk":"K.r.pete","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571415","Taluk":"Srirangapatna","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571427","Taluk":"Pandavapura","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571418","Taluk":"Nagamangala","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571812","Taluk":"K.r.pete","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571431","Taluk":"Pandavapura","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571807","Taluk":"Krishnarajpet","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571416","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571432","Taluk":"Nagamangala","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571606","Taluk":"Srirangapatna","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571417","Taluk":"Malavalli","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571419","Taluk":"Maddur","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571478","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571405","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571422","Taluk":"Maddur","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571445","Taluk":"Nagamangala","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571802","Taluk":"Nagamangala","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571463","Taluk":"Malavalli","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571812","Taluk":"Pandavapura","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571421","Taluk":"Malavalli","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571429","Taluk":"Maddur","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571446","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571403","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571455","Taluk":"Pandavapura","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571430","Taluk":"Malavalli","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571423","Taluk":"Krishnarajpet","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571811","Taluk":"Nagamangala","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571807","Taluk":"Shrirangapattana","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571476","Taluk":"Maddur","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571477","Taluk":"Srirangapatna","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571428","Taluk":"Maddur","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571402","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571450","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571436","Taluk":"K.e.pete","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571807","Taluk":"Sriranagapatna","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571435","Taluk":"Pandavapura","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571607","Taluk":"Shrirangapattana","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571401","Taluk":"Mandya","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571427","Taluk":"Srirangapatna","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571436","Taluk":"Krishnarajpet","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571605","Taluk":"Krishnarajpet","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571438","Taluk":"Srirangapatna","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571415","Taluk":"Shrirangapattana","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"571436","Taluk":"Nagamangala","Districtname":"Mandya","statename":"KARNATAKA"},
    {"pincode":"574145","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574142","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575007","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574141","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575017","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575006","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574199","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574154","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574267","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575011","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575030","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574153","Taluk":"Bantwal","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575002","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"576111","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"575004","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575008","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"576121","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574153","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575003","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575029","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574146","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575018","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574150","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575001","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574143","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574144","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574146","Taluk":"NA","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574144","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574151","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574148","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575015","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575023","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574143","Taluk":"Bantwal","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575022","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575014","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575028","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575019","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575005","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575013","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575010","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575018","Taluk":"Bantwal","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574509","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574199","Taluk":"Bantwal","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575016","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575025","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"575020","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"570026","Taluk":"H D Kote","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570028","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571107","Taluk":"Periyapatna","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571102","Taluk":"Periyapatna","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571130","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571604","Taluk":"K R Nagar","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571617","Taluk":"K R Nagar","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571114","Taluk":"H D Kote","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571108","Taluk":"Periyapatna","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571601","Taluk":"K R Nagar","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571189","Taluk":"Hunsur","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571311","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571105","Taluk":"Hunsur","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570015","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571602","Taluk":"K R Nagar","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571116","Taluk":"H D Kote","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571121","Taluk":"H D Kote","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570018","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571103","Taluk":"Hunsur","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570026","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570020","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571104","Taluk":"Periyapatna","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571125","Taluk":"H D Kote","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570010","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570004","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571610","Taluk":"Hunsur","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571107","Taluk":"Piriyapatna","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570008","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571106","Taluk":"Hunsur","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571187","Taluk":"Periyapatna","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570025","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571603","Taluk":"K R Nagar","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570002","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571134","Taluk":"Hunsur","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570016","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570027","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570031","Taluk":"NA","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570012","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570014","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571108","Taluk":"Piriyapatna","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570001","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570023","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571116","Taluk":"Heggadadevankote","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570006","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570005","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570003","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570007","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570022","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570019","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570009","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570029","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570011","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570030","Taluk":"NA","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"570017","Taluk":"Mysore","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571442","Taluk":"Yelandur","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571439","Taluk":"Kollegal","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571119","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571111","Taluk":"Gundlupet","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571117","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571127","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571441","Taluk":"Yelandur","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571111","Taluk":"Gundluper","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571128","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571101","Taluk":"T Narasipura","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571313","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571312","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571316","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571457","Taluk":"Kollegal","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571123","Taluk":"Gundlupet","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571124","Taluk":"T Narasipura","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571126","Taluk":"Gundlupet","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571101","Taluk":"Kollegal","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571109","Taluk":"Gundlupet","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571115","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571129","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571110","Taluk":"T Narasipura","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571302","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571313","Taluk":"Chamarajanagar","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571342","Taluk":"Chamarajanagar","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571342","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571314","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571443","Taluk":"Kollegal","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571120","Taluk":"T Narasipura","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571440","Taluk":"Kollegal","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571320","Taluk":"Kollegal","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571118","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571315","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571444","Taluk":"Kollegal","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571490","Taluk":"Kollegal","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571439","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571128","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571122","Taluk":"T Narasipura","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571443","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571110","Taluk":"T Naraipura","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571110","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571440","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571490","Taluk":"Chamarajanagara","Districtname":"Chamrajnagar","statename":"KARNATAKA"},
    {"pincode":"571110","Taluk":"NA","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"571301","Taluk":"Nanjangud","Districtname":"Mysore","statename":"KARNATAKA"},
    {"pincode":"574260","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574211","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574239","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574101","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574324","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574227","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574285","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574217","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574235","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574219","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574253","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574242","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574237","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574314","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574314","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574198","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574223","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574210","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"576117","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574233","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574102","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574241","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574122","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574234","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"574212","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574221","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574232","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574218","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574274","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574326","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574214","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574203","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574323","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574129","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574240","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"576112","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574213","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574259","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574238","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574325","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574243","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574328","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574110","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574222","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574248","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574201","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574107","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574228","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574202","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574238","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574216","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574248","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574110","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574239","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"576117","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574108","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574228","Taluk":"Beltangadi","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574229","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574234","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574217","Taluk":"Beltangadi","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574104","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574220","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574109","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574241","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574114","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574313","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574220","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574265","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574226","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574230","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574197","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574218","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574223","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574129","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574279","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574232","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574104","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574237","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574236","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574243","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574325","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574241","Taluk":"Puttur(dk)","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574242","Taluk":"Belthagady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574212","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574244","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574327","Taluk":"Sulya","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574109","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574224","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574122","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574236","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574225","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574107","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574328","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574231","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574107","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574228","Taluk":"Belthagady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574244","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574240","Taluk":"Beltangadi","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574314","Taluk":"Madikeri","Districtname":"Kodagu","statename":"KARNATAKA"},
    {"pincode":"574197","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574212","Taluk":"Puttur","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574237","Taluk":"Mangalore","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574233","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574229","Taluk":"Belthangady","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574216","Taluk":"Beltangadi","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574234","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"576112","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574238","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574326","Taluk":"Beltangadi","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574326","Taluk":"Bantval","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574108","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574221","Taluk":"Sullia","Districtname":"Dakshina Kannada","statename":"KARNATAKA"},
    {"pincode":"574101","Taluk":"Karkal","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"577204","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577412","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577213","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577227","Taluk":"Bhadravathi","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577413","Taluk":"Sorab","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577428","Taluk":"Shikarpur","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577411","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577427","Taluk":"Shikarpur","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577453","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577214","Taluk":"Shikarpur","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577243","Taluk":"Bhadravathi","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577245","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577427","Taluk":"Shikaripura","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577217","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577233","Taluk":"Bhadravathi","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577233","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577421","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577414","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577552","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577426","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577223","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577211","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577227","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577434","Taluk":"Sorab","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577423","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577301","Taluk":"Bhadravathii","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577219","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577431","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577432","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577551","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577224","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577226","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577232","Taluk":"Thirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577231","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577401","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577224","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577429","Taluk":"Sorab","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577301","Taluk":"Bhadravathi","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577115","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577301","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577302","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577418","Taluk":"Hosanagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577417","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577222","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577436","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577428","Taluk":"Sorab","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577216","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577221","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577416","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577218","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577225","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577215","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577230","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577422","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577415","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577544","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577302","Taluk":"Bhadravathi","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577229","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577419","Taluk":"Sorab","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577202","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577213","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577216","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577205","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577202","Taluk":"NA","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577220","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577243","Taluk":"Honnali","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577418","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577412","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577433","Taluk":"Shikarpur","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577214","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577452","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577436","Taluk":"Hosanagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577432","Taluk":"Thirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577430","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577227","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577448","Taluk":"Hosanagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577436","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577424","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577201","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577435","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577436","Taluk":"Sorab","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577203","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577219","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577424","Taluk":"Thirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577415","Taluk":"Thirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577229","Taluk":"Bhadravathi","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577422","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577421","Taluk":"NA","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577225","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577227","Taluk":"Shimogai","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577232","Taluk":"Tirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577451","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577434","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577425","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577220","Taluk":"Thirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577425","Taluk":"Hosanagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577413","Taluk":"Soraba","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577427","Taluk":"Channagiri","Districtname":"Davangere","statename":"KARNATAKA"},
    {"pincode":"577243","Taluk":"Bhadravati","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577452","Taluk":"Hosanagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577414","Taluk":"Thirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577417","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577426","Taluk":"Hosanagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577425","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577432","Taluk":"Thrithahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577422","Taluk":"Thirthahalli","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577245","Taluk":"Bhadravaathi","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577401","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577433","Taluk":"Sorab","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577433","Taluk":"Sagar","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577115","Taluk":"Shimoga","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"577448","Taluk":"Hosanagara","Districtname":"Shimoga","statename":"KARNATAKA"},
    {"pincode":"572129","Taluk":"Koratagere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572216","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572223","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572220","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572222","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572224","Taluk":"Tiptur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572211","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572111","Taluk":"Kunigal","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572212","Taluk":"Turuvekere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572213","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572117","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572140","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572106","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572130","Taluk":"Kunigal","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572212","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572227","Taluk":"Turuvekere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572121","Taluk":"Koratagere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572116","Taluk":"Pavagada","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572118","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572225","Taluk":"Turuvekere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572136","Taluk":"Pavagada","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572175","Taluk":"Madhugiri","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572113","Taluk":"Sira","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572112","Taluk":"Madhugiri","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572217","Taluk":"Tiptur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572137","Taluk":"Sira","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572123","Taluk":"Kunigal","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572119","Taluk":"C.n.hally","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572228","Taluk":"C.n.halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572214","Taluk":"Chikkanayakanahlli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572133","Taluk":"Madhugiri","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572215","Taluk":"Turuvekere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572114","Taluk":"Tiptur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572201","Taluk":"Tiptur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572104","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572219","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572218","Taluk":"C.n.halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572107","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572126","Taluk":"Kunigal","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572135","Taluk":"Sira","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572120","Taluk":"Kunigal","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572125","Taluk":"Sira","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572132","Taluk":"Madhugiri","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"561202","Taluk":"Pavagada","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572124","Taluk":"Madhugiri","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572128","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572129","Taluk":"Korategere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572115","Taluk":"Sira","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572127","Taluk":"Madhugiri","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572141","Taluk":"Pavagada","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572214","Taluk":"Chiknayakanhalli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572138","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572221","Taluk":"Turuvekere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572120","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572142","Taluk":"Kunigal","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572119","Taluk":"C.n.halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572226","Taluk":"C.n.hally","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572139","Taluk":"Sira","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572102","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572214","Taluk":"C.n.halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572215","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572168","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572214","Taluk":"C.n.hally","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572122","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572218","Taluk":"C.n. Halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572134","Taluk":"Kunigal","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572138","Taluk":"Koratagere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572228","Taluk":"C N Halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572103","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572105","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572225","Taluk":"Gubbi","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572226","Taluk":"C.n.halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572226","Taluk":"C N Halli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572226","Taluk":"Chiknayakanhalli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572224","Taluk":"Tiptir","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572218","Taluk":"Chiknayakanhalli","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572101","Taluk":"Tumkur","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"572211","Taluk":"Turuvekere","Districtname":"Tumkur","statename":"KARNATAKA"},
    {"pincode":"576225","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576101","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574119","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576226","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576283","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574118","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576233","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576227","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576103","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576211","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576212","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576124","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576213","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576222","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576107","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576223","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574103","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574117","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576224","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576115","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576234","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576210","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576113","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576221","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574115","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576232","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576214","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576215","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576230","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576104","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576102","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576201","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576216","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576247","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576235","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574113","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576210","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576229","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576217","Taluk":"Kundpaura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576219","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576212","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576282","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576218","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576122","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576222","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574106","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576114","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574105","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576120","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576231","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576105","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576217","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574116","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576220","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576221","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576106","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576108","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576257","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576201","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574111","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574112","Taluk":"Udupi","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576224","Taluk":"Navunda","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"576228","Taluk":"Kundapura","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"574116","Taluk":"Karkala","Districtname":"Udupi","statename":"KARNATAKA"},
    {"pincode":"673575","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673586","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673602","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673579","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673593","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673603","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673028","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673596","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673572","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673632","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673592","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673015","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673018","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673032","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673004","Taluk":"NA","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673020","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673001","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673008","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673002","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673301","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673573","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673601","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673595","Taluk":"Sulthan Batheri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673616","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673571","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673581","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673577","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673580","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673122","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673661","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673655","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673017","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673123","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673005","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673303","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673006","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673585","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673632","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673631","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673016","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673014","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673570","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673581","Taluk":"Sulthan Batheri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673302","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673122","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673604","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673611","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673613","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673003","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673582","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673121","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673122","Taluk":"Vythiti","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673591","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673010","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673592","Taluk":"Sulthan Batheri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673007","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673591","Taluk":"Sulthan Batheri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673576","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673009","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673328","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673012","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673591","Taluk":"Sulthan Bathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673577","Taluk":"Sulthan Bathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673595","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673011","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673027","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673577","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673019","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673579","Taluk":"Sulthan Batheri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673596","Taluk":"Sulthan Batheri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673633","Taluk":"Eranad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673579","Taluk":"Sulthan Bathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673021","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673004","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673633","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673592","Taluk":"Sulthan Bathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673029","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673577","Taluk":"Sulthan Batheri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673581","Taluk":"Sulthan Bathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"673633","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673303","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"670571","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670008","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670331","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670612","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670307","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670582","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670143","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670561","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670006","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670009","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670622","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670013","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670621","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670014","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670307","Taluk":"NA","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670633","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670592","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670604","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670631","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670632","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670581","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670301","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670301","Taluk":"NA","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670601","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670511","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670011","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670502","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670142","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670002","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670353","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670591","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670327","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670595","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670306","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670308","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670521","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670309","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670310","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670334","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670613","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670593","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670504","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670511","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"670306","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670005","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670562","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670602","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670592","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670604","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670012","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670003","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670017","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670001","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670567","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670562","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670007","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670592","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670309","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670304","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670611","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670501","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670325","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670302","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670331","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670594","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670612","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670563","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670601","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670305","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670004","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670503","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670358","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670303","Taluk":"Taliaparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670303","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670141","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670018","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670010","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670305","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"671351","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671531","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671121","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671543","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671123","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671322","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671323","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671321","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671551","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671533","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671552","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671541","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671319","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671348","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671124","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671316","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671318","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671543","Taluk":"NA","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671314","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671317","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671532","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671313","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671326","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671324","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671310","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671311","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671542","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671315","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671312","Taluk":"Hosdurg","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"671122","Taluk":"Kasaragod","Districtname":"Kasargod","statename":"KERALA"},
    {"pincode":"676525","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673641","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679357","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679332","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679339","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676123","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679322","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676509","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673637","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679321","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673639","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673638","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679341","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679329","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679334","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673647","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673635","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676541","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679328","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676504","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679323","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673634","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676521","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679327","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679325","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673636","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679340","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673645","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676519","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679331","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679326","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679326","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679340","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676122","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676523","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679324","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679333","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673638","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679330","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673640","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676123","Taluk":"Manjeri","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679325","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676504","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676522","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679338","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676506","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679322","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679338","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676507","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676505","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676542","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679357","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676121","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679324","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679341","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676517","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673642","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676519","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676506","Taluk":"Perinthalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679327","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676123","Taluk":"Nilambur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679339","Taluk":"Ernad","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679323","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679321","Taluk":"Perintalmanna","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679503","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678581","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679302","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678601","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678633","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679303","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679512","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679501","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679551","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679552","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678583","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679513","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678633","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679505","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679536","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679535","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678762","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679535","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679304","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679501","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679521","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679522","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679336","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679305","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679512","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678582","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679511","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679337","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678595","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679308","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679335","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679302","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679123","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678591","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679335","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678581","Taluk":"Mannarkkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679515","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678582","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678596","Taluk":"Mannarkkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679313","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679122","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679514","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678583","Taluk":"Mannarkkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678596","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679104","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679552","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678595","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679506","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679305","Taluk":"Ottapalam.","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678597","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679513","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679504","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679514","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679523","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679122","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679307","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678632","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679533","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679534","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678583","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679554","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679553","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678633","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679336","Taluk":"Ottpalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679301","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679534","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679554","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679521","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678582","Taluk":"Mannarkkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679306","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679307","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679501","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679534","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678597","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678593","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679511","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679502","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679308","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679101","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678591","Taluk":"Mannarkkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679103","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678583","Taluk":"Mannarkad Q","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679522","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679121","Taluk":"Ottpalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679303","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678598","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679303","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678581","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679121","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678593","Taluk":"Mannarkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679536","Taluk":"Ottapalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679337","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679533","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679506","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679514","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679102","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678601","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679502","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678632","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679121","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678595","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679516","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679309","Taluk":"Ottappalam","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"679309","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678593","Taluk":"NA","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678008","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678506","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678541","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678011","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678651","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678682","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678554","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678544","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678556","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678683","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678510","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678508","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678007","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678624","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678611","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678573","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678102","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678703","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678631","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678572","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678721","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678702","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678706","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678704","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678104","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678101","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678101","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678005","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678574","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678009","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678556","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678622","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678684","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678622","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678546","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678555","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678502","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678507","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678510","Taluk":"Alatur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678512","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678006","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678613","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678003","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678621","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678623","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678701","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678686","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678534","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678551","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678532","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678503","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678542","Taluk":"Alatur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678012","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678543","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678641","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678642","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678612","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678004","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678501","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678501","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678681","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678732","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678702","Taluk":"Alatur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678555","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678557","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678688","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678013","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678687","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678502","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678685","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678571","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678533","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678507","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678705","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678592","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678594","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678553","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678722","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678002","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678014","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678001","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678731","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678505","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678552","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678661","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678532","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678542","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678531","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678545","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678010","Taluk":"Palakkad","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678571","Taluk":"Alatur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678103","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678671","Taluk":"Alathur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678504","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678502","Taluk":"Alatur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"678552","Taluk":"Chittur","Districtname":"Palakkad","statename":"KERALA"},
    {"pincode":"670650","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670644","Taluk":"Mananthavady","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670651","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670701","Taluk":"Thalasseery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670706","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670672","Taluk":"NA","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670645","Taluk":"Mananthavady","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670705","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670704","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670673","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670721","Taluk":"Mananthavady","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670674","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670643","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670646","Taluk":"Mananthavady","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670102","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670694","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670702","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670692","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670104","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670693","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670106","Taluk":"Thalasery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670107","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670642","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670663","Taluk":"Kannur","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670703","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670676","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670703","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"673316","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670731","Taluk":"Mananthavady","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670649","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670721","Taluk":"Sulthanbathery","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670675","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670706","Taluk":"Taliparamba","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670691","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670701","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670741","Taluk":"Thalasery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670675","Taluk":"NA","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670661","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670103","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670645","Taluk":"Vythiri","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670662","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670105","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"673311","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"673313","Taluk":"NA","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670671","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670741","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"673312","Taluk":"NA","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670641","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670672","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670101","Taluk":"Thalassery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"670731","Taluk":"Manantahvady","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670646","Taluk":"Manantahvady","Districtname":"Wayanad","statename":"KERALA"},
    {"pincode":"670702","Taluk":"Thalasery","Districtname":"Kannur","statename":"KERALA"},
    {"pincode":"676305","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679585","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676102","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676301","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676303","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676312","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679573","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676106","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679576","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679580","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679576","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676503","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676561","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676306","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676304","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676510","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679575","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676319","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676508","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676108","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676501","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676552","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679587","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679579","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679572","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676103","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679573","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673314","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676553","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679582","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679586","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679582","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676551","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679584","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679571","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679584","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676307","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676501","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676309","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679591","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676562","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676105","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679581","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676528","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679574","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676311","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679578","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676320","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679575","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676109","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676320","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676528","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676302","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676317","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676502","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679580","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679577","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679583","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679586","Taluk":"Ponnani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676107","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676503","Taluk":"Tirurangadi","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676510","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676101","Taluk":"Tirur","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"676104","Taluk":"NA","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679578","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"679579","Taluk":"Ponani","Districtname":"Malappuram","statename":"KERALA"},
    {"pincode":"673517","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673508","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673513","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673317","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673620","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673507","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673315","Taluk":"Koyilani","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673528","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673524","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673614","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673541","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673521","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673309","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673612","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673526","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673315","Taluk":"Kozhikode","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673509","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673506","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673306","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673304","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673104","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673528","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673525","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673527","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673524","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673529","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673308","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673106","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673502","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673306","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673525","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673574","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673574","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673501","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673521","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673505","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673523","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673504","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673620","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673615","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673102","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673304","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673542","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673612","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673614","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673529","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673307","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673522","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673315","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673527","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673503","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673305","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673310","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673105","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673523","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673307","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673523","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673508","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673310","Taluk":"Mahe Ut","Districtname":"Mahe","statename":"PONDICHERRY"},
    {"pincode":"673508","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673522","Taluk":"Quilandy","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673310","Taluk":"Ut Of Pondichery","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"673526","Taluk":"Koyilandi","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673103","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"673101","Taluk":"Vadakara","Districtname":"Kozhikode","statename":"KERALA"},
    {"pincode":"688012","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688001","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688011","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688005","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688011","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688007","Taluk":"Ambalapuzh A","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688561","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688561","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688531","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688535","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688534","Taluk":"Cherthla","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688530","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688538","Taluk":"Mararikulam","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688006","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688505","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688537","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688502","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688501","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688506","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688524","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688539","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688562","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688529","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688522","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688582","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688007","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688504","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688527","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688541","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688006","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688533","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688526","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688538","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688523","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688503","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688525","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688540","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688521","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688009","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688570","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688522","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688014","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688004","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688003","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688013","Taluk":"Ambalappuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688002","Taluk":"Ambalapuha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688008","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688532","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688528","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688555","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688002","Taluk":"Ambalapuzha","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688536","Taluk":"Cherthala","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"688005","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"683544","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683541","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686692","Taluk":"Kothamangalam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683579","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683511","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683556","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683101","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683581","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686667","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683572","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683573","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686672","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683545","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683549","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683585","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686670","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686668","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683577","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686681","Taluk":"Kothamangalam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683502","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686671","Taluk":"Kothamangalam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683512","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683578","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683514","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686691","Taluk":"Kothamangalam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683546","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683571","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683102","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683561","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686662","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686665","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683594","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683572","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686673","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683112","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683513","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683516","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682311","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683519","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686664","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683574","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683575","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683576","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683562","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686663","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683111","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683518","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686666","Taluk":"Kothamangalam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683565","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683522","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682316","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683579","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683587","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683561","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686693","Taluk":"Kothamangalam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683520","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683105","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686669","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683589","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686669","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683547","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683110","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686661","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683563","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683550","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683515","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683542","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683543","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683580","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683106","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683108","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683105","Taluk":"Aluva","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683521","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683517","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"686544","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686546","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686508","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686503","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686501","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686517","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686540","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686101","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686101","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686106","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686533","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686510","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"686508","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686520","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686585","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686506","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686543","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686531","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686519","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686512","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686555","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686534","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"686510","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686512","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686541","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686514","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686543","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686539","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686509","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686102","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686535","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686508","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686509","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686515","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686541","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686507","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686506","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686544","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686513","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686540","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686102","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"686522","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686502","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686514","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686518","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686105","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686103","Taluk":"Kuttanadu","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"686511","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"686513","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"686532","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686104","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686536","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686546","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686542","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686514","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"686513","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686548","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686538","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686542","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686545","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686518","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686537","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686521","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686510","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686522","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686103","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686532","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686505","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686539","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686105","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686538","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686103","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686504","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686504","Taluk":"Changanacherry","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686510","Taluk":"Kanjirapally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686104","Taluk":"Changanassery","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"682041","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682303","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682302","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682315","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682008","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682313","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682501","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682508","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682308","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682033","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682034","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682315","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682027","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682312","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682037","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682010","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682024","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682502","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682026","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682503","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682035","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682011","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682031","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682016","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682018","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682306","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682308","Taluk":"Muvattupuzha","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683503","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682042","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682309","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682020","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682305","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682313","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682030","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683104","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682017","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682310","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682001","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682004","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682301","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682022","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682305","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682506","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682007","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683501","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682312","Taluk":"Kunnathunad","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682511","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"683501","Taluk":"Parur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682304","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682029","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682002","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682308","Taluk":"Kunnathunadu","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682314","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682504","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682507","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682307","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682505","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682509","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682040","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682009","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682012","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682314","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682025","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682006","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682036","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682506","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682310","Taluk":"Kunnathunadu","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682015","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682038","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682039","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682032","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682013","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682305","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682005","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682021","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682307","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682021","Taluk":"Kanayannur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682023","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682028","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682019","Taluk":"Ernakulam","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"682003","Taluk":"Kochi","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"685561","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685590","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685588","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685606","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685509","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685595","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685512","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685565","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685535","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685613","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685585","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685515","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685591","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685608","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685533","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685619","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685507","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685552","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685514","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685566","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685509","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685554","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685614","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685501","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685553","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685581","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685618","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685551","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685613","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685589","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685612","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685501","Taluk":"Peermade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685565","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685615","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685587","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685605","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685533","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"685531","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685602","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685603","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685607","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685562","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685532","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685511","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685620","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685505","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685586","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685601","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685508","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685582","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685604","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685563","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685503","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685509","Taluk":"Peermade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685583","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685505","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685609","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685602","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685616","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685532","Taluk":"Peermade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685571","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685535","Taluk":"Peerumade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685553","Taluk":"NA","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685562","Taluk":"Udumbanchola","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685531","Taluk":"Peermade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685563","Taluk":"Devikulam","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685584","Taluk":"Thodupuzha","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685505","Taluk":"Peermade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685503","Taluk":"Peermade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"685533","Taluk":"Peermade","Districtname":"Idukki","statename":"KERALA"},
    {"pincode":"680302","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680741","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680683","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680305","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680667","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680731","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680309","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680562","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680688","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680666","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680724","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680686","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680307","Taluk":"Mukundapuram Taluk","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680722","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680307","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680681","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680687","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680721","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680571","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680121","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680684","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680312","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680561","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680308","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680304","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680301","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680699","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680567","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680568","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680122","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680703","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680671","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680721","Taluk":"Mi\\ukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680734","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680564","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680121","Taluk":"Mukundapuram Taluk","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680125","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680317","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680662","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680689","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680669","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680682","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680711","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680702","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680123","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680670","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680697","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680664","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680691","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680311","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680668","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680732","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680731","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680733","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680703","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680733","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680712","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680685","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680661","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680566","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680301","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680711","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680665","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680310","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680565","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680663","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680664","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680701","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680667","Taluk":"Paravur","Districtname":"Ernakulam","statename":"KERALA"},
    {"pincode":"680570","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680123","Taluk":"Kodungallur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680303","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"686582","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686580","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686143","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686651","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686561","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686576","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686019","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686144","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686578","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686579","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686008","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686564","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686574","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686604","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686122","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686562","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686613","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686015","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686146","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686605","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686603","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686563","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686606","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686584","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686608","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686022","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686581","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686586","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686004","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686652","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686587","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686577","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686121","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686011","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686631","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686653","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686571","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686602","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686633","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686611","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686632","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686020","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686610","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686572","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686612","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686583","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686607","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686002","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686001","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686013","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686003","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686633","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686573","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686635","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686017","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686016","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686005","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686636","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686634","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686577","Taluk":"Kanjirappally","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686041","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686018","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686635","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686516","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686609","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686012","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686601","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686006","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686616","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686014","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686634","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686146","Taluk":"Voikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686575","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686007","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686021","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686637","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686560","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686583","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686009","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686630","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686123","Taluk":"Meenachil","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686010","Taluk":"Kottayam","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686141","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"686636","Taluk":"Vaikom","Districtname":"Kottayam","statename":"KERALA"},
    {"pincode":"682553","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682552","Taluk":"Amini","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682551","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682554","Taluk":"Amini","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682554","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682556","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682557","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682555","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682558","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"682559","Taluk":"Lakshadweep","Districtname":"Lakshadweep","statename":"LAKSHADWEEP"},
    {"pincode":"690513","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690535","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690515","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689505","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690110","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690504","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690517","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690558","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690503","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690505","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690105","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690105","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690507","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690104","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690106","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690532","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690506","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690534","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689624","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689622","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690108","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690106","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690516","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690572","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690527","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690514","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690501","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690509","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690103","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690533","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689504","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690503","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690502","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690508","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689508","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690509","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689521","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690533","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689512","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690548","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689623","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690558","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690101","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690101","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689627","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690511","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690512","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690504","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690571","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690510","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690529","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690107","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690504","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"690531","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690529","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689520","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690559","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690537","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689509","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690102","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690530","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690534","Taluk":"Mavelikkara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690102","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"690501","Taluk":"Mavelikara","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"680551","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680518","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680519","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680519","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680641","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680602","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680555","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680563","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680006","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679564","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680541","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680523","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680508","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680618","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679532","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680620","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680102","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680521","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680751","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680581","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680583","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680306","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680003","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680506","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680104","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680522","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679561","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680586","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680587","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680517","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680501","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680653","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680008","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679531","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680012","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680604","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680501","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680585","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680511","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680026","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680502","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680027","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680517","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680652","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680552","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680515","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680569","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680611","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680510","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680615","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680589","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680751","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680584","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680101","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680103","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680514","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680543","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680014","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680546","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680586","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680587","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679562","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680011","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680102","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680613","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680594","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680612","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680590","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680544","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680028","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680505","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680656","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680591","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680601","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680523","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680010","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679106","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680306","Taluk":"Mukundapuram","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680504","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680584","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680585","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680616","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680590","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680503","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680007","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680594","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680584","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680014","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680013","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680651","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680512","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680588","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680012","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680589","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680617","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680542","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680651","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680604","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680507","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680602","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679105","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680581","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680509","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680583","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680601","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680623","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680614","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680005","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679563","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680655","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680641","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680524","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680506","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679532","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680623","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680552","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680588","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680542","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680544","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680545","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680552","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680306","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680004","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680517","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680581","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680585","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680002","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680551","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680642","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680553","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680631","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679106","Taluk":"Talappilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680596","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680022","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680520","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680001","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680020","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680005","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680009","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680021","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680516","Taluk":"NA","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680513","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680619","Taluk":"Chavakkad","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680654","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"679531","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680010","Taluk":"Thrissur","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"680582","Taluk":"Thalapilly","Districtname":"Thrissur","statename":"KERALA"},
    {"pincode":"689696","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689711","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689692","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691523","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691306","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691508","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690520","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691309","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691311","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691525","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691307","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690561","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689674","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689648","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691555","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689662","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689649","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691333","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691533","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689691","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689691","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689693","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689692","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691312","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691533","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691508","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689648","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691556","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691556","Taluk":"Pathanapuram","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689677","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689671","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689664","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689503","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689650","Taluk":"Kozhencherri","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691306","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689673","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689663","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691551","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691310","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689668","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691552","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689672","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689643","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691524","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691322","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689643","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689656","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689625","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691526","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689675","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689667","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691552","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691553","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689648","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689503","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691526","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"690522","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691331","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689694","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691308","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690521","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689663","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"690540","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689514","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691332","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689650","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689502","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689514","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689653","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691555","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689693","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691322","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689654","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689641","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689641","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689661","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689695","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689501","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689695","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689644","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689645","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689676","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689666","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689646","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689513","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691551","Taluk":"Kunnathur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691553","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691530","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689501","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689678","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689699","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691311","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691524","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689647","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691554","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689507","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689698","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689678","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689711","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689642","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689642","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691556","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689674","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689647","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691522","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689645","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691554","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691551","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691305","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689652","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689676","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689672","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689673","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689664","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689699","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691526","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690522","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689646","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689502","Taluk":"Adur","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691552","Taluk":"Adoor","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689662","Taluk":"Ranny","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"691573","Taluk":"Adichanallur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691579","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691516","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690573","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691577","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690547","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691532","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691505","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691501","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690525","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690538","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691602","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691002","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690542","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691021","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691559","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691302","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691534","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691014","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691536","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691572","Taluk":"Kolllam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691583","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691583","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691584","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691601","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691557","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691510","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691520","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691543","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691578","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691559","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690525","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691502","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690523","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691011","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691531","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691505","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691507","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691536","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691008","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691535","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691303","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691560","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690519","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691004","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691576","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690544","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690518","Taluk":"Karunagappaly","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691537","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691003","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691574","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691590","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691538","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690536","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691001","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691013","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691541","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691020","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691506","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691571","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691512","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691521","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691521","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690544","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691503","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691573","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691557","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691509","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690526","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691560","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690574","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691015","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691537","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691585","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691010","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690519","Taluk":"Kunnathur","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690519","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691515","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691509","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691501","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691334","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691582","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691535","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690526","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691512","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690524","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691566","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691006","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691301","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691507","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690574","Taluk":"Karunagapally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691532","Taluk":"Pathanapuram","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691504","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691500","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691537","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690547","Taluk":"Karthikappally","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"691531","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691304","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691582","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690539","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691581","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691005","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691007","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691016","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691319","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691009","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691012","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691019","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691589","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691520","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691584","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691532","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"690528","Taluk":"Karunagappally","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691540","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691540","Taluk":"Kottarakkara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691511","Taluk":"Kollam","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"691538","Taluk":"Kottarakara","Districtname":"Kollam","statename":"KERALA"},
    {"pincode":"689126","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689113","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689112","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689572","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689122","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689585","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689582","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689533","Taluk":"Kozhencherry","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689123","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689612","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689611","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689510","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689586","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689573","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689121","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689594","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689511","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689549","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689103","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"686547","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689123","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689614","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689532","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689109","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689542","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689583","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689547","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689621","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689124","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689102","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689108","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689615","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689571","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689105","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689582","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689102","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689543","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689587","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689621","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689595","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689531","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689545","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689541","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689588","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689581","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689546","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689548","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689550","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689106","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689584","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689533","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689581","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689591","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689581","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689107","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689574","Taluk":"Kuttanadu","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689544","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689110","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689122","Taluk":"Kozhenchery","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689589","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689551","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689506","Taluk":"Chengannur","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689626","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689108","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689592","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689531","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689105","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689602","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689106","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689613","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689115","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689597","Taluk":"Mallappally","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689101","Taluk":"Tiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689101","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689111","Taluk":"Ranni","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689104","Taluk":"Thiruvalla","Districtname":"Pathanamthitta","statename":"KERALA"},
    {"pincode":"689590","Taluk":"Kuttanad","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"689590","Taluk":"Kuttanadu","Districtname":"Alappuzha","statename":"KERALA"},
    {"pincode":"695614","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695102","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695607","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695606","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695306","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695029","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695584","Taluk":"Chirayinkil","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695309","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695023","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695101","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695103","Taluk":"chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695103","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695310","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695305","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695008","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695609","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695024","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695301","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695601","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695615","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695587","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695142","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695304","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695011","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695141","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695104","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695311","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695004","Taluk":"Trivandrum","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695307","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695605","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695608","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695021","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695583","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695581","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695584","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695608","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695144","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695582","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695104","Taluk":"Chirayinkeezh","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695612","Taluk":"Chirayinkil","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695604","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695607","Taluk":"Chirayinkil","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695313","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695602","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695611","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695313","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695015","Taluk":"Trivendrum","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695603","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695312","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695312","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695004","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695610","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695614","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695302","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695146","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695025","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695145","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695015","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695589","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695143","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695316","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695586","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695028","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695303","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695033","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695588","Taluk":"Tiruvanathapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695612","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695303","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695585","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695017","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695001","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695007","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695036","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695016","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695022","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695034","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695317","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695308","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695035","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695028","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695601","Taluk":"Chirayinkil","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695318","Taluk":"Chirayinkeezhu","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695572","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695122","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695005","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695026","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695505","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695541","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695551","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695124","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695123","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695032","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695525","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695564","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695126","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695542","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695009","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695502","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695501","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695522","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695132","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695504","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695013","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695014","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695563","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695125","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695503","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695562","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695019","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695561","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695040","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695506","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695043","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695574","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695042","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695512","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695524","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695030","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695564","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695002","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695501","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695572","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695003","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695526","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695099","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695523","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695013","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695571","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695568","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695521","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695527","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695043","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695543","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695574","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695573","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695543","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695133","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695523","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695528","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695020","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695121","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695507","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695027","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695575","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695018","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695508","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695563","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695573","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695012","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695513","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695020","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695038","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695010","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695006","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695547","Taluk":"Nedumangad","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695521","Taluk":"Thiruvananthapuram","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"695134","Taluk":"Neyyattinkara","Districtname":"Thiruvananthapuram","statename":"KERALA"},
    {"pincode":"480887","Taluk":"Lakhnadon","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481445","Taluk":"Katangi","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481332","Taluk":"Katangi","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481998","Taluk":"Bhua Bichhiya","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481879","Taluk":"Dindori","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481117","Taluk":"Baihar","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"480882","Taluk":"Seoni","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480771","Taluk":"Barghat","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481335","Taluk":"Waraseoni","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481880","Taluk":"Dindori","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481222","Taluk":"Lanji","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481102","Taluk":"Balaghat","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481885","Taluk":"Niwas","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481111","Taluk":"Baihar","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"480661","Taluk":"Seoni","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481995","Taluk":"Bhuabichhiya","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480884","Taluk":"Lakhanadon","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481998","Taluk":"Bhua Bichhia","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480667","Taluk":"Barghat","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480771","Taluk":"Seoni","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480997","Taluk":"Ghansore","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481337","Taluk":"Khairlanji","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481882","Taluk":"Dindori","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481990","Taluk":"Shahpura","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481331","Taluk":"Lalburra","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481665","Taluk":"Balaghat","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"480994","Taluk":"Keolari","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481556","Taluk":"Baihar","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481441","Taluk":"Lalbarra","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481224","Taluk":"Lanji","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"480888","Taluk":"Lakhanadon","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481051","Taluk":"Baihar","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481441","Taluk":"Lalburra","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481661","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481001","Taluk":"Balaghat","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481449","Taluk":"Tirodi","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"480886","Taluk":"Lakhnadon","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481662","Taluk":"Niwas","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481778","Taluk":"Dindori","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481664","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481771","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480996","Taluk":"Keolari","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480888","Taluk":"Lakhnadon","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480999","Taluk":"Ghansore","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481666","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481226","Taluk":"Khairlanji","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481105","Taluk":"Baihar","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481115","Taluk":"Kirnapur","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481668","Taluk":"Nainpur","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481996","Taluk":"Bhuabichhia","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480990","Taluk":"Seoni","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481995","Taluk":"Bhuabichhia","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481884","Taluk":"Dindori","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481885","Taluk":"Shahpura","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481332","Taluk":"Waraseoni","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481226","Taluk":"Kirnapur","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481672","Taluk":"Sahpura Niwas","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480880","Taluk":"Korai","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481551","Taluk":"Balaghat","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481885","Taluk":"Niwas","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481672","Taluk":"Sahpura Niwas","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481768","Taluk":"Nainpur","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481331","Taluk":"Waraseoni","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481663","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481995","Taluk":"Bhua Bichhiya","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481449","Taluk":"Katangi","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481885","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480667","Taluk":"Bharghat","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480991","Taluk":"Keolari","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480991","Taluk":"Seoni","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481776","Taluk":"Nainpur","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480882","Taluk":"Lakhanadon","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481116","Taluk":"Baihar","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"480884","Taluk":"Ghansore","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481771","Taluk":"Nainpur","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481771","Taluk":"Bhuabichhiya","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481768","Taluk":"Bhua Bichhiya","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481001","Taluk":"Laburra","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481332","Taluk":"Lalbarra","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481337","Taluk":"Waraseoni","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481331","Taluk":"Lalbarra","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"480881","Taluk":"Korai","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481995","Taluk":"Bhubichhiya","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"480997","Taluk":"Ghansire","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"480991","Taluk":"Ghansore","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481665","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481998","Taluk":"Bhuabichhiya","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481879","Taluk":"Shahpura","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481672","Taluk":"Sahpuraniwas","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481672","Taluk":"Shahpura Niwas","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481449","Taluk":"Khairlanji","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481995","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481661","Taluk":"Balaghat","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481672","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481879","Taluk":"Shahpura Niwas","Districtname":"Dindori","statename":"MADHYA PRADESH"},
    {"pincode":"481226","Taluk":"Seoni","Districtname":"Seoni","statename":"MADHYA PRADESH"},
    {"pincode":"481115","Taluk":"Kiranpur","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"481998","Taluk":"Mandla","Districtname":"Mandla","statename":"MADHYA PRADESH"},
    {"pincode":"481105","Taluk":"Balaghat","Districtname":"Balaghat","statename":"MADHYA PRADESH"},
    {"pincode":"462016","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462031","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462020","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462030","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462042","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462022","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"464993","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"462027","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462038","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462041","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462043","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462026","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"463106","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462010","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462045","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"464993","Taluk":"Goharganj","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462101","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462044","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462008","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"463111","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462026","Taluk":"NA","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462001","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462030","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462120","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"464993","Taluk":"NA","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462003","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462010","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462120","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462036","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462023","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462024","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462066","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462022","Taluk":"Bhopal","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462042","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462007","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462038","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462004","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462046","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462047","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462026","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"464993","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462037","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462013","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462033","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462044","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462011","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462039","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"462002","Taluk":"Huzur","Districtname":"Bhopal","statename":"MADHYA PRADESH"},
    {"pincode":"471510","Taluk":"Laundi","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472101","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471315","Taluk":"Laundi","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488442","Taluk":"Baldeogarh","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472111","Taluk":"Baldeogarh","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488001","Taluk":"Panna","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472111","Taluk":"Baldeogarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"488220","Taluk":"Ajaigarh","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472010","Taluk":"Tikamgarh","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471606","Taluk":"Laundi","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472221","Taluk":"Palera","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471111","Taluk":"Nowgong","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488448","Taluk":"Pawai","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"488441","Taluk":"Panna","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472010","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"488222","Taluk":"Panna","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471405","Taluk":"Bijawar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471301","Taluk":"Malehara","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488220","Taluk":"Ajaygarh","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472442","Taluk":"Niwari","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472447","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472005","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472339","Taluk":"Baldeogarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471525","Taluk":"Laundi","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488333","Taluk":"Devendra Nagar","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471201","Taluk":"Nowgong","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471311","Taluk":"Malehara","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488442","Taluk":"Pawai","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471515","Taluk":"Laundi","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471001","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488441","Taluk":"Laundi","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472447","Taluk":"Niwari","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471525","Taluk":"Gaurihar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472111","Taluk":"Pawai","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471318","Taluk":"Malahara","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472331","Taluk":"Baldeogarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472118","Taluk":"Jatara","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471105","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471313","Taluk":"Malehara","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471408","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471315","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472246","Taluk":"Orchha","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472336","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472115","Taluk":"Khargapur","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"488442","Taluk":"Ajaigarh","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471501","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472101","Taluk":"Jatara","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471411","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472331","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472339","Taluk":"Prithvipur","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"488051","Taluk":"Panna","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471606","Taluk":"Rajnagar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472001","Taluk":"Tikamgarh","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472446","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"488333","Taluk":"Prithvipur","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472337","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471405","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472445","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471625","Taluk":"Rajnagar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488050","Taluk":"Panna","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472337","Taluk":"Prithvipur","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"488446","Taluk":"Pawai","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471318","Taluk":"Malehara","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471510","Taluk":"Gaurihar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471101","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471318","Taluk":"Badamalahra","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471318","Taluk":"Badamalahara","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488333","Taluk":"Panna","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472111","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472001","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472339","Taluk":"Rajnagar","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472111","Taluk":"Laundi","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472115","Taluk":"Baldeogarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472336","Taluk":"Prithvipur","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471501","Taluk":"Nowgong","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471411","Taluk":"Nowgong","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488442","Taluk":"Panna","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471516","Taluk":"Gaurihar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471313","Taluk":"Malehra","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488333","Taluk":"Devendra","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471515","Taluk":"Gaurihar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471301","Taluk":"Malehra","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"488059","Taluk":"Gunnor","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"471606","Taluk":"Gaurihar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471510","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471606","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472115","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472246","Taluk":"Niwari","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471318","Taluk":"Bad Malahara","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472442","Taluk":"Tikamgarh","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472331","Taluk":"Tikamgar","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471606","Taluk":"Rajanagar","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472221","Taluk":"Pelara","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472246","Taluk":"Palera","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"488443","Taluk":"Raipura","Districtname":"Panna","statename":"MADHYA PRADESH"},
    {"pincode":"472118","Taluk":"NA","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"472010","Taluk":"NA","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"471515","Taluk":"Laund","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"471111","Taluk":"Chhatarpur","Districtname":"Chhatarpur","statename":"MADHYA PRADESH"},
    {"pincode":"472447","Taluk":"Palera","Districtname":"Tikamgarh","statename":"MADHYA PRADESH"},
    {"pincode":"460225","Taluk":"Betul","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480221","Taluk":"Amarwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460553","Taluk":"Amla","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480449","Taluk":"Junnardeo","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480334","Taluk":"NA","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480331","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460449","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460551","Taluk":"Amla","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480106","Taluk":"NA","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460665","Taluk":"Multai","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480337","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480551","Taluk":"Junnardeo","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460666","Taluk":"Multai","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460110","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460330","Taluk":"Betul","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460440","Taluk":"Shahpur","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480338","Taluk":"Pandhurna","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480223","Taluk":"Amarwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480115","Taluk":"Chourak","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460554","Taluk":"Amla","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460220","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480110","Taluk":"Chaurai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460001","Taluk":"Betul","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480224","Taluk":"Amarwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480441","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460004","Taluk":"Betul","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460220","Taluk":"Bahinsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480115","Taluk":"Chourai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480559","Taluk":"Tamia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480109","Taluk":"Sausar","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480223","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480224","Taluk":"Harrai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480001","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480110","Taluk":"Chourai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460225","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480221","Taluk":"Amarawara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460661","Taluk":"Multai","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480551","Taluk":"Junnareo","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460663","Taluk":"Multai","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480447","Taluk":"Chandametta","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480557","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480106","Taluk":"Sausar","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480449","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480334","Taluk":"Pandhurna","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480106","Taluk":"Bichhua","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460330","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480111","Taluk":"Bichhua","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460668","Taluk":"Multai","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480447","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480003","Taluk":"NA","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480337","Taluk":"Pandhurna","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480551","Taluk":"Jamai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480107","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460330","Taluk":"Amal","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480555","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480449","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460443","Taluk":"Shahpur","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480441","Taluk":"Amarwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460663","Taluk":"Mulati","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480553","Taluk":"Jamai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480555","Taluk":"Junnardeo","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460443","Taluk":"Shahpura","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480555","Taluk":"Jamai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480108","Taluk":"Sausar","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480115","Taluk":"Chaurai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460557","Taluk":"Multai","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480224","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460663","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480559","Taluk":"Jamai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480441","Taluk":"Paraisa","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480331","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480331","Taluk":"Pandhurna","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480110","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460661","Taluk":"Amla","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480001","Taluk":"Parasia","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460553","Taluk":"Betul","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480001","Taluk":"Chhindwra","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480105","Taluk":"Sausar","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480112","Taluk":"NA","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460553","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480001","Taluk":"Chhindwar","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480555","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480557","Taluk":"Chhindwara","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480001","Taluk":"NA","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"480441","Taluk":"Amla","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460330","Taluk":"Shahpur","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460449","Taluk":"Shahpura","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460557","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480441","Taluk":"Multai","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460449","Taluk":"Shahpur","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480115","Taluk":"Chouai","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"460447","Taluk":"Betul","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460557","Taluk":"Amla","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"460449","Taluk":"Betul","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"480110","Taluk":"NA","Districtname":"Chhindwara","statename":"MADHYA PRADESH"},
    {"pincode":"461661","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461331","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487881","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487225","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487551","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461990","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461223","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487555","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461661","Taluk":"Babai","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461221","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487001","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487225","Taluk":"Kareli","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487118","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461111","Taluk":"Itarsi","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461668","Taluk":"Babai","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487001","Taluk":"Narsimhapur","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461001","Taluk":"Hoshangabad","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461228","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461116","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461221","Taluk":"Seoni Malwa","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461771","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461990","Taluk":"Bankhedi","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487661","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487330","Taluk":"Kareli","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487221","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461441","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487334","Taluk":"Tendukheda","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461228","Taluk":"Timarni","Districtname":"Harda","statename":"MADHYA PRADESH"},
    {"pincode":"487330","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487334","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487110","Taluk":"Narsimhapur","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487551","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487555","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461115","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487441","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461111","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487330","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487441","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487118","Taluk":"Gotegaon","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461001","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461441","Taluk":"Khirkiyan","Districtname":"Harda","statename":"MADHYA PRADESH"},
    {"pincode":"487330","Taluk":"Tendukheda","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461228","Taluk":"Harda","Districtname":"Harda","statename":"MADHYA PRADESH"},
    {"pincode":"461441","Taluk":"Khirkiya","Districtname":"Harda","statename":"MADHYA PRADESH"},
    {"pincode":"487770","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487001","Taluk":"Kareli","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461668","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461114","Taluk":"Itarsi","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461111","Taluk":"Shahpur","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"461441","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"461228","Taluk":"Bhainsdehi","Districtname":"Betul","statename":"MADHYA PRADESH"},
    {"pincode":"487337","Taluk":"Tendukheda","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487334","Taluk":"Kareli","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461116","Taluk":"Hoshangabad","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487114","Taluk":"Narsimhapur","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487770","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461775","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487114","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461331","Taluk":"Harda","Districtname":"Harda","statename":"MADHYA PRADESH"},
    {"pincode":"487225","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461990","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461771","Taluk":"Sohagpur","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487110","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487114","Taluk":"Narsinghpur","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487221","Taluk":"Kareli","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461111","Taluk":"Timarni","Districtname":"Harda","statename":"MADHYA PRADESH"},
    {"pincode":"487881","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487221","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487110","Taluk":"Kareli","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487661","Taluk":"Gadarwara","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461775","Taluk":"Pipariya","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461115","Taluk":"Itarsi","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487114","Taluk":"Gotegaon","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"487001","Taluk":"Narsinghpur Ho","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461771","Taluk":"Sohagpur","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461446","Taluk":"Itarsi","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461122","Taluk":"Itarsi","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461881","Taluk":"Pipariya","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461775","Taluk":"Piparia","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461551","Taluk":"Itarsi","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461110","Taluk":"Hoshangabad","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487337","Taluk":"NA","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461110","Taluk":"NA","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487881","Taluk":"Saikheda","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461001","Taluk":"Babai","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461668","Taluk":"Sohagpur","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"461223","Taluk":"Seoni Malwa","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"487110","Taluk":"Narsinghpur","Districtname":"Narsinghpur","statename":"MADHYA PRADESH"},
    {"pincode":"461005","Taluk":"Hoshangabad","Districtname":"Hoshangabad","statename":"MADHYA PRADESH"},
    {"pincode":"485226","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485775","Taluk":"Amarpatan","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485115","Taluk":"Rampur-Baghelan","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486003","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485772","Taluk":"Maihar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486223","Taluk":"NA","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486556","Taluk":"Teonthar","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486111","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485447","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485773","Taluk":"NA","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485775","Taluk":"NA","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485446","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486115","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486550","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486220","Taluk":"Teonthar","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485551","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486446","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486223","Taluk":"Teonthar","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485881","Taluk":"Ramnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485331","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485334","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486445","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486226","Taluk":"Teonthar","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486447","Taluk":"Teonthar","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486117","Taluk":"Teonthar","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485775","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485112","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485441","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486553","Taluk":"Gurh","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485005","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486448","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486440","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485001","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486331","Taluk":"Mauganj","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486446","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486441","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485111","Taluk":"Rampur-Baghelan","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486440","Taluk":"Raipur - Karchuliyan","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485221","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485666","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486114","Taluk":"Raipur - Karchuliyan","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486447","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486123","Taluk":"Raipur - Karchuliyan","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485111","Taluk":"Rampur Baghelan","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485774","Taluk":"Amarpatan","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485113","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485773","Taluk":"Maihar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486340","Taluk":"Teonthar","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486341","Taluk":"Mauganj","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485114","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485001","Taluk":"NA","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485778","Taluk":"Amarpatan","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486340","Taluk":"Mauganj","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486450","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485661","Taluk":"Unchehara","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486335","Taluk":"Hanumana","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485226","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486226","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486006","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485221","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486333","Taluk":"Mauganj","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486338","Taluk":"Mauganj","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485774","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485112","Taluk":"Raghuraj Nagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485771","Taluk":"Maihar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486117","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486005","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485661","Taluk":"Nagod","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485331","Taluk":"Raghurajnagar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"486440","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486556","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486001","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486002","Taluk":"Huzur","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485001","Taluk":"Maihar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"485331","Taluk":"Mauganj","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486123","Taluk":"Gurh","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"486451","Taluk":"Sirmour","Districtname":"Rewa","statename":"MADHYA PRADESH"},
    {"pincode":"485661","Taluk":"Maihar","Districtname":"Satna","statename":"MADHYA PRADESH"},
    {"pincode":"470661","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470673","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470125","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470771","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470339","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470675","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470441","Taluk":"Khurai","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470661","Taluk":"Patharia","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470223","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470001","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470442","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470672","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470227","Taluk":"Garhakota","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470664","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470772","Taluk":"Patera","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470118","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470021","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470228","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470663","Taluk":"Batiyagarh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470335","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470775","Taluk":"Hatta","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470661","Taluk":"Patera","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470664","Taluk":"Jabera","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470113","Taluk":"Bina","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470226","Taluk":"Deori","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470775","Taluk":"Batiyagarh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470227","Taluk":"Rehli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470772","Taluk":"Hatta","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470441","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470117","Taluk":"Khurai","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470118","Taluk":"Khurai","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470673","Taluk":"Batiyagarh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470051","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470120","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470335","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470881","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470113","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470118","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470775","Taluk":"Patera","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470232","Taluk":"Garhakota","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470221","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470115","Taluk":"Khurai","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470666","Taluk":"Pathariya","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470124","Taluk":"Bina","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470337","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470115","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470119","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470232","Taluk":"Deori","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470235","Taluk":"Kesli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470663","Taluk":"Jabera","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470772","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470880","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470221","Taluk":"Kesli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470115","Taluk":"Bina","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470004","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470229","Taluk":"Garhakota","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470223","Taluk":"Deori","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470235","Taluk":"Garhakota","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470669","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470232","Taluk":"Rehli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470663","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470775","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470226","Taluk":"Kesli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470223","Taluk":"Kesli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470115","Taluk":"Rahatgarh","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470227","Taluk":"Deori","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470664","Taluk":"Patera","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470669","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470227","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470235","Taluk":"Rehli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470675","Taluk":"Patharia","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470004","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470666","Taluk":"Damoh","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470228","Taluk":"Rehli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470223","Taluk":"Rehli","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470002","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470003","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470235","Taluk":"Sagar","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470672","Taluk":"Patharia","Districtname":"Damoh","statename":"MADHYA PRADESH"},
    {"pincode":"470442","Taluk":"Khurai","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470441","Taluk":"Banda","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470669","Taluk":"Garhakota","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"470120","Taluk":"Rahatgarh","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"484881","Taluk":"Pushparajgarh","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484660","Taluk":"Umaria","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484774","Taluk":"Beohari","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484116","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484886","Taluk":"Pushparajgarh","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484661","Taluk":"Umaria","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"486661","Taluk":"Gopad Vanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484771","Taluk":"Jaisinghnagar","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484330","Taluk":"Jaithari","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"486670","Taluk":"Sihawal","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484001","Taluk":"B. Pali","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"486775","Taluk":"Rampur Naikin","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486776","Taluk":"Rampur Naikin","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484117","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486887","Taluk":"Singrauli","Districtname":"Singrauli","statename":"MADHYA PRADESH"},
    {"pincode":"484110","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484224","Taluk":"Pushparajgarh","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484224","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484770","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484661","Taluk":"Bandhogarh","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"486771","Taluk":"Churhat","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486882","Taluk":"Chitrangi","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484334","Taluk":"Kotma","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484440","Taluk":"Kotma","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"486675","Taluk":"Sidhi","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484776","Taluk":"Beohari","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484665","Taluk":"Bandhogarh","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"486886","Taluk":"Singrauli Colliery","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484664","Taluk":"Umaria","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484001","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484552","Taluk":"Bandhogarh","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484446","Taluk":"Jaisinghnagar","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486669","Taluk":"Majauli","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484336","Taluk":"Kotma","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484117","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"486666","Taluk":"Majauli","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484660","Taluk":"Bandhogarh","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484555","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484001","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484113","Taluk":"Jaithari","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484001","Taluk":"Beohari","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484665","Taluk":"Umaria","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484440","Taluk":"Jaitpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484551","Taluk":"Birsinghpur Pali","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484220","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"486881","Taluk":"Devsar","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484551","Taluk":"B.pali","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484669","Taluk":"Burhar","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486889","Taluk":"Singrauli Colliery","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486886","Taluk":"Singrauli","Districtname":"Singrauli","statename":"MADHYA PRADESH"},
    {"pincode":"484771","Taluk":"Churhat","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484330","Taluk":"Jaithari","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484114","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486888","Taluk":"Singrauli","Districtname":"Singrauli","statename":"MADHYA PRADESH"},
    {"pincode":"486892","Taluk":"Chitrangi","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484555","Taluk":"B. Pali","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484444","Taluk":"Kotma","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484887","Taluk":"Pushprajgarh","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484444","Taluk":"Jaitpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486890","Taluk":"Gopadbanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486881","Taluk":"Gopadbanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486889","Taluk":"Singrauli","Districtname":"Singrauli","statename":"MADHYA PRADESH"},
    {"pincode":"486881","Taluk":"Deosar","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486887","Taluk":"Singrauli Colliery","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484770","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484660","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484669","Taluk":"Jaitpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484886","Taluk":"Anuppur","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"484446","Taluk":"Kotma","Districtname":"Anuppur","statename":"MADHYA PRADESH"},
    {"pincode":"486884","Taluk":"Gopadbanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486669","Taluk":"Jaitpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486892","Taluk":"Churhat","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486661","Taluk":"Gopad Vanas","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484001","Taluk":"Shagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484774","Taluk":"V","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486776","Taluk":"Gopadbanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484551","Taluk":"B. Pali","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484881","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484120","Taluk":"Sohagpur","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"484001","Taluk":"B.pali","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"484001","Taluk":"Shahdol","Districtname":"Shahdol","statename":"MADHYA PRADESH"},
    {"pincode":"486661","Taluk":"Gopadbanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486661","Taluk":"Gopadvanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"484552","Taluk":"B. Pali","Districtname":"Umaria","statename":"MADHYA PRADESH"},
    {"pincode":"486666","Taluk":"Gopadbanas","Districtname":"Sidhi","statename":"MADHYA PRADESH"},
    {"pincode":"486885","Taluk":"Singrauli","Districtname":"Singrauli","statename":"MADHYA PRADESH"},
    {"pincode":"464884","Taluk":"Gairatganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464111","Taluk":"Nateran","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464113","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464001","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464668","Taluk":"Baraily","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464774","Taluk":"Udaipura","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464886","Taluk":"Silwani","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464221","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464551","Taluk":"Gairatganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464665","Taluk":"Baraily","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464114","Taluk":"Lateri","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464221","Taluk":"Nateran","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464220","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464551","Taluk":"Raisen","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464776","Taluk":"Udaipura","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464881","Taluk":"Begamganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464665","Taluk":"Raisen","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464986","Taluk":"Goharganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464668","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464226","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464672","Taluk":"Silwani","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464228","Taluk":"Sironj","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464770","Taluk":"Udaipura","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464240","Taluk":"Bina","Districtname":"Sagar","statename":"MADHYA PRADESH"},
    {"pincode":"464337","Taluk":"Kurwai","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464671","Taluk":"Udaipura","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464228","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464661","Taluk":"Raisen","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464337","Taluk":"Basoda","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464770","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464665","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464651","Taluk":"Raisen","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464220","Taluk":"Gyaraspur","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464224","Taluk":"Kurwai","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464221","Taluk":"Basoda","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464671","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464774","Taluk":"Silwani","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464671","Taluk":"Baraily","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464224","Taluk":"Basoda","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464331","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464990","Taluk":"Goharganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464001","Taluk":"Vidisha","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464986","Taluk":"Baraily","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464651","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464258","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464240","Taluk":"Kurwai","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464220","Taluk":"Vidisha","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464331","Taluk":"Gyaraspur","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464331","Taluk":"Vidisha","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464226","Taluk":"Vidisha","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464672","Taluk":"Udaipura","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464240","Taluk":"NA","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464258","Taluk":"Nateran","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464258","Taluk":"Vidisha","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464986","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464881","Taluk":"NA","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464113","Taluk":"Vidisha","Districtname":"Vidisha","statename":"MADHYA PRADESH"},
    {"pincode":"464551","Taluk":"Goharganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464881","Taluk":"Gairatganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464672","Taluk":"Goharganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464886","Taluk":"Gairatganj","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"464672","Taluk":"Baraily","Districtname":"Raisen","statename":"MADHYA PRADESH"},
    {"pincode":"473444","Taluk":"Mungaoli","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473585","Taluk":"Khaniadhana","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473770","Taluk":"Kolaras","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473793","Taluk":"Pohri","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473781","Taluk":"Kolaras","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473665","Taluk":"Karera","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473660","Taluk":"Karera","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473880","Taluk":"Narwar","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473332","Taluk":"Ashoknagar","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473101","Taluk":"Guna","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473331","Taluk":"Ashok Nagar","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473331","Taluk":"Ashoknagar","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473885","Taluk":"Kolaras","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473331","Taluk":"Ashoknagar","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473226","Taluk":"Raghogarh","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473105","Taluk":"Bamori","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473443","Taluk":"Mungaoli","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473995","Taluk":"Pichhore","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473670","Taluk":"Pichhore","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473446","Taluk":"Chanderi","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473287","Taluk":"Maksudangarh","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473330","Taluk":"Ashoknagar","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473775","Taluk":"Pohri","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473249","Taluk":"Guna","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473551","Taluk":"Shivpuri","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473335","Taluk":"Esagarh","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473662","Taluk":"Karera","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473115","Taluk":"Chacauda","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473101","Taluk":"Aroun","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473222","Taluk":"Kumbhraj","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473774","Taluk":"Kolaras","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473113","Taluk":"Guna","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473111","Taluk":"Raghogarh","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473115","Taluk":"Chachoda","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473118","Taluk":"Chachauda","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473990","Taluk":"Khaniadhana","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473110","Taluk":"Raghogarh","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473865","Taluk":"Narwar","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473443","Taluk":"Mungaoli","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473112","Taluk":"Raghogarh","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473440","Taluk":"Mungaoli","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473001","Taluk":"Guna","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473638","Taluk":"Shivpuri","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473332","Taluk":"Esagarh","Districtname":"Ashok Nagar","statename":"MADHYA PRADESH"},
    {"pincode":"473885","Taluk":"Guna","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"473880","Taluk":"Pichhore","Districtname":"Shivpuri","statename":"MADHYA PRADESH"},
    {"pincode":"473118","Taluk":"Chacauda","Districtname":"Guna","statename":"MADHYA PRADESH"},
    {"pincode":"474015","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475001","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474001","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475005","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475001","Taluk":"Dabra","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475330","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475671","Taluk":"Datia","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475682","Taluk":"Seondha","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475673","Taluk":"Seondha","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"474006","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475330","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475115","Taluk":"Dabra","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475335","Taluk":"Bhandair","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475220","Taluk":"Bhitarwar","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474020","Taluk":"Morar","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475661","Taluk":"Datia","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"474006","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474005","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475685","Taluk":"Datia","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475686","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475110","Taluk":"Dabra","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475002","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475686","Taluk":"NA","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475336","Taluk":"Bhandair","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475675","Taluk":"Indergarh","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475335","Taluk":"Bhander","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"474004","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475686","Taluk":"Datia","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"474001","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475002","Taluk":"Gwalor","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475110","Taluk":"Pichhore","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474011","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474002","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474005","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474003","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474008","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475002","Taluk":"Morar","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475675","Taluk":"Seondha","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"474009","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474010","Taluk":"Gwalior","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475001","Taluk":"NA","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474010","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474020","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475115","Taluk":"Dabra","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"475110","Taluk":"Dabra","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"474007","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474012","Taluk":"Gird","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"475336","Taluk":"Bhander","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475675","Taluk":"Indergah","Districtname":"Datia","statename":"MADHYA PRADESH"},
    {"pincode":"475005","Taluk":"Pichhore","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"474006","Taluk":"NA","Districtname":"Gwalior","statename":"MADHYA PRADESH"},
    {"pincode":"477441","Taluk":"Mihona","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476111","Taluk":"Ambah","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477227","Taluk":"Mehgoun","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476332","Taluk":"Bijaypur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"477555","Taluk":"Ater","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477111","Taluk":"Attair","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477111","Taluk":"Ater","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476224","Taluk":"Kailaras","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477116","Taluk":"Gohad","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476134","Taluk":"Ambah","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477445","Taluk":"Lahar","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477557","Taluk":"Mehgoun","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477331","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477446","Taluk":"Lahar","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477660","Taluk":"Mehgoun","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477332","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477449","Taluk":"Lahar","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476221","Taluk":"Joura","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477447","Taluk":"Lahar","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477227","Taluk":"Mehgaon","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476001","Taluk":"Morena","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476219","Taluk":"Joura","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476337","Taluk":"Sheopur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"476229","Taluk":"Sabalgarh","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477333","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477001","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476339","Taluk":"Sheopur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"477105","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476115","Taluk":"Porsa","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477566","Taluk":"NA","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477335","Taluk":"Ron","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477117","Taluk":"Gohad","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476335","Taluk":"Bijaypur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"476444","Taluk":"Morena","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476355","Taluk":"Karahal","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"477566","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477222","Taluk":"Mehgoun","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476224","Taluk":"Kealras","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477555","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476224","Taluk":"Joura","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476554","Taluk":"Ambah","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476332","Taluk":"Bijaipur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"477557","Taluk":"Mehgaon","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477335","Taluk":"Raun","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477222","Taluk":"Gohad","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476339","Taluk":"Karahal","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"476134","Taluk":"Morena","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476335","Taluk":"Vijaypur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"477117","Taluk":"Mehgoun","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476355","Taluk":"Sheopur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"477332","Taluk":"Vijaypur","Districtname":"Sheopur","statename":"MADHYA PRADESH"},
    {"pincode":"476228","Taluk":"Joura","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477557","Taluk":"Gohad","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476224","Taluk":"Kelaras","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476001","Taluk":"Joura","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476115","Taluk":"Ambah","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476134","Taluk":"Dimni","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"476554","Taluk":"Morena","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"477445","Taluk":"Mehgaon","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477441","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477660","Taluk":"Mehgaon","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477660","Taluk":"Ater","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477105","Taluk":"Ater","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477447","Taluk":"Ater","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"477660","Taluk":"Bhind","Districtname":"Bhind","statename":"MADHYA PRADESH"},
    {"pincode":"476229","Taluk":"NA","Districtname":"Morena","statename":"MADHYA PRADESH"},
    {"pincode":"452006","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452003","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452005","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452007","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452013","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452001","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452002","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452010","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452016","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452015","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452011","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452018","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452014","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452009","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"452012","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"453115","Taluk":"Depalpur","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"455001","Taluk":"Dewas","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"455116","Taluk":"Sonkutch","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"455227","Taluk":"Bagli","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"454001","Taluk":"Dhar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454446","Taluk":"Manawar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"455336","Taluk":"Khategaon","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"453551","Taluk":"Sanwer","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"454221","Taluk":"Kukshai","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454331","Taluk":"Kukshi","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"453111","Taluk":"Depalpur","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"453441","Taluk":"Mhow","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"454441","Taluk":"Sardarpur","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"455332","Taluk":"Kannod","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"455223","Taluk":"Bagli","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"455118","Taluk":"Sonkutch","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"455115","Taluk":"Bagli","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"453001","Taluk":"Depalpur","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"455459","Taluk":"Kannod","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"454010","Taluk":"Dhar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"453220","Taluk":"Depalpur","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"454773","Taluk":"Dhar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454660","Taluk":"Badnawar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454111","Taluk":"Sardarpur","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454221","Taluk":"Kukshi","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454446","Taluk":"Dhar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"455111","Taluk":"Dewas","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"454552","Taluk":"Dharampuri","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454665","Taluk":"Badnawar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"455221","Taluk":"Bagli","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"453771","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"455440","Taluk":"Kannod","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"454116","Taluk":"Sardarpur","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454335","Taluk":"Kukshi","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"455339","Taluk":"Khategaon","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"454449","Taluk":"Dharampuri","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454331","Taluk":"Dhar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"452020","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"453112","Taluk":"Depalpur","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"453661","Taluk":"Mhow","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"453556","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"454774","Taluk":"Dhar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454010","Taluk":"Dhjar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454335","Taluk":"Manawar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"453331","Taluk":"Mhow","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"454775","Taluk":"Dhar","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"454660","Taluk":"Sardarpur","Districtname":"Dhar","statename":"MADHYA PRADESH"},
    {"pincode":"453446","Taluk":"Mhow","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"453555","Taluk":"Sanwer","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"453332","Taluk":"Indore","Districtname":"Indore","statename":"MADHYA PRADESH"},
    {"pincode":"455227","Taluk":"Dewas","Districtname":"Dewas","statename":"MADHYA PRADESH"},
    {"pincode":"483770","Taluk":"Vijayaraghavgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482004","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483334","Taluk":"Sihora","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482002","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483990","Taluk":"Rithi","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483053","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483880","Taluk":"Vijayaraghavgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482004","Taluk":"NA","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483336","Taluk":"Sihora","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483225","Taluk":"Sihora","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483501","Taluk":"Katni","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483113","Taluk":"Patan","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483220","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483110","Taluk":"Kundam","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483773","Taluk":"Katni","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482001","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483330","Taluk":"Katni","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483331","Taluk":"Katni","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483880","Taluk":"Vijayaragahvgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483222","Taluk":"Sihora","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483440","Taluk":"Sihora","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"482021","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483001","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"482008","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483225","Taluk":"Sihora","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"482056","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"482051","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483770","Taluk":"Katni","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483119","Taluk":"Patan","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483440","Taluk":"Sihora","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483773","Taluk":"Kanti","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483442","Taluk":"Katni","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483332","Taluk":"Sihora","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482020","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483105","Taluk":"Patan","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483770","Taluk":"Vijayarghavgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483331","Taluk":"Bahoriband","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482003","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483770","Taluk":"Murwara","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483775","Taluk":"Ijayaraghavgarh.","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482011","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483330","Taluk":"Bahoriband","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483775","Taluk":"Vijayaraghavgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483775","Taluk":"Vijaragahvgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483775","Taluk":"Vijayaragahvgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482005","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"482001","Taluk":"NA","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483501","Taluk":"Murwara","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483504","Taluk":"Murwara","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483222","Taluk":"Sihora","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483440","Taluk":"Sihira","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483770","Taluk":"Vijayragahvgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483770","Taluk":"Vijayaragahvgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483880","Taluk":"Vijayraghavgarh","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483220","Taluk":"Jabalpur","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483504","Taluk":"Katni","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483880","Taluk":"Vijayraghavgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483331","Taluk":"Bahoriaband","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483770","Taluk":"Vijayaraghvgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482010","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483334","Taluk":"Sihora","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"482009","Taluk":"Jabalpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483775","Taluk":"Vijayraghavgarh","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"482002","Taluk":"NA","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"483225","Taluk":"Sihora.","Districtname":"Katni","statename":"MADHYA PRADESH"},
    {"pincode":"483220","Taluk":"Jsbslpur","Districtname":"Jabalpur","statename":"MADHYA PRADESH"},
    {"pincode":"450337","Taluk":"Pandhana","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451332","Taluk":"Jhiranya","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451666","Taluk":"Sendhwa","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"450117","Taluk":"Harsud","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"451001","Taluk":"Khargone","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451113","Taluk":"Bhikangaon","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450001","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451111","Taluk":"Barwaha","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450331","Taluk":"Nepanagar","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451228","Taluk":"Kasrawad","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450001","Taluk":"Nepanagar","Districtname":"Burhanpur","statename":"MADHYA PRADESH"},
    {"pincode":"451335","Taluk":"Bhikangaon","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451556","Taluk":"Anjad","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451551","Taluk":"Barwani","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"450661","Taluk":"Pandhana","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451224","Taluk":"Maheshwar","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450331","Taluk":"NA","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450551","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450117","Taluk":"Harsud","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451221","Taluk":"Maheshwar","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451225","Taluk":"Maheshwar","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451115","Taluk":"Barwaha","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451113","Taluk":"Barwaha","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451447","Taluk":"Thikari","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451449","Taluk":"Thikari","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"450001","Taluk":"Pandhana","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451220","Taluk":"Barwaha","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451441","Taluk":"Bhagwanpura","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451449","Taluk":"Rajpur","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451001","Taluk":"Kasarawad","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451556","Taluk":"Barwani","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"450881","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450445","Taluk":"Burhanpur","Districtname":"Burhanpur","statename":"MADHYA PRADESH"},
    {"pincode":"451331","Taluk":"Bhikangaon","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451770","Taluk":"Pansemal","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451441","Taluk":"Bhagwanpura","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450116","Taluk":"Harsud","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450771","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450554","Taluk":"Khandwa","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450110","Taluk":"Harsud","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450051","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451441","Taluk":"Bhagawanpura","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450771","Taluk":"Khandwa","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450991","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450221","Taluk":"Nepanagar","Districtname":"Burhanpur","statename":"MADHYA PRADESH"},
    {"pincode":"451881","Taluk":"Pansemal","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"450445","Taluk":"Burhanpur","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451660","Taluk":"Kasrawad","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450551","Taluk":"Khandwa","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451220","Taluk":"Maheshwar","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450116","Taluk":"Harsud","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"451556","Taluk":"Rajpur","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"450110","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450331","Taluk":"Burhanpur","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450331","Taluk":"Burhanpur","Districtname":"Burhanpur","statename":"MADHYA PRADESH"},
    {"pincode":"451660","Taluk":"Thikari","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451332","Taluk":"Jhirniya","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450221","Taluk":"Nepanagar","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451001","Taluk":"Kasrawad","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451666","Taluk":"Niwali","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451447","Taluk":"Barwani","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451442","Taluk":"Rajpur","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451447","Taluk":"Rajpur","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451335","Taluk":"Khargone","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451442","Taluk":"Segaon","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450332","Taluk":"Khaknar","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450051","Taluk":"Harsud","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451111","Taluk":"Bhikangaon","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450331","Taluk":"Khaknar","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451442","Taluk":"Khargone","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451556","Taluk":"Thikari","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451440","Taluk":"Segaon","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450661","Taluk":"Pandhana","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"451332","Taluk":"Bhikangaon","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450114","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450112","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450112","Taluk":"Khandwa","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"451332","Taluk":"Jhirniya","Districtname":"Khargone","statename":"MADHYA PRADESH"},
    {"pincode":"451332","Taluk":"Jhirnia","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451449","Taluk":"Barwani","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451335","Taluk":"Kasarawad","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450332","Taluk":"Khaknar","Districtname":"Burhanpur","statename":"MADHYA PRADESH"},
    {"pincode":"450661","Taluk":"NA","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450001","Taluk":"Harsud","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451113","Taluk":"Kasrawad","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451881","Taluk":"Sendhawa","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"450771","Taluk":"Pandhana","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450337","Taluk":"Pandhana","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"451440","Taluk":"Khargone","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451225","Taluk":"Barwaha","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451224","Taluk":"Maheashwar","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450554","Taluk":"NA","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"450991","Taluk":"Naya Harsud","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451660","Taluk":"Kasrawad","Districtname":"Khargone","statename":"MADHYA PRADESH"},
    {"pincode":"450554","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451335","Taluk":"Kasrawad","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450119","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"450551","Taluk":"NA","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"451770","Taluk":"Niwali","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"451115","Taluk":"Khandwa","Districtname":"East Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451441","Taluk":"Khargone","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451441","Taluk":"Bhagawanpura","Districtname":"Khargone","statename":"MADHYA PRADESH"},
    {"pincode":"450445","Taluk":"NA","Districtname":"Burhanpur","statename":"MADHYA PRADESH"},
    {"pincode":"450554","Taluk":"Khandwa","Districtname":"Khandwa","statename":"MADHYA PRADESH"},
    {"pincode":"451331","Taluk":"Jhirniya","Districtname":"West Nimar","statename":"MADHYA PRADESH"},
    {"pincode":"451447","Taluk":"Niwali","Districtname":"Barwani","statename":"MADHYA PRADESH"},
    {"pincode":"458113","Taluk":"Manasa","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458883","Taluk":"Garoth","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458553","Taluk":"Malhargarh","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458895","Taluk":"Mandsaur","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458888","Taluk":"Suwasara Mandi","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458667","Taluk":"Mandsaur","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458669","Taluk":"Mandsaur","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458110","Taluk":"Manasa","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458441","Taluk":"Neemuch","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458002","Taluk":"Mandsaur","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458775","Taluk":"Bhanpura","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458330","Taluk":"Jawad","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458226","Taluk":"Jawad","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458664","Taluk":"Malhargarh","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458118","Taluk":"Manasa","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458558","Taluk":"Sitamau","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458220","Taluk":"Jawad","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458880","Taluk":"Garoth","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458556","Taluk":"Malhargarh","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458339","Taluk":"Malhargarh","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458116","Taluk":"Manasa","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458990","Taluk":"Sitamau","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458389","Taluk":"Sitamau","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458778","Taluk":"Bhanpura","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458553","Taluk":"Malhargarh","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458888","Taluk":"Sitamau","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458771","Taluk":"Bhanpura","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458339","Taluk":"Neemuch","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458228","Taluk":"Jawad","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458336","Taluk":"Neemuch","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458468","Taluk":"Jawad","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458470","Taluk":"Jawad","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458339","Taluk":"Malhargarh","Districtname":"Neemuch","statename":"MADHYA PRADESH"},
    {"pincode":"458001","Taluk":"Mandsaur","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"458553","Taluk":"Malahargarh","Districtname":"Mandsaur","statename":"MADHYA PRADESH"},
    {"pincode":"457550","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457550","Taluk":"Sailana","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457779","Taluk":"Meghnagar","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457887","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457887","Taluk":"Alirajpur","Districtname":"Alirajpur","statename":"MADHYA PRADESH"},
    {"pincode":"457114","Taluk":"Alot","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457882","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457770","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457555","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457222","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457773","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457888","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457993","Taluk":"Ranapur","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457779","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457336","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457990","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457887","Taluk":"Alirajpur","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457555","Taluk":"Bajna","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457777","Taluk":"Thandla","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457777","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457993","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457001","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457775","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457661","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457226","Taluk":"Jaora","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457331","Taluk":"Piploda","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457118","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457114","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457882","Taluk":"Bhavra","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457772","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457770","Taluk":"Petlawad","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457118","Taluk":"Alot","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457441","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457888","Taluk":"Alirajpur","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457339","Taluk":"Piploda","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457226","Taluk":"Piploda","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457773","Taluk":"Petlawad","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457331","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457114","Taluk":"Alote","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457775","Taluk":"Petlawad","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457339","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457885","Taluk":"Jhabua","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457001","Taluk":"Bajna","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457887","Taluk":"NA","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457222","Taluk":"Piploda","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457331","Taluk":"Jaora","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457226","Taluk":"Sailana","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457226","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457885","Taluk":"Alirajpur","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457336","Taluk":"Piploda","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457550","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457119","Taluk":"Alote","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457885","Taluk":"Jhabua","Districtname":"Alirajpur","statename":"MADHYA PRADESH"},
    {"pincode":"457777","Taluk":"Meghnagar","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457340","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457990","Taluk":"Alirajpur","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457772","Taluk":"Thandla","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457340","Taluk":"Jaora","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457336","Taluk":"Jaora","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457333","Taluk":"Piploda","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457770","Taluk":"NA","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457333","Taluk":"Ratlam","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457770","Taluk":"Meghnagar","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"457001","Taluk":"Sailana","Districtname":"Ratlam","statename":"MADHYA PRADESH"},
    {"pincode":"457888","Taluk":"Jhabua","Districtname":"Alirajpur","statename":"MADHYA PRADESH"},
    {"pincode":"457775","Taluk":"Petlawada","Districtname":"Jhabua","statename":"MADHYA PRADESH"},
    {"pincode":"466111","Taluk":"Bilkisganj","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465669","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465674","Taluk":"Biaora","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466448","Taluk":"Baktara","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466115","Taluk":"Ichawar","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466665","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466116","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466554","Taluk":"Shahganj","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466446","Taluk":"Rehti","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465677","Taluk":"Talen","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466113","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466221","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466116","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465697","Taluk":"Sarangpur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465687","Taluk":"Sarangpur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465693","Taluk":"Jirapur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466001","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465667","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465669","Taluk":"Biaora","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466331","Taluk":"Nasrullaganj","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466125","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465677","Taluk":"Suthalia","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466651","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465687","Taluk":"Khujner","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466448","Taluk":"Budni","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466554","Taluk":"Budni","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465680","Taluk":"Talen","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465683","Taluk":"Pachore","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466665","Taluk":"Ahmedpur","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465679","Taluk":"Khilchipur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466445","Taluk":"Budhni","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466114","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465693","Taluk":"Machalpur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465685","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465689","Taluk":"Chhapiheda","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466115","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465683","Taluk":"Sarangpur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465674","Taluk":"Rajgarh(bia)","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466111","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465677","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465685","Taluk":"Biaora","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465683","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466445","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465691","Taluk":"Zeerapur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466445","Taluk":"Budni","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465661","Taluk":"Rajgarh(bia)","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465689","Taluk":"Khilchipur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466661","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465680","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466114","Taluk":"Kotri","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466113","Taluk":"Ichhawar","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466331","Taluk":"Nasrullagaj","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466120","Taluk":"Siddikganj","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465667","Taluk":"Sarangpur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465693","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465677","Taluk":"Biaora","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466118","Taluk":"Metwada","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466118","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465685","Taluk":"Boda","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465679","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466115","Taluk":"Ichhawar","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465667","Taluk":"Kurawar","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465674","Taluk":"Sarangpur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466651","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466221","Taluk":"Jawar","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466445","Taluk":"Doraha","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"466651","Taluk":"Shyampur","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465687","Taluk":"Narsinghgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466114","Taluk":"Sehore","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465687","Taluk":"Khilchipur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465693","Taluk":"Khilchipur","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465669","Taluk":"Rajgarh(bia)","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466120","Taluk":"Ashta","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465683","Taluk":"Biaora","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465661","Taluk":"Rajgarh","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465661","Taluk":"NA","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"466446","Taluk":"Budni","Districtname":"Sehore","statename":"MADHYA PRADESH"},
    {"pincode":"465687","Taluk":"Biaora","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"465691","Taluk":"Rajgarh(bia)","Districtname":"Rajgarh","statename":"MADHYA PRADESH"},
    {"pincode":"456776","Taluk":"Badnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456441","Taluk":"Mehidpur","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465001","Taluk":"Shajapur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465441","Taluk":"Agar Malwa","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456222","Taluk":"Barnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465223","Taluk":"Moman Badodiya","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456224","Taluk":"Khacharod","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465333","Taluk":"Kalapipal","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456221","Taluk":"Nagda","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465550","Taluk":"Barode","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465447","Taluk":"Susner","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456771","Taluk":"Badnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465335","Taluk":"Jamner","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456006","Taluk":"Ghatiya","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465118","Taluk":"Awantipur Barodiya","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456771","Taluk":"Barnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456337","Taluk":"Badnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456337","Taluk":"Piploda Baghla","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465445","Taluk":"Agar","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456661","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456770","Taluk":"Tarana","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456222","Taluk":"Badnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456776","Taluk":"Runija","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456335","Taluk":"Khachraud","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456440","Taluk":"Mahidpur","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465110","Taluk":"Shajapur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456443","Taluk":"Mahidpur","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456335","Taluk":"Nagda","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465226","Taluk":"Moman Badodiya","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465337","Taluk":"Kalapipal","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465110","Taluk":"Berchha","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456770","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465339","Taluk":"Khokra Kalan","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465223","Taluk":"Akodia","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465445","Taluk":"Nalkheda","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456331","Taluk":"Khachraud","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456313","Taluk":"Bhatpachlana","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456313","Taluk":"Badnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465333","Taluk":"Shujalpur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456665","Taluk":"Tarana","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456331","Taluk":"Nagda","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465441","Taluk":"Agar","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465220","Taluk":"Kalisindh","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456664","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465226","Taluk":"Barodia Kumaria","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456006","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465227","Taluk":"Moman Badodiya","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465230","Taluk":"Moman Badodiya","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465449","Taluk":"Susner","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465220","Taluk":"Shajapur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465116","Taluk":"Shujalpur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456550","Taluk":"Ghatiya","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465449","Taluk":"Soyat Kalan","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456668","Taluk":"Tarana","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456550","Taluk":"Ghattia","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456003","Taluk":"Ghatiya","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465230","Taluk":"Kanad","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456661","Taluk":"Tajpur","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456441","Taluk":"Mahidpur","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456337","Taluk":"Polai Kalan","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465335","Taluk":"Shujalpur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456441","Taluk":"Jharda","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465106","Taluk":"Shajapur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456668","Taluk":"Ghattia","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465227","Taluk":"Shujalpur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456003","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456313","Taluk":"Khacharod","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465230","Taluk":"Agar","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465227","Taluk":"Salsalai","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456224","Taluk":"Khachraud","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465113","Taluk":"Shujalpur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456313","Taluk":"Khachraud","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456337","Taluk":"Nagda","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465113","Taluk":"Shajapur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456440","Taluk":"Mehidpur","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456443","Taluk":"Mehidpur","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465223","Taluk":"Shujalpur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456221","Taluk":"Badnagar","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456337","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465116","Taluk":"Shajapur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456337","Taluk":"Khacharod","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465113","Taluk":"Sundersi","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"465449","Taluk":"Shajapur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456440","Taluk":"Nagda","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"465337","Taluk":"Shujalpur","Districtname":"Shajapur","statename":"MADHYA PRADESH"},
    {"pincode":"456010","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456001","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"456221","Taluk":"Ujjain","Districtname":"Ujjain","statename":"MADHYA PRADESH"},
    {"pincode":"431132","Taluk":"Bhokardan","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431104","Taluk":"Kannad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431007","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431008","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431121","Taluk":"Paithan","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431110","Taluk":"Gangapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431118","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431117","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431202","Taluk":"Badnapur","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431208","Taluk":"Jaffrabad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"423701","Taluk":"Vaijapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431134","Taluk":"Phulumbri","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431103","Taluk":"Kannad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431204","Taluk":"Ambad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431133","Taluk":"Gangapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431112","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431203","Taluk":"Jalna","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431113","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431151","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431212","Taluk":"Jalna","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431211","Taluk":"Ghansawangi","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431107","Taluk":"Paithan","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431002","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431001","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431005","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431114","Taluk":"Bhokardan","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431115","Taluk":"Vaijapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431151","Taluk":"Phulumbri","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431202","Taluk":"Badnapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431134","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431120","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431136","Taluk":"Gangapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431105","Taluk":"Paithan","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431116","Taluk":"Valijapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431135","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431150","Taluk":"Soegaon","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431215","Taluk":"Jalna","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431004","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431154","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431206","Taluk":"Jaffrabad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431214","Taluk":"Jalna","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"423703","Taluk":"Vaijapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431114","Taluk":"Bhokardan","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431137","Taluk":"Paithan","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431008","Taluk":"Phulumbri","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431116","Taluk":"Vaijapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431111","Taluk":"Khuldabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431209","Taluk":"Ghansawangi","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431102","Taluk":"Khuldabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431215","Taluk":"Bhokardan","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431006","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431202","Taluk":"Ambad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431215","Taluk":"Badnapur","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431121","Taluk":"Ambad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431147","Taluk":"Kannad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431003","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431213","Taluk":"Jalna","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431137","Taluk":"Ambad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431102","Taluk":"Kannad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431215","Taluk":"Jaffrabad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431115","Taluk":"Kannad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431205","Taluk":"Ambad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431111","Taluk":"Phulumbri","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"423702","Taluk":"Gangapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431101","Taluk":"Khuldabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431109","Taluk":"Gangapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431009","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431212","Taluk":"Ambad","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431114","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431207","Taluk":"Badnapur","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431148","Taluk":"Paithan","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431118","Taluk":"Soegaon","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431120","Taluk":"Soegaon","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431207","Taluk":"Ghansawangi","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"423702","Taluk":"Vaijapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431132","Taluk":"Sillod","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431115","Taluk":"Gangapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431106","Taluk":"Paithan","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431111","Taluk":"Phulambri","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431114","Taluk":"Bholardan","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431134","Taluk":"Bhokardan","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431215","Taluk":"jalna","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431214","Taluk":"jalna","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431207","Taluk":"Ghanwangi","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431010","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431202","Taluk":"Badnapura","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431002","Taluk":"Gangapur","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431136","Taluk":"Aurangabad","Districtname":"Aurangabad","statename":"MAHARASHTRA"},
    {"pincode":"431114","Taluk":"Bhorkardan","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431517","Taluk":"Georai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431153","Taluk":"Beed","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431143","Taluk":"Georai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"413207","Taluk":"Patoda","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431517","Taluk":"Ambejogai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431523","Taluk":"Ambejogai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431124","Taluk":"K Dharur","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414202","Taluk":"Ashti","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431131","Taluk":"Majalgaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431124","Taluk":"Dharur","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431126","Taluk":"Beed","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431127","Taluk":"Georai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431130","Taluk":"Georai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"413249","Taluk":"Shirur K","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431124","Taluk":"Kille Dahrur","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414203","Taluk":"Ashti","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431131","Taluk":"Manjlegaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431518","Taluk":"Ambajogai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431122","Taluk":"Beed","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431530","Taluk":"Parli Vaij.","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414208","Taluk":"Ashti","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414205","Taluk":"Beed","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431128","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414204","Taluk":"Patoda","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431129","Taluk":"Georai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431123","Taluk":"Kaij","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431518","Taluk":"Kaij","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431125","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431144","Taluk":"Wadwani","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431124","Taluk":"Wadwani","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431122","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431515","Taluk":"Parlivaij","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431144","Taluk":"Beed","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431126","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431515","Taluk":"Parli Vaij.","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431517","Taluk":"Ambajogai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414205","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"413229","Taluk":"Patoda","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431519","Taluk":"Ambajogai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"413249","Taluk":"Shirur (Kasar)","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431130","Taluk":"Gevrai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431519","Taluk":"Ambejogai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431128","Taluk":"Majalgaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431515","Taluk":"Parli","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431129","Taluk":"Majalgaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431143","Taluk":"Gevrai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"413229","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431142","Taluk":"Manjlegaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414205","Taluk":"Shirur K.","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431124","Taluk":"Kille Dharur","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431142","Taluk":"Majalgaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431144","Taluk":"Manjlegaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431153","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431125","Taluk":"Beed","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431128","Taluk":"Majalgoan","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431530","Taluk":"Parli","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414205","Taluk":"Patoda","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431144","Taluk":"Kaij","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431520","Taluk":"Parli","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431515","Taluk":"Parli Vaijinath","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431142","Taluk":"Majalgaon S.o.","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431144","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"414205","Taluk":"Shirur K","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"413249","Taluk":"Shirur","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431129","Taluk":"Bid","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431129","Taluk":"Manjlegaon","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431127","Taluk":"Gevrai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431530","Taluk":"Parli Vai.j","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"431517","Taluk":"Ambajgoai","Districtname":"Beed","statename":"MAHARASHTRA"},
    {"pincode":"425508","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425305","Taluk":"Bhusawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425303","Taluk":"Chopda","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425107","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425302","Taluk":"Yawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425507","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425108","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425524","Taluk":"Yawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425304","Taluk":"Yawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425306","Taluk":"Muktainagar","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425301","Taluk":"Yawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425503","Taluk":"Yawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425310","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425201","Taluk":"Bhusawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425203","Taluk":"Bhusawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425310","Taluk":"Bodwad","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425327","Taluk":"Muktai Nagar","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425505","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424208","Taluk":"Jalgoan","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425311","Taluk":"Bhusawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425506","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425307","Taluk":"Bhusawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425114","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424204","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424206","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425501","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425309","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425308","Taluk":"Bhusawal","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425504","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425502","Taluk":"Raver","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424207","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424205","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425508","Taluk":"Chopda","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424004","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425405","Taluk":"Shirpur","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425408","Taluk":"Sindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425403","Taluk":"Shinkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425404","Taluk":"Shindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424301","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424305","Taluk":"Sakri","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425415","Taluk":"Akkalkuwa","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425409","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"424318","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425413","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425407","Taluk":"Sindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425410","Taluk":"Shahade","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425417","Taluk":"Navapur","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425412","Taluk":"Nandurbar","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425427","Taluk":"Shirpur","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424006","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424304","Taluk":"Sakri","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425444","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425421","Taluk":"Shirpur","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424002","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425411","Taluk":"Nandurbar","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"424306","Taluk":"Sakri","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425419","Taluk":"Akkalkuwa","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425423","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"424310","Taluk":"Sakri","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425403","Taluk":"Sindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424303","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425406","Taluk":"Shindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425416","Taluk":"Navapur","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425452","Taluk":"Dhadgaon","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425426","Taluk":"Navapur","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425452","Taluk":"Akkalkuwa","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425414","Taluk":"Dhadgaon","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425410","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425442","Taluk":"Taloda","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425428","Taluk":"Shirpur","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424307","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424308","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424311","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424302","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425432","Taluk":"Shahad","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425407","Taluk":"Shindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424304","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425413","Taluk":"Shahada","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425422","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425408","Taluk":"Shindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425418","Taluk":"Nawapur","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425408","Taluk":"Shinkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425418","Taluk":"Navapur","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"424001","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424005","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425408","Taluk":"Sindkhede","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425424","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425432","Taluk":"Shahada","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"424309","Taluk":"Dhule","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425415","Taluk":"Dhadgaon","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425423","Taluk":"Nandurbar","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425414","Taluk":"Dhadaon","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425409","Taluk":"Shahade","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425432","Taluk":"Shahade","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425422","Taluk":"NA","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424303","Taluk":"Sakri","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425421","Taluk":"NA","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425403","Taluk":"Shindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425416","Taluk":"Nawapur","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425417","Taluk":"Nawapur","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425424","Taluk":"Shahade","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425415","Taluk":"Akkal Kuwa","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425407","Taluk":"Sindkhede","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"424309","Taluk":"Sindkheda","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425413","Taluk":"Taloda","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425442","Taluk":"Talode","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425452","Taluk":"Shahade","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425413","Taluk":"Talode","Districtname":"Nandurbar","statename":"MAHARASHTRA"},
    {"pincode":"425428","Taluk":"NA","Districtname":"Dhule","statename":"MAHARASHTRA"},
    {"pincode":"425111","Taluk":"Parola","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425110","Taluk":"Erandol","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424104","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424105","Taluk":"Bhadgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425420","Taluk":"Amalner","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425401","Taluk":"Amalner","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425401","Taluk":"Parola","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425002","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425103","Taluk":"Dharangaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424201","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425115","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425101","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425003","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425113","Taluk":"Parola","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424107","Taluk":"Chalisgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424119","Taluk":"Chalisgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425102","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425109","Taluk":"Erandol","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424106","Taluk":"Chalisgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425105","Taluk":"Dharangaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425402","Taluk":"Amalner","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425104","Taluk":"Dharangaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425116","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424108","Taluk":"Chalisgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424101","Taluk":"Chalisgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424203","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425116","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425112","Taluk":"Parola","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424103","Taluk":"Chalisgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424107","Taluk":"Bhadgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424102","Taluk":"Chalisgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425001","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424103","Taluk":"Bhadgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424202","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425105","Taluk":"Dharagaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425004","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424103","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425104","Taluk":"Pachora","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424119","Taluk":"Chalisgoan","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"424108","Taluk":"Chalisgoan","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"425115","Taluk":"Jalgaon","Districtname":"Jalgaon","statename":"MAHARASHTRA"},
    {"pincode":"423502","Taluk":"Kalwan","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423101","Taluk":"Chandwad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423201","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423208","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422209","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423301","Taluk":"Satana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423302","Taluk":"Satana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423204","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423402","Taluk":"Yeola","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423401","Taluk":"Yeola","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423104","Taluk":"Yeola","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423303","Taluk":"Satana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423205","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423501","Taluk":"Kalwan","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422205","Taluk":"Chandvad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423106","Taluk":"Nandgaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423102","Taluk":"Devla","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423117","Taluk":"Chandwad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422205","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423213","Taluk":"Satana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423212","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"424109","Taluk":"Nandgaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423110","Taluk":"Deola","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423206","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423105","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422209","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423101","Taluk":"Chandvad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423104","Taluk":"Chandwad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422305","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422308","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423403","Taluk":"Yeola","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422303","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423110","Taluk":"Devla","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423204","Taluk":"Satana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423202","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423110","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423104","Taluk":"Nandgaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423104","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423104","Taluk":"Yevla","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423101","Taluk":"Chnadwad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422304","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422306","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423104","Taluk":"Chandvad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423403","Taluk":"Yeola.","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423213","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422306","Taluk":"NA","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423203","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423501","Taluk":"Kalwn","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422303","Taluk":"NA","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423501","Taluk":"Satana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423111","Taluk":"Chandvad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423108","Taluk":"Malegaon","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423111","Taluk":"Chandwad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423101","Taluk":"Chandwd","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422304","Taluk":"NA","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422305","Taluk":"NA","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423102","Taluk":"Kalwan","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"423401","Taluk":"Yevla","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"431736","Taluk":"Biloli","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431708","Taluk":"Loha","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431808","Taluk":"Dharmabad","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431723","Taluk":"Degloor","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431804","Taluk":"Kinwat","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431714","Taluk":"Kandhar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431715","Taluk":"Mukhed","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431811","Taluk":"Kinwat","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431803","Taluk":"Kinwat","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431721","Taluk":"Mahore","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431803","Taluk":"NA","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431704","Taluk":"Ardhapur","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431711","Taluk":"Biloli","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431710","Taluk":"Biloli","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431605","Taluk":"NA","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431712","Taluk":"Hadgaon","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431606","Taluk":"Himayatnagar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431807","Taluk":"Umari","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431717","Taluk":"Degloor","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431745","Taluk":"Mudkhed","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431715","Taluk":"Himayatnagar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431745","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431602","Taluk":"Naigaon","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431746","Taluk":"Kandhar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431710","Taluk":"Biloi","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431710","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431801","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431743","Taluk":"Hadgaon","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431741","Taluk":"Degloor","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431810","Taluk":"Kiwat","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431810","Taluk":"Kinwat","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431742","Taluk":"Kandhr","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431805","Taluk":"Kinwat","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431714","Taluk":"Kandahr","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431704","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431605","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431604","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431808","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431806","Taluk":"Mudkhed","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431809","Taluk":"Dharmabad","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431712","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431603","Taluk":"Nanded","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431707","Taluk":"Loha","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431716","Taluk":"Mukhed","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431719","Taluk":"Mukhed","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431709","Taluk":"Naigaon","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431717","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431603","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431809","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431602","Taluk":"Nanded","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431713","Taluk":"Hadgaon","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431802","Taluk":"Himayatnagar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431722","Taluk":"Naigaon","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431718","Taluk":"Degloor","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431731","Taluk":"Biloli","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431742","Taluk":"Kandhar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431809","Taluk":"Biloli","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431714","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431602","Taluk":"Loha","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431804","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431707","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431805","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431811","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431605","Taluk":"Nanded","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431602","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431721","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431750","Taluk":"Ardhapur","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431722","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431601","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431806","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431719","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431601","Taluk":"Nanded","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431716","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431743","Taluk":"Nanded","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431731","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431736","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431801","Taluk":"Bvhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431606","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431606","Taluk":"Nanded","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431807","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"431709","Taluk":"Bhokar","Districtname":"Nanded","statename":"MAHARASHTRA"},
    {"pincode":"422003","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422502","Taluk":"Sinnar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422215","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422004","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422211","Taluk":"Surgana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422212","Taluk":"Trimbakeshwar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422208","Taluk":"Peint","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422207","Taluk":"NA","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422010","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422003","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422007","Taluk":"Surgana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422204","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422213","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422012","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422403","Taluk":"Igatpuri","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422202","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422301","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422103","Taluk":"Sinnar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422401","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422204","Taluk":"Trimabakeshwar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422502","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422210","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422606","Taluk":"Sinnar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422005","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422007","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422102","Taluk":"Sinnar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422001","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422201","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422204","Taluk":"Trimbakeshwar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422003","Taluk":"Surgana","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422009","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422104","Taluk":"Sinnar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422003","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422112","Taluk":"Sinnar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422203","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422214","Taluk":"Trimbakeshwar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422501","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422302","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422011","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422006","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422222","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422402","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422002","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422204","Taluk":"Trimbak","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422101","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422113","Taluk":"Sinnar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422102","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422207","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422207","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422402","Taluk":"Igatpuri","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422007","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422213","Taluk":"Trimbakeshwar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422211","Taluk":"Kalwan","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422105","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422202","Taluk":"Dindoir","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422103","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422203","Taluk":"Trimbakeshwar","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422004","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422204","Taluk":"Peth","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422221","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422206","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422207","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422007","Taluk":"Peint","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422215","Taluk":"Chandwad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422502","Taluk":"Igatpuri","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422102","Taluk":"Niphad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422013","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422215","Taluk":"Chanwad","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422403","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422204","Taluk":"Peint","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422212","Taluk":"Trimbak","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422008","Taluk":"Nashik","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422214","Taluk":"Igatpuri","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"422203","Taluk":"Dindori","Districtname":"Nashik","statename":"MAHARASHTRA"},
    {"pincode":"413521","Taluk":"Nilanga","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413602","Taluk":"Tuljapur","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413515","Taluk":"Ahmedpur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413544","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413582","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413520","Taluk":"Ausa","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413605","Taluk":"Omerga","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413530","Taluk":"Nilanga","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413505","Taluk":"Paranda","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413507","Taluk":"Kallam","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413603","Taluk":"Tuljapur","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413523","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413513","Taluk":"Chakur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413521","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413502","Taluk":"Paranda","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413504","Taluk":"Bhoom","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413601","Taluk":"Tuljapur","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413509","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413604","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413581","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413532","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413524","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413606","Taluk":"Omerga","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413522","Taluk":"Nilanga","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413528","Taluk":"Kallam","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413517","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413531","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413607","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413506","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413525","Taluk":"Kallam","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413608","Taluk":"Lohara","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413511","Taluk":"Latur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413527","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"431522","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413519","Taluk":"Deoni","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413514","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413582","Taluk":"Osmanabad","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413607","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413516","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413603","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413405","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413517","Taluk":"Shirur-Anantpal","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413503","Taluk":"Vashi","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413520","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413623","Taluk":"Tuljapur","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413508","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413517","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413527","Taluk":"Renapur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413527","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413534","Taluk":"Bhoom","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413526","Taluk":"Bhoom","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413518","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413531","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413512","Taluk":"Latur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413514","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413515","Taluk":"Ahmedpuer","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413532","Taluk":"Jalkot","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413529","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413516","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413581","Taluk":"Chakur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413607","Taluk":"Nilanga","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413624","Taluk":"Tuljapur","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413516","Taluk":"Ausa","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413523","Taluk":"Ahmedpur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413532","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413521","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413503","Taluk":"Washi","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413581","Taluk":"NA","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413531","Taluk":"Latur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413510","Taluk":"Latur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413510","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413524","Taluk":"Chakur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413510","Taluk":"Kalamb","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413606","Taluk":"Umarga","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413603","Taluk":"Kalamb","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413501","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"431522","Taluk":"Renapur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413518","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413544","Taluk":"Shirur Anantpal","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413514","Taluk":"Ahmedpur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413511","Taluk":"Latur","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413513","Taluk":"Ahmadpur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413580","Taluk":"Osmanabad","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413604","Taluk":"Umarga","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413517","Taluk":"Udgir","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413524","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"413518","Taluk":"Udgir","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413529","Taluk":"Chakur","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413502","Taluk":"Paranda","Districtname":"Latur","statename":"MAHARASHTRA"},
    {"pincode":"413529","Taluk":"NA","Districtname":"Osmanabad","statename":"MAHARASHTRA"},
    {"pincode":"431513","Taluk":"Hingoli","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431705","Taluk":"Basmath","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431503","Taluk":"Sailu","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431511","Taluk":"Purna","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431703","Taluk":"Sengaon","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431504","Taluk":"Mantha","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431701","Taluk":"Kalamnuri","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431501","Taluk":"Basmath","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431512","Taluk":"Partur","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431510","Taluk":"Jintur","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431501","Taluk":"Partur","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431512","Taluk":"Basmath","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431540","Taluk":"Parbhani","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431509","Taluk":"Jintur","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431507","Taluk":"NA","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431402","Taluk":"Parbhani","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431705","Taluk":"Aundha Nagnath","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431702","Taluk":"Kalamnuri","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431514","Taluk":"Gangakhed","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431536","Taluk":"Palam","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431512","Taluk":"Basmathnagar","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431501","Taluk":"Mantha","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431506","Taluk":"Pathri","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431542","Taluk":"Sengaon","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431521","Taluk":"Parbhani","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431537","Taluk":"Parbhani","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431508","Taluk":"Jintur","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431509","Taluk":"Sailu","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431509","Taluk":"Aundha Nagnath","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431505","Taluk":"Manwath","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431521","Taluk":"Gangakhed","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431505","Taluk":"Sailu","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431516","Taluk":"Sonpeth","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431705","Taluk":"Hingoli","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431540","Taluk":"Jintur","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431536","Taluk":"Gangakhed","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431507","Taluk":"Partur","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431541","Taluk":"Manwath","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431702","Taluk":"Hingoli","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431402","Taluk":"Purna","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431720","Taluk":"Palam","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431502","Taluk":"Partur","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431506","Taluk":"Sailu","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431401","Taluk":"Parbhani","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431702","Taluk":"Aundha Nagnath","Districtname":"Hingoli","statename":"MAHARASHTRA"},
    {"pincode":"431541","Taluk":"Pathri","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431504","Taluk":"NA","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431501","Taluk":"NA","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431503","Taluk":"Mantha","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431502","Taluk":"NA","Districtname":"Jalna","statename":"MAHARASHTRA"},
    {"pincode":"431508","Taluk":"Sailu","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"431505","Taluk":"Pathri","Districtname":"Parbhani","statename":"MAHARASHTRA"},
    {"pincode":"403801","Taluk":"NA","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403801","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403503","Taluk":"Bicholim","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403530","Taluk":"Sattari","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403401","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403512","Taluk":"Pernem","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403702","Taluk":"Canacona","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403508","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403521","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403521","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403705","Taluk":"Quepem","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403723","Taluk":"Salcate","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403107","Taluk":"Sanquelim","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403509","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403601","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403524","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403516","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403507","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403714","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403701","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403503","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403802","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403703","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403201","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403202","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403107","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403703","Taluk":"Quepem","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403704","Taluk":"Sangaum","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403716","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403713","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403101","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403107","Taluk":"Ponda","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403409","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403723","Taluk":"Salsate","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403529","Taluk":"Bicholim","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403504","Taluk":"Bicholim","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403506","Taluk":"Sattari","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403806","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403001","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403706","Taluk":"Quepem","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403523","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403718","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403510","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403406","Taluk":"Ponda","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403515","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403712","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403110","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403002","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403505","Taluk":"Bicholim","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403717","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403731","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403711","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403006","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403715","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403102","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403410","Taluk":"Quepem","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403708","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403513","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403710","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403107","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403115","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403115","Taluk":"Tiswadi","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403709","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403707","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403725","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403602","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403702","Taluk":"Cancona","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403206","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403108","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403503","Taluk":"Pernem","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403505","Taluk":"Sattari","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403728","Taluk":"Canacona","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403403","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403527","Taluk":"Pernem","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403104","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403802","Taluk":"Marmugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403404","Taluk":"Ponda","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403404","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403601","Taluk":"Salcette","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403001","Taluk":"Tiswaddi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403410","Taluk":"Sanguem","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403803","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403109","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403729","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403114","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403004","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403723","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403724","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403517","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403513","Taluk":"Pernem","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403105","Taluk":"Sattari","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403706","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403512","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403203","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403114","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403401","Taluk":"Ponda","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403501","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403524","Taluk":"Pernem","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403706","Taluk":"Curchorem","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403720","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403705","Taluk":"Sangaum","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403804","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403511","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403710","Taluk":"Marmugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403704","Taluk":"Sanguem","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403106","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403005","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403719","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403103","Taluk":"Ponda","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403502","Taluk":"Bicholim","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403204","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403710","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403406","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403526","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403502","Taluk":"Bardez","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403721","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403105","Taluk":"Ponda","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403402","Taluk":"Tiswadi","Districtname":"North Goa","statename":"GOA"},
    {"pincode":"403722","Taluk":"Salcete","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"403726","Taluk":"Mormugao","Districtname":"South Goa","statename":"GOA"},
    {"pincode":"416236","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416509","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416210","Taluk":"Bhudargad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416526","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416505","Taluk":"Ajara","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416212","Taluk":"Radhanagari","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416106","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416144","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416214","Taluk":"Shahuwadi","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416104","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416109","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416112","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416215","Taluk":"Shahuwadi","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416201","Taluk":"Panhala","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416552","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416229","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416204","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416235","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416114","Taluk":"Panhala","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416220","Taluk":"Ajra","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416220","Taluk":"Ajara","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416001","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416120","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416208","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416504","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416206","Taluk":"Gaganbavada","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416118","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416502","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416213","Taluk":"Shahuwadi","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416211","Taluk":"Radhanagari","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416012","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416221","Taluk":"Radhanagari","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416011","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416110","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416205","Taluk":"Panhala","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416218","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416010","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416232","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416230","Taluk":"Panhala","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416209","Taluk":"Bhudargad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416507","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416506","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416503","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416011","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416204","Taluk":"Panhala","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416223","Taluk":"Bhudargad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416002","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416229","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416112","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"415101","Taluk":"Shahuwadi","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416116","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416119","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416101","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416207","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416107","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416102","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416507","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416207","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416231","Taluk":"Radhanagari","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416501","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416119","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416105","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416116","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416505","Taluk":"NA","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416103","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416001","Taluk":"Radhanagari","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416013","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416216","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416005","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416504","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416202","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416109","Taluk":"Hatkanagale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416501","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416005","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416508","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416203","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416115","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416143","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416113","Taluk":"Panhala","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416121","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416551","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416122","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416101","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416113","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416007","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416234","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416219","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416006","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416111","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416551","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416108","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416110","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416003","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416001","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416235","Taluk":"Gadhinglaj","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416111","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416001","Taluk":"Karveern","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416505","Taluk":"Ajra","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416109","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416234","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416223","Taluk":"NA","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416122","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416122","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416113","Taluk":"Shirol","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416211","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416146","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416202","Taluk":"Hatkanagale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416010","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416008","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416121","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416013","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416203","Taluk":"Hatkanagale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416143","Taluk":"Hatkanagale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416203","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416118","Taluk":"Hatkanagale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416011","Taluk":"Hatkanangle","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416526","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416143","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416004","Taluk":"Karvir","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416236","Taluk":"NA","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416527","Taluk":"Chandgad","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416112","Taluk":"Hatkanagale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416230","Taluk":"Karveer","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416221","Taluk":"Kagal","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"416146","Taluk":"Hatkanangale","Districtname":"Kolhapur","statename":"MAHARASHTRA"},
    {"pincode":"415726","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415802","Taluk":"Lanja","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415714","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416707","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415604","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415705","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415712","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415616","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415606","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416704","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415603","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415709","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415804","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415608","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415804","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415730","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415605","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415637","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415641","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415806","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415203","Taluk":"Mandangad","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416702","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415803","Taluk":"Lanja","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415634","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415610","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415703","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415643","Taluk":"Lanja","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416712","Taluk":"Lanja","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415708","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415711","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415730","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415713","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415722","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415708","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415727","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415701","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415208","Taluk":"Mandangad","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416701","Taluk":"Lanja","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415629","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415801","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415621","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415612","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415639","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415628","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416713","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415715","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415608","Taluk":"Snagmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416702","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415720","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415807","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415620","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415619","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415609","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415724","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415640","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415728","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415605","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415803","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415718","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415706","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415803","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415805","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415710","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415611","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415807","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415718","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415608","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415626","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415710","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415615","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415639","Taluk":"Sanmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415702","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416705","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416712","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415614","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415712","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415716","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415613","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416704","Taluk":"Vaibhavwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"415621","Taluk":"Khed (rtg)","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415702","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415727","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415719","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415610","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415640","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416707","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416709","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415726","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415717","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415620","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415803","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415640","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415709","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415709","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415715","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415637","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415617","Taluk":"Ratnagiri","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415613","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415607","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415602","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415202","Taluk":"Mandangad","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415708","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415722","Taluk":"Khed","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415203","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415639","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416705","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415607","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415214","Taluk":"Mandangad","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415713","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415214","Taluk":"Dapoli","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415719","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415601","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415612","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415634","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415729","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415611","Taluk":"Sanmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415715","Taluk":"Javali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415641","Taluk":"Sangmeshwar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415715","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415701","Taluk":"Guhagar","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415643","Taluk":"Rajapur","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"415718","Taluk":"Chiplun","Districtname":"Ratnagiri","statename":"MAHARASHTRA"},
    {"pincode":"416403","Taluk":"Kavathe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416411","Taluk":"K Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415311","Taluk":"Khanapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415401","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415409","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416419","Taluk":"Kavathe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416308","Taluk":"Palus","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415305","Taluk":"Kadegaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415303","Taluk":"Kadegaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416310","Taluk":"Palus","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416311","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416412","Taluk":"Jat","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416402","Taluk":"Jat","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416416","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416316","Taluk":"Palus","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416404","Taluk":"Jat","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415408","Taluk":"Shirala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416401","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415415","Taluk":"Shirala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416312","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416301","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415301","Taluk":"Atpadi","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416303","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416404","Taluk":"Jath","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415315","Taluk":"Atpadi","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416302","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415411","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415307","Taluk":"Khanapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416309","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416413","Taluk":"Jat","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415410","Taluk":"Shirala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416436","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416410","Taluk":"Kavathe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415306","Taluk":"Atpadi","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416301","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416410","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416302","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415302","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415310","Taluk":"Khanapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416303","Taluk":"Palus","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415309","Taluk":"Khanapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416420","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415302","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415402","Taluk":"Shirala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416304","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415413","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416416","Taluk":"Palus","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415405","Taluk":"Shirala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415310","Taluk":"Khnapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415412","Taluk":"Shirala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416402","Taluk":"Jath","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415303","Taluk":"Palus","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415401","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416405","Taluk":"K. Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416420","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416305","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416407","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416403","Taluk":"Jat","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416315","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416408","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415308","Taluk":"Atpadi","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416309","Taluk":"Palus","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416403","Taluk":"Kavthe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416415","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416314","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415311","Taluk":"Kahanapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415403","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416405","Taluk":"K Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416313","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415304","Taluk":"Kadegaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415406","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415415","Taluk":"Shiala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415403","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416306","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416312","Taluk":"Khanapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416408","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415311","Taluk":"Kahanpur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415404","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416307","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416405","Taluk":"Kavathe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416417","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415311","Taluk":"Kadegaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416418","Taluk":"Kavathe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416405","Taluk":"K Mhankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416306","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415411","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416406","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415407","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416312","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415413","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416409","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415406","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415307","Taluk":"Kahanapur","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415407","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416310","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416411","Taluk":"Kavathe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415407","Taluk":"Shirala","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415414","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415414","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416418","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416405","Taluk":"Jath","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416419","Taluk":"Kavthe Mahankal","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416437","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415313","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415404","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415412","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416301","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415313","Taluk":"Walva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416413","Taluk":"Jath","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"415409","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416313","Taluk":"Valva","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416414","Taluk":"Miraj","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416416","Taluk":"Tasgaon","Districtname":"Sangli","statename":"MAHARASHTRA"},
    {"pincode":"416614","Taluk":"Malvan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416810","Taluk":"Vaibhavwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416516","Taluk":"Vengurla","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416518","Taluk":"Sawantwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416510","Taluk":"Sawantwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416813","Taluk":"Vaibhavwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416605","Taluk":"Malvan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416628","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416812","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416520","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416518","Taluk":"Vengurla","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416611","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416513","Taluk":"Sawantwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416514","Taluk":"Sawantwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416610","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416602","Taluk":"Kankavli","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416521","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416512","Taluk":"Dodamarg","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416511","Taluk":"Sawantwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416608","Taluk":"Malvan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416613","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416601","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416632","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416609","Taluk":"Kankavli","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416523","Taluk":"Vengurla","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416606","Taluk":"NA","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416603","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416616","Taluk":"Kankal I","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416811","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416604","Taluk":"Malvan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416524","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416612","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416630","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416525","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416606","Taluk":"Malvan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416519","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416801","Taluk":"Kankalvi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416534","Taluk":"Malvan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416549","Taluk":"Dodamarg","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416806","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416616","Taluk":"Kankalvi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416626","Taluk":"Malvan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416529","Taluk":"Vengurla","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416528","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416521","Taluk":"Sindhudurgkudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416531","Taluk":"Sawantwadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416605","Taluk":"Malwan","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416620","Taluk":"Kankavli","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416801","Taluk":"Kankavli","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416703","Taluk":"Kankavli","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416522","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416616","Taluk":"Kankavli","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416703","Taluk":"Kankalvi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416550","Taluk":"Kudal","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416606","Taluk":"Malvsn","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416615","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416623","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416803","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416805","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416804","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416517","Taluk":"Vengurla","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416616","Taluk":"Kankavi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416611","Taluk":"Kanakvli","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416801","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416807","Taluk":"Devgad","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416515","Taluk":"Vengurla","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"416511","Taluk":"Sawanwtadi","Districtname":"Sindhudurg","statename":"MAHARASHTRA"},
    {"pincode":"400037","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400003","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400012","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400012","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400009","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400033","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400014","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400010","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400031","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400009","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400015","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400033","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400027","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400037","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400029","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400099","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400069","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400053","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400058","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400051","Taluk":"Bandra","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400051","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400050","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400093","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400052","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400057","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400056","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400059","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400049","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400061","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400054","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400055","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400096","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400098","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400094","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400085","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400084","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400075","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400082","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400042","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400078","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400074","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400071","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400089","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400022","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400043","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400086","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400088","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400083","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400070","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400070","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400019","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400081","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400080","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400024","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400087","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400076","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400079","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400077","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400022","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400072","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400065","Taluk":"Goregaon East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400104","Taluk":"Goregaon West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400066","Taluk":"Borivlai East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400091","Taluk":"Borivali West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400092","Taluk":"Borivali West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400067","Taluk":"Kandivlai West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400068","Taluk":"Dahisar West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400068","Taluk":"Dahisar East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400066","Taluk":"Borivali East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400063","Taluk":"Goregaon East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400095","Taluk":"Malad West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400060","Taluk":"Jogeshwari East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400102","Taluk":"Jogeshwari West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400101","Taluk":"Kandivali East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400067","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400067","Taluk":"Kandivali West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400064","Taluk":"Malad West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400097","Taluk":"Malad East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400103","Taluk":"Borivali West","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400065","Taluk":"Jogeshwari East","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400004","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400005","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400001","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400020","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400005","Taluk":"NA","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400001","Taluk":"Uran","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400032","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400002","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400021","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400011","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400007","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400028","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400030","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400026","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400013","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400017","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400008","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400034","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400016","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400006","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400025","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400035","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"400018","Taluk":"Mumbai","Districtname":"Mumbai","statename":"MAHARASHTRA"},
    {"pincode":"410206","Taluk":"Panvel","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400708","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400708","Taluk":"Digha","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"410221","Taluk":"Panvel","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410201","Taluk":"Panvel","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410207","Taluk":"Panvel","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410206","Taluk":"Uran","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410201","Taluk":"Karjat","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400614","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400702","Taluk":"Uran","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410101","Taluk":"Karjat","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410206","Taluk":"Khalapur","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400706","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"410208","Taluk":"Panvel","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410203","Taluk":"Khalapur","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400701","Taluk":"Rabale","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"410216","Taluk":"Karjat","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400707","Taluk":"Uran","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400703","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"410218","Taluk":"Panvel","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410201","Taluk":"NA","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410202","Taluk":"Khalapur","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410210","Taluk":"Panvel","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410204","Taluk":"Khopoli","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410203","Taluk":"Khopoli","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400709","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"410102","Taluk":"Karjat","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410220","Taluk":"Khalapur","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400710","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"410222","Taluk":"Khalapur","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400704","Taluk":"Uran","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410208","Taluk":"Uran","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410206","Taluk":"Karjat","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400706","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"410201","Taluk":"Akrjat","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"400703","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"402305","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402303","Taluk":"Poladpur","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402403","Taluk":"Shriwardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402103","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402401","Taluk":"Murud","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402203","Taluk":"Shriwardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402126","Taluk":"Roha","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402204","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402201","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402307","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402107","Taluk":"Pen","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402101","Taluk":"Mhasla","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402301","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402106","Taluk":"Roha","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402113","Taluk":"Shrivardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402109","Taluk":"Roha","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410205","Taluk":"Sudhagad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402208","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402114","Taluk":"Shriwardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402104","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402203","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402306","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402120","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402404","Taluk":"Shriwardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402302","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402308","Taluk":"Roha","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402111","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402110","Taluk":"Shrivardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402302","Taluk":"NA","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402202","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402122","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402105","Taluk":"Mhasla","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402113","Taluk":"Shriwardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"415213","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402304","Taluk":"Roha","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402109","Taluk":"Murud","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402102","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402117","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402402","Taluk":"Shriwardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402107","Taluk":"NA","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402110","Taluk":"Shriwardhan","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402103","Taluk":"NA","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402209","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"410205","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402115","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402309","Taluk":"Mahad","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402108","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402112","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402106","Taluk":"NA","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402308","Taluk":"Mangaon","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402125","Taluk":"Pen","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402401","Taluk":"NA","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402116","Taluk":"Roha","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402111","Taluk":"Tala","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402207","Taluk":"Alibag","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"402202","Taluk":"Murud","Districtname":"Raigarh(MH)","statename":"MAHARASHTRA"},
    {"pincode":"421506","Taluk":"Ambarnath","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421601","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421601","Taluk":"Shahapur","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421302","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421501","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421501","Taluk":"Ambarnath","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400610","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421102","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421503","Taluk":"Ambarnath","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421402","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400608","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421002","Taluk":"Ambarnath","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421603","Taluk":"Shahapur","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421308","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421603","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421002","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421312","Taluk":"Wada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421503","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400607","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400612","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421302","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421402","Taluk":"Shahapur","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421203","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421201","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421306","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421403","Taluk":"Shahapur","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400602","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421505","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400606","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400605","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421301","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421602","Taluk":"Shahapur","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400615","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421401","Taluk":"Murbad","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421605","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421204","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421312","Taluk":"Wade","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421403","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421311","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400603","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421312","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421103","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421602","Taluk":"NA","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400602","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421505","Taluk":"Ambarnath","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421502","Taluk":"Ambarnath","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421101","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421101","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400608","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421002","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400601","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421004","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421005","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421305","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421202","Taluk":"Kalyan","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"400604","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421303","Taluk":"Wada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401606","Taluk":"Talasari","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401102","Taluk":"Mokhada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401301","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401602","Taluk":"Mokhada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401501","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421303","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401302","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401103","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401604","Taluk":"Mokhada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401602","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401403","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401601","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421303","Taluk":"Vada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401202","Taluk":"Bassein","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401201","Taluk":"Bassein","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401501","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401303","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401105","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401101","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401403","Taluk":"Vikramgad","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401201","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401701","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401701","Taluk":"Talasari","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401503","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401606","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401603","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401605","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401608","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401502","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401102","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401303","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401610","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401405","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401603","Taluk":"Jawhar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401106","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401206","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401203","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401107","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401702","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401208","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401304","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401602","Taluk":"Talasari","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401603","Taluk":"Vikramgad","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401607","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401204","Taluk":"Vada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401401","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401402","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401605","Taluk":"Vikramgad","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401703","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401606","Taluk":"Vikramgad","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"421303","Taluk":"Vikramgad","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401107","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401207","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401209","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401404","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401504","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401603","Taluk":"Mokhada","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401101","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401609","Taluk":"Dahanu","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401607","Taluk":"Vikramgad","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401506","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401405","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401202","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401106","Taluk":"Thane","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401204","Taluk":"Bhiwandi","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401103","Taluk":"Palghar","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"401305","Taluk":"Vasai","Districtname":"Thane","statename":"MAHARASHTRA"},
    {"pincode":"444126","Taluk":"Akot","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444126","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444510","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444108","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444006","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444002","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444001","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444101","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444101","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444101","Taluk":"Akot","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444401","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444511","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444503","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444107","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444311","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444507","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444102","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444003","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444409","Taluk":"NA","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444409","Taluk":"Mangrulpir","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444404","Taluk":"Mangrulpir","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444501","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444104","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444302","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444117","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444106","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444401","Taluk":"Barshitakli","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444302","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444110","Taluk":"NA","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444506","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444505","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444407","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444002","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444102","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444004","Taluk":"NA","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444511","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444506","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444504","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444103","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444403","Taluk":"Mangrulpir","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444111","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444502","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444109","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444004","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444005","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444105","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444405","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444103","Taluk":"Telhara","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444507","Taluk":"NA","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444105","Taluk":"NA","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444005","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444111","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444110","Taluk":"Karanja","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444107","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444402","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444501","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444106","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444403","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444404","Taluk":"Washim","Districtname":"Washim","statename":"MAHARASHTRA"},
    {"pincode":"444107","Taluk":"Murtijapur","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444126","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444006","Taluk":"Akola","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444108","Taluk":"Telhara","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444108","Taluk":"NA","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444109","Taluk":"Balapur","Districtname":"Akola","statename":"MAHARASHTRA"},
    {"pincode":"444806","Taluk":"Achalpur City","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444901","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444705","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444706","Taluk":"Daryapur","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444801","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444908","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444905","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444717","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444601","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444802","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444904","Taluk":"Chandur Raiway","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444906","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444602","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444601","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444701","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444705","Taluk":"Anjangaon","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444709","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444705","Taluk":"Daryapur","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444806","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444806","Taluk":"Chandur Bazar","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444701","Taluk":"Amaravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444904","Taluk":"Chandur Railway","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444702","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444723","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444704","Taluk":"Chandur Bazar","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444908","Taluk":"Warud","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444719","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444704","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444808","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444708","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444720","Taluk":"Chandur Bazar","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444904","Taluk":"Chandur Rly.","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444805","Taluk":"Paratwada","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444807","Taluk":"Chikhaldara","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444813","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444807","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444606","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444806","Taluk":"Paratwada","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444707","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444802","Taluk":"Daryapur","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444803","Taluk":"Daryapur","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444717","Taluk":"Achalpur City","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444709","Taluk":"Dhamangaon R.s.","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444709","Taluk":"Dhamangaon","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444710","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444702","Taluk":"Dharni","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444711","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444720","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444902","Taluk":"Teosa","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444605","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444905","Taluk":"Morshi","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444719","Taluk":"Dharni","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444809","Taluk":"Chandur Bazar","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444809","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444602","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444903","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444804","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444709","Taluk":"Chandur Rly","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444906","Taluk":"Warud","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444711","Taluk":"Dhamangaon Rly","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444902","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444708","Taluk":"Nandgaon Khandeshwar","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444901","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444707","Taluk":"Morshi","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444808","Taluk":"Achalpur City","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444804","Taluk":"Bhatkuli","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444704","Taluk":"Morshi","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444607","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444907","Taluk":"NA","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444813","Taluk":"Dharni","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444907","Taluk":"Warud","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444801","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444603","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444723","Taluk":"Chandur Bazar","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444810","Taluk":"Chandur Bazar","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444710","Taluk":"Dhamangaon Rly","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444705","Taluk":"Daryapu","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444903","Taluk":"Tiosa","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444604","Taluk":"Amravati","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444706","Taluk":"Bhatkuli","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444801","Taluk":"Bhatkuli","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"444802","Taluk":"Bhatkuli","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"443203","Taluk":"Sindkhed Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443403","Taluk":"Khamgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444203","Taluk":"Shegaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443103","Taluk":"Motala","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443404","Taluk":"Nandura","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443206","Taluk":"Deulgaon Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443201","Taluk":"Deulgaon Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444303","Taluk":"Khamgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444312","Taluk":"Shegaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444301","Taluk":"Chikhli","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443301","Taluk":"Mehkar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443301","Taluk":"Lonar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443303","Taluk":"Mehkar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443402","Taluk":"Jalgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443101","Taluk":"Motala","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444204","Taluk":"Sonala","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443102","Taluk":"Malkapur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443106","Taluk":"Buldana","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443002","Taluk":"Buldana","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443101","Taluk":"Malkapur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444306","Taluk":"Khamgoan","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443404","Taluk":"Shegaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443202","Taluk":"Lonar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443202","Taluk":"Sindkhed Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444202","Taluk":"Sangrampur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443204","Taluk":"Deulgaon Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443201","Taluk":"Chikhli","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444306","Taluk":"Kahmgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443001","Taluk":"Buldana","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443401","Taluk":"Nandura","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443308","Taluk":"Sindkhed Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443112","Taluk":"Malkapur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444301","Taluk":"Khamgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443403","Taluk":"Khamgoan","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443002","Taluk":"Budlana","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443301","Taluk":"Sindkhed Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443304","Taluk":"Mehkar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443104","Taluk":"Motala","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444306","Taluk":"Khamgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443401","Taluk":"Malkapur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443206","Taluk":"Deulgaon Mahi","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443308","Taluk":"Sinkhed Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444312","Taluk":"Khamgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443302","Taluk":"Lonar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443201","Taluk":"Dulgaon Raja","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444303","Taluk":"Khamgoan","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444303","Taluk":"Shegaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443106","Taluk":"NA","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444301","Taluk":"Chikhali","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443402","Taluk":"Jalgaon (Jamod)","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444201","Taluk":"Sangrampur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444203","Taluk":"Shegan","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444204","Taluk":"Sangrampur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443002","Taluk":"Chikhli","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443102","Taluk":"Motala","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444304","Taluk":"Shegaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443102","Taluk":"Nandura","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443302","Taluk":"Mehkar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443101","Taluk":"Sangrampur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443002","Taluk":"NA","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443402","Taluk":"NA","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444306","Taluk":"Mehkar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443403","Taluk":"Jalgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443103","Taluk":"NA","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443402","Taluk":"Sangrampur","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"444303","Taluk":"NA","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443302","Taluk":"Loanr","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443202","Taluk":"Mehkar","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443101","Taluk":"Jalgaon","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"443404","Taluk":"Nanadura","Districtname":"Buldhana","statename":"MAHARASHTRA"},
    {"pincode":"442707","Taluk":"Mulchera","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442904","Taluk":"Chimur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441206","Taluk":"Bramhapuri","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442905","Taluk":"Rajura","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442705","Taluk":"Aheri","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442702","Taluk":"Gondipipri","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442703","Taluk":"Aheri","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441221","Taluk":"Nagbhir","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442906","Taluk":"Chimur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442903","Taluk":"Chimur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442605","Taluk":"Gadchiroli","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442903","Taluk":"Chamorshi","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442603","Taluk":"Chamorshi","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441207","Taluk":"Desaiganj","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442914","Taluk":"Warora","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441207","Taluk":"Desaiganj","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441217","Taluk":"Kurkheda","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442707","Taluk":"Chamorshi","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442504","Taluk":"Sironcha","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441223","Taluk":"Sindewahi","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441215","Taluk":"Saoli","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442917","Taluk":"Korphana","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441208","Taluk":"Armori","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441209","Taluk":"Korchi","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442906","Taluk":"Bhadrawati","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442917","Taluk":"Korpana","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442403","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442908","Taluk":"Korphana","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442901","Taluk":"Ballarpur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442701","Taluk":"Ballarpur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442605","Taluk":"Sironcha","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441226","Taluk":"Mul","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442604","Taluk":"Chamorshi","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442902","Taluk":"Bhadrawati","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441217","Taluk":"Armori","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442710","Taluk":"Bhamragadh","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442908","Taluk":"Rajura","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442906","Taluk":"Warora","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441221","Taluk":"Nagbhri","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441224","Taluk":"Mul","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441225","Taluk":"Saoli","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442704","Taluk":"Etapalli","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442502","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442702","Taluk":"Gondpipri","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442709","Taluk":"Aheri","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441209","Taluk":"Kurkheda","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442402","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442401","Taluk":"Chandrapur Ho","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442501","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442404","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442908","Taluk":"Chandur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442906","Taluk":"NA","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442907","Taluk":"Warora","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442606","Taluk":"Dhanora","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441224","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441212","Taluk":"Mul","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442918","Taluk":"Mul","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442918","Taluk":"Pombhurna","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442916","Taluk":"Korpana","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442505","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441226","Taluk":"Gondpipri","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441205","Taluk":"Nagbhid","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442404","Taluk":"NA","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442704","Taluk":"Ettapalli","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441225","Taluk":"Mul","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441215","Taluk":"Sindewahi","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442605","Taluk":"NA","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441221","Taluk":"Narbhir","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441222","Taluk":"Sindewahi","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442507","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442504","Taluk":"Sironcha","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442709","Taluk":"Sironcha","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442908","Taluk":"Jiwati","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442908","Taluk":"Korpana","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442503","Taluk":"Bhadrawati","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442603","Taluk":"Chamroshid","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442710","Taluk":"Bhamragadh","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442710","Taluk":"Aheri","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"441206","Taluk":"Nagbhir","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442406","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442404","Taluk":"Bhadrawati","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441205","Taluk":"Nabhid","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442917","Taluk":"Jiwati","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442507","Taluk":"Ballarpur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442908","Taluk":"NA","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442903","Taluk":"Chimur Bo","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441222","Taluk":"NA","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441205","Taluk":"NA","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441212","Taluk":"Saoli","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442606","Taluk":"Sawali","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442904","Taluk":"Sawali","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442902","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442401","Taluk":"Chandrapur","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"441221","Taluk":"Nagbhid","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442707","Taluk":"Chamorhsi","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"442701","Taluk":"Gondpipri","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442907","Taluk":"NA","Districtname":"Chandrapur","statename":"MAHARASHTRA"},
    {"pincode":"442606","Taluk":"NA","Districtname":"Gadchiroli","statename":"MAHARASHTRA"},
    {"pincode":"440010","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440003","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440024","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440008","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440037","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440014","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440013","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440001","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440019","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440012","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440017","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440018","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440036","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440035","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440025","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440022","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440032","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440030","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440034","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440005","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440002","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440015","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440020","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440027","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440006","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440016","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440033","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440026","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440007","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440021","Taluk":"Nagpur (Urban)","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441210","Taluk":"NA","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441107","Taluk":"Saoner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441614","Taluk":"Gondia","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"440023","Taluk":"Hingna","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441201","Taluk":"Bhiwapur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441903","Taluk":"Pauni","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441401","Taluk":"Kamthi","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441908","Taluk":"Pauni","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441912","Taluk":"Tumsar","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441901","Taluk":"Deori","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441202","Taluk":"Kuhi","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441210","Taluk":"Kuhi","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441910","Taluk":"Pauni","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441802","Taluk":"Sakoli","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441110","Taluk":"Hingna","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441804","Taluk":"Lakhani","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441902","Taluk":"Amgaon","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441914","Taluk":"Mohadi","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441203","Taluk":"Umrer","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441911","Taluk":"Tirora","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441701","Taluk":"Arjuni Morgaon","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441106","Taluk":"Ramtek","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441108","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441907","Taluk":"Tumsar","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441601","Taluk":"Gondia","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441101","Taluk":"Saoner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441501","Taluk":"Kalmeshwar","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441203","Taluk":"Umred","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441807","Taluk":"Sadak Arjuni","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441915","Taluk":"Tumsar","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441702","Taluk":"Arjuni Morgaon","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441805","Taluk":"Lakhandur","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"440023","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441906","Taluk":"Bhandarra","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441401","Taluk":"Ramtek","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441304","Taluk":"Narkhed","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441214","Taluk":"Umrer","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441905","Taluk":"Mohadi","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441803","Taluk":"Lakhandur","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441904","Taluk":"Bhandara","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441906","Taluk":"Bhandara","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441905","Taluk":"Bhandara","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441305","Taluk":"Katol","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441801","Taluk":"Goregaon","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441104","Taluk":"Mouda","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441809","Taluk":"Lakhni","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441102","Taluk":"Kamptee","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441806","Taluk":"Sadak Arjuni","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441113","Taluk":"Saoner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441204","Taluk":"Umrer","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441924","Taluk":"Bhandara","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441806","Taluk":"Sadak-Arjuni","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441104","Taluk":"Mauda","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441105","Taluk":"Parseoni","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441916","Taluk":"Salekasa","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441301","Taluk":"Narkhed","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440023","Taluk":"Nagpur (Urban)","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441807","Taluk":"Sadak-Arjuni","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441401","Taluk":"Parseoni","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441913","Taluk":"Tumsar","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441804","Taluk":"Lakhni","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441302","Taluk":"Katol","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441909","Taluk":"Mohadi","Districtname":"Bhandara","statename":"MAHARASHTRA"},
    {"pincode":"441306","Taluk":"Narkher","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441502","Taluk":"Kalmeshwar","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441103","Taluk":"Katol","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441501","Taluk":"Nagpur Rural","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441107","Taluk":"Savner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441601","Taluk":"Gondiya","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441123","Taluk":"Kamthi","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441404","Taluk":"Parseoni","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441614","Taluk":"Gondiya","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441122","Taluk":"Nagpur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441111","Taluk":"Kamthi","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441102","Taluk":"Parsioni","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440016","Taluk":"Nagpur (Urban)","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441122","Taluk":"Nagpur (Rural)","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441001","Taluk":"Kamthi","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441112","Taluk":"Saoner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441303","Taluk":"Narkhed","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"440026","Taluk":"Kamthi","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441101","Taluk":"Kamptee","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441102","Taluk":"Saoner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441304","Taluk":"Narkher","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441106","Taluk":"Mauda","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441111","Taluk":"Kamptee","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441101","Taluk":"Savner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441203","Taluk":"Bhiwapur","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441112","Taluk":"Savner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441501","Taluk":"Kalameshwar","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441108","Taluk":"Nagpur (Rural)","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441401","Taluk":"Kamptee","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441401","Taluk":"Mauda","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441122","Taluk":"Hingna","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441109","Taluk":"Saoner","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441214","Taluk":"Umred","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441807","Taluk":"Goregaon","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441107","Taluk":"Kalameshwar","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441902","Taluk":"Amgoan","Districtname":"Gondia","statename":"MAHARASHTRA"},
    {"pincode":"441204","Taluk":"Umred","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"441306","Taluk":"Narkhed","Districtname":"Nagpur","statename":"MAHARASHTRA"},
    {"pincode":"442101","Taluk":"Deoli","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442307","Taluk":"Hinganghat","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442001","Taluk":"Seloo","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442304","Taluk":"Hinganghat","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442001","Taluk":"Wardha","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442202","Taluk":"Ashti","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442201","Taluk":"Arvi","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442301","Taluk":"Hinganghat","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442302","Taluk":"Arvi","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442104","Taluk":"Seloo","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442102","Taluk":"Samudrapur","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442302","Taluk":"Dhamangaon Railway","Districtname":"Amravati","statename":"MAHARASHTRA"},
    {"pincode":"442203","Taluk":"Karanja","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442003","Taluk":"Wardha","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442306","Taluk":"Deoli","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442106","Taluk":"Arvi","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442305","Taluk":"Samudrapur","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442102","Taluk":"Seloo","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442301","Taluk":"Hinganghjat","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442302","Taluk":"Deoli","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442101","Taluk":"NA","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442102","Taluk":"Wardha","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442102","Taluk":"Samudraupr","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442301","Taluk":"Samudrapur","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442111","Taluk":"Wardha","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442303","Taluk":"Deoli","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442201","Taluk":"Karanja","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"442105","Taluk":"Seloo","Districtname":"Wardha","statename":"MAHARASHTRA"},
    {"pincode":"445304","Taluk":"Zari Jamni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445210","Taluk":"Ner Parsopant","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445215","Taluk":"Pusad","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445102","Taluk":"Ner Persopant","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445302","Taluk":"Kalapur","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445109","Taluk":"Ghatanji","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445207","Taluk":"Umarkhed","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445302","Taluk":"Kelapur","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445301","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445205","Taluk":"Mahagaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445301","Taluk":"Ghatanji","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445402","Taluk":"Ralegaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445305","Taluk":"Ghatanji","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445110","Taluk":"Digras","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445105","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445103","Taluk":"Arni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445001","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445101","Taluk":"Kalamb","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445106","Taluk":"Arni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445101","Taluk":"Babhulgaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445204","Taluk":"Pusad","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445206","Taluk":"Umerkhed","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445304","Taluk":"Wani","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445202","Taluk":"Darwha","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445002","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445230","Taluk":"Umarkhed","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445201","Taluk":"Ner Parsopant","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445201","Taluk":"Darwha","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445303","Taluk":"Maregaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445308","Taluk":"Ralegaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445305","Taluk":"Kelapur","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445201","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445306","Taluk":"Ghatanji","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445307","Taluk":"Wani","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445323","Taluk":"Kelapur","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445110","Taluk":"Darwha","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445203","Taluk":"Digras","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445207","Taluk":"Umerkhed","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445402","Taluk":"Rlegaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445305","Taluk":"Zari Jamni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445401","Taluk":"Kalamb","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445215","Taluk":"Mahagaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445323","Taluk":"Kalamb","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445230","Taluk":"Mahagaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445402","Taluk":"Ralegoan","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445210","Taluk":"Darwha","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445204","Taluk":"Mahagaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445205","Taluk":"Pusad","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445209","Taluk":"Pusad","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445105","Taluk":"Arni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445002","Taluk":"Kalamb","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445102","Taluk":"Nr Persopant","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445110","Taluk":"Arni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445109","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445206","Taluk":"Umarkhed","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445230","Taluk":"Pusad","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445323","Taluk":"Ralegaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445105","Taluk":"Darwha","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445002","Taluk":"Babhulgaon","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445302","Taluk":"Zari Jamni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445323","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445211","Taluk":"Umerkhed","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445308","Taluk":"Kelapur","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445102","Taluk":"Ner Parsopant","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445230","Taluk":"Umerkhed","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445216","Taluk":"Pusad","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445109","Taluk":"Arni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445305","Taluk":"Jhari Jamni","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445102","Taluk":"Yavatmal","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"445210","Taluk":"Nerparsopant","Districtname":"Yavatmal","statename":"MAHARASHTRA"},
    {"pincode":"414001","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413701","Taluk":"Shrigonda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414002","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414005","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414701","Taluk":"Shrigonda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414502","Taluk":"Shevgaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414102","Taluk":"Pathardi","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414305","Taluk":"Parner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414402","Taluk":"Karjat","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414505","Taluk":"Pathardi","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414301","Taluk":"Parner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413201","Taluk":"Jamkhed","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413702","Taluk":"Shrigonda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414401","Taluk":"Karjat","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413703","Taluk":"Shrigonda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414302","Taluk":"Parner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414006","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414504","Taluk":"Shevgaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414403","Taluk":"Karjat","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413205","Taluk":"Jamkhed","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414113","Taluk":"Pathardi","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414103","Taluk":"Parner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413728","Taluk":"Shrigonda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414201","Taluk":"Nagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414503","Taluk":"Shevgaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414105","Taluk":"Newasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414606","Taluk":"Newasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414201","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414106","Taluk":"Pathadi","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414111","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414306","Taluk":"Parner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414106","Taluk":"Pathardi","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413704","Taluk":"Rahuri","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414501","Taluk":"Pathardi","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414304","Taluk":"Parner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413204","Taluk":"Jamkhed","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414303","Taluk":"Parner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414607","Taluk":"Newasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414101","Taluk":"Shrigonda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414002","Taluk":"NA","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414601","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414701","Taluk":"NA","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414602","Taluk":"Newasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413726","Taluk":"Shrigonda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414002","Taluk":"Nagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414110","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413201","Taluk":"Nagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413701","Taluk":"Shrigoda","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414502","Taluk":"NA","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414005","Taluk":"Ahmedangar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414003","Taluk":"Ahmednagar","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414501","Taluk":"Shevgaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413306","Taluk":"Sangola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413212","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413308","Taluk":"Sangola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413101","Taluk":"Malshiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413307","Taluk":"Sangola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413211","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413208","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413309","Taluk":"Sangola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413304","Taluk":"Pandharpur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413305","Taluk":"Mangalvedha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413203","Taluk":"Karmala","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413209","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413301","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413101","Taluk":"NA","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413302","Taluk":"Pandharpur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413252","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413301","Taluk":"Mahda","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413202","Taluk":"Karmala","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413319","Taluk":"Mangalvedha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413310","Taluk":"Pandharpur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413111","Taluk":"Malshiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413223","Taluk":"Karmala","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413315","Taluk":"Pandharpur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413322","Taluk":"Mangalvedha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413317","Taluk":"Sangola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413113","Taluk":"Malshiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413112","Taluk":"Malshiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413310","Taluk":"Malshiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413307","Taluk":"Sangole","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413109","Taluk":"Malshiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413210","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413314","Taluk":"Sangola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413309","Taluk":"Mangalvedha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413251","Taluk":"Karmala","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413211","Taluk":"Mahda","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413309","Taluk":"Snagola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413319","Taluk":"Mangalvedhe","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413107","Taluk":"Malsiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413252","Taluk":"Mahda","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413206","Taluk":"Karmala","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413317","Taluk":"Pandharpur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413304","Taluk":"Pamdharpur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413250","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413101","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413108","Taluk":"Malsiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413305","Taluk":"Mangalvedhe","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413305","Taluk":"NA","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413319","Taluk":"Mangalwedha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413109","Taluk":"Malsiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413202","Taluk":"Karmalaa","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413113","Taluk":"Malshira","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413306","Taluk":"Sngola","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413310","Taluk":"Malsiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413304","Taluk":"NA","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413111","Taluk":"Malsiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413112","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413107","Taluk":"Malshiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413113","Taluk":"Malsiras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413309","Taluk":"Sangole","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413118","Taluk":"Malsras","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"411014","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411040","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411032","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411035","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411003","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411027","Taluk":"NA","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411026","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411039","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411037","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411001","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411031","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411062","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411019","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411033","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411012","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411015","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411028","Taluk":"Pune","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411028","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411013","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411057","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411033","Taluk":"Mulshi","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411017","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411034","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411048","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411047","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411057","Taluk":"Mulashi","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411018","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411060","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411036","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411044","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411061","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411062","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411027","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411022","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411014","Taluk":"Pune","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411057","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411006","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411004","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411046","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411051","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411021","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411007","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411007","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411002","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411045","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411008","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411021","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411042","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411038","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411020","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411005","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411043","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411041","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411016","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411016","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411052","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411011","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411046","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411024","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411023","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411030","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411023","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411045","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411009","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411041","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411058","Taluk":"Pune City","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410506","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410509","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413132","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412411","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412205","Taluk":"Bhor","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412201","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412105","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413801","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412211","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412206","Taluk":"Bhor","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410507","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412115","Taluk":"Mulshi","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410401","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412213","Taluk":"Bhor","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410501","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410505","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410502","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412218","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412408","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412410","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413114","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412212","Taluk":"Velhe","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412401","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412205","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412207","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412404","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412406","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412402","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412405","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412204","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413102","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413133","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413103","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412108","Taluk":"Mulshi","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413104","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412303","Taluk":"Purandar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412203","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412209","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412214","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412206","Taluk":"Khandala","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"413105","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413130","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410509","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412301","Taluk":"Purandar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412106","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410513","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413106","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412202","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412208","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410503","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410515","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412409","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413130","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412101","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412112","Taluk":"Mulshi","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412305","Taluk":"Purandar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412311","Taluk":"Purandar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412109","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413110","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410508","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412403","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413104","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411025","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412216","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410516","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413120","Taluk":"Indapur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412210","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412102","Taluk":"Purandar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412104","Taluk":"Purandar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410504","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412306","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410402","Taluk":"Mawal","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410504","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412215","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412107","Taluk":"Velhe","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410403","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412202","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410405","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412412","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412219","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412220","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410512","Taluk":"Khed","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410506","Taluk":"Mulshi","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412205","Taluk":"Purandhar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413116","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410301","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412102","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413105","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412110","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412103","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410511","Taluk":"Junnar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413802","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410402","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410512","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413114","Taluk":"NA","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410510","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413115","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412307","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412304","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412105","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410506","Taluk":"Mawal","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412107","Taluk":"Mulshi","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"413130","Taluk":"Baramati","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410406","Taluk":"Mawal","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412308","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410302","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412207","Taluk":"Daund","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412211","Taluk":"Shiurur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410504","Taluk":"Ambegaon","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412101","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412115","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410510","Taluk":"Shirur","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412208","Taluk":"NA","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"411005","Taluk":"Haveli","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410406","Taluk":"Maval","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412301","Taluk":"Purandhar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"410507","Taluk":"Mawal","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412312","Taluk":"Purandar","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412213","Taluk":"Velhe","Districtname":"Pune","statename":"MAHARASHTRA"},
    {"pincode":"412803","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415537","Taluk":"Phaltan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415205","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415539","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412806","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412802","Taluk":"Khandala Bawada.","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415012","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415014","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415011","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415112","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415506","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415013","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415021","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415002","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415511","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415527","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415206","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415525","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415508","Taluk":"Man","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415523","Taluk":"Phaltan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415521","Taluk":"Khandala Bawada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415020","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415019","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415106","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415518","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415521","Taluk":"Phaltan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415114","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415116","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415011","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415513","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412802","Taluk":"Khandalabawada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412805","Taluk":"Mahabaleshwar","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415519","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415510","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415209","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415002","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415514","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415124","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415507","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412804","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412802","Taluk":"Kandala Bawada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415109","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415526","Taluk":"Khandala Bawada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415010","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415022","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415526","Taluk":"Khandalabavada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415501","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415515","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415505","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415206","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415524","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412806","Taluk":"Mahabaleshwr","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412806","Taluk":"Mahabaleshwar","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415207","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415503","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415536","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415520","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415520","Taluk":"Phaltan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415122","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415103","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415501","Taluk":"Satara  Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415004","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415538","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415205","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412805","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415211","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415509","Taluk":"Man","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412802","Taluk":"Khandala Bawada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415022","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415104","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415504","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415103","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415111","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415540","Taluk":"Man","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415512","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415105","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415015","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415522","Taluk":"Phalatan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415538","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415530","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412801","Taluk":"Khandala","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415528","Taluk":"Phaltan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415312","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415022","Taluk":"Satara.satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412802","Taluk":"Kanandala Bawada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415110","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415502","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415516","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412802","Taluk":"Khandala","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415003","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415108","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415115","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415514","Taluk":"Jawoli","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412806","Taluk":"Mahabaleshawar","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412802","Taluk":"Khandalabavada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415513","Taluk":"Jawali","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415023","Taluk":"Man (dahiwadi)","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415020","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415212","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415102","Taluk":"Khatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415528","Taluk":"Phalatan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415107","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415023","Taluk":"Kahatav","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415521","Taluk":"Kahandala Bawada","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415520","Taluk":"Patan.","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415014","Taluk":"Karad","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415521","Taluk":"Kahandala Bawda","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415506","Taluk":"Man","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412806","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415511","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415522","Taluk":"Phaltan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415001","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"412801","Taluk":"Phaltan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415503","Taluk":"Satara","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415517","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415109","Taluk":"Karad.","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415107","Taluk":"Koregaon","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415109","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415523","Taluk":"Phalatan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415509","Taluk":"Man(dahivadi)","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415114","Taluk":"Patan","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"415020","Taluk":"Wai","Districtname":"Satara","statename":"MAHARASHTRA"},
    {"pincode":"422601","Taluk":"Akole","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422620","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422610","Taluk":"Akole","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422605","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413715","Taluk":"Shrirampur.","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413705","Taluk":"Rahuri","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413714","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413738","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423107","Taluk":"Rahata","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413737","Taluk":"Rahta","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422602","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422604","Taluk":"Akole","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413725","Taluk":"Shrirampur.","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414603","Taluk":"Nevasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413739","Taluk":"Shrirampur.","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414609","Taluk":"Nevasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414605","Taluk":"Nevasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413721","Taluk":"Shrirampur.","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413708","Taluk":"Kopargaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423601","Taluk":"Kopergaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413723","Taluk":"Rahta","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423604","Taluk":"Kopargaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413706","Taluk":"Rahuri","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413710","Taluk":"Rahta","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422611","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413736","Taluk":"Rahata","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423603","Taluk":"Kopargaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"414604","Taluk":"Nevasa","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413716","Taluk":"Rahuri","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423607","Taluk":"Kopergaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422603","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413719","Taluk":"Rahta","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413718","Taluk":"Shrirampur.","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422622","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413709","Taluk":"Shrirampur","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423605","Taluk":"Kopergaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413717","Taluk":"Shrirampur","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413720","Taluk":"Shrirampur","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423602","Taluk":"Kopergaon","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"423109","Taluk":"Rahata","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413712","Taluk":"Rahta","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413713","Taluk":"Rahata","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"422608","Taluk":"Sangamner","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413722","Taluk":"Rahuri","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413707","Taluk":"Rahata","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413711","Taluk":"Shrirampur.","Districtname":"Ahmed Nagar","statename":"MAHARASHTRA"},
    {"pincode":"413228","Taluk":"S.solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413409","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413215","Taluk":"South Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413216","Taluk":"Akkalkot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413222","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413214","Taluk":"Mohol","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413219","Taluk":"Akkalkot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413253","Taluk":"Mohol","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413221","Taluk":"Solapur South","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413002","Taluk":"Solapur South","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413006","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413303","Taluk":"Mohol","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413248","Taluk":"Mohol","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413008","Taluk":"North Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413220","Taluk":"Akkalkot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413218","Taluk":"Akkalkot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413216","Taluk":"Akkaikot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413255","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413226","Taluk":"Akkalkot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413401","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413008","Taluk":"N.solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413403","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413213","Taluk":"Mohol","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413002","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413406","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413221","Taluk":"South Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413404","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413412","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413005","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413410","Taluk":"Madha","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413324","Taluk":"Mohol","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413402","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413002","Taluk":"N.solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413227","Taluk":"Akkalkot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413003","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413008","Taluk":"Solapur South","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413255","Taluk":"North Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413008","Taluk":"Nort Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413004","Taluk":"North Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413001","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413219","Taluk":"Solapur South","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413411","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413222","Taluk":"N.solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413006","Taluk":"Nort Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413401","Taluk":"NA","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413224","Taluk":"Solapur South","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413006","Taluk":"Solapur South","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413217","Taluk":"Akkalkot","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413006","Taluk":"North Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413006","Taluk":"North  Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413224","Taluk":"North Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413222","Taluk":"North Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413222","Taluk":"Barshi","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413213","Taluk":"Mhol","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413007","Taluk":"Solapur North","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413002","Taluk":"North Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413008","Taluk":"Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"413228","Taluk":"South Solapur","Districtname":"Solapur","statename":"MAHARASHTRA"},
    {"pincode":"795002","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795143","Taluk":"Nil","Districtname":"Churachandpur","statename":"MANIPUR"},
    {"pincode":"795102","Taluk":"Nil","Districtname":"Chandel","statename":"MANIPUR"},
    {"pincode":"795135","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795128","Taluk":"Nil","Districtname":"Churachandpur","statename":"MANIPUR"},
    {"pincode":"795141","Taluk":"Nil","Districtname":"Tamenglong","statename":"MANIPUR"},
    {"pincode":"795149","Taluk":"Nil;","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795149","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795130","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795132","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795103","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795145","Taluk":"Nil","Districtname":"Ukhrul","statename":"MANIPUR"},
    {"pincode":"795125","Taluk":"Nil","Districtname":"Tamenglong","statename":"MANIPUR"},
    {"pincode":"795138","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795005","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795136","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795134","Taluk":"Nil","Districtname":"Bishnupur","statename":"MANIPUR"},
    {"pincode":"795159","Taluk":"Nil","Districtname":"Tamenglong","statename":"MANIPUR"},
    {"pincode":"795139","Taluk":"Nil","Districtname":"Churachandpur","statename":"MANIPUR"},
    {"pincode":"795115","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795126","Taluk":"Nil","Districtname":"Bishnupur","statename":"MANIPUR"},
    {"pincode":"795009","Taluk":"Nil;","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795131","Taluk":"Nil","Districtname":"Chandel","statename":"MANIPUR"},
    {"pincode":"795116","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795133","Taluk":"Nil","Districtname":"Bishnupur","statename":"MANIPUR"},
    {"pincode":"795118","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795003","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795004","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795101","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795112","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795142","Taluk":"Nil","Districtname":"Ukhrul","statename":"MANIPUR"},
    {"pincode":"795127","Taluk":"Nil","Districtname":"Chandel","statename":"MANIPUR"},
    {"pincode":"795122","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795124","Taluk":"Nil","Districtname":"Bishnupur","statename":"MANIPUR"},
    {"pincode":"795104","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795148","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795010","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795147","Taluk":"Nil","Districtname":"Tamenglong","statename":"MANIPUR"},
    {"pincode":"795146","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795113","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795001","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795114","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795118","Taluk":"Nil","Districtname":"Bishnupur","statename":"MANIPUR"},
    {"pincode":"795009","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795129","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795144","Taluk":"Nil","Districtname":"Ukhrul","statename":"MANIPUR"},
    {"pincode":"795008","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795107","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795007","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795117","Taluk":"Nil","Districtname":"Churachandpur","statename":"MANIPUR"},
    {"pincode":"795015","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795140","Taluk":"Nil","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795116","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795001","Taluk":"Imphal West","Districtname":"Imphal West","statename":"MANIPUR"},
    {"pincode":"795003","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795150","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795132","Taluk":"Nil","Districtname":"Thoubal","statename":"MANIPUR"},
    {"pincode":"795006","Taluk":"Churachandpur","Districtname":"Churachandpur","statename":"MANIPUR"},
    {"pincode":"795106","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795135","Taluk":"Nil","Districtname":"Chandel","statename":"MANIPUR"},
    {"pincode":"795118","Taluk":"Nil","Districtname":"Churachandpur","statename":"MANIPUR"},
    {"pincode":"795136","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795101","Taluk":"Nil","Districtname":"Chandel","statename":"MANIPUR"},
    {"pincode":"795125","Taluk":"Nil","Districtname":"Senapati","statename":"MANIPUR"},
    {"pincode":"795140","Taluk":"Nil","Districtname":"Imphal East","statename":"MANIPUR"},
    {"pincode":"795102","Taluk":"Nil;","Districtname":"Chandel","statename":"MANIPUR"},
    {"pincode":"796190","Taluk":"Aibawk","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796261","Taluk":"Saitual","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796501","Taluk":"Reiek","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796571","Taluk":"Hnahthial","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796001","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796770","Taluk":"Chawngte","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796007","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796008","Taluk":"Tlangnuam","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796081","Taluk":"North Thingdawl","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796161","Taluk":"Thingsulthliah","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796891","Taluk":"Lawngtlai","Districtname":"Lawngtlai","statename":"MIZORAM"},
    {"pincode":"796014","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796730","Taluk":"Lungsen","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796321","Taluk":"Khawbung","Districtname":"Champhai","statename":"MIZORAM"},
    {"pincode":"796370","Taluk":"East Lungdar (Part)","Districtname":"Champhai","statename":"MIZORAM"},
    {"pincode":"796091","Taluk":"Bilkhawthlir","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796471","Taluk":"Zawlnuam","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796070","Taluk":"North Thingdawl","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796581","Taluk":"Hnahthial","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796901","Taluk":"Tuipang","Districtname":"Saiha","statename":"MIZORAM"},
    {"pincode":"796701","Taluk":"Lunglei","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796081","Taluk":"Kolasib","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796070","Taluk":"Chandmary","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796701","Taluk":"West Bunghmun","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796710","Taluk":"West Bunghmun","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796005","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796181","Taluk":"Serchhip","Districtname":"Serchhip","statename":"MIZORAM"},
    {"pincode":"796320","Taluk":"S.khawbung","Districtname":"Champhai","statename":"MIZORAM"},
    {"pincode":"796901","Taluk":"Saiha","Districtname":"Saiha","statename":"MIZORAM"},
    {"pincode":"796310","Taluk":"Khawzawl","Districtname":"Champhai","statename":"MIZORAM"},
    {"pincode":"796012","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796009","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796571","Taluk":"Hanhthial","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796901","Taluk":"Sangau","Districtname":"Lawngtlai","statename":"MIZORAM"},
    {"pincode":"796017","Taluk":"Thingsulthliah (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796431","Taluk":"Phaileng","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796441","Taluk":"Mamit","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796410","Taluk":"Sairang","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796111","Taluk":"Darlawn","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796751","Taluk":"Lunglei","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796082","Taluk":"Kolasib","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796751","Taluk":"Demagiri","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796691","Taluk":"Zotlang","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796421","Taluk":"Sairang","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796691","Taluk":"Lunglei","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796321","Taluk":"Champhai","Districtname":"Champhai","statename":"MIZORAM"},
    {"pincode":"796070","Taluk":"Kawnpui","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796290","Taluk":"Ngopa","Districtname":"Champhai","statename":"MIZORAM"},
    {"pincode":"796471","Taluk":"Zawlnuam","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796770","Taluk":"Chawngte","Districtname":"Lawngtlai","statename":"MIZORAM"},
    {"pincode":"796710","Taluk":"Bunghmun","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796261","Taluk":"Phullen","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796005","Taluk":"Kulikawn","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796017","Taluk":"Zemabawk","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796370","Taluk":"Serchhip","Districtname":"Serchhip","statename":"MIZORAM"},
    {"pincode":"796421","Taluk":"Lengpui","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796410","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796014","Taluk":"Bawngkawn","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796370","Taluk":"Lungdar","Districtname":"Serchhip","statename":"MIZORAM"},
    {"pincode":"796441","Taluk":"West Phaileng","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796431","Taluk":"West Phaileng","Districtname":"Mammit","statename":"MIZORAM"},
    {"pincode":"796004","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796184","Taluk":"E. Lungdar","Districtname":"Serchhip","statename":"MIZORAM"},
    {"pincode":"796101","Taluk":"Vairengte","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796901","Taluk":"Siaha","Districtname":"Saiha","statename":"MIZORAM"},
    {"pincode":"796070","Taluk":"Chanmary","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796770","Taluk":"Lawngtlai","Districtname":"Lawngtlai","statename":"MIZORAM"},
    {"pincode":"796190","Taluk":"Sialsuk","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796014","Taluk":"Sihphir","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796186","Taluk":"Serchhip","Districtname":"Serchhip","statename":"MIZORAM"},
    {"pincode":"796082","Taluk":"North Thingdawl","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796571","Taluk":"Thingsai","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796581","Taluk":"Thingsai","Districtname":"Lunglei","statename":"MIZORAM"},
    {"pincode":"796101","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"796101","Taluk":"North Thingdawl","Districtname":"Kolasib","statename":"MIZORAM"},
    {"pincode":"796017","Taluk":"Tlangnuam (Part)","Districtname":"Aizawl","statename":"MIZORAM"},
    {"pincode":"798620","Taluk":"Aghunato","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"797106","Taluk":"Medziphema","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797100","Taluk":"Doyang H Project","Districtname":"Wokha","statename":"NAGALAND"},
    {"pincode":"798619","Taluk":"Akuluto","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"797114","Taluk":"Meluri","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"798619","Taluk":"Atoizu","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"798619","Taluk":"Zunheboto","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"798601","Taluk":"Mokokchung","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798604","Taluk":"Mongkholemba","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798611","Taluk":"Kiphire","Districtname":"Kiphire","statename":"NAGALAND"},
    {"pincode":"798616","Taluk":"Longkhim","Districtname":"Tuensang","statename":"NAGALAND"},
    {"pincode":"798603","Taluk":"Champang","Districtname":"Mon","statename":"NAGALAND"},
    {"pincode":"797115","Taluk":"Artc","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797115","Taluk":"Dimapur","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"798618","Taluk":"Changtongya","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798620","Taluk":"Zunheboto","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"797109","Taluk":"Tseminyu","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797110","Taluk":"Zalukie","Districtname":"Peren","statename":"NAGALAND"},
    {"pincode":"798620","Taluk":"Atoizu","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"797111","Taluk":"Doyang H Project","Districtname":"Wokha","statename":"NAGALAND"},
    {"pincode":"798618","Taluk":"Tuli","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798622","Taluk":"Naginimora","Districtname":"Mon","statename":"NAGALAND"},
    {"pincode":"797111","Taluk":"Doyang H. Project","Districtname":"Wokha","statename":"NAGALAND"},
    {"pincode":"797099","Taluk":"Bhandari","Districtname":"Wokha","statename":"NAGALAND"},
    {"pincode":"797105","Taluk":"Chiechama","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797003","Taluk":"Kohima Village","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798603","Taluk":"Mon","Districtname":"Mon","statename":"NAGALAND"},
    {"pincode":"798613","Taluk":"Changtongya","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798612","Taluk":"Tuensang","Districtname":"Tuensang","statename":"NAGALAND"},
    {"pincode":"797104","Taluk":"Chozuba","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"797105","Taluk":"Kohima","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798616","Taluk":"Lonkhim","Districtname":"Tuensang","statename":"NAGALAND"},
    {"pincode":"797102","Taluk":"Phek","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"797102","Taluk":"Chizami","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"798626","Taluk":"Noklak","Districtname":"Tuensang","statename":"NAGALAND"},
    {"pincode":"797104","Taluk":"Phek","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"798614","Taluk":"Mokokchung","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798621","Taluk":"Mon","Districtname":"Mon","statename":"NAGALAND"},
    {"pincode":"797111","Taluk":"Wokha","Districtname":"Wokha","statename":"NAGALAND"},
    {"pincode":"797103","Taluk":"Dimapur","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797117","Taluk":"Dimapur Sadar","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797116","Taluk":"Chumukedima","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797112","Taluk":"Dimapur","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797100","Taluk":"Wokha","Districtname":"Wokha","statename":"NAGALAND"},
    {"pincode":"797112","Taluk":"Dimapur Mdg","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797113","Taluk":"Dimapur Sadar","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"798601","Taluk":"Ongpangkong","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797109","Taluk":"Ghathashi","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"797121","Taluk":"Kohima Sadar","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798625","Taluk":"Longleng","Districtname":"Longleng","statename":"NAGALAND"},
    {"pincode":"798615","Taluk":"Mokokchung","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797113","Taluk":"New Nepaligaon","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797005","Taluk":"Kohima","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797002","Taluk":"Kohima Science College","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797108","Taluk":"Phek","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"797116","Taluk":"Dimapur Bazar","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"798617","Taluk":"Ongpang Kong","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797112","Taluk":"Dimapur Sadar","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797107","Taluk":"Pfutsero","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"797103","Taluk":"Chumukedima","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797108","Taluk":"Phek Sadar","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"798620","Taluk":"Ghathashi","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"797001","Taluk":"Kohima","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797005","Taluk":"Kohima Sadar","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797002","Taluk":"Kohima","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797003","Taluk":"Kohima","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798615","Taluk":"Impur","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798616","Taluk":"Tuensang","Districtname":"Tuensang","statename":"NAGALAND"},
    {"pincode":"798614","Taluk":"Chuchuyimlang","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798627","Taluk":"Akuluto","Districtname":"Zunhebotto","statename":"NAGALAND"},
    {"pincode":"797101","Taluk":"Peren","Districtname":"Peren","statename":"NAGALAND"},
    {"pincode":"797106","Taluk":"Dimapur","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797114","Taluk":"Phek","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"798613","Taluk":"Changtogia","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798604","Taluk":"Mokokchung","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797004","Taluk":"New Sectt Complex","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798622","Taluk":"Mon","Districtname":"Mon","statename":"NAGALAND"},
    {"pincode":"798602","Taluk":"Tizit","Districtname":"Mon","statename":"NAGALAND"},
    {"pincode":"797004","Taluk":"Kohima Sadar","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798626","Taluk":"Tuensang","Districtname":"Tuensang","statename":"NAGALAND"},
    {"pincode":"798617","Taluk":"Mokokchung","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797120","Taluk":"kohima","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798623","Taluk":"Tuli","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797101","Taluk":"Peren","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797107","Taluk":"Phek","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"798613","Taluk":"Chuchuyimlang","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797109","Taluk":"Kohima","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"797117","Taluk":"Dimapur Mdg","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797104","Taluk":"Chozoba","Districtname":"Phek","statename":"NAGALAND"},
    {"pincode":"797118","Taluk":"Dimapur","Districtname":"Dimapur","statename":"NAGALAND"},
    {"pincode":"797004","Taluk":"New Sectt. Complex","Districtname":"Kohima","statename":"NAGALAND"},
    {"pincode":"798602","Taluk":"Mon","Districtname":"Mon","statename":"NAGALAND"},
    {"pincode":"798618","Taluk":"Mokokchung","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"798614","Taluk":"Chuchulang","Districtname":"Mokokchung","statename":"NAGALAND"},
    {"pincode":"797110","Taluk":"Peren","Districtname":"Peren","statename":"NAGALAND"},
    {"pincode":"799013","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799155","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799005","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799009","Taluk":"Mohanpur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799001","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799004","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799001","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799014","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799010","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799002","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799103","Taluk":"Bishramganj","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799101","Taluk":"Amarpur","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799143","Taluk":"Manubazar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799101","Taluk":"Ampi","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799130","Taluk":"Sekerkote","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799004","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799157","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799004","Taluk":"Agartala","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799003","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799045","Taluk":"Birendranagar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799045","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799115","Taluk":"Melaghar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799144","Taluk":"Santirbazar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799114","Taluk":"Bagma","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799103","Taluk":"Bishramganj","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799211","Taluk":"Mohanpur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799145","Taluk":"Sabroom","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799157","Taluk":"Rajnagar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799212","Taluk":"Sidhai","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799007","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799006","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799104","Taluk":"Jatanbari","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799115","Taluk":"Melaghar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799155","Taluk":"Beloniya","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799105","Taluk":"Kakranan","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799131","Taluk":"Sonamura","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799144","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799003","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799015","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799102","Taluk":"Bishalgarh","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799210","Taluk":"Kamalghat","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799002","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799102","Taluk":"Boxonagar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799035","Taluk":"Ranirbazar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799045","Taluk":"Birendranagar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799105","Taluk":"Kakranan","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799142","Taluk":"Muhuripur","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799013","Taluk":"Udaipur","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799141","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799143","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799141","Taluk":"Jolaibari","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799007","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799157","Taluk":"Anandapur","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799131","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799009","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799114","Taluk":"Udaipur","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799114","Taluk":"Matarbari","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799012","Taluk":"Sadar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799125","Taluk":"Garjee","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799125","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799104","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799156","Taluk":"Hrishyamukh","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799141","Taluk":"Bagafa","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799102","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799150","Taluk":"Rajnagar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799131","Taluk":"Kathalia","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799008","Taluk":"Khayerpur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799008","Taluk":"Jirania","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799105","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799143","Taluk":"Satchand","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799155","Taluk":"Matai","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799105","Taluk":"Udaipur","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799046","Taluk":"Jirania","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799120","Taluk":"Radhakishorepur","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799153","Taluk":"Radhanagar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799153","Taluk":"Radhanagar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799144","Taluk":"Bagafa","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799130","Taluk":"NA","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799212","Taluk":"Simna","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799101","Taluk":"NA","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799015","Taluk":"Sadar","Districtname":"South Tripura","statename":"TRIPURA"},
    {"pincode":"799022","Taluk":"Bishalgarh","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"792110","Taluk":"Roing","Districtname":"Lower Dibang Valley","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791102","Taluk":"Pasighat","Districtname":"East Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792101","Taluk":"Alinye","Districtname":"Dibang Valley","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Bordumsa Circle","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792102","Taluk":"Chowkham","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792101","Taluk":"Anelih","Districtname":"Dibang Valley","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792101","Taluk":"NA","Districtname":"Dibang Valley","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791111","Taluk":"Itanagar","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791112","Taluk":"Yupia","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791104","Taluk":"Mebo","Districtname":"East Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790104","Taluk":"Tawang","Districtname":"Tawang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Bagra","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790002","Taluk":"Kalaktang","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791123","Taluk":"Balijan","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791101","Taluk":"Bame","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790102","Taluk":"Seppa","Districtname":"East Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791123","Taluk":"Banderdewa","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791101","Taluk":"Basar","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Bene","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790114","Taluk":"Bhalukpong","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791120","Taluk":"Ziro","Districtname":"Lower Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790001","Taluk":"Bomdila","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792056","Taluk":"Bordumsa","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792130","Taluk":"Khonsa","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792120","Taluk":"Bubang","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792104","Taluk":"Hayuliang","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791118","Taluk":"Chambang","Districtname":"Kurung Kumey","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792120","Taluk":"Changlang","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792001","Taluk":"Tezu","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791113","Taluk":"Itanagar","Districtname":"Lower Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792122","Taluk":"Miao","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790116","Taluk":"Tenga Market","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791102","Taluk":"Pasighat","Districtname":"Upper Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791101","Taluk":"Dali","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791118","Taluk":"Damin","Districtname":"Kurung Kumey","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Daporijo","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Darak","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791101","Taluk":"Daring","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Darka","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792129","Taluk":"Deomali","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790101","Taluk":"Dirang Basti","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790101","Taluk":"Dirang","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792103","Taluk":"Namsai","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791112","Taluk":"Diomukh","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791113","Taluk":"Itanagar","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Dumporijo","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792055","Taluk":"Vijoynagar","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791125","Taluk":"Likabali","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791002","Taluk":"Yingkiong","Districtname":"Upper Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791105","Taluk":"Tuting","Districtname":"Upper Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Giba","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790106","Taluk":"Lumla","Districtname":"Tawang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791102","Taluk":"Pasight","Districtname":"East Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791121","Taluk":"Kimin","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792104","Taluk":"Hayuliang","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791103","Taluk":"Pasighat","Districtname":"East Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791118","Taluk":"Nyapin","Districtname":"Kurung Kumey","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792103","Taluk":"Namsai","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792121","Taluk":"Jairampur","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790105","Taluk":"Tang","Districtname":"Tawang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792131","Taluk":"Longding","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Kambang","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792102","Taluk":"Kamlang Nagar","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792130","Taluk":"Kanubari","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Kaying","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791112","Taluk":"Doimukh","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790114","Taluk":"Khellong","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792120","Taluk":"Khemiyong","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792056","Taluk":"Kherem Bisa","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792104","Taluk":"Hayulinag","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791123","Taluk":"Kokila","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791118","Taluk":"Koloriang","Districtname":"Kurung Kumey","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Kombo","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792105","Taluk":"Mahadevpur","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792056","Taluk":"Lallung","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Lemiking","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Lepajaring","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790105","Taluk":"Jang","Districtname":"Tawang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791101","Taluk":"Liromoba","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790101","Taluk":"Lish","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Logum Jining","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Maro","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791003","Taluk":"Mechuka","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792102","Taluk":"Medo","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791119","Taluk":"Ziro","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791110","Taluk":"Naharlagun","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792102","Taluk":"Momong","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Monigong","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790101","Taluk":"Munna Camp","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Muri","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Nacho","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790001","Taluk":"Nafra","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792120","Taluk":"Namdang","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792123","Taluk":"Nampong","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792129","Taluk":"Namsang","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792129","Taluk":"Namsang Mukh","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792120","Taluk":"Namtok","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792129","Taluk":"Narottam Nagar","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792056","Taluk":"New Mohang","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Nikte","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791109","Taluk":"Nirjuli","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791118","Taluk":"Palin","Districtname":"Kurung Kumey","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790102","Taluk":"Seppa","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Payum","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"NA","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790001","Taluk":"NA","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792056","Taluk":"Rajanagar","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791119","Taluk":"R N Project","Districtname":"Lower Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792120","Taluk":"Ranglum","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792110","Taluk":"Roing","Districtname":"Dibang Valley","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Gumgong","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790003","Taluk":"Rupa","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791112","Taluk":"Saglee","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790001","Taluk":"Salari","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790101","Taluk":"Sangti","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790103","Taluk":"Seijosa","Districtname":"East Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790101","Taluk":"Senge","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Sippi","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Siyum","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792129","Taluk":"Soha","Districtname":"Tirap","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791123","Taluk":"Sonajuli","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791113","Taluk":"NA","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792111","Taluk":"Sunpura","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Tabarijo","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Taksing","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791118","Taluk":"Tali","Districtname":"Kurung Kumey","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791122","Taluk":"Taliha","Districtname":"Upper Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Tato","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790116","Taluk":"Tengaf Market","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"790114","Taluk":"Tippi","Districtname":"West Kameng","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791101","Taluk":"Tirbin","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792056","Taluk":"Vijoypur","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Vivek Nagar","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792102","Taluk":"Wakro","Districtname":"Lohit","statename":"ARUNACHAL PRADESH"},
    {"pincode":"792120","Taluk":"Yangkang","Districtname":"Changlang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791119","Taluk":"Ziro","Districtname":"Lower Subansiri","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Yoji Yora","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791001","Taluk":"Yomcha","Districtname":"West Siang","statename":"ARUNACHAL PRADESH"},
    {"pincode":"791110","Taluk":"Yupia","Districtname":"Papum Pare","statename":"ARUNACHAL PRADESH"},
    {"pincode":"799286","Taluk":"Abhanga","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Paschim Sonatala","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"NA","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Baruakandi","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799289","Taluk":"Ambassa","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Saraspur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799271","Taluk":"Anandabazar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Pecharthal","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Asharambari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Teliamura","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799281","Taluk":"Gournagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Bachaibari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Bagaicherri","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Bagbassa","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Dakshin Padmabil","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799254","Taluk":"Balidhum","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Bishnupur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799204","Taluk":"Balaram","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Jalai","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799262","Taluk":"Balicherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Baligaon","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799286","Taluk":"Bamancherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Banbazar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799286","Taluk":"Baralutma","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799271","Taluk":"Dasda","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Baramaidan","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Barmura","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Baruakandi","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Belcherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Behalabari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Bhangmun","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799266","Taluk":"Betchesrra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Samrurpar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799279","Taluk":"Bhagabannagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799284","Taluk":"Bhagirathpara","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Bhagyapur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Bharatsardarbari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799264","Taluk":"Sonaimuri","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Panisagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799273","Taluk":"Lalcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Birchandranagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Brahmacherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Brajendranagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799284","Taluk":"Bullongbassa","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799273","Taluk":"Chailengta","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Chakmaghat","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Ramchandraghat","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Chandipur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Chandrapur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Chankhala","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799279","Taluk":"Chantail","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799273","Taluk":"Chawmanu","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Chebri","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799286","Taluk":"Chengkap","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799279","Taluk":"Deoracherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Chotosarma","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799262","Taluk":"Churaibari","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Promodenagar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799284","Taluk":"Dalapatipara","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Bilashpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799264","Taluk":"Darchai","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Demcherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Demdun","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Deocherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799254","Taluk":"Dewanpassa","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Dhanbilash","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Dhanicherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Dharmanagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799250","Taluk":"Dharmanagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Dhumacherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Dhupirbond","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"South Huruah","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Dudhpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799273","Taluk":"Durgacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Durgapur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Paschimkalyanpur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Tulashikhar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Huruah","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Kameswar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799264","Taluk":"Kumarghat","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Masli","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"West Rataceherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Fatikcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Fatikroy","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799262","Taluk":"Fulbari","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Kamalpur","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Fultali","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Gakulbari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Gakulnagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Ganganagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799289","Taluk":"Ganganagar","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Ganki","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799271","Taluk":"Garchirampara","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Kalachari","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Ghilatali","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Gobindapur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Golabari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799279","Taluk":"Goldharpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Lakshminarayan Pur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799279","Taluk":"Gournagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Nabincherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Gulakpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Hadrai","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799286","Taluk":"Halahali","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799254","Taluk":"Halflong","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Vhamgmun","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Paschim Hmunpui","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Howaibari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"East Huruah","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Ichabpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Ichailalcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Praytyakroy","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Indurail","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799281","Taluk":"Irani","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799284","Taluk":"Jagabandhupara","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Jagannathpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Chandrahalampara","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Jlebassa","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Jampui","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799286","Taluk":"Jamthing","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Ramnagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Jamircherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799270","Taluk":"Uttarlaljuri","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799254","Taluk":"Jubarajnagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Juri","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799256","Taluk":"Kacharicherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799278","Taluk":"Kachucherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Kadamtala","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799279","Taluk":"Kailashahar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Kailashahar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Kalacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Kalangangarpar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Kalagong","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799271","Taluk":"Kalapani","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Sorojini","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Kalyanpur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Dwarikapur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799287","Taluk":"Salema","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Kanchanbari","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799266","Taluk":"Manu","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799270","Taluk":"Kanchanpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Kangrai","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Karaicherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Karailong","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799266","Taluk":"Karamcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Karaticherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Paschim Karingicherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799286","Taluk":"Katalutma","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Khasiamangal","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799256","Taluk":"Khedacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799262","Taluk":"Lakhinagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799202","Taluk":"Khowai","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Khowai","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Ramguna","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799287","Taluk":"Kuchainala","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799204","Taluk":"Kulai","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Kurti","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Lalcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799273","Taluk":"Labancherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799270","Taluk":"Laljuri","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Laljuri","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799281","Taluk":"Latiapur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799284","Taluk":"Laxmipur","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Longtharai","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Machmara","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799278","Taluk":"Maharanipur","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Maharanipur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Maigonga","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Mainerma","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799287","Taluk":"Manikbhander","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799273","Taluk":"Manikpur","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Manughat","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Manuvelly","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Maracherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Masauli","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799278","Taluk":"Mechuria","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799278","Taluk":"Mendihaor","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Moharcherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Madhya Krishnapur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Sandukarkari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799287","Taluk":"Nagbangshi","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799204","Taluk":"Nalicherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Nalkata","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799256","Taluk":"Narendranagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799273","Taluk":"Natinmanu","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Dharmanagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Kathalcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Noagaon","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Noorpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Padmabil","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799264","Taluk":"Karamcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Nunacherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799264","Taluk":"Sukantanagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Paglabari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Paharmora","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Paschimbelcherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799278","Taluk":"Paschim Dalucherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Kunjaban","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Paschim Laxmicherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799254","Taluk":"Radhapur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Santinagar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"West Ratacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Pearacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Pechardahar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Pekucherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Vhangmun","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799256","Taluk":"Piplacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Prayekroy","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Premtala","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799266","Taluk":"Betcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Radhapur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Raghna","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Rahumcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Rajkandi","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Badlabari","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799254","Taluk":"Rajnagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Bishnupur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Ramchandraghat","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Paschim Ddwarikapur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Mayachari","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Ramgunapara","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799281","Taluk":"Rangauti","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Rangrung","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Ranibari","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799284","Taluk":"Ranipukur","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Ratacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Padmabil","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Ratia","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Rowa","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Rupacherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Sabual","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Murticherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Saidarpar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Pratyakroy","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799271","Taluk":"Sakhan Serhmun","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799278","Taluk":"Salema","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Samatal Padmabil","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799262","Taluk":"Sanicherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799284","Taluk":"Sarma","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799270","Taluk":"Satnala","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799264","Taluk":"Ambedkarnagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799270","Taluk":"Sibnagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799275","Taluk":"Sindhukumarpara","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799285","Taluk":"Halhuli","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799201","Taluk":"Singicherra","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799280","Taluk":"Sonamukhi","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799251","Taluk":"Pratyekroy","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Sonatala","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"South Ganganagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799264","Taluk":"Unokoti","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799277","Taluk":"Sreerampur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799287","Taluk":"Srirampur Lambucherra","Districtname":"Dhalai","statename":"TRIPURA"},
    {"pincode":"799270","Taluk":"Suknacherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Tablabari Madhya Ganki","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799261","Taluk":"Tarakpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Taraninagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Kriahnanagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799290","Taluk":"Krishnanagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Tilthai","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799269","Taluk":"Tiangsang","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799256","Taluk":"Damcherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Northganganagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799281","Taluk":"Jubaraznagar","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799271","Taluk":"Tuichama","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799263","Taluk":"Uttar Dhanicherra","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799288","Taluk":"Ujandodhpur","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799260","Taluk":"Uptakhali","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799205","Taluk":"Gakulnagar","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799207","Taluk":"Uttarchebri","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799203","Taluk":"Pulinpur","Districtname":"West Tripura","statename":"TRIPURA"},
    {"pincode":"799266","Taluk":"Nalkata","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799253","Taluk":"Zaithang","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"799279","Taluk":"Zamurtali","Districtname":"North Tripura","statename":"TRIPURA"},
    {"pincode":"794002","Taluk":"Lower Chandmari","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"783123","Taluk":"Dambo Rongjeng","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793150","Taluk":"Jowai","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"793109","Taluk":"Amlarem","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"794115","Taluk":"Ampati","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794101","Taluk":"Araimile","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793011","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794102","Taluk":"Baghmara","Districtname":"South Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793106","Taluk":"Balat","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794111","Taluk":"Williamnagar","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794106","Taluk":"Mahendraganj","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794103","Taluk":"Barengapara","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793104","Taluk":"Sumer","Districtname":"Ri Bhoi","statename":"MEGHALAYA"},
    {"pincode":"794104","Taluk":"Fulbari","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793160","Taluk":"Ladrymbai","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"794109","Taluk":"Tikrikilla","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793103","Taluk":"Umiam","Districtname":"Ri Bhoi","statename":"MEGHALAYA"},
    {"pincode":"793107","Taluk":"Bholaganj Bazar","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793004","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794005","Taluk":"Dobasipara","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793106","Taluk":"Balat","Districtname":"West Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793119","Taluk":"Nongstoin","Districtname":"West Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793101","Taluk":"Byrnihat","Districtname":"Ri Bhoi","statename":"MEGHALAYA"},
    {"pincode":"793111","Taluk":"Cherrabazar","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793108","Taluk":"Cherrapunjee","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794110","Taluk":"Rongjeng","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794003","Taluk":"NA","Districtname":"NULL","statename":"NULL"},
    {"pincode":"794108","Taluk":"Resubelpara","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794002","Taluk":"Lower Chandmari","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793112","Taluk":"Shella","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794105","Taluk":"Garobadha","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794114","Taluk":"NA","Districtname":"South Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793109","Taluk":"Dawki","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"793109","Taluk":"Dawki","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793005","Taluk":"Upper Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793012","Taluk":"Mawpat","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794110","Taluk":"Ronjeng","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793120","Taluk":"Mairang","Districtname":"West Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794105","Taluk":"Grobadha","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793106","Taluk":"NA","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793007","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793102","Taluk":"Nongpoh","Districtname":"Ri Bhoi","statename":"MEGHALAYA"},
    {"pincode":"793200","Taluk":"Khliehriat","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"793002","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793110","Taluk":"Pynursla","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794107","Taluk":"Nangwalbibra","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793114","Taluk":"Mawkyrwat","Districtname":"West Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793015","Taluk":"Smit","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793151","Taluk":"Thadlaskein","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"783134","Taluk":"Dudnai","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793200","Taluk":"NA","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"793021","Taluk":"Madanriting","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793022","Taluk":"Mawlai","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793105","Taluk":"Umsning","Districtname":"Ri Bhoi","statename":"MEGHALAYA"},
    {"pincode":"793010","Taluk":"Laitkor Peak","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793010","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793003","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793113","Taluk":"Mawsynram","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793004","Taluk":"Laban","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793210","Taluk":"NA","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"793121","Taluk":"Mawphlang","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793021","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794114","Taluk":"Rongra","Districtname":"South Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794106","Taluk":"Mahendraganjj","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793016","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793009","Taluk":"Nonglyer","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793017","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793012","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793008","Taluk":"Mawlai","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793121","Taluk":"Mawphlang","Districtname":"West Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793001","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"794112","Taluk":"Mendipathar","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794107","Taluk":"Nangwalbibra","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793022","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793018","Taluk":"NA","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793009","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793019","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793106","Taluk":"NA","Districtname":"West Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793014","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793008","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793106","Taluk":"NA","Districtname":"East Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"794108","Taluk":"Resubelpara","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793006","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793015","Taluk":"Mawkynrew","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793115","Taluk":"NA","Districtname":"West Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793104","Taluk":"Sumer","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793160","Taluk":"L;adrymbai","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"794001","Taluk":"Tura","Districtname":"West Garo Hills","statename":"MEGHALAYA"},
    {"pincode":"793122","Taluk":"Ucc","Districtname":"Ri Bhoi","statename":"MEGHALAYA"},
    {"pincode":"793006","Taluk":"Rynjah","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793151","Taluk":"Thadlasken","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"793123","Taluk":"Umsaw","Districtname":"Ri Bhoi","statename":"MEGHALAYA"},
    {"pincode":"793005","Taluk":"Shillong","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793109","Taluk":"NA","Districtname":"East Khasi Hills","statename":"MEGHALAYA"},
    {"pincode":"793150","Taluk":"NA","Districtname":"Jaintia Hills","statename":"MEGHALAYA"},
    {"pincode":"761111","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761144","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761118","Taluk":"Buguda","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761120","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761121","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761119","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761104","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761108","Taluk":"Surada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761117","Taluk":"Buguda","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761110","Taluk":"Asika","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761110","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761151","Taluk":"Surada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761143","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761131","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761124","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761109","Taluk":"Surada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761101","Taluk":"Hinjili","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761116","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761125","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761141","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761114","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761107","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761115","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761104","Taluk":"Kabisuryanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761106","Taluk":"Asika","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761123","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761106","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761133","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761122","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761126","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761117","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761132","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761103","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761146","Taluk":"Hinjili","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761102","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761107","Taluk":"Asika","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761146","Taluk":"Hinjli","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761140","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761101","Taluk":"Hinjli","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761013","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761111","Taluk":"Asika","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761108","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761102","Taluk":"Hinjili","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761140","Taluk":"Aska","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761121","Taluk":"Buguda","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761104","Taluk":"Kodala","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761143","Taluk":"Buguda","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761118","Taluk":"Bhanjanagar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761013","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761215","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761211","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761026","Taluk":"Chatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761029","Taluk":"Khallikote","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761207","Taluk":"Parlakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761217","Taluk":"Mohana","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761019","Taluk":"Purushottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761012","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761005","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761016","Taluk":"R.udayagiri","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761005","Taluk":"Jarada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761035","Taluk":"Khallikote","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760010","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761100","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761017","Taluk":"Mohana","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761043","Taluk":"Purushottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761041","Taluk":"Purushottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761207","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761105","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761032","Taluk":"Kodala","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761011","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761006","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761201","Taluk":"Parlakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761007","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761045","Taluk":"Chatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761018","Taluk":"Purushottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761004","Taluk":"Jarada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761042","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761011","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761015","Taluk":"Mohana","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761041","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760001","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760007","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761214","Taluk":"R.udayagiri","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761010","Taluk":"Chikiti","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761002","Taluk":"Gopalpur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761017","Taluk":"Mohana","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761030","Taluk":"Kkhallikote","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761206","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761054","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761003","Taluk":"Purushottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760007","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760004","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761054","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761030","Taluk":"Khalikote","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761030","Taluk":"Khallikote","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760002","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761105","Taluk":"Kodala","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761208","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761055","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761026","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761031","Taluk":"Kodala","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761003","Taluk":"Purusottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760002","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761001","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761209","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761004","Taluk":"Chikiti","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761210","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761213","Taluk":"R.udayagiri","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761028","Taluk":"Chatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761055","Taluk":"Bissmagiri","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761008","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761042","Taluk":"Bomakei","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761002","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760001","Taluk":"berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761035","Taluk":"Kodala","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761209","Taluk":"Jarada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761014","Taluk":"Digapahandi","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761020","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761020","Taluk":"Chatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761010","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761200","Taluk":"Parlakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761212","Taluk":"Mohana","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"760003","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761100","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761208","Taluk":"Parlakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"760010","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761212","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761026","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761009","Taluk":"Girisola","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761052","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761028","Taluk":"Rambha","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761009","Taluk":"Berhampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761206","Taluk":"Paralkhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761210","Taluk":"Parlakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"760003","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761037","Taluk":"Chikiti","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761207","Taluk":"Serango","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761027","Taluk":"Chatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760005","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760008","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761042","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761213","Taluk":"Rayagada","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761201","Taluk":"Paralakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761025","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761009","Taluk":"Berhampurf","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761016","Taluk":"R.Udaygiri","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761043","Taluk":"Purusottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761026","Taluk":"Jarada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761211","Taluk":"Parlakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761217","Taluk":"Adva","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761206","Taluk":"Kashinagara","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761009","Taluk":"Golanthara","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761029","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761037","Taluk":"Jarada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761215","Taluk":"Garabandha","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761018","Taluk":"Kashinagara","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761019","Taluk":"Purusottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761029","Taluk":"Khalikote","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761042","Taluk":"Jarada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761045","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761028","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760009","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761007","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761001","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"760006","Taluk":"Brahmapur Sadar","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761213","Taluk":"R.Udaygiri","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761014","Taluk":"Parlakhemundi","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761018","Taluk":"Purusottampur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761014","Taluk":"Garabandha","Districtname":"Gajapati","statename":"ODISHA"},
    {"pincode":"761006","Taluk":"Jarada","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"761002","Taluk":"Chhatrapur","Districtname":"Ganjam","statename":"ODISHA"},
    {"pincode":"766011","Taluk":"Kesinga","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766106","Taluk":"Komna","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766018","Taluk":"Jaipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766015","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766019","Taluk":"Jaipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766102","Taluk":"M.rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766111","Taluk":"Boden","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766105","Taluk":"Nuapada","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766028","Taluk":"Jaipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766104","Taluk":"Nuapada","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766017","Taluk":"Junagarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766036","Taluk":"Bhawanipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766014","Taluk":"Junagarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766118","Taluk":"Duajhar","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766002","Taluk":"Bhawanipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766011","Taluk":"Golamunda","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766037","Taluk":"Thuamulrampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766107","Taluk":"Khariar","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766018","Taluk":"Klampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766018","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766100","Taluk":"Narla","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766023","Taluk":"Junagarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766029","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766013","Taluk":"Kalampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766020","Taluk":"Lanjigarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766017","Taluk":"Jaipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766118","Taluk":"Sinhapalli","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766027","Taluk":"Lanjigarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766103","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766012","Taluk":"Kesinga","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766104","Taluk":"Khariar Road","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766108","Taluk":"Sinhapalli","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766001","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766001","Taluk":"Kesinga","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766001","Taluk":"Sadar","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766002","Taluk":"Sadar","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766101","Taluk":"Narla","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766107","Taluk":"Khariar","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766100","Taluk":"M.rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766118","Taluk":"Khariar","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766037","Taluk":"Th. Rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766111","Taluk":"Khariar","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766102","Taluk":"Karlamunda","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766016","Taluk":"Golamunda","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766011","Taluk":"Bhawanipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766108","Taluk":"Sinapalli","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766017","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766015","Taluk":"Dhramgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766029","Taluk":"Dharmagarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766002","Taluk":"Bhawanipatana","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766105","Taluk":"Nuapara","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766013","Taluk":"Junagarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766029","Taluk":"Golamunda","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766019","Taluk":"Jaipatana","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766102","Taluk":"M.ramour","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766031","Taluk":"Karlamunda","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766016","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766106","Taluk":"Nuapada","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766110","Taluk":"Narla","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766026","Taluk":"Thuamulrampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766102","Taluk":"Madanpur Rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766118","Taluk":"Sinhapalli","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766118","Taluk":"Boden","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766014","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766013","Taluk":"Jaipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766100","Taluk":"Lanjigarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766032","Taluk":"Bhawanipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766037","Taluk":"Th.rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766111","Taluk":"Bodeb","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766104","Taluk":"Jonk","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766110","Taluk":"Lanjigarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766036","Taluk":"Golamunda","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766023","Taluk":"Jaipatn A","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766026","Taluk":"Dharamgarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766102","Taluk":"Madanpurrampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766014","Taluk":"Junagrh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766026","Taluk":"Jaipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766023","Taluk":"Jaipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766002","Taluk":"Junagarh","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766110","Taluk":"Madanpur Rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766100","Taluk":"Madanpur Rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766002","Taluk":"Narla","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766108","Taluk":"Sinapali","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766037","Taluk":"Bhawanipatna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766031","Taluk":"Madanpur Rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766101","Taluk":"Madanpur Rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766036","Taluk":"Bhawaniaptna","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766110","Taluk":"M.rampur","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"766118","Taluk":"Khatriar","Districtname":"Nuapada","statename":"ODISHA"},
    {"pincode":"766017","Taluk":"Narla","Districtname":"Kalahandi","statename":"ODISHA"},
    {"pincode":"764021","Taluk":"Koraput","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765015","Taluk":"Kashipur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764073","Taluk":"Umerkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764059","Taluk":"Nabarangpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765020","Taluk":"Muniguda","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"765025","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764027","Taluk":"Koraput","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764063","Taluk":"Nabarangpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765021","Taluk":"Bissamcuttack","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764044","Taluk":"Malkangiri","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764055","Taluk":"Jeypore(k)","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764049","Taluk":"Nabarangpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764039","Taluk":"Pottangi","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764077","Taluk":"Umerkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764087","Taluk":"Motu","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764056","Taluk":"Borigumma","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764052","Taluk":"Chitrakonda","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764005","Taluk":"Jeypore(k)","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765017","Taluk":"Rayagada","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"765026","Taluk":"Gudari","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764002","Taluk":"Jeypore(k)","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764041","Taluk":"Machhkund","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764075","Taluk":"Kodinga","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765020","Taluk":"Ambadala","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764001","Taluk":"Jeypur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765019","Taluk":"Bissamcuttack","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"765002","Taluk":"Rayagada","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764075","Taluk":"Kodinda","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764037","Taluk":"Pottangi","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764051","Taluk":"Kalimela","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764072","Taluk":"Umarkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764073","Taluk":"Umarkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764059","Taluk":"Nabarangour","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764061","Taluk":"Kodinga","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764076","Taluk":"Umerkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764041","Taluk":"Machh kund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764040","Taluk":"Machkund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765033","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764072","Taluk":"Umerkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764038","Taluk":"Pottangi","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764041","Taluk":"Machhkund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764043","Taluk":"Jeypore(k)","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764051","Taluk":"Malkangiri","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764014","Taluk":"Narayanpatna","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764063","Taluk":"Tentulikhunti","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765016","Taluk":"Kashipur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"765029","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764047","Taluk":"Motu","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764006","Taluk":"NA","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765019","Taluk":"Bishamakatak","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764058","Taluk":"Kotpad","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764076","Taluk":"Jharigan","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764057","Taluk":"Borigumma","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765024","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764005","Taluk":"Jeypur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764049","Taluk":"Paparahandi","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764074","Taluk":"Umerkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764063","Taluk":"Umarkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764044","Taluk":"Boipariguda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764070","Taluk":"Nabarangpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"763008","Taluk":"Koraput","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765026","Taluk":"Muniguda","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764062","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"765013","Taluk":"Narayanpatna","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764041","Taluk":"Nandapur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764071","Taluk":"Nabarangpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764086","Taluk":"Kalimela","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"765018","Taluk":"Rayagada","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"765023","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764045","Taluk":"Malkangiri","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"765013","Taluk":"Rayagada","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764028","Taluk":"Dasamantapur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764072","Taluk":"Dabugan","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765022","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764056","Taluk":"Kotpad","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764086","Taluk":"Malkangiri","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"763003","Taluk":"Sunabeda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764036","Taluk":"Koraput","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765016","Taluk":"K.singhpur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764002","Taluk":"Kundura","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764003","Taluk":"Boipariguda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"763008","Taluk":"Damanjodi","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764028","Taluk":"Koraput","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764074","Taluk":"Dabugan","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764011","Taluk":"Koraput","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764002","Taluk":"Jeypur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764078","Taluk":"Nabarangapur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764078","Taluk":"Nabarangpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764003","Taluk":"Jeypur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764021","Taluk":"Nandapur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764044","Taluk":"Mathili","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764051","Taluk":"Orkel","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"765034","Taluk":"Gunupur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764003","Taluk":"Jeypore(k)","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764057","Taluk":"Bhairabsingipur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764055","Taluk":"Jeypore","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764055","Taluk":"Boriguma","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764071","Taluk":"Nabarangour","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764027","Taluk":"Narayanpatana","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764081","Taluk":"Machkund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764059","Taluk":"Dabugan","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765025","Taluk":"Padmapur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764001","Taluk":"Jeypore(k)","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764004","Taluk":"Jeypur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765017","Taluk":"Kalyanasingpur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764047","Taluk":"Malkangiri","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764051","Taluk":"Chitrakonda","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764057","Taluk":"Birigumma","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764043","Taluk":"Boipariguda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764046","Taluk":"Malkangiri","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764085","Taluk":"Nabarangpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764020","Taluk":"Koraput","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764043","Taluk":"Orkel","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764038","Taluk":"Padua","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764074","Taluk":"Raighar","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765013","Taluk":"Lakshmipur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764055","Taluk":"Nabarangapur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"765013","Taluk":"Laxmipur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764040","Taluk":"Machhkund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764059","Taluk":"Kalyanasingpur","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"765017","Taluk":"Kodinga","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764048","Taluk":"Malkangiri","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764070","Taluk":"Nabarangpu","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764063","Taluk":"Nabarangapur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764059","Taluk":"Nabarangapur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"763004","Taluk":"Sunabeda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764047","Taluk":"Kalimela","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764042","Taluk":"Machh kund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764042","Taluk":"Machkund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764036","Taluk":"Similiguda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764071","Taluk":"Kodinga","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764085","Taluk":"Khatiguda","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764045","Taluk":"NA","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764037","Taluk":"Nandapur","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"764052","Taluk":"Kalimela","Districtname":"Malkangiri","statename":"ODISHA"},
    {"pincode":"764056","Taluk":"Boipariguda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765001","Taluk":"Rayagada","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"763002","Taluk":"Sunabeda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765029","Taluk":"Rayagada","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764056","Taluk":"Kundura","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765001","Taluk":"Rayagada(k)","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764063","Taluk":"Nabarngpur","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764081","Taluk":"Machh kund","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"763001","Taluk":"Sunabeda","Districtname":"Koraput","statename":"ODISHA"},
    {"pincode":"765025","Taluk":"Gudari","Districtname":"Rayagada","statename":"ODISHA"},
    {"pincode":"764088","Taluk":"Umerkote","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"764071","Taluk":"Paparahandi","Districtname":"Nabarangapur","statename":"ODISHA"},
    {"pincode":"762112","Taluk":"Paburia","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762029","Taluk":"Gumagarh","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762023","Taluk":"Jhadrajing","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762015","Taluk":"Boudh","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762021","Taluk":"Bamunigaon","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762018","Taluk":"Ghantapada","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762010","Taluk":"Tikabali","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762020","Taluk":"Harabhanga","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762012","Taluk":"Khajuripada","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762013","Taluk":"Purunakatak","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762104","Taluk":"Daringbadi","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762029","Taluk":"Ghantapada","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762017","Taluk":"Kantamal","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762103","Taluk":"Balliguda","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762026","Taluk":"Sarsara","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762030","Taluk":"Baghiabahal","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762015","Taluk":"Bounsuni","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762012","Taluk":"Khajuripda","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762101","Taluk":"Raikia","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762110","Taluk":"Barakhama","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762002","Taluk":"Phulbani","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762014","Taluk":"Boudh","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762109","Taluk":"Kurtamgarh","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762102","Taluk":"Nuagaon","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762022","Taluk":"Kalinga","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762024","Taluk":"Bounsuni","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762105","Taluk":"Kothagarh","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762011","Taluk":"Phiringia","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762027","Taluk":"Linepada","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762016","Taluk":"Manamunda","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762106","Taluk":"Sarangada","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762028","Taluk":"Chakapad","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762107","Taluk":"Tumudibandha","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762024","Taluk":"Biranarasinghpur","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762011","Taluk":"Phulbani","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762014","Taluk":"Boudhraj","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762100","Taluk":"G.udayagiri","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762100","Taluk":"G Udayagiri","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762018","Taluk":"Ghantapada","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762019","Taluk":"Sankarakhole","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762012","Taluk":"Khajuripada","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762015","Taluk":"Bousuni","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762011","Taluk":"Phirigia","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762020","Taluk":"Harabhnaga","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762020","Taluk":"Harabhuin","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762001","Taluk":"Phulbani","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762020","Taluk":"Harabhanga","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762024","Taluk":"Birasinghpur","Districtname":"Boudh","statename":"ODISHA"},
    {"pincode":"762011","Taluk":"Phirngia","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762101","Taluk":"Raikai","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762103","Taluk":"Baliguda","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"762012","Taluk":"Khajuriapada","Districtname":"Kandhamal","statename":"ODISHA"},
    {"pincode":"756047","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756038","Taluk":"Bhograi","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756049","Taluk":"Niligiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756036","Taluk":"Bhograi","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756086","Taluk":"Jaleswar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756043","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756027","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756030","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756034","Taluk":"Jaleswar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756046","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756045","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756028","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756058","Taluk":"Nilgiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756048","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756019","Taluk":"Balasore","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756044","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756079","Taluk":"Bhograi","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756033","Taluk":"Raibania","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756026","Taluk":"Baliapal","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756037","Taluk":"Bhograi","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756051","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756001","Taluk":"Balasore","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756024","Taluk":"Baliapal","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756083","Taluk":"Baliapal","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756003","Taluk":"Balasore","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756080","Taluk":"Jaleswar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756060","Taluk":"Nilgiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756042","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756035","Taluk":"Bhograi","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756029","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756085","Taluk":"Bhograi","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756025","Taluk":"Balasore","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756001","Taluk":"Baleshwar Sadar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756056","Taluk":"Baleshwar Sadar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756023","Taluk":"Baliapal","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756081","Taluk":"Singla","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756028","Taluk":"Rupsa","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756047","Taluk":"Khaira","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756021","Taluk":"Balasore","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756003","Taluk":"Baleshwar Sadar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756022","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756033","Taluk":"Jaleswar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756039","Taluk":"Bhograi","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756020","Taluk":"Niligiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756020","Taluk":"Nilgiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756055","Taluk":"Soro","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756043","Taluk":"Niligiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756056","Taluk":"Balasore","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756049","Taluk":"Nilgiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756084","Taluk":"Jaleswar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756020","Taluk":"Nilagiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756040","Taluk":"Nilgiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756081","Taluk":"Jaleswar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756041","Taluk":"Nilgiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756041","Taluk":"Nilagiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756059","Taluk":"Simulia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756080","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756046","Taluk":"Similia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756032","Taluk":"Jaleswar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756081","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756019","Taluk":"Remuna","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756023","Taluk":"Singla","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756040","Taluk":"Nilagiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756002","Taluk":"Balasore","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756002","Taluk":"Baleshwar Sadar","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756019","Taluk":"Nilgiri","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756023","Taluk":"Basta","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756134","Taluk":"Similia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756114","Taluk":"Bant","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756125","Taluk":"Basudebpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756128","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756122","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756113","Taluk":"Bant","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756124","Taluk":"Basudevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756182","Taluk":"Simulia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756118","Taluk":"Dhusuri","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756101","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756129","Taluk":"Chandabali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756116","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756126","Taluk":"Similia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756112","Taluk":"Bant","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756129","Taluk":"Chandbali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756123","Taluk":"Basudevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756117","Taluk":"Dhamanagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756115","Taluk":"Bant","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756144","Taluk":"Bant","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756138","Taluk":"Chandbali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756182","Taluk":"Similia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756125","Taluk":"Basudevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756139","Taluk":"Tihidi","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756131","Taluk":"Tihidi","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756116","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756168","Taluk":"Basudevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756137","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756121","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756165","Taluk":"Basudevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756135","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756115","Taluk":"Agarpada","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756130","Taluk":"Tihidi","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756164","Taluk":"Basudevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756111","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756133","Taluk":"Chandbali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756166","Taluk":"Similia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756119","Taluk":"Dhamanagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756101","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756113","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756112","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756167","Taluk":"Tihidi","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756119","Taluk":"Dhusuri","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756100","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756181","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756112","Taluk":"Bhandari Pokhari","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756120","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756163","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756163","Taluk":"Bhandari Pokhari","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756163","Taluk":"Tihidi","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756111","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756133","Taluk":"Chandabali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756132","Taluk":"Chandbali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756121","Taluk":"Hatadihi","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"756171","Taluk":"Basudebpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756117","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756130","Taluk":"Chandabali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756119","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756127","Taluk":"Tihidi","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756171","Taluk":"Chandbali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756162","Taluk":"Basudevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756137","Taluk":"Dhamanagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756127","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756181","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756126","Taluk":"Simulia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756131","Taluk":"Chandabali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756115","Taluk":"Bant","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756118","Taluk":"Dhamnagar","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756168","Taluk":"Basudebpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756165","Taluk":"Basduevpur","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756132","Taluk":"Chandabali","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756122","Taluk":"Bhandari Pokhari","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756121","Taluk":"Bhandari Pokhari","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756133","Taluk":"Similia","Districtname":"Baleswar","statename":"ODISHA"},
    {"pincode":"756112","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756116","Taluk":"Dhusuri","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756135","Taluk":"Bhadrak Rural","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"756123","Taluk":"Bhadrak","Districtname":"Bhadrak","statename":"ODISHA"},
    {"pincode":"751001","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752103","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751022","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751020","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751019","Taluk":"Balianta","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752109","Taluk":"Kakatpur","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752110","Taluk":"Gop","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752106","Taluk":"Nimapada","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752120","Taluk":"Nimapada","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751003","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752106","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751009","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752102","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751014","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751018","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752121","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752120","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752105","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752100","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752101","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752111","Taluk":"Kakatpur","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752118","Taluk":"Kakatpur","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751002","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752104","Taluk":"Pipili","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752107","Taluk":"Kakatpur","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752114","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752108","Taluk":"Kakatpur","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752110","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752115","Taluk":"Balianta","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752113","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752116","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751006","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751011","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751016","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752119","Taluk":"Kakatpur","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752105","Taluk":"Nimapada","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752121","Taluk":"Gop","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751019","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751025","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751015","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751019","Taluk":"Lingaraj","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751006","Taluk":"Saheednagar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751024","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752108","Taluk":"Kakatpur]","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752111","Taluk":"Konark","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751030","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752111","Taluk":"Nimapara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752116","Taluk":"Kakatpur","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751017","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752107","Taluk":"Gop","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751012","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752120","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"752101","Taluk":"Balianta","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752114","Taluk":"Niampara","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751008","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751010","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751013","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752114","Taluk":"Pipili","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751023","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751007","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751021","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751005","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"751002","Taluk":"Lingaraj","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752110","Taluk":"Nimapada","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"751004","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"753012","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754011","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754010","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754009","Taluk":"Banki","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753014","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754004","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754005","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754018","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754010","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753001","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754001","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754008","Taluk":"Banki","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754006","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754001","Taluk":"Saheednagar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"754005","Taluk":"Barang","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754112","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753011","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754012","Taluk":"Chandaka","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"753002","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754100","Taluk":"Cuttack  Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754003","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753006","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753004","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753003","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754013","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754005","Taluk":"Saheednagar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"754007","Taluk":"Banki","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754105","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754100","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754003","Taluk":"Gobindpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754006","Taluk":"Barang","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754003","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753010","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753013","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753008","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754002","Taluk":"Niali","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753007","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754004","Taluk":"Balipatna","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"754004","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753009","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754018","Taluk":"Gobindpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"753015","Taluk":"Cuttack Sadar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"755016","Taluk":"Binjharpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755005","Taluk":"Dharmasala","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754205","Taluk":"Darpanigarh","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754220","Taluk":"Rajkanika","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755009","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754209","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"755036","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754215","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754223","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754228","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754212","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755013","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754214","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754250","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754217","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755018","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754218","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754240","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755019","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755004","Taluk":"Jajapur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755004","Taluk":"Binjharpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755010","Taluk":"Dharmasala","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755001","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755023","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755061","Taluk":"Aul","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755007","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755027","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754231","Taluk":"Aul","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755008","Taluk":"Dharmasala","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755024","Taluk":"Darpanigarh","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754213","Taluk":"Marsaghai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754219","Taluk":"Aul","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754220","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754245","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755012","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754244","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755015","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754221","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754215","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754225","Taluk":"Rajnagar","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754208","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754227","Taluk":"Rajkanika","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755017","Taluk":"Dharmasala","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754213","Taluk":"Marasaghai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754224","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754289","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755019","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"755003","Taluk":"Dharmasala","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755014","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755062","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754246","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755026","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755025","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754204","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754222","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754253","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754292","Taluk":"Darpanigarh","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755043","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754293","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754239","Taluk":"Aul","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754202","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754207","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754206","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754210","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755044","Taluk":"Darpanigarh","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755028","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754248","Taluk":"Rajnagar","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755006","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755022","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755011","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755020","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755023","Taluk":"Dharmasala","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754222","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754203","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754216","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755043","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755023","Taluk":"Jajpur Road.","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755050","Taluk":"Dharmasala","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755012","Taluk":"Binjharpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755049","Taluk":"Darpanigarh","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754211","Taluk":"Kendrapara","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754203","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754205","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754227","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"755019","Taluk":"Jajpur Raod","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754214","Taluk":"Jajpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"755027","Taluk":"Bimjharpur","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754248","Taluk":"Pattamundai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754035","Taluk":"Badamba","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754030","Taluk":"Tigiria","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754119","Taluk":"Balikuda","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754102","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754037","Taluk":"Narasinghpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754132","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754028","Taluk":"Jagatpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754108","Taluk":"Balikuda","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754107","Taluk":"Naugaon","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754130","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754032","Taluk":"Narasinghpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754103","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754132","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754162","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754139","Taluk":"Kujang","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754102","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754113","Taluk":"NA","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754140","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754162","Taluk":"Marsaghai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754029","Taluk":"NA","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754024","Taluk":"Darpan","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754130","Taluk":"Kishorenagar","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754138","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754103","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754295","Taluk":"Athagad","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754029","Taluk":"Athagad","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754029","Taluk":"Athagarh","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754120","Taluk":"Paradip","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754131","Taluk":"Kishannagar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754134","Taluk":"Kishorenagar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754113","Taluk":"Naugaonhat","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754296","Taluk":"Darpan","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754031","Taluk":"Badamba","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754114","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754200","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754106","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754022","Taluk":"Tangi Choudwar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754023","Taluk":"Darpan","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754141","Taluk":"Kujang","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754037","Taluk":"Narsinghpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754141","Taluk":"Marsaghai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754153","Taluk":"Patkura","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754031","Taluk":"Baramba","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754113","Taluk":"Naugaon","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754142","Taluk":"Paradip","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754024","Taluk":"Badachana","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754082","Taluk":"Darpan","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754136","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754134","Taluk":"Patkura","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754134","Taluk":"Marsaghai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754022","Taluk":"Tangi","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754200","Taluk":"Jagatpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754290","Taluk":"Mahanga","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754201","Taluk":"Salepur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754138","Taluk":"Kujang","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754111","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754025","Taluk":"Jagatpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754135","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754130","Taluk":"Kishannagar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754140","Taluk":"Kujang","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754110","Taluk":"Balikuda","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754107","Taluk":"Balikuda","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754028","Taluk":"Choudwar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754106","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754027","Taluk":"Choudwar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754102","Taluk":"Biridi","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754294","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754025","Taluk":"Choudwar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754137","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754026","Taluk":"Choudwar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754109","Taluk":"Naugaon","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754104","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754113","Taluk":"Balikuda","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754027","Taluk":"Athagarh","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754139","Taluk":"Ersama","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754159","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754107","Taluk":"Naugaonhat","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754023","Taluk":"Badachana","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754110","Taluk":"Kujang","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754134","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754021","Taluk":"Jagatpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754133","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754110","Taluk":"Ersama","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754201","Taluk":"Salipur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754023","Taluk":"Darpan","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754153","Taluk":"Marsaghai","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754082","Taluk":"Badachana","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754132","Taluk":"Kishorenagar","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754139","Taluk":"Kujanga","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754035","Taluk":"Tigiria","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754071","Taluk":"Choudwar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754106","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754131","Taluk":"Kishorenagar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754027","Taluk":"Jagatpur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754106","Taluk":"Balikuda","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754134","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754111","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754114","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754160","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754021","Taluk":"Choudwar","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754104","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754162","Taluk":"Patkura","Districtname":"Kendrapara","statename":"ODISHA"},
    {"pincode":"754142","Taluk":"Kujang","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754133","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754145","Taluk":"Paradip","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754104","Taluk":"Raghunathpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754104","Taluk":"Tirtol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754134","Taluk":"Marsaghai","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754104","Taluk":"Kujang","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754200","Taluk":"NA","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754143","Taluk":"Paradip","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754162","Taluk":"Jagatsinghapur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754296","Taluk":"Badachana","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"754138","Taluk":"Tirol","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754133","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"754290","Taluk":"Salepur","Districtname":"Cuttack","statename":"ODISHA"},
    {"pincode":"754137","Taluk":"Jagatsinghpur","Districtname":"Jagatsinghapur","statename":"ODISHA"},
    {"pincode":"757025","Taluk":"Betnoti","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757050","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757082","Taluk":"Betnoti","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757052","Taluk":"Betnoti","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757055","Taluk":"Rasgovindpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757075","Taluk":"Barsahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757048","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757041","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757028","Taluk":"Betnoti","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757030","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757087","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757001","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757035","Taluk":"Karanjia","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757105","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757043","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757042","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757021","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757018","Taluk":"Rasgovindpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757039","Taluk":"Karanjia","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757083","Taluk":"Barsahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757054","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757033","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757032","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757029","Taluk":"Betnoti","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757034","Taluk":"Karanjia","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757019","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757026","Taluk":"Barsahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757016","Taluk":"R.g.pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757047","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757020","Taluk":"Rg Pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757084","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757086","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757100","Taluk":"Betnoti","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757053","Taluk":"Bahalda","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757074","Taluk":"Barsahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757023","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757036","Taluk":"Karanjia","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757092","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757040","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757077","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757045","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757051","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757022","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757003","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757014","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757031","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757054","Taluk":"Bahalda","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757046","Taluk":"Bahalda","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757104","Taluk":"Khunta","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757020","Taluk":"R.g. Pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757101","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757024","Taluk":"Barsahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757091","Taluk":"Karanjia","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757049","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757107","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757085","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757020","Taluk":"R.g.pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757046","Taluk":"Rairangpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757074","Taluk":"Khunta","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757024","Taluk":"Barasahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757038","Taluk":"Karanjia","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757002","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757073","Taluk":"R.g.pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757037","Taluk":"Karanjia","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757018","Taluk":"R.g.pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757104","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757017","Taluk":"Barsahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757081","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757102","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757016","Taluk":"R.g. Pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757079","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757093","Taluk":"Baripada","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757103","Taluk":"Barsahi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757027","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757106","Taluk":"Udala","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757017","Taluk":"Betnoti","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757020","Taluk":"Rasgovindpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757073","Taluk":"Rasgovindpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757052","Taluk":"NA","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757017","Taluk":"Barsashi","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757016","Taluk":"Rasgovindpur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"757073","Taluk":"R.g. Pur","Districtname":"Mayurbhanj","statename":"ODISHA"},
    {"pincode":"752016","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752021","Taluk":"Tangi","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752093","Taluk":"Khandapara","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752014","Taluk":"Satyabadi","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752012","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752017","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752011","Taluk":"Brahmagiri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752060","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752054","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752030","Taluk":"Banpur","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752031","Taluk":"Banpur","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752066","Taluk":"Bolagarh","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752064","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752050","Taluk":"Bhubaneswar","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752035","Taluk":"Banpur","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752024","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752002","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752078","Taluk":"Khandaparagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752025","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752061","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752094","Taluk":"Khandapara","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752065","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752023","Taluk":"Tangi","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752063","Taluk":"Bhapur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752084","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752050","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752030","Taluk":"Krishnaprasad","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752068","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752057","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752082","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752077","Taluk":"Khandaparagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752030","Taluk":"Krushnaprsad","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752019","Taluk":"Banpur","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752026","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752046","Taluk":"Satyabadi","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752085","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752045","Taluk":"Sakhigopal","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752014","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752001","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752070","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752030","Taluk":"Chilika","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752056","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752089","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752081","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752034","Taluk":"Banpur","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752027","Taluk":"Banpur","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752080","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752062","Taluk":"Nayagarh","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752013","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752092","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752062","Taluk":"Begunia","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752019","Taluk":"Puri","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752070","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752091","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752020","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752046","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752038","Taluk":"Begunia","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752018","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752062","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752011","Taluk":"Krushnaprasad","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752090","Taluk":"Khandapara","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752079","Taluk":"Ranpur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752084","Taluk":"Daspalla","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752015","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752064","Taluk":"Bolagarh","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752083","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752094","Taluk":"Khandapra","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752079","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752045","Taluk":"Satyabadi","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752070","Taluk":"Odagaon","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752034","Taluk":"Chilika","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752085","Taluk":"Daspalla","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752061","Taluk":"Nayagarh","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752092","Taluk":"Ranapur","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752050","Taluk":"Satyabadi","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752060","Taluk":"Kanas","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752054","Taluk":"Jatni","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752022","Taluk":"Tangi","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752050","Taluk":"Jatni","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752077","Taluk":"Khandapara","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752019","Taluk":"Pipli","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752055","Taluk":"Khurda","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752090","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752093","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752094","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752037","Taluk":"Banpur","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752069","Taluk":"Nayagarh","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752083","Taluk":"Odagaon","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752081","Taluk":"Odagaon","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752050","Taluk":"Pipili","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752020","Taluk":"Tangi","Districtname":"Khorda","statename":"ODISHA"},
    {"pincode":"752030","Taluk":"Krushnaprasad","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752014","Taluk":"Satyabai","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"752080","Taluk":"Odagaon","Districtname":"Nayagarh","statename":"ODISHA"},
    {"pincode":"752003","Taluk":"Puri","Districtname":"Puri","statename":"ODISHA"},
    {"pincode":"767066","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767020","Taluk":"Loisingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767022","Taluk":"Loisingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767033","Taluk":"Kantabanji","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767018","Taluk":"Birmaharajpur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767032","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767002","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767016","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767067","Taluk":"Tusra","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767016","Taluk":"Tarbha","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767045","Taluk":"Binka","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767030","Taluk":"Tusra","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767029","Taluk":"Tusra","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767060","Taluk":"Kantabanji","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767038","Taluk":"Kantabanji","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767045","Taluk":"S.rampur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767037","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767026","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767025","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767024","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767017","Taluk":"Sonepur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767021","Taluk":"Loisingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767001","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767040","Taluk":"Kantabanji","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767023","Taluk":"S Rampur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767027","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767041","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767019","Taluk":"Binka","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767038","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767048","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767028","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767061","Taluk":"Loisingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767042","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767019","Taluk":"S.rampur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767048","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767035","Taluk":"Kantabanji","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767029","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767039","Taluk":"Patnagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767033","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767065","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767021","Taluk":"Losingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767022","Taluk":"Losingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767062","Taluk":"Ullunda","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767039","Taluk":"Kantabanji","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767023","Taluk":"S.rampur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767027","Taluk":"Patngarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767020","Taluk":"Tarbha","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767046","Taluk":"Tusura","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767062","Taluk":"Sonapur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767023","Taluk":"S.rampur","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767024","Taluk":"Loisingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767046","Taluk":"Tusra","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767040","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767062","Taluk":"B.m.pur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767038","Taluk":"Khaprakhol","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767018","Taluk":"Ullunda","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767026","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767029","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767020","Taluk":"Balangir","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767035","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767065","Taluk":"Loisingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767066","Taluk":"Sindhekela","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767068","Taluk":"Loisingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767068","Taluk":"Sonepur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767002","Taluk":"Tusra","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767048","Taluk":"Tusra","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767018","Taluk":"Brmaharajpur","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"767070","Taluk":"Titilagarh","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767035","Taluk":"Sindhekela","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767020","Taluk":"Losingha","Districtname":"Balangir","statename":"ODISHA"},
    {"pincode":"767016","Taluk":"Tarha","Districtname":"Sonapur","statename":"ODISHA"},
    {"pincode":"759125","Taluk":"Athamallik","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759146","Taluk":"Parajang","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759026","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759126","Taluk":"Athamallik","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759120","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759024","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759017","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759122","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759132","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759128","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759100","Taluk":"Rengali Damsite","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759019","Taluk":"Hindol","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759117","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759103","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759129","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759020","Taluk":"Hindol","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759014","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759118","Taluk":"Pallahara","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759039","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759143","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759141","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759013","Taluk":"Dhenkanal Sadar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759105","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759027","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759116","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759025","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759124","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759104","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759028","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759026","Taluk":"Bhuban","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759119","Taluk":"Pallahara","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759023","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759016","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759013","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759018","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759022","Taluk":"Hindol","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759121","Taluk":"Hindol","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759015","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759127","Taluk":"Athamallik","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759100","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759130","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759021","Taluk":"Hindol","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759017","Taluk":"Gandia","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759019","Taluk":"Parajang","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759124","Taluk":"Chhendipada","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759147","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759102","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759019","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759001","Taluk":"Dhenkanal Sadar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759001","Taluk":"Dhenkanal","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759146","Taluk":"Kamakhyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759143","Taluk":"Anugul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759100","Taluk":"Talcher Sadar","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759019","Taluk":"Motunga","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759132","Taluk":"Anugul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759039","Taluk":"Kamakshyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759104","Taluk":"Colliery","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759111","Taluk":"Chhendipada","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759021","Taluk":"Rasol","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759014","Taluk":"Gandia","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759145","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759132","Taluk":"Banarpal","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759100","Taluk":"Khamar","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759016","Taluk":"Gandia","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759028","Taluk":"Kamakshyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759125","Taluk":"Athmallik","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759123","Taluk":"Angul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759146","Taluk":"Samal Barrage","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759103","Taluk":"Kaniha","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759017","Taluk":"Bhuban","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759120","Taluk":"Parajang","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759118","Taluk":"Khamar","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759148","Taluk":"Colliery","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759123","Taluk":"Anugul","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759121","Taluk":"Balimi","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759125","Taluk":"Thakurgarh","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759026","Taluk":"Tumusingha","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759105","Taluk":"Rengali Damsite","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759100","Taluk":"Samal Barrage","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759037","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759018","Taluk":"Kamakshyanagar","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759128","Taluk":"Balimi","Districtname":"Dhenkanal","statename":"ODISHA"},
    {"pincode":"759129","Taluk":"NA","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759101","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759107","Taluk":"Talcher Sadar","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759040","Taluk":"Banarpal","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"759106","Taluk":"Talcher","Districtname":"Angul","statename":"ODISHA"},
    {"pincode":"758076","Taluk":"Telkoi","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758043","Taluk":"Anandapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758082","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758047","Taluk":"Champua","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758022","Taluk":"Anandapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758021","Taluk":"Anandapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758031","Taluk":"Champua","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758032","Taluk":"Champua","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758015","Taluk":"Anandapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758016","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758017","Taluk":"Patna","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758027","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758044","Taluk":"Champua","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758028","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758079","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758014","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758083","Taluk":"Hatadihi","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758045","Taluk":"Patna","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758020","Taluk":"Anandapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758034","Taluk":"Barbil","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758037","Taluk":"Barbil","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758025","Taluk":"Anandapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758023","Taluk":"Hatadihi","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758026","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758034","Taluk":"NA","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758081","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758038","Taluk":"Barbil","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758025","Taluk":"Jajpur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"758085","Taluk":"Banspal","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758085","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758035","Taluk":"Barbil","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758018","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758078","Taluk":"Hatadihi","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758025","Taluk":"Anadapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758032","Taluk":"Patna","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758013","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758080","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758028","Taluk":"Gahtagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758029","Taluk":"Ghatagaon","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758032","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758084","Taluk":"Telkoi","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758002","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758030","Taluk":"Patna","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758046","Taluk":"Champua","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758041","Taluk":"Champua","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758046","Taluk":"Patna","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758019","Taluk":"Telkoi","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758040","Taluk":"Barbil","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758031","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758002","Taluk":"NA","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758001","Taluk":"Kendujhar Town","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758001","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758046","Taluk":"Keonjhar","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758036","Taluk":"Barbil","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758014","Taluk":"NA","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758035","Taluk":"Baril","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758043","Taluk":"Andnapur","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758016","Taluk":"Patna","Districtname":"Kendujhar","statename":"ODISHA"},
    {"pincode":"758025","Taluk":"Jajapur Road","Districtname":"Jajapur","statename":"ODISHA"},
    {"pincode":"768006","Taluk":"Sadar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768040","Taluk":"Bargarh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768029","Taluk":"Barpali","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768104","Taluk":"Dhama","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768215","Taluk":"Laikera","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768004","Taluk":"Sambalpur","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768031","Taluk":"Ambabhana","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768110","Taluk":"Barkote","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768113","Taluk":"Maneswar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768042","Taluk":"Jharbandh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768202","Taluk":"Jharsuguda Sadar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768222","Taluk":"Mahulpalli","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768104","Taluk":"Bheden","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768103","Taluk":"Bheden","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768226","Taluk":"Lakhanpur","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768027","Taluk":"Attabira","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768109","Taluk":"Reamal","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768038","Taluk":"Bijepur","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768029","Taluk":"Barapali","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768228","Taluk":"Kuchinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768221","Taluk":"Laikera","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768221","Taluk":"Govindpur","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768032","Taluk":"Bijepur","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768112","Taluk":"Jujumura","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768227","Taluk":"Rairakhol","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768036","Taluk":"Paikmal","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768107","Taluk":"Jamankira","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768220","Taluk":"Jharsuguda","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768219","Taluk":"Lakhanpur","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768108","Taluk":"Barkote","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768228","Taluk":"Mahulpalli","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768221","Taluk":"Bamra","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768038","Taluk":"Bhatli","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768038","Taluk":"Bargarh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768032","Taluk":"Barpali","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768211","Taluk":"Lakhanpur","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768217","Taluk":"Belpahar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768052","Taluk":"Ambabhana","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768227","Taluk":"Mahulpalli","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768105","Taluk":"Rairakhol","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768045","Taluk":"Ambabhana","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768028","Taluk":"Bargarh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768121","Taluk":"Tileibani","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768040","Taluk":"Bargarh Sadar","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768039","Taluk":"Paikamal","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768025","Taluk":"Dhankauda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768105","Taluk":"Jujumura","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768212","Taluk":"Katarbaga","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768005","Taluk":"Maneswar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768106","Taluk":"Naktideul","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768222","Taluk":"Kochinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768033","Taluk":"Sohella","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768222","Taluk":"Kuchinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768029","Taluk":"Bargarh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768218","Taluk":"Belpahar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768220","Taluk":"Kirmira","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768213","Taluk":"Laikera","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768030","Taluk":"Bargarh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768030","Taluk":"Bhatli","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768107","Taluk":"Kochinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768104","Taluk":"Bargarh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768039","Taluk":"Paikmal","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768036","Taluk":"Rajborasambar","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768214","Taluk":"Kochinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768034","Taluk":"Sohella","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768029","Taluk":"Bargarh Sadar","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768200","Taluk":"Dhankauda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768049","Taluk":"Padmapur","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768212","Taluk":"Rengali","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768216","Taluk":"Brajarajnagar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768001","Taluk":"Sadar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768004","Taluk":"Sadar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768017","Taluk":"Dhankauda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768033","Taluk":"Bargarh","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768216","Taluk":"Jharsuguda Sadar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768035","Taluk":"Rajborasambar","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768106","Taluk":"Rairakhol","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768211","Taluk":"Brajarajnagar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768050","Taluk":"Paikmal","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768033","Taluk":"Bheden","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768026","Taluk":"Dhankauda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768037","Taluk":"Gaisilet","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768224","Taluk":"Govindpur","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768108","Taluk":"Debagarh","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768108","Taluk":"Deogarh","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768102","Taluk":"Bheden","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768005","Taluk":"Dhanupali","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768035","Taluk":"Melchhamunda","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768111","Taluk":"Attabira","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768052","Taluk":"Ambabhona","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768204","Taluk":"Jharsuguda Sadar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768202","Taluk":"Jharsuguda","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768018","Taluk":"Burla","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768109","Taluk":"Kundheigola","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768108","Taluk":"Tileibani","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768049","Taluk":"Rajborasamber","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768006","Taluk":"Dhankauda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768222","Taluk":"Jamankira","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768121","Taluk":"Debagarh","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768115","Taluk":"Bheden","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768037","Taluk":"Melchhamunda","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768112","Taluk":"Jamankira","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768016","Taluk":"Hirakud","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768203","Taluk":"Jharsuguda","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768234","Taluk":"Jharsuguda","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768033","Taluk":"Sohela","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768105","Taluk":"Jujomura","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768020","Taluk":"Burla","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768042","Taluk":"Rajborasamber","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768212","Taluk":"Sasan","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768034","Taluk":"Ambabhona","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768201","Taluk":"Jharsuguda","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768213","Taluk":"Kolabira","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768048","Taluk":"Bijepur","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768019","Taluk":"Burla","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768119","Taluk":"Barkot","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768102","Taluk":"Attabira","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768045","Taluk":"NA","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768225","Taluk":"Banaharapali","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768107","Taluk":"Reamal","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768106","Taluk":"Kisinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768003","Taluk":"Sadar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768108","Taluk":"Reamal","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768224","Taluk":"Bamra","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768214","Taluk":"Rengali","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768200","Taluk":"Jujumura","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768035","Taluk":"Gaisilet","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768227","Taluk":"Kuchinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768227","Taluk":"Kochinda","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768212","Taluk":"NA","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768017","Taluk":"Burla","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768035","Taluk":"Rajborasamber","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768002","Taluk":"Sadar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768234","Taluk":"Lakhanpur Bo","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768038","Taluk":"Melchhamunda","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768118","Taluk":"NA","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768112","Taluk":"NA","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768228","Taluk":"Kundheigola","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768204","Taluk":"Jharsuguda","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768233","Taluk":"Brajarajnagar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768112","Taluk":"Sadar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768234","Taluk":"Lakhanpur","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768200","Taluk":"Maneswar","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768212","Taluk":"Jamankira","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768119","Taluk":"Debagarh","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768225","Taluk":"Brajarajnagar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768036","Taluk":"Rajborasamber","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768211","Taluk":"Lakhanpur Ho","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768119","Taluk":"Reamal","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768214","Taluk":"Katarbaga","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768113","Taluk":"Dhama","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768110","Taluk":"Barkot","Districtname":"Debagarh","statename":"ODISHA"},
    {"pincode":"768037","Taluk":"Barapali","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768032","Taluk":"Barapali","Districtname":"Bargarh","statename":"ODISHA"},
    {"pincode":"768200","Taluk":"Sambalpur","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768202","Taluk":"Laikera","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768200","Taluk":"Sasan","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768204","Taluk":"Jhrasuguda Sadar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768204","Taluk":"Jhasuguda Sadar","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"768200","Taluk":"Rengali","Districtname":"Sambalpur","statename":"ODISHA"},
    {"pincode":"768217","Taluk":"Lakhanpur","Districtname":"Jharsuguda","statename":"ODISHA"},
    {"pincode":"770033","Taluk":"Kuarmunda","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770036","Taluk":"Bisra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770017","Taluk":"Rajgangpur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770012","Taluk":"Lephripara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770020","Taluk":"Tangarpali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770016","Taluk":"Bargaon","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770018","Taluk":"Kutra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769043","Taluk":"Tangarapali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770021","Taluk":"Lephripara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770015","Taluk":"Talasara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770014","Taluk":"Talasara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770037","Taluk":"Lathikata","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770040","Taluk":"Lahunipara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770019","Taluk":"Bhasma","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770072","Taluk":"Tangarpali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770038","Taluk":"Bonaigarh","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770022","Taluk":"Bhasma","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770036","Taluk":"Nuagaon Block","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770073","Taluk":"Sundargarh","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770031","Taluk":"Raghunathapali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770076","Taluk":"Hemgir","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770015","Taluk":"Balisankara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770074","Taluk":"Balisankara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770015","Taluk":"Balisanakara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770051","Taluk":"Banei","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770002","Taluk":"Tangarpali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770032","Taluk":"Bisra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770036","Taluk":"Bisra Block","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770037","Taluk":"Bisra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770016","Taluk":"Baragaon","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770035","Taluk":"Bisra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770041","Taluk":"Lahunipara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769012","Taluk":"Raurkela Industrial Township (ITS)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770020","Taluk":"Sundargarh","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770075","Taluk":"Hemgir","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770031","Taluk":"Lathikata","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770011","Taluk":"Tangarpali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770038","Taluk":"Banei","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770019","Taluk":"Sundargarh","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770011","Taluk":"Lephripara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770024","Taluk":"Hemgir","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770040","Taluk":"Kamarposh Balang","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770037","Taluk":"Brahmani Tarang","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769001","Taluk":"Rourkela Steel City","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770043","Taluk":"Gurundia","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769004","Taluk":"Lathikata","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770037","Taluk":"Gurundia","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770002","Taluk":"Subdega","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770035","Taluk":"Hatibari","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770034","Taluk":"Rajagangapur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769004","Taluk":"Raurkela Industrial Township (ITS)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769001","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770044","Taluk":"Lahunipara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770019","Taluk":"Tangarpali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770033","Taluk":"Biramitrapur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770014","Taluk":"Balisankara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770018","Taluk":"Bargaon","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770072","Taluk":"Bhasma","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770001","Taluk":"Sundargarh","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770048","Taluk":"Koira","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770014","Taluk":"Subdega","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770034","Taluk":"Biramitrapur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770013","Taluk":"Hemgir","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769007","Taluk":"Raurkela Industrial Township (ITS)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770002","Taluk":"Sundargarh","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770017","Taluk":"Rajagangapur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769043","Taluk":"Brahmani Tarang","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770039","Taluk":"Kuarmunda","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770023","Taluk":"Kuta","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770046","Taluk":"Nuagaon","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770051","Taluk":"Bonaigarh","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769042","Taluk":"Koida","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769043","Taluk":"Lathikata","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770040","Taluk":"Koira","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770044","Taluk":"Mahulpada","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770070","Taluk":"Kutra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770037","Taluk":"Biramitrapur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769003","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770021","Taluk":"Hemgir","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769015","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770039","Taluk":"Biramitrapur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770052","Taluk":"Koida","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770072","Taluk":"Hemgir","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770023","Taluk":"Kutra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770036","Taluk":"Nuagaon","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770048","Taluk":"Koida","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770013","Taluk":"Hemgri","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770020","Taluk":"Kinjirkela","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770051","Taluk":"Koida","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770037","Taluk":"Banki","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769042","Taluk":"Bisra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770032","Taluk":"Bondamunda","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769009","Taluk":"Rourkela","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770038","Taluk":"Gurundia","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770034","Taluk":"Brahmani Tarang","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770035","Taluk":"Kamarposh Balang","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770020","Taluk":"Tangarpur","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770001","Taluk":"Sundargarh Town","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769010","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769011","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769013","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769014","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769002","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769004","Taluk":"Raghunathapali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769005","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769006","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769007","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769008","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769009","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769016","Taluk":"Raurkela (M)","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770020","Taluk":"Tangarpai","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770033","Taluk":"Kutra","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770042","Taluk":"Koida","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"769012","Taluk":"Raghunathapali","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"770072","Taluk":"Lephripara","Districtname":"Sundergarh","statename":"ODISHA"},
    {"pincode":"140119","Taluk":"Ropar","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160003","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140118","Taluk":"Ropar","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"160004","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140125","Taluk":"Ropar","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140103","Taluk":"NA","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140111","Taluk":"Bela","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160036","Taluk":"NA","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140413","Taluk":"Ropar","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140119","Taluk":"Ropar","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140108","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160055","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140604","Taluk":"Dera Bassi","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140125","Taluk":"Ropar","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140110","Taluk":"Ropar","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140507","Taluk":"Derabassi","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140102","Taluk":"Ropar","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140117","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140126","Taluk":"Ropar","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140112","Taluk":"Ropar","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140413","Taluk":"Kharar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140111","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160003","Taluk":"NA","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160014","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140108","Taluk":"NA","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160003","Taluk":"NA","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140307","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140111","Taluk":"Ropar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140112","Taluk":"Ropar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140101","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140126","Taluk":"NA","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140201","Taluk":"Dera Bassi","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140133","Taluk":"Bhanupali","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140119","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140114","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140114","Taluk":"Bharatgarh","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160047","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140103","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140102","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140112","Taluk":"Chamkaur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160017","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160059","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160062","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160071","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140603","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140103","Taluk":"NA","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140301","Taluk":"Ropar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140307","Taluk":"Kharar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160014","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140506","Taluk":"Dera Bassi","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140506","Taluk":"Dera Bassi","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"160101","Taluk":"NA","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140133","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140301","Taluk":"Kharar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160104","Taluk":"Derabassi","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140306","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140501","Taluk":"Derabassi","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140133","Taluk":"Ropar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140124","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140306","Taluk":"NA","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160012","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140126","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140123","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140114","Taluk":"NA","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140113","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160018","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160002","Taluk":"NA","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160001","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140110","Taluk":"Sialba Majri","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"160002","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140901","Taluk":"Ropar","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140117","Taluk":"NA","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140307","Taluk":"NA","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140103","Taluk":"Kharar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140116","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140117","Taluk":"NA","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140102","Taluk":"NA","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140133","Taluk":"Ropar","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140117","Taluk":"Ropar","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140112","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140109","Taluk":"Khizrabad West","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140115","Taluk":"Kiratpur Sahib","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"160101","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140108","Taluk":"Ropar Ho","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140109","Taluk":"Ropar","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140901","Taluk":"Mullanpur","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140308","Taluk":"Ropar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140102","Taluk":"Lutheri","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140307","Taluk":"Ropar","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"160025","Taluk":"NA","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140125","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140115","Taluk":"Anandpur Sahib","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140308","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160102","Taluk":"NA","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140001","Taluk":"Rupnagar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140101","Taluk":"NA","Districtname":"Ropar","statename":"PUNJAB"},
    {"pincode":"140201","Taluk":"Dera Bassi","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140103","Taluk":"Kharar","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140113","Taluk":"NA","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160103","Taluk":"NA","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140301","Taluk":"NA","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"140901","Taluk":"Ropar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140111","Taluk":"NA","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160102","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140306","Taluk":"Ropar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"160019","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"140101","Taluk":"Kharar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140103","Taluk":"Sahauran","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"160011","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160015","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160020","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160022","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160023","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160030","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160036","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160009","Taluk":"Chandigarh","Districtname":"Chandigarh","statename":"CHANDIGARH"},
    {"pincode":"160022","Taluk":"NA","Districtname":"NULL","statename":"NULL"},
    {"pincode":"160014","Taluk":"Kharar","Districtname":"Rupnagar","statename":"PUNJAB"},
    {"pincode":"140603","Taluk":"Rajpura","Districtname":"Mohali","statename":"PUNJAB"},
    {"pincode":"141003","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141012","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141008","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141008","Taluk":"NA","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141013","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141007","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141001","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141007","Taluk":"NA","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141014","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141010","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141006","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141017","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141015","Taluk":"NA","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141016","Taluk":"NA","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141001","Taluk":"Bharat Nagar Chowk","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141002","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141015","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141001","Taluk":"NA","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141004","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141105","Taluk":"Raikot","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141104","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142034","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141116","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141414","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141401","Taluk":"Khanna","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141108","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141203","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141107","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142027","Taluk":"Ludhiana (West)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141119","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142021","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141412","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141412","Taluk":"Khanna","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141205","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141202","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142033","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141416","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142024","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141110","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142032","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141418","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141121","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141112","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141114","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141109","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142034","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141102","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142025","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141415","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141206","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141421","Taluk":"Khamanon","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"141120","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142024","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141114","Taluk":"Samrala","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141422","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141127","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141421","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141118","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142030","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142030","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141110","Taluk":"Ludhiana (West)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141417","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142035","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141113","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141204","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141127","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142022","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141109","Taluk":"Raikot","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141101","Taluk":"Ludhiana (West)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142022","Taluk":"Ludhiana (West)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142029","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141413","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141101","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141119","Taluk":"Payal","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141204","Taluk":"Ludhiana (West)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141421","Taluk":"NA","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141116","Taluk":"Ludhiana (West)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142036","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142036","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141419","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141206","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141417","Taluk":"Khanna","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142023","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141106","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141115","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141117","Taluk":"Payal","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142031","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141107","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141422","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141414","Taluk":"Khanna","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142026","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141415","Taluk":"Ludhiana  ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141122","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141117","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141126","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141107","Taluk":"Raikot","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141125","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141204","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141120","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142035","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141104","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141103","Taluk":"Khanna","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141118","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141201","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141127","Taluk":"Ludhiana East","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141203","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141115","Taluk":"Samrala","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141205","Taluk":"Raikot","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142027","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142023","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141103","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142028","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141123","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141105","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141123","Taluk":"Ludhiana","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141121","Taluk":"Samrala","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"142025","Taluk":"Jagraon","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"141126","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"140406","Taluk":"Sirhind","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147111","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147202","Taluk":"Samana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147201","Taluk":"Nabha","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140701","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147006","Taluk":"Nabha","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147203","Taluk":"Amloh (P)","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140802","Taluk":"Payal","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147105","Taluk":"Samana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140405","Taluk":"Rajpura","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147102","Taluk":"Samana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147001","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147006","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147101","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140601","Taluk":"NA","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147201","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"141411","Taluk":"Khamanoo","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140402","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140412","Taluk":"Bassi","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140412","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147101","Taluk":"Samana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147202","Taluk":"Nabha","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140602","Taluk":"Derabassi","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147103","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"141411","Taluk":"Khamanon","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147203","Taluk":"Amloh (P)","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140701","Taluk":"Fatehgarh Sahib","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140412","Taluk":"Bassi Pathana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140602","Taluk":"Fatehgarh Sahib","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147006","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147021","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"141801","Taluk":"Khamanon","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147104","Taluk":"Fatehgarh Sahib","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140406","Taluk":"Fatehgarh Sahib","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147003","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147007","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147104","Taluk":"Nabha","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147111","Taluk":"Samana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140802","Taluk":"Khamanon","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"141411","Taluk":"Khamanoo","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140407","Taluk":"Fatehgarh Sahib","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140417","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147102","Taluk":"NA","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140702","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140412","Taluk":"Bassi Pathana","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147004","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147102","Taluk":"Samaana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140401","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147202","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140601","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147102","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147005","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147105","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147203","Taluk":"Fateh Garh Sahib","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140701","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147301","Taluk":"Fatehgarhsahib","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140702","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140406","Taluk":"Sirhind","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140406","Taluk":"Bassi Pathana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147301","Taluk":"S.A.S.Nagar (Mohali)","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"141801","Taluk":"Khamanon","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147004","Taluk":"Fatehgarh Sahib","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147201","Taluk":"Dera Bassi","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140417","Taluk":"Bassi Pathana","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140412","Taluk":"Bassi","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147101","Taluk":"Fatehgarh Sahib","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"141411","Taluk":"Khamanoo","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"140601","Taluk":"Nabha","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147202","Taluk":"Bassi Pathana","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147201","Taluk":"Rajpura","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"140406","Taluk":"Fatehgarh Sahib","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147104","Taluk":"Amloh (P)","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"147301","Taluk":"Amloh (P)","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140405","Taluk":"Fatehgarh Sahib","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"140802","Taluk":"Khamanon","Districtname":"Fatehgarh Sahib","statename":"PUNJAB"},
    {"pincode":"147002","Taluk":"Patiala","Districtname":"Patiala","statename":"PUNJAB"},
    {"pincode":"148021","Taluk":"Malerkotla","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148026","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148022","Taluk":"Malerkotla","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148027","Taluk":"Moonak","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148105","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148106","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148002","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148024","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148018","Taluk":"Malerkotla","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148025","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148100","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148031","Taluk":"Moonak","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148028","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148002","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148106","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148033","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148033","Taluk":"Moonak","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148101","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148034","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148109","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148102","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148030","Taluk":"Moonak","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148017","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148026","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148001","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148019","Taluk":"Malerkotla","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148023","Taluk":"Malerkotla","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148104","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148034","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148103","Taluk":"NA","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148029","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148035","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148030","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148108","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148031","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148022","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148107","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148035","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148020","Taluk":"Malerkotla","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148031","Taluk":"Lehragaga","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148026","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148030","Taluk":"Chhajli","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148025","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148002","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148107","Taluk":"Ludhiana (East)","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"148034","Taluk":"Ladda","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148102","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148024","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148029","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148103","Taluk":"Barnala","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148020","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148018","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148019","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148031","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148106","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148034","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148002","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148017","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148020","Taluk":"Dhuri","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148106","Taluk":"Sunam","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148027","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148107","Taluk":"NA","Districtname":"Ludhiana","statename":"PUNJAB"},
    {"pincode":"148035","Taluk":"Sangrur","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"148100","Taluk":"NA","Districtname":"Barnala","statename":"PUNJAB"},
    {"pincode":"148108","Taluk":"NA","Districtname":"Sangrur","statename":"PUNJAB"},
    {"pincode":"143502","Taluk":"Kathunangal","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143101","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143301","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143102","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143116","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"Khem Karan","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143412","Taluk":"Harike","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143111","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143001","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143001","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143114","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"Patti","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143108","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143108","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143108","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143202","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143119","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143108","Taluk":"Attari","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143402","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143304","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143302","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143601","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143204","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143606","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143102","Taluk":"Ajnala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143601","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143117","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143303","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143411","Taluk":"Dhotian","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143416","Taluk":"Patti","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143305","Taluk":"Khalra","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143105","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143006","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143406","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143201","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143407","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143118","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143301","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143416","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143304","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143304","Taluk":"Patti","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143107","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143201","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143149","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143117","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143409","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143303","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143504","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143109","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143112","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143107","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143111","Taluk":"Khilchian","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143302","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143109","Taluk":"Chogawan","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"Khem Karn","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143107","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143303","Taluk":"Bhikhiwind","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143009","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143413","Taluk":"Bundala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143203","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143205","Taluk":"Sathiala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143022","Taluk":"Kot Mit Singh","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143414","Taluk":"S.a. Khan","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143606","Taluk":"Ajnala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143408","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143103","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143022","Taluk":"Kot Mit Sing","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143414","Taluk":"S.a.khan","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143606","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143105","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143107","Taluk":"D.khasa","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143305","Taluk":"Khalra","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143112","Taluk":"Rayya","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143502","Taluk":"Kathunanagal","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143109","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143116","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143408","Taluk":"Amritsar","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143001","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143411","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143204","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143022","Taluk":"Amritsar","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143410","Taluk":"Sarhali","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143601","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143603","Taluk":"Ajnala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143305","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143304","Taluk":"Kacha Pacca","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143406","Taluk":"Kang","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143504","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143115","Taluk":"Jandiala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143205","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143416","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143411","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143111","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143422","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143406","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143008","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143107","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143115","Taluk":"Jandiala Guru","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143009","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143407","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143501","Taluk":"Verka","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143113","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143006","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143201","Taluk":"Beas","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143415","Taluk":"Kairon","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143410","Taluk":"Amritsar","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143103","Taluk":"Chamiari","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143022","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143422","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143008","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143005","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143109","Taluk":"Ajnala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143412","Taluk":"Tarn Taran","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143101","Taluk":"Raja Sansi","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143118","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143002","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143116","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143601","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143101","Taluk":"Ajnala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143501","Taluk":"Khadoor Sahib","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143502","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143114","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143112","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143115","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143149","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143111","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143102","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143603","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143412","Taluk":"Patti","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143415","Taluk":"Patti","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143302","Taluk":"Sur Singh","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143101","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143413","Taluk":"Bundalal","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143204","Taluk":"D.b.j.singh.","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143402","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143304","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143402","Taluk":"Tarn Taran","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143415","Taluk":"Tarn Taran","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143109","Taluk":"Preet Nagar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143406","Taluk":"Kang","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143406","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143105","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143502","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143114","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143117","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143305","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143501","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143203","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143410","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143407","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143112","Taluk":"Khadoor Sahib","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143412","Taluk":"Amritsar","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143301","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143022","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143504","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143202","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143411","Taluk":"Amritsar","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143301","Taluk":"Chabhal","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143415","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143502","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143105","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"Khem Karan","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143149","Taluk":"Jandiala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143116","Taluk":"Tarsika","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143109","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143115","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143413","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143410","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143119","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143115","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143022","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143419","Taluk":"NA","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143114","Taluk":"Mehta Chowk","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143114","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143112","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143606","Taluk":"Chetanpura","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143409","Taluk":"Nausher Panvan","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143102","Taluk":"Ajnla","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143108","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143501","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143601","Taluk":"Majitha","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143117","Taluk":"Khadoor Sahib","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143113","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143409","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143408","Taluk":"Chola Sahib","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143402","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143008","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143411","Taluk":"Patti","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143402","Taluk":"Tarn Taran","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143412","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143113","Taluk":"Fatehpur Rajputan","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143114","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143409","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143113","Taluk":"Fatehpur Rajputan","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143022","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143412","Taluk":"Harike","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143112","Taluk":"Amritsar -I","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143408","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143411","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143415","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143409","Taluk":"Naushera Panvan","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143414","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143415","Taluk":"Kairon","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143205","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143415","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143203","Taluk":"Butala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143111","Taluk":"Khilchaian","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143302","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143006","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143115","Taluk":"Jandila Guru","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143149","Taluk":"Janidiala Resistant/s","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143401","Taluk":"NA","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143009","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143111","Taluk":"Baba Bakala","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143411","Taluk":"Khadoor Sahib","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143119","Taluk":"Amritsar- II","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143416","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143601","Taluk":"Amritsar","Districtname":"Amritsar","statename":"PUNJAB"},
    {"pincode":"143305","Taluk":"TARN-TARAN","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"143409","Taluk":"Amritsar","Districtname":"Tarn Taran","statename":"PUNJAB"},
    {"pincode":"151201","Taluk":"Goniana Mandi","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151506","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151507","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151502","Taluk":"Bdh","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151505","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151510","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151108","Taluk":"Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151503","Taluk":"Boha","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151104","Taluk":"Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151504","Taluk":"Bkh","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151501","Taluk":"Bareta","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151102","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151103","Taluk":"Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151510","Taluk":"Rampura Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151005","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151401","Taluk":"Sangat","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151301","Taluk":"T Sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151501","Taluk":"NA","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151004","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151001","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151302","Taluk":"T.sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151302","Taluk":"Talwandi Sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151206","Taluk":"Rampura Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151301","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151106","Taluk":"Rampura Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151508","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151105","Taluk":"Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151504","Taluk":"NA","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151201","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151101","Taluk":"Bhucho Manda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151101","Taluk":"Bhucho","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151101","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151503","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151502","Taluk":"Budhlada","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151508","Taluk":"Bhaini Bagha","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151509","Taluk":"Maur","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151302","Taluk":"NA","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151302","Taluk":"T Sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151002","Taluk":"Bti","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151005","Taluk":"NA","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151106","Taluk":"Bhai Rupa","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151111","Taluk":"Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151509","Taluk":"Maur Mandi","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151002","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151201","Taluk":"NA","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151206","Taluk":"Bhagta Bhai","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151302","Taluk":"T  Sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151101","Taluk":"Rampura Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151508","Taluk":"B.bagha","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151509","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151504","Taluk":"Mansa","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151509","Taluk":"Maur","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151005","Taluk":"Talwandi Sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151003","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151104","Taluk":"Rampura Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151401","Taluk":"Bathinda","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151509","Taluk":"Talwandi Sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151301","Taluk":"Talwandi Sabo","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151103","Taluk":"Rampura Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151108","Taluk":"Rampura Phul","Districtname":"Bathinda","statename":"PUNJAB"},
    {"pincode":"151507","Taluk":"Sardulgarh","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"151508","Taluk":"B. Bagha","Districtname":"Mansa","statename":"PUNJAB"},
    {"pincode":"152114","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142053","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152026","Taluk":"Muktsar","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142038","Taluk":"Bhagha Purana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151210","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"151213","Taluk":"Faridkot","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"152025","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"151207","Taluk":"Faridkot","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"152113","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142037","Taluk":"Nihal Singh Wala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142037","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152112","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142011","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151205","Taluk":"NA","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"152032","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"151208","Taluk":"Jaitu","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"142038","Taluk":"Baghapurana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152025","Taluk":"NA","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142039","Taluk":"Nihal Singhwala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142039","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152107","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152115","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152101","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152112","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152032","Taluk":"Muktsar","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152031","Taluk":"Muktsar","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"151212","Taluk":"Faridkot","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"142039","Taluk":"Nihal Singh Wala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142037","Taluk":"Nihal Singhwala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151202","Taluk":"Jaitu","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"151211","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142002","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142048","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142040","Taluk":"Nihal Singh Wala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152031","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152032","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152101","Taluk":"Malout","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142054","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151204","Taluk":"Jaitu","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"142003","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152114","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"151204","Taluk":"Faridkot","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"151209","Taluk":"Kotkapura","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"142055","Taluk":"Nihal Singh Wala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142046","Taluk":"Nihal Singh Wala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151205","Taluk":"Jaitu","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"152107","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"151203","Taluk":"Faridkot","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"142057","Taluk":"Baghapurana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142056","Taluk":"Baghapurana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142056","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142001","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142049","Taluk":"Bhagha Purana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152115","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152025","Taluk":"Muktsar","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"152025","Taluk":"Faridkot","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"142046","Taluk":"Nihal Singhwala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142045","Taluk":"Baghapurana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151202","Taluk":"Giddarbaha","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142039","Taluk":"NA","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151209","Taluk":"Faridkot","Districtname":"Faridkot","statename":"PUNJAB"},
    {"pincode":"142055","Taluk":"Nihal Singhwala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152114","Taluk":"Lambi","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142040","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142045","Taluk":"Nihal Singh Wala","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142049","Taluk":"Baghapurana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"151208","Taluk":"Bhagha Purana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142057","Taluk":"Baghaurana","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152116","Taluk":"Abohar","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152022","Taluk":"Jalalabad","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152116","Taluk":"NA","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142042","Taluk":"Dharamkot","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152024","Taluk":"Jalalabad","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152021","Taluk":"Firozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152124","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152028","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152121","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152117","Taluk":"Abohar","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152128","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152002","Taluk":"Firozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152004","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152122","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152123","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142044","Taluk":"Zira","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142052","Taluk":"Firozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152132","Taluk":"Abohar","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152005","Taluk":"Zira","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142041","Taluk":"Moga","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152003","Taluk":"Firozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152001","Taluk":"Firozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152033","Taluk":"Jalalabad","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152024","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152002","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152033","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142058","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152023","Taluk":"Firozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142047","Taluk":"Zira","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152128","Taluk":"Abohar","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142043","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142050","Taluk":"Zira","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142042","Taluk":"Moga","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142052","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152003","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152005","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152001","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142060","Taluk":"Ferozpeur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142050","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152028","Taluk":"Zira","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142060","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142042","Taluk":"Dharmakot","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152124","Taluk":"NA","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152023","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142042","Taluk":"Dharmkot","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"142050","Taluk":"Feroeepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152005","Taluk":"Firozpur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152021","Taluk":"Zira","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152028","Taluk":"Moga","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142042","Taluk":"Dharakot","Districtname":"Moga","statename":"PUNJAB"},
    {"pincode":"152004","Taluk":"Firozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152116","Taluk":"Aboahr","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152022","Taluk":"Muktsar","Districtname":"Muktsar","statename":"PUNJAB"},
    {"pincode":"142052","Taluk":"Ferozpeur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152122","Taluk":"Jalalabad","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152005","Taluk":"Ferozepur","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"142043","Taluk":"Zira","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152116","Taluk":"Fazilka","Districtname":"Firozpur","statename":"PUNJAB"},
    {"pincode":"152118","Taluk":"Abohar","Districtname":"Fazilka","statename":"PUNJAB"},
    {"pincode":"145001","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143511","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143507","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143517","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143527","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143531","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143530","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143529","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143520","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143534","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143515","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145027","Taluk":"Pathankot","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"143512","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143527","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143505","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143506","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143532","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145023","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143511","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143604","Taluk":"Dera Baba Nanak","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145022","Taluk":"Dhar Kalan","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143532","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143525","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145025","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143517","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145022","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143513","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143514","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143520","Taluk":"Guirdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143533","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143512","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143528","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143530","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143520","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145026","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143518","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143516","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143516","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143519","Taluk":"Sub Tehsil Dhariwal","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145001","Taluk":"Dhar Kalan","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143605","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143605","Taluk":"Dera Baba Nanak","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143531","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143518","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143531","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143532","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143526","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145022","Taluk":"Dhar Kalan","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"143519","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143602","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143512","Taluk":"Dera Baba Nanak","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145023","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143533","Taluk":"NA","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"145101","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143521","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145101","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143525","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143602","Taluk":"Dera Baba Nanak","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143604","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143526","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145027","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143512","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143534","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143602","Taluk":"Batala","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143604","Taluk":"Gurdaspur","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145029","Taluk":"Dhar Kalan","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145024","Taluk":"Pathankot","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"145025","Taluk":"Pathankot","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"143515","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145101","Taluk":"Pathankot","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"145026","Taluk":"NA","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"143516","Taluk":"Dera Baba Nanak","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143511","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"143506","Taluk":"NA","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145029","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"145029","Taluk":"Pathankot","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"145023","Taluk":"Pathankot","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"143534","Taluk":"Pathankot","Districtname":"Pathankot","statename":"PUNJAB"},
    {"pincode":"145024","Taluk":"Pathankot","Districtname":"Gurdaspur","statename":"PUNJAB"},
    {"pincode":"144214","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146024","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144521","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"146112","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144520","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144404","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146111","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144202","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144207","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144224","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144533","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144526","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"146102","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144406","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144528","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144223","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144105","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144208","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144522","Taluk":"Nawanshahar","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146001","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144206","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146105","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144204","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144202","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146104","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146023","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144522","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"146101","Taluk":"Hoshiar  Pur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144208","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144306","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144527","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146021","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144524","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"146115","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144519","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144305","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146113","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144221","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146103","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144224","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144306","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144213","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144529","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144522","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"146111","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144523","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144210","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146101","Taluk":"Garhshankar","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144527","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144212","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146110","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144532","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144525","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144524","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144306","Taluk":"Mukerian","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144207","Taluk":"Dasua","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144209","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144529","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146022","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144205","Taluk":"Dasuya","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144222","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144214","Taluk":"Mukerian","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144224","Taluk":"Mukerian","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144528","Taluk":"Hoshiar Pur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144530","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146022","Taluk":"Hoshiar Pur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144530","Taluk":"Hoshiar Pur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146109","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146106","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144207","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144527","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144527","Taluk":"Garhshankar","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146116","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146107","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144532","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"146001","Taluk":"Gurdaspur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144211","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144523","Taluk":"Garhshankar","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146102","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146115","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146001","Taluk":"Balachaur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146114","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146108","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144305","Taluk":"Dasua","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144531","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144519","Taluk":"Garhshankar","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146104","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146105","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146102","Taluk":"Garhshankar","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146108","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144525","Taluk":"Nawanshahar","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146101","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144211","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144521","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144528","Taluk":"NA","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144531","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144211","Taluk":"Mukerian","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144221","Taluk":"Mukerian","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"146101","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144532","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144212","Taluk":"Dasua","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144216","Taluk":"Hoshiarpur","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144224","Taluk":"Balachaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"146116","Taluk":"Dasua","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144204","Taluk":"Dasuya","Districtname":"Hoshiarpur","statename":"PUNJAB"},
    {"pincode":"144103","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144102","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144106","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144301","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144514","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144513","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144416","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144002","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144418","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144417","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144505","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144517","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144508","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144012","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144505","Taluk":"Nawanshahr","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144021","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144002","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144008","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144001","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144518","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144302","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144504","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144201","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144516","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144422","Taluk":"Nawanshahr","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144003","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144503","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144419","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144201","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144506","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144512","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144201","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144101","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144409","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144421","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144101","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144006","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144025","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144507","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144422","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144504","Taluk":"Nawanshahr","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144029","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144028","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144009","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144023","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144515","Taluk":"Nawanshahr","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144104","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144104","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144419","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144502","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144010","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144025","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144102","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144302","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144511","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144511","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144510","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144514","Taluk":"Nawanshahr","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144004","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144410","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144025","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144021","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144515","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144005","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144303","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144020","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144501","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144303","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144030","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144026","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144026","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144509","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144003","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144024","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144002","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144502","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144106","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144024","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144510","Taluk":"Banga","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144007","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144026","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144415","Taluk":"Nawanshahar","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144004","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144511","Taluk":"Phillaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144029","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144021","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144416","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144005","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144022","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144301","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144508","Taluk":"Nawanshahr","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144012","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144417","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144011","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144009","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144012","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144024","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144029","Taluk":"Phillaur","Districtname":"Nawanshahr","statename":"PUNJAB"},
    {"pincode":"144027","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144014","Taluk":"Jalandhar - I","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144003","Taluk":"Jalandhar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144041","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144623","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144603","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144621","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144622","Taluk":"Bholath","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144601","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144402","Taluk":"Phagwara","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144621","Taluk":"Bhulath","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144703","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144702","Taluk":"Shahkot","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144401","Taluk":"Phagwara","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144629","Taluk":"Shahkot","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144040","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144403","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144044","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144621","Taluk":"NA","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144407","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144622","Taluk":"Bhulath","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144405","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144039","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144804","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144033","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144620","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144034","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144620","Taluk":"NA","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144036","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144040","Taluk":"Shahkot","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144632","Taluk":"Phagwara","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144043","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144630","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144628","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144622","Taluk":"Jalandhar -II","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144701","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144042","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144033","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144624","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144033","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144411","Taluk":"Phagwara","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144039","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144805","Taluk":"NA","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144040","Taluk":"Nakoder","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144032","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144620","Taluk":"Sultanpur Lodhi","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144626","Taluk":"Sultanpur","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144701","Taluk":"Shahkot","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144633","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144311","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144804","Taluk":"Dhilwan","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144803","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144606","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144043","Taluk":"Nakodar","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144629","Taluk":"Sultanpur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144630","Taluk":"Kapurthala","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144819","Taluk":"Bhulath","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144802","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144623","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144033","Taluk":"Phagwara Ho","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144626","Taluk":"Sultanpur Lodhi","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144805","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144801","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144035","Taluk":"Phagwara Ho","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144701","Taluk":"Kapurthala","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144603","Taluk":"Jalandhar -II","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144806","Taluk":"Kapurthala","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144702","Taluk":"Kapurthala","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144803","Taluk":"Jalandhar -II","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144625","Taluk":"Shahkot","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144040","Taluk":"NA","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144701","Taluk":"Nakoder","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144402","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144629","Taluk":"Sultanpur","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144041","Taluk":"Nakoder","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144632","Taluk":"Nawanshahr","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144035","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144804","Taluk":"Shahkot","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144631","Taluk":"Bhulath","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144702","Taluk":"Sultanpur Lodhi","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144624","Taluk":"Bholath","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144624","Taluk":"Bhulath","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144037","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144703","Taluk":"Bholath","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144043","Taluk":"Kapurthala","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144623","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144408","Taluk":"Phagwara","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144037","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144806","Taluk":"Jalandhar -II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144629","Taluk":"NA","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144036","Taluk":"Phillaur","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144603","Taluk":"Jalandhar-II","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144801","Taluk":"NA","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144602","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144411","Taluk":"Shahkot","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144819","Taluk":"NA","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144626","Taluk":"NA","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144403","Taluk":"Phagwara","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144630","Taluk":"NA","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144703","Taluk":"Shahkot","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144031","Taluk":"Phagwara","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144628","Taluk":"Sultanpur Lodhi","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144032","Taluk":"Phagwara","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144703","Taluk":"NA","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144625","Taluk":"Kapurthala","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"144044","Taluk":"Kapurthala","Districtname":"Jalandhar","statename":"PUNJAB"},
    {"pincode":"144606","Taluk":"Sultanpur Lodhi","Districtname":"Kapurthala","statename":"PUNJAB"},
    {"pincode":"305002","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305801","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305003","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305005","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305001","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305813","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305819","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305206","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305813","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305811","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305004","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305025","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305814","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305022","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305023","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305205","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305812","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305817","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305811","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305802","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305025","Taluk":"Nasira Bad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305007","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305012","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305025","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305021","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305819","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305815","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305802","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305022","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305024","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305009","Taluk":"Ajmer","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305025","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305816","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305814","Taluk":"Kishangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305814","Taluk":"Kiashan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305801","Taluk":"Kishsangarh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305801","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305816","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305022","Taluk":"Kishan Garh","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305404","Taluk":"Kekri","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305207","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305405","Taluk":"Kekri","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305922","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305901","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305203","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305601","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305926","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305925","Taluk":"Bewar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305624","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305415","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305401","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305407","Taluk":"Kekri","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305621","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305628","Taluk":"Bhinai","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305412","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305630","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305403","Taluk":"Sarwar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305622","Taluk":"Bhinai","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305624","Taluk":"Bijainagar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305922","Taluk":"Beawar","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"305408","Taluk":"Kekri","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305622","Taluk":"Bhina","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305204","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305629","Taluk":"Bhinai","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305621","Taluk":"Bhinai","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305925","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305415","Taluk":"Kekri","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305629","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305623","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305927","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305202","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305201","Taluk":"Pisangan","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305625","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305406","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305406","Taluk":"Kekri","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305630","Taluk":"Masuda","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305207","Taluk":"Pisangan","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305402","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305627","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305408","Taluk":"Nasirabad","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305405","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305204","Taluk":"NA","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305925","Taluk":"Beawar","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"305201","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305923","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"305924","Taluk":"Beawar","Districtname":"Ajmer","statename":"RAJASTHAN"},
    {"pincode":"311402","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311022","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311204","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311603","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311026","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311025","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311605","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311804","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311201","Taluk":"NA","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311801","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311404","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311011","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311602","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311023","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311301","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311802","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311001","Taluk":"NA","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311401","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311021","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311302","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311202","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311203","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311604","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311408","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311024","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311403","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311001","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311030","Taluk":"NA","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311803","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311601","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311201","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311805","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311407","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311801","Taluk":"NA","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311202","Taluk":"NA","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311806","Taluk":"Bhilwara","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311803","Taluk":"NA","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311604","Taluk":"Mandalgarh","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"311026","Taluk":"NA","Districtname":"Bhilwara","statename":"RAJASTHAN"},
    {"pincode":"312615","Taluk":"Arnod","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312605","Taluk":"Pratapgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312203","Taluk":"Rashmi","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312622","Taluk":"NA","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312605","Taluk":"Pratbgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312001","Taluk":"Chittorgah","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312027","Taluk":"Bhadesar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312205","Taluk":"Bhupalsagar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312404","Taluk":"Bari Sadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312022","Taluk":"Chittorgah","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312604","Taluk":"Chhoti Sadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312619","Taluk":"Arnod","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312605","Taluk":"Pratabgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312605","Taluk":"Partabgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312202","Taluk":"Kapasan","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323303","Taluk":"Rawatbhata","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312022","Taluk":"Bassi","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312402","Taluk":"Doongla","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312620","Taluk":"Mangrol","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312612","Taluk":"Shambhupura","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312602","Taluk":"Bhadesar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312204","Taluk":"NA","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312601","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323307","Taluk":"Rawatbhata","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312401","Taluk":"Bari Sadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312024","Taluk":"Bhadesar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312023","Taluk":"Begun","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312614","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312207","Taluk":"Kapasan","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312001","Taluk":"Bhadesar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312401","Taluk":"Barisadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312616","Taluk":"Dhamotar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312616","Taluk":"Arnod","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312623","Taluk":"Partapgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312403","Taluk":"Barisadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312021","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312207","Taluk":"Rasmi","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312022","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312901","Taluk":"Gangrar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323307","Taluk":"Rawatbhatta","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312206","Taluk":"Rashmi","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312603","Taluk":"Bari Sadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312620","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323304","Taluk":"Rawatbhatta","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312204","Taluk":"Bhupalsagar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312623","Taluk":"Pratapgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312022","Taluk":"Begun","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312404","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323304","Taluk":"Rawatbhata","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312604","Taluk":"Chhotisadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312613","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312001","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312205","Taluk":"Bhopalsagar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312001","Taluk":"Cittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312619","Taluk":"Partabgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312404","Taluk":"Doongala","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312201","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312604","Taluk":"Chhoti Sadri","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312623","Taluk":"Partabgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312603","Taluk":"Bari Sadri","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323304","Taluk":"Bhensrorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312205","Taluk":"Akola","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312605","Taluk":"Annuppura","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312612","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312403","Taluk":"Bari Sadrai","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312617","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312606","Taluk":"Nimbahera","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312204","Taluk":"Bhuopalsagar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312604","Taluk":"Chhoti Sadrai","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312403","Taluk":"Bari Sadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312403","Taluk":"Barisadri","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312024","Taluk":"Bhdesar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323306","Taluk":"Rawatbhata","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312024","Taluk":"Doongala","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312203","Taluk":"Rasmi","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312613","Taluk":"NA","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312206","Taluk":"Gangrar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312603","Taluk":"Barisadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312022","Taluk":"Chitttorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312605","Taluk":"Arnod","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312404","Taluk":"Barisadari","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312604","Taluk":"Chhotisadri","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312603","Taluk":"Barisadri","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323306","Taluk":"Rawatbhatta","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323305","Taluk":"Rawatbhata","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312024","Taluk":"Doongla","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312613","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312025","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312612","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312616","Taluk":"Partabgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"312207","Taluk":"Chittorgarh","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"323306","Taluk":"Rawabhatta","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"327034","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327022","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327032","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314024","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327605","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314021","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327021","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327023","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314027","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314030","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327031","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314801","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327602","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314037","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314025","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314036","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314035","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327025","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327001","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327026","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314403","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327801","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314031","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327601","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314804","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327603","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314406","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314023","Taluk":"Aspur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314402","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314011","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314401","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314038","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327604","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314029","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314404","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314022","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314034","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314801","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327606","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314001","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314011","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327022","Taluk":"NA","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314038","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327027","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314027","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327027","Taluk":"NA","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314036","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314406","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314032","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314001","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314035","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314026","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314804","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314034","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"314028","Taluk":"Dungarpur","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"327001","Taluk":"NA","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327034","Taluk":"NA","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327024","Taluk":"Banswara","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327606","Taluk":"NA","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"327025","Taluk":"NA","Districtname":"Banswara","statename":"RAJASTHAN"},
    {"pincode":"314022","Taluk":"NA","Districtname":"Dungarpur","statename":"RAJASTHAN"},
    {"pincode":"325001","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326520","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325217","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326022","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325221","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326035","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"326033","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"326038","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"326518","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325204","Taluk":"Digod","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325218","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"325209","Taluk":"Kota","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"325202","Taluk":"Kota","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326037","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"326502","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325602","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"324002","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326039","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325215","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326021","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325223","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326512","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325214","Taluk":"Baran","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325222","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326023","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325206","Taluk":"Kota","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"325216","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"325209","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326001","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325009","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325004","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325208","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325205","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"325204","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326036","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325207","Taluk":"Jhalawar","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325220","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326515","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325203","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325003","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326501","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"325202","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326513","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"324001","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325601","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325220","Taluk":"Kota","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"324007","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325221","Taluk":"Kota","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"326034","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"324009","Taluk":"NA","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326514","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"326519","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325201","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325219","Taluk":"Baran","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"324010","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326516","Taluk":"Jhalawar","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"324008","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326529","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"324003","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326501","Taluk":"Jhalawar","Districtname":"Chittorgarh","statename":"RAJASTHAN"},
    {"pincode":"324006","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326517","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325207","Taluk":"Jhalawar","Districtname":"Baran","statename":"RAJASTHAN"},
    {"pincode":"324005","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"325202","Taluk":"Baran","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"326517","Taluk":"Kota","Districtname":"Jhalawar","statename":"RAJASTHAN"},
    {"pincode":"326530","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"324004","Taluk":"Kota","Districtname":"Kota","statename":"RAJASTHAN"},
    {"pincode":"323024","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323603","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323803","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323001","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304023","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304502","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323021","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304804","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323022","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323614","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323616","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304503","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304802","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323301","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304001","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304501","Taluk":"NA","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323613","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323801","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304801","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304025","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304024","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304022","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304505","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323802","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304021","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323025","Taluk":"NA","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304507","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304504","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323602","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304803","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304501","Taluk":"Tonk","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323023","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323025","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304505","Taluk":"NA","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323302","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"323601","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304025","Taluk":"Newai","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"323615","Taluk":"Bundi","Districtname":"Bundi","statename":"RAJASTHAN"},
    {"pincode":"304504","Taluk":"NA","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"304502","Taluk":"NA","Districtname":"Tonk","statename":"RAJASTHAN"},
    {"pincode":"313332","Taluk":"Amet","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313611","Taluk":"Dhariawad","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313804","Taluk":"Kherwara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313027","Taluk":"Salumber","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313702","Taluk":"Salumber","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313703","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313604","Taluk":"Bhinder","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313334","Taluk":"Rajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"305921","Taluk":"Rajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313323","Taluk":"Nathdwara","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313031","Taluk":"Girva","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313602","Taluk":"Bhinder","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313701","Taluk":"Jhadol","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313038","Taluk":"Salumber","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313331","Taluk":"Deogarh","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313325","Taluk":"Kumbhalgarh","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313601","Taluk":"Vallabhnagar","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313704","Taluk":"Gogunda","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313026","Taluk":"Salumber","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313603","Taluk":"Bhinder","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313905","Taluk":"Sarara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313205","Taluk":"Mavli","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313011","Taluk":"Badgaon","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313803","Taluk":"Kherwara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313324","Taluk":"Rajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313705","Taluk":"Gogunda","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313341","Taluk":"Bhim","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313301","Taluk":"Nathdwara","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"307025","Taluk":"Kotra","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313322","Taluk":"Khamnore","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313001","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313706","Taluk":"Salumber","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313802","Taluk":"Kherwara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313706","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313329","Taluk":"Railmagra","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313902","Taluk":"Sarara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313333","Taluk":"Amet","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313801","Taluk":"Kherwara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313341","Taluk":"Rajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313011","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313906","Taluk":"Jhadol","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313708","Taluk":"Gogunda","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313321","Taluk":"Nathdwara","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313024","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313204","Taluk":"Mavli","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313342","Taluk":"Rajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313324","Taluk":"Rajsmand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313203","Taluk":"Mavli","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313603","Taluk":"Vallabhnagar","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313003","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313605","Taluk":"Dhariawad","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313201","Taluk":"Mavli","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313202","Taluk":"Nathdwara","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313327","Taluk":"Rajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313038","Taluk":"NA","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313903","Taluk":"Sarara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313015","Taluk":"NA","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313211","Taluk":"Railmagra","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313332","Taluk":"Charbhuja","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313333","Taluk":"Rajasamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313904","Taluk":"Sarara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313022","Taluk":"Vallabhnagar","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313022","Taluk":"Bhinder","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313331","Taluk":"Rajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313901","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313206","Taluk":"Mavli","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313001","Taluk":"NA","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313330","Taluk":"Lawa Sardargarh","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313207","Taluk":"Gilund","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313328","Taluk":"Railmagra","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313804","Taluk":"NA","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313702","Taluk":"Jhadol","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313015","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313604","Taluk":"Lasadia","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313602","Taluk":"Vallabhangar","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313327","Taluk":"Koaria","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313903","Taluk":"NA","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313323","Taluk":"Kothaira","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313328","Taluk":"Kuraj","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313324","Taluk":"Kankroli","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313031","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313801","Taluk":"Sarara","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313324","Taluk":"Raajsamand","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313206","Taluk":"Mavli","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313704","Taluk":"Sayra","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313342","Taluk":"Kankroli","Districtname":"Rajsamand","statename":"RAJASTHAN"},
    {"pincode":"313002","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"313004","Taluk":"Girwa","Districtname":"Udaipur","statename":"RAJASTHAN"},
    {"pincode":"301401","Taluk":"Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301035","Taluk":"Rajgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301027","Taluk":"Tana Gazi","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301024","Taluk":"Thanagazi","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301401","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301001","Taluk":"Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301703","Taluk":"Bahror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301712","Taluk":"K.g.basss","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301412","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301026","Taluk":"Ramgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301713","Taluk":"Bahror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"321605","Taluk":"Laxmangarh Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301411","Taluk":"Tizara","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301405","Taluk":"Kishan Garh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301028","Taluk":"Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301030","Taluk":"Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301414","Taluk":"Rajgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301404","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301701","Taluk":"Bahror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301714","Taluk":"Bahror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301406","Taluk":"Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301410","Taluk":"Rajgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301408","Taluk":"Rajgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301407","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301402","Taluk":"Bansur","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301413","Taluk":"Rajgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"321607","Taluk":"Laxman Garh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301021","Taluk":"Laxaman Garh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301020","Taluk":"Bahror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301709","Taluk":"Bahror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"321633","Taluk":"Lachhmangarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301025","Taluk":"Ramgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301702","Taluk":"Kishan Garh Bass","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"321633","Taluk":"Laxmangarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301409","Taluk":"Rajgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301022","Taluk":"Thanagazi","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301019","Taluk":"Tijara","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301707","Taluk":"Tizara","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301427","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301415","Taluk":"Rajgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301411","Taluk":"Tijara","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301023","Taluk":"Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301706","Taluk":"Neemrana","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301704","Taluk":"Neemrana","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"321606","Taluk":"Laxman Garh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301410","Taluk":"Thanagazi","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301604","Taluk":"Laxmzn Garh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301405","Taluk":"Tijara","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301705","Taluk":"Neemrana","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301604","Taluk":"Ramgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301403","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301713","Taluk":"Behror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301018","Taluk":"Tijara","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"321606","Taluk":"Kathumar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301020","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301708","Taluk":"Mandawar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301030","Taluk":"Ramgarh","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301707","Taluk":"Tijara","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301002","Taluk":"Alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301708","Taluk":"Behror","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301416","Taluk":"Bansur","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"301023","Taluk":"alwar","Districtname":"Alwar","statename":"RAJASTHAN"},
    {"pincode":"321202","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321001","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321025","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321303","Taluk":"Kumher","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321022","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321614","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321024","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321615","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321407","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321022","Taluk":"Nagar","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321602","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321302","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321201","Taluk":"Kumher","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321028","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321203","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321026","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321023","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321409","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321301","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321303","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321021","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321406","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321614","Taluk":"Nadbai","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321602","Taluk":"Nadbai","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321001","Taluk":"NA","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321205","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321204","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321406","Taluk":"NA","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321642","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321201","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321022","Taluk":"Kaman","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321205","Taluk":"Nagar","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321409","Taluk":"Weir","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321601","Taluk":"Bharatpur","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321206","Taluk":"Deeg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321203","Taluk":"Nagar","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321204","Taluk":"Pahari","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321024","Taluk":"Nagar","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321406","Taluk":"Weir","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321411","Taluk":"Bayana Mdg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"328041","Taluk":"Bari","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328021","Taluk":"Bari","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328025","Taluk":"Rajakhera","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328031","Taluk":"Baresei","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328022","Taluk":"Baresei","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328024","Taluk":"Dholpur","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"321408","Taluk":"Weir","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321405","Taluk":"Bayana","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321403","Taluk":"Bayana Mdg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"328031","Taluk":"Bari","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328001","Taluk":"Rajakhera","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"321402","Taluk":"Bayana Mdg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"328029","Taluk":"Rajakhera","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328023","Taluk":"Saipau","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328030","Taluk":"Dholpur","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328028","Taluk":"Dholpur","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328001","Taluk":"Dholpur","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328022","Taluk":"Baseri","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"321401","Taluk":"Bayana","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321405","Taluk":"Bayana Mdg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321401","Taluk":"Bayana Mdg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"328026","Taluk":"Baresei","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"328027","Taluk":"Saipau","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"321410","Taluk":"Bayana Mdg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321404","Taluk":"Bayana Mdg","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321411","Taluk":"Bayana","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321404","Taluk":"Roopbas","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321402","Taluk":"Bayana","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"321410","Taluk":"Bayana","Districtname":"Bharatpur","statename":"RAJASTHAN"},
    {"pincode":"328026","Taluk":"Baresri","Districtname":"Dholpur","statename":"RAJASTHAN"},
    {"pincode":"302029","Taluk":"Sanganer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302006","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302013","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302039","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302018","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302002","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302028","Taluk":"Amer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302004","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302001","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302015","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302016","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302026","Taluk":"NA","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302026","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302022","Taluk":"Sanganer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302012","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302027","Taluk":"Amer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302012","Taluk":"Kalwad","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302003","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302021","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302005","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302017","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302026","Taluk":"Sanganer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302036","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302031","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302031","Taluk":"Amber","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302037","Taluk":"Sanganer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302020","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302040","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302013","Taluk":"Amber","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302034","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302033","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302033","Taluk":"Sanganer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302022","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302019","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"302012","Taluk":"NA","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303329","Taluk":"NA","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303326","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303502","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303908","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303002","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303007","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303338","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303901","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303315","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303801","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303303","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303804","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303601","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303120","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303510","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303313","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303712","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303704","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303302","Taluk":"Dausa","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303323","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303001","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303119","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303604","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303602","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"322240","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303903","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303103","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303325","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303305","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303603","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303503","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303805","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"321608","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303105","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"321609","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303109","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303304","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303806","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303305","Taluk":"Jaipur","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303507","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"321613","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303106","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303706","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303301","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303327","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303102","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303501","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303328","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303107","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303110","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303003","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303006","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303004","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303701","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303001","Taluk":"NA","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303807","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303803","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303504","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303104","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303012","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303348","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303005","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303509","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303108","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303505","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303904","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303702","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303905","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303008","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303009","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303511","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"321612","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303506","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303508","Taluk":"Dausa","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303302","Taluk":"LALSOT","Districtname":"Dausa","statename":"RAJASTHAN"},
    {"pincode":"303328","Taluk":"NA","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303503","Taluk":"Dausa","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303604","Taluk":"NA","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303338","Taluk":"NA","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303507","Taluk":"Dausa","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303121","Taluk":"Amer","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303509","Taluk":"Dausa","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"303122","Taluk":"Jaipur","Districtname":"Jaipur","statename":"RAJASTHAN"},
    {"pincode":"322701","Taluk":"Chauth Ka Barwara","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322001","Taluk":"Sawaimadhopur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322201","Taluk":"Gangapur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"321611","Taluk":"Todabhim","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322027","Taluk":"Sawaimadhopur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322025","Taluk":"Khandar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322021","Taluk":"NA","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322001","Taluk":"Khandar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322218","Taluk":"Sapotara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322212","Taluk":"Bamanwas","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322703","Taluk":"Chauth Ka Barwara","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322251","Taluk":"Mandrail","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322218","Taluk":"NA","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322241","Taluk":"Karauli","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322243","Taluk":"Karauli","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322034","Taluk":"Sawaimadhopur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322216","Taluk":"Nadoti","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322702","Taluk":"Chauth Ka Barwara","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322204","Taluk":"Nadoti","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322211","Taluk":"Bamanwas","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322238","Taluk":"Todabhim","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322023","Taluk":"Bonli","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322205","Taluk":"Gangapur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322249","Taluk":"Sapotara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322214","Taluk":"Bamanwas","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322202","Taluk":"Sapotara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322230","Taluk":"Hindaun","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322215","Taluk":"Nadoti","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322255","Taluk":"Karauli","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322203","Taluk":"Sapotara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322219","Taluk":"Gangapur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322028","Taluk":"Malrna Doongar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322029","Taluk":"Sawaimadhopur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322024","Taluk":"Malarna Dungar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"321610","Taluk":"Todabhim","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322249","Taluk":"Mandrail","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322214","Taluk":"NA","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322255","Taluk":"Sapotara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322242","Taluk":"Karauli","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322033","Taluk":"Khandar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322230","Taluk":"Todabhim","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322252","Taluk":"Hindaun","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322033","Taluk":"Malarna Dungar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322213","Taluk":"Nadoti","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322220","Taluk":"Hindaun","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322202","Taluk":"Gangapur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322236","Taluk":"Hindaun","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322212","Taluk":"Nadoti","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322026","Taluk":"Sawaimadhopur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322255","Taluk":"Sapotrara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322215","Taluk":"Bamanwas","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322030","Taluk":"Bonli","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322254","Taluk":"Hindaun","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322252","Taluk":"Karauli","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322213","Taluk":"Todabhim","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322024","Taluk":"Bonli","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322027","Taluk":"NA","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322033","Taluk":"Sapotara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322234","Taluk":"Hindaun","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322242","Taluk":"Mandrail","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322249","Taluk":"Karauli","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322701","Taluk":"Sawaimadhopur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322243","Taluk":"Sapotara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322220","Taluk":"Nadoti","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322254","Taluk":"Todabhim","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322201","Taluk":"NA","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322201","Taluk":"Bamanwas","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322704","Taluk":"Chauth Ka Barwara","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322243","Taluk":"NA","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322220","Taluk":"Todabhim","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322030","Taluk":"Malarna Doongar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322028","Taluk":"Malarna Doongar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322204","Taluk":"Bamanwas","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322028","Taluk":"Bamanwas","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322203","Taluk":"Gangapur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322219","Taluk":"Karauli","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322219","Taluk":"Gangpur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322211","Taluk":"NA","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322243","Taluk":"Karaui","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322026","Taluk":"Phalodi Quarry","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322220","Taluk":"Gangapur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322202","Taluk":"Malarna Doongar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322021","Taluk":"Sawaimadhopur","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322704","Taluk":"Chuth Ka Barwara","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322030","Taluk":"Malarna Dungar","Districtname":"Sawai Madhopur","statename":"RAJASTHAN"},
    {"pincode":"322243","Taluk":"Saptara","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"322236","Taluk":"Hinduan","Districtname":"Karauli","statename":"RAJASTHAN"},
    {"pincode":"344502","Taluk":"Ramsar","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344011","Taluk":"Bishala","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344033","Taluk":"Sindhari","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344702","Taluk":"Chohtan","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344021","Taluk":"Samdari","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344034","Taluk":"Baitu","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344012","Taluk":"Ranigaon","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344704","Taluk":"Dhorimana","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344031","Taluk":"Gudamalani","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344701","Taluk":"Gunga","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344706","Taluk":"Sedwa","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344022","Taluk":"Balotra","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344026","Taluk":"Kalyanpur","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344001","Taluk":"Barmer","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344708","Taluk":"Dhorimana","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344032","Taluk":"Pachpadra City","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344701","Taluk":"Shiv","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344501","Taluk":"Gadra Road","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344035","Taluk":"Kavas","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344043","Taluk":"Mokalsar","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344801","Taluk":"Padru","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344708","Taluk":"Sanawara","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344703","Taluk":"Rawatsar","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344031","Taluk":"Gudamalni","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344044","Taluk":"Siwana","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344037","Taluk":"Swau Padamsingh","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344705","Taluk":"Gunga","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344024","Taluk":"Jasol","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344027","Taluk":"P;arlu","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344027","Taluk":"Parlu","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344034","Taluk":"Swau Padamsingh","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344704","Taluk":"dhorimana","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344037","Taluk":"Swaupadamsingh","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344025","Taluk":"Mewanagar","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344701","Taluk":"shiv","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344001","Taluk":"Barmer Ho","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"344035","Taluk":"Barmer","Districtname":"Barmer","statename":"RAJASTHAN"},
    {"pincode":"334023","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334023","Taluk":"Khajuwala","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331801","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334001","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334402","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334802","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334604","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331803","Taluk":"Dungargarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"334202","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334305","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331811","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334601","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334022","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331803","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334803","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334004","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334801","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334303","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334302","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334603","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334403","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334006","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334021","Taluk":"Chhatargarh","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334801","Taluk":"Nokha","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334804","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334001","Taluk":"NA","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334201","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334401","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334602","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331801","Taluk":"Biakner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334302","Taluk":"NA","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331811","Taluk":"NA","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331811","Taluk":"Dungargarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"334021","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334602","Taluk":"NA","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334603","Taluk":"Lunkaransar","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334604","Taluk":"Lunkaransar","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334604","Taluk":"Biakner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331801","Taluk":"Dungargarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"334804","Taluk":"Biakner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334803","Taluk":"Nokha","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334402","Taluk":"NA","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334003","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334305","Taluk":"Kolayat","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334808","Taluk":"Bikaner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331803","Taluk":"NA","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334803","Taluk":"Bikansr","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334201","Taluk":"Biakner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"334603","Taluk":"Biakner","Districtname":"Bikaner","statename":"RAJASTHAN"},
    {"pincode":"331802","Taluk":"Ratangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331505","Taluk":"Sujangahr","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331403","Taluk":"Sardarshahar","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331504","Taluk":"Ratangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331304","Taluk":"Taranagar","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331517","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331001","Taluk":"Churu","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331022","Taluk":"Ratangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331507","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331518","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331303","Taluk":"Sadulpur","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331023","Taluk":"Sadulpur","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331503","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331506","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331411","Taluk":"Sardarshahr","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331301","Taluk":"Sadulpur","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331305","Taluk":"Sadulpur","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331411","Taluk":"Sardarshahar","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331302","Taluk":"Taranagar","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331701","Taluk":"Sadulpur","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331506","Taluk":"NA","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331505","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331501","Taluk":"Ratangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331031","Taluk":"Churu","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331503","Taluk":"Ratangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331502","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331501","Taluk":"Sujangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331021","Taluk":"Churu","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331402","Taluk":"Sardarshahar","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331029","Taluk":"Churu","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331029","Taluk":"Sadulpur","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331505","Taluk":"Ratangarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331023","Taluk":"Rajgarh","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331402","Taluk":"Sardarshahr","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331029","Taluk":"Taranagar","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"331022","Taluk":"Churu","Districtname":"Churu","statename":"RAJASTHAN"},
    {"pincode":"333001","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333023","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333026","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333029","Taluk":"Surajgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333021","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333023","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331025","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333027","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333033","Taluk":"Surajgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333501","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333503","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333035","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333502","Taluk":"Buhana","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331026","Taluk":"Alsisar","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333801","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333307","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333704","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333025","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333704","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333705","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333022","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333031","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333026","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333304","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333042","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331025","Taluk":"Malsisar","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333516","Taluk":"Buhaana","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333033","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333305","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333515","Taluk":"Buhana","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333041","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333024","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331030","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333030","Taluk":"Surajgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333308","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333032","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333034","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331027","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333021","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331027","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333053","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333042","Taluk":"Nawalglarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333302","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333012","Taluk":"Udaiprwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333303","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333516","Taluk":"Buhana","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333701","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333707","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"332716","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"332746","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333504","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333514","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333011","Taluk":"Jhunjhunu","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333702","Taluk":"Nawlgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333001","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333516","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333302","Taluk":"Udaip;urwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333028","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333011","Taluk":"Alsisar","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333012","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333302","Taluk":"Udail;urwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333034","Taluk":"Buhana","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333030","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333053","Taluk":"Udaiplurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333029","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331028","Taluk":"Alsisar","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333024","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333053","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333023","Taluk":"Jhunjhunui","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333012","Taluk":"Udaiplurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333011","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333036","Taluk":"Khetri","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333307","Taluk":"Udailpurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331030","Taluk":"Malsisar","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333021","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331028","Taluk":"Malsisar","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333036","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333031","Taluk":"Buhana","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333308","Taluk":"Udaipurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333701","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333801","Taluk":"Udaiurwati","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333041","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333502","Taluk":"Chirawa","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"331026","Taluk":"Jhunjhunun","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"333303","Taluk":"Nawalgarh","Districtname":"Jhujhunu","statename":"RAJASTHAN"},
    {"pincode":"342311","Taluk":"Aau","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345001","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342001","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345023","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342308","Taluk":"Khichan","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342037","Taluk":"Lavera Baori","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342606","Taluk":"Pipar Road","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342025","Taluk":"Setrawa","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345028","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342901","Taluk":"NA","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342014","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342311","Taluk":"NA","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345021","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342603","Taluk":"Bhopalgarh","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345027","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342605","Taluk":"Bhavi","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342306","Taluk":"Teori","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342023","Taluk":"Balesar","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342023","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345024","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342027","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345025","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342027","Taluk":"Banar","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342307","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342303","Taluk":"Osian","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342301","Taluk":"Phalodi","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342307","Taluk":"Bap","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342602","Taluk":"Bilara","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342309","Taluk":"Peelwa","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342901","Taluk":"Aau","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342312","Taluk":"Chadi","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342901","Taluk":"Bhopalgarh","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345034","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342024","Taluk":"NA","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342801","Taluk":"Dunara","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345031","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342601","Taluk":"Pipar","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342605","Taluk":"Bilara","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345024","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342605","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345026","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342305","Taluk":"Mathania","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342022","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342603","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342022","Taluk":"Shergarh","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342602","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342310","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342012","Taluk":"NA","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342604","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345021","Taluk":"Pokaran","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342312","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345001","Taluk":"Bilara","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345001","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342901","Taluk":"Asop","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342314","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342606","Taluk":"Bhopalgarh","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342802","Taluk":"Shergarh","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342021","Taluk":"Setrawa","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342025","Taluk":"Shergarh","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342302","Taluk":"Lohawat","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342801","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342015","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342802","Taluk":"Luni","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345033","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342027","Taluk":"Phalodi","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342003","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342302","Taluk":"Phalodi","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342306","Taluk":"Osian","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342013","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342011","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342026","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342005","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342007","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342006","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342024","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342314","Taluk":"Dechu","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345034","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342015","Taluk":"Banar","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342308","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342028","Taluk":"Tena","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345031","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342037","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342302","Taluk":"Pokaran","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342802","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345024","Taluk":"Pokaran","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342304","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342305","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345027","Taluk":"Fatehgarh","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"345033","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"345028","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342008","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345026","Taluk":"Pokaran","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342310","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342303","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345025","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342604","Taluk":"Borunda","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342309","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342301","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342601","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342606","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345021","Taluk":"Luni","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345021","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"345022","Taluk":"NA","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"345023","Taluk":"Pokaran","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"345022","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342013","Taluk":"Luni","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"345027","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"345026","Taluk":"Jaisalmer","Districtname":"Jaisalmer","statename":"RAJASTHAN"},
    {"pincode":"342025","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342604","Taluk":"Bilara","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342021","Taluk":"Shergarh","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342304","Taluk":"Mandore","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342028","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"342306","Taluk":"Jodhpur","Districtname":"Jodhpur","statename":"RAJASTHAN"},
    {"pincode":"341025","Taluk":"Khinwsar","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341305","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341303","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341510","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341024","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341506","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341022","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341001","Taluk":"NA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341513","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341504","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341508","Taluk":"Nawacity","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341514","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341001","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341519","Taluk":"Nawacity","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341319","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"342902","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341306","Taluk":"Ladnun","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341302","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341518","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341031","Taluk":"NA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341501","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341512","Taluk":"Parbatsar","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341503","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341316","Taluk":"Ladnun","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341304","Taluk":"Ladnun","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341533","Taluk":"Nawacity","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341021","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341551","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341301","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341023","Taluk":"Jael","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341505","Taluk":"Parbatsar","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341520","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341026","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341516","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341517","Taluk":"Ladnun","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341028","Taluk":"Mundwa","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341509","Taluk":"Nawacity","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341031","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341502","Taluk":"Parbatsar","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341542","Taluk":"Parbatsar","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341502","Taluk":"Makrana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341027","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341505","Taluk":"NA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341511","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341302","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341029","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341301","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341030","Taluk":"Nagaur","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341507","Taluk":"Nawacity","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"342902","Taluk":"NA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341520","Taluk":"Kuchamancity","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341317","Taluk":"Ladnun","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341520","Taluk":"Nawacity","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341509","Taluk":"Parbatsar","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341515","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341508","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341503","Taluk":"NA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341304","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341026","Taluk":"Marwar Mundwa","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341506","Taluk":"Didwana]","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341318","Taluk":"Degana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341024","Taluk":"Mundwa","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341505","Taluk":"MAKRANA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341507","Taluk":"Makrana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341533","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341506","Taluk":"NA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341508","Taluk":"NA","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341317","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"305026","Taluk":"Merta","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"341319","Taluk":"Didwana","Districtname":"Nagaur","statename":"RAJASTHAN"},
    {"pincode":"306302","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306103","Taluk":"Sojat","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306504","Taluk":"Bali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306102","Taluk":"Raipur  Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306703","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306301","Taluk":"Jetaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306602","Taluk":"Sumerpur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306303","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306104","Taluk":"Sojat","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306001","Taluk":"NA","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306021","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306902","Taluk":"Sumerpur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306708","Taluk":"Sumerpur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306101","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306305","Taluk":"Raipur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306502","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306105","Taluk":"Raipur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306114","Taluk":"Sojat","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306709","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306912","Taluk":"Sumerpur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306701","Taluk":"Bali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306422","Taluk":"Pali Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306308","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306901","Taluk":"Sumerpur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306307","Taluk":"Raipur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306503","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306105","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306702","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306001","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306706","Taluk":"Bali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306306","Taluk":"Sojat","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306022","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306421","Taluk":"Rohat","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306707","Taluk":"Bali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306115","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306126","Taluk":"NA","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306401","Taluk":"Pali Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306705","Taluk":"Bali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306301","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306601","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306126","Taluk":"Sumerpur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306102","Taluk":"Raipur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306023","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306304","Taluk":"Raipur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306306","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306603","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306116","Taluk":"NA","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306501","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306116","Taluk":"Bali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306119","Taluk":"Pali Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306422","Taluk":"Pali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306103","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306704","Taluk":"Bali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306502","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306704","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306401","Taluk":"Pali  Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306021","Taluk":"Marwar Jn","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306422","Taluk":"Pali  Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306401","Taluk":"Marwar Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306119","Taluk":"Pali Marwar","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306401","Taluk":"Rohat","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306401","Taluk":"Pali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306119","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306502","Taluk":"Marwar  Jn.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306119","Taluk":"Pali  Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306708","Taluk":"NA","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306305","Taluk":"Raipur Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306305","Taluk":"Jaitaran","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306023","Taluk":"Marwar Jn","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306126","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306023","Taluk":"Marwar In.","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306307","Taluk":"Raipur Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306101","Taluk":"Raipur","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306304","Taluk":"Raipur  Mwr","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306706","Taluk":"Desuri","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"306503","Taluk":"Pali","Districtname":"Pali","statename":"RAJASTHAN"},
    {"pincode":"332402","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332602","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332701","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332315","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332312","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332303","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332002","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332021","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332603","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332709","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332401","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332023","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332317","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332311","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332715","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332001","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332601","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332403","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332401","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332718","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332305","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332301","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332023","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332411","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332405","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332402","Taluk":"Danta Ramgarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332708","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332316","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332316","Taluk":"Lachhmangarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332031","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332303","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332024","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332714","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332742","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332028","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332025","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332318","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332404","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332713","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332703","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332042","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332316","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332316","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332029","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332027","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332710","Taluk":"Danta Ramgarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332706","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332601","Taluk":"Danta Ramgarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332705","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332706","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332401","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332718","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332702","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332711","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332701","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"331024","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332302","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332406","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332721","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332712","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332719","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332312","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332026","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332722","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332714","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332715","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332707","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332715","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332304","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332318","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332715","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332708","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332710","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332719","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332722","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332002","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"331024","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332710","Taluk":"Srimadhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332041","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332709","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332705","Taluk":"Neem-Ka-Thana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332315","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332026","Taluk":"Lachhmangarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332404","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332311","Taluk":"Lachhmangarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332402","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332602","Taluk":"Danta Ramgarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332703","Taluk":"Danta Ramgarh","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332030","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332712","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332713","Taluk":"Neem-Ka-Thana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332713","Taluk":"Nimkathana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332302","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332701","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332027","Taluk":"NA","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332701","Taluk":"Neem-Ka-Thana","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332721","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332719","Taluk":"Sikar","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332718","Taluk":"Sri Madhopur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332302","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332317","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332305","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332307","Taluk":"Fatehpur","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"332307","Taluk":"Fatehpur Shekhawati","Districtname":"Sikar","statename":"RAJASTHAN"},
    {"pincode":"307026","Taluk":"Abu Road","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307501","Taluk":"Aburoad","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343041","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307510","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307030","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307029","Taluk":"Ahore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307029","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307022","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343040","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343039","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343025","Taluk":"Sanchore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307510","Taluk":"Abu Road","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343001","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307028","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343049","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307512","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343027","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307031","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307511","Taluk":"Reodar","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307043","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343042","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343022","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307026","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307027","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343032","Taluk":"Jalore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343025","Taluk":"Jalore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343025","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307001","Taluk":"Sirohi","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307513","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307801","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307514","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307019","Taluk":"Pindwara","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343028","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343029","Taluk":"Bhinmal","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343029","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307023","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343024","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307024","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307031","Taluk":"Pindwara","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307803","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307802","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343032","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307511","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343021","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307515","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307512","Taluk":"Reodar","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307501","Taluk":"Abu Road","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343048","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343023","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343030","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307001","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307030","Taluk":"Ahore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343027","Taluk":"Sanchore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343002","Taluk":"NA","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343001","Taluk":"Jalor","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343001","Taluk":"Jalore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307515","Taluk":"Bhinmal","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307801","Taluk":"Sirohi","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343030","Taluk":"Bhinmal","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343049","Taluk":"Bhinmal","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307802","Taluk":"Sirohi","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343025","Taluk":"Jalor","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343039","Taluk":"Bhinmal","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307513","Taluk":"Reodar","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343042","Taluk":"Jalor","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343028","Taluk":"Bhinmal","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343041","Taluk":"Sanchore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343021","Taluk":"Jalor","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307043","Taluk":"Sirohi","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307022","Taluk":"Pindwara","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307028","Taluk":"Sheoganj","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343024","Taluk":"Jalor","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307803","Taluk":"Bhinmal","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343040","Taluk":"Raniwara","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"343032","Taluk":"Bagora","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307514","Taluk":"Reodar","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307024","Taluk":"Aburoad","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"307023","Taluk":"Pindwara","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343022","Taluk":"Sayla","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307027","Taluk":"Sheoganj","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343024","Taluk":"Bagra","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307515","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343021","Taluk":"Sayla","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"307501","Taluk":"NA","Districtname":"Sirohi","statename":"RAJASTHAN"},
    {"pincode":"343048","Taluk":"Sanchore","Districtname":"Jalor","statename":"RAJASTHAN"},
    {"pincode":"335061","Taluk":"Padampur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335707","Taluk":"Gharsana","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335038","Taluk":"Sriganganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335039","Taluk":"Raisinghnagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335051","Taluk":"Raisinghnagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335704","Taluk":"Sribijaynagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335711","Taluk":"Gharsana","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335001","Taluk":"NA","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335040","Taluk":"Padampur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335073","Taluk":"Srikaranpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335024","Taluk":"Gajsinghpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335524","Taluk":"Rawatsar","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335001","Taluk":"Sriganganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335703","Taluk":"Sribijeynagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335041","Taluk":"Padampur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335027","Taluk":"Kesrisinghpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335701","Taluk":"Anupgarh","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335027","Taluk":"Srikaranpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335024","Taluk":"Gajsinghpur","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335802","Taluk":"Pilibanga","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335038","Taluk":"Srigaganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335705","Taluk":"Suratgarh","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335024","Taluk":"Gajsinghpura","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335021","Taluk":"Raisinghnagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335702","Taluk":"Sribijenagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335512","Taluk":"Hanumangarh Jn.","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335702","Taluk":"Sribijeynagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335804","Taluk":"Suratgarh","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335025","Taluk":"Sadulsahar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335803","Taluk":"Pilibangan","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335501","Taluk":"Bhadra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335062","Taluk":"Sadulshahar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335063","Taluk":"Sangaria","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335513","Taluk":"Hanumangarh","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335523","Taluk":"Nohar","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335503","Taluk":"Bhadra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335504","Taluk":"Nohar","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335801","Taluk":"Bhadra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335051","Taluk":"Sribijeynagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335063","Taluk":"Sangari","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335063","Taluk":"Snagaria","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335701","Taluk":"Sadulshahar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335524","Taluk":"Rawatsar.","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335511","Taluk":"Bhadra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335001","Taluk":"Srigangangar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335502","Taluk":"Bhadra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335022","Taluk":"Sriganganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335002","Taluk":"Sriganganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335526","Taluk":"Tibbi","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335023","Taluk":"Kesrisinghpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335001","Taluk":"Anupgarh","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335511","Taluk":"Nohar","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335512","Taluk":"Hanumangarh","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335801","Taluk":"Hanumangarh","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335023","Taluk":"Srigangangaar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335065","Taluk":"Suratgarh","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335040","Taluk":"Srikaranpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335002","Taluk":"Sadulsahar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335064","Taluk":"Hanumangarh","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335901","Taluk":"Kesrisinghpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335024","Taluk":"Gajsingpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335025","Taluk":"Sadulshahar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335502","Taluk":"Bhdra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335802","Taluk":"Hanumangarh","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335901","Taluk":"Sriganganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335064","Taluk":"Sadulshahar","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335803","Taluk":"NA","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335702","Taluk":"Sriganganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335702","Taluk":"Sribijaynagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335502","Taluk":"Nohar","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335513","Taluk":"Kohlan","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335703","Taluk":"Anoopgarh","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335037","Taluk":"Sadulshahar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335804","Taluk":"NA","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335512","Taluk":"Hanumangrh","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335703","Taluk":"Ramsinghpur","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335523","Taluk":"NA","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335703","Taluk":"Sribijaynagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335061","Taluk":"Padampura","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335061","Taluk":"Sriganganagar","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335504","Taluk":"Bhadra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335503","Taluk":"NA","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335525","Taluk":"Tibbi","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"335805","Taluk":"Suratgarh","Districtname":"Ganganagar","statename":"RAJASTHAN"},
    {"pincode":"335525","Taluk":"Bhadra","Districtname":"Hanumangarh","statename":"RAJASTHAN"},
    {"pincode":"600002","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"632506","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632510","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632508","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631002","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632501","Taluk":"Wallajah","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632519","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632507","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631003","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632513","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632511","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632502","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631001","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632517","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632503","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631151","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632509","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631001","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631051","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632505","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632531","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631102","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632406","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632511","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632520","Taluk":"Wallajah","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632504","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632512","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632405","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631004","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632510","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632515","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631101","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631006","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631052","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631003","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632501","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632404","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632519","Taluk":"NA","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631004","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632514","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631101","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631102","Taluk":"Wallajah","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632520","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632516","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632318","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632404","Taluk":"Wallajah","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632502","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632512","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632401","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632508","Taluk":"Wallajah","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631002","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632518","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632510","Taluk":"NA","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632518","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632401","Taluk":"Walaja","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632401","Taluk":"Walajapet Taluk","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632403","Taluk":"Walajapet","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632521","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631051","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632515","Taluk":"Wallajah","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631152","Taluk":"Arakkonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632507","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632513","Taluk":"Wallajah","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"631005","Taluk":"Arakonam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"603301","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603001","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603110","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603109","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603003","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603312","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603107","Taluk":"Uthiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603307","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603401","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603204","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603211","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603127","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603405","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603106","Taluk":"Uthiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603306","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603101","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603310","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603102","Taluk":"Tiruklalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603308","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603002","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603302","Taluk":"Cheyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603204","Taluk":"Chengalpatatu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603302","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603313","Taluk":"Cheyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603109","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603107","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603201","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603204","Taluk":"Sriperubudur Taluk","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603202","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603108","Taluk":"Chengalpattu Taluk","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603301","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603312","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603301","Taluk":"Cheyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603310","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603303","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603305","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603104","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603308","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603102","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603108","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603310","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603204","Taluk":"Chengallpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603203","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603314","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603313","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603301","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603110","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603107","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603303","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603303","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603305","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603104","Taluk":"Tirukalilkundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603308","Taluk":"Maduranthakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603304","Taluk":"Cheyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603104","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603304","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603304","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603105","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603202","Taluk":"Chengalplattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603108","Taluk":"Tirukalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603111","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603105","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603209","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603319","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603102","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603204","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603111","Taluk":"Maduranthakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603307","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603309","Taluk":"Cheyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603109","Taluk":"Tirulkalikundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603105","Taluk":"Tirukalilkundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603302","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603107","Taluk":"Maduranthakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603310","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603305","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603306","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603203","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603313","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603109","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603106","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603307","Taluk":"Maduranthakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603111","Taluk":"Tirukalikundram`","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603311","Taluk":"Cheyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603201","Taluk":"Maduranthakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603309","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603308","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603107","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603301","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603311","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603309","Taluk":"Cheyyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603314","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603104","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603104","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603107","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603102","Taluk":"Tirukalukundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603401","Taluk":"Cheyur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603201","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603101","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603107","Taluk":"Utiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603106","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603305","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603319","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603306","Taluk":"Maduranthakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603319","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603109","Taluk":"Tirukalukundram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603110","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603210","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603401","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603304","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603401","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603311","Taluk":"Madurantakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600005","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600094","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600086","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600006","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600017","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600014","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600034","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600004","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600018","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600082","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600030","Taluk":"Egmore Nungambakkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600102","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600040","Taluk":"Egmore Nungambakkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600101","Taluk":"Ambattur","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600106","Taluk":"Maduravoyal","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600023","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600031","Taluk":"Egmore Nungambakkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600106","Taluk":"Egmore Nungambakkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600003","Taluk":"Tondiarpet Fort St George","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600008","Taluk":"NA","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600008","Taluk":"Egmore Nungambakka","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600001","Taluk":"NA","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600084","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600009","Taluk":"Tondiarpet Fort St George","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600082","Taluk":"Perambur Purawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600001","Taluk":"Tondiarpet Fort St George","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600104","Taluk":"Tondiarpet Fort St George","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600038","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600010","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600107","Taluk":"Maduravoyal","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600082","Taluk":"NA","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600001","Taluk":"Tondiarpet Fort St Goerge","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600107","Taluk":"Chennai Corporation","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600012","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600011","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600013","Taluk":"Egmore Nungambakkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600081","Taluk":"Tondiarpet Fort St George","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600007","Taluk":"Perambur Purasawalkam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600039","Taluk":"Tondiarpet Fort St George","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600021","Taluk":"Tondiarpet Fort St George","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600031","Taluk":"Egmore Nungambakkm","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600088","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600020","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600016","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600087","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600083","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600090","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600032","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600025","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600015","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600036","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600115","Taluk":"Kancheepuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600078","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600097","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600024","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600085","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600092","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600091","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600033","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600016","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600089","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600035","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600061","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600041","Taluk":"Chennai City Corporation","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600088","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600041","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600096","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600028","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600022","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600093","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600119","Taluk":"Saidapet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600113","Taluk":"Mambalam","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600041","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600113","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600026","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600041","Taluk":"Mylapore - Triplicane","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600042","Taluk":"Chennai City Corporation","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600001","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"631303","Taluk":"Pallipat","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603406","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631209","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631213","Taluk":"Trutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603402","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602023","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631303","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631301","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602025","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603402","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631204","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631603","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631201","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631605","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631202","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631202","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631210","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601102","Taluk":"Kancheepuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631561","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631605","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631601","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631502","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631551","Taluk":"Kancheepuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631205","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631502","Taluk":"Kancheepuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631208","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631206","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631302","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631553","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631210","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631551","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"601103","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631207","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631604","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631553","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631203","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631304","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631302","Taluk":"Pallipattu","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602026","Taluk":"Uttukottai","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602105","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631210","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602021","Taluk":"Thiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602002","Taluk":"Thiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602117","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631301","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631203","Taluk":"Thiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602003","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602025","Taluk":"Thiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603406","Taluk":"Uthiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631502","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631204","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631501","Taluk":"Kancheepuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602108","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"601102","Taluk":"Uttukottai","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631552","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631552","Taluk":"Kancheepuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602021","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631207","Taluk":"Pallipat","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602024","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631211","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631211","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603406","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631208","Taluk":"Pallipat","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631205","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631402","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603406","Taluk":"Maduranthakam","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602002","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631402","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631202","Taluk":"Kancheepuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631206","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602024","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631206","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631606","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602106","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602026","Taluk":"Uthukkottai","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603403","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603403","Taluk":"Uthiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602002","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631209","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631212","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601102","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602024","Taluk":"Sriperumbudur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631209","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602001","Taluk":"Thiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631606","Taluk":"Uttiramerur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631302","Taluk":"Pallipat","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602024","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602026","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631207","Taluk":"Pallipattu","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602108","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631604","Taluk":"Sriperubudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602105","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631402","Taluk":"Sriperumbudur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631213","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631213","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602023","Taluk":"Tiurvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631212","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601103","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603403","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631561","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631304","Taluk":"Tiruttani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602026","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631605","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602106","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602021","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631601","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"631606","Taluk":"Kanchipuram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"602105","Taluk":"Thiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"602001","Taluk":"Tiruvallur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631304","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"631303","Taluk":"Tirutani","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601103","Taluk":"Uttukottai","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"605007","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605402","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604301","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604207","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604101","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604302","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604151","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604303","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605201","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605103","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604203","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605201","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604202","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605651","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604210","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605202","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605102","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605108","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605110","Taluk":"Villianur Commune Panchayat","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605203","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605652","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604152","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605401","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604307","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605101","Taluk":"Vanur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604201","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604001","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605601","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607402","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605104","Taluk":"Pondichery","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605203","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605014","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605106","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605102","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605601","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605502","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604208","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605006","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605106","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604102","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604304","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604204","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604204","Taluk":"Tindvanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604202","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605111","Taluk":"Vanur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604201","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604210","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605111","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604206","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605502","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604151","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605105","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605302","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605014","Taluk":"Vanur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605602","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605301","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605401","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604205","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604154","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605109","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605110","Taluk":"Vanur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605014","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607403","Taluk":"NA","Districtname":"NULL","statename":"NULL"},
    {"pincode":"605501","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604306","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605110","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605104","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605101","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605107","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605008","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605107","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605105","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605107","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605602","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605302","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605106","Taluk":"Nettapakkam Commune Panchayat","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605501","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605007","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605202","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605103","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604206","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605105","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604205","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605101","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605009","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605004","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605003","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604152","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604307","Taluk":"Tindvanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605301","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605108","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605651","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605005","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604305","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605010","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604153","Taluk":"Tindivanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605106","Taluk":"cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"604153","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607403","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605652","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605001","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605201","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605109","Taluk":"Vanur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607403","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605013","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604204","Taluk":"Gingee","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604153","Taluk":"Tindivaman","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605111","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604301","Taluk":"Tindvanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605002","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"604102","Taluk":"Vanur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607402","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605201","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605501","Taluk":"Viluppuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604207","Taluk":"Tindvanam","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"604154","Taluk":"Vanur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605402","Taluk":"Villupurm","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605011","Taluk":"Pondicherry","Districtname":"Pondicherry","statename":"PONDICHERRY"},
    {"pincode":"605106","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"601204","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600052","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600116","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600067","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600053","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600058","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600070","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600103","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601203","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601201","Taluk":"Gummidipundi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600072","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601101","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600054","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600055","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600095","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600077","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600117","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600044","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"601206","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600064","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600059","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600065","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600044","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600057","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600057","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600057","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600118","Taluk":"Chennai","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600058","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600122","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600073","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600045","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600056","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600050","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600047","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600066","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600019","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600071","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601202","Taluk":"Gummidipundi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600056","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601206","Taluk":"Gummidipoondi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600117","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603103","Taluk":"Chingleput","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600118","Taluk":"Chennai City","Districtname":"Chennai","statename":"TAMIL NADU"},
    {"pincode":"600055","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600127","Taluk":"Chinglepet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600099","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601205","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600076","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603112","Taluk":"Chinglepet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600129","Taluk":"Sholinganallur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600062","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600128","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600069","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600124","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600126","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600051","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600060","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600095","Taluk":"Poonamalee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601301","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600123","Taluk":"Poonamallee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600103","Taluk":"Gummidipoondi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600068","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600125","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600048","Taluk":"Chinglepet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600100","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600127","Taluk":"Chengalpattu","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600037","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600060","Taluk":"Gummidipoondi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600048","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600120","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600130","Taluk":"Chinglepet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600044","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600120","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600063","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600052","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"603103","Taluk":"Chinglepet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600043","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600075","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"601201","Taluk":"Gummidpundi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600074","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600110","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600056","Taluk":"Poonamalee","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600066","Taluk":"Ponneri","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600049","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600073","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"601301","Taluk":"NA","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600098","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"601201","Taluk":"Gummidipoondi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600046","Taluk":"Tambaram","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"601201","Taluk":"Gummidipundii","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600128","Taluk":"Sriperumbudur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600130","Taluk":"Tiruporur","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"603112","Taluk":"Chingelpet","Districtname":"Kanchipuram","statename":"TAMIL NADU"},
    {"pincode":"600019","Taluk":"NA","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600060","Taluk":"Ambattur","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"600062","Taluk":"Avadi","Districtname":"Tiruvallur","statename":"TAMIL NADU"},
    {"pincode":"606905","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606604","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606901","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632317","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606604","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604403","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632314","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"631701","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606702","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604408","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604402","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606904","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606753","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604503","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632315","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604408","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604405","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604401","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606804","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606703","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604504","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604401","Taluk":"Vandavsi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606808","Taluk":"Thandrampattu","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606807","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"631702","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604408","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606709","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632326","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632301","Taluk":"Arani","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632301","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606752","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606806","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604407","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604501","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606803","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606708","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606902","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606907","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606755","Taluk":"Tiruvannamalai Taluk","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604505","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606603","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606601","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606908","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606755","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606701","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606801","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632316","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604410","Taluk":"Cheyyaru","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606802","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606806","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604404","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606707","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606906","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604406","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604502","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606704","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604601","Taluk":"Tvmalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604402","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632313","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604503","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604403","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604407","Taluk":"Arcot","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"604410","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606903","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632311","Taluk":"Arni","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606751","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606805","Taluk":"Tiruvananamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606710","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606754","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606903","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632319","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"606611","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632311","Taluk":"Arani","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606705","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604601","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632312","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632312","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604601","Taluk":"Tiruannvmalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606604","Taluk":"Tiruvnannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606705","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606601","Taluk":"Tiruvananmalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606704","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604601","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606805","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606706","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604409","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606752","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"631701","Taluk":"Cheyyar","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606801","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604406","Taluk":"Vandvasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604503","Taluk":"Vandavsi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606708","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606603","Taluk":"Tiruvananamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606754","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606705","Taluk":"Chegam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606808","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606806","Taluk":"Tiruannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604410","Taluk":"Vandavasi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606705","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606755","Taluk":"Tiruvnamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604601","Taluk":"Tvamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606755","Taluk":"Tvmalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606802","Taluk":"Tiruvananmalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606603","Taluk":"NA","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606804","Taluk":"Tiruvananamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632313","Taluk":"Vandavsi","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606811","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606802","Taluk":"Tiruvannamaali","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606754","Taluk":"Tiruvanamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"604505","Taluk":"Vandavasi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632311","Taluk":"Arni","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632319","Taluk":"Vellore","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"632011","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632010","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632101","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632102","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632009","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632101","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632204","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632202","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632107","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632055","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632106","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632002","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632004","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632014","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632105","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632113","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632113","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632059","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632002","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632104","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632103","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632006","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632107","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632006","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632007","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632012","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632202","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632106","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632102","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632013","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632001","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632007","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632058","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632204","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632013","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632057","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632103","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632103","Taluk":"NA","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632009","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632114","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632009","Taluk":"NA","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632114","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632006","Taluk":"Vlr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632103","Taluk":"Vlrq","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632008","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632106","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632011","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632059","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632115","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632105","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632204","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632059","Taluk":"Vl","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632103","Taluk":"Velr","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632001","Taluk":"Vellore Taluk","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632014","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632104","Taluk":"Vellore","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"641110","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641101","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641112","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641108","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641032","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641046","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641019","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641105","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641018","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641029","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641201","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641049","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641014","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641001","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641021","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641014","Taluk":"Coimbatore","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641008","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641109","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641109","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641016","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641025","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641006","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641004","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641012","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641107","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641013","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641047","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641022","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641114","Taluk":"Madhavapuram","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641010","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641048","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641107","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641114","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641107","Taluk":"Avanashi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641030","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641035","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641026","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641023","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641042","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641045","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641038","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641003","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641101","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641050","Taluk":"Coimbatore","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641062","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641020","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641036","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641031","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641033","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641028","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641011","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641017","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641037","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641041","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641016","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641002","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641009","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641027","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641007","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641043","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641035","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641007","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641026","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641044","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641005","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641010","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641024","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641039","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641034","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641015","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641041","Taluk":"Coimbatore","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641110","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641108","Taluk":"Coimabtore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641111","Taluk":"Coimbatore South","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641048","Taluk":"Coimbatore North","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"636704","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636807","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636905","Taluk":"Pappireddipatti","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635205","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636903","Taluk":"Harur","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636803","Taluk":"Pennagaram","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636810","Taluk":"Pennagaram","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636904","Taluk":"Pappireddipatti","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636806","Taluk":"Palakkodu","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636906","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636906","Taluk":"Harur","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636813","Taluk":"Pennagaram","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636811","Taluk":"Pennagaram","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635201","Taluk":"Krishnagiri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636805","Taluk":"Pennagaram","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635301","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635201","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636808","Taluk":"Palacode","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636808","Taluk":"Palakkodu","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636806","Taluk":"Palacode","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636804","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635302","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636809","Taluk":"Pennagaram","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635302","Taluk":"Pappireddipatti","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635202","Taluk":"NA","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635305","Taluk":"Harur","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636705","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636701","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636001","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636810","Taluk":"NA","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636902","Taluk":"Harur","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635205","Taluk":"Palakkodu","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636809","Taluk":"Paupparapatti","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635202","Taluk":"Harur","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636803","Taluk":"Palacode","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636805","Taluk":"Palacode","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635303","Taluk":"Pappireddipatti","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636352","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635303","Taluk":"Harur","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635302","Taluk":"Pappiredipatti","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636804","Taluk":"Nallampalli","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636803","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636807","Taluk":"Nallampalli","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636812","Taluk":"Palacode","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635301","Taluk":"Pappireddipatti","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635202","Taluk":"Dharmapuri","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636809","Taluk":"Palakkodu","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"636352","Taluk":"Nallampalli","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635302","Taluk":"Harur","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"638502","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638454","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638501","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638455","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638457","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638453","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638109","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638311","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638752","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638462","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638151","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638501","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638315","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638101","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638506","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638103","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638115","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638455","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638503","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638051","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638301","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638301","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638315","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638311","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638007","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638504","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638004","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638103","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638102","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638751","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638312","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638503","Taluk":"Sathyamangalam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638009","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638458","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638316","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638104","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638505","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638011","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638001","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638002","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638153","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638110","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638112","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638452","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638452","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638476","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638056","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638504","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638503","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638052","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638107","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638476","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638314","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638110","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638056","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638112","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638505","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638116","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638314","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638453","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638457","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638052","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638003","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638151","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"638454","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638502","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638007","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638154","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638456","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638152","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638751","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638458","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638313","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638110","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638055","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638462","Taluk":"Coimbatore","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638103","Taluk":"Coimbatore","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638057","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638055","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638057","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638006","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638007","Taluk":"Erode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638312","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638458","Taluk":"Erode","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638462","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638005","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638053","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638313","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638315","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638752","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638054","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638103","Taluk":"Erode","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638010","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638456","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638012","Taluk":"Erode","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638462","Taluk":"Erode","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638506","Taluk":"Gobichettipalayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638151","Taluk":"Erode","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"638316","Taluk":"Bhavani","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"635105","Taluk":"NA","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635108","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635110","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635118","Taluk":"Denkanikotta","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635119","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635204","Taluk":"Pochampalli","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635122","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635002","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635203","Taluk":"Pochampalli","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635104","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635206","Taluk":"Pochampalli","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635304","Taluk":"Uttangarai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635102","Taluk":"Denkanikotta","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635203","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635306","Taluk":"Uttangarai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635107","Taluk":"Denkanikotta","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635307","Taluk":"Uttangarai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635105","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635109","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635103","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635113","Taluk":"Denkanikotta","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635121","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635107","Taluk":"Denkanikottai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635116","Taluk":"Denkanikottai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635123","Taluk":"Pochampalli","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635106","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635104","Taluk":"Barugur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635109","Taluk":"NA","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635104","Taluk":"Pochampalli","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635115","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635126","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635118","Taluk":"Denkanikottai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635124","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635120","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635111","Taluk":"Palakkodu","Districtname":"Dharmapuri","statename":"TAMIL NADU"},
    {"pincode":"635113","Taluk":"Denkanikottai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635117","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635114","Taluk":"Hosur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635112","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635123","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635111","Taluk":"Palacode","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635001","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635107","Taluk":"NA","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635116","Taluk":"Denkanikotta","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635101","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635304","Taluk":"Harur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635207","Taluk":"Uttangarai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635112","Taluk":"Kaveripattinam","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635304","Taluk":"Uthangarai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635102","Taluk":"Denkanikottai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635114","Taluk":"Denkanikotta","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635114","Taluk":"Denkanikottai","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635204","Taluk":"Mathur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635206","Taluk":"Mathur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635306","Taluk":"Krishnagiri","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"635307","Taluk":"Harur","Districtname":"Krishnagiri","statename":"TAMIL NADU"},
    {"pincode":"637210","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637103","Taluk":"Sankari","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637411","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637202","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637212","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637301","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637503","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637409","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637101","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638008","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637020","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637013","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637303","Taluk":"Sankari","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637411","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637304","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637214","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638182","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637017","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637015","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637019","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637402","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637405","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637105","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637003","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637002","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637101","Taluk":"Idappadi","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637103","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637303","Taluk":"Sanakari","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637410","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637209","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637201","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637105","Taluk":"Idappadi","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637018","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637301","Taluk":"Sankari","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637209","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637021","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637202","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638008","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637205","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637102","Taluk":"Idappadi","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637201","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637204","Taluk":"Paramthi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637213","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637102","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637014","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637404","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638183","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637203","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637204","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637503","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637302","Taluk":"Sankari","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637410","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637302","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637104","Taluk":"Sankari","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637203","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637207","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637208","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637201","Taluk":"Paramathi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638183","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637107","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637206","Taluk":"Paramathi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637214","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637215","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637205","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637203","Taluk":"Paramathi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637213","Taluk":"Paramathi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637207","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637503","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637212","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637410","Taluk":"Tiruchengodu","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637104","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637304","Taluk":"Sankari","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637303","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637206","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637206","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637001","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638182","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637107","Taluk":"Idappadi","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637303","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637204","Taluk":"Paramathivelur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637206","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638181","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637104","Taluk":"Sankari","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638182","Taluk":"Paramathi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637203","Taluk":"Paramathivelur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637202","Taluk":"Tirchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637208","Taluk":"Paramathi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637210","Taluk":"Paramthi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637304","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637215","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637211","Taluk":"Tiruchengodu","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637211","Taluk":"Tiruchengode","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637201","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637404","Taluk":"namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"638181","Taluk":"Paramathi Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637207","Taluk":"Paramthivelur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"637210","Taluk":"Paramathi-Velur","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"643102","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643240","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643206","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643216","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643221","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643201","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643202","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643203","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643241","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643212","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643209","Taluk":"Kundah","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643203","Taluk":"Kundah","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643224","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643204","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643231","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643214","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643204","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643001","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643205","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643220","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643220","Taluk":"Pandalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643211","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643101","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643207","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643007","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643002","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643219","Taluk":"Kundah","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643217","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643005","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643206","Taluk":"Udagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643004","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643006","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643237","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643226","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643103","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643236","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643007","Taluk":"Udagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643004","Taluk":"Udagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643214","Taluk":"Udagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643225","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643213","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643236","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643215","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643253","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643239","Taluk":"Pandalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643218","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643219","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643242","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643003","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643220","Taluk":"Panthalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643223","Taluk":"Udagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643223","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643207","Taluk":"Panthalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643003","Taluk":"Kundah","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643224","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643225","Taluk":"Pandalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643238","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643105","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643233","Taluk":"Pandalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643103","Taluk":"Coonoor","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643228","Taluk":"Gudalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643238","Taluk":"Kotagiri","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643215","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643002","Taluk":"Udagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643001","Taluk":"Udagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643241","Taluk":"Panthalur","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"643243","Taluk":"Udhagamandalam","Districtname":"Nilgiris","statename":"TAMIL NADU"},
    {"pincode":"642205","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642002","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642111","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642004","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642127","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642110","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642101","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642201","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642102","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642103","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642205","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642104","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642154","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642120","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642007","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642109","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642206","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642154","Taluk":"Udamalpet Ho","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642207","Taluk":"Udaamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642106","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642202","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642132","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642005","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642207","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642112","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642126","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642122","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642001","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642154","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642107","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642108","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642203","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642101","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642129","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642003","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642130","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642204","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641202","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642111","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642107","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642113","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642114","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642202","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642105","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642117","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642125","Taluk":"Valparai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642204","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642134","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642206","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"642120","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642123","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642113","Taluk":"Palani","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"642113","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642133","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642205","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642122","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642006","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642132","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642112","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642133","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642126","Taluk":"Udamalpet","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642004","Taluk":"NA","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642207","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642127","Taluk":"Pollachi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"642128","Taluk":"Udumalaipettai","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"636117","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636106","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636122","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636204","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636308","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636104","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636004","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636003","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636141","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636105","Taluk":"Gangavalli","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636002","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636139","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636108","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636101","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636104","Taluk":"Valapady","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636107","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636119","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636602","Taluk":"Yercaud","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636115","Taluk":"Valapady","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637501","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636102","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636102","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636103","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636008","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636104","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636111","Taluk":"Valapady","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636006","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636015","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636109","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636201","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636001","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636112","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636203","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636016","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636007","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636114","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636116","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636113","Taluk":"Gangavalli","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636117","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636014","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636140","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636138","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636121","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636601","Taluk":"Yercaud","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636107","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636138","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636139","Taluk":"Valapady","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636104","Taluk":"Gangavalli","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636010","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636110","Taluk":"Gangavalli","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636113","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636115","Taluk":"Atur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636112","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636111","Taluk":"Vazhapadi","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636142","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636142","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636141","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636118","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636108","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636114","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636113","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636109","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636101","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636106","Taluk":"Vazhapadi","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636203","Taluk":"Yercaud","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636116","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636118","Taluk":"Gangavalli","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636121","Taluk":"Attur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636115","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637403","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636501","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637505","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636503","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636451","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637401","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636404","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636307","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636009","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637406","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636502","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636301","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636304","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636202","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636302","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637412","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636351","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636304","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636012","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636455","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636303","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636354","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636309","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636306","Taluk":"Edappadi","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636306","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637502","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636011","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636453","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636005","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636351","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637504","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637408","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636202","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636454","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636305","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636354","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636013","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636403","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636012","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636305","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636452","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636458","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636011","Taluk":"Omalur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637407","Taluk":"Rasipuram","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636030","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636455","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636406","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636402","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636401","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636005","Taluk":"salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636503","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636402","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636502","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636309","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636354","Taluk":"Salem","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"636457","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637407","Taluk":"Rasipuram","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"637408","Taluk":"Salem","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"636456","Taluk":"Mettur","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"635651","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635602","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635804","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635653","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632604","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635701","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635814","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635801","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635802","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635806","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635702","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635652","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635811","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635810","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635601","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635703","Taluk":"Polur","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"635852","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635703","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635815","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635801","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635854","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632602","Taluk":"Gudiyatham","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635653","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635805","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632209","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635751","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635901","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635754","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635813","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635811","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635655","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632603","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635853","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632601","Taluk":"Gudiyatham","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635901","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635602","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635809","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632602","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635803","Taluk":"Gudiyatham","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635652","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635651","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635851","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635654","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632601","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635810","Taluk":"Gudiyatham","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635852","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635815","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635803","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635812","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635655","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632201","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635752","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635853","Taluk":"Tirupathur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635808","Taluk":"Gudiyatham","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635710","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635851","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635710","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"632203","Taluk":"Katpadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635703","Taluk":"Chengam","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"635807","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635808","Taluk":"Gudiyattam","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635702","Taluk":"Vaniyambadi","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635809","Taluk":"Gudiyatham","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635710","Taluk":"NA","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635601","Taluk":"Tirupattur","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"635751","Taluk":"NA","Districtname":"Vellore","statename":"TAMIL NADU"},
    {"pincode":"641652","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641663","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638673","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638111","Taluk":"Kangeyam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641663","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641302","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641665","Taluk":"NA","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638701","Taluk":"Kangayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638105","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638661","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638657","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641655","Taluk":"Avanashi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641653","Taluk":"Avanashi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638459","Taluk":"Sathy","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641687","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641664","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641402","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638401","Taluk":"Sathyamangalam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638461","Taluk":"Sathy","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641604","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641407","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638402","Taluk":"Sathyamangalam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641103","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641654","Taluk":"NA","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638701","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638660","Taluk":"Kangayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641603","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641104","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638702","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638451","Taluk":"Sathyamangalam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641113","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641658","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641669","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638812","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641659","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638461","Taluk":"Sathyamangalam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638401","Taluk":"Sathy","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638106","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638656","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638672","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641305","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638459","Taluk":"Sathyamangalam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641667","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638702","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638451","Taluk":"Sathy","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641664","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641697","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638460","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641605","Taluk":"Kangayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641659","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641401","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641668","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641687","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641103","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641666","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641671","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641302","Taluk":"Mettupalyaam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641662","Taluk":"Palladam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638661","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641602","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638706","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638701","Taluk":"Kangeyam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641402","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641605","Taluk":"Palladam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641670","Taluk":"NA","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641697","Taluk":"Avanashi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641671","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641654","Taluk":"Avanashi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641654","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641601","Taluk":"Avanashi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641659","Taluk":"Avanashi","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641666","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641604","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641667","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638402","Taluk":"Sathy","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638106","Taluk":"Dharapram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638660","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638108","Taluk":"Kangayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641653","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638703","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638111","Taluk":"Kangayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638105","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641607","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641606","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641301","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638703","Taluk":"Kangayam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641606","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638706","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638660","Taluk":"Dharaupram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641665","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638673","Taluk":"Dharapram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641669","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641607","Taluk":"Perundurai","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638460","Taluk":"Sathyamangalam","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641113","Taluk":"NA","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641668","Taluk":"Mettupalayam","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638402","Taluk":"NA","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"638703","Taluk":"NA","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641607","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641601","Taluk":"Tirupur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"641605","Taluk":"Tirupur","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"638111","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"641605","Taluk":"Tiruppur","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"624614","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624302","Taluk":"NA","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624702","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624212","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624003","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624101","Taluk":"Kodaikanal","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624709","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624710","Taluk":"NA","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624303","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624620","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624703","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624613","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624302","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624612","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624206","Taluk":"NA","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624622","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624201","Taluk":"Nilakkottai","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624219","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624620","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624621","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624001","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624308","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624617","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624616","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624619","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624701","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624101","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624401","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624304","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624801","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624211","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624005","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624610","Taluk":"NA","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624202","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624002","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624708","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624802","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624402","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624615","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624712","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624301","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624612","Taluk":"Oddanchatram","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624204","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624711","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624705","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624004","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624702","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624202","Taluk":"Kodaikanal","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624704","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624206","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624710","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624206","Taluk":"Nilakkottai","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624201","Taluk":"Nilakottai","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624216","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624306","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624216","Taluk":"Kodaikanal","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624802","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624614","Taluk":"Oddanchatram","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624215","Taluk":"Nilakkottai","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624703","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624615","Taluk":"Palani","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624401","Taluk":"Natham","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624212","Taluk":"Kodaikanal","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624103","Taluk":"Kodaikanal","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624616","Taluk":"Oddanchatram","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624617","Taluk":"Oddanchatram","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624403","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624103","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624618","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624215","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624801","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624403","Taluk":"Natham","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624005","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624706","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624706","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624220","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624707","Taluk":"Nilakkottai","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624210","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624402","Taluk":"Natham","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624307","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624618","Taluk":"Palani","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624208","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624619","Taluk":"Oddanchatram","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624707","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624601","Taluk":"Palani","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624601","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624610","Taluk":"Dindigul","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624622","Taluk":"Dindigul","Districtname":"Coimbatore","statename":"TAMIL NADU"},
    {"pincode":"624211","Taluk":"NA","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624704","Taluk":"Oddanchatram","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624220","Taluk":"Nilakkottai","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624708","Taluk":"Nilakkottai","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624621","Taluk":"Palani","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"624613","Taluk":"Palani","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"629153","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629701","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629167","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629170","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629159","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629101","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629802","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629801","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629204","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629201","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629851","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629168","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629401","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629301","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629151","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629851","Taluk":"Thovalai","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629177","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629704","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629177","Taluk":"NA","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629252","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629401","Taluk":"Agasteeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629202","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629803","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629852","Taluk":"Thovala","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629001","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629166","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629804","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629301","Taluk":"Thovalai","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629177","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629161","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629152","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629151","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629003","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629251","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629501","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629002","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629171","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629193","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629901","Taluk":"Thovalai","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629158","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629402","Taluk":"NA","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629004","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629160","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629203","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629502","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629165","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629162","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629852","Taluk":"Thovalai","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629401","Taluk":"Thovala","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629810","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629154","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629169","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629155","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629702","Taluk":"Agasteeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629156","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629809","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629157","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629801","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629174","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629102","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629703","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629702","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629403","Taluk":"Agasteeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629180","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629164","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629402","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629163","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629302","Taluk":"Thovala","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629178","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629851","Taluk":"Thovala","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629402","Taluk":"Agasteeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629403","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629601","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629602","Taluk":"Agastheeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629152","Taluk":"Thovala","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629172","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629175","Taluk":"Kalkulam","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629173","Taluk":"NA","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629601","Taluk":"Agasteeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629176","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629704","Taluk":"Agasteeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629901","Taluk":"Thovala","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629602","Taluk":"Agasteeswaram","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629173","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629302","Taluk":"Thovalai","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629179","Taluk":"Vilavancode","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"629164","Taluk":"NA","Districtname":"Kanyakumari","statename":"TAMIL NADU"},
    {"pincode":"630201","Taluk":"Tiruppathur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630107","Taluk":"Karaikudi-sakkottai Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630003","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630307","Taluk":"Karaikudi-kallal Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630301","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630303","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630202","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630207","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630101","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630205","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630102","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630501","Taluk":"Singampunari Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630302","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630005","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630309","Taluk":"Tiruppattur-singampunariblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630212","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630108","Taluk":"Karaikudi-sakkottaiblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630106","Taluk":"Karaikudi -sakkottai Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630501","Taluk":"Tiruppattur-singampunari Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630001","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630306","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630103","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630314","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630104","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630104","Taluk":"Karaikudi-sakkottai Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630502","Taluk":"Tiruppattur-singampunariblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630002","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630309","Taluk":"Tiruppattur-singampunari Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630410","Taluk":"Tiruppattur-singampunariblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630312","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630405","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630206","Taluk":"Karaikudi-kallal Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630105","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630106","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630307","Taluk":"Karaikudi-kallalblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630105","Taluk":"Karaikudi-sakkottai Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630410","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630309","Taluk":"Tiruppattur-","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630312","Taluk":"Devakottai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"630103","Taluk":"Karaikudi-sakkottai Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630208","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630107","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630307","Taluk":"Karaikudi -kallal Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630311","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630108","Taluk":"Karaikkudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630108","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630502","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630207","Taluk":"Tiruppattur- Kallal Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630501","Taluk":"Tiruppattur-singampunariblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630410","Taluk":"Tiruppattur-singamounariblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630107","Taluk":"Karaikudi--sakkottai Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630501","Taluk":"Tirppattur-singamounari Block","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630201","Taluk":"Tiruppattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630410","Taluk":"Tiruppattur -singsmpunariblock","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"628718","Taluk":"Otapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627861","Taluk":"Virakeralampudur","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627803","Taluk":"Shenkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627808","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628712","Taluk":"Otapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627852","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628714","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628721","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627719","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627754","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628503","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627851","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627862","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627859","Taluk":"Virakeralampudur","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628901","Taluk":"Vilathikulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627758","Taluk":"Sivigiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627757","Taluk":"Sivagiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628952","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627760","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628904","Taluk":"Vilathikulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628502","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627808","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627814","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627853","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628502","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627855","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627805","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627754","Taluk":"Sankarankoil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627953","Taluk":"Virakeralampudur","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627751","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628907","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628905","Taluk":"Vilathikulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628552","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628902","Taluk":"Ettayapuram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627855","Taluk":"Sivigiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628502","Taluk":"Sankarankoil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627951","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627802","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627951","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628904","Taluk":"Ettayapuram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627764","Taluk":"Sivagiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627803","Taluk":"Sengottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628712","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627761","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627760","Taluk":"Sivagiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627857","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627804","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628716","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627713","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628720","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628552","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628902","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628907","Taluk":"Vilathikulam ( Kol)","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628721","Taluk":"Sankarankovil","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627861","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627803","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628905","Taluk":"Vilathikulam (kol)","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627807","Taluk":"Sengottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627753","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627813","Taluk":"Sengottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627860","Taluk":"Virakeralampudur","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628721","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627854","Taluk":"Virakeralampudur","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627854","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628908","Taluk":"Ettayapuram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627953","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627806","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628903","Taluk":"Vilathikulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628907","Taluk":"Tuticorin","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627814","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627953","Taluk":"Sankarankoil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628501","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627759","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628722","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627755","Taluk":"Sivigiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628907","Taluk":"Ettayapuram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627818","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627860","Taluk":"Virakerlamapudur","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628722","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627809","Taluk":"Shenkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627808","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627755","Taluk":"Sivagiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627755","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628952","Taluk":"Tuticorin","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628718","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627809","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628906","Taluk":"Vilathikulam (kol)","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627809","Taluk":"Sengottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627856","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628905","Taluk":"Ettayapuram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627855","Taluk":"Sivagiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627855","Taluk":"Tirunelveli","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628907","Taluk":"Vilathikulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627755","Taluk":"NA","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627856","Taluk":"Sengottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627756","Taluk":"NA","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627756","Taluk":"Sankarankovil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627809","Taluk":"NA","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627753","Taluk":"Sankarankoil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627713","Taluk":"Virudhunagar","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627858","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627811","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627757","Taluk":"Sivigiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627857","Taluk":"Sankarankoil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627857","Taluk":"Sivagiri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627719","Taluk":"Sankarankoil","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628907","Taluk":"Vilathikulam (kol)","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627812","Taluk":"Sengottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627812","Taluk":"Shenkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628907","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628903","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627857","Taluk":"Tenkasi","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628552","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"627806","Taluk":"Virakeralampudur","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627758","Taluk":"Kovilpatti","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"625706","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625514","Taluk":"Thirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625501","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625704","Taluk":"Thirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625301","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625706","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625003","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625301","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625109","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625704","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625017","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625020","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625218","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625009","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625009","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625016","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625106","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625105","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625104","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625108","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625008","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625701","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625012","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625221","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625207","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625103","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625501","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625014","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625503","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625514","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625514","Taluk":"Usilampatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625707","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625122","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625703","Taluk":"Peraiyur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625007","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625016","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625019","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625708","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625016","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625005","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625201","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625205","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625011","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625702","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625102","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625701","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625101","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625234","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625207","Taluk":"Thirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625008","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625107","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625234","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625001","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625514","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625703","Taluk":"Tirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625702","Taluk":"Peraiyur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625022","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625018","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625707","Taluk":"Thirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625002","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625020","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625001","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625023","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625104","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625002","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625101","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625501","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625102","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625021","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625009","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625006","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625402","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625004","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625110","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625007","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625019","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625107","Taluk":"Melur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625402","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625214","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625708","Taluk":"Peraiyur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625022","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625110","Taluk":"Madurai North","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625015","Taluk":"Madurai South","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625706","Taluk":"NA","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625701","Taluk":"Thirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625706","Taluk":"Thirumangalam","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625707","Taluk":"Tirumangalam Taluk","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625001","Taluk":"Vadipatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"623601","Taluk":"Kamudhi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623502","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623521","Taluk":"Rameswaram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623705","Taluk":"Paramakudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623512","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623712","Taluk":"Mudukulathur","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623701","Taluk":"Paramakudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623401","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623308","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623407","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623703","Taluk":"Kadaladi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623527","Taluk":"Paramakudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623529","Taluk":"Rameswaram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623525","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623538","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623513","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623707","Taluk":"Paramakudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623402","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623704","Taluk":"Mudukulathur","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623514","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623533","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623605","Taluk":"Kamudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623566","Taluk":"Kadaladi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623515","Taluk":"Kadaladi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623705","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623115","Taluk":"Kamudhi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623516","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623711","Taluk":"Mudukulathur","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623523","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623401","Taluk":"Tiruvadanai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"623536","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623608","Taluk":"Paramakudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623603","Taluk":"Kamudhi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623517","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623135","Taluk":"Kadaladi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623403","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623402","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"623504","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623603","Taluk":"Kamuthi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623530","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623528","Taluk":"Kadaladi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623706","Taluk":"Paramakudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623115","Taluk":"Kamuthi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623701","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"623605","Taluk":"Kamudhi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623531","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623604","Taluk":"Kamuthi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623409","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623120","Taluk":"Kadaladi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623532","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623519","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623518","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623534","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623520","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623406","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623601","Taluk":"Mudukulathur","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623601","Taluk":"Kamudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623404","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623601","Taluk":"Kamuthi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623537","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623315","Taluk":"Tiruvadanai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623526","Taluk":"Rameswaram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623402","Taluk":"Devakottai","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623604","Taluk":"Kamudhi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623521","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623522","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623503","Taluk":"NA","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623708","Taluk":"Mudukulathur","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623704","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623524","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623501","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623503","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623806","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623708","Taluk":"Kamuthi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623711","Taluk":"Kadaladi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623712","Taluk":"Paramakudi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623605","Taluk":"Kamuthi","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"623711","Taluk":"Ramanathapuram","Districtname":"Ramanathapuram","statename":"TAMIL NADU"},
    {"pincode":"630321","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630611","Taluk":"Manamadurai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630305","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630713","Taluk":"Ilayangudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630553","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630556","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630211","Taluk":"Tirupattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630612","Taluk":"Manamadurai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630551","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630566","Taluk":"Tirupattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630702","Taluk":"Ilayangudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630211","Taluk":"Tirupathur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630562","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630210","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630561","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630609","Taluk":"Manamadurai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630313","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630602","Taluk":"Manamadurai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630709","Taluk":"Ilayangudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630710","Taluk":"Ilayangudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630555","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630557","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630305","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630559","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630203","Taluk":"Tirupattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630204","Taluk":"Tirupattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630606","Taluk":"Manamadurai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630610","Taluk":"Manamadurai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630408","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630552","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630411","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630554","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630204","Taluk":"Tirupathur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630558","Taluk":"Sivaganga","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630612","Taluk":"Tiruppuvanam","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630313","Taluk":"Karaikudi","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630210","Taluk":"Tirupattur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630559","Taluk":"Manamadurai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"630321","Taluk":"Devakottai","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"625602","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625605","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625523","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625527","Taluk":"Peraiyur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625531","Taluk":"Theni","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625512","Taluk":"Andipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625582","Taluk":"Bodinayakanur","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625526","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625518","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625515","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625532","Taluk":"Usilampatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625517","Taluk":"Andipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625705","Taluk":"Peraiyur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625537","Taluk":"Usilampatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625528","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625536","Taluk":"Andipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625513","Taluk":"Bodinayakanur","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625556","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625527","Taluk":"Peraiyur","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625516","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625520","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625535","Taluk":"Peraiyur","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625604","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625203","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625517","Taluk":"Aundipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625534","Taluk":"Bodinayakanur","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625562","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625533","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625519","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625520","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625603","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625579","Taluk":"Aundipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625529","Taluk":"Usilampatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625521","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625534","Taluk":"Theni","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625522","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625579","Taluk":"Andipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625530","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625523","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625535","Taluk":"Usilampatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625515","Taluk":"Aundipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625524","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625528","Taluk":"Bodinayakanur","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625540","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625705","Taluk":"Usilampatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625601","Taluk":"Periyakulam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625525","Taluk":"Uthamapalayam","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625527","Taluk":"Usilampatti","Districtname":"Madurai","statename":"TAMIL NADU"},
    {"pincode":"625515","Taluk":"Theni","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625512","Taluk":"Aundipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625536","Taluk":"Aundipatti","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"625705","Taluk":"Peraiyur","Districtname":"Theni","statename":"TAMIL NADU"},
    {"pincode":"627113","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627151","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627117","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627413","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627416","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627428","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627421","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627652","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627201","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627354","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627412","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627425","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627401","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627651","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627654","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627423","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627120","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627426","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627105","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627418","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627358","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627108","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627351","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627414","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627111","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627501","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627355","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627101","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627116","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627502","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627102","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627112","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627110","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627106","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627103","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627008","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627352","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627451","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627424","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627602","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627104","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627010","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627652","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627114","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627007","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627352","Taluk":"NA","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627011","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627415","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627202","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627001","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627109","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627657","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627127","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627659","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627417","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627357","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627152","Taluk":"Nenguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627453","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627356","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627152","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627351","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627353","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627007","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627427","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627107","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627106","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627002","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627006","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627011","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627133","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627420","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627413","Taluk":"NA","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627012","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627005","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627452","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627423","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627601","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627152","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627356","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627115","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627604","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627602","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627359","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627002","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627422","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627110","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627151","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627358","Taluk":"Tirunelvel;i","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627118","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627152","Taluk":"Palayankottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627412","Taluk":"NA","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627101","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627353","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627115","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627009","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627004","Taluk":"Tirunelveli","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627603","Taluk":"Ambasamudram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627131","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627003","Taluk":"Palayamkottai","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627415","Taluk":"Alangulam","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627119","Taluk":"Nanguneri","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"627118","Taluk":"Radhapuram","Districtname":"Tirunelveli","statename":"TAMIL NADU"},
    {"pincode":"628217","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628152","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628401","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628303","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628703","Taluk":"Sathankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628002","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628704","Taluk":"Sathankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628206","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628619","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628612","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628215","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628701","Taluk":"Sathankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628201","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628210","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628701","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628809","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628702","Taluk":"Sathankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628851","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628621","Taluk":"Rivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628002","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628802","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628202","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628613","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628621","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628005","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628151","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628003","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628614","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628622","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628701","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628008","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628617","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628104","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628001","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628301","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628105","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628801","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628007","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628656","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628211","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628251","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628402","Taluk":"Otapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628218","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628656","Taluk":"Sathankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628614","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628203","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628252","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628202","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628301","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628615","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628613","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628618","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628204","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628205","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628304","Taluk":"Tuticorin","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628302","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628653","Taluk":"Sathankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628704","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628653","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628103","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628101","Taluk":"Tuticorin","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628801","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628152","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628103","Taluk":"Tuticorin","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628623","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628207","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628213","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628008","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628208","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628209","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628103","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628302","Taluk":"Ottapidaam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628002","Taluk":"Tuticorin","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628702","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628102","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628102","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628616","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628005","Taluk":"Tuticorin","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628005","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628402","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628751","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628616","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628617","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628618","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628302","Taluk":"Otapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628703","Taluk":"Satankulam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628212","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628751","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628301","Taluk":"Ottapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628620","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628620","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628101","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628703","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628752","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628219","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628653","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628103","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628656","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628621","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628229","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628251","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628753","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628601","Taluk":"Srivaikuntam","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628105","Taluk":"Otapidaram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628304","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628622","Taluk":"NA","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628006","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628004","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628001","Taluk":"Thoothukkudi","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"628216","Taluk":"Tiruchendur","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"626203","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626141","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626137","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626129","Taluk":"Aruppukkottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626607","Taluk":"Tiruchuli","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626109","Taluk":"Kariapatti","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626130","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626127","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626106","Taluk":"Kariapatti","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626112","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626005","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626129","Taluk":"Tiruchuli","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626130","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626124","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626189","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626204","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626101","Taluk":"Aruppukkottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626101","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626135","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626115","Taluk":"Kariapatti","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626138","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626142","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626102","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626002","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626134","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626134","Taluk":"Aruppukkottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626202","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626108","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626121","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626139","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626188","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626612","Taluk":"Tiruchuli","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626131","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626201","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626103","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626136","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626140","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626149","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626125","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626003","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626114","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626105","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626104","Taluk":"Kariapatti","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626001","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626133","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626149","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626205","Taluk":"Ettayapuram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"626107","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626001","Taluk":"Vriudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626113","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626004","Taluk":"NA","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626133","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626138","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626126","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626118","Taluk":"Aruppukkottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626004","Taluk":"Aruppukkottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626141","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626124","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626118","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626138","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626607","Taluk":"Kariapatti","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626202","Taluk":"Ettayapuram","Districtname":"Tuticorin","statename":"TAMIL NADU"},
    {"pincode":"626111","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626110","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626004","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626106","Taluk":"NA","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626205","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626119","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626135","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626102","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626102","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626137","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626129","Taluk":"NA","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626004","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626201","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626134","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626103","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626109","Taluk":"Virudhunagar","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626125","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626117","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626105","Taluk":"Aruppukkottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626204","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626128","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626130","Taluk":"Sattur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626116","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626132","Taluk":"Srivilliputhur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626122","Taluk":"Rajapalayam","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626123","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626141","Taluk":"Srivilliputtur","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626140","Taluk":"Sivakasi","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626112","Taluk":"Aruppukkottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"626109","Taluk":"Aruppukottai","Districtname":"Virudhunagar","statename":"TAMIL NADU"},
    {"pincode":"607802","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608801","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607302","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608702","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608502","Taluk":"NA","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607205","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607003","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607101","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607109","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608801","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608305","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607301","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608704","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608401","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607802","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607101","Taluk":"Panruti","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607106","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608002","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608301","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607103","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608502","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608401","Taluk":"Chidabamram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608306","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608201","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608601","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608501","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608704","Taluk":"Kattmannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607102","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607108","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607801","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607803","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607807","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608302","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608102","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608602","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607005","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608304","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608001","Taluk":"NA","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608001","Taluk":"Chidambaram North","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607001","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608303","Taluk":"NA","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607104","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607308","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607105","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607006","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607805","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608701","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607303","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608703","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607112","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608303","Taluk":"Chidambaram","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608302","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608701","Taluk":"Kattummannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608303","Taluk":"Kattumannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607004","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607105","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607805","Taluk":"Panruti","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607401","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608701","Taluk":"NA","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608701","Taluk":"Kattumanarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"607301","Taluk":"Panruti","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608703","Taluk":"Kattumanarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608701","Taluk":"Kattumannarkioil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608701","Taluk":"Kattmannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608002","Taluk":"NA","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608703","Taluk":"Kattumannarkoil","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"607002","Taluk":"Cuddalore","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"608702","Taluk":"Kattmannarkoil","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"639207","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621308","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621311","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621314","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621310","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621313","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621306","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621307","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639203","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621312","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639202","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"639008","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639003","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639201","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639201","Taluk":"Dharapuram","Districtname":"Erode","statename":"TAMIL NADU"},
    {"pincode":"639111","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621307","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639118","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639202","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621311","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639206","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639120","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639105","Taluk":"Krishnarayapuram","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621301","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621312","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639113","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621302","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621302","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639007","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621310","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639004","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639107","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621314","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621315","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639110","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639205","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621306","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639006","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639136","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621301","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621313","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621305","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621315","Taluk":"Manapparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639108","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639001","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639105","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639108","Taluk":"Krishnarayapuram","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639119","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639005","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639102","Taluk":"Krishnarayapuram","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621305","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639114","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621308","Taluk":"Tiruppathur","Districtname":"Sivaganga","statename":"TAMIL NADU"},
    {"pincode":"639104","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639104","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639120","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639116","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639120","Taluk":"Krishnarayapuram","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639110","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639108","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639108","Taluk":"Kirishnarayapuram","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621305","Taluk":"Illupur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"639109","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639002","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639119","Taluk":"Krishnarayapuram","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639117","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639109","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621308","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"639119","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621306","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639002","Taluk":"Karur","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639136","Taluk":"Aravakurichi","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"621312","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"621315","Taluk":"Kulithalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"639203","Taluk":"Vedasandur","Districtname":"Dindigul","statename":"TAMIL NADU"},
    {"pincode":"621311","Taluk":"Manaparai","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639105","Taluk":"Kulittalai","Districtname":"Karur","statename":"TAMIL NADU"},
    {"pincode":"613705","Taluk":"Koradacherry","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612101","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612101","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612601","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609801","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612103","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612202","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612105","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612104","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612401","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612201","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612106","Taluk":"Kuttalam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612603","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612501","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609204","Taluk":"Tirupanandal","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612503","Taluk":"Tirupanandal","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613702","Taluk":"Needamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612101","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612204","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612302","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613705","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612703","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612702","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609805","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612603","Taluk":"Kodavasal","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612303","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609501","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609805","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"613703","Taluk":"Koradacherry","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612605","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609501","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612106","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609811","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612503","Taluk":"Tiruppanandal","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609807","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612604","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609804","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612203","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609807","Taluk":"Tirupanandal","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612610","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613703","Taluk":"Needamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612201","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612602","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612504","Taluk":"Tirupanandal","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613703","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612001","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612602","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609801","Taluk":"NA","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612502","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609806","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612402","Taluk":"Valangaiman","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612504","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612002","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612502","Taluk":"Tirupanandal","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612202","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609802","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610107","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612203","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612102","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609807","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612102","Taluk":"Kuttalam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612604","Taluk":"Kodavasla","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609802","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613705","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612201","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612503","Taluk":"Tiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609808","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609804","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612402","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612605","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612102","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609204","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612504","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612103","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"609810","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609803","Taluk":"Kuttalam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612104","Taluk":"Tiruvidamarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613702","Taluk":"Tiruvarur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"612105","Taluk":"Thiruvidaimarudur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612201","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612605","Taluk":"Valangaiman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609403","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609101","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609503","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609301","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609405","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609118","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609503","Taluk":"Nannilam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609102","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609103","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609109","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609302","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609111","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609203","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609309","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609117","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609303","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609312","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609307","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609003","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609001","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609115","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609401","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609116","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609108","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609117","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609201","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609308","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609104","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609301","Taluk":"Tarangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609403","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609304","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609112","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609105","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609107","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609106","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609402","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609202","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609314","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609113","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609202","Taluk":"Mayiaduthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609404","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609313","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609114","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609305","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609306","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609107","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609105","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609404","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609205","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609503","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609112","Taluk":"Mayiladuthurai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609310","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609205","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609110","Taluk":"Sirkali Tk","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609201","Taluk":"Sirkali","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609311","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609310","Taluk":"Tharangampadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609314","Taluk":"Tharangambadi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610105","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611101","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609703","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611106","Taluk":"NA","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609604","Taluk":"Neravy Commune Panchayat","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"609502","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609703","Taluk":"NA","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610203","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609604","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610106","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611104","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609601","Taluk":"Karaikal","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"610201","Taluk":"Tiruturipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613701","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610101","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610106","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611102","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611101","Taluk":"Thiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611105","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610103","Taluk":"NA","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611108","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610102","Taluk":"Mannarkudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611102","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610001","Taluk":"Thiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609602","Taluk":"NA","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"611002","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610104","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609701","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611105","Taluk":"NA","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611109","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610102","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609702","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610204","Taluk":"Tirukuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610201","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611101","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"613704","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611110","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610101","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609605","Taluk":"NA","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"609607","Taluk":"Thirunallar Commune Panchayat","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"611103","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610205","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610003","Taluk":"Thiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610103","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610109","Taluk":"Thiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610207","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611112","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610207","Taluk":"Tiurkuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609608","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610202","Taluk":"Tiruturipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609609","Taluk":"Kottucherry Commune Panchayat","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"613704","Taluk":"Kodavasal","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609603","Taluk":"Nedungadu Commune Panchayat","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"611103","Taluk":"Tirukuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611106","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610202","Taluk":"Thiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610101","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610201","Taluk":"Tirukkuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611002","Taluk":"NA","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611001","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611003","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609601","Taluk":"Thirunallar Commune Panchayat","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"611112","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609603","Taluk":"Karaikal","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"611002","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"609504","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609704","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610203","Taluk":"Thiruthuraipoondi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611111","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611112","Taluk":"NA","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610109","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609504","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"611109","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610203","Taluk":"Tirukuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610201","Taluk":"Thirukkuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610101","Taluk":"Thiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609606","Taluk":"Thirumalairayan Pattinam Commune Panchayat","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"611111","Taluk":"Nagapattinam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610004","Taluk":"Thiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610202","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609603","Taluk":"Nannilam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609606","Taluk":"Karaikal","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"609607","Taluk":"Karaikal","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"610205","Taluk":"NA","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610004","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610001","Taluk":"Tiruvarur","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611111","Taluk":"NA","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610102","Taluk":"Needamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"611112","Taluk":"Kilvelur","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"610203","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"609607","Taluk":"Neravy Commune Panchayat","Districtname":"Karaikal","statename":"PONDICHERRY"},
    {"pincode":"614602","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614707","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614701","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614701","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614810","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614614","Taluk":"Orattanad","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614615","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614723","Taluk":"Peravurni","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614901","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614704","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614704","Taluk":"Thiruthuraipoondi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614626","Taluk":"Orattanad","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614623","Taluk":"Peravurni","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614612","Taluk":"Peravurani","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614903","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614714","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614702","Taluk":"Thiruthuraipoondi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614809","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614711","Taluk":"Thirukkuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614902","Taluk":"Gandarvakkottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614906","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614613","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614806","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614711","Taluk":"Tiruturaipundi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614904","Taluk":"Orattanad","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614803","Taluk":"Peravurni","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614626","Taluk":"Orathanadu","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614902","Taluk":"Orattanad","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614702","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614715","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614628","Taluk":"Peravurni","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614703","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614738","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614715","Taluk":"Thiruthuraipoondi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614625","Taluk":"Orattanad","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614804","Taluk":"Peravurni","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614809","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614602","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614802","Taluk":"Peravurni","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614902","Taluk":"Orathanadu","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614804","Taluk":"Peravurani","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614904","Taluk":"Orathanadu","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614613","Taluk":"Patukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614808","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614716","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614704","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614904","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614807","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614628","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614711","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614702","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614706","Taluk":"Thiruthuraipoondi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614903","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614723","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614612","Taluk":"Peravurni","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614712","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614613","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614704","Taluk":"Tiruturaipundi","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614714","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614903","Taluk":"Pattukottai","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614902","Taluk":"Orattanad","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614625","Taluk":"Orathanadu","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614808","Taluk":"Vedarniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614808","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614601","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614706","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614806","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614712","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614809","Taluk":"Vedarniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614711","Taluk":"Vedaranyam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614716","Taluk":"Tiruturaipundi","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614905","Taluk":"Orathanadu","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614905","Taluk":"Orattanad","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614713","Taluk":"Thiruthuraipoondi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614713","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614614","Taluk":"Orathanadu","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614703","Taluk":"Thiruthuraipoondi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614712","Taluk":"Thirukkuvalai","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614810","Taluk":"Vedaraniam","Districtname":"Nagapattinam","statename":"TAMIL NADU"},
    {"pincode":"614628","Taluk":"Orathanadu","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614902","Taluk":"Pattukkottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"622302","Taluk":"Alangudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614629","Taluk":"Avudayarkoil","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622203","Taluk":"Gandarvakottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622003","Taluk":"Pudukkottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"613301","Taluk":"Gandarvakkottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622301","Taluk":"Alangudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622401","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614618","Taluk":"Avudayarkoil","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614616","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614618","Taluk":"Avadaiyarkoil","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614805","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622005","Taluk":"Pudukkottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622504","Taluk":"Kulathur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614617","Taluk":"Aranthangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622304","Taluk":"Alangudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622101","Taluk":"Iluppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614616","Taluk":"Aranthangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622402","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614621","Taluk":"Manamelkudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614801","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614622","Taluk":"Aranthangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622201","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622506","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622503","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614630","Taluk":"Aranthangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614624","Taluk":"Alangudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622515","Taluk":"Kulathur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614801","Taluk":"Aranangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614619","Taluk":"Manamelkudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622001","Taluk":"Pudukkottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614630","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622501","Taluk":"Kulathur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614622","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622101","Taluk":"Illuppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614616","Taluk":"Aratangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614620","Taluk":"Manamelkudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622102","Taluk":"Illuppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"621316","Taluk":"Iluppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"613301","Taluk":"Gandarvakottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622412","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622505","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622002","Taluk":"NA","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614629","Taluk":"Avadaiyarkoil","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622204","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622303","Taluk":"Alangudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622502","Taluk":"Kulathur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622209","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622409","Taluk":"Tirumayaam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614619","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622204","Taluk":"Avudayarkoil","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622503","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614624","Taluk":"Aranthangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622411","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622202","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622209","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622201","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"621316","Taluk":"Illuppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622401","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622404","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622104","Taluk":"Annavasal","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622507","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622409","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622102","Taluk":"Iluppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622403","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622103","Taluk":"Iluppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622004","Taluk":"Pudukkottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622404","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622401","Taluk":"Tirumayaaam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622422","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622505","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614629","Taluk":"Aranthangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622002","Taluk":"Pudukkottai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622422","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"621316","Taluk":"Kulathur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622506","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622407","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622004","Taluk":"Pudukkottai--","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"613301","Taluk":"Gandarvakotai","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622407","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622103","Taluk":"Illuppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622501","Taluk":"Illuppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622409","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614805","Taluk":"Manamelkudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622403","Taluk":"Tirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614624","Taluk":"Arantangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622412","Taluk":"Thirumayam","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622202","Taluk":"Aranthangi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"622104","Taluk":"Illuppur","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"614617","Taluk":"Manamelkudi","Districtname":"Pudukkottai","statename":"TAMIL NADU"},
    {"pincode":"621211","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621103","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621007","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621118","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621014","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621708","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621108","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621210","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621209","Taluk":"Thottiam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621005","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621107","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621003","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621109","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"620006","Taluk":"Srirangam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621208","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621101","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621204","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621717","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621102","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621219","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621214","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621212","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621215","Taluk":"Thottiam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621203","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621115","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621113","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621202","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621003","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621001","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621203","Taluk":"Thottiyam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621216","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621104","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621115","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621104","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621207","Taluk":"Thottiam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621213","Taluk":"Manachanalloor","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621206","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621112","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621133","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"620005","Taluk":"Srirangam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621106","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621008","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621105","Taluk":"Trichy","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621205","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621110","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621716","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621117","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621008","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621014","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621206","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621114","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621109","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621009","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621004","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621002","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621002","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621105","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621012","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621004","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621005","Taluk":"Manachanalloor","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621116","Taluk":"Veppanthattai","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621211","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621006","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621112","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621215","Taluk":"Thottiyam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621004","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621011","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621001","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621012","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621716","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621717","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621008","Taluk":"Thottiam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621105","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621111","Taluk":"Trichy","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621217","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621006","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621104","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621110","Taluk":"Musiri","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621009","Taluk":"Manachanalloor","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621118","Taluk":"Turaiyur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621101","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621213","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621111","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621112","Taluk":"Manachanalloor","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621220","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621215","Taluk":"Namakkal","Districtname":"Namakkal","statename":"TAMIL NADU"},
    {"pincode":"621113","Taluk":"Kunnam","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621118","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621209","Taluk":"Thottiyam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621009","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621011","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621010","Taluk":"Turaiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621010","Taluk":"Thuraiyur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621219","Taluk":"Perambalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"621204","Taluk":"Manachanallur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621110","Taluk":"Gangavalli","Districtname":"Salem","statename":"TAMIL NADU"},
    {"pincode":"613402","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614103","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612301","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614403","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612804","Taluk":"Valangaman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614717","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614303","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613101","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613602","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613601","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612801","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614018","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614401","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613204","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613003","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614019","Taluk":"Orattanad","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614404","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613001","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613205","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613504","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614402","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614016","Taluk":"NA","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612802","Taluk":"Valangaman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612701","Taluk":"Valangaman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614211","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614201","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613403","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613009","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612803","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614204","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613104","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614017","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614202","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613007","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614001","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"610206","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613204","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614302","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614013","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614203","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613005","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613102","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614708","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613502","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613102","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613003","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612803","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612802","Taluk":"Valangaman","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613002","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613602","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613201","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613202","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614017","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614705","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614014","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614019","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614015","Taluk":"Pattukottai","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613103","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614208","Taluk":"Valangaman","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614402","Taluk":"Valangaman","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614015","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614101","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614101","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614301","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613105","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614403","Taluk":"Needamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614102","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613501","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613303","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"612801","Taluk":"Valangaman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614102","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614302","Taluk":"Valangaman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614206","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614710","Taluk":"Tiruturaipundi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614404","Taluk":"Valangaman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613004","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613006","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613203","Taluk":"Thiruvaiyaru","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614206","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"610206","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614014","Taluk":"NA","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614019","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614710","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614208","Taluk":"Valangaman","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614210","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614016","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614404","Taluk":"Needamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"612804","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614020","Taluk":"Mannargudi","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614205","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613501","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614013","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"614207","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614014","Taluk":"Nidamangalam","Districtname":"Tiruvarur","statename":"TAMIL NADU"},
    {"pincode":"613503","Taluk":"Papanasam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"614208","Taluk":"Kumbakonam","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613010","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613008","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"613401","Taluk":"Thanjavur","Districtname":"Thanjavur","statename":"TAMIL NADU"},
    {"pincode":"620102","Taluk":"Srirangam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621719","Taluk":"Sendurai","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621714","Taluk":"Sendurai","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620014","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621712","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"608901","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621715","Taluk":"Lalgudi","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621722","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621711","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621702","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"612903","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621719","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620011","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621713","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620101","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621651","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621701","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620004","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"612902","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621718","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"639101","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621801","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621709","Taluk":"Sendurai","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621703","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621802","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621803","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620026","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621851","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620013","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621704","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621729","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620010","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620009","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621707","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620015","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621705","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621709","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"612901","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620024","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620001","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621730","Taluk":"NA","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620008","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620012","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621806","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621804","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621218","Taluk":"Lalgudi","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621803","Taluk":"Jayamkondcholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621218","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639103","Taluk":"Srirangam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621713","Taluk":"Ariyalur","Districtname":"Perambalur","statename":"TAMIL NADU"},
    {"pincode":"620007","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620025","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"612904","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621806","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621851","Taluk":"Lalgudi","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"612903","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620020","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621802","Taluk":"NA","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"639101","Taluk":"Srirangam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620021","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"612904","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621653","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621652","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621651","Taluk":"Ariyalur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621701","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621706","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"608901","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620023","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621802","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621710","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621805","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621804","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"612902","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"639115","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621715","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"639103","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621714","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620102","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"612901","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621601","Taluk":"Lalgudi","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621652","Taluk":"Ariyalur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621715","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621706","Taluk":"Ariyalur","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620016","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621710","Taluk":"Sendurai","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621651","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620019","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621801","Taluk":"Udayarpalayam","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621805","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"639112","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621653","Taluk":"Lalgudi","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"639112","Taluk":"Srirangam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621711","Taluk":"NA","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620017","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620002","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620003","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621730","Taluk":"Sendurai","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621730","Taluk":"Ariyalur","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"639115","Taluk":"Srirangam","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"612904","Taluk":"Jayamkondacholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"620018","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"620022","Taluk":"Tiruchirappalli","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"621804","Taluk":"Jayamkondcholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"621803","Taluk":"Jayamkondocholapuram","Districtname":"Ariyalur","statename":"TAMIL NADU"},
    {"pincode":"607201","Taluk":"Ulundurpettai","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606304","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606102","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605758","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606213","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606106","Taluk":"Tittagudi","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606115","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606108","Taluk":"Tittagudi","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605701","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606303","Taluk":"Tittagudi","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606208","Taluk":"Sankarapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606201","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606204","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607209","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605803","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606107","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606301","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605752","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606209","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606401","Taluk":"Sankarapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607107","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605755","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605801","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605751","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606402","Taluk":"Sankarapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605766","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606305","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606111","Taluk":"Tittakudi","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605702","Taluk":"Sankarapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606003","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606110","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606207","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606104","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605754","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606103","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606302","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605802","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607202","Taluk":"Ulundurpettai","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607201","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607203","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607204","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606103","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606111","Taluk":"Tittagudi","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606202","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606105","Taluk":"Tittagudi","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606209","Taluk":"Sankarapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606205","Taluk":"Sankarapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606104","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606203","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606105","Taluk":"Tittakudi","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"605756","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607804","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606301","Taluk":"Villupuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607201","Taluk":"Ulundurpet","Districtname":"Tiruchirappalli","statename":"TAMIL NADU"},
    {"pincode":"606206","Taluk":"Kallakurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605759","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606104","Taluk":"Ulundurpettai","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606207","Taluk":"Sankarapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605702","Taluk":"Tiruvannamalai","Districtname":"Tiruvannamalai","statename":"TAMIL NADU"},
    {"pincode":"606208","Taluk":"Sankapuram","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606213","Taluk":"NA","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607204","Taluk":"Ulundurpettai","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606206","Taluk":"Kallakkurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"607202","Taluk":"Ulundurpet","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606107","Taluk":"Ulundurpettai","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606213","Taluk":"Kallakkurichi","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605766","Taluk":"NA","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"605757","Taluk":"Tirukkoyilur","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606209","Taluk":"Chinnasalem Taluk","Districtname":"Villupuram","statename":"TAMIL NADU"},
    {"pincode":"606302","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606001","Taluk":"Virudhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"606001","Taluk":"Vriddhachalam","Districtname":"Cuddalore","statename":"TAMIL NADU"},
    {"pincode":"282001","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283101","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282003","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282004","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283202","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283201","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283102","Taluk":"Kiraoli","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283102","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283201","Taluk":"Etmadpur","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283110","Taluk":"Kiraoli","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283111","Taluk":"Bah","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282007","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283105","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283110","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282007","Taluk":"Sadar","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283124","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282002","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283104","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283126","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283115","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282009","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282006","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283125","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283111","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283123","Taluk":"Bah","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283119","Taluk":"Kheragarh","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283115","Taluk":"Keragarh","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283122","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283202","Taluk":"Etmadpur","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283102","Taluk":"Kheragarh","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283113","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283121","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283115","Taluk":"Kheragarh","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282010","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283113","Taluk":"Bah","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283112","Taluk":"Kheragarh","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283112","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283119","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282006","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283125","Taluk":"Fatehabad","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283114","Taluk":"Bah","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282007","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282005","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283122","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283125","Taluk":"Fatahabad","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283125","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283111","Taluk":"Fatehabad","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283126","Taluk":"Etmadpur","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282006","Taluk":"Etmadpur","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282008","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283122","Taluk":"Kiraoli","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283114","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283102","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283101","Taluk":"Kiraoli","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283119","Taluk":"Kheragah","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283105","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282005","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282009","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283112","Taluk":"Fetehabad","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283123","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283126","Taluk":"Etdmadpur","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283104","Taluk":"Bah","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"282004","Taluk":"Agra","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"283119","Taluk":"Kiraoli","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"202133","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202138","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202002","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204101","Taluk":"NA","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202135","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202002","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204102","Taluk":"NA","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202121","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202140","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202129","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202001","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202122","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204215","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202132","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204215","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"204213","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202282","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202280","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202131","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202134","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202130","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202137","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202143","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204212","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202001","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202128","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202126","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204213","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202129","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202125","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204214","Taluk":"NA","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202150","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204214","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"204216","Taluk":"NA","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202281","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202165","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202145","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202132","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202124","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202130","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202141","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204211","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202142","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202170","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202139","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202127","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204216","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"204211","Taluk":"NA","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202002","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202155","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202123","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202136","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204101","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"204102","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202139","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202124","Taluk":"NA","Districtname":"Agra","statename":"UTTAR PRADESH"},
    {"pincode":"202142","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202140","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202155","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202282","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202146","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202121","Taluk":"Aligarh","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"202136","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"204102","Taluk":"Aligarh","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202280","Taluk":"Atrauli","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"202141","Taluk":"NA","Districtname":"Aligarh","statename":"UTTAR PRADESH"},
    {"pincode":"203002","Taluk":"NA","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203131","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203411","Taluk":"Siyana","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203155","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203398","Taluk":"Anupshaher","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203392","Taluk":"Debai","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203150","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203001","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203405","Taluk":"Siyana","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203395","Taluk":"Shikarpur","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203391","Taluk":"Anupshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203390","Taluk":"Anupshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203131","Taluk":"Khurja","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203393","Taluk":"Debai","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203408","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203132","Taluk":"Khurja","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203202","Taluk":"Goutam Budd Nagar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203129","Taluk":"Khurja","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203398","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203205","Taluk":"Sikarndrabad","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203401","Taluk":"NA","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203412","Taluk":"Siyana","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203409","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203203","Taluk":"Sikandrabad","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203396","Taluk":"Debai","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203402","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203155","Taluk":"Jewer","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203394","Taluk":"Anoop Shahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203403","Taluk":"Siyana","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203402","Taluk":"Siyana","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203397","Taluk":"Debai","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203389","Taluk":"Debai","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203209","Taluk":"Jewer","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203141","Taluk":"Jewer","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203205","Taluk":"Sikandrabad","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203141","Taluk":"Jewer","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203390","Taluk":"Anupshaher","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203407","Taluk":"Siyana","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203390","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203203","Taluk":"Sikndrabdad","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203394","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203399","Taluk":"Jewer","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203201","Taluk":"Sikandrabad","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203135","Taluk":"Jewer","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203002","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203203","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203201","Taluk":"Goutam  Budd  Nagar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203399","Taluk":"Debai","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203398","Taluk":"Anupshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203398","Taluk":"Anupshhar","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203202","Taluk":"Sikandrabad","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203141","Taluk":"Jewar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203206","Taluk":"Sikandrabad","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203394","Taluk":"Anupshashr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203394","Taluk":"Anupshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203394","Taluk":"Sikarpur","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203135","Taluk":"Jewar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203396","Taluk":"Dibai","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203141","Taluk":"Khurja","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203201","Taluk":"Goutam  Budd  Nagar","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203401","Taluk":"Bulandshahr","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203209","Taluk":"Jewar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"203390","Taluk":"Anoup  Shaher","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203001","Taluk":"Shikarpur","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203205","Taluk":"Sikandarabad","Districtname":"Bulandshahr","statename":"UTTAR PRADESH"},
    {"pincode":"203155","Taluk":"Jewar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"207241","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207125","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207001","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207247","Taluk":"Aliganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207250","Taluk":"Aliganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207244","Taluk":"Aliganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207121","Taluk":"NA","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207245","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207121","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207002","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207243","Taluk":"Patiali","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207246","Taluk":"NA","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207301","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207302","Taluk":"Jalesar","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207001","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207401","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207242","Taluk":"Patiali","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207125","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207301","Taluk":"Jaleshar","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207123","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207249","Taluk":"Aliganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207120","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207248","Taluk":"Aliganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207402","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207246","Taluk":"Patiali","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207124","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207301","Taluk":"Jalesher","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207003","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207122","Taluk":"Jalesar","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207247","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207403","Taluk":"Kasganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207302","Taluk":"Jaleasr","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207248","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207001","Taluk":"NA","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207301","Taluk":"Firojabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"207245","Taluk":"NA","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207122","Taluk":"Jalessar","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207302","Taluk":"Jalesasr","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207401","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207244","Taluk":"Patiali","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207302","Taluk":"NA","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207122","Taluk":"Etah","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207001","Taluk":"Aliganj","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207120","Taluk":"NA","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207245","Taluk":"Patiali","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207248","Taluk":"Patiali","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207244","Taluk":"Patiyali","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"207301","Taluk":"Jalesar","Districtname":"Etah","statename":"UTTAR PRADESH"},
    {"pincode":"206253","Taluk":"Saifai","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206241","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206247","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206002","Taluk":"Saifai","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206244","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206120","Taluk":"Bharthana","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206249","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206252","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206131","Taluk":"Etawah","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206121","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206243","Taluk":"NA","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206128","Taluk":"Bharthana","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206001","Taluk":"Etawah","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206243","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206122","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206125","Taluk":"Chakar Nagar","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206127","Taluk":"Chakar Nagar","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206242","Taluk":"Bharthana","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206126","Taluk":"Etawah","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206123","Taluk":"Bharthana","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206124","Taluk":"Bharthana","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206129","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206245","Taluk":"Jaswant Nagar","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206123","Taluk":"Etawah","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206125","Taluk":"Chakarnagar **","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206253","Taluk":"Etawah","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206251","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206246","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206125","Taluk":"Chakarnagar","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206003","Taluk":"Etawah","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206001","Taluk":"Bharthana","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206250","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206001","Taluk":"Jaswant Nagar","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206127","Taluk":"Bharthana","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206248","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206130","Taluk":"Saifai","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206001","Taluk":"Saifai","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206002","Taluk":"Etawah","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206128","Taluk":"Bharthna","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206255","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206121","Taluk":"Auraiy","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206122","Taluk":"Bidhuna","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206001","Taluk":"Chakar Nagar","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206255","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"206242","Taluk":"Bharthna","Districtname":"Etawah","statename":"UTTAR PRADESH"},
    {"pincode":"206249","Taluk":"Auraiya","Districtname":"Auraiya","statename":"UTTAR PRADESH"},
    {"pincode":"284135","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284003","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285130","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285001","Taluk":"Orai","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284302","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285223","Taluk":"Konch","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285201","Taluk":"Konch","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284204","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285129","Taluk":"Madhogarh","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285205","Taluk":"Konch","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285123","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284204","Taluk":"Mauranipur","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284122","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"285203","Taluk":"Kalpi","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284205","Taluk":"Mauranipur","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284419","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284306","Taluk":"Moth","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284303","Taluk":"Moth","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285205","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284406","Taluk":"Mahroni","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284202","Taluk":"Garautha","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285202","Taluk":"Orai","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285121","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284301","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284403","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284401","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284123","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284126","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284304","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285124","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285204","Taluk":"Kalpi","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284301","Taluk":"Jhnsi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284405","Taluk":"Mahroni","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284403","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284404","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284126","Taluk":"Talbehat","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"285122","Taluk":"Orai","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284136","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284202","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284402","Taluk":"Mahroni","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284122","Taluk":"Talbehat","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"285203","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284002","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285001","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284121","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284121","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284123","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284206","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284122","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284124","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284201","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285206","Taluk":"Konch","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285125","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285202","Taluk":"Kalpi","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284127","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285128","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284127","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284120","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285130","Taluk":"Madhogarh","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284206","Taluk":"Mauranipur","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284302","Taluk":"Maruanipur","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284402","Taluk":"Mehroni","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284406","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284128","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284001","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284304","Taluk":"Moth","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285126","Taluk":"Madhogarh","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285001","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285125","Taluk":"Madhogarh","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284501","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284404","Taluk":"Mahroni","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284203","Taluk":"Garautha","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284405","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284302","Taluk":"Mauranipur","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284135","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284124","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284301","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285001","Taluk":"Konch","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285125","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284125","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"285205","Taluk":"Konch","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285126","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285204","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284204","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284136","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284123","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284406","Taluk":"Lalitpur","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"285127","Taluk":"Madhogarh","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284306","Taluk":"Moth","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284305","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284405","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284306","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"284402","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284501","Taluk":"NA","Districtname":"Lalitpur","statename":"UTTAR PRADESH"},
    {"pincode":"284203","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285125","Taluk":"NA","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285127","Taluk":"Jalaun","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284303","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"284201","Taluk":"Jhansi","Districtname":"Jhansi","statename":"UTTAR PRADESH"},
    {"pincode":"285129","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"285202","Taluk":"NA","Districtname":"Jalaun","statename":"UTTAR PRADESH"},
    {"pincode":"283151","Taluk":"Shikohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205263","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205247","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205262","Taluk":"Bhongaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205304","Taluk":"Bhgaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205001","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283203","Taluk":"Firozabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283136","Taluk":"Jasrana","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205001","Taluk":"Bhongaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205265","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205264","Taluk":"Karahal","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205262","Taluk":"Bhogaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205304","Taluk":"Bhongaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283141","Taluk":"Shikohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205264","Taluk":"NA","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205268","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205267","Taluk":"Bhongaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205121","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283204","Taluk":"Firozabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283142","Taluk":"Jasarana","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283103","Taluk":"NA","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205301","Taluk":"Bhongaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205264","Taluk":"Karhal","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205261","Taluk":"Karhal","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283135","Taluk":"Shikohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283130","Taluk":"Jasarana","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283206","Taluk":"Shikohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205303","Taluk":"NA","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205301","Taluk":"Bhogaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283152","Taluk":"Jasrana","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283203","Taluk":"Shikohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205264","Taluk":"Kahal","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205303","Taluk":"Bhongaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283205","Taluk":"Firozabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205261","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205119","Taluk":"Mainpuri","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283206","Taluk":"Firozabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283203","Taluk":"NA","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205304","Taluk":"Bhogaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283203","Taluk":"Firozbaad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283103","Taluk":"Firozabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205267","Taluk":"NA","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205247","Taluk":"Bhogaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283145","Taluk":"Shikohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"283207","Taluk":"Jasarana","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205303","Taluk":"Bhogaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283207","Taluk":"Jasrana","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205268","Taluk":"Karahal","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205268","Taluk":"Karhal","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283142","Taluk":"Shikohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205001","Taluk":"Bhogaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283130","Taluk":"Jasrana","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205261","Taluk":"Karahal","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"283145","Taluk":"Shjkohabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"205267","Taluk":"Bhogaon","Districtname":"Mainpuri","statename":"UTTAR PRADESH"},
    {"pincode":"205262","Taluk":"Firozabad","Districtname":"Firozabad","statename":"UTTAR PRADESH"},
    {"pincode":"281006","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281404","Taluk":"Chhata","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281201","Taluk":"Mant","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281204","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281406","Taluk":"Chhata","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281301","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281308","Taluk":"Sadabad","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281502","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281501","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281202","Taluk":"Mant","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281307","Taluk":"Sadabad","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"281001","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281123","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281504","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281302","Taluk":"Sadabad","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281005","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281403","Taluk":"Chhata","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281405","Taluk":"Chhata","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281306","Taluk":"Sadabad","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"281004","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281122","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281205","Taluk":"Mant","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281401","Taluk":"Chhata","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281001","Taluk":"Chhata","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281203","Taluk":"Mant","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281401","Taluk":"NA","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"281121","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281206","Taluk":"Mant","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281003","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281303","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281305","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"281104","Taluk":"Sadabad","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"281308","Taluk":"Sadabad","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"281001","Taluk":"Sadabad","Districtname":"Hathras","statename":"UTTAR PRADESH"},
    {"pincode":"281205","Taluk":"Mathura","Districtname":"Mathura","statename":"UTTAR PRADESH"},
    {"pincode":"212303","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212217","Taluk":"Manjhanpur","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212205","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"211007","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211003","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212208","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212217","Taluk":"Sirathu","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212301","Taluk":"Akorha","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229411","Taluk":"Anapur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212213","Taluk":"Chail","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"211005","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211001","Taluk":"Allahabad **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211017","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211002","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211001","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211006","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212214","Taluk":"Manjhanpur","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212202","Taluk":"Chail","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"229411","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229413","Taluk":"Lalgopal Ganj","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212401","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221508","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212301","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211008","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212216","Taluk":"Manjhanpur","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212503","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211012","Taluk":"Allahabad **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229412","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221502","Taluk":"Baraut","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212218","Taluk":"Chail","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"221502","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212306","Taluk":"Koraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221507","Taluk":"Sahson","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212404","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212502","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212203","Taluk":"Charwa","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212404","Taluk":"Ghiyanagar","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212111","Taluk":"Bara","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212402","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212109","Taluk":"Sikandra","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212106","Taluk":"Koraon **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229412","Taluk":"Atrampur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212507","Taluk":"Mau Aima","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212201","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212203","Taluk":"Chail","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"229412","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221503","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211012","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212104","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212201","Taluk":"Allahabad **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212207","Taluk":"Manjhanpur","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212216","Taluk":"Chail","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212107","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212503","Taluk":"Holagarh","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212405","Taluk":"Ugrasenpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212217","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"221505","Taluk":"Hanumanganj","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212106","Taluk":"Bara","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212307","Taluk":"Koraon **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212302","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212108","Taluk":"Bara","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212213","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221508","Taluk":"Saidabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212405","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212104","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211011","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212104","Taluk":"Koraon **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212201","Taluk":"Manjhanpur","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212307","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212206","Taluk":"Manjhanpur","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212305","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229413","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212108","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221502","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212307","Taluk":"Bara","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212109","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211014","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211004","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211008","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212402","Taluk":"Phoolpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212106","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212401","Taluk":"Janghai Bazar","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212203","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212507","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211019","Taluk":"Allahabad **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212105","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212507","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212107","Taluk":"Bara","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212111","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212204","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212402","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221507","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211010","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221503","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211011","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221505","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211016","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212306","Taluk":"Koraon **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212213","Taluk":"Chail","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212404","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212307","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211013","Taluk":"Phaphamau","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212214","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212306","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211013","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212503","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211010","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212301","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212204","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"221505","Taluk":"Hanumaganj","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212107","Taluk":"Jasra","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211019","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212206","Taluk":"Karari","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212301","Taluk":"Karchana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212201","Taluk":"Chail","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212307","Taluk":"Khain","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212502","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212507","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212401","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212104","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212216","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212212","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212401","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212216","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212507","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212216","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221505","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212503","Taluk":"Holgarh","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212201","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212218","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212401","Taluk":"Chail","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212507","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212108","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211019","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229413","Taluk":"Lalgopalganj","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212106","Taluk":"Meja","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212106","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229413","Taluk":"Handia","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212401","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"229411","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211018","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212208","Taluk":"Sirathu","Districtname":"Kaushambi","statename":"UTTAR PRADESH"},
    {"pincode":"212218","Taluk":"Chail","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221507","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"221508","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212204","Taluk":"Phulpur","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212502","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212405","Taluk":"Soraon","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211015","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211009","Taluk":"Karchhana","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212405","Taluk":"Allahabad","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"212507","Taluk":"Mauaima","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"211011","Taluk":"Allahabad **","Districtname":"Allahabad","statename":"UTTAR PRADESH"},
    {"pincode":"233227","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232331","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233222","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233002","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275204","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233305","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233302","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275203","Taluk":"Jakhania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232333","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233221","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233223","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233231","Taluk":"Mohammadabad Yusufpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232336","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233225","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233300","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233301","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275201","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232329","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275204","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233223","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233310","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233227","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233229","Taluk":"Mohammadabad Yusufpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232325","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233229","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275202","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233226","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233311","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233232","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275205","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233306","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232339","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233304","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233303","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232340","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233303","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275204","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233228","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232327","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233231","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232332","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233307","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233300","Taluk":"Ghazipur Sadar","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275203","Taluk":"Jakhanian **","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233310","Taluk":"Jakhania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233224","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233001","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233304","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233233","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232328","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232326","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275202","Taluk":"Jakhania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233302","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232330","Taluk":"Zamania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233222","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233225","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233228","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275205","Taluk":"Jakhania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275203","Taluk":"Saidpur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233233","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233229","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233230","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233311","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233230","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233233","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233228","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275201","Taluk":"Mohammedabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233225","Taluk":"Ghazipur","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233231","Taluk":"Mohammadabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"232333","Taluk":"Zamnia","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233303","Taluk":"Mohammabad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275203","Taluk":"Kakhania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275204","Taluk":"Jakhania","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233227","Taluk":"Jakhanian **","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"275202","Taluk":"Jakhanian **","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"233227","Taluk":"M.bad","Districtname":"Ghazipur","statename":"UTTAR PRADESH"},
    {"pincode":"222133","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223102","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222203","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222141","Taluk":"Badalapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222180","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222161","Taluk":"Mariahun","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222201","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222162","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222142","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222165","Taluk":"Mariahun","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222170","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223104","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222139","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222101","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222145","Taluk":"Badalapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222136","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222203","Taluk":"Mariahun","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222137","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222003","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222105","Taluk":"Mariahun","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222165","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222127","Taluk":"Machhalishahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222125","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222175","Taluk":"Badalapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222136","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223101","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222129","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222201","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222143","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222128","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222101","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222181","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222144","Taluk":"Machhalishahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222144","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222161","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222165","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222109","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222202","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223103","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222128","Taluk":"Mariahun","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222109","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222133","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222136","Taluk":"Mariahun","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222109","Taluk":"Badalapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222149","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222148","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222109","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222137","Taluk":"Mariahun","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222002","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222135","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222136","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222002","Taluk":"NA","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222125","Taluk":"Badalapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222132","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223105","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222170","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223104","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222145","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222204","Taluk":"Machali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222201","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222127","Taluk":"NA","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222144","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222138","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222135","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222146","Taluk":"Kerakat","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222127","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222001","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222141","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222127","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222144","Taluk":"Machhali Shshar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222131","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222175","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222204","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222145","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222105","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222133","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222128","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222109","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222203","Taluk":"NA","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222135","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223104","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222204","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222127","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222138","Taluk":"NA","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"223104","Taluk":"Badalapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222105","Taluk":"Mariahu","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222202","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222202","Taluk":"Machhlishahr","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222141","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222003","Taluk":"Shahganj","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222131","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222161","Taluk":"Badlapur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222165","Taluk":"Machhali Shahar","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"222125","Taluk":"Jaunpur","Districtname":"Jaunpur","statename":"UTTAR PRADESH"},
    {"pincode":"231205","Taluk":"Robertsganj","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231302","Taluk":"Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231301","Taluk":"Chunar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231305","Taluk":"Chunar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231301","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231211","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231216","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231307","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"Mirzapur","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231307","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231212","Taluk":"Mirzapur Sadar","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231225","Taluk":"Dudhi","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231208","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231223","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231223","Taluk":"Sonbhadr","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231205","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231306","Taluk":"Chunar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231304","Taluk":"Chunar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231226","Taluk":"Dudhi","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231212","Taluk":"NA","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231302","Taluk":"NA","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231225","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231313","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231312","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231305","Taluk":"Chuanr","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231213","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231221","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231501","Taluk":"Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231220","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231309","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231217","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231304","Taluk":"Chuanr","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231304","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231501","Taluk":"NA","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231302","Taluk":"Adalhat","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231216","Taluk":"Robertsganj","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231303","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231219","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231501","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231206","Taluk":"Robertsganj","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231312","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231311","Taluk":"Chunar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231206","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231207","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231225","Taluk":"Sonbhadar","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231215","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231301","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231001","Taluk":"Mirzapursadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231212","Taluk":"Mirzapur","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"Sadar","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231302","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231209","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231207","Taluk":"Sonbadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231306","Taluk":"NA","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231305","Taluk":"NA","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231304","Taluk":"NA","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231001","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231215","Taluk":"Sonbhaddra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231314","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231313","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231226","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231224","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231208","Taluk":"Sobhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231216","Taluk":"Sonbadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231226","Taluk":"Sonbadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231222","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231218","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231211","Taluk":"Mirzpaur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231309","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231304","Taluk":"Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231208","Taluk":"Sonbadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231501","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"Marihan","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231001","Taluk":"Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231304","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231216","Taluk":"NA","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231314","Taluk":"Mirzapur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231304","Taluk":"Mirazapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"Mrizapur","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231302","Taluk":"Adlalhat","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231307","Taluk":"Mirzpaur","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231215","Taluk":"Mirzapur Sadar","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"Sonbhadra","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"231307","Taluk":"Lalganj","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231306","Taluk":"Mirzapur Sadar","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231210","Taluk":"NA","Districtname":"Mirzapur","statename":"UTTAR PRADESH"},
    {"pincode":"231222","Taluk":"Dudhi","Districtname":"Sonbhadra","statename":"UTTAR PRADESH"},
    {"pincode":"230141","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230304","Taluk":"Raniganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230131","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"229410","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230142","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230204","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230144","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230132","Taluk":"Lalgang","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"229408","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230301","Taluk":"Raniganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230124","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230132","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230125","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230139","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230501","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230401","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230143","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230126","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230402","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230131","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230137","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230135","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230402","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230202","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230304","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230403","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230134","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230129","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230127","Taluk":"Raniganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230502","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230201","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230402","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230001","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230143","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230128","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230404","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230302","Taluk":"Raniganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230502","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230121","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230302","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230503","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230127","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230405","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230136","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230501","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230002","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230138","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230304","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230403","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230403","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230125","Taluk":"Lalaganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230127","Taluk":"Sadar","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230306","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230132","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230201","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230503","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230130","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230129","Taluk":"Kunda","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230141","Taluk":"Lalganj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230002","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230129","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230133","Taluk":"NA","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230128","Taluk":"Kudna","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230144","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230404","Taluk":"Pratapgarh","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"230302","Taluk":"Ranigaj","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"232110","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221101","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232102","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221007","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232101","Taluk":"Chandauli","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232118","Taluk":"Chakia","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232110","Taluk":"Chandauli","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232105","Taluk":"Sakaldhia","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"221104","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232111","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232109","Taluk":"Sakaldiha","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232118","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232106","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221005","Taluk":"NA","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232108","Taluk":"Sakaldiha","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232106","Taluk":"Sakaldiha","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232102","Taluk":"Chandauli","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"221011","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232107","Taluk":"Sakaldiha","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232109","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221112","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232118","Taluk":"Chakia","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232104","Taluk":"Chandauli","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"221110","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221001","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232103","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221101","Taluk":"NA","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221005","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232103","Taluk":"Chakia","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232104","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232111","Taluk":"Chakia","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232101","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221116","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221115","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232105","Taluk":"Sakaldiha","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232120","Taluk":"Sakaldiha","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"221115","Taluk":"Sakaldiha","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"221008","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232108","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"232104","Taluk":"NA","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232107","Taluk":"Sakaldiha","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232104","Taluk":"Chanduali","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"221007","Taluk":"NA","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221009","Taluk":"Chandauli","Districtname":"Chandauli","statename":"UTTAR PRADESH"},
    {"pincode":"232105","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221002","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221404","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221404","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221304","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221406","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221201","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221402","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221303","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221002","Taluk":"Pindra","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221401","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221301","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221010","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221202","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221006","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221311","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221314","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221201","Taluk":"NA","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221403","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221310","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221204","Taluk":"Pindra","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221207","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221402","Taluk":"Pindra","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221309","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221204","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221405","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221308","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221106","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221206","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221307","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221401","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221201","Taluk":"Pindra **","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221108","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221308","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221105","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221003","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221204","Taluk":"NA","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221004","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221310","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221305","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221303","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221313","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221302","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221208","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221402","Taluk":"S.r.nagar","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221405","Taluk":"Pindra **","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221006","Taluk":"Vanranasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221406","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221309","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221401","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221306","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221306","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221107","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221409","Taluk":"Gyanpur","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221103","Taluk":"Varanasi","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221305","Taluk":"Pindra **","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221409","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221207","Taluk":"Pindra **","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221306","Taluk":"Gyanpur","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221402","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"221305","Taluk":"NA","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221003","Taluk":"NA","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221204","Taluk":"Pindra **","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221304","Taluk":"Pindra **","Districtname":"Varanasi","statename":"UTTAR PRADESH"},
    {"pincode":"221314","Taluk":"Bhadohi","Districtname":"Sant Ravidas Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"262122","Taluk":"Pooranpur","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"262406","Taluk":"Nawabganj","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243501","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243407","Taluk":"Nawabganj","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262201","Taluk":"Bisalpur","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243002","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243004","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243401","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243302","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262121","Taluk":"Amaria","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"262203","Taluk":"Barkhera","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243503","Taluk":"Faridpur","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243301","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243202","Taluk":"Baheri","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243501","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262001","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243201","Taluk":"Baheri","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243505","Taluk":"Meerganj","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262202","Taluk":"Barkhera","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243123","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243003","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243402","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262202","Taluk":"Bisalpur","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"262121","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243303","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243001","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243506","Taluk":"Faridpur","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262203","Taluk":"Bisalpur","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243203","Taluk":"Baheri","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243504","Taluk":"Meerganj","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243401","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243122","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262201","Taluk":"Baheri","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243202","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243126","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262122","Taluk":"Puranpur","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243502","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262124","Taluk":"Puranpur","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"262302","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243506","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262001","Taluk":"Puranpur","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243402","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243403","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262122","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"262124","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"262203","Taluk":"Baheri","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262406","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243123","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243123","Taluk":"Bareilly I","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243302","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243401","Taluk":"Bhamora","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243503","Taluk":"Fridpur","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243006","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"262302","Taluk":"Aonla","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243502","Taluk":"Meerganj","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243003","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"262305","Taluk":"Pilibhit","Districtname":"Pilibhit","statename":"UTTAR PRADESH"},
    {"pincode":"243005","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243123","Taluk":"Bareilly Ho","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243201","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243407","Taluk":"Nawab Ganj","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243505","Taluk":"Bareilly","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"243502","Taluk":"Baheri","Districtname":"Bareilly","statename":"UTTAR PRADESH"},
    {"pincode":"246722","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246701","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246734","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246725","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246764","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246733","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246736","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246762","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246734","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246747","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246726","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246761","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246727","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246725","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246729","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246724","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246745","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246721","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246731","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246764","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246723","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246731","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246749","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246747","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246735","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246727","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246746","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246763","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246728","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246728","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246763","Taluk":"Najibabad","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"246762","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246721","Taluk":"Naijibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246726","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246732","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246762","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246722","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246721","Taluk":"NA","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246734","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246723","Taluk":"Najibabad","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246727","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246763","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246729","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246725","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246763","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246721","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246721","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246737","Taluk":"Dhampur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246763","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246731","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246735","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246733","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246761","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246701","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246725","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246761","Taluk":"Chandpur","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246732","Taluk":"Nagina","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"246761","Taluk":"Bijnor","Districtname":"Bijnor","statename":"UTTAR PRADESH"},
    {"pincode":"243639","Taluk":"Budaun","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243631","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243638","Taluk":"Sahaswan","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243725","Taluk":"Bisauli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243723","Taluk":"Bilsi","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243633","Taluk":"Bilsi","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243601","Taluk":"Budaun","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243722","Taluk":"Gunnaur","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243632","Taluk":"Busauli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243723","Taluk":"Sahaswan","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243635","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243751","Taluk":"Budaun","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243635","Taluk":"Datganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243726","Taluk":"Budaun","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243634","Taluk":"Budaun","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243723","Taluk":"Bisauli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243751","Taluk":"Gunnaur","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243720","Taluk":"Bisuali","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243630","Taluk":"NA","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243724","Taluk":"Bisauli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243751","Taluk":"Gunnour","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243632","Taluk":"NA","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243636","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243641","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243720","Taluk":"Bisauli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243631","Taluk":"NA","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243638","Taluk":"NA","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243723","Taluk":"Bilsi **","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243632","Taluk":"Bisauli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243630","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243723","Taluk":"Gunnour","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243727","Taluk":"Guoour","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243601","Taluk":"Buduan","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243637","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"242021","Taluk":"Budaun","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243727","Taluk":"Gunnour","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243725","Taluk":"NA","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243751","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243633","Taluk":"Bisli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243725","Taluk":"Bisuali","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243636","Taluk":"NA","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243601","Taluk":"NA","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243635","Taluk":"Dataganj`","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"242021","Taluk":"Dataganj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243633","Taluk":"Bilsi **","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243636","Taluk":"Datagnj","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"243724","Taluk":"Bisli","Districtname":"Budaun","statename":"UTTAR PRADESH"},
    {"pincode":"241123","Taluk":"Sawayajpur","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241407","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241403","Taluk":"Harodi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241121","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241202","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241125","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241301","Taluk":"Bilgram","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241124","Taluk":"Sawayajpur","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241402","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241305","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241204","Taluk":"NA","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241203","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241126","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241303","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241001","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241122","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241201","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241404","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241401","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241406","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241401","Taluk":"NA","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241304","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241405","Taluk":"Hardoi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241303","Taluk":"Mallawan","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241302","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241001","Taluk":"Harodi","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241127","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241204","Taluk":"Sandila","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"241124","Taluk":"Shahabad","Districtname":"Hardoi","statename":"UTTAR PRADESH"},
    {"pincode":"262727","Taluk":"Mohammdi","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262724","Taluk":"Dhaurahara","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262722","Taluk":"Dhaurahara","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262903","Taluk":"Nighasan","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"261506","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262802","Taluk":"Gola","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262804","Taluk":"Mohammdi","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"261501","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262721","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262901","Taluk":"Gola","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262723","Taluk":"Dhaurahara","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262904","Taluk":"Palia","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262902","Taluk":"Palia","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262728","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"261505","Taluk":"Mohammdi","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262726","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"261502","Taluk":"Dhaurahara","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262701","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262805","Taluk":"Gola","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262908","Taluk":"Palia","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262906","Taluk":"Nighasan","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262725","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262801","Taluk":"Gola Gokaran Nath","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262802","Taluk":"Gola Gokaran Nath","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262805","Taluk":"Gola Gokaran Nath","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262908","Taluk":"Pallia","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262907","Taluk":"Dhaurahara","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262907","Taluk":"Nighasan","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262702","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262801","Taluk":"Gola","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262905","Taluk":"Nighasan","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262804","Taluk":"Mohammadi","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262803","Taluk":"Gola","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"262901","Taluk":"Gola Gokaran Nath","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"250406","Taluk":"Mawana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250001","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250601","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250609","Taluk":"Baghpat","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"245206","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250401","Taluk":"Mawana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250221","Taluk":"Sardhana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250611","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250502","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250606","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250002","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250623","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250104","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250622","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250341","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250617","Taluk":"Baghpat","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250626","Taluk":"Meerut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250620","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250621","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250342","Taluk":"Sardhana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250101","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250611","Taluk":"Baraut **","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250345","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250404","Taluk":"Mawana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250501","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250344","Taluk":"Sardhana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250103","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250617","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250615","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250205","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250223","Taluk":"Sardhana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250004","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250402","Taluk":"Mawana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250615","Taluk":"Baghpat","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250622","Taluk":"Baghpat","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250110","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250626","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250341","Taluk":"Sardhana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250619","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250625","Taluk":"Baraut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250344","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250106","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250001","Taluk":"NA","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250222","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250222","Taluk":"Sardhana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250406","Taluk":"Sardhana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250003","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250005","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250104","Taluk":"Mawana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250617","Taluk":"Meerut","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250001","Taluk":"Mawana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250606","Taluk":"NA","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250101","Taluk":"Khekada","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250606","Taluk":"Baruat","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250342","Taluk":"Meerut","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250104","Taluk":"Kithore","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250106","Taluk":"Mawana","Districtname":"Meerut","statename":"UTTAR PRADESH"},
    {"pincode":"250617","Taluk":"Baruat","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"250345","Taluk":"Baruat","Districtname":"Bagpat","statename":"UTTAR PRADESH"},
    {"pincode":"244001","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244255","Taluk":"Hasanpur","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244414","Taluk":"Chandausi","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244901","Taluk":"Rampur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244504","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244927","Taluk":"Rampur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244921","Taluk":"Bilasour","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244231","Taluk":"Dhanaura","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244501","Taluk":"Kanth","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244302","Taluk":"Sambhal","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244104","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244411","Taluk":"Bilari","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244401","Taluk":"Thakurdwara","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244410","Taluk":"Chandausi","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244303","Taluk":"Sambhal","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244221","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244304","Taluk":"Sambhl","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244921","Taluk":"Bilaspur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244412","Taluk":"Chandausi","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244236","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244251","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244301","Taluk":"Sambhal","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244701","Taluk":"Milak","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244225","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244601","Taluk":"Thakuedwara","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244402","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244415","Taluk":"Bilari","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244410","Taluk":"NA","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244242","Taluk":"Hasanpur","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244922","Taluk":"Shahabad","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244412","Taluk":"Bilari","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244402","Taluk":"Thakurdwara","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244223","Taluk":"Dhanaura","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244924","Taluk":"Suar","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244501","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244235","Taluk":"Hasanpur","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244923","Taluk":"Rampur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244241","Taluk":"Hasanpur","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244225","Taluk":"Dhanaura","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244923","Taluk":"Bilaspur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244102","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244501","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244602","Taluk":"Thakurdwara","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244925","Taluk":"Suar","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244102","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244242","Taluk":"Dhanaura","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244411","Taluk":"Chandausi","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244222","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244401","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244304","Taluk":"Sambhal","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244601","Taluk":"Thakurdwara","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244235","Taluk":"Dhanaura","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244411","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244923","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244413","Taluk":"Bilari","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244251","Taluk":"NA","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244928","Taluk":"Bilaspur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244236","Taluk":"Hasanpur","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244245","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244236","Taluk":"Dhanaura","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244413","Taluk":"Balari","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244103","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244701","Taluk":"Kilak","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244925","Taluk":"Moradabad","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244921","Taluk":"Builaspur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244701","Taluk":"Rampur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244926","Taluk":"Rampur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244104","Taluk":"Moradabad Thehsil","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244414","Taluk":"Sambhal","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244102","Taluk":"Sambhal","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244241","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244231","Taluk":"Amroha","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244301","Taluk":"Chandausi","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244102","Taluk":"Bilari","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244102","Taluk":"Thakurdwara","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244401","Taluk":"Thakaurdwara","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244927","Taluk":"Suar","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244414","Taluk":"Bilari","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"244925","Taluk":"Rampur","Districtname":"Rampur","statename":"UTTAR PRADESH"},
    {"pincode":"244221","Taluk":"NA","Districtname":"Jyotiba Phule Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"244925","Taluk":"Sambhal","Districtname":"Moradabad","statename":"UTTAR PRADESH"},
    {"pincode":"251001","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247771","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251309","Taluk":"Budhna","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251301","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251306","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251201","Taluk":"Jansath","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251305","Taluk":"Kairana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251202","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247776","Taluk":"Shamli","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251307","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251327","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251203","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251314","Taluk":"Jansath","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247772","Taluk":"Shamli","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247776","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251305","Taluk":"Shamli","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251309","Taluk":"Budhana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251310","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247774","Taluk":"Kairana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251203","Taluk":"Jansath","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251308","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251316","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247777","Taluk":"Kairana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251319","Taluk":"Kairana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247771","Taluk":"Budhana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251316","Taluk":"Jansath","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251315","Taluk":"Jansath","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251308","Taluk":"Muzafarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251319","Taluk":"Budhana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247772","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251311","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247778","Taluk":"Kairana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251001","Taluk":"Mzn","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251201","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251318","Taluk":"Budhana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247775","Taluk":"Shamli **","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247775","Taluk":"Budhana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247775","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251318","Taluk":"Budhna","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247773","Taluk":"Kairana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251320","Taluk":"Jansath","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251201","Taluk":"Budhana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251003","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251203","Taluk":"Budhana","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251305","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251002","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251318","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251309","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251307","Taluk":"Muzaffarnagarmuzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251319","Taluk":"Shamli","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251301","Taluk":"Shamli","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251314","Taluk":"Muzaffarnagar","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247777","Taluk":"Shamli","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"251308","Taluk":"Jansath","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247776","Taluk":"Shamli **","Districtname":"Muzaffarnagar","statename":"UTTAR PRADESH"},
    {"pincode":"247451","Taluk":"Rampur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247342","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247232","Taluk":"Sarsawa","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247340","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247554","Taluk":"Deoband","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247452","Taluk":"Nanauta","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247129","Taluk":"M.bad","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247120","Taluk":"Muzaffarabad","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247551","Taluk":"Nagal","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247001","Taluk":"Saharanpur H.o.","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247122","Taluk":"S.qudeem","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247453","Taluk":"Nanauta","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247120","Taluk":"Muzaffrabad","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247001","Taluk":"Saharanpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247662","Taluk":"M.bad","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247341","Taluk":"Gangoh","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247452","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247231","Taluk":"Sarsawa","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247121","Taluk":"Behat","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247662","Taluk":"M.bad","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"247231","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247662","Taluk":"Muzaffrabad","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"247669","Taluk":"Saharanpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247662","Taluk":"Muzaffrabad","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247001","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247669","Taluk":"Punwarka","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247451","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247341","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247120","Taluk":"Muzaffrabad`","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247129","Taluk":"Behat","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247662","Taluk":"Behat","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247662","Taluk":"Saharanpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247001","Taluk":"Saharnpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247129","Taluk":"Muzaffrabad","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247451","Taluk":"Saharanpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247453","Taluk":"Saharanpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247451","Taluk":"Deoband","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247002","Taluk":"Saharanpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247120","Taluk":"Saharanpur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247232","Taluk":"NA","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247120","Taluk":"Behat","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247232","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"247343","Taluk":"Nakur","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242001","Taluk":"Shahjahanpur","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242401","Taluk":"Powayan","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242305","Taluk":"Tilhar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242220","Taluk":"Jalalabad","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242221","Taluk":"Jalalabad","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242223","Taluk":"Sadar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242407","Taluk":"Tilhar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242127","Taluk":"Jalalabad","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242223","Taluk":"Jalalabad","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242303","Taluk":"Tilhar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242226","Taluk":"Shahjahanpur","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242042","Taluk":"Powayan","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242406","Taluk":"Sadar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242301","Taluk":"Tilhar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242306","Taluk":"Shahjahanpur","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242307","Taluk":"Tilhar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242306","Taluk":"Sadar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242001","Taluk":"Sadar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242123","Taluk":"Powayan","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242001","Taluk":"shahjahanpur","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242226","Taluk":"Sadar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242405","Taluk":"Powayan","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242307","Taluk":"Shahjahanpur","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242221","Taluk":"Shahjahanpur","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242405","Taluk":"Tilhar","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"242406","Taluk":"Shahjahanpur","Districtname":"Shahjahanpur","statename":"UTTAR PRADESH"},
    {"pincode":"276205","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275102","Taluk":"Maunath Bhanjan","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275304","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223221","Taluk":"Budanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276301","Taluk":"Phoolpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221706","Taluk":"Sadar","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276126","Taluk":"Mehnagar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276122","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276124","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276125","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275305","Taluk":"Sadar","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Mohammadabad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276142","Taluk":"Budan Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223222","Taluk":"Phulpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223227","Taluk":"Nizamabad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275301","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276404","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276138","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276125","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276139","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221705","Taluk":"Sadar","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275105","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276406","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276301","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276142","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223223","Taluk":"Budan Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276001","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276001","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276305","Taluk":"Nizamabad **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276204","Taluk":"Nizamabad **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276204","Taluk":"Mehnagar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Sadar","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276141","Taluk":"Nizamabad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276128","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276125","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276201","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221602","Taluk":"Madhuban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276288","Taluk":"Phool Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276122","Taluk":"NA","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276206","Taluk":"Nizamabad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223226","Taluk":"Phoolpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276303","Taluk":"Lal Ganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276143","Taluk":"Phoolpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276304","Taluk":"Phoolpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275306","Taluk":"Mohamda Bad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"M.b. Gohna","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275103","Taluk":"Sadar","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276123","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276136","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221603","Taluk":"Madhuban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276126","Taluk":"Meh Nagar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276405","Taluk":"Mohammadabad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276129","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223221","Taluk":"Budan Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276301","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221601","Taluk":"Madhuban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276121","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223223","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276127","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276305","Taluk":"Nijamabad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276306","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276203","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275307","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276302","Taluk":"Lal Ganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221602","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276204","Taluk":"Sagri","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276202","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276138","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223224","Taluk":"Phool Pur.","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223223","Taluk":"Nizamabad **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276123","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276142","Taluk":"Budanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276135","Taluk":"NA","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276141","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275305","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223221","Taluk":"Nizamabad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276202","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275302","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276208","Taluk":"Nizamabad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276131","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276405","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276306","Taluk":"Madhu Ban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276135","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276123","Taluk":"Mehnagar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276404","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276205","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276202","Taluk":"Lal Ganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276129","Taluk":"Maunath Bhanjan","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275303","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276206","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276140","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276122","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276206","Taluk":"Nizamzbad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276207","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276201","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276141","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276302","Taluk":"Nizama Bad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276129","Taluk":"Mohd. Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276124","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223221","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223226","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276129","Taluk":"Mohammada Bad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223224","Taluk":"Nizamabad **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275306","Taluk":"Sadar Mau","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276203","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276306","Taluk":"Madhuban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Mohammmadabad","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276128","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276201","Taluk":"Lal Ganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Mohammdabad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276137","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276139","Taluk":"Sagri","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276405","Taluk":"Mohammada Bad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223224","Taluk":"Phool Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276136","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276301","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276121","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223225","Taluk":"Phoolpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276402","Taluk":"M.b. Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223227","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223224","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276128","Taluk":"Jahana Ganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276302","Taluk":"Mehnagar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276142","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276135","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223222","Taluk":"Phool Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276402","Taluk":"M.b.gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276128","Taluk":"NA","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223223","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276125","Taluk":"NA","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276127","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276137","Taluk":"Budan Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275102","Taluk":"Sadar","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275302","Taluk":"Mohammadabad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223226","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275305","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275105","Taluk":"Maunath Bhanjan","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275304","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276140","Taluk":"Sagri","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276127","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276140","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275101","Taluk":"Sadar","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276143","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276302","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276128","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276402","Taluk":"Mohammadabad Gohan","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276131","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276141","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276402","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Muhammadabad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276288","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276206","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223222","Taluk":"Phoolpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276126","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276208","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276288","Taluk":"Phulpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276207","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276142","Taluk":"Budhan Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275306","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275103","Taluk":"Mau","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275307","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"221602","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221603","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276131","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276303","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276124","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276304","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276136","Taluk":"Sagri","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275101","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221603","Taluk":"Madhu Ban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223223","Taluk":"Godhan Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276137","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276123","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223225","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223222","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276138","Taluk":"Sagri","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223224","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221602","Taluk":"Madhu Ban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276206","Taluk":"NA","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276122","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275101","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275101","Taluk":"Madhuban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276127","Taluk":"Sagari","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221706","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276305","Taluk":"Nizamabad","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276143","Taluk":"Burhanpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Mohammada Bad Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276403","Taluk":"Mohammadabad","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276302","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276288","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276402","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276204","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275302","Taluk":"Badrav","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276208","Taluk":"Sadar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221603","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276303","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223222","Taluk":"Madhuban **","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276205","Taluk":"Lal Ganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223224","Taluk":"Phoolpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276202","Taluk":"Meh Nagar","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275307","Taluk":"Madhuban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276304","Taluk":"Phool Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276126","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276126","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276124","Taluk":"Ghosi","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223226","Taluk":"Phul Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223225","Taluk":"Phuool Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221602","Taluk":"Madhuban **","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276305","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276129","Taluk":"Mohammada Bad","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276129","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276406","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276402","Taluk":"Md. Gohana","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275105","Taluk":"Ghoshi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"223221","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276406","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221706","Taluk":"NA","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276206","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221603","Taluk":"Maduban","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"221706","Taluk":"Madhuban **","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276126","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276127","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276301","Taluk":"Phulpur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"223221","Taluk":"Phool Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276406","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276141","Taluk":"Budan Pur","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"275301","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221705","Taluk":"NA","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"276207","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276123","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276123","Taluk":"Lal Ganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276207","Taluk":"Lalganj","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276208","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"221603","Taluk":"Ghosi","Districtname":"Mau","statename":"UTTAR PRADESH"},
    {"pincode":"275302","Taluk":"Mehnagar **","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"276128","Taluk":"Azamgarh","Districtname":"Azamgarh","statename":"UTTAR PRADESH"},
    {"pincode":"271831","Taluk":"Bhinga","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271870","Taluk":"Kaiserganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271871","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271802","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271851","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271803","Taluk":"Bhinga","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271882","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271865","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271904","Taluk":"NA","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271865","Taluk":"NA","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271881","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271903","Taluk":"Kaiserganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271840","Taluk":"Bhinga","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271902","Taluk":"Kaiserganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271904","Taluk":"Kaiserganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271903","Taluk":"Kaisarganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271801","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271830","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271851","Taluk":"Mahasi","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271855","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271845","Taluk":"Ikauna","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271875","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271841","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271825","Taluk":"Mahasi Tahsil","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271872","Taluk":"Kaiserganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271902","Taluk":"NA","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271875","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271825","Taluk":"Mahasi","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271901","Taluk":"Kaiserganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271804","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271821","Taluk":"Payagpur","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271804","Taluk":"Bhinga","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271830","Taluk":"NA","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271802","Taluk":"Ikauna","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271802","Taluk":"Bhinga","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271835","Taluk":"Ikauna","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271870","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271851","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271875","Taluk":"Bhinga","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271875","Taluk":"Mahasi **","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271871","Taluk":"Payagpur **","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271821","Taluk":"Bahraich","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271851","Taluk":"Mahsi","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271902","Taluk":"Mahasi **","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271845","Taluk":"Ikauna","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271803","Taluk":"Ikauna","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271865","Taluk":"Bhinga","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271824","Taluk":"Mahasi","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271902","Taluk":"Nanpara","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271845","Taluk":"Payagpur **","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271825","Taluk":"Mahasi **","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271804","Taluk":"Ikauna","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271845","Taluk":"NA","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271805","Taluk":"Ikauna **","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271802","Taluk":"Mahasi **","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271804","Taluk":"Kaiserganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271851","Taluk":"Mahasi **","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271870","Taluk":"Payagpur **","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271855","Taluk":"Lakhimpur","Districtname":"Kheri","statename":"UTTAR PRADESH"},
    {"pincode":"271805","Taluk":"Ikauna","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271802","Taluk":"Mahasi","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271824","Taluk":"Mahasi **","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"271901","Taluk":"Kaisarganj","Districtname":"Bahraich","statename":"UTTAR PRADESH"},
    {"pincode":"277209","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277401","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221715","Taluk":"Bilthara Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221713","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221712","Taluk":"Rasra","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277501","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277203","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277502","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277304","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277302","Taluk":"Sikandarpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221701","Taluk":"Rasra","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221711","Taluk":"Rasra","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221716","Taluk":"Bilthara Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221715","Taluk":"Bithara Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221716","Taluk":"Bilthra Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277402","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277303","Taluk":"Sikandar Pur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221717","Taluk":"Sikanderpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277207","Taluk":"Sikanderpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277001","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277216","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221715","Taluk":"Bilthra Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277201","Taluk":"NA","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277202","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277121","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277205","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277303","Taluk":"Sikandarpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277207","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221716","Taluk":"Bilthra Rioad","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277301","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277403","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277201","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221711","Taluk":"Bilthara Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277209","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277211","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221709","Taluk":"Rasra","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277503","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277121","Taluk":"Rasra","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277124","Taluk":"Sikandarour","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277208","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277214","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221718","Taluk":"Bilthra Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277210","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277210","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277204","Taluk":"Bairia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221711","Taluk":"Sikandarpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277403","Taluk":"Basllia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221715","Taluk":"Bilthararoad","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221709","Taluk":"Sikanderpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277402","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277213","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277219","Taluk":"Bansdih","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221716","Taluk":"Blthra Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277124","Taluk":"Sikandarpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221718","Taluk":"Belthra Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221717","Taluk":"NA","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277123","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277202","Taluk":"NA","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277304","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277303","Taluk":"Siaknderpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277302","Taluk":"Sikanderpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221711","Taluk":"Bilthjara Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277303","Taluk":"Sikanderpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277506","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277504","Taluk":"Ballia","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277124","Taluk":"Sikanderpur","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277123","Taluk":"Rasra","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221718","Taluk":"Bilthararoad","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"277504","Taluk":"Rasra","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"221711","Taluk":"Bilthra Road","Districtname":"Ballia","statename":"UTTAR PRADESH"},
    {"pincode":"272152","Taluk":"Menhdawal","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272170","Taluk":"NA","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272204","Taluk":"Naugarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272163","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272153","Taluk":"Bansi","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272202","Taluk":"Naugarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272194","Taluk":"Bhanpur","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272155","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272191","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272150","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272127","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272190","Taluk":"Bhanpur","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272205","Taluk":"Shohratgarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272152","Taluk":"Bansi","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272173","Taluk":"Khalilabad","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272162","Taluk":"Dhanghata","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272153","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272151","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272175","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272201","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272165","Taluk":"Dhanghata","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272161","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272165","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272126","Taluk":"Basti Sadar","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272208","Taluk":"Naugarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272170","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272206","Taluk":"Naugarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272002","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272302","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272301","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272131","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272192","Taluk":"Itwa","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272125","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272199","Taluk":"Khalilabad","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272154","Taluk":"Bansi","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272152","Taluk":"Menhdawal","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272175","Taluk":"Khalilabad","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272123","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272151","Taluk":"Rudhauli","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272177","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272126","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272131","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272128","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272190","Taluk":"Bhanpur (sonha)","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272271","Taluk":"Menhdawal","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272165","Taluk":"NA","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272001","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272002","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272154","Taluk":"Menhdawal","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272195","Taluk":"Domariaganj","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272189","Taluk":"Domariaganj","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272181","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272164","Taluk":"Dhanghata","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272270","Taluk":"Menhdawal","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272182","Taluk":"Basti East","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272191","Taluk":"Domariaganj","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272155","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272204","Taluk":"Shohratgarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272130","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272176","Taluk":"Dhanghata","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272194","Taluk":"Bhanpur Babu","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272193","Taluk":"Itwa","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272123","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272124","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272207","Taluk":"Naugarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272177","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272125","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272170","Taluk":"Khalilabad","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272153","Taluk":"Itwa","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272129","Taluk":"Domariaganj","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272203","Taluk":"Naugarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272201","Taluk":"Shohratgarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272148","Taluk":"Bansi","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272151","Taluk":"Bansi","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272125","Taluk":"Khalilabad","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272172","Taluk":"NA","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272151","Taluk":"Bansi","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272301","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272148","Taluk":"Menhdawal","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272193","Taluk":"Domariaganj","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272126","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272148","Taluk":"Rudhauli","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272150","Taluk":"Bhanpur Baboo","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272302","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272154","Taluk":"Menhdawal","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272129","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272176","Taluk":"NA","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272170","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272171","Taluk":"NA","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272150","Taluk":"Rudhauli","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272002","Taluk":"NA","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272125","Taluk":"NA","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272171","Taluk":"Basti Sadar","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272206","Taluk":"Shohratgarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272172","Taluk":"Dhanghata","Districtname":"Sant Kabir Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"272207","Taluk":"NA","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272161","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272195","Taluk":"Domagariaganj","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272163","Taluk":"Harraina","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272153","Taluk":"NA","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272131","Taluk":"Harrraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272150","Taluk":"Bhanpur Babu","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272195","Taluk":"Bhanpur","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272124","Taluk":"Basti","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272195","Taluk":"Bhanpur)sonha)","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272163","Taluk":"Bhanpur","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"272205","Taluk":"Shoharatgarh","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"272194","Taluk":"Domariaganj","Districtname":"Siddharthnagar","statename":"UTTAR PRADESH"},
    {"pincode":"274509","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274702","Taluk":"Bhatpar Rani **","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274402","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274409","Taluk":"Tamkuhi","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274149","Taluk":"Hata","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274508","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274302","Taluk":"Tamkuhi Raj","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274405","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274305","Taluk":"Hata","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274604","Taluk":"Barhaj **","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274203","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274149","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274301","Taluk":"Hata","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274001","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274502","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274304","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274505","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274407","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274409","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274404","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274702","Taluk":"Bhatpar Rani","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274208","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274501","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274501","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274201","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274408","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274202","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274509","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274802","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274705","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274704","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274401","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274408","Taluk":"Deoria Sadar","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274506","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274704","Taluk":"Bhatpar Rani","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274703","Taluk":"Bhatpar Rani","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274302","Taluk":"Padrauna","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274603","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274601","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274205","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274204","Taluk":"Rudrapur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274001","Taluk":"Deoria Sadar","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274201","Taluk":"Deoria Sadar","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274207","Taluk":"Hata","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274305","Taluk":"Padrauna","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274303","Taluk":"Padrauna","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274301","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274502","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274204","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274208","Taluk":"Rudrapur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274405","Taluk":"Deoria","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274701","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274401","Taluk":"Tamkuhi","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274802","Taluk":"Padrauna","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274602","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274182","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274701","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274206","Taluk":"Hata","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274206","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274701","Taluk":"Bhatpsar Rani","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274702","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274703","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274506","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274603","Taluk":"Barhaj","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274203","Taluk":"Hata","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274302","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274404","Taluk":"Deoria Sadar","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274808","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274306","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274801","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274207","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274505","Taluk":"Salempur","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274402","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274806","Taluk":"deoria","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274604","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274304","Taluk":"Padrauna","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274202","Taluk":"Deoria Sadar","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274406","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274705","Taluk":"Bhatpar Rani","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274303","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274402","Taluk":"Kasia","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274305","Taluk":"NA","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274508","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274306","Taluk":"Hata","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274182","Taluk":"Barhaj","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274403","Taluk":"Kasia","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274306","Taluk":"Padrauna","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274407","Taluk":"Tamkuhi","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274001","Taluk":"Deoria","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274601","Taluk":"Barhaj","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274304","Taluk":"Padaruna","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274604","Taluk":"Barhaj","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274704","Taluk":"Bhatparrani","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274404","Taluk":"Deoria","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274705","Taluk":"NA","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"274406","Taluk":"Tamkohi","Districtname":"Kushinagar","statename":"UTTAR PRADESH"},
    {"pincode":"274807","Taluk":"Deoria","Districtname":"Deoria","statename":"UTTAR PRADESH"},
    {"pincode":"271124","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271307","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271215","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271401","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271503","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271305","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271402","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271309","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271504","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271604","Taluk":"Utraula","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271312","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271206","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271403","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271203","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271302","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271204","Taluk":"Balrampur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271303","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271202","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271126","Taluk":"Cononel Ganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271126","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271002","Taluk":"NA","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271312","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271313","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271205","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271306","Taluk":"Utraula","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271002","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271201","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271210","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271603","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271401","Taluk":"Tarbganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271861","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271125","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271201","Taluk":"NA","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271129","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271122","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271208","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271602","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271003","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271310","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271207","Taluk":"Tulsipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271311","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271209","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271123","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271201","Taluk":"Ikauna **","Districtname":"Shrawasti","statename":"UTTAR PRADESH"},
    {"pincode":"271204","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271503","Taluk":"NA","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271204","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271209","Taluk":"Balrampur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271307","Taluk":"Utraula","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271122","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271601","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271504","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271207","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271609","Taluk":"Utraula","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271129","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271208","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271123","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271001","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271502","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271310","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271607","Taluk":"Utraula","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271607","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271403","Taluk":"Tarabganjj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271504","Taluk":"Utraula","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271311","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271208","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271604","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271003","Taluk":"NA","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271402","Taluk":"Tarabfanj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271305","Taluk":"Harraiya","Districtname":"Basti","statename":"UTTAR PRADESH"},
    {"pincode":"271302","Taluk":"Manakpur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271003","Taluk":"Tulsipur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271301","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271205","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271126","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271210","Taluk":"Tuslipur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271319","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271308","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271209","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271403","Taluk":"Tarabganu","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271003","Taluk":"Colonelganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271310","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271204","Taluk":"NA","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271403","Taluk":"Taranganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271319","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271313","Taluk":"NA","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271307","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271861","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271604","Taluk":"Balrampur","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271304","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271504","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271313","Taluk":"Manapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271215","Taluk":"NA","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271301","Taluk":"Gonda","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271402","Taluk":"Tarabganij","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271305","Taluk":"NA","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271302","Taluk":"Tarabganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271215","Taluk":"Tulsipuir","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271504","Taluk":"Tarbganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271604","Taluk":"NA","Districtname":"Balrampur","statename":"UTTAR PRADESH"},
    {"pincode":"271303","Taluk":"Mankapur","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"271124","Taluk":"Tarab Ganj","Districtname":"Gonda","statename":"UTTAR PRADESH"},
    {"pincode":"273002","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273164","Taluk":"Nautanawa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273213","Taluk":"Khajani","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273213","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273001","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273202","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273212","Taluk":"Khajani","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273157","Taluk":"Farenda","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273155","Taluk":"Pharenda","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273163","Taluk":"Nichlaul","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273003","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273407","Taluk":"Gola","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273004","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273304","Taluk":"Nichlaul","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273013","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273404","Taluk":"Khajni","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273306","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273207","Taluk":"Mahrajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273163","Taluk":"Mahrajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"Mahrajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273201","Taluk":"Chauri Chaura","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273403","Taluk":"Basgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273016","Taluk":"Sader","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273158","Taluk":"Campierganj","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273161","Taluk":"Farenda","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273413","Taluk":"Basgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273007","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Mahrajgang","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273402","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273165","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273155","Taluk":"Farenda","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273403","Taluk":"Bansgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273412","Taluk":"Gola","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273002","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273406","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273409","Taluk":"Gola","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273211","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273157","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273402","Taluk":"Gola","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273405","Taluk":"Chauri Chaura","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273209","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273406","Taluk":"Bansgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273151","Taluk":"Mahrajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273162","Taluk":"Nautanwa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273301","Taluk":"Mahrajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273163","Taluk":"Nochlaul","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273405","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273407","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273411","Taluk":"Basgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273152","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273404","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"NA","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Mahrajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273001","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273401","Taluk":"Bhowapar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273401","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273408","Taluk":"Gola","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273209","Taluk":"Sahajanwa","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273306","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273202","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273207","Taluk":"NA","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Marajganj Sadar","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273401","Taluk":"Khajani","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273401","Taluk":"Shajnwa","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273305","Taluk":"Nautanwa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273203","Taluk":"Chauri Chaura","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273157","Taluk":"Fharenda","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273164","Taluk":"NA","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"Mahrajganj.","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273401","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273212","Taluk":"Khajni","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"Maharajganj","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273408","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273409","Taluk":"Gola Bazar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273211","Taluk":"KHAJANI","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273301","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273161","Taluk":"Fharenda","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273301","Taluk":"NA","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273411","Taluk":"Bansgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273158","Taluk":"Camperganj","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273162","Taluk":"NA","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273015","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273007","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273212","Taluk":"Sahjanwa","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273404","Taluk":"Khajani","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273412","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273305","Taluk":"Nidhlaul","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273207","Taluk":"Nichlaul","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273209","Taluk":"Sahjanwa","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273151","Taluk":"Maharaj Ganj Sadar","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273005","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273006","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273015","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273009","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273012","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273013","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Maharajang","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273152","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273211","Taluk":"Khajani","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273212","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273007","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273014","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273015","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273010","Taluk":"Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273163","Taluk":"Maharajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273309","Taluk":"Pharenda","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"Maharajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273413","Taluk":"Basgaob","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273413","Taluk":"Bansgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273163","Taluk":"NA","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"Maharajgang","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273411","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273163","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273010","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273408","Taluk":"Basgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273405","Taluk":"Chauri Chaura","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273008","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273162","Taluk":"Nautanawa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Maharaganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273305","Taluk":"Nachulwa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Maharganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273012","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273155","Taluk":"Nautanwa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"Maharajganj Sadar","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273407","Taluk":"Khajni","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273207","Taluk":"Nichaulal","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273008","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273404","Taluk":"Gola","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273201","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273164","Taluk":"Maharajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273164","Taluk":"Nautanwa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273311","Taluk":"Nichlaul","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273014","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273403","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273310","Taluk":"Campeirganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273165","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273152","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273212","Taluk":"Sahajanwa","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273012","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273005","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273209","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273165","Taluk":"Campierganj","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273303","Taluk":"Marajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273209","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273202","Taluk":"Chauri Chaura","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273304","Taluk":"NA","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273207","Taluk":"Mahraganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Maharjang","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273412","Taluk":"Bansgaon","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273016","Taluk":"Gorakhpur Sadar","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273306","Taluk":"Sadar","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273017","Taluk":"Gorakhpur","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273209","Taluk":"Sajhanwa","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273163","Taluk":"Nichlaulal","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273302","Taluk":"Maharajganj","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273308","Taluk":"Nautanwa","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273413","Taluk":"Chauri Chaura","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273305","Taluk":"Nichlaul","Districtname":"Maharajganj","statename":"UTTAR PRADESH"},
    {"pincode":"273165","Taluk":"NA","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"273406","Taluk":"KHAJANI","Districtname":"Gorakhpur","statename":"UTTAR PRADESH"},
    {"pincode":"210001","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210126","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210205","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210425","Taluk":"NA","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210423","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210421","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210203","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210121","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210430","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210128","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210432","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210201","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210421","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210120","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210125","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210507","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210206","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210502","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210433","Taluk":"NA","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210422","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210431","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210202","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210426","Taluk":"NA","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210429","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210129","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210501","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210001","Taluk":"NA","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210430","Taluk":"NA","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210207","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210208","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210209","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210341","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210506","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210204","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210505","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210123","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210424","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210505","Taluk":"NA","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210427","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210203","Taluk":"NA","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210429","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210504","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210504","Taluk":"NA","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210427","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210428","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210208","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210424","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210202","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210507","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210301","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210209","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210341","Taluk":"NA","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210301","Taluk":"NA","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210207","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210205","Taluk":"NA","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210504","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210425","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210426","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210425","Taluk":"Hamirpur","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210206","Taluk":"Banda","Districtname":"Banda","statename":"UTTAR PRADESH"},
    {"pincode":"210428","Taluk":"NA","Districtname":"Hamirpur","statename":"UTTAR PRADESH"},
    {"pincode":"210203","Taluk":"Banda","Districtname":"Chitrakoot","statename":"UTTAR PRADESH"},
    {"pincode":"210431","Taluk":"Hamirpur","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"210427","Taluk":"NA","Districtname":"Mahoba","statename":"UTTAR PRADESH"},
    {"pincode":"209723","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209502","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209721","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209621","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209745","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209731","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209622","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209733","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209652","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209728","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209720","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209729","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209736","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209625","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209501","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209721","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209732","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209651","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209727","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209601","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209734","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209724","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209601","Taluk":"NA","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209749","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209602","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209505","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209747","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209725","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209739","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209738","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209743","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209722","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209504","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209503","Taluk":"Farrukhabad","Districtname":"Farrukhabad","statename":"UTTAR PRADESH"},
    {"pincode":"209726","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"209735","Taluk":"Kannauj","Districtname":"Kannauj","statename":"UTTAR PRADESH"},
    {"pincode":"212665","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212601","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212653","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212651","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212641","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212655","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212620","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212661","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212652","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212635","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212659","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212631","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212650","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212658","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212656","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212664","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212622","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212663","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212657","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212645","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212621","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"212654","Taluk":"Fatehpur","Districtname":"Fatehpur","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Csa","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Amour","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208011","Taluk":"Anand Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208003","Taluk":"Anwarganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Aranjhami","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208009","Taluk":"Armapur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208009","Taluk":"Kalpi Road","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Arya Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208017","Taluk":"Avas Vikas Yojna No. 3","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Shanti Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Baikunthpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208017","Taluk":"Bairi Gaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Baraigarh","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208027","Taluk":"Barra","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208027","Taluk":"Chauraha","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Bausar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Behta Gambhirpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Bhadrsa","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209305","Taluk":"Bhairampur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Bharu","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209305","Taluk":"Bhauti Pratappur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Bheoli","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209305","Taluk":"Bhimsen","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Bidhnoo","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208024","Taluk":"Vikas Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Binour","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Birhana Road","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Bisayakpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Bithoor","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208007","Taluk":"Harjinder Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208008","Taluk":"Chakeri","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209305","Taluk":"Chakarpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Chaurai","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Chhatarpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208013","Taluk":"Cod","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Colonelganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Cotton Mill","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208012","Taluk":"Fazalganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Dehli Ujagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Deosarh","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Nayaganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208013","Taluk":"G T Road","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Gwaltoli","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"G T Road","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Fahimabad","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Farrashkhana","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Fatehpur Roshnai","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208007","Taluk":"Rama Devi Chauraha","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Gayatri Seedh Peeth Khadeshar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Gazipur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Gopalpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208006","Taluk":"Govind Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Medical College","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208007","Taluk":"Hal Colony","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Harchand Khera","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Hardauli","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Hithgaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Hatia","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Kesa Colony","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208005","Taluk":"Lajpat Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208016","Taluk":"Kalyanpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208026","Taluk":"Indira Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208005","Taluk":"Vijay Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Itra","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208010","Taluk":"Jajmau","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Jamu","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Jarkala","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208012","Taluk":"Jawahar Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Jugrajpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208014","Taluk":"Juhi","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Nai Sarak","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208024","Taluk":"K P University","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Kadari Champatpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Kaindha","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208017","Taluk":"Kalyanpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Kamlapur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208004","Taluk":"Cantt.","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Kanpur Court","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Bara Chauraha","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Stock Exchange","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Karbigawan","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208021","Taluk":"Kasigaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Kathara","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Katherua","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208021","Taluk":"Kathongar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208012","Taluk":"Kaushalpuri","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208017","Taluk":"Kanpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208004","Taluk":"Khapra Mohal","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208017","Taluk":"Kheora","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208011","Taluk":"Kidwai Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208007","Taluk":"Lal Bangla","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Kudani","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Kulgaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Maharajpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Mahauli","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Mahua Gaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Majhawan","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Mandhana","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209305","Taluk":"Mardanpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208004","Taluk":"Meerpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Motijheel","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208023","Taluk":"Munshipurwa","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208021","Taluk":"Naubasta","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208004","Taluk":"Kanpur Cantt.","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Naramau","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208004","Taluk":"The Mall","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Narwal","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Nasra","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208021","Taluk":"Kanpur Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Parade","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208025","Taluk":"Naveen Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Nawabganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208015","Taluk":"New Pac Lines","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208014","Taluk":"Nirala Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208017","Taluk":"Nsi Campus","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Oef Factory","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Pachor","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Palhepur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Palibhogipur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208020","Taluk":"Ganga Ganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208020","Taluk":"Panki","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Parasadepur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Parsauli","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Pem","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Filkhana","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Phuphuwar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208021","Taluk":"Pipergaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Prempur Badagaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Purana Kanpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Purwameer","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208012","Taluk":"R K Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208004","Taluk":"Railganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Raipur Kukhat","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Ramaipur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208012","Taluk":"Ashok Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Rania","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Rasulpur Umra","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208022","Taluk":"Ratan Lal Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208019","Taluk":"Rawatpur Gaon","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208011","Taluk":"Rbi Colony","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Rooma","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209304","Taluk":"Sachendi","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208025","Taluk":"Kakadeo","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Salempur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208021","Taluk":"Sanigawan","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Sapai","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Sarh","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Sarsaul","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208005","Taluk":"Sarvodaya Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Swaijpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209401","Taluk":"Semarjhal","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209305","Taluk":"Senpara Paschim","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208005","Taluk":"Shivaji Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208013","Taluk":"Shyam Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Sirhi Itra","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208012","Taluk":"Premnagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Subhauli","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208002","Taluk":"Swarup Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208023","Taluk":"Transport Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Tatyaganj","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208021","Taluk":"Taudhakpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209217","Taluk":"Tikra","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209402","Taluk":"Tilsahari","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209214","Taluk":"Udaipur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208022","Taluk":"Dada Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208005","Taluk":"Vijai Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"208011","Taluk":"Yashoda Nagar","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209866","Taluk":"Unnaao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209202","Taluk":"Bilhaur","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"208001","Taluk":"Kanpur","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209801","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209821","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209831","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209302","Taluk":"Jhinjhak","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209111","Taluk":"Pukhraayan","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209867","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209206","Taluk":"Ghatampur","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209101","Taluk":"Akbarpur","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209862","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209863","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209112","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209301","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209115","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209827","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209204","Taluk":"Sheoli","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209868","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209312","Taluk":"Knpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209859","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209125","Taluk":"Sikandra","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209306","Taluk":"Rasulabad","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209859","Taluk":"Uunao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209870","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209310","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209871","Taluk":"Safipur","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209209","Taluk":"Kanpur Dehta","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209825","Taluk":"Purwa","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209869","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209208","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209303","Taluk":"Rura","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209311","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209312","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209308","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209209","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209865","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209307","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209864","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209205","Taluk":"Shivrajpur","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209871","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209121","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209111","Taluk":"Pukhrayan","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209866","Taluk":"Bihar","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209112","Taluk":"Amraudha","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209210","Taluk":"Uttaripura","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209841","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209866","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209203","Taluk":"Kanpur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209861","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209881","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209208","Taluk":"Kanpur  Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209121","Taluk":"Kanpu Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209203","Taluk":"Chaubepur","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209206","Taluk":"Ghatampur","Districtname":"Kanpur Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"209871","Taluk":"Safuipur","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209302","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209208","Taluk":"Kanpur Dehta","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209101","Taluk":"Akbapur","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209111","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209825","Taluk":"Unnao","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209870","Taluk":"Unna","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209115","Taluk":"Kanpur Dheat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209306","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209303","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209204","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209205","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209125","Taluk":"NA","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"209871","Taluk":"Sfipur","Districtname":"Unnao","statename":"UTTAR PRADESH"},
    {"pincode":"209210","Taluk":"Kanpur Dehat","Districtname":"Kanpur Dehat","statename":"UTTAR PRADESH"},
    {"pincode":"225208","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225207","Taluk":"Sirauli Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225412","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225122","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225401","Taluk":"Ram Sanehi Ghat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225409","Taluk":"Ram Sanehi Ghat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225205","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225124","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225302","Taluk":"Fateh Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225416","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225003","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225301","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225306","Taluk":"Fateh Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225201","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225415","Taluk":"Ram Sanehi Ghat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225001","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225204","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225123","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225126","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225002","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225414","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225121","Taluk":"Nawab Ganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225302","Taluk":"Fatehpur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225305","Taluk":"Fatehpur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225303","Taluk":"Fateh Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225305","Taluk":"Fateh Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225205","Taluk":"Sirauli Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225121","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225405","Taluk":"Ram Sanehi Ghat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225414","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225119","Taluk":"Haidergarh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225120","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225204","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225305","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225301","Taluk":"Fateh Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225202","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225125","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225206","Taluk":"Sirauli Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225203","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225403","Taluk":"Ram Sanehi Ghat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225404","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225304","Taluk":"Fateh Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225416","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225413","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225305","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225003","Taluk":"NA","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225126","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225305","Taluk":"NA","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225405","Taluk":"Sirauli Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225415","Taluk":"Sirauli Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225304","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225119","Taluk":"Haider Garh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225304","Taluk":"NA","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225416","Taluk":"Ram Sanehi Ghat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225405","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225415","Taluk":"Siraul Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225403","Taluk":"Ram Sanehi Gaht","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225124","Taluk":"Haidergarh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225412","Taluk":"Haidergarh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225126","Taluk":"Haidergarh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225412","Taluk":"Sirauli Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225206","Taluk":"Ramnagar","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225413","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225303","Taluk":"Fatehpur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225404","Taluk":"Ram Sanehi Ghat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225415","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225208","Taluk":"NA","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225403","Taluk":"Sirauli Gauspur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225206","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225415","Taluk":"Sirauli Gauspur**","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225206","Taluk":"Siraul Gaus Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225413","Taluk":"Haidergarh","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225306","Taluk":"Fatehpur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225208","Taluk":"Fateh Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225207","Taluk":"Sirauli Gaus Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225415","Taluk":"Siraul Gaus Pur","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225416","Taluk":"Nawabganj","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"225121","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"224116","Taluk":"NA","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224001","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224129","Taluk":"Alapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224225","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224168","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224208","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224147","Taluk":"Alapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224228","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224225","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224157","Taluk":"Akbarur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224139","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224137","Taluk":"Alapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224120","Taluk":"Rudauli","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"224145","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224190","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224122","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224116","Taluk":"Rudauli","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"224161","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224135","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224181","Taluk":"Alapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224121","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224186","Taluk":"Alapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224149","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224118","Taluk":"Rudauli","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"224141","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224172","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224164","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224143","Taluk":"Alapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224152","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224209","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224190","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224188","Taluk":"Sohawal","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224141","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224125","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224151","Taluk":"Akberpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224207","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224284","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224234","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224123","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224123","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224161","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224189","Taluk":"Sohawal","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224153","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224204","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224168","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224125","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224132","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224122","Taluk":"Akabarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224205","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224176","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224201","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224208","Taluk":"Milkipur **","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224230","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224126","Taluk":"Sohawal","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224203","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224227","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224195","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224186","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224210","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224127","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224182","Taluk":"Sohawal","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224234","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224206","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224228","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224129","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224152","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224132","Taluk":"Akberpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224229","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224231","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224129","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224205","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224203","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224152","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224132","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224172","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224159","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224206","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224202","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224151","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224145","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224210","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224155","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224116","Taluk":"Rudauli","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224176","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224206","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224181","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224122","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224176","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224118","Taluk":"Rudauli","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224141","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224231","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224190","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224231","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224145","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224204","Taluk":"Bikapaur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224133","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224205","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224168","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224155","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224201","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224210","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224171","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224189","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224137","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224284","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224135","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224161","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224189","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224127","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224195","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224119","Taluk":"Rudauli","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"224143","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224171","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224232","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224143","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224120","Taluk":"Rudauli","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224208","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224181","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224146","Taluk":"Akberpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224201","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224146","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224172","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224147","Taluk":"NA","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224195","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224133","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224202","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224146","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224195","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224234","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224151","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224186","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224195","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224206","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224153","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224158","Taluk":"Milkipur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224157","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224145","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224155","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224206","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224158","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224229","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224183","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224227","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224152","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224235","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224121","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224126","Taluk":"Sohawal **","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224207","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224129","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224132","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224117","Taluk":"Rudauli","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224151","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224117","Taluk":"Rudaui","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224161","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224118","Taluk":"Rudaui","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224127","Taluk":"Milkipur **","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224189","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224202","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224117","Taluk":"Rudauli","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"224147","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224235","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224143","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224181","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224139","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224203","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224137","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224205","Taluk":"Bikapur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224153","Taluk":"Milkpur","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224155","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224132","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224164","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224119","Taluk":"Rudauli","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224171","Taluk":"Faizabad","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224119","Taluk":"Ramsanehighat","Districtname":"Barabanki","statename":"UTTAR PRADESH"},
    {"pincode":"224202","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224141","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224176","Taluk":"Alapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224181","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224172","Taluk":"Allapur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224234","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224182","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224168","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224159","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224208","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224127","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224127","Taluk":"Motinagar","Districtname":"Faizabad","statename":"UTTAR PRADESH"},
    {"pincode":"224209","Taluk":"Tanda","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224123","Taluk":"Allapur **","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224189","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224205","Taluk":"Akbarpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224164","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"224238","Taluk":"Jalalpur","Districtname":"Ambedkar Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201206","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245101","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"203207","Taluk":"Achheja","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201015","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245208","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245201","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201310","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201313","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"245304","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201009","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201001","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245205","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201003","Taluk":"Ator Nagla","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201003","Taluk":"Aurangabad Ristal","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201102","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"203207","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201304","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"245207","Taluk":"Modinagar","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201204","Taluk":"Modinagar","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201010","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201005","Taluk":"Loni","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201003","Taluk":"Bhup Kheri","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245205","Taluk":"Garhmukteshwer","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201008","Taluk":"Bisara","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201306","Taluk":"Bishrakh","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201011","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201314","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201307","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201009","Taluk":"Chhprola","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201009","Taluk":"Chhpyana","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201006","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201201","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201311","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201016","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"203207","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"245207","Taluk":"Simbhaoli","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245301","Taluk":"Dhaulana","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245301","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"203207","Taluk":"Dhoom","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201003","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201312","Taluk":"G B nagar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201310","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201013","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201201","Taluk":"Modinagar","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201004","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201306","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201002","Taluk":"Ingram Institute","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201002","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245304","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201012","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201204","Taluk":"Khanjarpur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245205","Taluk":"Khiluai","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201309","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201310","Taluk":"Gautam Budh Nagar","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201306","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201304","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201310","Taluk":"Maicha","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201014","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201204","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201007","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"245207","Taluk":"Hapur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201003","Taluk":"Morta","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201206","Taluk":"Murad Nagar","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201305","Taluk":"Dadri","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201305","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201003","Taluk":"Nistoli","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201301","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201301","Taluk":"Noida","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201303","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201307","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201303","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201309","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201005","Taluk":"Pasonda","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201008","Taluk":"Piyaoli","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201017","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201009","Taluk":"Roza Yakubpur","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201005","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201008","Taluk":"Salarpur Kalan","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201002","Taluk":"Shahpur Bamheta","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201103","Taluk":"NA","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201310","Taluk":"A.g.nioda","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201019","Taluk":"Ghaziabad","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"201008","Taluk":"Noida","Districtname":"Gautam Buddha Nagar","statename":"UTTAR PRADESH"},
    {"pincode":"201002","Taluk":"NA","Districtname":"Ghaziabad","statename":"UTTAR PRADESH"},
    {"pincode":"226023","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226004","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226001","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226012","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226303","Taluk":"Mohanlalganj","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226005","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226401","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226203","Taluk":"Bakshi Ka Ta","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226017","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226024","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226009","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226008","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226003","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226501","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226101","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226018","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226028","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226104","Taluk":"Malihabad","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226002","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226201","Taluk":"Bakshi Ka Talab","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226025","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226201","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226301","Taluk":"Mohanlalganj","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226026","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226021","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226501","Taluk":"Mohanlalganj","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226102","Taluk":"Malihabad","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226028","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226015","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226001","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226020","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226103","Taluk":"Malihabad","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226302","Taluk":"Mohanlalganj","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226202","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226016","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226010","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226010","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226006","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226013","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226016","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226019","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226203","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226031","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226022","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226202","Taluk":"Bakshi Ka Talab","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226011","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226301","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226007","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226303","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226203","Taluk":"Bakshi Ka Talab","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226302","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226103","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226014","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226102","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226030","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226027","Taluk":"NA","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"226029","Taluk":"Lucknow","Districtname":"Lucknow","statename":"UTTAR PRADESH"},
    {"pincode":"229001","Taluk":"Rae Bareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229802","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229135","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229316","Taluk":"Rae Bareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229309","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229121","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229206","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229126","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229404","Taluk":"Unchahar **","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229307","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229204","Taluk":"Dalmau","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229103","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229306","Taluk":"Maharajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229404","Taluk":"Unchahar","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229301","Taluk":"Mahrajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229001","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229129","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229210","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229130","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229303","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229305","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229401","Taluk":"Unchahar **","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229128","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229311","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229203","Taluk":"Dalmau","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229308","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229216","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229211","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229123","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229207","Taluk":"Dalmau","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229204","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229801","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229301","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229205","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229122","Taluk":"Raebareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229303","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229208","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229305","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229125","Taluk":"Dalmau","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229802","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229001","Taluk":"Raebarely","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229201","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229402","Taluk":"Unchahar","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229127","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229302","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229405","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229306","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229401","Taluk":"Unchahar","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229202","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229405","Taluk":"Unchahar **","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229316","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229001","Taluk":"Raebareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229310","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229212","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229303","Taluk":"Maharajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229209","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229215","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229406","Taluk":"Unchahar","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229302","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229303","Taluk":"Rae Bareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229406","Taluk":"Unchahar **","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229309","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229204","Taluk":"Unchahar **","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229124","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229401","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229122","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229103","Taluk":"Maharajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229304","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229303","Taluk":"Mahrajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229123","Taluk":"Mahrajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229130","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229204","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229010","Taluk":"Rae Bareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229010","Taluk":"Raebareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229301","Taluk":"Maharajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229202","Taluk":"Maharajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229401","Taluk":"Maharajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229306","Taluk":"Mahrajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229130","Taluk":"Raebarely","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229128","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229405","Taluk":"Rae Bareli","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229304","Taluk":"Tiloi","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229207","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229304","Taluk":"Maharajganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229404","Taluk":"Salon","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229010","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229303","Taluk":"Mrg","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229120","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229124","Taluk":"Dalmau","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229127","Taluk":"Rbl","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229122","Taluk":"NA","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229127","Taluk":"Salo N","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229402","Taluk":"Unchahar **","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229125","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229125","Taluk":"Unchahar **","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229121","Taluk":"Dalmau","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"229203","Taluk":"Lalganj","Districtname":"Raebareli","statename":"UTTAR PRADESH"},
    {"pincode":"261001","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261202","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261301","Taluk":"Sidhauli","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261201","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261208","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261401","Taluk":"Mishrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261135","Taluk":"Laharpur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261121","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261303","Taluk":"Sidhauli","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261405","Taluk":"Mishrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261207","Taluk":"Laharpur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261204","Taluk":"Mahmoodabad","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261131","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261402","Taluk":"Mishrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261403","Taluk":"Mishrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261206","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261141","Taluk":"Mishrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261405","Taluk":"Misrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261203","Taluk":"Mahmudabad","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261204","Taluk":"Mahmudabad","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261151","Taluk":"Mishrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261205","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261302","Taluk":"Sidhauli","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261145","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261125","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261141","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261403","Taluk":"Misrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261404","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261205","Taluk":"Mahmudabad","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261136","Taluk":"Laharpur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261151","Taluk":"Misrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261405","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261302","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261303","Taluk":"Sidhali","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261141","Taluk":"Misrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261207","Taluk":"Biswan","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261001","Taluk":"Sitpaur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261121","Taluk":"Laharpur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261207","Taluk":"Laharp[ur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261207","Taluk":"Sitapur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261401","Taluk":"Misrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261208","Taluk":"Laharpur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261301","Taluk":"Mahmudabad","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261206","Taluk":"Mahmudabad","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261402","Taluk":"Misrikh","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261202","Taluk":"Laharpur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"261135","Taluk":"Laharur","Districtname":"Sitapur","statename":"UTTAR PRADESH"},
    {"pincode":"228001","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227801","Taluk":"Gauriganj","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227813","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227405","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228121","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228132","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228001","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227409","Taluk":"Gauriganj","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227814","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228171","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228151","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227816","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227805","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228161","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228141","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228118","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227304","Taluk":"Lambhua","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228121","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227814","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228120","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228151","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227816","Taluk":"Musafirkna","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228133","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227808","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"222302","Taluk":"Lambhua","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228119","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"222301","Taluk":"Lambhua","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228125","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228131","Taluk":"Kadipiur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228145","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228155","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227812","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228120","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227807","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227407","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228151","Taluk":"Sulatnpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227816","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228142","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227806","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228133","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228131","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227406","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227408","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227413","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"222303","Taluk":"Lambhua","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227815","Taluk":"Sultanpur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227411","Taluk":"Gauraiganj","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227412","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228133","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227408","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228125","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227815","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227809","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228125","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228119","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227412","Taluk":"Gauriganj","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227811","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227808","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227817","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228141","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228001","Taluk":"Lambhua","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228118","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227411","Taluk":"Gauriganj","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228151","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228155","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228142","Taluk":"Sultanpur Sadar","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227408","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"222302","Taluk":"Kadipur","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"228159","Taluk":"Amethi","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"227805","Taluk":"Musafirkhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"222301","Taluk":"Patti","Districtname":"Pratapgarh","statename":"UTTAR PRADESH"},
    {"pincode":"227813","Taluk":"Fikhana","Districtname":"Sultanpur","statename":"UTTAR PRADESH"},
    {"pincode":"263652","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263665","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263646","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263645","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263601","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263676","Taluk":"Salt","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263642","Taluk":"Bageshwar","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263623","Taluk":"Bhanoli","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263641","Taluk":"Garur","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263625","Taluk":"Jainti","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263653","Taluk":"Dwarahat","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263628","Taluk":"Bageshwar","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263632","Taluk":"Kapkote","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263622","Taluk":"Bhanoli","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263626","Taluk":"Jainti","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263621","Taluk":"Dwarahat","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263655","Taluk":"Someshwar","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263634","Taluk":"Kapkote","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263659","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263624","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263636","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263661","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263653","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263622","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263680","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263663","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263678","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263656","Taluk":"Chaukhutia","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263636","Taluk":"Someshwar","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263637","Taluk":"Someshwar","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263619","Taluk":"Bageshwar","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263633","Taluk":"Kapkote","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263640","Taluk":"Kanda","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263679","Taluk":"Kapkote","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263646","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263667","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263635","Taluk":"Garur","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263630","Taluk":"Bageshwar","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263621","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263664","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263629","Taluk":"Someshwar","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263651","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263680","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263667","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263643","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263660","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263659","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263631","Taluk":"Kanda","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263660","Taluk":"Ranikhet","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263624","Taluk":"Bhanoli","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263653","Taluk":"Dawarahat","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263642","Taluk":"Kapkote","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263658","Taluk":"Chaukhutia","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263628","Taluk":"Someshwar","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263653","Taluk":"Dawaraht","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263624","Taluk":"Almora","Districtname":"Bageshwar","statename":"UTTARAKHAND"},
    {"pincode":"263645","Taluk":"Dawarahat","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263639","Taluk":"Someshwar","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263620","Taluk":"Garur","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263639","Taluk":"Garur","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263625","Taluk":"NA","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263638","Taluk":"Someshwar","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263676","Taluk":"Bhikiyasen","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263601","Taluk":"Almora.","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263645","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263628","Taluk":"Someshwar","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"246440","Taluk":"Gairsain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246442","Taluk":"Rudraprayag","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246446","Taluk":"Pokhari","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246428","Taluk":"Gairsain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246439","Taluk":"Okhimath","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246425","Taluk":"Jakholi","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246455","Taluk":"Tharali","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246421","Taluk":"Rudraprayag","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246424","Taluk":"Chamoli","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246486","Taluk":"Gairsain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246471","Taluk":"Okhimath","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246422","Taluk":"Joshimath","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246449","Taluk":"Chamoli","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246444","Taluk":"Karanprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246487","Taluk":"Karanprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246401","Taluk":"Chamoli","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246419","Taluk":"Jakholi","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246427","Taluk":"Tharali","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246443","Taluk":"Joshimath","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246435","Taluk":"Ghat","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246439","Taluk":"Okhimatn","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246425","Taluk":"Okhimath","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246419","Taluk":"Okhimath","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246488","Taluk":"Karanprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246141","Taluk":"Jakholi","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246171","Taluk":"Rudraprayag","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246429","Taluk":"Karanprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246431","Taluk":"Gairsain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246473","Taluk":"Pokhari","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246472","Taluk":"Chamoli","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246444","Taluk":"Gairsain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246429","Taluk":"Rudraprayag","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246475","Taluk":"Jakholi","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246482","Taluk":"Tharali","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246487","Taluk":"Gairsain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246481","Taluk":"Tharali","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246426","Taluk":"Chamoli","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246441","Taluk":"Tharali","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246495","Taluk":"Rudraprayag","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246453","Taluk":"Tharali","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246469","Taluk":"Okhimath","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246446","Taluk":"Karanprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246474","Taluk":"Karanprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246472","Taluk":"Joshimath","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246429","Taluk":"Rudraprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246472","Taluk":"NA","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246444","Taluk":"Tharali","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246401","Taluk":"NA","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246449","Taluk":"Karanprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246429","Taluk":"Pokhari","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246445","Taluk":"Okhimath","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246488","Taluk":"Karnprayag","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246141","Taluk":"Jalholi","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246435","Taluk":"Chamoli","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246448","Taluk":"Rudraprayg","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246487","Taluk":"Gairain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246487","Taluk":"Gaisain","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246449","Taluk":"Ghat","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246471","Taluk":"Ohimath","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246483","Taluk":"Joshimath","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246429","Taluk":"Karanprayag","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246171","Taluk":"Jakholi","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246421","Taluk":"Jakholi","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246475","Taluk":"Rudraprayag","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"246449","Taluk":"Pokhari","Districtname":"Chamoli","statename":"UTTARAKHAND"},
    {"pincode":"246421","Taluk":"NA","Districtname":"Rudraprayag","statename":"UTTARAKHAND"},
    {"pincode":"249403","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"247663","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248121","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248125","Taluk":"Vikasnagar","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248007","Taluk":"Deharadun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249404","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248003","Taluk":"NA","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248199","Taluk":"Tuini","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248001","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248007","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249407","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248125","Taluk":"NA","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249402","Taluk":"Haridwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248158","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248143","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248140","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248005","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247663","Taluk":"Laksar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"249404","Taluk":"Haridwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248196","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248001","Taluk":"Dehardun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248008","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248122","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248124","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248197","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247667","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"247661","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248009","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247668","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248123","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248001","Taluk":"Rishieksh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247671","Taluk":"Laksar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"249401","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248143","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247666","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248199","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248007","Taluk":"Vikasnagar","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248003","Taluk":"Vikasnagar **","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248197","Taluk":"Vikasnagar","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248159","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249204","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248002","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248003","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248145","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248124","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248012","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248165","Taluk":"Chakrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248142","Taluk":"Vikasnagar","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248142","Taluk":"Vikasnagar **","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248143","Taluk":"Rishieksh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249408","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"249402","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"249204","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247670","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"249401","Taluk":"Haridwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"249205","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248179","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248006","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248195","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247665","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248140","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248001","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248159","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248013","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248007","Taluk":"Dehraudun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248197","Taluk":"NA","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247664","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"247656","Taluk":"Roorkee","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248158","Taluk":"NA","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248125","Taluk":"Vikasnagar **","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247665","Taluk":"Roorkee","Districtname":"Saharanpur","statename":"UTTAR PRADESH"},
    {"pincode":"248171","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248196","Taluk":"Cahrata","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"247670","Taluk":"NA","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248014","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249202","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248198","Taluk":"Vikasnagar","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248007","Taluk":"NA","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249203","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249405","Taluk":"Haridwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"249201","Taluk":"Rishikesh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249205","Taluk":"Rishieksh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248197","Taluk":"Vikasnagar **","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249201","Taluk":"Rishieksh","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249410","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248197","Taluk":"Viakasnagar","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248196","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248146","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248011","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"249411","Taluk":"Hardwar","Districtname":"Haridwar","statename":"UTTARAKHAND"},
    {"pincode":"248015","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"248158","Taluk":"Dehradun","Districtname":"Dehradun","statename":"UTTARAKHAND"},
    {"pincode":"263153","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263152","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263157","Taluk":"Dhari","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263159","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263126","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"244716","Taluk":"Kashipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263139","Taluk":"Haldwani","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263136","Taluk":"Dhari","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263148","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263139","Taluk":"Ramnagar","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263140","Taluk":"Kaladhungi","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263159","Taluk":"Kaladhungi","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262310","Taluk":"Champawat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"262401","Taluk":"Kashipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262401","Taluk":"Bazpur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"244712","Taluk":"Jaspur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262405","Taluk":"Sittaganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263128","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262308","Taluk":"Khatima","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263134","Taluk":"Kosya Kutauli","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"244715","Taluk":"Bhikia Sain","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263132","Taluk":"Dhari","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263136","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263132","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262405","Taluk":"Sittarganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262405","Taluk":"Sitarganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262402","Taluk":"Lalkuwan","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263127","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262310","Taluk":"Tanakpur","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"263153","Taluk":"Kashipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"244713","Taluk":"Kashipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"244715","Taluk":"Ramnagar","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263149","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263126","Taluk":"Haldwani","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263158","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263135","Taluk":"Kosya Kutauli","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"244715","Taluk":"Haldwani","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263138","Taluk":"Kosya Kutauli","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263160","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263139","Taluk":"NA","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263140","Taluk":"Haldwani","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263152","Taluk":"Gadarpur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263135","Taluk":"Kushya Kutali","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263134","Taluk":"Betalghat","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263156","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262308","Taluk":"Sitarganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262401","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263126","Taluk":"Dhari","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263145","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262311","Taluk":"Sitarganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"244713","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263001","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"244713","Taluk":"Kshipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"244715","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"244712","Taluk":"Kashipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263135","Taluk":"Kushya Kuali","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263132","Taluk":"Kosya Kutauli","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263150","Taluk":"Bazpur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263159","Taluk":"Haldwani","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263148","Taluk":"Sitarganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262402","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262308","Taluk":"Tanakpur","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"244713","Taluk":"Kichha","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263138","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262311","Taluk":"Sittarganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263138","Taluk":"Koshya Kutali","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263136","Taluk":"Bhimtal","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263159","Taluk":"Ramnagar","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"244715","Taluk":"Ramnagar","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262311","Taluk":"Khatima","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263137","Taluk":"Nainital","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"244717","Taluk":"Kashipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"263134","Taluk":"Betal Ghat","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"263151","Taluk":"Sitarganj","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"244713","Taluk":"Bazpur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"262309","Taluk":"Tanakpur","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"263138","Taluk":"Almora","Districtname":"Almora","statename":"UTTARAKHAND"},
    {"pincode":"263148","Taluk":"Kashipur","Districtname":"Udham Singh Nagar","statename":"UTTARAKHAND"},
    {"pincode":"244715","Taluk":"Ramanagar","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"246155","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246277","Taluk":"Dhumakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246146","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246176","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246285","Taluk":"Thalisain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246121","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246173","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246167","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246149","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246277","Taluk":"Dhoomakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246131","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246179","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246130","Taluk":"Thali Sain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246169","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246275","Taluk":"Thali Sain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246161","Taluk":"Dhumakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246123","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246127","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246128","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246130","Taluk":"Thalisain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246129","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246159","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246278","Taluk":"Thalisain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246172","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246155","Taluk":"Dhoomakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246276","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246175","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246275","Taluk":"Thalisain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246162","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246276","Taluk":"Dhoomakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246144","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246113","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246001","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246276","Taluk":"Thalisain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246275","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246166","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246285","Taluk":"Thali Sain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246164","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246144","Taluk":"Kotdwra","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246161","Taluk":"Dhoomakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246147","Taluk":"Srinagar","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246148","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246147","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249306","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246177","Taluk":"Dhumakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246124","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246194","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246125","Taluk":"Srinagar","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249301","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246275","Taluk":"Dhoomakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249304","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249301","Taluk":"Srinagar **","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246279","Taluk":"Dhoomakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246174","Taluk":"Srinagar","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249301","Taluk":"Thali Sain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246193","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246163","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246142","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246149","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249301","Taluk":"Srinagar","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246174","Taluk":"Srinagar **","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249302","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246279","Taluk":"Dhumakot","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246165","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246147","Taluk":"Srinagar **","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246123","Taluk":"Thali Sain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246113","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246125","Taluk":"Pauri","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246167","Taluk":"Lansdowne","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246172","Taluk":"Kotdwara","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"246177","Taluk":"Thali Sain","Districtname":"Pauri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"262525","Taluk":"Lohaghat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"262551","Taluk":"Didihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262534","Taluk":"Gangolihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262530","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262543","Taluk":"Didihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262542","Taluk":"Didihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262521","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262551","Taluk":"Dharchula","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262524","Taluk":"Lohaghat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"262544","Taluk":"Dharchula","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262552","Taluk":"Berinag","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262545","Taluk":"Dharchula","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262576","Taluk":"Dharchula","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262541","Taluk":"Didihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262532","Taluk":"Gangolihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262501","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262555","Taluk":"Munsiari","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262527","Taluk":"Lohaghat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"262540","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262501","Taluk":"Pithoraharh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262554","Taluk":"Munsiari","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262531","Taluk":"Berinag","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262520","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262529","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262553","Taluk":"Munsiari","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262552","Taluk":"Didihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262528","Taluk":"Lohaghat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"262561","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262522","Taluk":"Gangolihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262502","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262541","Taluk":"NA","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262547","Taluk":"Dharchula","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262523","Taluk":"Champawat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"262533","Taluk":"Berinag","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262523","Taluk":"NA","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262552","Taluk":"NA","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262580","Taluk":"Lohaghat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"262580","Taluk":"Dhari","Districtname":"Nainital","statename":"UTTARAKHAND"},
    {"pincode":"262545","Taluk":"NA","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262544","Taluk":"NA","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262546","Taluk":"Dharchula","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262526","Taluk":"Pithoragarh","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262550","Taluk":"Didihat","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262534","Taluk":"Berinag","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262547","Taluk":"NA","Districtname":"Pithoragarh","statename":"UTTARAKHAND"},
    {"pincode":"262561","Taluk":"Lohaghat","Districtname":"Champawat","statename":"UTTARAKHAND"},
    {"pincode":"249175","Taluk":"Narendra Nagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249155","Taluk":"Ghansali","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249131","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249122","Taluk":"Deoprayag","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249146","Taluk":"Gajja","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249145","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249121","Taluk":"Jakhnidhar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249128","Taluk":"Purola","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249192","Taluk":"Narendranagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249192","Taluk":"Narendra Nagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249196","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249161","Taluk":"Kirtinagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249199","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249171","Taluk":"Burkot","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249132","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249152","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249180","Taluk":"Dhanolti","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249181","Taluk":"Ghansali","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249135","Taluk":"Uttarkashi","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249124","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249122","Taluk":"Devprayag","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249126","Taluk":"Deoprayag","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249193","Taluk":"Uttarkashi","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249171","Taluk":"Purola","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249141","Taluk":"Rajgarhi","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249165","Taluk":"Pratapnagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249135","Taluk":"Bhatwari","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249151","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249125","Taluk":"Ghansali","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249125","Taluk":"Ghanslai","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249193","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249141","Taluk":"Burkot","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249123","Taluk":"Jakhnidhar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249128","Taluk":"Puraula","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249131","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249161","Taluk":"Kirtinagr","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249122","Taluk":"NA","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249185","Taluk":"Purola","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249185","Taluk":"Puraula","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249137","Taluk":"Narendernagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249180","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249130","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249171","Taluk":"Rajgarhi","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249161","Taluk":"Devprayag","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249175","Taluk":"Narendranagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249141","Taluk":"Burkot","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249196","Taluk":"Pratapnagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249135","Taluk":"Uttrkashi","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249121","Taluk":"Jakhnidharr","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249193","Taluk":"Bhatwari","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249135","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249001","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249175","Taluk":"Narender Nagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249175","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249135","Taluk":"Uttarkasshi","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249165","Taluk":"Pratapngar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249180","Taluk":"Dhaolti","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249165","Taluk":"Pratap Nagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249186","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249192","Taluk":"Nrendra Nagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249199","Taluk":"Tehyri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249194","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249194","Taluk":"Uttarkasshi","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249131","Taluk":"Pratap Nagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249195","Taluk":"Bhatwari","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249194","Taluk":"Bhatwari","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249192","Taluk":"Narendra Nagr","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249146","Taluk":"Devprayag","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249128","Taluk":"NA","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249137","Taluk":"Narendranagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249196","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249001","Taluk":"Partapnagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249165","Taluk":"Dunda","Districtname":"Uttarkashi","statename":"UTTARAKHAND"},
    {"pincode":"249186","Taluk":"Dhanoulti","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249131","Taluk":"Pratapnagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249123","Taluk":"Tehri","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249145","Taluk":"Dhanolti","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249146","Taluk":"NA","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249155","Taluk":"Ghansali **","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249132","Taluk":"Their","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249145","Taluk":"NA","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249165","Taluk":"Dunda","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"249123","Taluk":"Pratapnagar","Districtname":"Tehri Garhwal","statename":"UTTARAKHAND"},
    {"pincode":"700027","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700007","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Kuliadanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700155","Taluk":"Amdanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Adampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743221","Taluk":"Adhata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743248","Taluk":"Adhikashimpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700131","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Ajijnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743710","Taluk":"Akaipur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Akandakeshari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743263","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Amandikandi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Amberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Ambikapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Amdobe","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Amodpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743445","Taluk":"Amta","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Amudia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Baduria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Angrail","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743437","Taluk":"Basirhat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743221","Taluk":"Amdanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Asharu","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743222","Taluk":"Habra - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Atapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743438","Taluk":"Atghara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Atpukur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Aturia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743292","Taluk":"Basirhat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700128","Taluk":"Barasat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Bagangram","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Bagdah","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Bagu","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Bhangatushkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Baidyapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Baikara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743262","Taluk":"Bairampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Bajitpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Bakra Dabar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Baksha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Baliadanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Balisha Chowmatha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Ballavpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Balti","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743248","Taluk":"Bamangachi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Bamanibad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Bamanpukur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743270","Taluk":"Baneswarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Bongaon","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Bangaon","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743286","Taluk":"Banglani","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743233","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Bankra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Banshtala","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Barabankra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700124","Taluk":"Barasat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Barghorai","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743262","Taluk":"Barrackpore Sripalli","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Barunhat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743411","Taluk":"Basirhat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743412","Taluk":"Basirhat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Beara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Mohanpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743437","Taluk":"Begumpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Deganga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Belpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743701","Taluk":"Belta","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Bena","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743711","Taluk":"Beraberi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743270","Taluk":"Berabari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743263","Taluk":"Bergoom","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Bermajur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743445","Taluk":"Bhabanipur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Basirhat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Bhaduria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700128","Taluk":"Bhagyamantapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Bhanderkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Bhanderkhola","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Bharatpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Bhasila","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743270","Taluk":"Bhawanipur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Basirhat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Baduria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Bhurkunda","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743234","Taluk":"Bira","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Biramnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Bishpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743286","Taluk":"Bithari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Boaldah","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700126","Taluk":"Bodai","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743294","Taluk":"Bohara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Bowthakurani","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Boyermari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Boyra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Buruj","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743405","Taluk":"Chaigharia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743445","Taluk":"Chaita","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Chaital","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Chakla","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Chakpatli","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743291","Taluk":"Basirhat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Chandpara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Hingalganj","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Chandipur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Rajarhat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Chjandpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Chandpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Charghat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Chekati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743294","Taluk":"Chhoto Jagulia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743287","Taluk":"Chikanpara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Chimta Kachari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Chorashi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Choto Sahebkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Choto Sehara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Chowberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Chowhata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743287","Taluk":"Ramchandra Pur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Colony Simulia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Akratola","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743429","Taluk":"Bagundi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Bhebia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Chatra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Sandeshkhali - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Sandeshkhali - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Panchpota","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743412","Taluk":"Dandirhat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743711","Taluk":"Dariapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Deara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Debalaya","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Deuli","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Gaighata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743412","Taluk":"Dhaltita","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743437","Taluk":"Dhanyakuria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Dharamberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Dharampukuria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Dharampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Dheknamari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Dhuchnikhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Dhutradaha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743248","Taluk":"Digha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Digha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Dighari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Dogachia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Monmohanpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Dulduli Mathbari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Duma","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Durgamandop","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743286","Taluk":"Duttapara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743248","Taluk":"Barasat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Dwarikjungle","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"East Bishnupur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700129","Taluk":"Barasat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743293","Taluk":"Eojnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743263","Taluk":"Fultala","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743710","Taluk":"Gadpukuria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Gaighata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743252","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Gandharbapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700132","Taluk":"Barasat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Ganrapota","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700132","Taluk":"Ganti","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Garakupi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Gazipur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Ghatbour","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Ghatihara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Ghatpatila","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743445","Taluk":"Ghona","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Ghoni","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Ghonza","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Ghosalbati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Ghoshpara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743289","Taluk":"Ghoshpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Goalbathan","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Goaldaha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743252","Taluk":"Gobardanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Gobardhanpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Gobila","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Gobindapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Gobrapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743293","Taluk":"Gokna","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743286","Taluk":"Gokulpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Barasat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743262","Taluk":"Gopalnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743445","Taluk":"Gopalpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743710","Taluk":"Goribpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Gotra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Gotri","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743445","Taluk":"Basirhat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743704","Taluk":"Guma","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743268","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Deganga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Hakimpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743223","Taluk":"Habra - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Haroa","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Rampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Hasnabad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Hatgachha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Hatisala","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Hatkurulia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743270","Taluk":"H Colony","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Hemnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743271","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Hingalganj","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Hingli","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743221","Taluk":"Hishabi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700127","Taluk":"Barasat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Ichapurabad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743222","Taluk":"Iswarigacha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743292","Taluk":"Itinda","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743293","Taluk":"Jadurhati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Jagadishpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700126","Taluk":"Jagannathpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Jaleswar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743263","Taluk":"Janaphul","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743438","Taluk":"Jangalpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Jasaikati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743233","Taluk":"Jeoldanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Jhawdanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Jhikra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Jhopkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Jogeshganj","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Joygopalpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Joygram","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743234","Taluk":"Joypul","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Kachdaha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Kachua","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743293","Taluk":"Kachua Swarupnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700125","Taluk":"Kadambagachi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Kadampukur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Kahankia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Kaijuri","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Kaipukuria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Kaliani","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Kalinagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Kalitala","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Kalupur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743272","Taluk":"Habra - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743438","Taluk":"Kamdevkati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700125","Taluk":"Kamdevpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Kamduni","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743711","Taluk":"Habra - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Kanmari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743286","Taluk":"Kantabagan","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Kapileswarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Karanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743412","Taluk":"Karnolkora","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Kashinathpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Kathur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Katiahat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700125","Taluk":"Barasat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Khalisadi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743405","Taluk":"Khalitpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Kharampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Kharihatabad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Kharua Rajapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Kharupala","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Khasbalanda","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700125","Taluk":"Khilkapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743428","Taluk":"Kholapota","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Khulna","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700128","Taluk":"Kirtipur`","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743429","Taluk":"Hasnabad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700131","Taluk":"Barrackpur - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743438","Taluk":"Deganga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Koniara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700130","Taluk":"Barrackpur - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Korakati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Kothabari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Krishnachandapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743270","Taluk":"Kulia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743270","Taluk":"Kumarkhola","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Kumarzole","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743271","Taluk":"Kumra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Labukhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743234","Taluk":"Laxmipul","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Machibhanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700128","Taluk":"Madanpur Krishnapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Madhavkathi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700130","Taluk":"Barasat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Swarupnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743262","Taluk":"Madiahat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Bagda","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Makhalgacha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Malancha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Malekangumti","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Malidah","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Malikagherai","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700126","Taluk":"Malikapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Mambhagina","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Manikkathi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743438","Taluk":"Mandra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743263","Taluk":"Habra - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743232","Taluk":"Mashyampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743289","Taluk":"Habra - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Maslishpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743711","Taluk":"Masunda","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Mathbariabad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743437","Taluk":"Matia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Matiaghata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743292","Taluk":"Merudandi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700133","Taluk":"Barasat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743411","Taluk":"Minakhan","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700125","Taluk":"Mirhati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743291","Taluk":"Mominpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743297","Taluk":"Bagda","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700128","Taluk":"Mudiahat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Murarisah","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Bongaon","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700126","Taluk":"Barasat - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Naahata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Nakpul","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743252","Taluk":"Nakpul Kuchlia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743294","Taluk":"Naksha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Nalkora","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Minakhan","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700136","Taluk":"Rajarhat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743270","Taluk":"Nataberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Natagram","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Naya Basti","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Nazat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743437","Taluk":"Nehalpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743294","Taluk":"Nimdaria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Nimichi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743286","Taluk":"Swarupnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Nityaberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Nityananda Kati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700125","Taluk":"Noapara Madhavpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700125","Taluk":"Noapara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Nurpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Bongaon","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Bhangar - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Paikparahat","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743701","Taluk":"Palla","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743710","Taluk":"Panchberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Panchita","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Panchpota","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743292","Taluk":"Panitar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Parghumti","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743438","Taluk":"Parpatna","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743429","Taluk":"Paschim Madhyampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Paschim Malikberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Paschim Palta","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Patharghata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Pathghoria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Pathsimulia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743405","Taluk":"Petrapole","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Phazilpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743456","Taluk":"Minakhan","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Pifa","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Pithapukuria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743704","Taluk":"Prithiba","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Punra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Purandarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743235","Taluk":"Purantan Bongaon","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Purba  Khejurberia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Purba Sankardaha","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743263","Taluk":"Putia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Raghunathpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743428","Taluk":"Raghunathpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Raikhan","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Bishnupur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743289","Taluk":"Rajballapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743291","Taluk":"Rajendrapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743702","Taluk":"Rajibpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Ramchandrapur Khaspur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Rameswarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Ramnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Ramnathpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743249","Taluk":"Rampur Bhatpara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Ramshankarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Ranihati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Rohanda","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743234","Taluk":"Rowtara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Rupmari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743222","Taluk":"Sabdalpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743221","Taluk":"Sadhanpur Uludanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Safirabad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Saguna","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Sahebkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Sakdah Jadapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743247","Taluk":"Salua","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743263","Taluk":"Salua","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Samsernagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743272","Taluk":"Samudrapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Sandelerbill","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Sandeshkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Sangrampur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743286","Taluk":"Sanrapul","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Sashan","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Sasadanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Satashi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743262","Taluk":"Satberi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Satbhaya","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743427","Taluk":"Sayestanagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743424","Taluk":"Secondranagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743272","Taluk":"Sendanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743234","Taluk":"Sethpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Shyamnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743292","Taluk":"Sibhati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Sikharpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743428","Taluk":"Basirhat - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743262","Taluk":"Simulia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Simulia Durgapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Simulia Kalibari","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743289","Taluk":"Simulpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743297","Taluk":"Sindrani","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Sitalia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743445","Taluk":"Sodarpur-behala","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743423","Taluk":"Sohaikumarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743429","Taluk":"Soladanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743271","Taluk":"Sonakenia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743701","Taluk":"Sonekpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Charalkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743442","Taluk":"Sandeshkhali - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743439","Taluk":"Sridharkati","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743711","Taluk":"Srikrishnapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743428","Taluk":"Srinagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Sukdwani","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743701","Taluk":"Durgapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743426","Taluk":"Sulkaniabad","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Sundarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743711","Taluk":"Suria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743273","Taluk":"Sutia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743435","Taluk":"Akimamudpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743429","Taluk":"Takipur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743234","Taluk":"Talsa","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743251","Taluk":"Tangra","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743401","Taluk":"Taragunia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743290","Taluk":"Taranipur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743245","Taluk":"Tetulbaria","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743287","Taluk":"Gaighata","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743297","Taluk":"Thoara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743446","Taluk":"Tushkhali","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743425","Taluk":"Uchildah","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743289","Taluk":"Uludanga","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700135","Taluk":"Urttar Kashipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743428","Taluk":"Zafarpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743422","Taluk":"Zirakpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"731303","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731121","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731204","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731201","Taluk":"NA","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731130","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731302","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731222","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731219","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731127","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731242","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731233","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731243","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731101","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731125","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731234","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731201","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731219","Taluk":"Murarai - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731240","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731123","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731215","Taluk":"Nanoor","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731237","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731221","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731103","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731202","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731223","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731301","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731220","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731245","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731215","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731236","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731243","Taluk":"Nalhati - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731126","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731238","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731216","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731102","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731244","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731104","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731204","Taluk":"Bolpur Sriniketan","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731213","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731302","Taluk":"Nanoor","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731301","Taluk":"Nanoor","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731233","Taluk":"Rampurhat - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731102","Taluk":"Suri - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731241","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731104","Taluk":"Dubrajpur","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731214","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731133","Taluk":"NA","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731129","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731124","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731132","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731234","Taluk":"Mayureswar - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731102","Taluk":"Suri - II","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731201","Taluk":"Sainthia","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731123","Taluk":"Dubrajpur","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731130","Taluk":"Rajnagar","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731303","Taluk":"Labpur","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731224","Taluk":"Rampurhat - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731127","Taluk":"Suri - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731133","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731235","Taluk":"Birbhum","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731101","Taluk":"Suri - I","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731234","Taluk":"Sainthia","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"731235","Taluk":"Bolpur Sriniketan","Districtname":"Birbhum","statename":"WEST BENGAL"},
    {"pincode":"700020","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700044","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700086","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700022","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700019","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700008","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700018","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700034","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700025","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700066","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700032","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700042","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700088","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700031","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700029","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700107","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700047","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700024","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700075","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700095","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700078","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700082","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700033","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700061","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700068","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700053","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700063","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700026","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700099","Taluk":"NA","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700023","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700045","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700060","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700099","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700040","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700094","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700041","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700092","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700038","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700043","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700014","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700012","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700073","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700001","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700013","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700016","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700069","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700021","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700072","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700071","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700087","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700001","Taluk":"NA","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700017","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700062","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700046","Taluk":"A. C Lane","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700059","Taluk":"D B Nagr","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700159","Taluk":"Deshbandhunagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700055","Taluk":"Jessore Road","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700039","Taluk":"Bediadanga","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700010","Taluk":"Beleghata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700054","Taluk":"Kankurgachi","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700064","Taluk":"Saltlake","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700091","Taluk":"Saltlake","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700106","Taluk":"Saltlake","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700064","Taluk":"Sakltlake","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700098","Taluk":"Saltlake","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700059","Taluk":"D B Nagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700105","Taluk":"Dhapa","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700017","Taluk":"Circus Avenue","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700054","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700059","Taluk":"24 Pgs North","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700055","Taluk":"Dum Dum Park","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700046","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700157","Taluk":"North 24 Pgs","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700010","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700085","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700089","Taluk":"North 24 Pgs","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700102","Taluk":"North 24 Pgs","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700089","Taluk":"North 24 Pgs","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700091","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700011","Taluk":"Narkeldanga","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700160","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700156","Taluk":"New Town","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700105","Taluk":"Dhapa","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700105","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700017","Taluk":"Park Circus","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700009","Taluk":"Parsibagan","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700048","Taluk":"Patipukur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700054","Taluk":"Phulbagan","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700039","Taluk":"Picnic Garden","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700101","Taluk":"North 24 Pgs","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700097","Taluk":"Saltklake","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700009","Taluk":"Amherst Street","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700048","Taluk":"24 Pgs North","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700015","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700012","Taluk":"Sankaritola","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700014","Taluk":"Sealdah","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700055","Taluk":"North 24 Pgs","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700048","Taluk":"Sreebhumi","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700014","Taluk":"Taltala","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700015","Taluk":"NA","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700039","Taluk":"Tiljala","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700039","Taluk":"Topsia","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700100","Taluk":"Vip Nagar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700005","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700035","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700003","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700108","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700036","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700006","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700077","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700037","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700002","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700074","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700028","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700065","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700030","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700080","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700052","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700067","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700090","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700004","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"700050","Taluk":"Kolkata","Districtname":"Kolkata","statename":"WEST BENGAL"},
    {"pincode":"742122","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742227","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742226","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742223","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742135","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742224","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742301","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742123","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742406","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742136","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742202","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742121","Taluk":"Nawda","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742168","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742189","Taluk":"NA","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742189","Taluk":"Beldanga - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742138","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742148","Taluk":"Lalgola","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742159","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742201","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742147","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742302","Taluk":"Raninagar - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742187","Taluk":"Hariharpara","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742308","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742163","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742212","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742138","Taluk":"Suti - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742223","Taluk":"Suti - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742148","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742165","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742237","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742302","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742306","Taluk":"Jalangi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742102","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742235","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742213","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742189","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742132","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742406","Taluk":"Bhagawangola - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742187","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742133","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742175","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742133","Taluk":"Beldanga - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742140","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742101","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742134","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742134","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742135","Taluk":"Bhagawangola - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742302","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742159","Taluk":"Khargram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742149","Taluk":"Murshidabad Jiaganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742303","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742184","Taluk":"Nabagram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742301","Taluk":"Bharatpur - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742168","Taluk":"Burwan","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742102","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742308","Taluk":"Raninagar - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742138","Taluk":"Kandi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742175","Taluk":"Nawda","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742161","Taluk":"Burwan","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742202","Taluk":"Suti - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742407","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742184","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742223","Taluk":"Lalgola","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742306","Taluk":"Raninagar - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742405","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742302","Taluk":"Bhagawangola - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742138","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742166","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742305","Taluk":"Jalangi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742140","Taluk":"Bharatpur - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742213","Taluk":"Raghunathganj - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742104","Taluk":"Murshidabad Jiaganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742405","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742102","Taluk":"Murshidabad Jiaganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742104","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742305","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742189","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742103","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742174","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742113","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742302","Taluk":"Murshidabad Jiaganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742306","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742134","Taluk":"Suti - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742113","Taluk":"Suti - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742401","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742202","Taluk":"Farakka","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742226","Taluk":"Sagardighi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742174","Taluk":"Nawda","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742187","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742132","Taluk":"Burwan","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742147","Taluk":"Khargram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742104","Taluk":"Nabagram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742212","Taluk":"Farakka","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742225","Taluk":"Raghunathganj - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742303","Taluk":"Domkal","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742121","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742213","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742136","Taluk":"Kandi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742121","Taluk":"Domkal","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742122","Taluk":"NA","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742304","Taluk":"Raninagar - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742161","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742304","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742149","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742308","Taluk":"Raninagar - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742201","Taluk":"Murshidabad Jiaganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742224","Taluk":"Sagardighi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742223","Taluk":"Raghunathganj - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742213","Taluk":"Raghunathganj - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742140","Taluk":"Kandi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742121","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742137","Taluk":"Kandi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742137","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742237","Taluk":"Sagardighi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742404","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742168","Taluk":"Khargram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742202","Taluk":"Samserganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742151","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742225","Taluk":"Raghunathganj - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742136","Taluk":"Nabagram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742304","Taluk":"Raninagar - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742161","Taluk":"Kandi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742236","Taluk":"Farakka","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742135","Taluk":"Bhagawangola - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742160","Taluk":"Murshidabad Jiaganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742224","Taluk":"Jalangi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742224","Taluk":"Samserganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742165","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742223","Taluk":"Sagardighi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742227","Taluk":"Raghunathganj - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742140","Taluk":"Berhampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742165","Taluk":"Hariharpara","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742304","Taluk":"Domkal","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742238","Taluk":"Palsanda","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742201","Taluk":"Suti - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742201","Taluk":"Khargram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742225","Taluk":"Murshidabad","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742223","Taluk":"Raghunathganj - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742306","Taluk":"Bhagawangola - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742175","Taluk":"Raghunathganj - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742164","Taluk":"Murshidabad Jiaganj","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742151","Taluk":"Lalgola","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742122","Taluk":"Sagardighi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742163","Taluk":"Beldanga - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742166","Taluk":"Hariharpara","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742408","Taluk":"Behrampore","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742148","Taluk":"Domkal","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742235","Taluk":"Raghunathganj - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742409","Taluk":"Raninagar-II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742306","Taluk":"Farakka","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742161","Taluk":"Nabagram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742405","Taluk":"Beldanga - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742402","Taluk":"Bharatpur - II","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742235","Taluk":"Sagardighi","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742187","Taluk":"Nabagram","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"742175","Taluk":"Beldanga - I","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"741137","Taluk":"Nakashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741137","Taluk":"Kaliganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741317","Taluk":"Krishnagar - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741315","Taluk":"Krishnagar-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741101","Taluk":"Krishnagar - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741165","Taluk":"Karimpur Ii (mohisbatan)","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741125","Taluk":"Krishnagar - II","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741122","Taluk":"Karimpur I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741126","Taluk":"Debagram","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741161","Taluk":"Krishnanagar-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741316","Taluk":"Nabadwip","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741123","Taluk":"Chapra","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741125","Taluk":"Dhubulia","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741154","Taluk":"Nakashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741152","Taluk":"Karimpur I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741155","Taluk":"Tehatta I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741156","Taluk":"Nowda (murshidabad)","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741152","Taluk":"Karimpur Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741153","Taluk":"Kaliganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741313","Taluk":"Sreemayapur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741126","Taluk":"Nakashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741124","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741124","Taluk":"Nakashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741150","Taluk":"Kaliganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741156","Taluk":"Kaliganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741165","Taluk":"Karimpur Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741156","Taluk":"Tehatta Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741156","Taluk":"Plashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741155","Taluk":"Tehatta Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741125","Taluk":"Krishnanagar Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741103","Taluk":"Krishnanagar I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741163","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741181","Taluk":"Kaliganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741102","Taluk":"Krishnanagar I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741155","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741167","Taluk":"Krishnaganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741157","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741319","Taluk":"Burdwan","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741138","Taluk":"Nakashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741302","Taluk":"Nabadwip","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741162","Taluk":"Nakashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741160","Taluk":"Tehatta I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741301","Taluk":"Nabadwip","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741103","Taluk":"Krishnagar - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741124","Taluk":"Chapra","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741140","Taluk":"Krishnangar Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741158","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741164","Taluk":"Chapra","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741156","Taluk":"Palashipara","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741103","Taluk":"Tehatta I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741137","Taluk":"Debagram","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741139","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741140","Taluk":"Krishnagar - II","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741102","Taluk":"Dogachhi","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741315","Taluk":"Nabadwip","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741313","Taluk":"Nabadwip","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741154","Taluk":"Dhubulia","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741103","Taluk":"Krishnanagar Municipality","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741160","Taluk":"Tehatta Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741102","Taluk":"Krishnagar I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741317","Taluk":"Krishnanagar I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741102","Taluk":"Hanskhali","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741150","Taluk":"Debagram","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741166","Taluk":"Chapra","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741102","Taluk":"Krishnagar","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741154","Taluk":"Krishnanagar Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741158","Taluk":"Karimpur I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741161","Taluk":"Krishnanagar I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741163","Taluk":"Tehatta - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741103","Taluk":"Krishnagar-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741165","Taluk":"Tehatta I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741302","Taluk":"Nabadwip Municipality","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741101","Taluk":"Krishnanagar Municipality","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741160","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741122","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741165","Taluk":"Karimpur I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741140","Taluk":"Krishnanagar Ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741137","Taluk":"Kalligang","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741156","Taluk":"Debagram","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741126","Taluk":"Bethuadahari","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741157","Taluk":"Kaliganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741103","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741165","Taluk":"Tehatta","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741152","Taluk":"Chapra","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741158","Taluk":"Shikarpur Bdo I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741156","Taluk":"Nowda","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741316","Taluk":"Purbasthali I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741103","Taluk":"Chapra","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741124","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741165","Taluk":"Nazrpur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741402","Taluk":"Santipur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741245","Taluk":"Chakdah","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741245","Taluk":"Chakdaha","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741253","Taluk":"Ranaghat-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741255","Taluk":"Ranaghat-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741501","Taluk":"Ranaghat - II","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741121","Taluk":"Hanskhali","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741404","Taluk":"Santipur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741502","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741223","Taluk":"Chakdaha","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741503","Taluk":"Krishnaganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741221","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741504","Taluk":"Ranaghat - II","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741507","Taluk":"Santipur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741250","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741256","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741502","Taluk":"Hanskhali","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741505","Taluk":"Hanskhlai","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741121","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741248","Taluk":"Chakdah","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741507","Taluk":"Krishnaganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741235","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741127","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741245","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741201","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741121","Taluk":"Santipur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741127","Taluk":"Ranaghat-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741222","Taluk":"Chakdah","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741121","Taluk":"Ranaghat-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741509","Taluk":"Krishnaganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741248","Taluk":"Chakdaha","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741202","Taluk":"Ranaghat-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741151","Taluk":"Hanskhali","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741251","Taluk":"Chakdah","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741501","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741232","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741505","Taluk":"Hanskhali","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741504","Taluk":"Hanskhali","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741256","Taluk":"Ranaghat - II","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741238","Taluk":"Ranaghat-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741508","Taluk":"Krishnaganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741257","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741401","Taluk":"Santipur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741506","Taluk":"Krishnaganj","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741504","Taluk":"Ranaghat-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741249","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741507","Taluk":"Hanskhali","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741238","Taluk":"Ranaghat - II","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741234","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741507","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741223","Taluk":"Chakdah","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741402","Taluk":"Ranaghat-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741202","Taluk":"Ranagaht-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741246","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741501","Taluk":"Ranaghat-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741202","Taluk":"Santipur","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741202","Taluk":"Ranaghat-i","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741254","Taluk":"Ranaghat-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741252","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741507","Taluk":"Chakdah","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741202","Taluk":"Ranaghat - II","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741246","Taluk":"Haringhat","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741249","Taluk":"Haringhat","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741248","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741202","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741251","Taluk":"Haringhata","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741255","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741402","Taluk":"Ranaghat-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741247","Taluk":"Ranaghat","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741503","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741223","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741256","Taluk":"Ranaghat-ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741201","Taluk":"Ranaghat-i/ii","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741506","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741159","Taluk":"NA","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"741402","Taluk":"Ranaghat - I","Districtname":"Nadia","statename":"WEST BENGAL"},
    {"pincode":"700109","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743144","Taluk":"Ichapur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700122","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700057","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743128","Taluk":"Athpur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700116","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743145","Taluk":"Kanchrapara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700119","Taluk":"Barrackpur - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700114","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743145","Taluk":"Barrackpur - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700120","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700123","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700056","Taluk":"Belgharia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700056","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743122","Taluk":"Palta","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700121","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743123","Taluk":"Bhatpara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700110","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700051","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700119","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700158","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700119","Taluk":"Titagarh]","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743145","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700076","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743130","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700083","Taluk":"North 24 Parganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700058","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743124","Taluk":"Jagatdal","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743127","Taluk":"Shyamnagar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743129","Taluk":"Fingapara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743133","Taluk":"Garulia","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700111","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743125","Taluk":"Jagatdal","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743166","Taluk":"Gorifa","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743127","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743134","Taluk":"Halishahar","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743135","Taluk":"Barrackpur - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700079","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700122","Taluk":"N.c.pukur","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700117","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700110","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700058","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743193","Taluk":"Kanchrapara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743126","Taluk":"Kankinara","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700113","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743194","Taluk":"Jagatdal","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743126","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700117","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743166","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743135","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743126","Taluk":"Barrackpur - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743144","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700120","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743123","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743136","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743165","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700083","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700134","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700049","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700122","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700057","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700112","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700119","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743133","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743165","Taluk":"Barrackpur - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743127","Taluk":"Barrackpur - I","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700118","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700081","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743194","Taluk":"NA","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700056","Taluk":"NORTH 24 PARGANAS","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700056","Taluk":"North 24 Parganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700110","Taluk":"North 24 parganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700115","Taluk":"North 24 Paraganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700117","Taluk":"North 24 Parganas","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700049","Taluk":"Barrackpore","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743368","Taluk":"Diamond Harbour - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700138","Taluk":"Gosaba","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743383","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743329","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700140","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743347","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743355","Taluk":"Baruipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700104","Taluk":"Bishnupur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743368","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743370","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743337","Taluk":"Canning - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743378","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743378","Taluk":"Gosaba","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743312","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743504","Taluk":"Falta","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743336","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743503","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743377","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700141","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743374","Taluk":"Kulpi","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743513","Taluk":"Falta","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743349","Taluk":"Mathurapur - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743357","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743372","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743610","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743399","Taluk":"Kulpi","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743318","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743336","Taluk":"Kulpi","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743354","Taluk":"Mathurapur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743338","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743377","Taluk":"Bakrahat","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743349","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743338","Taluk":"Jaynagar - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743370","Taluk":"Gosaba","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700145","Taluk":"Sonarpur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743373","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743355","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743502","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743371","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743337","Taluk":"Jaynagar - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743375","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743513","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700070","Taluk":"Budge Budge - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743363","Taluk":"Canning","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743337","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743336","Taluk":"Mathurapur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743332","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700152","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743387","Taluk":"Baruipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700144","Taluk":"Baruipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743611","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743611","Taluk":"Baruipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700140","Taluk":"Bishnupur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743354","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700137","Taluk":"Budge Budge - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700137","Taluk":"Budge Budge - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743395","Taluk":"Mandirbazar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743387","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743337","Taluk":"Jaynagar - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743376","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743348","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743504","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743613","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700138","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743609","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743399","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743371","Taluk":"Mathurapur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743399","Taluk":"Patharpratima","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743502","Taluk":"Bhangar - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743503","Taluk":"Bishnupur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743399","Taluk":"Mathurapur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743383","Taluk":"Kultali","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743347","Taluk":"Kakdwip","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743345","Taluk":"Mandirbazar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743318","Taluk":"Budge Budge - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743503","Taluk":"Bishnupur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743338","Taluk":"Budge Budge - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700103","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700154","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743349","Taluk":"Mathurapur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700096","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700137","Taluk":"Budge Budge","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700137","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743329","Taluk":"Canning - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743395","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743503","Taluk":"Bishnupur - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743373","Taluk":"Sagar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743330","Taluk":"Champahati","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743330","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743357","Taluk":"Namkhana","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743503","Taluk":"Falta","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743338","Taluk":"Kultali","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700149","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743374","Taluk":"Kakdwip","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700145","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743331","Taluk":"Diamond Harbour - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700151","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743611","Taluk":"Canning - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700139","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743349","Taluk":"Kultali","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743376","Taluk":"Canning - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743355","Taluk":"Magrahat - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743355","Taluk":"Magrahat - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743610","Taluk":"Baruipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743351","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743347","Taluk":"Namkhana","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743611","Taluk":"Sandeshkhali - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700084","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743374","Taluk":"Sagar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743332","Taluk":"Magrahat - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743356","Taluk":"Sagar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743383","Taluk":"Mathurapur - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743368","Taluk":"Diamond Harbour - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743372","Taluk":"Jaynagar - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743363","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743332","Taluk":"Kulpi","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700150","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700148","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700104","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743348","Taluk":"Kulpi","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743336","Taluk":"Mandirbazar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743329","Taluk":"Sandeshkhali - II","Districtname":"North 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743312","Taluk":"Basanti","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743376","Taluk":"Canning - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743371","Taluk":"Patharpratima","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743355","Taluk":"Mandirbazar","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743329","Taluk":"Kultali","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743609","Taluk":"Magrahat - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700070","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743330","Taluk":"Sonarpur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743329","Taluk":"Canning - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700150","Taluk":"Sonarpur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743345","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743354","Taluk":"Mathurapur - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700146","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743368","Taluk":"Magrahat - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743502","Taluk":"Bhangar - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700153","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743355","Taluk":"Magrahat Taluk","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700145","Taluk":"Baruipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743374","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743318","Taluk":"Budge Budge - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743329","Taluk":"Basanti","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743503","Taluk":"Magrahat - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743504","Taluk":"Diamond Harbour - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700140","Taluk":"Budge Budge - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743347","Taluk":"Patharpratima","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743395","Taluk":"Magrahat - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700138","Taluk":"Budge Budge - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700093","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743399","Taluk":"Kakdwip","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743351","Taluk":"Kulpi","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743374","Taluk":"Budge Budge - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743610","Taluk":"Magrahat - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700142","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743375","Taluk":"Magrahat - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743377","Taluk":"Jaynagar - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700143","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743395","Taluk":"Mathurapur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743513","Taluk":"Mathurapur - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743513","Taluk":"Magrahat - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743337","Taluk":"Kultali","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743377","Taluk":"Budge Budge - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743613","Taluk":"Baruipur","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743610","Taluk":"Magrahat - II","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700147","Taluk":"NA","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"743338","Taluk":"Jaynagar - I","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"700141","Taluk":"Thakurpukur Mahestola","Districtname":"South 24 Parganas","statename":"WEST BENGAL"},
    {"pincode":"744104","Taluk":"Port  Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744202","Taluk":"Diglipur","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744205","Taluk":"Rangat","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744107","Taluk":"Ferrargunj","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744206","Taluk":"Ferrargunj","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744105","Taluk":"Port Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744201","Taluk":"Rangat","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744103","Taluk":"Port Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744302","Taluk":"Nancowrie","Districtname":"Nicobar","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744301","Taluk":"Carnicobar","Districtname":"Nicobar","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744112","Taluk":"Portblair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744303","Taluk":"Nancowrie","Districtname":"Nicobar","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744102","Taluk":"Portblair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744102","Taluk":"Port Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744211","Taluk":"Port Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744103","Taluk":"Ferrargunj","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744105","Taluk":"Ferrargunj","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744207","Taluk":"Hut Bay","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744209","Taluk":"Rangat","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744210","Taluk":"Port Blair","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744304","Taluk":"Nancowrie","Districtname":"Nicobar","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744203","Taluk":"Rangat","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744101","Taluk":"Portblair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744204","Taluk":"Mayabander","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744103","Taluk":"Portblair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744104","Taluk":"Port Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744201","Taluk":"Mayabander","Districtname":"North And Middle Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744101","Taluk":"Port Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744302","Taluk":"Nancorie","Districtname":"Nicobar","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744106","Taluk":"Port Blair","Districtname":"South Andaman","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"744303","Taluk":"Carnicobar","Districtname":"Nicobar","statename":"ANDAMAN & NICOBAR ISLANDS"},
    {"pincode":"736156","Taluk":"Cooch Behar","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736157","Taluk":"Mathabhanga - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736122","Taluk":"Alipurduar - I","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736123","Taluk":"Alipurduar - I","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736121","Taluk":"Alipurduar - I","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736133","Taluk":"Cooch Behar","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736160","Taluk":"Tufanganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736146","Taluk":"Mathabhanga","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736159","Taluk":"Tufanganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736157","Taluk":"Mathabhanga","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735217","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736145","Taluk":"NA","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736131","Taluk":"Tufanganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736206","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735227","Taluk":"Kalchini","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736135","Taluk":"Dinhata","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736135","Taluk":"NA","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736168","Taluk":"Dinhata - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736158","Taluk":"Sitalkuchi","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736122","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736101","Taluk":"Cooch Behar - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736206","Taluk":"Alipurduar - II","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736172","Taluk":"Mathabhanga`","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736135","Taluk":"Dinhata - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736145","Taluk":"Dinhata","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736121","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736169","Taluk":"Dinhata","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736207","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736203","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736172","Taluk":"Mathabhanga","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736159","Taluk":"Tufanganj - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736208","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736134","Taluk":"Dinhata - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736123","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736134","Taluk":"Dinhata","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736167","Taluk":"Sitai","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736202","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736167","Taluk":"Dinhata","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736165","Taluk":"Cooch Behar","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736168","Taluk":"Dinhata","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736134","Taluk":"Tufanganj - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736206","Taluk":"Alipurduar","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736160","Taluk":"Tufanganj - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736165","Taluk":"Cooch Behar - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736157","Taluk":"Cooch Behar - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735217","Taluk":"Kalchini","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736168","Taluk":"Dinhata - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736201","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736159","Taluk":"Tufanganj - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736204","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736202","Taluk":"Kumargram","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735214","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735208","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736156","Taluk":"Tufanganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736134","Taluk":"Cooch Behar - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736157","Taluk":"Cooch Behar - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736170","Taluk":"Cooch Behar","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735217","Taluk":"Dinhata - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736171","Taluk":"Mathabhanga - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736172","Taluk":"Sitalkuchi","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736145","Taluk":"Dinhata - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736170","Taluk":"Cooch Behar - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735214","Taluk":"Alipurduar - I","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735215","Taluk":"Kalchini","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736201","Taluk":"Alipurduar - I","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736182","Taluk":"Kalchini","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735227","Taluk":"Kumargram","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736204","Taluk":"Alipurduar","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736207","Taluk":"Kumargram","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736201","Taluk":"Kumargram","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736204","Taluk":"Alipurduar - I","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736176","Taluk":"Dinhata","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736171","Taluk":"Cooch Behar - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736134","Taluk":"Dinhata - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736179","Taluk":"Cooch Behar - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736146","Taluk":"Mathabhanga - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736171","Taluk":"Mathabhanga","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736202","Taluk":"Alipurduar - II","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736203","Taluk":"Kumargram","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736156","Taluk":"Tufanganj - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736135","Taluk":"Dinhata - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736133","Taluk":"Cooch Behar - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736179","Taluk":"Cooch Behar - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"736205","Taluk":"Kumargram","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736169","Taluk":"Dinhata - I","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735226","Taluk":"Kalchini","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735227","Taluk":"Alipurduar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"736131","Taluk":"Tufanganj - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735214","Taluk":"Kalchini","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734301","Taluk":"Kalimpong -I","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734427","Taluk":"Kharibari","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734314","Taluk":"Kalimpong - II","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734203","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734105","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734429","Taluk":"Rangli Rangliot","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734421","Taluk":"Phansidewa","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734014","Taluk":"Phansidewa","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734224","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734008","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734219","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734104","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734201","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734312","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734221","Taluk":"Jorebunglow Sukiapokhri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734423","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734424","Taluk":"Phansidewa","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734427","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734007","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734101","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734201","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734102","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734426","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734003","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734436","Taluk":"Phansidewa","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734102","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734426","Taluk":"Phansidewa","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734014","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734223","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734004","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734201","Taluk":"Kalimpong - II","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734220","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734004","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734215","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734121","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734204","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734429","Taluk":"Kharibari","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734214","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734317","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734015","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734226","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734006","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734314","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734201","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734014","Taluk":"Matigara","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734009","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734434","Taluk":"Phansidewa","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734429","Taluk":"Naxalbari","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734103","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734217","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734005","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734011","Taluk":"Naxalbari","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734311","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734426","Taluk":"Naxalbari","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734320","Taluk":"Kalimpong -I","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734301","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734434","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734007","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734213","Taluk":"Rangli Rangliot","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734008","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734319","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734105","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734008","Taluk":"Kalimpong -I","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734213","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734316","Taluk":"Kalimpong - II","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734001","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734215","Taluk":"Jorebunglow Sukiapokhri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734010","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734221","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734313","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734315","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734215","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734429","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734219","Taluk":"Jorebunglow Sukiapokhri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734013","Taluk":"Matigara","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734104","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734218","Taluk":"Mirik","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734216","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734122","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734123","Taluk":"Darjeeling","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734012","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734313","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734321","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734316","Taluk":"Kalimpong -I","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734431","Taluk":"Naxalbari","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734001","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734002","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734313","Taluk":"Rangli Rangliot","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734216","Taluk":"Jorebunglow Sukiapokhri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734102","Taluk":"Jorebunglow Sukiapokhri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734009","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734316","Taluk":"Kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734218","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734301","Taluk":"kalimpong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734227","Taluk":"Mirik","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734222","Taluk":"Kurseong","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734014","Taluk":"Siliguri","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734427","Taluk":"Naxalbari","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734222","Taluk":"Rangli Rangliot","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734316","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"734124","Taluk":"Darjeeling Pulbazar","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"735101","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735231","Taluk":"Gorubathan","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"735216","Taluk":"Falakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735224","Taluk":"Mekhliganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735210","Taluk":"Dhupguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735207","Taluk":"Madarihat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735231","Taluk":"Garubathan I","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"735218","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735202","Taluk":"Nagrakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735303","Taluk":"Mekliganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735211","Taluk":"Mathabhanga - II","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735220","Taluk":"Falakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735211","Taluk":"Falakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735304","Taluk":"Mekhliganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735302","Taluk":"Maynaguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734501","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735122","Taluk":"Haldibari","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735121","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735305","Taluk":"Maynaguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735135","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735211","Taluk":"Mathabhanga","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735233","Taluk":"Dhupguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735202","Taluk":"Dhupguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735224","Taluk":"Maynaguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735230","Taluk":"Matteli","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735206","Taluk":"Matteli","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735221","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735133","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735132","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735303","Taluk":"Mathabhanga","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735301","Taluk":"Mekhliganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735232","Taluk":"Dhupguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735203","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735204","Taluk":"Falakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735122","Taluk":"Jalpaiguri","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735304","Taluk":"Mekliganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735204","Taluk":"Madarihat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735205","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735219","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735225","Taluk":"Nagrakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735301","Taluk":"Mekliganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735102","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735218","Taluk":"Nagrakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735220","Taluk":"Madarihat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735210","Taluk":"Madarihat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735209","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735213","Taluk":"Falakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735303","Taluk":"Mekhliganj","Districtname":"Cooch Behar","statename":"WEST BENGAL"},
    {"pincode":"735210","Taluk":"Dhupguri Sadar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735228","Taluk":"Madarihat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735231","Taluk":"Mal","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"735134","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"734503","Taluk":"Gorubathan","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"735212","Taluk":"Dhupguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735234","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735213","Taluk":"Madarihat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735230","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735122","Taluk":"Haldibari","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735223","Taluk":"Matteli","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735224","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735101","Taluk":"Jalpaiguri Sadar","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735303","Taluk":"Mekhliganjs.o.","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735122","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735135","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735133","Taluk":"Rajganj","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735206","Taluk":"Mal","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"735220","Taluk":"Madari Hat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735222","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735223","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735206","Taluk":"Matelli","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735222","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735219","Taluk":"Maynaguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735216","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735228","Taluk":"Madari Hat","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735206","Taluk":"Gorubathan","Districtname":"Darjiling","statename":"WEST BENGAL"},
    {"pincode":"735229","Taluk":"Mal","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735205","Taluk":"Nagrakata","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735212","Taluk":"Jalpaiguri","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"735223","Taluk":"Matelli","Districtname":"Jalpaiguri","statename":"WEST BENGAL"},
    {"pincode":"732128","Taluk":"Old Malda","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732122","Taluk":"Habibpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Habibpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732124","Taluk":"Habibpur  (arajimistrabati)","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732121","Taluk":"Habibpir","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732124","Taluk":"Gajol","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732216","Taluk":"Kaliachak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732128","Taluk":"Gajol","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Kaliachak-1","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Kaliachak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Amarsinghi","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732208","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732214","Taluk":"Ratua-ii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732143","Taluk":"Old Malda","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732123","Taluk":"Chanchal","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732142","Taluk":"Old Malda","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Harishchandrapur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732205","Taluk":"Ratua","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732210","Taluk":"Kaliachak-ii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732203","Taluk":"Manikchjak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732206","Taluk":"Kaliachak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Chanchal","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732206","Taluk":"Kaliachak-i","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732101","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732147","Taluk":"Bamangola","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Kaliachak-1","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732140","Taluk":"Harishchandrapur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732128","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Ratua","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732210","Taluk":"Kaliachak-iii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732209","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732126","Taluk":"Chanchal","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Kaliachak-i","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732203","Taluk":"Manikchak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Manikchak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732140","Taluk":"Chanchal","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732204","Taluk":"Chanchal","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732122","Taluk":"Bulbulchandi","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732127","Taluk":"Lakshipur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Goripur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732210","Taluk":"Kaliachak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732127","Taluk":"Kaliachak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Chariantatapur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Chorolmoni","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732202","Taluk":"Manikchak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Ratua","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Dalla","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Silampur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Damaipur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Kaliachak-ii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732123","Taluk":"Hatinda","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Mothabari","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732124","Taluk":"Deotala","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Dighalbar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Gajol","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732121","Taluk":"Habibpur (srirampur Anchal)","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Gangadevi","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Kaliachak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732103","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732121","Taluk":"Gouramari","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732127","Taluk":"Baishnabnagar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Uttar Lakshmipur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Hardamnagar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Old Malda","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Mozampur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732212","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Jagannathpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Bamangola","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732202","Taluk":"Pashchim Narayanpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732141","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Kaliacahak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Panchanandapur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732209","Taluk":"English Bazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732216","Taluk":"Kadamtala","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Ratua Ii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732205","Taluk":"Ratua -i","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732122","Taluk":"Kanturka","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Kashimpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732150","Taluk":"Kharba","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732122","Taluk":"Aktail","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Bamongola","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Koilabad","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732122","Taluk":"Kotalpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732144","Taluk":"Sahajalalpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732210","Taluk":"Krishnapur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Kumarganj","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732140","Taluk":"Kushida","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732128","Taluk":"Ratua","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732121","Taluk":"Mabarakpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732216","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Gajol","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Malior","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732124","Taluk":"Alal","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732207","Taluk":"Kaliachak Ii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Miahat","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Mariary","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732211","Taluk":"Englishbazar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732210","Taluk":"Baishnabnagar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732140","Taluk":"Paro","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Patul","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Pipla Kashimpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732102","Taluk":"Pukuria","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732215","Taluk":"Baishnabnagar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732213","Taluk":"Chanchal","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Silampur-ii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732202","Taluk":"Chowki Mirdadpur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732205","Taluk":"Ratua-i","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732209","Taluk":"Manikchak","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732124","Taluk":"Gajol.","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Andhirampara","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732126","Taluk":"Khanpur Hubaspur 165","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732138","Taluk":"Habibpuir","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732121","Taluk":"Srirampur (habibpur)","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732139","Taluk":"Sripur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732206","Taluk":"Baro Sujapur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"Baishnabnagar","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Harishchandrapur -ii","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Talbangrua","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Talgachhi","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Talgram","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732125","Taluk":"Talsur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732140","Taluk":"Tulsihatta","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732216","Taluk":"Akandaberia","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"732201","Taluk":"North Jadupur","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"737135","Taluk":"Ranipool","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737133","Taluk":"Rhenock","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737111","Taluk":"Gayzing","Districtname":"West Sikkim","statename":"SIKKIM"},
    {"pincode":"737121","Taluk":"Jorethang","Districtname":"West Sikkim","statename":"SIKKIM"},
    {"pincode":"737126","Taluk":"Namchi","Districtname":"South Sikkim","statename":"SIKKIM"},
    {"pincode":"737134","Taluk":"Singtam","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737139","Taluk":"Ravangla","Districtname":"South Sikkim","statename":"SIKKIM"},
    {"pincode":"737106","Taluk":"Pakyong","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737113","Taluk":"Pelling","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737103","Taluk":"Gangtok","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737131","Taluk":"Rangli","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737120","Taluk":"Chungthang","Districtname":"North Sikkim","statename":"SIKKIM"},
    {"pincode":"737113","Taluk":"Pelling","Districtname":"West Sikkim","statename":"SIKKIM"},
    {"pincode":"737102","Taluk":"Gangtok","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737107","Taluk":"Dikchu","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737132","Taluk":"Rangpo","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737101","Taluk":"Gangtok","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737117","Taluk":"Mangan","Districtname":"North Sikkim","statename":"SIKKIM"},
    {"pincode":"737128","Taluk":"Melli","Districtname":"South Sikkim","statename":"SIKKIM"},
    {"pincode":"737102","Taluk":"Namchi","Districtname":"South Sikkim","statename":"SIKKIM"},
    {"pincode":"737116","Taluk":"Mangan","Districtname":"North Sikkim","statename":"SIKKIM"},
    {"pincode":"737136","Taluk":"Rangpo","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737138","Taluk":"Singtam","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737135","Taluk":"Ranipool / Gangtok","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737121","Taluk":"Jorethang","Districtname":"South Sikkim","statename":"SIKKIM"},
    {"pincode":"737118","Taluk":"Mangan","Districtname":"North Sikkim","statename":"SIKKIM"},
    {"pincode":"737119","Taluk":"Mangan","Districtname":"North Sikkim","statename":"SIKKIM"},
    {"pincode":"737135","Taluk":"Gangtok","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"737102","Taluk":"East District","Districtname":"East Sikkim","statename":"SIKKIM"},
    {"pincode":"733127","Taluk":"Tapan","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733129","Taluk":"Kaliganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733215","Taluk":"Karandighi","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733208","Taluk":"Islampur","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733202","Taluk":"Islampur","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733132","Taluk":"Kushmandi","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733103","Taluk":"Balurghat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733201","Taluk":"Dalkhola","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733141","Taluk":"Kumarganj","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733207","Taluk":"Chopra","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733141","Taluk":"Gangarampur","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733209","Taluk":"Karandighi","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733158","Taluk":"Balurghat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733121","Taluk":"Banshihari","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733129","Taluk":"Kaliyaganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733125","Taluk":"Harirampur","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733210","Taluk":"Goalpukher","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733134","Taluk":"Raiganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733128","Taluk":"Itahar","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733125","Taluk":"Itahar","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733101","Taluk":"Balurghat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733140","Taluk":"Gangarampur","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733128","Taluk":"Harirampur","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733102","Taluk":"Balurghat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733210","Taluk":"Goalpokhar - I","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733156","Taluk":"Raiganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733142","Taluk":"Tapan","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733133","Taluk":"Kumarganj","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733143","Taluk":"Itahar","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733124","Taluk":"Gangarampur","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733123","Taluk":"Raiganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733210","Taluk":"Karandighi","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733143","Taluk":"Raiganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733209","Taluk":"Goalpokhar - II","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733208","Taluk":"Goalpokhar - II","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733142","Taluk":"Gangarampur","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733142","Taluk":"Bamangola","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"733121","Taluk":"Bansihari","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733145","Taluk":"Balurghat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733130","Taluk":"Raiganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733201","Taluk":"Karandighi","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733125","Taluk":"Banshihari","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733140","Taluk":"Kumargunj","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733129","Taluk":"Kaliaganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733202","Taluk":"Ilsmapur","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733124","Taluk":"Gazole","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"733121","Taluk":"Itahar","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733126","Taluk":"Hili","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733132","Taluk":"Kaliaganj","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733202","Taluk":"Chopra","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733130","Taluk":"Hemtabad","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733126","Taluk":"Hilli","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733145","Taluk":"Hilli","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733207","Taluk":"Islampur","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733208","Taluk":"Goalpokhar - I","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733133","Taluk":"Balurghat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733207","Taluk":"Goalpokhar - I","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733201","Taluk":"Goalpokhar - II","Districtname":"North Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733124","Taluk":"Bamangola","Districtname":"Malda","statename":"WEST BENGAL"},
    {"pincode":"733121","Taluk":"Kushmundi","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733133","Taluk":"Balurgthat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733132","Taluk":"Kushmundi","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733140","Taluk":"Kumarganj","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733125","Taluk":"Bansihari","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"733127","Taluk":"Balurghat","Districtname":"South Dinajpur","statename":"WEST BENGAL"},
    {"pincode":"713335","Taluk":"Salanpur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713359","Taluk":"Kulti Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713381","Taluk":"Laudoha","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713331","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713212","Taluk":"Kanksa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713214","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713203","Taluk":"Faridpur Durgapur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713358","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713358","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713321","Taluk":"Andal","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713321","Taluk":"Raniganj","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713347","Taluk":"Andal","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713215","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713360","Taluk":"Kulti Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713301","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713304","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713301","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713303","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713362","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713322","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713363","Taluk":"Laudoha","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713323","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713324","Taluk":"Kulti Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713334","Taluk":"Baraboni","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713359","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713324","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713204","Taluk":"Faridpur Durgapur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713213","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713375","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713378","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713337","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713212","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713371","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713378","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713332","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713325","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713325","Taluk":"Asansol Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713330","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713339","Taluk":"Raniganj Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713331","Taluk":"Salanpur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713365","Taluk":"Salanpur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713326","Taluk":"Asansol Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713368","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713216","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713302","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713339","Taluk":"Asansol Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713205","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713376","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713333","Taluk":"Kulti Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713206","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713208","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713208","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713202","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713203","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713201","Taluk":"Durgapur Municipal Corporation","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713210","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713209","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713209","Taluk":"Duragpur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713201","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713211","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713204","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713207","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713323","Taluk":"Raniganj Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713333","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713219","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713377","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713378","Taluk":"Pandaveshwar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713335","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713362","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713315","Taluk":"Baraboni","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713336","Taluk":"Jamuriahat","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713206","Taluk":"Kanksa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713338","Taluk":"Andal","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713340","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713369","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713373","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713341","Taluk":"Baraboni","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713342","Taluk":"Pandabeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713380","Taluk":"Pandaveshwar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713343","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713363","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713347","Taluk":"Raniganj Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713359","Taluk":"Baraboni","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713385","Taluk":"Laudoha","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713330","Taluk":"Baraboni","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713336","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713344","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713218","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713358","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713370","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713373","Taluk":"Baraboni","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713217","Taluk":"Durgapur Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713346","Taluk":"Pandaveshwr","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713384","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713372","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713305","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713347","Taluk":"Raniganj Municipality","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713346","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713386","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713357","Taluk":"Salanpur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713359","Taluk":"Salanpur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713383","Taluk":"Jamuria","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713358","Taluk":"Raniganj Mc","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713346","Taluk":"Pandaveshwar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713373","Taluk":"Pandaveshwar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713373","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713360","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713361","Taluk":"Barabani","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713363","Taluk":"Faridpur Durgapur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"722144","Taluk":"Onda","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722173","Taluk":"Indpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722122","Taluk":"Vishnupur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722206","Taluk":"Patrasayer","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722134","Taluk":"Raipur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722148","Taluk":"Ranibundh","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722201","Taluk":"Indus","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722137","Taluk":"Chhatna","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722133","Taluk":"Gangajalghati","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722135","Taluk":"Ranibandh","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722141","Taluk":"Kotalpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722122","Taluk":"Bishnupur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722207","Taluk":"Sonamukhi","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722206","Taluk":"Indas","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722102","Taluk":"Bankura - I","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722146","Taluk":"Bankura","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722161","Taluk":"Kotulpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722121","Taluk":"Indpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722143","Taluk":"Mejhia","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722154","Taluk":"Khatra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722154","Taluk":"Joypur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722138","Taluk":"Jaypur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722136","Taluk":"Indpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722139","Taluk":"Chhatna","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722140","Taluk":"Khatra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722143","Taluk":"Saltora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722153","Taluk":"Saltora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722133","Taluk":"G.ghati","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722151","Taluk":"Raipur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722142","Taluk":"Mejia","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722164","Taluk":"Vishnupur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722102","Taluk":"Bankura","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722101","Taluk":"Bankura - I","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722160","Taluk":"Simlapal","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722150","Taluk":"Sarenga","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722203","Taluk":"Barjora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722151","Taluk":"Simlapal","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722162","Taluk":"Raipur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722202","Taluk":"Barjora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722134","Taluk":"Sarenga","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722155","Taluk":"Bankura - II","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722122","Taluk":"Joypur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722138","Taluk":"Joypur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722155","Taluk":"Bankura","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722154","Taluk":"Vishnupur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722121","Taluk":"Taldangra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722136","Taluk":"Bankura","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722157","Taluk":"Bishnupur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722121","Taluk":"Hirbandh","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722140","Taluk":"Hirbandh","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722156","Taluk":"Taldangra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722152","Taluk":"Taldangra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722148","Taluk":"Ranibandh","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722158","Taluk":"Saltora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722142","Taluk":"Gangajalghati","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722132","Taluk":"Saltora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722152","Taluk":"Onda","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722132","Taluk":"Chhatna","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722150","Taluk":"Raipur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722142","Taluk":"Barjora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722135","Taluk":"Khatra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722149","Taluk":"Taldangra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722141","Taluk":"Kotulpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722140","Taluk":"Ranibandh","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722201","Taluk":"Indas","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722140","Taluk":"Raipur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722202","Taluk":"Borjora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722137","Taluk":"Saltora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722136","Taluk":"Hirbandh","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722161","Taluk":"Kotalpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722204","Taluk":"Barjora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722121","Taluk":"Khatra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722146","Taluk":"Onda","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722152","Taluk":"Indpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722157","Taluk":"Sonamukhi","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722205","Taluk":"Indas","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722132","Taluk":"Bankura","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722139","Taluk":"Bankura","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722164","Taluk":"Bishnpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722151","Taluk":"Taldangra","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722133","Taluk":"Saltora","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722146","Taluk":"Indpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722136","Taluk":"Onda","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722156","Taluk":"Simlapal","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722183","Taluk":"Gangajalghati","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722136","Taluk":"Chhatna","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722208","Taluk":"Sonamukhi","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722140","Taluk":"Indpur","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722147","Taluk":"Onda","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722146","Taluk":"Bankura - I","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"722152","Taluk":"Bankura","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713144","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713166","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713428","Taluk":"Galsi - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713148","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713123","Taluk":"Ketugram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713502","Taluk":"Katwa - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713512","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713101","Taluk":"Burdwan - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713401","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713157","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713150","Taluk":"Katwa","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713154","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713141","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713155","Taluk":"Ausgram - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713125","Taluk":"Bhatar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713129","Taluk":"Ketugram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713423","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713514","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713408","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713129","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713502","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713122","Taluk":"Kalna","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713121","Taluk":"Bhatar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713402","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713121","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713145","Taluk":"Bhatar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713405","Taluk":"Kalna","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713147","Taluk":"Mangolkote","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713130","Taluk":"Katwa - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"Bhatar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713156","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713146","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713501","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713123","Taluk":"Ketugram - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713406","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713122","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713104","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713427","Taluk":"Raina - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713407","Taluk":"Bhatar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713152","Taluk":"Ausgram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713131","Taluk":"Mangolkote","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713146","Taluk":"Memari - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713150","Taluk":"Katwa - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713123","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713141","Taluk":"Burdwan - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713421","Taluk":"Raina - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713158","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713141","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713409","Taluk":"Kalna - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713429","Taluk":"Memari - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713140","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"Mangolkote","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713124","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713422","Taluk":"Kalna","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713407","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713428","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713512","Taluk":"Kalna","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713102","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713124","Taluk":"Burdwan - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713502","Taluk":"Katwa - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713126","Taluk":"Ausgram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713408","Taluk":"Jamalpur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713409","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713141","Taluk":"Burdwan - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713149","Taluk":"Burdwan - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713426","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713125","Taluk":"Bhater","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713125","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713153","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713126","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713145","Taluk":"Bhater","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713141","Taluk":"Guskara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713152","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713432","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713142","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713148","Taluk":"Kanksa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713404","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713513","Taluk":"Kalna","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713430","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713103","Taluk":"Galsi - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713407","Taluk":"Memari - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713127","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713103","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713424","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713124","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713403","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713104","Taluk":"Burdwan - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713513","Taluk":"NA","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713404","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713519","Taluk":"Memari","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713131","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713151","Taluk":"Memari - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713142","Taluk":"Khandaghosh","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713519","Taluk":"NA","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713512","Taluk":"Kalna","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713423","Taluk":"Raina - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713103","Taluk":"Burdwan - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713502","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713123","Taluk":"Katwa","Districtname":"Murshidabad","statename":"WEST BENGAL"},
    {"pincode":"713128","Taluk":"Ausgram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713128","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713124","Taluk":"Raina - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713422","Taluk":"Manteswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713405","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713166","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713515","Taluk":"Purbasthali - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713519","Taluk":"Kalna","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713403","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713427","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713406","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713152","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713424","Taluk":"Raina - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713406","Taluk":"Galsi - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713128","Taluk":"Burdwan Sadar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713160","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713426","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713514","Taluk":"Katwa","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713127","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713128","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713401","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713409","Taluk":"Kalna","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713407","Taluk":"Burdwan - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713129","Taluk":"Kalna - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713162","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713152","Taluk":"Ausgram - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713408","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713512","Taluk":"Purbasthali - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713406","Taluk":"Ausgram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713424","Taluk":"Burddwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713131","Taluk":"Ausgram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713409","Taluk":"Kalna - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713102","Taluk":"Burdwan - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713421","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713129","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713102","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713131","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713130","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713140","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"Bhater","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713150","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713142","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713144","Taluk":"Ausgram - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713422","Taluk":"Purbasthali - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713519","Taluk":"Ketugram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713127","Taluk":"Guskara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713144","Taluk":"Guskara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713148","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713519","Taluk":"Bhatar","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713427","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713147","Taluk":"Bhatar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713126","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713512","Taluk":"Purbasthali - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713151","Taluk":"Memari","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713145","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713426","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713144","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713146","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713123","Taluk":"Katwa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713519","Taluk":"Katwa","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713419","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713166","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713515","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713512","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713165","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713434","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713424","Taluk":"Raina - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713515","Taluk":"Kalna","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713520","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713167","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713168","Taluk":"Memari - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713128","Taluk":"Mangolkote","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"Ketugram - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713420","Taluk":"Kanksa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713516","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713153","Taluk":"Gushkara","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713428","Taluk":"Galsi - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"Manteswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713421","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713151","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713420","Taluk":"Burdwan","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713405","Taluk":"Purbasthali - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713149","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713143","Taluk":"Bhatart","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713422","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713423","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713169","Taluk":"Kanksa","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713123","Taluk":"Mangolkote","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713424","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713425","Taluk":"NA","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713170","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713514","Taluk":"NA","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713148","Taluk":"Ausgram - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713404","Taluk":"Jamalpur","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713150","Taluk":"NA","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713171","Taluk":"Ausgram - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713517","Taluk":"NA","Districtname":"Bankura","statename":"WEST BENGAL"},
    {"pincode":"713146","Taluk":"Kalna - I","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713172","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713173","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713426","Taluk":"Memari - II","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"713433","Taluk":"NA","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Adasimla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Ajodhyapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Ajoya","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Akanda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Akpura","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Alamchak Belda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721420","Taluk":"Alangiri","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721441","Taluk":"Alankarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Alukaranbarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721436","Taluk":"Amarda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Amarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Amarshi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Amda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Amtalia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Analberia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721143","Taluk":"Andhari","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721143","Taluk":"Andhari Maubhandar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Angua","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Anikola","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Argoal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Argora","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Arjunnagar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Asda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Astichak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Aswathapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Atbati","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Contai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Aurai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Ayma Barbaria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Babla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721143","Taluk":"Bachhurkhoyar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Baghasti","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721143","Taluk":"Baharadari","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Baharganj","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Bahitrakunda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Bahurupa","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Baikunthapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721420","Taluk":"Baitabazar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Bakhrabad","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Bakrachak","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Baksispur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Bakurpada","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Balageria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Baligeria","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Balighai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Balisai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721440","Taluk":"Ballyagobindapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Bamanbarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Belmula","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721449","Taluk":"Banamalichatta","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Bandhuchak","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Banisole","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Banjhatia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Bankibheri","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Bankiput","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Bansgora Bazar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Banskuthi","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721450","Taluk":"Barabantalia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Barabari South","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Barabatia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Barachara","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Barada Bazar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Barakalankai","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Baranegui","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Barangi","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Baranihary","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Baratala","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Baraudaypur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Barhat","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Barida","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Barida Bazar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721434","Taluk":"Barmakhal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Bartana","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Basantia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Basudebberia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Basudebpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Basuli Bazar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Bathuary","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Battala","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Bayenda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721436","Taluk":"Begunia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Belda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Benachakri","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Benadiha","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Benichak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Bhadrakali","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Bhaghadari","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Bhajachauli","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Bhasra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Bhatda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Bhawanichak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Bhimeswaribazar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Bhuniajibarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Bhupatinagar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Bibhisanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Bidurpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Binandapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Birbira","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Bishnupur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Biswanathpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Bodhra","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Boga","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Bolkhusda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Bonai","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721143","Taluk":"Bonpura","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Borah","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Borai","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Brahamankhalisa","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Brajaballavepur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Bulakipur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Chak Islampur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721440","Taluk":"Chakbhabani","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Chakla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Chakmahima","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Chandabila","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Chandanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721438","Taluk":"Chandanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Chandanpurhat","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Chandkuri-batikati","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Chapatala","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Chatla","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Chaudachulli","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Chaulia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Chaulkhola","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Chhatorekole","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Chhatri","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Chhorda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721435","Taluk":"Chhoto Srikrishnapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Chotta-chatla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Chilkipada","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Chingurdania","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Chirulia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Chorpalia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Chunpara","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Dakshin Kalyanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721449","Taluk":"Daisai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Dakhinbadalpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Dakhin Sandalpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721450","Taluk":"Contai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"D.kalamdan","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Dakshin Kalynpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Damodarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Damodarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Dantan","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Dariapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Darua","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Dasagram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Daudpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Dekhali","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721453","Taluk":"Depal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721450","Taluk":"Deshdattabarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Deulbarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Deuli","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Deulinekurseni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Deulpota Keshabchak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Dhalbelun","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Dhandalibarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Dhangaon","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Dhangarpara","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Dhanghara","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721434","Taluk":"Dhankrabanka","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Dholmari","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Dhuipada","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Dhumsai","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721463","Taluk":"Digha","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721428","Taluk":"Digha","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Dighadari","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Dobaria","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Dokra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Dolgram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Dubda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Dumandari","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"D.s.boni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Duria","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Durmuth","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Egra","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Ekarukhi","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Erenda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Fuleswar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Fulgeria","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Gaganeswar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Gaita","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Ganasarisa","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Gangadharchak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Garh Haripur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Garh Radhanagar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Garh-monoharpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Garia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Gazipur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721438","Taluk":"Ghatua Purusottampur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Ghoraghata","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721441","Taluk":"Gobra","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Gokulpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Gomunda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Gopalchak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Gopichak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Haipur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721441","Taluk":"Haldia Patna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Haludbari","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Handlarajgarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Haria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Hatbaincha","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Hatgopalpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721441","Taluk":"Hirapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Dibarpanda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Ichabari","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Islampur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Itaberia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Jabda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Jahalda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Jahanabad","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Jamirapalgarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Jamsole","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Jamua Sankarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Jamualachimpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Janka","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Jasteghori","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721435","Taluk":"Jenkapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721420","Taluk":"Jerthan","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Jhanjianankar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721159","Taluk":"Jharia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721450","Taluk":"Jhawa","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721441","Taluk":"Jhawgeria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Jukhia Bazar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Juki","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Jumki","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Junboni","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"June Belda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721450","Taluk":"Junput","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Kaithore","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Kajli","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Kakrajit","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Kalagachia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Kalasbarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Kalindi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Kalindi Teghari","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721159","Taluk":"Kalmapukhuria","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Kalsi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721447","Taluk":"Kalyanpur Tentulmuri","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Kamarda Bazar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Kanaidighi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Kanakpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Kandagram","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Kanpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Kantai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Kanthalia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721449","Taluk":"Karaldanimakbarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Karanji","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Kartikkhali","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Kasaria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721447","Taluk":"Kasbagola","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Kashimpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Kashipur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Katka Debichak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Katranka","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Kayemgeria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Kekai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Keshiapada","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Keshiary","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Keshrambha","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Keunchia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Keut Khalisa","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Keutgeria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Khagda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Khakurda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Khalina","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Khalisabhanga","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Khar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721159","Taluk":"Kharikamathani","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Kharuigarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Khatnagar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Khejurda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Khejuri","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Khurshi","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Khurutia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Kolanda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Konarpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721435","Taluk":"Koria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Kotaigarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Kotepada","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Krishnanagar Contai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Kukai","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Kulanjara","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Kuldiha","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Kultikri Aranga","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Kultikri","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Kumarda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Kumirda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Kunjapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Kushbasan","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Kuishgeria","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Kutki","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Lakhi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Lakshmanchak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Lalat","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Lalitapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Lalnagar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Lalua","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Laudaha","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Laudangri","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Lengamara","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Lohabaranchak","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Madanmohanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Mahespur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Mahisagote","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721434","Taluk":"Maisali","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Maitana","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Majna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Makrampur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Makrampurbazar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Maljamuna","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721441","Taluk":"Mandar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Mandarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721434","Taluk":"Manglamaro","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721453","Taluk":"Manikabasan","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Manikjore","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721449","Taluk":"Marisda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Markanda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Mathura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Matibaruan","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Medakhali","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721435","Taluk":"Menkapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Mirgoda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Mirzapur Belda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721436","Taluk":"Mohanpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Mohati","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Morchi","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Mugberia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Murabani","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Murisai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Mustafapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Nachhipur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Nachinda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Nagripada","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Naipur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Namal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Namaldiha","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Nankarmaguria","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Narandia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Narayangarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721143","Taluk":"Narda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Narkuli","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Naskarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721159","Taluk":"Nayagram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Nayaput","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Negua","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721159","Taluk":"Nekrasole","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Nekurseni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Nijkasba","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Nilda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Noonhanda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"North Biswanathpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Paika","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Pailachhanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721453","Taluk":"Paldhui","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Palpara","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Palsandapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Panchahari","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721438","Taluk":"Panchetgarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721447","Taluk":"Panchrol","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721434","Taluk":"Panchuria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721440","Taluk":"Pania","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Panichiary","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Paniparul","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Pankhai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Paragana","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721436","Taluk":"Paroi","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721450","Taluk":"Paschim Bamunia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Paschim Manikpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Patashpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Pathra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Paushi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721428","Taluk":"Paya-medinipur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Phandar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721455","Taluk":"Pichabani","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Pirijkhanbarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Porolda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721440","Taluk":"Pratapdighi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721453","Taluk":"Purba Madhabpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Purbaradhapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Purunda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"R.p.barh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721436","Taluk":"Rajnagar","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Ramchak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721441","Taluk":"Ramnagar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Rampura","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"Ranbonia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Ranisai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Rasan","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Rautara","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Rautarapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Remu","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Renjura","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Reripur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721143","Taluk":"Rohini","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Sabajput","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Sabra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Sadihat","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Sadikpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Safiabad","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Sagareswar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721446","Taluk":"Sahara","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721435","Taluk":"Salikotha","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Saljora","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Saluka","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Sansadgopinathpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Santrapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Saorapin","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Sarada","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721434","Taluk":"Saridaspur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721428","Taluk":"Saripur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721444","Taluk":"Sarpai","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Sarrang","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721467","Taluk":"Sarta","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721439","Taluk":"Satbahini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Satilapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Satkhanda Sahebnagar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Satmile","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721125","Taluk":"Satpautia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Saulabheri","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Sauri","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721457","Taluk":"Sautia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721454","Taluk":"Selmabad","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Serkhanchak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721448","Taluk":"Shipur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721423","Taluk":"Shyampur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721431","Taluk":"Sillyaberia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Singaijamuna","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Sisusadan","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721436","Taluk":"Siyalsai","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Sonakonia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721440","Taluk":"Srirampur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Srirampur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Subdi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721456","Taluk":"Sukhakhola","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Sukharole","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Susunia","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721429","Taluk":"Susunia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721433","Taluk":"Tagaria","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721434","Taluk":"Tagharia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721432","Taluk":"Takapura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721435","Taluk":"Talda Ratanchak","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721436","Taluk":"Tanua","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Tararui","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Tarua","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721447","Taluk":"Telami","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721401","Taluk":"Tengunia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"Tentuliabhumjan","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721437","Taluk":"NA","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Thakurchak","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Thakurnagar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Tikashi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721135","Taluk":"T.mahisamunda","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Totanala","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721159","Taluk":"Tufuria Kalmapur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Turka Kashba","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721445","Taluk":"Turkagarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721451","Taluk":"Uchudiha","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721425","Taluk":"Udbadal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721424","Taluk":"Uparjagatpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Urdhabpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721458","Taluk":"Ururi","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721427","Taluk":"Uttar Amtalia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721443","Taluk":"Uttar Asda","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721452","Taluk":"Uttar Badalpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721133","Taluk":"Uttar Dumurkhola","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721453","Taluk":"Uttar Gobindapur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721430","Taluk":"Uttar Kalamdan","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721453","Taluk":"Uttar Kanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721426","Taluk":"Uttar Ranibarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721442","Taluk":"Uttarharaschak","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721422","Taluk":"Uttar-tajpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"712121","Taluk":"Addconagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712512","Taluk":"Guptipara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712148","Taluk":"Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712414","Taluk":"Pursurah","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712305","Taluk":"Puinan","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712301","Taluk":"Dhaniakhali","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712149","Taluk":"Pandua","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712601","Taluk":"Arambag","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712601","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712413","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712146","Taluk":"Pandua","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712616","Taluk":"Goghat-i","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712611","Taluk":"Goghat - II","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712305","Taluk":"Polba - Dadpur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712122","Taluk":"Goghat-ii","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712301","Taluk":"Bhanderhati","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712146","Taluk":"Ilsomandalai","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712413","Taluk":"Arambag","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712602","Taluk":"Goghat-i","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712512","Taluk":"Balagarh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712501","Taluk":"Balagarh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712416","Taluk":"Khanakul 2b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712303","Taluk":"Gurap","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712134","Taluk":"Boinchi","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712135","Taluk":"Pandua","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712123","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712515","Taluk":"Khamargachi","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712502","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712502","Taluk":"Bansberia","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712617","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712412","Taluk":"Pursurah","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712134","Taluk":"Pandua","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712615","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712302","Taluk":"Dhaniakhali","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712611","Taluk":"Goghat-ii","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712613","Taluk":"Arambag","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712301","Taluk":"Bhandarhati","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712415","Taluk":"Pursurah","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712103","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712417","Taluk":"Khanakul 2b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712503","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712105","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712412","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712504","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712602","Taluk":"Arambag","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712147","Taluk":"Itachuna","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712402","Taluk":"NA","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712101","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712101","Taluk":"Chinsurah","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712102","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712308","Taluk":"Khanpur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712413","Taluk":"Khanakul-i","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712413","Taluk":"Raina","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712402","Taluk":"Dasghara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712123","Taluk":"Bandel","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712614","Taluk":"Goghat-ii","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712414","Taluk":"Pursurah.","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712613","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712613","Taluk":"Khanakul-i","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712146","Taluk":"Ilsobamandali","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712513","Taluk":"Nayasarai","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712402","Taluk":"Dhaniakhali","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712415","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712412","Taluk":"Khanakul I.b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712406","Taluk":"Khanakul-2b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712147","Taluk":"Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712146","Taluk":"Ilsobamandal","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712303","Taluk":"NA","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712122","Taluk":"Goghat - II","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712412","Taluk":"Khanakul-1b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712612","Taluk":"Goghat-ii","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712102","Taluk":"NA","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712406","Taluk":"Khanakul-1b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712104","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712515","Taluk":"Balagarh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712406","Taluk":"Khanakul I.b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712308","Taluk":"Gurap","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712147","Taluk":"Pandua","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712614","Taluk":"Goghat-i","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712148","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712602","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712612","Taluk":"Goghat-1","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712414","Taluk":"Pursura","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712513","Taluk":"Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712416","Taluk":"Khanakul2b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712148","Taluk":"Polba - Dadpur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712406","Taluk":"Khanakul 2b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712152","Taluk":"Pandua","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712417","Taluk":"Khanakul2b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712614","Taluk":"Goghat - I","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712406","Taluk":"Khanakul 1b","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712514","Taluk":"Balagarh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712401","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712701","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712410","Taluk":"Chandannagar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"712409","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712221","Taluk":"Singur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712706","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712223","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712424","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712404","Taluk":"Jangipara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712405","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712203","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712222","Taluk":"Singur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712410","Taluk":"Burdwan","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712407","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712304","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712410","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712401","Taluk":"Chanditala - II","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712250","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712248","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712246","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712306","Taluk":"Chanditala - II","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712306","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712403","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712223","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712232","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712124","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712410","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712124","Taluk":"Chandanagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712410","Taluk":"Tarakeswar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712306","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712136","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712310","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712702","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712204","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712304","Taluk":"Chanditala - II","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712401","Taluk":"Arambagh","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712235","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712311","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712408","Taluk":"Jangipara","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"712409","Taluk":"Singur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712404","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712708","Taluk":"Chanditala - II","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712401","Taluk":"Tarakeswar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712137","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712410","Taluk":"Tarakeswar","Districtname":"Bardhaman","statename":"WEST BENGAL"},
    {"pincode":"712408","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712407","Taluk":"Haripal","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712403","Taluk":"Haripal","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712233","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712707","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712419","Taluk":"NA","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712701","Taluk":"Chanditala - I","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712138","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712124","Taluk":"Singur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712201","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712234","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712705","Taluk":"Chandangar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712222","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712704","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712202","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712245","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712139","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712250","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712311","Taluk":"Chanditala - II","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712138","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712418","Taluk":"Singur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712249","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712426","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712247","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712125","Taluk":"Chinsurah - Magra","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712408","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712258","Taluk":"Serampur Uttarpara","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712411","Taluk":"Chandannagar","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712223","Taluk":"Singur","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712203","Taluk":"Serampore","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"712409","Taluk":"NA","Districtname":"Hooghly","statename":"WEST BENGAL"},
    {"pincode":"711204","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711106","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711205","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711303","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711312","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711315","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711401","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711227","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711301","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711302","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711103","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711316","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711226","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711102","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711110","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711109","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711113","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711201","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711405","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711106","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711322","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711304","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711316","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711403","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711303","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711401","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711404","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711103","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711305","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711411","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711101","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711202","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711202","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711106","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711411","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711313","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711203","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711322","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711310","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711307","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711204","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711104","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711114","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711313","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711308","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711303","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711412","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711102","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711315","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711405","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711312","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711314","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711109","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711307","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711105","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711205","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711410","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711309","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711104","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711102","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711302","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711205","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711310","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711322","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711410","Taluk":"Domjur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711413","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711227","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711107","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711112","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711109","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711314","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711414","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711314","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711404","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711226","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711410","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711303","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711310","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711101","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711106","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711101","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711104","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711316","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711111","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711315","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711114","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711408","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711411","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711314","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711302","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711226","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711401","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711205","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711412","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711102","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711307","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711411","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711316","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711401","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711303","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711410","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711302","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711306","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711413","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711114","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711204","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711201","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711414","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711409","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711404","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711303","Taluk":"Amta - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711410","Taluk":"Jagatballavpur","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711310","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711413","Taluk":"Amta - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711414","Taluk":"Panchla","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711108","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711409","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711104","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711112","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711101","Taluk":"Sankrail","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711225","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711101","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711302","Taluk":"Panchla","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711109","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711414","Taluk":"Howrah","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711317","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711322","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711310","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711106","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711409","Taluk":"Bally Jagachha","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711313","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711227","Taluk":"Uluberia - I","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711309","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"711405","Taluk":"Uluberia - II","Districtname":"Howrah","statename":"WEST BENGAL"},
    {"pincode":"721102","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721222","Taluk":"Binpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721121","Taluk":"Garhbeta-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721501","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721517","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721150","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721156","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721504","Taluk":"Binpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721253","Taluk":"Garhbeta-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721140","Taluk":"Pingla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721506","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721156","Taluk":"Debra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721232","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721517","Taluk":"Gopiballavpur-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721131","Taluk":"Panskura-I","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721260","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721513","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721157","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721121","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721157","Taluk":"Garhbeta-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721505","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721201","Taluk":"Garhbeta-III","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721122","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721128","Taluk":"Garhbeta-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721201","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721144","Taluk":"Sabang","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721124","Taluk":"Ballichak","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721126","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721506","Taluk":"Binpur-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721515","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721506","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721506","Taluk":"Gopiballavpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721149","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721166","Taluk":"Sabang","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721517","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721155","Taluk":"Pingla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721503","Taluk":"Jamboni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721301","Taluk":"Kharagpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721503","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721212","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721124","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721155","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721516","Taluk":"Binpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721501","Taluk":"Binpur-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721514","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721201","Taluk":"Chandrakona-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721301","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721160","Taluk":"Debra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721140","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721136","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721301","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721211","Taluk":"Ghatal","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721513","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721253","Taluk":"Garhbeta-III","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721144","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721501","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721155","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721212","Taluk":"Ghatal","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721129","Taluk":"Salboni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721129","Taluk":"Salbani","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721504","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721515","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721516","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721147","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721222","Taluk":"Ghatal","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721101","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721128","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721144","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721149","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721127","Taluk":"Garhbeta-III","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721260","Taluk":"Chandrakona-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721146","Taluk":"Ghatal","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721126","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721157","Taluk":"Garhbeta","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721232","Taluk":"Ghatal","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721131","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721124","Taluk":"Pingla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721145","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721242","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721127","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721166","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721304","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721101","Taluk":"Midnapore","Districtname":"Medinipur","statename":"WEST BENGAL"},
    {"pincode":"721304","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721513","Taluk":"Kharagpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721242","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721121","Taluk":"Garhbeta-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721160","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721505","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721253","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721146","Taluk":"Daspur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721514","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721260","Taluk":"Keshpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721253","Taluk":"Midnapore","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721129","Taluk":"NA","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721232","Taluk":"Chandrakona-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721129","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721504","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721211","Taluk":"Hgatal","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721305","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721149","Taluk":"kharagpur-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721306","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721306","Taluk":"Kharagpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721232","Taluk":"Chandrakona-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721131","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721507","Taluk":"Jhargram","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721160","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721303","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721124","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721136","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721140","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721157","Taluk":"Garheta","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721302","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721222","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721516","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721201","Taluk":"Chandrakona-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721140","Taluk":"Sabang","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721121","Taluk":"Binpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721516","Taluk":"Salboni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721127","Taluk":"Garhbeta-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721305","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721161","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721211","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721515","Taluk":"Binpur-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721144","Taluk":"NA","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721166","Taluk":"Narayangarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721304","Taluk":"Kharagpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721212","Taluk":"Daspur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721126","Taluk":"Keshpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721146","Taluk":"Debra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721157","Taluk":"Garhbeta-III","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721150","Taluk":"Keshpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721124","Taluk":"Debra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721155","Taluk":"Sabang","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721131","Taluk":"Pingla","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721517","Taluk":"Sankrail","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721128","Taluk":"Binpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721242","Taluk":"Chandrakona-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721145","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721132","Taluk":"Salboni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721147","Taluk":"Salboni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721149","Taluk":"Debra","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721145","Taluk":"Kharagpur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721211","Taluk":"Daspur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721146","Taluk":"Midnapore","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721260","Taluk":"Chandrakona-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721146","Taluk":"Daspur-II","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721260","Taluk":"Daspur-I","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721144","Taluk":"Narayangarh","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721306","Taluk":"Kharagpur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721504","Taluk":"Jamboni","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721260","Taluk":"Garhbeta-III","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"723145","Taluk":"Santaldih","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723121","Taluk":"Adra","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723133","Taluk":"Raghuathpur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723129","Taluk":"Bundwan","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723130","Taluk":"Hura","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723154","Taluk":"Sirkabad","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723152","Taluk":"Bagmundi","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723101","Taluk":"Purulia - I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723127","Taluk":"Barabhum","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723148","Taluk":"Hutmura","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723126","Taluk":"Anara","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723126","Taluk":"Para","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723201","Taluk":"Arsha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723212","Taluk":"Tulin","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723133","Taluk":"Raghunathpur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723151","Taluk":"Puncha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723126","Taluk":"Anara Rs","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723147","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723202","Taluk":"Jhalda","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723148","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723215","Taluk":"Jaipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723146","Taluk":"Para","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723128","Taluk":"Puncha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723149","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723213","Taluk":"Jiudaru","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723127","Taluk":"Barabazar","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723148","Taluk":"Hura","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723148","Taluk":"Para","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723128","Taluk":"Balakdih","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723132","Taluk":"Kashipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723153","Taluk":"Sirkabad","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723201","Taluk":"Garjaipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723103","Taluk":"Chharrah","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723201","Taluk":"Jaipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723213","Taluk":"Kotshila","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723131","Taluk":"Manbazar - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723161","Taluk":"Manbazar - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723131","Taluk":"Manbazar","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723102","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723143","Taluk":"Balarampur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723101","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723155","Taluk":"Para","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723103","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723128","Taluk":"Manbazar - I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723161","Taluk":"Raghunathpur - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723128","Taluk":"Kenda","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723149","Taluk":"Manguria","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723130","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723128","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723153","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723152","Taluk":"Raghunathpur - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723146","Taluk":"Raghunathpur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723147","Taluk":"Chharrah","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723130","Taluk":"Puncha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723215","Taluk":"Jhalda","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723155","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723126","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723128","Taluk":"Manbazar","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723143","Taluk":"Bagmudni","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723121","Taluk":"Neturia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723101","Taluk":"Puncha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723102","Taluk":"Chharrah","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723213","Taluk":"Joypur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723143","Taluk":"Bagmundi","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723212","Taluk":"Baghmundi","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723121","Taluk":"Kashipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723152","Taluk":"Balarampur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723133","Taluk":"Kashipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723201","Taluk":"Joypur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723154","Taluk":"Arsha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723212","Taluk":"Jhalda","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723154","Taluk":"Purulia","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723145","Taluk":"Raghunathpur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723126","Taluk":"Kashipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723121","Taluk":"Kalapathar","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723153","Taluk":"Arsha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723213","Taluk":"Jhalda","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723152","Taluk":"Baghmundi","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723121","Taluk":"Santuri","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723213","Taluk":"Jhalda - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723126","Taluk":"Purulia - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723149","Taluk":"Chharrah","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723168","Taluk":"Manihara","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723152","Taluk":"Baghmudni","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723130","Taluk":"Kashipur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723156","Taluk":"Murardih","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723103","Taluk":"Purulia - I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723131","Taluk":"Hura","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723131","Taluk":"Puncha","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723215","Taluk":"Joypur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723143","Taluk":"Baghmundi","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723153","Taluk":"Baghmudni","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723142","Taluk":"Santuri","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723142","Taluk":"Ramkanali","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723121","Taluk":"Raghunathpur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723153","Taluk":"Balarampur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723104","Taluk":"Purulia - I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723145","Taluk":"Raghunathpur - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723146","Taluk":"Raghunathpur - II","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723133","Taluk":"Santuri","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723128","Taluk":"Purulia - I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723148","Taluk":"Joypur","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723148","Taluk":"Purulia Ii","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723212","Taluk":"Bagmundih","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723156","Taluk":"Santuri","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723131","Taluk":"Manbazar Ii","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723151","Taluk":"Santuri","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723201","Taluk":"Jhalda Ii","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723213","Taluk":"Jhalda Ii","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723212","Taluk":"Bagmundi","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723133","Taluk":"Raghunath Pur I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723201","Taluk":"Jhalda I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"723147","Taluk":"Purulia - I","Districtname":"Puruliya","statename":"WEST BENGAL"},
    {"pincode":"721636","Taluk":"Tamluk-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721635","Taluk":"Sutahata","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721650","Taluk":"Reapara","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721137","Taluk":"Sahid Matangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721134","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721650","Taluk":"Nandigram-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721631","Taluk":"Nandigram-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721656","Taluk":"Nandigram","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721628","Taluk":"Mahisadal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721642","Taluk":"Arankiarana","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721139","Taluk":"Panskura-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721148","Taluk":"Daspur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721134","Taluk":"Panskura-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721626","Taluk":"Kajlagarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721659","Taluk":"Nandigram-iii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721642","Taluk":"Moiyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721633","Taluk":"Nandigram-iii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721626","Taluk":"Bhagawanpur-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721151","Taluk":"Panskura-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721627","Taluk":"Tamkluk-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721649","Taluk":"Tamluk-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721158","Taluk":"Panskura-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721642","Taluk":"Moyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721139","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721641","Taluk":"Khukurdaha","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721139","Taluk":"Panakura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721645","Taluk":"Haldia Municipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721152","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721645","Taluk":"Sutahata","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721652","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721656","Taluk":"Reapara","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721648","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721647","Taluk":"Moyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721645","Taluk":"Haldia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721636","Taluk":"Tamluk","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721657","Taluk":"Haldia Municipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721651","Taluk":"Moyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721658","Taluk":"Sutahata","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721633","Taluk":"Chandipur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721659","Taluk":"Kajlagarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721654","Taluk":"Mahisadal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721654","Taluk":"Sutahata","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721602","Taluk":"Sutahata","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721601","Taluk":"Bhagawanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721656","Taluk":"Nandigram-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721151","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721635","Taluk":"Haldia Municipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721659","Taluk":"Chandipur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721641","Taluk":"Daspur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721649","Taluk":"Tamluk","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721633","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721152","Taluk":"Panskura-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721629","Taluk":"Moyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721644","Taluk":"Moyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721653","Taluk":"Tamluk","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721154","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721634","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721632","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721654","Taluk":"Haldia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721167","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721631","Taluk":"Nandigram","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721657","Taluk":"Haldia","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721172","Taluk":"Sahid Matangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721151","Taluk":"Panskura-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721603","Taluk":"Mahisadal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721636","Taluk":"Sahidmatangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721643","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721647","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721130","Taluk":"Daspur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721602","Taluk":"Haldia Municipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721633","Taluk":"Bhawanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721628","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721657","Taluk":"Mahisadal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721627","Taluk":"Sahidmatangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721646","Taluk":"Nandigram","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721130","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721633","Taluk":"Bhagawanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721633","Taluk":"Bhagwarpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721606","Taluk":"Haldia Municipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721607","Taluk":"Haldia Municipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721604","Taluk":"Haldia Municipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721625","Taluk":"Reapara","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721151","Taluk":"Tamluk-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721653","Taluk":"Tamluk-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721601","Taluk":"Bhagwanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721641","Taluk":"Panskura","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721134","Taluk":"Sahid Matangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721137","Taluk":"Sahidmatangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721153","Taluk":"Panskura","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721650","Taluk":"Nandigram Ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721627","Taluk":"Tamluk-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721602","Taluk":"Haldiamunicipality","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721655","Taluk":"Kajlagarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721134","Taluk":"Panskura Ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721633","Taluk":"Kajlagarh","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721171","Taluk":"Sahid Matangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721153","Taluk":"Daspur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721153","Taluk":"Daspur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721634","Taluk":"Panskura-i","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721659","Taluk":"Nandigram","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721648","Taluk":"Mahisadal","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721649","Taluk":"Moyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721642","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721629","Taluk":"Moiyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721625","Taluk":"Nandigram","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721154","Taluk":"Panskura-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721627","Taluk":"Daspur","Districtname":"West Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721172","Taluk":"NA","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721634","Taluk":"Panskura-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721647","Taluk":"Moiyna","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721151","Taluk":"Sahid Matangini","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721130","Taluk":"Panskura-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721646","Taluk":"Nandigram-ii","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721627","Taluk":"Tamluk","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721649","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721626","Taluk":"Bhagawanpur","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    {"pincode":"721648","Taluk":"Nandakumar","Districtname":"East Midnapore","statename":"WEST BENGAL"},
    ]
    